export default {
    getItem(key) {
        return localStorage.getItem(key)
    },
    getItemChange(key) {
        return JSON.parse(localStorage.getItem(key))
    },
    setItem(key, value) {
        localStorage.setItem(key, value)
    },
    removeItem(key) {
        localStorage.removeItem(key)
    },
    clear() {
        localStorage.clear();
    },
    toTree(data) {
        let result = []
        if (!Array.isArray(data)) {
            return result
        }
        data.forEach(item => {
            delete item.children;
            //    console.log(1234)
        });
        let map = {};
        data.forEach(item => {
            map[item.id] = item;
        });
        data.forEach(item => {
            let parent = map[item.pid];
            if (parent) {
                (parent.children || (parent.children = [])).push(item);
            } else {
                result.push(item);
            }
        });
        return result;
    },
    toTreePower(data) {
        let result = []
        if (!Array.isArray(data)) {
            return result
        }
        data.forEach(item => {
            delete item.children;
        });
        let map = {};
        data.forEach(item => {
            map[item.id] = item;
        });
        data.forEach(item => {
            let parent = map[item.ppermissionId];
            if (parent) {
                (parent.children || (parent.children = [])).push(item);
            } else {
                result.push(item);
            }
        });
        return result;
    },

    //静态页面添加权限函数
    staticPlusPpwer(arr, id, obj) {
        console.log("添加函数进去")
        try {
            arr.forEach(item => {
                if (item.id == id) {
                    item.children.push(obj)
                    console.log(item)
                    console.log(item.id)
                    throw Error()
                } else {
                    this.staticPlusPpwer(item.children, id, obj)
                }

            })
        } catch (e) {
            console.log(e)
        }
        return arr
    },
       //账号类型静态页面添加权限函数
       typeStaticPlusPpwer(arr, id, obj) {
        console.log("添加函数进去")
        try {
            arr.forEach(item => {
                if (item.id == id) {
                    item.children.push(obj)
                    console.log(item)
                    console.log(item.id)
                    throw Error()
                } else {
                    this.staticPlusPpwer(item.childs, id, obj)
                }

            })
        } catch (e) {
            console.log(e)
        }
        return arr
    },
    //编辑权限递归
    staticEditPower(arr, id, obj) {
        try {
            arr.forEach(item => {
                if (item.id == id) {
                    item = obj
                    throw Error()
                } else {
                    this.staticEditPower(item.children, id, obj)
                }
            })
        } catch (e) {
            console.log(e)
        } return arr
    },
    //删除权限递归
    staticDeletePower(arr, id) {
        try {
            arr.forEach(item => {
                console.log(item)
                if (item.id == id) {
                    arr.splice(arr.indexOf(item), 1)

                    throw Error()
                } else {
                    this.staticDeletePower(item.children, id)
                }
            })
        } catch (e) {
            console.log(e)
        }

    },
        //z账号删除删除权限递归
        typeStaticDeletePower(arr, id) {
            try {
                arr.forEach(item => {
                    console.log(item)
                    if (item.id == id) {
                        arr.splice(arr.indexOf(item), 1)
    
                        throw Error()
                    } else {
                        this.staticDeletePower(item.childs, id)
                    }
                })
            } catch (e) {
                console.log(e)
            }
    
        },
    //遍历权限拿到所有叶子节点
    leafHandler(totalArr,leafList){
        console.log(totalArr)
       for(var i=0;i<totalArr.length;i++){
           if(totalArr[i].children){
               this.leafHandler(totalArr[i].children,leafList)
           }else{
            leafList.push(totalArr[i].id) 
           }
       }
    return leafList
    }
}
