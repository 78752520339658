<template>
  <div>
  
    <el-dialog center title="忘记密码" :visible.sync="dialogFormVisible" width="28rem" style="margin-top: 10rem"
      :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item>
          <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.code" placeholder="请输入验证码" style="width: 14rem;" name="code" autocomplete="off">
          </el-input>
          <el-button style="float: right;width: 9rem" type="primary" v-show="!showTimer" @click="getCode">发送验证码
          </el-button>
          <el-button style="float: right;width: 9rem" type="info" v-show="showTimer">{{count}}秒后重新发送</el-button>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.newPwd" placeholder="请输入新密码" show-password autocomplete="new-password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.newPwdConfirm" placeholder="请再次输入新密码" show-password autocomplete="new-password">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" style="width: 6rem;">取 消</el-button>
        <el-button type="primary" @click="forgetPwd" style="width: 6rem">确 定</el-button>
      </div>
    </el-dialog>
    <div class="front" :style="backgroundDiv">

      <div class="login_con2">
       
        <div class="login_main">
          <div class="main-left">
            <div>
              <p> 
                  忘记密码
              </p>
              
             <el-form :model="form" class="formData">
        <el-form-item>
          <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input   v-model="form.code" placeholder="请输入验证码" name="code" autocomplete="off" >
          </el-input>
          <span class="sendCode"  v-show="!showTimer" @click="getCode">发送验证码
          </span>
          <span class="sendCode" v-show="showTimer">{{count}}秒后重新发送</span>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.newPwd" placeholder="请输入新密码" show-password autocomplete="new-password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.newPwdConfirm" placeholder="请再次输入新密码" show-password autocomplete="new-password">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('form')" size="mini" style="width:6rem;">取 消</el-button>
        <el-button type="primary" @click="forgetPwd" size="mini" style="width: 6rem;background:#FF6A00;border:none">确 认</el-button>
      </div>
            </div>
          </div>
    
        </div>
      </div>
     
      <div class="footer">
        <p>技术支持：北京青鸟美好生活科技有限公司</p>
      </div>
    </div>
    <el-dialog title="免责声明" :visible.sync="centerDialogVisible" width="50%" center>
      <p>客户和用户购买或使用北京青鸟美好生活科技有限公司（以下简称“青鸟美好生活”）产品或服务，表示已无条件接受本声明所涉全部内容。声明具体内容如下：</p>
      <p>下列损失、费用和责任，青鸟美好生活不负责赔偿：</p>
      <p>1、 产品售出后因贮存不当导致产品无法正常运行使用的；</p>
      <p>2、 用户擅自更改产品设计、结构或用途的；</p>
      <p>3、 用户未严格按照产品说明书对产品予以安装并使用的；</p>
      <p>4、 产品提示电池电力不足后用户未及时更换电池的，或产品提示故障后用户未及时处理的；</p>
      <p>5、 未按照要求填写联系人信息，或联系人信息填写有误等客户自身原因导致系统无法向联系人送达警报通知的；</p>
      <p>6、 联系人用于接收报警通知的设备未接入互联网和通信运营商网络，导致产品报警后无法向联系人正常送达警报通知的；</p>
      <p>7、 因通信运营商网络信号原因导致产品无法向联系人正常送达警报通知的；</p>
      <p>8、 产品报警后用户或联系人未及时采取相关措施，或忽视该报警情形的；</p>
      <p>9、 因第三方原因，如因吸烟、水汽、灰尘过多等现场环境问题、或因人为操作疏忽、人为故意等，造成的产品误报警、故障或失灵的；</p>
      <p>10、 地震、台风、洪水、战争等其他不可预见并且不可避免的不可抗力原因导致产品误报警、故障或失灵的；</p>
      <p>11、 其他非因产品本身质量原因导致的损失；</p>
      <p>12、 精神损害赔偿；</p>
      <p>13、 间接损失或预期利益；</p>
      <p>14、 在产品质保期外提出的相关要求；</p>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Storage from "../../utils/Storage";
import qs from "querystring";
import wxlogin from "vue-wxlogin";

export default {
  name: "Login",
  data() {
    return {
      showBind: false,
      activeIndex: 0,
      account: "",
      Wechat_authorized_login:true,
      wx_code: "",
      arr: ["账号登录"],
      arrwixin: ["微信登录绑定账号"],
      // arr: ["账号登录", "手机号登录"],
      checked: false,
      iconType: false,
      centerDialogVisible: false,
      password: "",
      showTimer: false,
      count: "",
      dialogFormVisible: false,
      form: {
        phone: "",
        code: "",
        newPwd: "",
        newPwdConfirm: ""
      },
      formLabelWidth: "120px",
      backgroundDiv: {
        backgroundImage:
          "url(" + require("../../assets/login/index_bg2.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }
    };
  },
  mounted() {
    
  },
  created() {
   
  },
  methods: {
    get_wx_qrcode2(){
       var href = 'data:text/css;base64,CiAgICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogMHB4KSBhbmQgKG1heC13aWR0aDogMTM3MHB4KSB7CiAgICAgICAuaW1wb3dlckJveCAucXJjb2RlIHt3aWR0aDogMTUwcHg7fQouaW1wb3dlckJveHsKICAgICAgICBtYXJnaW46YXV0bwp3aWR0aDoxNTBweDsKICAgfQogICAgICB9CgogICAgICBAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxMzcxcHgpIHsKICAgICAgICAgICAuaW1wb3dlckJveCAucXJjb2RlIHt3aWR0aDogMTgwcHg7fQouaW1wb3dlckJveHsKICAgICAgICBtYXJnaW46YXV0bwp3aWR0aDoxODBweDsKICAgfQogICAgICB9CgouaW1wb3dlckJveCAudGl0bGUgewp9Ci5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgewoKfQoKLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30KCi4gd2ViX3FyY29kZV90eXBlX2lmcmFtZXsKd2lkdGg6MTAwJTt9Ci5vbGQtdGVtcGxhdGV7CndpZHRoOjEwMCU7fQo='
 //从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
            var obj = new WxLogin({
                self_redirect: false,  //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
                id: "login_container",       //需要承载二维码的容器id  
                appid: "wxb2611ef16e200cab",
                scope: "snsapi_login",     //网页授权，目前网页只有这一种方式，静默授权
                redirect_uri: encodeURIComponent('https://jiayong360safe.cn/login'),     //回调域名(地址前部分是项目域名，后部分loginSuccess是微信需要跳转的路径（vue中的路由名称）)
                state: Math.random(),
                style: "black",
                  href:href,


            });

    },
    ...mapActions(["seUserInfoActions"]),
    get_wx_qrcode() {
      let DDNS_resolution = encodeURIComponent(
        "https://jiayong360safe.cn/login"
      );

      window.location.href =
        "https://open.weixin.qq.com/connect/qrconnect?appid=wxb2611ef16e200cab&redirect_uri=" +
        DDNS_resolution +
        "&response_type=code&scope=snsapi_login#wechat_redirect";
      window.open();
    },
    Get_wechat_callback_information() {
      let Wechat_return_code = window.location.href;
      let wx_parseUrl = Wechat_return_code.split("=")[1];
      if (wx_parseUrl && wx_parseUrl != null) {
        let wx_code = wx_parseUrl.split("&")[0];
        if (wx_code && wx_code != null) {
          this.$api
            .wxLogin(
              qs.stringify({
                code: wx_code
              })
            )
            .then(res => {
              if (res.data.code == 200) {
                if (res.data.wxBind) {
                  this.seUserInfoActions({
                    token: res.data.data.access_token
                  });
                  Storage.setItem("token", res.data.data.access_token);
                  Storage.setItem("refresh_token", res.data.data.refresh_token);
                  Storage.setItem("moduleIndex", "0");
                  this.$router.replace("/mainRouter");
                } else if (!res.data.wxBind) {
                  this.showBind = true;
                  this.wx_code = wx_code;
                }
              }
            });
        }
      }
    }, 
  resetForm(formName) {
        this.form.newPwd = ""
         this.form.newPwdConfirm = ""
         this.form.code = ""
         this.form.phone = ""
           this.$router.replace("/login");
      },
    activeHandle(index) {
      //把我们自定义的下标赋值
      this.activeIndex = index;
    },
  forgetPage(){
    this.$router.replace("/forgetPage");
  },
    forgetPwd() {
      if (this.form.phone == "") {
        this.$message({
          message: "手机号码不能为空",
          type: "error"
        });
        return;
      }
      if (this.form.code == "") {
        this.$message({
          message: "验证码不能为空",
          type: "error"
        });
        return;
      }
      if (this.form.newPwd == "" || this.form.newPwdConfirm == "") {
        this.$message({
          message: "新密码不能为空",
          type: "error"
        });
        return;
      }
      if (this.form.newPwd != this.form.newPwdConfirm) {
        this.$message({
          message: "两次输入的密码不一致，请重新输入",
          type: "error"
        });
        return;
      }
      this.$api
        .forgetPwd({
          message: this.form.code,
          mobile: this.form.phone,
          newPassword: this.form.newPwd
        })
        .then(res => {
          if (res.data.code === "200") {
              this.$router.push("/login");
            this.$message({
              message: "新密码设置成功",
              type: "success"
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(error => {});
    },
    getCode() {
      if (this.form.phone == "") {
        this.$message({
          message: "手机号码不能为空",
          type: "error"
        });
        return;
      }
      this.$api
        .getCode(this.form.phone)
        .then(res => {
          if (res.data.code === "200") {
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.showTimer = true;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.showTimer = false;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$message({
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(error => {});
    },
    onSubmit() {
      if (this.account == "" || this.password == "") {
        this.$message({
          message: "账号和密码不能为空",
          type: "warning"
        });
        return;
      }
      let subDate = {
        grant_type: "password",
        username: this.account,
        password: this.password,
        client_id: "test-client",
        client_secret: "test-secret"
      };
      let subDateStr = qs.stringify(subDate);
      this.$api
        .getLogin(subDateStr)
        .then(res => {
          if (res.status === 200) {
            //vuex写入数据
            this.seUserInfoActions({
              token: res.data.access_token
            });
            Storage.setItem("token", res.data.access_token);
            Storage.setItem("refresh_token", res.data.refresh_token);
            Storage.setItem("moduleIndex", "0");
            if (this.showBind) {
              this.$api
                .wxBind({
                  code: this.wx_code
                })
                .then(res => {
                  this.$router.replace("/mainRouter");
                });
            } else {
              this.$router.replace("/mainRouter");
            }
            //回到首页
          }
        })
        .catch(error => {});
    }
  }
};
</script>
<style lang="less" scoped>
.body {
  width: 100%;
  height: 100%;
  max-height: 100%;
  z-index: 0;
  position: absolute;
  background: #132230;
}

.front {
  z-index: 1;
  width: 100%;
  height: 100vh;
  border-top: 1px solid rgba(56, 91, 255, 0.6);
  box-sizing: border-box;
}

.logoWS {
  width: 5.1vw;
  margin: auto;
  padding: 1.4vw 0 3.2vw 0;
  height: 2.3vw;

  img {
    width: 100%;
    height: 100%;
  }
}

.login_con {
  width: 42.2vw;
//   height: 28.1vw;
  border-radius: 4px;
  background: rgba(255, 255, 255);
  border-radius: 20px;
  text-align: center;
  margin-top: 8%;
  margin-left: 47vw;
}
.login_con2 {
  width: 25.2vw;
  height: 27.5rem;
  border-radius: 4px;
  background: rgba(255, 255, 255);
  border-radius: 20px;
  text-align: center;
  margin-top: 8%;
  margin-left: 53vw;
}

.title {
  width: 25rem;
  height: 4rem;
  margin: 0 auto;
  text-align: center;
  padding-top: 18px;
}

.span_title {
  font-size: 18px;
  color: #333333;
  width: 353px;
  font-family: Microsoft yahei;
}

.login_main {
  width: 17.6vw;
  height: 17.6vw;
  margin: 0 0 0 4vw;
  display: flex;
 
}

.main-left {
  text-align: center;
//   height: 17vw;
  position: relative;
  p{
      font-weight: 500;
      font-size: 1.2vw;
      text-align: center;
      width: 100%;
      color: #333;
      padding:2rem 0 1rem 0;
      border-bottom:  1px dashed #c1c1c1;
  }
  .el-input {
    width: 16.9vw;
  }
  .formData{
      margin-top: 2.5vw;
  }
  .form_group {
    width: 16.9vw;
     font-size: 0.8vw;
     /deep/.el-button--text{
        font-size: 0.8vw;
     }
  }
  /deep/.el-input__inner {
    width: 16.9vw;

    background: transparent;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom: 1px solid #d6d6d6;
  }
}
/deep/.el-form-item__content{
    line-height: normal;
    

}
/deep/.el-form-item__content::after{
    clear: none;
}
/deep/.el-form-item::after{
      clear: none;
}
.sendCode{
   float: right;
   cursor: pointer;
    transform: translate(-10%,-150%);
    // transform: translateY(-170%);
    // transform: translateX(-10%);
    font-size: 0.8vw;
}
.main-left2 {
  text-align: left;
  height: 17vw;
  position: relative;
  .el-input {
    width: 16.9vw;
  }
  .form_group {
    width: 16.9vw;
     font-size: 0.8vw;
     /deep/.el-button--text{
        font-size: 0.8vw;
     }
  }
  /deep/.el-input__inner {
    width: 16.9vw;

    background: transparent;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom: 1px solid #d6d6d6;
  }
}
.main-right {
  border-left: 1px dashed #c1c1c1;
  margin-left: 3.5vw;

   text-align: center;
   width: 100%;
   #login_container{
    margin: auto;
      // width: 100%;
    text-align: center;
    
      @media screen and (min-width: 0px) and (max-width: 1370px) {
               transform: translate(-10%,0%);
      }

      @media screen and (min-width: 1371px) {
            transform: translate(5%,0%);
      }
  
      // border: 1px dashed #c1c1c1;
   }
   p{
    //  transform: translate(30%,0%);
    //       font-size: 1.1vw;
   }
   
 
}
.footer {
  position: absolute;
  height: 30px;
  width: 100%;
  bottom: 0.5rem;
  left: 0;
  text-align: center;
  z-index: 1001;
  font-weight: bolder;
}

.footer_bw {
  position: absolute;
  height: 12rem;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
}

.footer p {
  color: #eeeeee;
  font-size: 15px;
}

.form_group .input_check {
  margin: 0px 5px 0 0;
  cursor: pointer;
}

.form_group .find_pwd {
  color: #a7b6c7;
  font-size: 0.8vw;
}

.form_group .find_pwd:hover {
  color: #FF6A00;
}

.form_input_con form {
  width: 280px;
  height: 195px;
}

.form_input_con form .form_group {
  width: 280px;
  height: 55px;
}

.input_label {
  color: #0f2149;
  cursor: pointer;
}

.bt_submit {
  height: 2.1vw;
  width: 16.9vw;
 background: #FF6A00;
border-radius: 5px;
 position: absolute;
 bottom:0;
  border: 0px;
  font-size: 1vw;
  // font: 15px/36px  ;
  color: white;
  letter-spacing: 4px;
  cursor: pointer;
}

.bt_submit:hover {
  background: #1d4663dd;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.el-button--small {
  padding-top: 0px;
  padding-bottom: 0px;
}

.third_party_login {
  margin-top: 2rem;

  span {
    padding-top: 0.2rem;
    font-size: 0.8rem;
    height: 3rem;
    color: #a5a3a3;
    float: left;
  }
}

//@font-face {
//  font-family: 'iconfont';
//  src: url('../../assets/fonts/iconfont/iconfont.ttf?t=1626659053853') format('truetype');
//}
.iconfont {
  margin-left: 0.5rem;
  font-family: "iconfont" !important;
  font-size: 1rem !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-weixin {
  padding-top: 0 !important;
  margin-left: 0.5rem;
  cursor: pointer;
  width: 1.5rem !important;
  height: 1.5rem !important;
  //vertical-align: middle;
  text-align: center;
  //background-position: center;
  //background-attachment: fixed;
  //background-repeat: no-repeat;
  background: url("../../assets/icons/weixin-02.png");

  &:hover {
    background-image: url("../../assets/icons/weixin-01.png");
  }

  background-size: 100% 100%;
}

.icon-info {
  float: right !important;
  margin-top: 0.2rem !important;
  margin-left: 1rem;
  cursor: pointer;
  width: 1.2rem !important;
  height: 1rem !important;
  text-align: center;
  background: url("../../assets/icons/tishi.png");
  background-size: 100% 100%;
}

.background-image {
  width: 100%;
  height: 100%;
  background: url("../../assets/login/index_bg2.png");
  background-size: 100% 100%;
  z-index: 1000;
}

.background-mid-layer {
  width: 100%;
  height: 100%;
  // background: #47566966;
  z-index: 999;
}

.active {
  color: #ff6a00;
  border-bottom: 1px solid #ff6a00;
}

.titleTab {
  font-size: 1.1vw;
  display: inline-block;
  margin-right: 2vw;
  cursor: pointer;
}



</style>