<template>
  <div class="mainContainer">


    <div class="select">
      <span class="title">物联网卡信息列表</span>

      <div class="select-input-right-input">
        <el-input placeholder="请输入IMEI" v-model="imei" size="small" @keyup.13.native="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </div>
    </div>

    <div class="table-vv">
<!--      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">-->
<!--        <div class="result-info">-->
<!--          共有-->
<!--          <span>{{ total }}</span>台设备-->
<!--        </div>-->
<!--      </div>-->
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="device-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column v-if="checkedImei" prop="payload" label="Payload" align="center"></el-table-column>



        <el-table-column v-if="checkedTime" prop="createTime" label="创建时间" align="center"></el-table-column>

      </el-table>

    </div>

  </div>
</template>


<script>


export default {
  data() {
    return {

      allowDelete: true,
      imei: "",
      visible: false,
      checkedRegistStatus: true,
      macStr: "",
      checkedProduct: true,
      checkedOpera: true,
      checkedTime: true,
      checkedOnline: true,
      checkedDelDet: true,
      checkedImei: true,
      delDialogVisible: false,
      tableData: [],
      //新增设备弹出对话框标记
      dialogVisible: false,
      options: [],
      limitUpload: 1,
      value: "",
      input: "",
      fileList: [],
      total: 0
    };
  },
  methods: {


    search() {

      this.getRegisterList();
    },



  getRegisterList() {
      //查询设备列表
      this.$api
        .getVoiceCardList(this.imei)
        .then(res => {
            this.tableData = res.data.data
        })
        .catch(error => {});
    }
  },
  created() {
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.device-content {
  height: 6rem;

  .device-info-item {
    float: left;
    margin-right: 3rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 12rem;
    }
  }
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15 /16rem;
  }
  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
  .data-setting {
    font-size: 0.875rem;
    color: #2673bb;
    float: right;
    line-height: 1rem;

    i {
      padding-right: 0.2rem;
    }

    cursor: pointer;
  }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}
</style>
