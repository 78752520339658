<template>
    <div class="outting">
        <div class="left-card">
           
            <div class="left_tit" > <h3 style=";margin-left:2rem">兑换统计</h3>
            <span style="font-size:12px;;margin-left:2rem">时间 ：</span>
                    <el-date-picker style="width: 22.25rem;;margin-right:2rem;margin-top:10px" clearable v-model="codeTime"
                range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" size="mini"
                value-format="yyyy-MM-dd" format="yyyy-MM-dd" 
                type="daterange" @change="getTimeBlock"></el-date-picker>
            <el-button size="mini" type="primary" @click="codeStatistics">搜索</el-button>
           </div>
            <div class="charts"  id="rightTop"></div>
        </div>
        <div class="right-card">
            <div class="left_tit" >
             <h3 style=";margin-left:2rem">订单统计</h3>
            <span style="font-size:12px;;margin-left:2rem">时间 ：</span>
    
             <el-date-picker style="width: 22.25rem;;margin-right:2rem;margin-top:10px" clearable v-model="ordertime"
                range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" size="mini"
                value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                type="daterange" @change="getTimeBlock"></el-date-picker>
            <el-button size="mini" type="primary" @click="orderStatistics">搜索</el-button>
            </div>
           
            <div class="charts"  id="leftTop"></div>
        </div>

 
    </div>
</template>
<script>
    export default {
        name: "statistics",
        data() {
            return {
                codeTime: '',
                ordertime: "",

            };
        },
        methods: {
            handleOpen(key, keyPath) {
                // console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                // console.log(key, keyPath);
            },
            getTimeBlock() {

            },
            getTime(val) {
                //   获取时间并格式化
                // 获取前val天的时间
                var dt = new Date();
                val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + "").padStart(2, "0");
                const d = (dt.getDate() + "").padStart(2, "0");
                const hh = (dt.getHours() + "").padStart(2, "0");
                const mm = (dt.getMinutes() + "").padStart(2, "0");
                const ss = (dt.getSeconds() + "").padStart(2, "0");

                // return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
                return `${y}-${m}-${d}`;  // 获取的时间格式为 2021-04-09
                // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
                // return y + m + d  // 获取的时间格式为  20210409
            },
            codeStatistics() {
                this.$api
                    .codeStatistics({
                        startTime: this.codeTime[0],
                        endTime: this.codeTime[1],
                    })
                    .then(res => {
                        console.log(res.data.data)
                        this.echarts2 = this.$echarts.init(document.getElementById("rightTop"));
                        let option2 = {
                            tooltip: {
                                trigger: 'axis'
                            },
                            grid: {

                                //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算

                                top: "2%",

                                left: "10%",

                                right: "10%",

                                bottom: "10%"

                            },
                            // backgroundColor: 'rgba(255, 173, 177, 0.2)', //背景色
                            xAxis: {
                                type: 'category',
                                axisLine: {
                                    onZero: false,
                                    lineStyle: {
                                        color: '#8b8aa2',
                                    }
                                },

                                // color:,
                                data: res.data.data.dateList
                            },
                            yAxis: {
                                type: 'value',
                                color: '#fff',
                                axisLabel: {
                                    formatter: '{value} 个'
                                },
                                axisLine: {
                                    onZero: false,
                                    lineStyle: {
                                        color: '#8b8aa2',
                                    }
                                },
                            },
                            series: [{
                                data: res.data.data.numList,
                                type: 'line',
                                stack: 'Total',
                                smooth: true,
                                lineStyle: {
                                    width: 1
                                },
                                showSymbol: false,
                                itemStyle: {
                                    color: '#6de9cf'
                                },
                                areaStyle: {
                                    normal: {
                                        color: '#6de9cf' //改变区域颜色
                                    },
                                },
                                emphasis: {
                                    focus: 'series'
                                },
                                smooth: true
                            }]
                        };
                        this.echarts2.setOption(option2);

                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            orderStatistics() {
                this.$api
                    .orderStatistics({
                        startTime: this.ordertime[0],
                        endTime: this.ordertime[1],
                    })
                    .then(res => {
                        console.log(res.data.data)
                        this.echarts = this.$echarts.init(document.getElementById("leftTop"));
                        let option = {
                            tooltip: {
                                trigger: 'axis'
                            },
                            grid: {

                                //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算

                                top: "2%",

                                left: "10%",

                                right: "10%",

                                bottom: "5%"

                            },
                            //  backgroundColor: '#332d5e', //背景色  
                            xAxis: {
                                type: 'category',
                                data: res.data.data.dateList,

                            },
                            yAxis: {
                                type: 'value',
                                axisLabel: {
                                    formatter: '{value} 个'
                                }

                            },
                            series: [{
                                data: res.data.data.numList,
                                type: 'line',

                                stack: 'Total',
                                smooth: true,
                                lineStyle: {
                                    width: 1
                                },
                                showSymbol: false,
                                itemStyle: {
                                    color: '#9c92db'
                                },
                                areaStyle: {
                                    normal: {
                                        color: '#9c92db' //改变区域颜色
                                    },
                                },
                                emphasis: {
                                    focus: 'series'
                                },

                            }]
                        };
                        this.echarts.setOption(option);

                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        created() {
            this.codeTime = [this.getTime(7), this.getTime(0)]
            this.ordertime = [this.getTime(7), this.getTime(0)]
        },
        mounted() {
            // 订单统计

            this.orderStatistics()
            this.codeStatistics()




        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        // display: flex;
        text-align: center;
        // justify-content: space-around;
        background-color: #fafafa;

        .left-card,
        .right-card {
            width: 97%;
            // margin-top: 1rem;
            // margin: 1rem auto;
           .left_tit{
           text-align: left;
           margin: 20px 20px;
           }
            .charts {
                width: 100%;
                height: 58vh;
                margin-top: 30px;
                margin-bottom:3rem;
                min-width: 280px;
                min-height: 210px;
            }
        }




    }
</style>