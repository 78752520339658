<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除该产品品类吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delConfirmProductCategory">确 定</el-button>
      </span>
    </el-dialog>
    <div class="select">
      <span class="title">产品品类列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-refresh"
        class="select-button"
        @click="renovate()"
      >刷新</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        class="select-button"
        @click="showAddProductCategoryDialog"
      >新增</el-button>
    </div>
      <div class="tab-nav">
        <div class="tab-item left" id="producList" ref="producList" @click="changeStyleAndView($event)">
          产品列表
        </div>
        <div class="tab-item" id="listThird" ref="listThird" @click="changeStyleAndView($event)">第三方产品列表
        </div>
     
      </div>
     <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个产品品类
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="device-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="name" label="产品品类" align="center"></el-table-column>
        <el-table-column prop="categoryCode" label="品类编码" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="sysUserName" label="创建人" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="deleteProductCategory(scope.row.id)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="新增品类"
      :visible.sync="dialogVisible"
      width="48rem"
      :close-on-click-modal="false"
    >
      <el-form :model="productCategory" :rules="rules" ref="productCategory" label-width="100px">
        <el-form-item label="品类名称" prop="name">
          <el-input v-model="productCategory.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="品类编码" prop="categoryCode">
          <el-input v-model="productCategory.categoryCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="品类描述" prop="remark">
          <el-input type="textarea" v-model="productCategory.remark"></el-input>
        </el-form-item>
           <el-form-item label="产品类型" prop="categoryType">
          <el-select
                    v-model="productCategory.categoryType"
                    placeholder="产品类型"
                    style="width:100%"
                  >
                    <el-option
                      v-for="item in categoryTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitForm('productCategory')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  name: "ProductCategoryList",
  data() {
    return {
        itemIds: [
          "producList",
          "listThird",
        ],
      formLabelWidth: "120px",
      productCategory: {
        name: "",
        remark: "",
        categoryCode:'',
        categoryType:'',
      },
      total: 0,
      categoryTypeList:[{
        id:"0",
        name:"自有"
      },{
        id:"1",
        name:"第三方"
      }],
      visible: false,
      tableData: [],
      elID:'',
      checked: false,
      //新增产品品类弹出对话框标记
      dialogVisible: false,
      delDialogVisible: false,
      options: [],
      value: "",
      input: "",
      id: "",
      rules: {
        name: [
          { required: true, message: "请输入品类名称", trigger: "blur" },
          { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        ],
        categoryCode: [
          { required: true, message: "请输入品类编码", trigger: "blur" },
          { min: 1, max:4, message: "长度在 1 到 4 个字符", trigger: "blur" }
        ],
        remark: [{ required: true, message: "请填写品类描述", trigger: "blur" }],
        categoryType: [{ required: true, message: "请选择产品类型", trigger: "change" }]
      }
    };
  },
  methods: {
    // 切换选择
      changeStyleAndView(event){
           var el = event.currentTarget;
        console.log("当前对象的内容：" + el.id);
        this.elID = el.id
        el.style.borderBottom = "3px solid #12b7f5";
        switch (el.id) {
          case "producList":
            // this.producList = true;
            this.productCategoryList()
            break;
          case "listThird":
            // this.listThird = true;
            this.productListThird(); //待处理
            break;
        
          default:
            break;
        }
        this.itemIds.forEach(item => {
          if (item !== el.id) {
            switch (item) {
              case "producList":
                this.$refs.producList.style.borderBottom = "none";
                // this.producList = false;
                break;
              case "listThird":
                this.$refs.listThird.style.borderBottom = "none";
                // this.listThird = false;
                break;
           
              default:
                break;
            }
          }
        });
      },
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    deviceDetail(data) {
      this.$router.push("/productDetail");
    },
    // 点击刷新
    renovate(){
        this.$refs.producList.style.borderBottom = "3px solid #12b7f5";
          this.$refs.listThird.style.borderBottom = "none";
      this.productCategoryList();
    },
    deleteProductCategory(id) {
      this.id = id;
      this.delDialogVisible = true;
    },
    delConfirmProductCategory() {
      this.$api
        .deleteProductCategory(this.id)
        .then(res => {
          //查询产品品类列表
          this.delDialogVisible = false;
          console.log(this.elID)
          if( this.elID  == 'listThird'){
                  this.$refs.listThird.style.borderBottom = "3px solid #12b7f5";
          this.$refs.producList.style.borderBottom = "none";
          this.productListThird();
          }else{
                this.$refs.listThird.style.borderBottom ="none";
          this.$refs.producList.style.borderBottom = "3px solid #12b7f5";
          this.productCategoryList();
          }
         
        })
        .catch(error => {
          console.log(error);
        });
    },
    //新增产品品类
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.dialogVisible = false;
          this.$api
            .addProductCategory(this.productCategory)
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("添加产品品类成功!");
                this.productCategory.name = "";
                this.productCategory.remark = "";
                this.productCategory.categoryCode = "";
                //查询产品品类列表
                this.productCategoryList();
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },
    showAddProductCategoryDialog() {
      this.dialogVisible = true;
      if (this.$refs["productCategory"] !== undefined) {
        this.resetForm("productCategory");
      }
    },
    productCategoryList() {
       
      this.$api
        .productCategoryList({
          page: {
            current: 1,
            size: 1000
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 第三方列表
      productListThird() {
      this.$api
        .productListThird({
          page: {
            current: 1,
            size: 1000
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    this.productCategoryList();
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);
.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

      .tab-nav {
        //background: red;
        border-bottom: 1px solid #d9d9d9;

        .tab-item {
          cursor: pointer;
          display: inline-block;
          padding: 0 1.5rem;
          line-height: 2rem;
          font-weight: bold;
          font-size: small;

          &.left {
            margin-left: 3rem;
            border-bottom: 3px solid #4faaf0;
          }

          &.right {
            margin-right: 3rem;
          }

          &:hover {
            color: #4faaf0;
          }
        }
      }
.device-content {
  height: 16rem;
  overflow: auto;
  .device-info-item {
    margin-right: 4rem;
    margin-bottom: 2rem;
    display: block;
    .label-info {
      margin-bottom: 0.5rem;
      label {
        font-size: 0.75rem;
        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }
    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 13rem;
    }
  }
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15px;
  }
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}
</style>