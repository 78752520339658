<template>
    <div class="outting">
        <div class="select">
            <span class="title">第三方应用限频信息列表</span>
     
        </div>
        <div class="table-vv">
            <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <el-table-column prop="appName" label="名称" min-width="2" fixed align="center">
                    <template slot-scope="{row}">{{ row.appName || '暂无数据'}}</template>
                </el-table-column>

                <el-table-column prop="limitCount" label="次数" min-width="1" fixed align="center">
                    <template slot-scope="{row}">{{ row.limitCount || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column prop="sec" label="时间(秒)" min-width="1" fixed align="center">
                    <template slot-scope="{row}">{{ row.sec || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column prop="appHandle" label="是否允许app操作设备数据" min-width="2" fixed align="center">
                    <template slot-scope="{row}">{{ row.appHandle == 1?'是':"否"  }}</template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center">
                    <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column prop="updateTime" label="修改时间" min-width="1" fixed align="center">
                    <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column min-width="1" label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" icon="el-icon-edit"
                            @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
                        <el-popconfirm title="确定删除这个版本吗？" @onConfirm="deleteAddHandler()">
                            
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="total" class="pagin"
                @current-change="changeCurrent"></el-pagination>
        </div>
      
        <el-dialog title="修改更新提示" :visible.sync="editDialogFormVisible" :close-on-click-modal="false">
            <el-form :model="editForm" size="small" :inline="true" style="width:700px" :rules="editRules"
                :auto-upload="false" ref="editRules" >
                <el-form-item label="是否允许app操作" :label-width="formLabelWidth" prop="appHandle">
                        <el-select v-model="editForm.appHandle" placeholder="是否允许app操作设备数据">
                        <el-option label="否" value="0"></el-option>
                        <el-option label="是" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="次数" :label-width="formLabelWidth" prop="limitCount">
                      <el-input v-model="editForm.limitCount" autocomplete="off" style="width:215px"
                      ></el-input>
                </el-form-item>
                  <el-form-item label="时间(秒)" :label-width="formLabelWidth" prop="sec">
                      <el-input v-model="editForm.sec" autocomplete="off" style="width:215px"
                        :rows="2"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="editAppListForm('editForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: "AppList",
        data() {
            return {
                current: 1,
                size: 10,
                total: 0,
                appId:"",
                tableData: [],
                file: {},
                editFile: {},
                deleteId: "",
                editId: "",
                addDialogFormVisible: false,
                // 限制开始时间
                pickerOptionsStart: {},
                pickerOptionsEnd: {},
           
                editForm: {
                    sec: "",
                    limitCount: "",
                    appHandle:"",
                },
                addforceUpdate: "",
                addappType: "",
                editDialogFormVisible: false,
            
                editRules: {
                    sec: [{
                        required: true,
                        message: "请输入时间,",
                        trigger: "blur"
                    }],    
                    limitCount: [{
                        required: true,
                        message: "请输入次数,",
                        trigger: "blur"
                    }],
                    appHandle: [{
                        required: true,
                        message: "是否允许app操作",
                        trigger: "change"
                    }],
                },
                formLabelWidth: "180px",
                editforceUpdate: "",
                editappType: ""
            };
        },
        created() {
            this.appList();
        },
        methods: {
         
            //更新函数
            handleUpdate(index, row) {
                this.appId = row.appId;
                this.editFile = {};
                this.editDialogFormVisible = true;
                if (this.$refs["editRules"] !== undefined) {
                    this.resetForm("editRules");
                }
                console.log(row);
                this.editForm.sec = row.sec   
                this.editForm.limitCount = row.limitCount   
                if(row.appHandle == 0) {
                     this.editForm.appHandle = '否'
                }else{
                    this.editForm.appHandle = '是'
                }
            
            },

            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            appList() {
                this.$api
                    .thirdPlatformAppLimitDataList({
                        current: this.current,
                        size: 10
                    })
                    .then(res => {
                        console.log(res.data);
                        this.tableData = res.data.data;
                      
                    });
            },
            addUserHandler() {
                this.file = {};

                this.addDialogFormVisible = true;

                if (this.$refs["addRules"] !== undefined) {
                    this.resetForm("addRules");
                }
            },
            stateFormatAppType(row) {
                if (row.type == 0) {
                    return "安卓";
                } else if (row.type == 1) {
                    return "IOS";
                } else if (row.type == 2) {
                    return "onenet";
                } else {
                    return "暂无数据";
                }
            },
            stateFormatForceUpdate(row) {
                if (row.forceUpdate == 0) {
                    return "是";
                } else if (row.forceUpdate == 1) {
                    return "否";
                } else {
                    return "暂无数据";
                }
            },
            changeCurrent(id) {
                this.current = id;
                this.appList();
            },

            changeAppType(id) {
                // console.log(id);
                this.addappType = Number(id);
            },
            editchangeAppType(id) {
                // console.log(id);
                this.editappType = Number(id);
            },
         
            editAppListForm(editForm) {
                this.$refs["editRules"].validate(valid => {
                    if (valid) {
                        this.editDialogFormVisible = false;
                     if(this.editForm.appHandle == '是'){
                       this.editForm.appHandle  = 1
                     }else if(this.editForm.appHandle == '否'){
                           this.editForm.appHandle  = 0
                     }
                   this.editForm.appId  = this.appId 
                     
                            this.$api.editThirdPlatformAppLimitData(this.editForm).then(res => {
                                if (res.data.code == 200) {
                                    this.$message.success("修改成功");
                                    this.editFile = {};
                                    this.appList();
                                }
                            });
                     
                      
                    } else {
                        return false;
                    }
                });
            },
            handleChange(file, fileList) {
                console.log(file);
                this.file = file.raw;
            },
            editHandleChange(file, fileList) {
                console.log(file);
                this.editFile = file.raw;
            },
       

        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .select-status {
                width: 9rem;
                margin-left: 1.7rem;
                margin-top: 7px;
                // margin-top:7px;
            }

            .select-input-timer {
                float: right;
            }

            .select-status {
                float: right;
            }

            .select-input-right-input {
                float: right;
                // width: 8rem;
                line-height: 46px;
            }

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .addRoleButton {
                margin-left: 15px;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }

            .update-input-timer {
                float: right;
                line-height: 46px;
                margin-left: 25px;
                height: 46px;

                .demonstrationTwo {
                    float: left;
                    font-size: 0.8rem;
                }

                .update-input-timer-select {
                    width: 11rem;
                    margin-left: 5px;
                }
            }
        }

        .table-vv {
            padding: 1rem 3rem;

            .pagin {
                margin-left: 30%;
                margin-top: 10px;
                float: right;
            }
        }
    }
</style>