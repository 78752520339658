<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除商品吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDelete">取 消</el-button>
        <el-button type="primary" @click="handleDelClose">确 定</el-button>
      </span>
    </el-dialog>
    <div class="select">
      <span class="title">商品列表</span>
      <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新</el-button>
      <el-button type="text" size="small" icon="el-icon-plus" class="select-button" @click="showAddProductDialog">新增</el-button>
      <el-button type="text" size="small" icon="el-icon-download" class="select-button" @click="goodsExport">导出</el-button>
          <!-- <el-input size="mini" placeholder="请输入商品名称" style="width:20%"   class="select-button"  v-model="input3">   <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input> -->
    </div>
 <div class="gaojiSearch">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="toolbar-right">
             <span style="font-size:12px;margin-bottom:20px">商品名称：</span> <el-input size="mini" clearable placeholder="请输入商品名称" style="width:15%;margin-right:10px;"   class="select-searchClass"  v-model="input3">
      </el-input>
        <span style="font-size:12px;margin-bottom:20px">上架时间：</span>
        <el-date-picker style="width: 20.25rem;;margin-right:10px;" v-model="onShelf" range-separator="~" start-placeholder="开始日期"
              end-placeholder="结束日期" size="mini"  value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
              @change="getTimeBlock"></el-date-picker>
               <span style="font-size:12px;margin-bottom:20px">下架时间：</span>
        <el-date-picker style="width: 20.25rem;" v-model="downShelf" range-separator="~" start-placeholder="开始日期"
              end-placeholder="结束日期" size="mini" value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
              @change="getTimeBlock2"></el-date-picker><br>
            <br />
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
               <el-button size="mini" @click="emptyFn()">清空</el-button>
                    <el-button type="primary" size="mini" @click="searchFn">搜索</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
        <!-- <div class="searchClass" >


        </div> -->
    <div class="table-vv">

      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个商品
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}"      @select="getIds"  @select-all="getAllIds">
          <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="name" label="商品名称" align="center"></el-table-column>
        <el-table-column prop="id" label="商品ID" align="center"></el-table-column>
        <el-table-column prop="goodsNo" label="商品编码" align="center"></el-table-column>
<!--        <el-table-column prop="actualPrice" label="商品实际价格" align="center"></el-table-column>-->
        <el-table-column prop="actualPrice" label="商品实际价格" align="center">
          <template slot-scope="scope">
            <el-input style="width:5rem" type="text" v-model="scope.row.actualPrice"
                      v-show="scope.row.iseditor" size="mini"> </el-input>
            <el-button v-show="scope.row.iseditor" @click="save(scope.row)" type="text" size="small" icon="el-icon-info">修改</el-button>

            <span v-show="!scope.row.iseditor">{{scope.row.actualPrice}}</span>
            <el-button v-show="!scope.row.iseditor" @click="edit(scope.row,scope)" type="text" size="small" icon="el-icon-info">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="actualPrice" label="商品显示销量" align="center">
          <template slot-scope="scope">
            <el-input style="width:5rem" type="text" v-model="scope.row.salesVolumeShow"
                      v-show="scope.row.iseditor" size="mini"> </el-input>
            <el-button v-show="scope.row.iseditor" @click="saveSales(scope.row)" type="text" size="small" icon="el-icon-info">修改</el-button>

            <span v-show="!scope.row.iseditor">{{scope.row.salesVolumeShow}}</span>
            <el-button v-show="!scope.row.iseditor" @click="edit(scope.row,scope)" type="text" size="small" icon="el-icon-info">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="columnPicture" label="商品列表图片"  align="center">
             <template slot-scope="scope">
            <el-popover placement="top-start" title trigger="hover">
              <el-image
                :src="scope.row.columnPicture"
                :fit="fit"
                style="width:160px;height:160px"
                referrerpolicy="no-referrer"
              ></el-image>
              <el-image
                slot="reference"
                :src="scope.row.columnPicture"
                style="width: 50px;height: 50px"
                :fit="fit"
                referrerpolicy="no-referrer"
              ></el-image>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="stock" label="商品库存" align="center"></el-table-column>
        <el-table-column prop="exchangeAmount" label="已兑换数量" align="center"></el-table-column>
        <el-table-column prop="shelfTime" label="上架时间" align="center"></el-table-column>
        <el-table-column prop="offShelfTime" label="下架时间" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <!-- <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column> -->
        <el-table-column fixed="right" label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button @click="goEvaluation(scope.row)" type="text" size="small" icon="el-icon-info">评价</el-button>
            <el-button @click="goodsUpdate(scope.row)" type="text" size="small" icon="el-icon-info">修改</el-button>
            <el-button @click="deleteProductD(scope.row.id)" type="text" size="small" icon="el-icon-delete">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background :current-page.sync="currentPage" :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
        layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
      </el-pagination>
    </div>


  </div>
</template>


<script>
// import Bus from "../../../components/bus";
export default {
  name: "mallList",
  data() {
    return {
      productDel: false,
      productAdd: false,
         currentPage: 1,
      input3: "",
         onShelf: "",
         downShelf: "",
          fit: "fill",
         onstartTime: "",
         downstartTime: "",
         onendTime: "",
         downendTime: "",
             pickerOptions: {
          disabledDate(time) {
            let cuttentDate = new Date(
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
            );
            let hmCurrentDate = cuttentDate.getTime();
            let three = 90 * 24 * 3600 * 1000;
            let threeMonths = hmCurrentDate - three;
            return time.getTime() > hmCurrentDate || time.getTime() < threeMonths;
          }
        },
      form: {
        nodeName: "",
        isSendEmail: "",
        nodeDesc: "",
        problemStatusId: "",
        isSendSmsCreator: ""
      },
      formModify: {
        isSendEmail: "",
        isSendSmsCreator: ""
      },
      visible: false,
      total: 0,
      tableData: [],
      emileList: [
        {
          value: "1",
          label: "是"
        },
        {
          value: "0",
          label: "否"
        }
      ], //搜索下拉选择
      smsList: [
        {
          value: "1",
          label: "发送"
        },
        {
          value: "0",
          label: "不发送"
        }
      ], //是否发送短信
      chooseOnlineStatus: "", //搜索选择
      sysUserlist: [], //账号所属类型
      newSsUserlist: {
        value: "id",
        label: "typeName",
        children: "childs",
        expandTrigger: "hover"
        // emitPath: false
      },
      checked: false,
      //新增账号弹出对话框标记
      dialogVisible: false,
      //修改账号弹出对话框标记
      modifydialogVisible: false,
      //节点问题状态
      protocols: [],

      //产品品类列表
      categories: [],
      ids: [],

      value: "",
      input: "",
      current: 1,
      pageSize: 10,
      delDialogVisible: false,
      productId: "",
      indexId: "", //反显id
      nameinput: "", //搜索输入
      indexIdlist: "", //反显列表
      formLabelWidth: "120px",
      rulesModify: {
        isSendEmail: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ],
        isSendSmsCreator: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ]
      },
      rules: {
        nodeName: [
          {
            required: true,
            message: "请输入节点标题",
            trigger: "blur"
          },
          {
            min: 1,
            max: 15,
            message: "长度在 1 到 15 个字符",
            trigger: "blur"
          }
        ],
        nodeDesc: [
          {
            required: true,
            message: "请输入节点描述",
            trigger: "blur"
          },
          {
            min: 1,
            max: 64,
            message: "长度在 1 到 64 个字符",
            trigger: "blur"
          }
        ],
        isSendEmail: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ],
        isSendSmsCreator: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ],

        problemStatusId: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    save(item) {
      let formData = new FormData();
      formData.append("id", item.id);
      formData.append("actualPrice", item.actualPrice);
      this.$api
          .goodsUpdate(formData)
          .then(res => {
            if (res.data.code == 200) {

              this.$message.success("修改成功");
              this.nodeList()

            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    saveSales(item) {
      let formData = new FormData();
      formData.append("id", item.id);
      formData.append("salesVolumeShow", item.salesVolumeShow);
      this.$api
          .goodsUpdate(formData)
          .then(res => {
            if (res.data.code == 200) {

              this.$message.success("修改成功");
              this.nodeList()

            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    edit(row, index) {
      row.iseditor = true
    },
    refresh() {
      this.nodeList();
    },
    // 搜索清空
    emptyFn(){
      this.input3 = ""
      this.onShelf = ""
      this.downShelf = ""
        this.onstartTime = "";
          this.onendTime = ""

          this.onstartTime = "";
          this.onendTime = "";
    },
    searchFn(){
      this.current = 1
      this.nodeList();
    },
    ifMohuHandler(e) {
      // console.log(e);
      this.ifMohu = false;
      this.searchData = [];
    },
         //获取上架时间函数
      getTimeBlock() {
        if (this.onShelf) {
          this.onstartTime = this.onShelf[0];
          this.onendTime = this.onShelf[1];
        } else {
          this.onstartTime = "";
          this.onendTime = "";
        }
      },
      //获取下架时间函数
      getTimeBlock2() {
        if (this.downShelf) {
          this.downstartTime = this.downShelf[0];
          this.downendTime = this.downShelf[1];
        } else {
          this.downstartTime = "";
          this.downendTime = "";
        }
      },
    // changex清空
    upOptionType() {
      this.nameinput = "";
    },
    handlePageChange(val) {
      this.current = val;
      this.nodeList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.nodeList();
    },
    isSendSmsCreator(item) {
      // console.log(item);
    },

    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].typeName) {
              depth++;
              childrenEach(childrenData[j].typeName, depth);
            }
          }
        }

        return returnArr;
      }
      return childrenEach(treeData, depth);
    },

    deleteProductD(data) {
      this.delDialogVisible = true;
      this.productId = data;
    },
    cancelDelete() {
      this.delDialogVisible = false;
      this.productId = "";
    },
    handleDelClose() {
      this.delDialogVisible = false;
      this.deleteProduct(this.productId);
    },
         //批量选中
      getIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;

      },
      //全部选中
      getAllIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;

      },
    deleteProduct(data) {

      this.$api
        .GoodsDelete(data)
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("删除商品成功!");
            this.nodeList();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //新增产品
    addProduct() {
      this.dialogVisible = false;
      this.$api
        .addProduct(this.product)
        .then(res => {
          this.form.nodeName = "";
          this.form.nodeDesc = "";
          this.form.isSendEmail = "";
          this.form.isSendSmsCreator = "";
          this.form.problemStatusId = "";
          //查询产品列表
          this.nodeList();
        })
        .catch(error => {
          console.log(error);
        });
    },
    nodeList() {
      //查询设备列表
      console.log(this.onShelf)
      this.$api
        .listGoods({
          page: {
            current: this.current,
            size: this.pageSize
          },
          queryDto:{
            name:this.input3,
            shelfTimeStart:this.onShelf == null ? "" :this.onShelf[0],
            shelfTimeStop:this.onShelf == null ? "" :this.onShelf[1],
            offShelfTimeStart:this.downShelf == null ? "" :this.downShelf[0],
            offShelfTimeStop:this.downShelf == null ? "" :this.downShelf[1],
          }
        })
        .then(res => {
          for (let index = 0; index < res.data.data.records.length; index++) {
            res.data.data.records[index].iseditor = false

          }
          this.tableData = res.data.data.records;

          this.total = res.data.data.total;
            this.currentPage = parseInt(this.current)
        })
        .catch(error => {
          console.log(error);
        });
    },
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    showAddProductDialog() {
      this.$router.push("/addGood");
    },
    goodsUpdate(data) {

       this.$router.push({
        path: `/goodsUpdate`,
        query: {
            data: data
        }

      });
    },
    goEvaluation(data) {

      this.$router.push({
        path: `/evaluation`,
        query: {
          data: data
        }

      });
    },
    // 新增账号接口
    submitForm(formName) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.dialogVisible = false;
          this.$api.nodeListAdd(this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success("添加节点成功!");
              this.nodeList();
            }
          });
        } else {
          return false;
        }
      });
    },
  // 导出
    goodsExport(){
      this.$api
          .GoodsExport({
            query: {
              ids: this.ids,
            name:this.input3,
            shelfTimeStart:this.onShelf == null ? "" :this.onShelf[0],
            shelfTimeStop:this.onShelf == null ? "" :this.onShelf[1],
            offShelfTimeStart:this.downShelf == null ? "" :this.downShelf[0],
            offShelfTimeStop:this.downShelf == null ? "" :this.downShelf[1],
            },
            properties: [
              "name",
              "goodsNo",
              "oriPrice",
              "stock",
              "exchangeAmount",
              "shelfTime",
              "offShelfTime",
              "createTime",
            ],
            titles: [
              "商品名称",
              "商品编码",
              "商品原价",
              "商品库存",
              "已兑换数量",
              "上架时间",
                 "下架时间",
              "创建时间",

            ]
          })
          .then(res => {
            // console.log(res);
             this.download(res.data);
          })
          .catch(error => {});
    },
       //导出函数
    download(data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/octet-binary"
        })
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "excel.xls");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },

  },
  created() {
    //查询产品列表
    // this.nodeList();
  },
     beforeRouteEnter(to, from, next) {
      // console.log(from.path, '组件独享守卫beforeRouteEnter第二个参数');
      next(vm => {
        if (from.path == '/goodsUpdate') {
          vm.current = sessionStorage.getItem("mallcurrent")
          vm.input3 = sessionStorage.getItem("mallinput3")
          vm.onShelf = sessionStorage.getItem("mallonShelf").split(',')
          vm.downShelf = sessionStorage.getItem("malldownShelf").split(',')

          vm.nodeList();
        } else {
          vm.current = 1
           vm.input3  = ''
           vm.onShelf  = ''
           vm.downShelf  = ''
          vm.nodeList();
        }

      });
    },

    beforeRouteLeave(to, from, next) {
      // 导航离开该组件的对应路由时调用
      // 可以访问组件实例 `this`
      // console.log(this, 'beforeRouteLeave'); //当前组件实例
      // console.log(to.path, '组件独享守卫beforeRouteLeave第一个参数');
      if (to.path == '/goodsUpdate') {
        sessionStorage.setItem("mallcurrent", this.current);
        sessionStorage.setItem("mallinput3", this.input3);
        sessionStorage.setItem("mallonShelf", this.onShelf);
        sessionStorage.setItem("malldownShelf", this.downShelf);


      } else {
        sessionStorage.removeItem("mallcurrent");
        sessionStorage.removeItem("mallinput3");
        sessionStorage.removeItem("mallonShelf");
        sessionStorage.removeItem("malldownShelf");

      }

      next();
    }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}
 .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;

    .searchBut {
      float: right;
      margin-left: 20px;
    }
  }
.product-content {
  height: 6rem;

  .product-info-item {
    float: left;
    margin-right: 4rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 13rem;
    }
  }
}

.container-el-row {
  margin-top: 0px;
}

.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;

  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15px;
  }

  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}

.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;

  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
 .searchClass{
  //  border: 1px solid red;
   width: 100%;
     background-color: #f4f6fa;
    //  position: relative;
   span{
     font-size: 0.8rem;
     color: rgba(17, 17, 17, 0.87);
   }
   .el-button{
    float: right;
     margin-bottom: 20px;
     margin-left: 10px;
      margin-top: 20px;

    //  left:92.5%;
   }
  .select-searchClass{
      // margin-left:10%;
  }

 }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.item {
  margin: 4px;
  cursor: pointer;
}

.right .el-tooltip__popper {
  padding: 8px 10px;
  cursor: pointer;
}
</style>
