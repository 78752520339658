<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除节点吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDelete">取 消</el-button>
        <el-button type="primary" @click="handleDelClose">确 定</el-button>
      </span>
    </el-dialog>
    <div class="select">
      <span class="title">节点列表</span>
      <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新</el-button>
      <el-button type="text" size="small" icon="el-icon-plus" class="select-button" @click="showAddProductDialog">新增
      </el-button>
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个节点
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <!-- <el-table-column prop="id" label="节点编号" align="center"></el-table-column> -->
        <el-table-column prop="nodeName" label="节点标题" align="center"></el-table-column>
        <el-table-column prop="nodeDesc" label="节点描述" align="center"></el-table-column>
        <el-table-column prop="emailEnum" label="是否需要发送邮件" align="center"></el-table-column>
        <el-table-column prop="smsEnum" label="是否需要发送短信" align="center"></el-table-column>
        <el-table-column prop="problemStatus" label="节点问题状态" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="productDetail(scope.row)" type="text" size="small" icon="el-icon-info">修改</el-button>
            <el-button @click="deleteProductD(scope.row.id)" type="text" size="small" icon="el-icon-delete">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
        layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
      </el-pagination>
    </div>
    <el-dialog title="新增节点" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="节点标题" :label-width="formLabelWidth" prop="nodeName">
          <el-input v-model="form.nodeName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="节点描述" :label-width="formLabelWidth" prop="nodeDesc">
          <el-input v-model="form.nodeDesc" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否发送邮件" :validate-on-rule-change="false" :label-width="formLabelWidth" prop="isSendEmail">
          <el-select v-model="form.isSendEmail" filterable placeholder="是否需要发送邮件" size="small">
            <el-option v-for="item in emileList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否发送短信(创建者)" :validate-on-rule-change="false" :label-width="formLabelWidth"
          prop="isSendSmsCreator">
          <el-select v-model="form.isSendSmsCreator" filterable placeholder="是否需要发送短信" size="small">
            <el-option v-for="item in smsList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="节点问题状态" :label-width="formLabelWidth" prop="problemStatusId">
          <el-select v-model="form.problemStatusId" filterable placeholder="请选择问题状态" size="small">
            <el-option v-for="item in protocols" :key="item.id" :label="item.status" :value="item.id"></el-option>
          </el-select>

          <el-tooltip class="item" effect="dark" content="节点问题状态可在问题状态页面进行新增配置" placement="right-start">
            <i class="el-icon-info" style="display:inline-black;margin-left:10px"></i>
          </el-tooltip>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="
      修改节点" :visible.sync="modifydialogVisible" :lock-scroll="false" :getCheckedNodes="true">
      <el-form :model="formModify" :rules="rulesModify" ref="formModify">
        <el-form-item label="是否发送邮件" :label-width="formLabelWidth" prop="isSendEmail">
          <el-select  @change="$forceUpdate()" v-model="formModify.isSendEmail" filterable placeholder="是否需要发送邮件"
            size="small">
            <el-option v-for="item in emileList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否发送短信(创建者)" :label-width="formLabelWidth" prop="isSendSmsCreator">
          <el-select v-model="formModify.isSendSmsCreator" @change="$forceUpdate()"
            filterable placeholder="是否需要发送短信" size="small">
            <el-option v-for="item in smsList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifydialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFormUpdate('formModify')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
  import Bus from "../../../components/bus";
  export default {
    name: "nodeList",
    data() {
      return {
        productDel: false,
        productAdd: false,
        form: {
          nodeName: "",
          isSendEmail: "",
          nodeDesc: "",
          problemStatusId: "",
          isSendSmsCreator: "",
        },
        formModify: {
          isSendEmail: "",
          isSendSmsCreator: "",
        },
        visible: false,
        total: 0,
        tableData: [],
        emileList: [{
            value: "1",
            label: "是"
          },
          {
            value: "0",
            label: "否"
          },
        ], //搜索下拉选择
        smsList: [{
            value: "1",
            label: "发送"
          },
          {
            value: "0",
            label: "不发送"
          },
        ], //是否发送短信
        chooseOnlineStatus: "", //搜索选择
        sysUserlist: [], //账号所属类型
        newSsUserlist: {
          value: "id",
          label: "typeName",
          children: "childs",
          expandTrigger: "hover"
          // emitPath: false
        },
        checked: false,
        //新增账号弹出对话框标记
        dialogVisible: false,
        //修改账号弹出对话框标记
        modifydialogVisible: false,
        //节点问题状态
        protocols: [],

        //产品品类列表
        categories: [],

        value: "",
        input: "",
        current: 1,
        pageSize: 10,
        delDialogVisible: false,
        productId: "",
        indexId: "", //反显id
        nameinput: "", //搜索输入
        indexIdlist: "", //反显列表
        formLabelWidth: "120px",
        rulesModify: {
          isSendEmail: [{
            required: true,
            message: "请选择",
            trigger: "change"
          }],
          isSendSmsCreator: [{
            required: true,
            message: "请选择",
            trigger: "change"
          }],
        },
        rules: {
          nodeName: [{
              required: true,
              message: "请输入节点标题",
              trigger: "blur"
            },
            {
              min: 1,
              max: 15,
              message: "长度在 1 到 15 个字符",
              trigger: "blur"
            }
          ],
          nodeDesc: [{
              required: true,
              message: "请输入节点描述",
              trigger: "blur"
            },
            {
              min: 1,
              max: 64,
              message: "长度在 1 到 64 个字符",
              trigger: "blur"
            }
          ],
          isSendEmail: [{
            required: true,
            message: "请选择",
            trigger: "change"
          }],
          isSendSmsCreator: [{
            required: true,
            message: "请选择",
            trigger: "change"
          }],

          problemStatusId: [{
            required: true,
            message: "请选择",
            trigger: "change"
          }]
        }

      };
    },
    methods: {
      refresh() {
        this.nodeList();
      },
      ifMohuHandler(e) {
        // console.log(e);
        this.ifMohu = false;
        this.searchData = [];
      },
      // changex清空
      upOptionType() {
        this.nameinput = "";
      },
      handlePageChange(val) {

        this.current = val;
        this.nodeList();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.nodeList();
      },
      isSendSmsCreator(item) {
        console.log(item)
      },
      //修改节点
      productDetail(data) {
        this.formModify = {};
        this.indexId = [];
        sessionStorage.setItem("id", data);
        this.modifydialogVisible = true;
        console.log(data)
        this.formModify.id = data.id
        if (data.emailEnum == '否') {
          this.formModify.isSendEmail = "0"
        } else {
          this.formModify.isSendEmail = "1"
        }
        if (data.smsEnum == '发送') {
          this.formModify.isSendSmsCreator = "1"
        } else {
          this.formModify.isSendSmsCreator = "0"
        }


      },
      changeDetSelect(key, treeData) {
        let arr = []; // 在递归时操作的数组
        let returnArr = []; // 存放结果的数组
        let depth = 0; // 定义全局层级
        // 定义递归函数
        function childrenEach(childrenData, depthN) {
          for (var j = 0; j < childrenData.length; j++) {
            depth = depthN; // 将执行的层级赋值 到 全局层级
            arr[depthN] = childrenData[j].id;
            if (childrenData[j].id == key) {
              returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
              break;
            } else {
              if (childrenData[j].typeName) {
                depth++;
                childrenEach(childrenData[j].typeName, depth);
              }
            }
          }

          return returnArr;
        }
        return childrenEach(treeData, depth);
      },

      deleteProductD(data) {
        this.delDialogVisible = true;
        this.productId = data;
      },
      cancelDelete() {
        this.delDialogVisible = false;
        this.productId = "";
      },
      handleDelClose() {
        this.delDialogVisible = false;
        this.deleteProduct(this.productId);
      },
      deleteProduct(data) {
        // console.log("删除节点" + data);
        this.$api
          .nodeListDelete(data)
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("删除节点成功!");
              this.nodeList();
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      //新增产品
      addProduct() {
        this.dialogVisible = false;
        this.$api
          .addProduct(this.product)
          .then(res => {
            this.form.nodeName = "";
            this.form.nodeDesc = "";
            this.form.isSendEmail = "";
            this.form.isSendSmsCreator = "";
            this.form.problemStatusId = "";
            //查询产品列表
            this.nodeList();
          })
          .catch(error => {
            console.log(error);
          });
      },
      nodeList() {
        //查询设备列表
        this.$api
          .nodeList({
            page: {
              current: this.current,
              size: this.pageSize
            }

          })
          .then(res => {
            this.tableData = res.data.data.records;

            this.total = res.data.data.total;
            let cloudList = JSON.parse(sessionStorage.getItem("cloudList"));
            for (let i = 0; i < cloudList.length; i++) {
              if (
                this.$store.state.localList.localList.productDel == cloudList[i]
              ) {
                this.productDel = true;
              }
              if (
                this.$store.state.localList.localList.productAdd == cloudList[i]
              ) {
                this.productAdd = true;
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      //重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      showAddProductDialog() {
        this.dialogVisible = true;
        this.form.nodeName = "";
        this.form.nodeDesc = "";
        this.form.isSendEmail = "";
        this.form.isSendSmsCreator = "";
        this.form.problemStatusId = "";
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
          this.$refs["form"].resetFields();
        });
      },
      // 新增账号接口
      submitForm(formName) {
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.dialogVisible = false;
            this.$api.nodeListAdd(this.form).then(res => {
              if (res.data.code == 200) {
                this.$message.success("添加节点成功!");
                this.nodeList();
              }
            });
          } else {
            return false;
          }
        });
      },
      // 修改账号接口
      submitFormUpdate(formName) {
        this.$refs["formModify"].validate(valid => {
          if (valid) {

            this.$api.nodeListUpdate(this.formModify).then(res => {
              if (res.data.code == 200) {
                this.$message.success("修改节点成功!");
                console.log(res.data)
                this.modifydialogVisible = false;
                this.nodeList();

                this.formModify.isSendEmail = "";
                this.formModify.isSendSmsCreator = "";

                this.$nextTick(() => {
                  this.$refs["formModify"].clearValidate();
                  this.$refs["formModify"].resetFields();
                });

              }
            });
          } else {
            return false;
          }
        });
      },
      // 账号列表新增平台账号下拉框接口
      showaccountListSelector() {
        this.$api
          .questionStatus({
            page: {
              current: this.current,
              size: this.pageSize
            }
          })
          .then(res => {

            this.protocols = res.data.data.records;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    created() {
      //查询产品列表
      this.nodeList();
      this.showaccountListSelector();
    }
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);

  .el-row {
    margin: 1rem 3rem;
    line-height: 0;
    min-width: 900px;

    .platform-toolbar {
      position: relative;
      margin: 0;
      border: 1px solid #d9d9d9;

      .title-info {
        height: 46px;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      .el-col {
        padding: 0 3rem;

        .toolbar-right {
          float: right;
          margin-top: 7px;

          &.export {
            margin-left: 1rem;

            .toolbar-btn {
              color: @toolBarFontColor;
              font-size: 0.9rem;

              &.left {
                margin-left: 1.5rem;
              }

              &.right {
                margin-right: 0;
              }
            }
          }
        }
      }

      .title-info {
        float: left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .result-info-wrapper {
      height: 1rem;
      margin: 1rem 0 0.5rem 0;
      position: relative;

      .setting-panel {
        position: absolute;
        float: right;
        top: 2rem;
        width: 200px;
        height: 200px;
        background: red;
        z-index: 1000;
        border-radius: 3px;
      }

      .result-info {
        height: 1rem;
        line-height: 1rem;
        font-size: 0.875rem;
        float: left;
        color: #898989;

        span {
          color: #292929;
          padding: 0 0.1rem;
        }
      }

      .data-setting {
        font-size: 0.875rem;
        color: #2673bb;
        float: right;

        i {
          padding-right: 0.2rem;
        }

        cursor: pointer;
      }
    }

    .el-table td,
    .el-table th {
      padding: 0 !important;
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .product-content {
    height: 6rem;

    .product-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .container-el-row {
    margin-top: 0px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15px;
    }

    .select-input-right-input {
      float: right;
      margin-top: 7px;
    }
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }


  .item {
    margin: 4px;
    cursor: pointer;
  }


  .right .el-tooltip__popper {
    padding: 8px 10px;
    cursor: pointer;
  }
</style>