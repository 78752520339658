<template>
  <div class="outting">
    <div class="select">
      <span class="title">添加角色</span>
      <el-button type="text" size="small" icon="el-icon-back" @click="goBackHandler">返回角色列表</el-button>
    </div>
    <div class="container">
      <div class="left">
        <div class="left-title">
          <span class="left-title-text">角色信息</span>
        </div>
        <div class="left-container">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-form-item label="角色名称" prop="name" class="first-item">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="角色介绍" prop="remark">
              <el-input v-model="ruleForm.remark"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">立即创建</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="right">
        <div class="right-title">
          <span class="right-title-text">权限</span>
        </div>
        <div class="right-container">
          <el-tree
            ref="tree"
            :data="dataTree"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            @check="getCheckNode"
            @check-change="getCheckChange"
            :default-expanded-keys="defaultDisplayId"
          ></el-tree>

          <!-- 右边角色创建结束 -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Storage from "../../../utils/Storage";
export default {
  name: "AddRole",

  data() {
    return {
      defaultDisplayId: [],
      ifAdd: true,
      checkNodeList: [],
      activeName: "first",
      editableTabs: [],
      tabindex: "0",
      dataTree: [],
      defaultProps: {
        children: "children",
        label: "permissionName",
        id: "id"
      },
      ruleForm: {
        name: "",
        remark: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur"
          }
        ],
        remark: [
          {
            required: true,
            message: "请输入角色介绍",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    var token = this.$store.state.userinfo.token;
    this.$api.permissionList(token).then(res => {
      // console.log(res.data.data)
      var oldEditableTabsOne = res.data.data;
      var keyMap = {
        permissionList: "children",
        platformId: "id",
        platformName: "permissionName"
      };
      this.defaultDisplayId = [];
      oldEditableTabsOne.forEach(element => {
        console.log(element.platformId);
        this.defaultDisplayId.push(element.platformId);
      });
      console.log(this.defaultDisplayId);
      for (var i = 0; i < oldEditableTabsOne.length; i++) {
        var obj = oldEditableTabsOne[i];
        for (var key in obj) {
          var newKey = keyMap[key];
          if (newKey) {
            obj[newKey] = obj[key];
            delete obj[key];
          }
        }
      }
      this.arr = [];
      for (var i = 0; i < oldEditableTabsOne.length; i++) {
        oldEditableTabsOne[i].children = Storage.toTreePower(
          oldEditableTabsOne[i].children
        );
        this.arr.push(oldEditableTabsOne[i].id);
      }
      this.dataTree = oldEditableTabsOne;
    });
  },
  methods: {
    goBackHandler() {
      this.$router.replace("/sysrole");
    },
    //成功消息提示
    open2() {
      this.$message({
        message: "角色创建成功",
        type: "success"
      });
    },
    //选择传递的节点
    getCheckNode(obj1, obj2) {
      console.log(obj1, obj2);

      this.checkNodeList = obj2.checkedKeys.concat(obj2.halfCheckedKeys);

      for (var i = 0; i < this.checkNodeList.length; i++) {
        if (this.arr.indexOf(this.checkNodeList[i]) > -1) {
          this.checkNodeList.splice(i, 1);
        }
        if(this.checkNodeList[i]==10000){
           this.checkNodeList.splice(i, 1);
        }
      }
    },
    //判断点击节点是否被选中
    getCheckChange(obj, ifCheck, ifCheckChild) {
      // console.log(obj,ifCheck,ifCheckChild)
    },
    submitForm() {
      console.log(this.checkNodeList);

      var token = this.$store.state.userinfo.token;

      this.$api
        .addRole(
          {
            permissions: this.checkNodeList,
            remark: this.ruleForm.remark,
            roleName: this.ruleForm.name
          },
          token
        )
        .then(res => {
          // console.log(res.data);
          if (res.data.code == 200) {
            (this.checkNodeList = []), (this.ruleForm.remark = "");
            this.ruleForm.name = "";
            this.$refs.tree.setCheckedKeys([]);
            this.open2();
            this.$router.replace("/sysrole");
          } else {
			var err=res.data.message;
            // (this.checkNodeList = []), (this.ruleForm.remark = "");
            // this.ruleForm.name = "";
            // this.$refs.tree.setCheckedKeys([]);
              this.$message.error(err);

          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: #111d;

.outting {
  height: 100%;
  background-color: #fafafa;
.select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    button {
      color: @toolBarFontColor;
      font-size: 0.8rem;
      float: right;
      margin-top: 7px;
    }
  }

  .container {
    padding: 0 3rem;
    overflow: hidden;
    height: 80%;
    margin-top: 20px;

    .left {
      width: 49.5%;
      background-color: #ffffff;
      float: left;

      .left-title {
        width: 100%;
        height: 36px;
        border-bottom: 1px solid #d9d9d9;

        span {
          display: inline-block;
          margin-top: 7px;
          font-weight: bold;
          font-size: 0.8rem;
          margin-left: 1rem;
        }
      }

      .left-container {
        margin-top: 1rem;
        padding: 0 1rem;
      }
    }

    .right {
      width: 49.5%;
      margin-left: 1%;
      background-color: #ffffff;
      float: left;

      .right-title {
        width: 100%;
        height: 36px;
        border-bottom: 1px solid #d9d9d9;

        span {
          display: inline-block;
          margin-top: 7px;
          font-weight: bold;
          font-size: 0.8rem;
          margin-left: 1rem;
        }
      }
    }

    .right-container {
      margin-top: 1rem;
      padding: 0 0.5rem;
    }
  }
}
</style>
