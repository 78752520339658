<template>
  <div class="outting">
    <div class="select">
      <span class="title">设备扫码</span>
      <el-button type="text" size="small" icon="el-icon-upload2" class="select-button" @click="outHandler">导出
      </el-button>
    </div>
    <div class="rate-vv">

      <div style="width:100%;overflow: hidden;margin-top:1rem;">
        <div style="float:left;width:18%;">
          <div class="grid-content bg-purple-dark">
            <span style="font-size:.8rem;">MAC号查询</span>
            <div style="margin-top:5px;">
              <el-input v-model="deviceMac" clearable prefix-icon="el-icon-search" placeholder="请输入mac号" size="small"></el-input>
            </div>
          </div>
        </div>
        <div style="float:left;width:32%;margin-left:2%">
          <div class="grid-content bg-purple-dark">
            <span style="font-size:.8rem;">时间查询</span>
            <div style="margin-top:5px;">
              <el-date-picker style="width:100%;" v-model="value" range-separator="~" start-placeholder="开始日期"
                end-placeholder="结束日期" size="small" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00','23:59:59']" type="datetimerange" @change="getTimeBlock"></el-date-picker>
            </div>
          </div>
        </div>
        <!-- <div
          style="width:40%;height:5rem;margin-left:5%;float:left;display: flex;align-items: center;"
        >
          
        </div> -->
        <div style="float:left;margin-left:2%;width:10%;">
          <div class="grid-content bg-purple-dark">
            <span style="font-size:.8rem;">是否通过</span>
            <div style="margin-top:5px;">
              <el-select v-model="result" clearable size="small" @change="changeIfPass" style="width:100%;">
                <el-option v-for="item in ifPassOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div style="float:left;margin-left:2%;width:17%;">
          <div class="grid-content bg-purple-dark">
            <span style="font-size:.8rem;">产品名称</span>
            <div style="margin-top:5px;">
              <el-select v-model="productName" clearable size="small" @change="changeProduct" style="width:100%;">
                <el-option v-for="item in productNameOptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div style="float:left;margin-left:2%;width:15%;">
          <div class="grid-content bg-purple-dark">
            <span style="font-size:.8rem;">腾讯设备名称</span>
            <div style="margin-top:5px;">
              <el-input v-model="deviceName" prefix-icon="el-icon-search" placeholder="请输入" size="small"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow:hidden;margin-top:1rem;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="imeiSearch">搜索</el-button>
            </div>
            <div class="emptyBut">
              <el-button size="small" @click="refash">清空</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-vv">
      <span style="font-size:14px;">共有{{total}}条数据</span>
      <el-table :data="tableData" style="width: 100%" stripe size="small"
        :header-cell-style="{'background-color': '#eeeeee'}" @select="getChildIds" @select-all="getAllChildIds">
        <el-table-column type="selection" min-width="1"></el-table-column>
        <el-table-column type="index" width="50" label="序号" align="center" fixed></el-table-column>
        <el-table-column prop="deviceMac" label="MAC" min-width="1" fixed align="center">
          <!-- <template slot-scope="{row}">{{ row.deviceMac || '暂无数据' }}</template> -->
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.productName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="deviceName" label="腾讯设备名称" min-width="1" fixed align="center"></el-table-column>
        <el-table-column prop="psk" label="腾讯psk" min-width="1" fixed align="center"></el-table-column>
        <el-table-column prop="tencentProductId" label="腾讯产品id" min-width="1" fixed align="center">

        </el-table-column>
        <el-table-column prop="result" label="检测结果" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.result ==0?'不通过':'通过' }}</template>
        </el-table-column>

        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" icon="el-icon-info" @click="handleDetail(scope.$index, scope.row)">查看详情
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination background layout="prev, pager, next" :total="total" class="pagin" @current-change="changeCurrent">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  export default {
    name: "wifiTestResult",
    data() {
      return {
        childIds: [],
        failRate: "",
        successRate: "",
        value: "",
        startTime: "",
        endTime: "",
        ifSearchMany: false,
        deviceMac: "",
        deviceName: "",
        result: "",
        productName: "",
        deviceId: "",
        tableData: [],
        ifPassOptions: [{
            value: 1,
            label: "通过"
          },
          {
            value: 0,
            label: "不通过"
          }
        ],
        productNameOptions: [],
        current: 1,
        total: 0
      };
    },
    methods: {
      getAllChildIds(selection) {
        this.childIds = [];
        selection.forEach(item => {
          this.childIds.push(item.id);
        });
        console.log(this.childIds);
      },
      //获得多选框的id数组
      getChildIds(selection, row) {
        this.childIds = [];
        selection.forEach(item => {
          this.childIds.push(item.id);
        });
        console.log(this.childIds);
      },
      //故障渲染函数
      stateFormatDetectorLossFault(row) {
        if (row.detectorLossFault == "true") {
          return "是";
        } else if (row.detectorLossFault == "false") {
          return "否";
        } else {
          return "暂无数据";
        }
      },
      //自检渲染函数
      stateFormatSelfChecking(row) {
        if (row.selfChecking == "true") {
          return "是";
        } else if (row.selfChecking == "false") {
          return "否";
        } else {
          return "暂无数据";
        }
      },
      //火警渲染函数
      stateFormatFireAlarm(row) {
        if (row.fireAlarm == "true") {
          return "是";
        } else if (row.fireAlarm == "false") {
          return "否";
        } else {
          return "暂无数据";
        }
      },
      //测试结果渲染函数
      stateFormatResult(row) {
        if (row.result == 0) {
          return "不通过";
        } else if (row.result == 1) {
          return "通过";
        } else {
          return "测试中";
        }
      },

      //导出函数
      outHandler() {
        // let  data = eval(this.tableData[0].testContent)

        //  let yy = [];
        //  let mm = [];
        // yy = data.split(",");
        // for (var i = 0; i < yy.length; i++) {
        //     eval("var c=" + yy[i]);
        //     mm.push(c);
        // } 
       
// this.tableData[0].testContent = this.tableData[0].testContent.replace('"', "").replace('"', "")
        // console.log( JSON.parse(this.tableData[0].testContent))

       

        
        this.$api
          .devTestResultExport({
            query: {

              productName: this.productName,
              deviceMac: this.deviceMac,
              deviceName: this.deviceName,
              result: this.result,
              startTime: this.startTime,
              endTime: this.endTime,

            },
            properties: [
              "deviceMac",
              "productName",
              "deviceName",
              "psk",
              "apStatus",
              "voltageValue",
              "temperatureValue",
              "rssiValue",
              "keyStatus",
              "voltagePercentage",
              "scmSoftwareVersion",
              "deviceSoftwareVersion",
              "deviceHardwareVersion",
              "deviceManufacturerId",
              "currentValue",
            ],
            titles: [
              "imei",
              "产品名称",
              "腾讯设备名称",
              "腾讯psk",
              "apStatus",
              "voltageValue",
              "temperatureValue",
              "rssiValue",
              "keyStatus",
              "voltagePercentage",
              "scmSoftwareVersion",
              "deviceSoftwareVersion",
              "deviceHardwareVersion",
              "deviceManufacturerId",
              "currentValue",
            ]
          })
          .then(res => {
            console.log(res);
            this.download(res.data);
          })
          .catch(error => {});

      },
      //时间选择器函数
      getTimeBlock() {
        if (this.value) {
          this.startTime = this.value[0];
          this.endTime = this.value[1];
        } else {
          this.startTime = null;
          this.endTime = null;
        }
      },
      //导出函数
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
      //搜索函数
      imeiSearch() {
        this.current = 1
        this.testResultListHandler();
        // this.testResult();
      },
      //重置搜索条件函数
      refash() {
        this.productName = "";
        this.deviceMac = "";
        this.deviceName = "";
        this.result = "";
        this.value = "";
        this.startTime = null;
        this.endTime = null;
      },
      //页面列表函数
      testResultListHandler() {
        this.$api
          .iotQcWifiList({
            page: {
              current: this.current,
              size: 10
            },
            pqcWifiDeviceQueryDto: {
              productName: this.productName,
              deviceMac: this.deviceMac,
              deviceName: this.deviceName,
              result: this.result,
              startTime: this.startTime,
              endTime: this.endTime,

            }
          })
          .then(res => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
      },
      //是否通过选择函数id
      changeIfPass(id) {
        this.result = id;
      },
      //产品名称的改变函数
      changeProduct(id) {
        console.log(id);
        let obj = {};
        obj = this.productNameOptions.find(item => {
          return item.id === id;
        });
        this.productName = obj.name;
      },
      //详情函数
      handleDetail(index, row) {
        console.log(row.testContent)
        // this.deviceId = row.deviceMac;
        // var obj = {
        //   page: {
        //     current: this.current,
        //     size: 10
        //   },
        //   query: {
        //     productName: this.productName,
        //     deviceMac: this.deviceMac,
        //     deviceName: this.deviceName,
        //     result: this.result,
        //     startTime: this.startTime,
        //     endTime: this.end
        //   }
        // };
        this.$router.push({
          name: "WifiTestResultInfo",
          params: {
            data: row.testContent
          }
        });
      },
      //分页函数
      changeCurrent(id) {
        this.current = id;
        this.testResultListHandler();
      },
      //通过率函数
      // testResult() {
      //   this.$api
      //     .testFateHandler({
      //       productName: this.productName,
      //       deviceMac: this.deviceMac,
      //       deviceName: this.deviceName,
      //       result: this.result,
      //       startTime: this.startTime,
      //       endTime: this.endTime
      //     })
      //     .then(res => {
      //       this.failRate = res.data.data.failRate;
      //       this.successRate = res.data.data.successRate;
      //     });
      // }
    },
    created() {
      console.log(this.$route.params.queryCon);
      if (this.$route.params.queryCon) {
        this.current = this.$route.params.queryCon.page.current;
        this.productName = this.$route.params.queryCon.query.productName;
        this.deviceMac = this.$route.params.queryCon.query.deviceMac;
        this.deviceName = this.$route.params.queryCon.query.deviceName;
        this.result = this.$route.params.queryCon.query.result;
        this.startTime = this.$route.params.queryCon.query.startTime;
        this.endTime = this.$route.params.queryCon.query.endTime;
        this.value = [this.startTime, this.endTime];
        this.testResultListHandler();
        // this.testResult();
        this.$api
          .productListForPullUrl({
            page: {
              current: 1,
              size: 1000
            }
          })
          .then(res => {
            this.productNameOptions = res.data.data;
          })
          .catch(error => {});
      } else {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
        this.value = [start, end];
        this.endTime = end.Format("yyyy-MM-dd HH:mm:ss");
        this.startTime = this.value[0].Format("yyyy-MM-dd HH:mm:ss");
        this.testResultListHandler();
        // this.testResult();
        this.$api
          .productListForPullUrl({
            page: {
              current: 1,
              size: 1000
            }
          })
          .then(res => {
            this.productNameOptions = res.data.data;
          })
          .catch(error => {});
      }
    }
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .select {
      height: 46px;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      .select-button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
        margin-left: 15 /16rem;
      }
    }
  }

  .xieyiTable {
    padding: 0rem 3rem;
    margin-top: 1rem;
  }

  .rate-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;
    background-color: #f4f6fa;
    overflow: hidden;

    .rate-vv-v {
      float: left;
      width: 25%;
      height: 5rem;

      span {
        .outting .rate-vv .rate-vv-v i[data-v-02af7493] {
          font-size: 6rem;
          color: #409eff;
        }
      }
    }

    .searchBut {
      float: right;
      margin-left: 20px;
    }

    .emptyBut {
      float: right;
    }
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .pagin {
      margin-left: 30%;
      margin-top: 10px;
      float: right;
    }
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
</style>