<template>
    <div class="outting">
        
  <div class="left-card">
            <div class="left_tit">
                <h3 style=";margin-left:2rem">短信趋势统计
                </h3>
                <span style="font-size:12px;;margin-left:2rem">时间 ：</span>
              <el-date-picker style="width: 22.25rem;;margin-right:2rem;margin-top:10px" clearable
                    v-model="msmTime" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                    size="mini" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00','23:59:59']" type="datetimerange"></el-date-picker>
                <el-button size="mini" type="primary" @click="stackedLineFn">搜索</el-button>
            </div>
            <div class="charts" id="stackedLine"></div>
        </div>
   <div class="left-card">
        <div class="left_tit">
                <h3 style=";margin-left:2rem">语音趋势统计
                </h3>
                <span style="font-size:12px;;margin-left:2rem">时间 ：</span>
              <el-date-picker style="width: 22.25rem;;margin-right:2rem;margin-top:10px" clearable
                    v-model="voiceTime" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                    size="mini" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00','23:59:59']" type="datetimerange"></el-date-picker>
                <el-button size="mini" type="primary" @click="voiceTimeLineFn">搜索</el-button>
            </div>
            <div class="charts" id="voiceLine"></div>
        </div>
    </div>
</template>
<script>
import bus from "@/components/bus";
    export default {
        name: "statistics",
        data() {
            return {
                histogramTime: '',
                projectTime: "",
                month: "",
                msmTime:"",
                voiceTime:"",
                weekData: "22",
                echartsdate: "",
                yearweek: "",
                monthList: "",
                
            };
        },
        methods: {
         
            // 选择月份
            changeMonth() {

                this.echartsdate = this.month
                this.year = this.month.slice(0, 4);
                this.mon = this.month.slice(5, 7);

                this.fireTimeMonthStatistics();
            },
            weekDataFn() {
                this.weekDataNow = document.querySelector("#getWeekData").value
                this.yearweek = this.weekDataNow.slice(0, 4);
                this.week = this.weekDataNow.slice(5, 7);
                this.fireTimeWeekStatistics()
            },
            getTime(val) {
                //   获取时间并格式化
                // 获取前val天的时间
                var dt = new Date();
                val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + "").padStart(2, "0");
                const d = (dt.getDate() + "").padStart(2, "0");
                const hh = (dt.getHours() + "").padStart(2, "0");
                const mm = (dt.getMinutes() + "").padStart(2, "0");
                const ss = (dt.getSeconds() + "").padStart(2, "0");

                return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
                // return `${y}-${m}-${d}`;  // 获取的时间格式为 2021-04-09
                // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
                // return y + m + d  // 获取的时间格式为  20210409
            },
            getMonth(val) {
                //   获取时间并格式化
                // 获取前val天的时间
                var dt = new Date();
                val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + "").padStart(2, "0");
                const d = (dt.getDate() + "").padStart(2, "0");
                const hh = (dt.getHours() + "").padStart(2, "0");
                const mm = (dt.getMinutes() + "").padStart(2, "0");
                const ss = (dt.getSeconds() + "").padStart(2, "0");

                // return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
                return `${y},${m}`; // 获取的时间格式为 2021-04-09
                // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
                // return y + m + d  // 获取的时间格式为  20210409
            },
            getWeek() {
                let d1 = new Date()
                let d2 = new Date()
                d2.setMonth(0)
                d2.setDate(1)
                let rq = d1 - d2
                const y = d1.getFullYear();
                let s1 = Math.ceil(rq / (24 * 60 * 60 * 1000))
                let s2 = Math.ceil(s1 / 7)
                console.log('第' + s1 + '天,第' + s2 + '周', y)
                this.yearweek = y,
                    this.week = s2

            },

      
            voiceTimeLineFn(){
                        this.$api.analysisVoice({
                      startTime: this.voiceTime[0],
                        endTime: this.voiceTime[1]
                  }).then(res => {
             console.log(res.data.data);
                this.echarts6 = this.$echarts.init(document.getElementById("voiceLine"));
            const option = {
        title: {
                                text: '语音发送图',
                                left: 'center'
                            },
        tooltip: {
          trigger: 'axis',
        },
    
        legend: {
     
          tooltip: {
            show: true,
          },
          y: 'bottom',
          data: ['成功条数', '失败条数', '总条数'],
        },
        xAxis: { type: 'category', data: res.data.data.dateList },
        yAxis: { type: 'value', name: '数量', },
        series: [
              {
            name: '成功条数',
            data: res.data.data.smsStatisticsMap.successCount,
            type: 'line',
            itemStyle: { normal: { color: 'green', lineStyle: { color: 'green' } } },
          },
          {
            name: '失败条数',
            data: res.data.data.smsStatisticsMap.failCount,
            type: 'line',
            itemStyle: { normal: { color: 'red', lineStyle: { color: 'red' } } },
          },
        
          {
            name: '总条数',
            data: res.data.data.smsStatisticsMap.totalCount,
            type: 'line',
            itemStyle: { normal: { color: 'blue', lineStyle: { color: 'blue' } } },
          },
        ],
      }
       this.echarts6.setOption(option);
        });
            },
            // 短信折线图
            stackedLineFn(){
                  this.$api.analysisSms({
                      startTime: this.msmTime[0],
                        endTime: this.msmTime[1]
                  }).then(res => {
             console.log(res.data.data);
                this.echarts5 = this.$echarts.init(document.getElementById("stackedLine"));
            const option = {
        title: {
                                text: '短信发送图',
                                left: 'center'
                            },
        tooltip: {
          trigger: 'axis',
        },
        // grid: {
        //   left: '1%',
        //   right: '4%',
        //   bottom: '23%',
        //   containLabel: true,
        // },
        legend: {
        //   padding: 10,
          tooltip: {
            show: true,
          },
          y: 'bottom',
          data: ['成功条数', '失败条数', '总条数'],
        },
        xAxis: { type: 'category', data: res.data.data.dateList },
        yAxis: { type: 'value', name: '数量', },
        series: [
              {
            name: '成功条数',
            data: res.data.data.smsStatisticsMap.successCount,
            type: 'line',
            itemStyle: { normal: { color: 'green', lineStyle: { color: 'green' } } },
          },
          {
            name: '失败条数',
            data: res.data.data.smsStatisticsMap.failCount,
            type: 'line',
            itemStyle: { normal: { color: 'red', lineStyle: { color: 'red' } } },
          },
        
          {
            name: '总条数',
            data: res.data.data.smsStatisticsMap.totalCount,
            type: 'line',
            itemStyle: { normal: { color: 'blue', lineStyle: { color: 'blue' } } },
          },
        ],
      }
       this.echarts5.setOption(option);
        });
            }
        },
        created() {
          
            this.msmTime = [this.getTime(7), this.getTime(0)]
            this.voiceTime = [this.getTime(7), this.getTime(0)]

           
        },
        mounted() {

            this.getWeek()
            this.month = this.year + '-' + this.mon
           
            this.voiceTimeLineFn()

          
            this.stackedLineFn()

          
        
           

        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        // display: flex;
        text-align: center;
        // justify-content: space-around;
        background-color: #fafafa;

        .left-card,
        .right-card {
            width: 47%;
            display: inline-block;

            // margin-top: 1rem;
            // margin: 1rem auto;
            .left_tit {
                text-align: left;
                margin: 20px 20px;
            }

            .charts {
                width: 90%;
                height: 58vh;
                margin-top: 30px;
                margin-bottom: 3rem;
                min-width: 280px;
                min-height: 210px;
            }
        }




    }
</style>