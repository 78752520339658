var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outting"},[_c('div',{staticClass:"select"},[_c('span',{staticClass:"title"},[_vm._v("故障类型")]),_c('el-button',{staticClass:"addRoleButton",attrs:{"type":"text","size":"small","icon":"el-icon-plus"},on:{"click":_vm.addUserHandler}},[_vm._v("添加故障类型")])],1),_c('div',{staticClass:"guzhangTable"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":"","size":"small","header-cell-style":{'background-color': '#eeeeee'}}},[_c('el-table-column',{attrs:{"prop":"faultType","label":"故障类型","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.faultType || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"sysUserName","label":"用户名","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.sysUserName || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.createTime || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"更新时间","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.updateTime || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"min-width":"1","label":"操作","width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popconfirm',{attrs:{"title":"确定删除这个故障吗？"},on:{"onConfirm":function($event){return _vm.deleteDeviceFault()}}},[_c('el-button',{attrs:{"slot":"reference","type":"text","size":"small","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}},slot:"reference"},[_vm._v("删除")])],1)]}}])})],1),_c('el-pagination',{staticStyle:{"float":"right","margin-top":"10px"},attrs:{"background":"","layout":"prev, pager, next","total":_vm.total},on:{"current-change":_vm.changeCurrent}})],1),_c('el-dialog',{attrs:{"title":"增加设备故障类型","visible":_vm.dialogFormVisible,"lock-scroll":false,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"故障类型","label-width":_vm.formLabelWidth,"prop":"type"}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.type"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }