<template>
    <div class="fireBlanket">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click.native="backTo">
                    {{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"产品中心"}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color:#f28c02">
                        产品详情
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="major_service">
            <div class="item-title">
                <p> 灭火毯</p>
                <div>
                    <img src="../../../assets/login/mht.png" alt="" class="image">
                    <span class="text_list" style="margin-bottom:30px">
                        (WSH-MHT-01)
                    </span>
                </div>
                   <div class="level_subject">
                    概述
                </div>
                <div class="text_subject">
                  灭火毯或称消防被,灭火被，防火毯，消防毯，阻燃毯，逃生毯，是由玻璃纤维等材料经过特殊处理编织而成的织物，能起到个隔离热源及火焰的作用，可用于扑灭油锅或者披覆在身上逃生
                </div>
                <div class="level_subject">
                    适用范围
                </div>
                <div class="text_subject">
                   灭火毯主要用于企业，商场，船舶，骑车，民用建筑物等场合的一种简便的初始灭火工具。特别适用于家庭和饭店的厨房，宾馆，娱乐场所，加油站等一些容易着火的场所。防止火势蔓延以及防护逃生用
                </div>
                  <div class="level_subject">
                    主要性能特点
                </div>
                <div class="text_subject">
                  覆盖火源，阻隔空气，以达到灭火目的。<br>
                  不燃，耐高温(550℃)，质地柔软，光滑，紧密，且不刺激皮肤，对需远离热源体的人，物是一个理想的外保护层，并且非常容易包扎表明凹凸不平的物体，在无破损的情况下可重复使用。

                </div>
                 <div class="level_subject">
                 技术性能参数
                </div>
                <div class="text_subject">
                    <img src="../../../assets/login/fireBlanket2.png" alt="" class="image">
                </div>
           <div class='botom_tit'>   *如需获取产品3c证书、检验报告、说明书等，
               可扫描底部公众号二维码进入“吻胜智能生活”公众号获取</div>
            </div>

            <div>

            </div>
        </div>


    </div>
</template>
<script>
 import '../../../../static/css/global.css'
    export default {
        name: "fireBlanket",
        components: {},
        data() {
            return {

            };
        },
        mounted() {
            window.addEventListener('scroll', this.scrollToTop)
            const that = this
            let ispeed = Math.floor(-that.scrollTop / 5)
            document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        },
        created() {},
        methods: {
            scrollToTop() {
                const that = this
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                that.scrollTop = scrollTop
                that.btnFlag = false
            },
            backTo() {
                if (this.$route.query.crumbsTitle == '首页') {
                    let vm = this
                    vm.$router.replace({
                        path: `/`
                    });
                } else {
                    this.$router.replace({
                        path: "/productCenter"
                    });
                }
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTop)
        },
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1370px) {
        .fireBlanket {
               background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;
            .text_subject{
                 font-size: 1.05rem;
                 line-height: 1.8rem;
                //   padding-bottom: 3rem;
                    padding-top: 1.5rem;
            }
             .level_subject{
                font-size: 1.15rem; 
            }
        }
    }

    @media screen and (min-width: 1370px) {
        .fireBlanket {
            background-color: #efefef;
            overflow-x: auto;
            min-width: 1300px;
             .text_subject{
                 font-size: 1.25rem;
                 line-height: 2.5rem;
                //   padding-bottom: 0.5rem;
                    padding-top: 2rem;
            }
            .level_subject{
                font-size: 1.35rem; 
            }
        }
    }

    .breadcrumb {
        padding: 2rem 0 2rem 0;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;

        /deep/.el-breadcrumb {
            float: right;
        }

        /deep/.el-breadcrumb__inner:hover {
            color: #f28c02;
        }
    }
 .botom_tit{
      text-align: left;
      width: 60%;
       margin: 0 auto;
         padding-bottom:  4rem;
         padding-left:  2rem;
  }
    .major_service {
        width: 100%;
        padding-bottom: 20px;

        .item-title {
            padding-top: 2rem;
            text-align: center;
            // color: #333333;
            width: 100%;
            margin: 0 auto;

            .image {
                width: 20%;
                display: block;
                margin: 0 auto;
            }

            p {
                text-shadow: 1px 1px 1px #666;
                display: inline-block;
                color: #333333;
                padding: 0 50px;
                font-size: 1.5em;
            }

            .text_list {
                display: inline-block;
                transform: translateY(-2rem);
               font-size: 1rem; 
                color: #333333;
            }

           
            .level_subject {
                text-align: left;
                width: 60%;
                letter-spacing: 4px;
                margin: 0 auto;
            //    font-size: 1.35rem; 
                color: #333333;
                font-weight: 600;
            }

            .text_subject {
                display: inline-block;
            //  font-size: 1.25rem;
                
                text-align: justify;
                width: 60%;
              
                margin: 0 auto;
                // text-align: left;
                color: #333333;
               
                // border: #f28c02 1px solid;
         
                img {
                    width: 100%;

                }

            }

        }


    }
</style>