<template>
    <div class="mainContainer">
        <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
            <span>确定删除吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelDelete">取 消</el-button>
                <el-button type="primary" @click="handleDelClose">确 定</el-button>
            </span>
        </el-dialog>
        <div class="select">
            <span class="title">程序版本管理</span>
            <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新
            </el-button>
            <el-button type="text" size="small" icon="el-icon-plus" class="select-button" @click="showAddProductDialog">
                新增</el-button>
        </div>
        <div class="table-vv">
            <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
            </div>
            <el-table :data="tableData" stripe size="small" class="product-table"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                   <el-table-column prop="versionName" label="版本名称" align="center"></el-table-column>
                       <el-table-column prop="netOperator" label="运营商类型" align="center">
                      <template slot-scope="scope">
                        <el-select style="width:105px" v-model="scope.row.netOperator" v-show="scope.row.iseditor"
                            size="mini" placeholder="请选择">
                            <el-option label="移动" value="0"></el-option>
                            <el-option label="电信iot" value="1"></el-option>
                            <el-option label="联通" value="2"></el-option>
                            <el-option label="wifi" value="4"></el-option>
                            <el-option label="电信aep" value="5"></el-option>
                            <el-option label="直连" value="-1"></el-option>
                        </el-select>
                        <span v-show="!scope.row.iseditor">{{scope.row.netOperator == '0'?"移动":scope.row.netOperator == '1'?"电信iot":scope.row.netOperator == '2'?"联通":scope.row.netOperator == '4'?"wifi":scope.row.netOperator == '-1'?"直连":"电信aep"}}</span>
                    </template>
                </el-table-column>
                 <el-table-column prop="moduleManufacturers" label="模组厂商" align="center">
                     <template slot-scope="scope">
                        <el-select style="width:105px" v-model="scope.row.moduleManufacturers" v-show="scope.row.iseditor"
                            size="mini" placeholder="请选择">
                            <el-option label="coretek" value="02"></el-option>
                            <el-option label="奥兰迪" value="03"></el-option>
                            <el-option label="志连" value="04"></el-option>
                            <el-option label="wifi" value="05"></el-option>
                        </el-select>
                        <span v-show="!scope.row.iseditor">{{scope.row.moduleManufacturers == '02'?"coretek":scope.row.moduleManufacturers == '03'?"奥兰迪":scope.row.moduleManufacturers == '04'?"志连":"wifi"}}</span>
                    </template>

                </el-table-column>
                <el-table-column prop="initialVersion" label="初始版本" align="center"></el-table-column>
                  <el-table-column prop="targetVersion" label="目标版本" align="center"></el-table-column>

                <el-table-column prop="softwareType" label="软件类型" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.softwareType == '0'?"转接板":"探测器"}}</span>
                    </template>
                </el-table-column>
              <el-table-column prop="upgradeMethod" label="升级方式" align="center">
                       <template slot-scope="scope">
                        <el-select style="width:105px" v-model="scope.row.upgradeMethod" v-show="scope.row.iseditor"
                            size="mini" placeholder="请选择">
                            <el-option label="主动" value="0"></el-option>
                            <el-option label="手动" value="1"></el-option>
                        </el-select>
                        <span v-show="!scope.row.iseditor">{{scope.row.upgradeMethod  == '0'?"主动":"手动"}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="downloadUrl" label="下载地址" align="center" width="
                250"></el-table-column>
                <el-table-column prop="fileSize" label="文件大小" align="center"></el-table-column>

                <el-table-column prop="createTime" label="创建时间" :formatter="dateFormat" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <el-button @click="save(scope.row)" v-show="scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">修改
                        </el-button>
                        <el-button @click="edit(scope.row,scope)" v-show="!scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">编辑
                        </el-button>
                        <el-button @click="deleteProductD(scope.row.id)" type="text" size="small" icon="el-icon-delete">
                            删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
                layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
            </el-pagination>
        </div>
        <el-dialog title="新增程序版本" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="版本名称" :label-width="formLabelWidth" prop="versionName">
                    <el-input style="width:315px" v-model="form.versionName" autocomplete="off" placeholder="请填写版本名称">
                    </el-input>
                </el-form-item>
                <el-form-item label="模组厂商" :label-width="formLabelWidth" prop="moduleManufacturers">
                    <el-select style="width:315px" v-model="form.moduleManufacturers" placeholder="请选择模组厂商">
                        <el-option label="coretek" value="02"></el-option>
                        <el-option label="奥兰迪" value="03"></el-option>
                        <el-option label="志连" value="04"></el-option>
                        <el-option label="WiFi" value="05"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="运营商类型" :label-width="formLabelWidth" prop="netOperator">
                    <el-select style="width:315px" v-model="form.netOperator" placeholder="请选择运营商类型">
                        <el-option label="移动" value="0"></el-option>
                        <el-option label="电信iot" value="1"></el-option>
                        <el-option label="联通" value="2"></el-option>
                        <el-option label="wifi" value="4"></el-option>
                        <el-option label="电信aep" value="5"></el-option>
                       <el-option label="直连" value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="软件类型" :label-width="formLabelWidth" prop="softwareType">
                    <el-select style="width:315px" v-model="form.softwareType" placeholder="请选择软件类型">
                        <el-option label="转接板" value="0"></el-option>
                        <el-option label="探测器" value="1"></el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="升级方式" :label-width="formLabelWidth" prop="upgradeMethod">
                    <el-select style="width:315px" v-model="form.upgradeMethod" placeholder="请选择软件类型">
                        <el-option label="主动" value="0"></el-option>
                        <el-option label="手动" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="初始版本" :label-width="formLabelWidth" prop="initialVersion">
                    <el-input style="width:315px" v-model="form.initialVersion" autocomplete="off"
                        placeholder="请填写初始版本"></el-input>
                </el-form-item>

                <el-form-item label="目标版本" :label-width="formLabelWidth" prop="targetVersion">
                    <el-input style="width:315px" v-model="form.targetVersion" autocomplete="off"
                        placeholder="请填写目标版本"></el-input>
                </el-form-item>
                    <el-form-item label="上传" :label-width="formLabelWidth" prop="fileList">
                    <el-upload
                class="upload-demo"
                action
                :on-change="handleChange"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"

                :file-list="form.fileList"
                :limit="limitUpload"
                :auto-upload="false"
              >
                <el-button type="primary" round size="mini" class="input-button">导入文件</el-button>
                <!-- <span style="color: red">*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式 -->
              </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    export default {
        name: "programVersion",
        data() {
            return {
                productDel: false,
                productAdd: false,
                iseditor: false,
                  limitUpload: 1,
                form: {
                    versionName: "",
                    moduleManufacturers: "",
                    netOperator: "",
                    softwareType: "",
                    upgradeMethod: "",
                    initialVersion: "",
                    targetVersion: "",
                    fileList:[],
                },
                 rules: {
                    versionName: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }, ],
                    moduleManufacturers: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }, ],
                    netOperator: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }, ],
                    softwareType: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],
                    upgradeMethod: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],
                       initialVersion: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],
                       targetVersion: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],
                      fileList: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],

                },
                visible: false,
                total: 0,
                tableData: [],
                 fileList:[],
                chooseOnlineStatus: "", //搜索选择
                sysUserlist: [], //账号所属类型

                checked: false,
                //新增账号弹出对话框标记
                dialogVisible: false,
                //修改账号弹出对话框标记
                modifydialogVisible: false,

                //产品品类列表
                categories: [],

                value: "",
                input: "",
                current: 1,
                pageSize: 10,
                delDialogVisible: false,
                productId: "",
                indexId: "", //反显id
                nameinput: "", //搜索输入
                indexIdlist: "", //反显列表
                formLabelWidth: "150px",

            };
        },
        methods: {
              handleChange(file, fileList) {
     this.fileTemp = file.raw;
     this.form.fileList = fileList;
      if ( this.fileTemp ) {
       console.log( this.fileTemp)
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！"
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },
    //移除文件的操作方法
    handleRemove(file, fileList) {
       this.fileTemp = null;

    },

            refresh() {
                this.questionStatus();
            },
            dateFormat(row, column, cellValue, index) {
                // console.log(row, column, cellValue, index)
                const daterc = row[column.property]
                if (daterc != null) {
                    var date = new Date(daterc);
                    var year = date.getFullYear();
                    /* 在日期格式中，月份是从0开始，11结束，因此要加0
                     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                     * */
                    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
                    // 拼接
                    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
                }
            },


            handlePageChange(val) {

                this.current = val;
                this.questionStatus();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.questionStatus();
            },


            deleteProductD(data) {
                this.delDialogVisible = true;
                this.productId = data;
            },
            cancelDelete() {
                this.delDialogVisible = false;
                this.productId = "";
            },
            handleDelClose() {
                this.delDialogVisible = false;
                this.deleteProduct(this.productId);
            },
            // 修改
            edit(row, index) {
                // row.isEnable = row.isEnable == 0 ? '否' : '是'
              row.netOperator =  row.netOperator == '0'?"移动":row.netOperator == '1'?"电信iot":row.netOperator == '2'?"联通":row.netOperator == '4'?"wifi":row.netOperator == '-1'?"直连":"电信aep"
            row.moduleManufacturers =  row.moduleManufacturers == '02'?"coretek":row.moduleManufacturers == '03'?"奥兰迪":row.moduleManufacturers == '04'?"志连":"wifi"
        row.upgradeMethod  =  row.upgradeMethod  == '0'?"主动":"手动"
              row.iseditor = true
                console.log(row)
            },
            save(item) {
                console.log(item)
                if(item.upgradeMethod  == '主动' || item.upgradeMethod  == '手动'){
              item.upgradeMethod  =  item.upgradeMethod  == '主动'?"0":"1"
                }
                if(item.moduleManufacturers == 'coretek' ||item.moduleManufacturers == '奥兰迪'||item.moduleManufacturers == '志连'||item.moduleManufacturers == 'wifi'){
item.moduleManufacturers = item.moduleManufacturers == 'coretek'?'02':item.moduleManufacturers == '奥兰迪'?'03':item.moduleManufacturers == '志连'?'04':'05'
                }
                if(item.netOperator == '移动'||item.netOperator == '电信iot'||item.netOperator == '联通'||item.netOperator == 'wifi'||item.netOperator == '电信aep'||item.netOperator == '直连'){
item.netOperator = item.netOperator =='移动'?"0":item.netOperator =='电信iot'?"1":item.netOperator =='联通'?"2":item.netOperator =='wifi'?"4":item.netOperator =='直连'?"-1":'5'
                }

                //       item.moduleManufacturers =  item.moduleManufacturers == '02'?"coretek":item.moduleManufacturers == '03'?"奥兰迪":item.moduleManufacturers == '04'?"志连":"wifi"
                // item.netOperator =  item.netOperator == '0'?"移动":item.netOperator == '1'?"电信iot":item.netOperator == '2'?"联通":item.netOperator == '4'?"wifi":"电信aep"
                this.$api
                    .versionProgramUpdate({
                        moduleManufacturers : item.moduleManufacturers ,
                        upgradeMethod : item.upgradeMethod ,
                        netOperator : item.netOperator ,
                        id: item.id
                    })
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("修改成功");
                            this.questionStatus();
                            this.iseditor = false
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            deleteProduct(data) {

                this.$api
                    .versionProgramDelete(data)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功!");
                            this.questionStatus();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            //新增产品
            addProduct() {
                this.dialogVisible = false;
            },
            questionStatus() {
                //查询列表
                this.$api
                    .versionProgramList({
                        current: this.current,
                        size: 10
                    })
                    .then(res => {
                        console.log(res.data.data.records)
                          for (let index = 0; index < res.data.data.records.length; index++) {
                            res.data.data.records[index].iseditor = false
                        }
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;


                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            showAddProductDialog() {
                this.dialogVisible = true;
                this.form.categoryName = "";
                this.form.calculateUv = "";
                this.form.description = "";
                this.form.isEnable = "";
                this.form.requestMethod = "";
                this.form.behaviorSource = "";
                this.$nextTick(() => {
                    this.$refs["form"].clearValidate();
                });
            },
            // 新增程序接口
            submitForm(formName) {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                 let formData = new FormData();
                      formData.append("multipartFile", this.fileTemp);
                      formData.append("versionName", this.form.versionName);
                      formData.append("moduleManufacturers", this.form.moduleManufacturers);
                      formData.append("netOperator", this.form.netOperator);
                      formData.append("softwareType", this.form.softwareType);
                      formData.append("upgradeMethod", this.form.upgradeMethod);
                      formData.append("initialVersion", this.form.initialVersion);
                      formData.append("targetVersion", this.form.targetVersion);
// {
//                             versionName: this.form.versionName,
//                             moduleManufacturers: this.form.moduleManufacturers,
//                             netOperator: this.form.netOperator,
//                             softwareType: this.form.softwareType,
//                             upgradeMethod: this.form.upgradeMethod,
//                             initialVersion: this.form.initialVersion,
//                             targetVersion: this.form.targetVersion,
//                             multipartFile: this.fileTemp,
//                         }
                        this.dialogVisible = false;
                        this.$api.versionProgramAdd(formData).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("添加成功!");
                                this.questionStatus();
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },

            // 账号列表新增平台账号下拉框接口
            showaccountListSelector() {}
        },
        created() {
            //查询产品列表
            this.questionStatus();
            this.showaccountListSelector();
        }
    };
</script>
<style lang="less" scoped>
    @toolBarFontColor: rgba(17, 17, 17, 0.87);

    .el-row {
        margin: 1rem 3rem;
        line-height: 0;
        min-width: 900px;

        .platform-toolbar {
            position: relative;
            margin: 0;
            border: 1px solid #d9d9d9;

            .title-info {
                height: 46px;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .el-col {
                padding: 0 3rem;

                .toolbar-right {
                    float: right;
                    margin-top: 7px;

                    &.export {
                        margin-left: 1rem;

                        .toolbar-btn {
                            color: @toolBarFontColor;
                            font-size: 0.9rem;

                            &.left {
                                margin-left: 1.5rem;
                            }

                            &.right {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .title-info {
                float: left;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        .result-info-wrapper {
            height: 1rem;
            margin: 1rem 0 0.5rem 0;
            position: relative;

            .setting-panel {
                position: absolute;
                float: right;
                top: 2rem;
                width: 200px;
                height: 200px;
                background: red;
                z-index: 1000;
                border-radius: 3px;
            }

            .result-info {
                height: 1rem;
                line-height: 1rem;
                font-size: 0.875rem;
                float: left;
                color: #898989;

                span {
                    color: #292929;
                    padding: 0 0.1rem;
                }
            }

            .data-setting {
                font-size: 0.875rem;
                color: #2673bb;
                float: right;

                i {
                    padding-right: 0.2rem;
                }

                cursor: pointer;
            }
        }

        .el-table td,
        .el-table th {
            padding: 0 !important;
        }

        .el-pagination {
            margin: 1rem 0;
            float: right;
        }
    }

    .product-content {
        height: 6rem;

        .product-info-item {
            float: left;
            margin-right: 4rem;

            .label-info {
                margin-bottom: 0.5rem;

                label {
                    font-size: 0.75rem;

                    span {
                        color: red;
                        margin-right: 0.5rem;
                    }
                }
            }

            .el-input {
                width: 10rem;
            }

            .el-select {
                width: 13rem;
            }
        }
    }

    .container-el-row {
        margin-top: 0px;
    }

    .select {
        height: 46px;
        overflow: hidden;
        border-bottom: 1px solid #d9d9d9;
        padding: 0rem 3rem;

        .title {
            display: inline-block;
            line-height: 46px;
            font-weight: bold;
            font-size: 0.8rem;
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            margin-top: 7px;
            margin-left: 15px;
        }

        .select-input-right-input {
            float: right;
            margin-top: 7px;
        }
    }

    .table-vv {
        padding: 0rem 3rem;
        margin-top: 1rem;

        .result-info {
            height: 1rem;
            line-height: 1rem;
            font-size: 0.875rem;
            float: left;
            color: #898989;

            span {
                color: #292929;
                padding: 0 0.1rem;
            }
        }

        .el-pagination {
            margin: 1rem 0;
            float: right;
        }
    }
</style>
