<template>
    <div class="fireExtinguisher">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click.native="backTo">
                    {{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"产品中心"}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color:#f28c02">
                        产品详情
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="major_service">
            <div class="item-title">
                <p> 简易式水基型灭火器</p>
                <div>
                    <img src="../../../assets/login/mhqs.png" alt="" class="image">
                    <span class="text_list" style="margin-bottom:30px">
                        (MSWJ500/950)
                    </span>
                </div>
                   <div class="level_subject">
                    工作原理
                </div>
                <div class="text_subject">
                 灭火器灭火属物理灭火机理。药剂可在可燃物表明形成并扩展一层薄水膜，使可燃物与空气隔离，实现灭火。经雾化喷嘴，喷射出水雾，漫步火场并蒸发热量，迅速降低火场温度，同时降低燃烧区空气中氧的浓度，防止复燃。
                </div>
                <div class="level_subject">
                   用途及性能特点
                </div>
                <div class="text_subject">
                  简易式灭火器是近几年新兴的一种轻便式灭火器，产品结构简单，操作方便，所充装的水系灭火剂安全、环保、无毒，非常适合家庭或者车载使用。 <br>
                  产品小巧，易于携带；水基型药剂，产品环保，使用无危害。
                </div>
                  <div class="level_subject">
                   适用范围
                </div>
                <div class="text_subject">
                  该产品主要适用于家庭、办公、车载等场所。可扑灭的火灾有：  <br>
                  A类火灾：如棉、木、麻、纸等可燃固体火灾;<br>
                  B类火灾：如原油，重油，柴油，汽油等可燃液体，可融化固体火灾;<br>
                  E类带电设备火灾：如机房，变电站，电缆隧道，电缆井沟等场所带电设备火灾。<br>
                  F类烹饪火灾：如动植物油脂火灾。

                </div>
                <div class="level_subject">
                 技术性能参数
                </div>
                <div class="text_subject">
                    <img src="../../../assets/login/fireExtinguisher3.png" alt="" class="image">
                </div>
<div class='botom_tit'>   *如需获取产品3c证书、检验报告、说明书等，
               可扫描底部公众号二维码进入“吻胜智能生活”公众号获取</div>
            </div>

            <div>

            </div>
        </div>


    </div>
</template>
<script>
  import '../../../../static/css/global.css'
    export default {
        name: "fireExtinguisher",
        components: {},
        data() {
            return {

            };
        },
        mounted() {
            window.addEventListener('scroll', this.scrollToTop)
            const that = this
            let ispeed = Math.floor(-that.scrollTop / 5)
            document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        },
        created() {},
        methods: {
            scrollToTop() {
                const that = this
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                that.scrollTop = scrollTop
                that.btnFlag = false
            },
            backTo() {
                if (this.$route.query.crumbsTitle == '首页') {
                    let vm = this
                    vm.$router.replace({
                        path: `/`
                    });
                } else {
                    this.$router.replace({
                        path: "/productCenter"
                    });
                }
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTop)
        },
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1370px) {
        .fireExtinguisher {
               background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;
            .text_subject{
                 font-size: 1.05rem;
                 line-height: 1.8rem;
                //   padding-bottom: 3rem;
                    padding-top: 1.5rem;
            }
             .level_subject{
                font-size: 1.15rem; 
            }
        }
    }

    @media screen and (min-width: 1370px) {
        .fireExtinguisher {
          background-color: #efefef;
            overflow-x: auto;
            min-width: 1300px;
             .text_subject{
                 font-size: 1.25rem;
                 line-height: 2.5rem;
                  padding-bottom: 0.5em;
                    padding-top: 2rem;
            }
            .level_subject{
                font-size: 1.35rem; 
            }
        }
    }
 .botom_tit{
      text-align: left;
      width: 60%;
       margin: 0 auto;
         padding-bottom:  4rem;
         padding-left:  2rem;
  }
    .breadcrumb {
        padding: 2rem 0 2rem 0;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;

        /deep/.el-breadcrumb {
            float: right;
        }

        /deep/.el-breadcrumb__inner:hover {
            color: #f28c02;
        }
    }

    .major_service {
        width: 100%;
        padding-bottom: 20px;

        .item-title {
            padding-top: 2rem;
            text-align: center;
            // color: #333333;
            width: 100%;
            margin: 0 auto;

            .image {
                width: 10%;
                display: block;
                margin: 1rem auto;
            }

            p {
                text-shadow: 1px 1px 1px #666;
                display: inline-block;
                color: #333333;
                padding: 0 50px;
               font-size: 1.5em;
            }

            .text_list {
                display: inline-block;
                // transform: translateX(-1rem);
              font-size: 1rem; 
                color: #333333;
            }

             .level_subject {
                text-align: left;
                width: 60%;
                letter-spacing: 4px;
                margin: 0 auto;
            //    font-size: 1.35rem; 
                color: #333333;
                font-weight: 600;
            }

            .text_subject {
                display: inline-block;
            //  font-size: 1.25rem;
                
                text-align: justify;
                width: 60%;
              
                margin: 0 auto;
                // text-align: left;
                color: #333333;
               
                // border: #f28c02 1px solid;
         
                img {
                    width: 100%;

                }

            }

        }


    }
</style>