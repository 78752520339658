<template>
  <div class="mainContainer">
    <div class="select">
      <span class="title">火警周期统计列表</span>
      <el-button type="text" size="small" class="select-button" icon="el-icon-back" @click="gobackPlaceDetail">返回火警列表
      </el-button>
      <el-button type="text" size="small" icon="el-icon-upload2" class="select-button" @click="problemExport">导出
      </el-button>
    </div>

    <div class="table-vv">
      <el-card shadow="always">
        <span style="font-size:14px">时间选择 ：</span>
        <el-date-picker style="width: 20.25rem;" v-model="timeValue" range-separator="~" start-placeholder="开始日期"
          end-placeholder="结束日期" size="mini" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
          @change="getTimeBlock"></el-date-picker>
      </el-card>

      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;margin-top:1rem">

        <div class="result-info">
          共有
          <span>{{ total }}</span>个数据
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}" @select="getIds" @select-all="getAllIds">
        <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column label="序号" type="index" align="center"></el-table-column>
        <el-table-column v-for="(item,index) in labels" :key="index" :prop="props[index]" :label="item"
          v-if="relLabelsStatus[index]" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button @click="questionDetails(scope.row)" type="text" size="small" icon="el-icon-document">级别详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
        layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
      </el-pagination>
      <el-dialog title="级别详情" center :visible.sync="dialogVisible" width="80%" :before-close="handleClose">

        <div v-for="(item,index) in fireLevelDtoList" :key="index" class="itemFireLeve">
          <div class="left-fire">
            <h3>火警信息</h3>
            <p> 火警级别： <span style="color:#66b1ff"> {{item.fireLevel}} </span> <span
                style="display:inline-block;margin:0 0 0 15px">手机是否发送：</span> <span style="color:#66b1ff">
                {{item.mobileSend==true?"是":"否"}} </span> <span
                style="display:inline-block;margin:0 0 0 15px">短信是否发送：</span> <span style="color:#66b1ff">
                {{item.mobileSend==true?"是":"否"}}</span> </p>
            <p> </p>
            <p> </p>
          </div>
          <div class="center-fire">
            <h3>语音接收详情</h3>
            <p v-if="item.mobileSendDtos == ''"> 暂无消息</p>
            <p v-for="(i,key) in item.mobileSendDtos" :key="key">

              <span>手机号:</span> <span style="color:#66b1ff"> {{i.mobile}} </span> <span
                style="display:inline-block;margin:0 0 0 15px">语音发送结果:</span> <span style="color:#66b1ff">
                {{i.mobileSendResult}}</span>
              <span style="display:inline-block;margin:0 0 0 15px">语音接收结果:</span> <span style="color:#66b1ff">
                {{i.mobileReceiveResult == null?"待确认":i.mobileReceiveResult }}</span>
            </p>

          </div>
          <div class="right-fire">
            <h3>短信接收详情</h3>
            <p v-if="item.smsSendDtos == ''"> 暂无消息</p>
            <p v-for="(j,key) in item.smsSendDtos" :key="key">

              <span>手机号:</span> <span style="color:#66b1ff"> {{j.mobile}} </span> <span
                style="display:inline-block;margin:0 0 0 15px">短信发送结果:</span><span style="color:#66b1ff">
              
                     {{j.smsSendResult}}  </span>
              <span style="display:inline-block;margin:0 0 0 15px">短信接收结果:</span>
              <span style="color:#66b1ff">
                  {{j.smsReceiveResult == 0?"待确认":j.smsReceiveResult == 1?"接收成功":"接收失败" }} </span>
          
            </p>
          </div>
        </div>


        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>


<script>
  import Bus from "../../../components/bus";
  import qs from "qs";
  import PubSub from "pubsub-js";
  import Storage from "@/utils/Storage";
  export default {
    name: "Statistics overviewDetails",
    data() {
      return {
        isActive: 1,
        month: "",
        productDel: false,
        productAdd: false,
        ifSearchMany: false,

        form: {
          handlerUserId: []
        },
        formHandle: {
          assignUserId: "",
          ccUserIds: [],
          handleOpinion: "",
          handleResurt: ""
        },
        visible: false,
        total: 0,
        tableData: [],
        ids: [],
        chooseOnlineStatus: "", //搜索选择
        sysUserlist: [], //账号所属类型
        newSsUserlist: {
          value: "id",
          label: "typeName",
          children: "childs",
          expandTrigger: "hover"
          // emitPath: false
        },
        checked: false,
        //转派对话框标记
        dialogVisible: false,
        //修改账号弹出对话框标记
        handleDialogVisible: false,
        //协议类型列表
        protocols: [{
          id: 1,
          protocolName: "test"
        }],
        pickerOptions: {
          disabledDate(time) {
            let cuttentDate = new Date(
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
            );
            let hmCurrentDate = cuttentDate.getTime();
            let three = 90 * 24 * 3600 * 1000;
            let threeMonths = hmCurrentDate - three;
            return time.getTime() > hmCurrentDate || time.getTime() < threeMonths;
          }
        },
        //产品品类列表
        categories: [],
        handleResurt: [{
            value: false,
            label: "不通过"
          },
          {
            value: true,
            label: "通过"
          }
        ],
        value: "",
        input: "",
        current: 1,

        mon: "",
        year: "",
        pageSize: 10,
        delDialogVisible: false,
        productId: "",
        indexId: "", //反显id
        nameinput: "", //搜索输入
        indexIdlist: "", //反显列表
        formLabelWidth: "120px",
        realNameList: [], //当前处理人员列表
        projectName: "", //项目名称
        mac: "", //设备号
        productName: "", //产品名称
        placeName: "", //产品名称
        userMobile: "", //app注册用户手机号
        startTime: "",
        endTime: "",
        timeValue: "",
        echarts1: null,
        echartsdate: "",
        fireLevelDtoList: [],
        props: [
          "fireLevel",
          "checkResult",
          "currentLevelCount",
        ],
        labels: [
          "火警等级",
          "处理结果",
          "当前级别触发数量",
        ],
        relLabelsStatus: [true, true, true, true, true, true, true, true],
        accountLevel: "" //是否是超级账号
      };
    },
    methods: {
      // 选择月份
      changeMonth(item) {
        console.log(item);
        this.echartsdate = item
        this.year = item.slice(0, 4);
        this.mon = item.slice(5, 7);

        this.fireTimeMonthStatistics();
      },
      getTime(val) {
        //   获取时间并格式化
        // 获取前val天的时间
        var dt = new Date();
        val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
        const y = dt.getFullYear();
        const m = (dt.getMonth() + 1 + "").padStart(2, "0");
        const d = (dt.getDate() + "").padStart(2, "0");
        const hh = (dt.getHours() + "").padStart(2, "0");
        const mm = (dt.getMinutes() + "").padStart(2, "0");
        const ss = (dt.getSeconds() + "").padStart(2, "0");

        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
        // return `${y}-${m}-${d}`;  // 获取的时间格式为 2021-04-09
        // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
        // return y + m + d  // 获取的时间格式为  20210409
      },

      refresh() {
        this.problemList();
      },
      // 切换样式
      changeClass(i) {
        this.isActive = i;
        this.problemList();
      },
      //批量选中
      getIds(selection, row) {
        console.log(selection)
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;
      },
      //全部选中
      getAllIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;
      },
      //获取时间函数2
      getTimeBlock() {
        console.log(this.timeValue);
        if (this.timeValue) {
          this.startTime = this.timeValue[0];
          this.endTime = this.timeValue[1];
          this.fireCycleStatisticsUrl();
        } else {
          this.startTime = "";
          this.endTime = "";
          this.fireCycleStatisticsUrl();
        }
      },
      handlePageChange(val) {
        this.current = val;
        this.problemList();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.problemList();
      },

      cancelDelete() {
        this.delDialogVisible = false;
        this.productId = "";
      },
      handleDelClose() {
        this.delDialogVisible = false;
        this.deleteProduct(this.productId);
      },


      fireCycleStatisticsUrl() {
        //查询列表
        let macs = this.$route.query.mac

        this.$api
          .fireCycleStatisticsUrl({
            //  FireCycleQueryDto:{
            mac: macs,
            startTime: this.startTime,
            endTime: this.endTime,
            //  }
          })
          .then(res => {
            // console.log( res.data.data)
            this.tableData = res.data.data;

            this.total = res.data.data.length;


          })
          .catch(error => {
            console.log(error);
          });
      },
      //回到火警列表页面
      gobackPlaceDetail() {
        this.$router.push("/fireStatistics");
      },
      // 导出
      problemExport() {
        let properties = [];
        let titles = [];
        for (let i = 0; i < this.relLabelsStatus.length; i++) {
          if (this.relLabelsStatus[i]) {
            properties.push(this.props[i]);
            titles.push(this.labels[i]);
          }
        }
        let query = {};
        query.placeName = this.placeName;
        // query.ids = this.ids;
        query.productName = this.productName;
        query.macs = this.dispatchList;
        query.projectName = this.projectName;
        query.userMobile = this.userMobile;
        this.$api
          .exportDeviceCycle({
            properties: properties,
            titles: titles,
            query: query
          })
          .then(res => {
            this.download(res.data);
          })
          .catch(error => {});
      },
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
            // type: "application/vnd.ms-excel"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
      //重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      showAddProductDialog() {
        this.dialogVisible = true;
        this.form.problemStatus = "";
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
      },
      //   级别详情
      questionDetails(row) {
        this.dialogVisible = true
        console.log(row.fireLevelDtoList)
        this.fireLevelDtoList = row.fireLevelDtoList
      },
      handleClose(done) {
        // console.log(done)
        this.dialogVisible = false
      }
    },
    created() {
      //查询产品列表
      this.startTime = this.getTime(7)
      this.endTime = this.getTime(0)
      this.timeValue = [this.getTime(7), this.getTime(0)]
      this.fireCycleStatisticsUrl();
      this.accountLevel = sessionStorage.getItem("accountLevel");

    },
    
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);


  .el-row {
    margin: 1rem 3rem;
    line-height: 0;
    min-width: 900px;

    .platform-toolbar {
      position: relative;
      margin: 0;
      border: 1px solid #d9d9d9;

      .title-info {
        height: 46px;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      .el-col {
        padding: 0 3rem;

        .toolbar-right {
          float: right;
          margin-top: 7px;

          &.export {
            margin-left: 1rem;

            .toolbar-btn {
              color: @toolBarFontColor;
              font-size: 0.9rem;

              &.left {
                margin-left: 1.5rem;
              }

              &.right {
                margin-right: 0;
              }
            }
          }
        }
      }

      .title-info {
        float: left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .result-info-wrapper {
      height: 1rem;
      margin: 1rem 0 0.5rem 0;
      position: relative;

      .setting-panel {
        position: absolute;
        float: right;
        top: 2rem;
        width: 200px;
        height: 200px;
        background: red;
        z-index: 1000;
        border-radius: 3px;
      }

      .result-info {
        height: 1rem;
        line-height: 1rem;
        font-size: 0.875rem;
        float: left;
        color: #898989;

        span {
          color: #292929;
          padding: 0 0.1rem;
        }
      }

      .data-setting {
        font-size: 0.875rem;
        color: #2673bb;
        float: right;

        i {
          padding-right: 0.2rem;
        }

        cursor: pointer;
      }
    }

    .el-table td,
    .el-table th {
      padding: 0 !important;
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .product-content {
    height: 6rem;

    .product-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .container-el-row {
    margin-top: 0px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15px;
    }

    .active {
      color: #2673bb;
    }

    .select-input-right-input {
      float: right;
      margin-top: 7px;
    }
  }

  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;

    .searchBut {
      float: right;
      margin-left: 20px;
    }
  }

  .toolbarTit {
    min-width: 101px;
    display: inline-block;
  }

  .itemFireLeve {
    //  border: 1px solid rgb(221, 213, 213);
    //  display: flex;

    padding: 10px;
    // box-shadow: 5px 5px 5px #a19f9f;
    justify-content: space-between;

    .left-fire,
    .center-fire,
    .right-fire {

      margin-top: 20px;

    }

  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }


  }
</style>