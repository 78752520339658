var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outting"},[_c('div',{staticClass:"select"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title)+"版块列表")]),_c('el-button',{staticClass:"addRoleButton",attrs:{"type":"text","size":"small","icon":"el-icon-back"},on:{"click":_vm.gobackFloorList}},[_vm._v("返回楼层列表")]),_c('el-button',{staticClass:"addRoleButton",attrs:{"type":"text","size":"small","icon":"el-icon-plus"},on:{"click":_vm.addUserHandler}},[_vm._v("新增版块")]),_c('el-button',{staticClass:"serachRoleButton",attrs:{"type":"text","size":"small","icon":"el-icon-bottom"},on:{"click":_vm.playShow}},[_vm._v("高级查询")]),_c('div',{staticClass:"select-input-right-input"},[_c('el-input',{attrs:{"prefix-icon":"el-icon-search","placeholder":"请输入版块名称","size":"small"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},model:{value:(_vm.inputName),callback:function ($$v) {_vm.inputName=$$v},expression:"inputName"}})],1)],1),_c('div',{staticClass:"table-vv"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":"","size":"small","header-cell-style":{'background-color': '#eeeeee'}}},[_c('el-table-column',{attrs:{"prop":"plateName","label":"名称","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.plateName || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"plateTitle","label":"标题","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.plateTitle || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"plateSubtitle","label":"副标题","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.plateSubtitle || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"plateLinkUrl","label":"跳转链接","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.plateLinkUrl || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"plateDetail","label":"详情","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.plateDetail || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.createTime || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"更新时间","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.updateTime || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"label":"图片","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"top-start","title":"","trigger":"hover"}},[_c('el-image',{staticStyle:{"width":"160px","height":"80px"},attrs:{"src":scope.row.plateImgUrl,"fit":_vm.fit,"referrerpolicy":"no-referrer"}}),_c('el-image',{staticStyle:{"width":"30px","height":"30px"},attrs:{"slot":"reference","src":scope.row.plateImgUrl,"fit":_vm.fit,"referrerpolicy":"no-referrer"},slot:"reference"})],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"1","label":"操作","width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small","icon":"el-icon-edit"},on:{"click":function($event){return _vm.handleChange(scope.$index, scope.row)}}},[_vm._v("编辑")]),_c('el-popconfirm',{attrs:{"title":"确定删除这条广告吗？"},on:{"onConfirm":function($event){return _vm.deleteAddHandler()}}},[_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"slot":"reference","type":"text","size":"small","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}},slot:"reference"},[_vm._v("删除")])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }