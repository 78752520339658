import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css' //element-ui的css
import './plugins/element.js'
import api from "./api"
import Storage from "./utils/Storage"
import echarts from 'echarts'
import Blob from './vendor/Blob.js'
import Export2Excel from './vendor/Export2Excel.js'
import XLSX from 'xlsx'
import VueIntro from 'vue-introjs';
import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;
// import { jsPlumb } from "jsplumb"
import panzoom from "panzoom"
import './utils/rem'
import 'intro.js/introjs.css';
// import axios from "axios"



Vue.use(VueIntro);
Vue.use(XLSX)
Vue.prototype.$echarts = echarts
// Vue.prototype.$axios = axios
Vue.use(ElementUI) //使用elementUI
Vue.prototype.$api = api
Vue.config.productionTip = false
// vue.config.devtools=true
if (Storage.getItem("token")) {
	// console.log(Storage.getItem("token"))
	store.dispatch("seUserInfoActions", {
		// username:Storage.getItem("username"),
		token: Storage.getItem("token")
	})
}

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

const VUE_APP_VERSION = require('../package.json').version
const version = window.localStorage.getItem("Version");
if(VUE_APP_VERSION != version){
    localStorage.clear()
    window.localStorage.setItem("Version", VUE_APP_VERSION);
    location.reload(false)
}

//添加时间自定义函数
Date.prototype.Format = function (fmt) {
    var o = {
        'M+': this.getMonth() + 1,
        'd+': this.getDate(),
        'H+': this.getHours(),
        'm+': this.getMinutes(),
        's+': this.getSeconds(),
        'S+': this.getMilliseconds()
    };
    //因为date.getFullYear()出来的结果是number类型的,所以为了让结果变成字符串型，下面有两种方法：
    if (/(y+)/.test(fmt)) {
        //第一种：利用字符串连接符“+”给date.getFullYear()+''，加一个空字符串便可以将number类型转换成字符串。
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            //第二种：使用String()类型进行强制数据类型转换String(date.getFullYear())，这种更容易理解。
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(String(o[k]).length)));
        }
    }
    return fmt;
};

new Vue({
	router,
	store,
    // axios,
	render: h => h(App)
}).$mount('#app')
