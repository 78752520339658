import { render, staticRenderFns } from "./sysUserDetail.vue?vue&type=template&id=52423da3&scoped=true&"
import script from "./sysUserDetail.vue?vue&type=script&lang=js&"
export * from "./sysUserDetail.vue?vue&type=script&lang=js&"
import style0 from "./sysUserDetail.vue?vue&type=style&index=0&id=52423da3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52423da3",
  null
  
)

export default component.exports