<template>
	<div class="mainContainer">
		设备命令
	</div>
</template>


<script>
	export default {
		name: "DeviceCommand",
		data() {
			return {

			};
		},
		methods: {
			initCharts() {

			}
		},
		mounted() {
			this.initCharts();
		}
	};
</script>
<style lang="less" scoped>
	.mainContainer {
		padding: 2rem 3rem;
	}
</style>
