<template>
  <div>
    <div style="border: 1px solid #ccc; margin-top: 10px">
      <!-- 工具栏 -->
      <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
      <!-- 编辑器 -->
      <Editor style="height: 400px; overflow-y: hidden" :defaultConfig="editorConfig" v-model="html" @onChange="onChange" @onCreated="onCreated" />
    </div>
  </div>
</template>
<script>
  import Storage from "../utils/Storage";
  import Wang from "../utils/wangEditor.js";
  import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
  // import E from 'wangeditor'
  export default {
   components: { Editor, Toolbar },
    name: 'editoritem',
    data() {
      return {
        arr: [],
        // uploadPath,
        // editor: null,
        info_: null,
         editor: null,
      html: "",
      toolbarConfig: {
        toolbarKeys: [// [ /* 显示哪些菜单，如何排序、分组 */ ]
          "headerSelect",
          "blockquote",
          "header1",
          "header2",
          "header3",
          "|",
          "bold",
          "underline",
          "italic",
          "through",
          "color",
          "bgColor",
          "clearStyle",
          "|",
          "fontSize",
          "fontFamily",
          "lineHeight",
          "|",
          "bulletedList",
          "numberedList",
          // "todo",
          "justifyLeft",
          "justifyRight",
          "justifyCenter",
           'justifyJustify', // 两端对齐
          "divider",
          {
            iconSvg: "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
            key: "group-indent",
            menuKeys: ["indent", "delIndent"]
          },
          "|",
          "insertLink",
          "emotion",
          {
            key: 'group-image',
            title: '图片',
            iconSvg: '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
            menuKeys: ["insertImage", "uploadImage"]
          },
          "insertVideo",
          "insertTable",
          // "codeBlock",
          "|",
          "undo",
          "redo",
          "|",
          "fullScreen",
        ]
      },

      editorConfig: {
        placeholder: "请输入内容...",
        // autoFocus: false,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            server: this.uri,
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            fieldName: 'multipartFile',
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 20 * 1024 * 1024, // 1M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
             meta: {
        token:  "Bearer " +Storage.getItem("token"),
        // otherKey: 'yyy'
    },
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,
            // 自定义增加 http  header
            headers: {
                 authorization: "Bearer " + Storage.getItem("token")
            //  'Content-Type':'application/form-data'
              // Accept: 'application/json',
              // otherKey: 'xxx'
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,
            // 超时时间，默认为 10 秒
            timeout: 5 * 1000, // 5 秒
            base64LimitSize: 5 * 1024,//小于5K的用base64位表示
            customInsert: this.customInsertFn// 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
          },

          fontSize:{
               fontSizeList: [
        '12px',
        '16px',
        '17px',
        '18px',
        '20px',
        '21px',
        '22px',
        '24px',
        '26px',
        '28px',
        '40px',
    ]
          }
        },
        hoverbarKeys: {//图片和链接hover时的编辑按钮配置
          // 'link': {
          //   menuKeys: ['editLink', 'unLink', 'viewLink']
          // },
          // 'image': {
          //   menuKeys: [

          //   ]
          // }
        },
      },
        mode: 'simple', // default默认显示全部 or 'simple'简单模式 工具栏工具比较少
      }
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
    //    id: {
    //   type: String,
    //   default: "editor",
    // },
    // value: String,
      value: {
        type: String,
        default: ''
      },
      isClear: {
        type: Boolean,
        default: false
      },
      uri: {
        type: String,
        default: ''
      },
    },
    watch: {
      isClear(val) {
        // 触发清除文本域内容
        if (val) {
          // this.editor.txt.clear()
          // this.info_ = null
          // console.log(val)
        }
      },
      value: function (value) {
        console.log(value)
        this.value = value

        // console.log( this.html)
        // if (value !== this.editor.txt.html()) {
        //   this.editor.txt.html(this.value)
        // }
      }
      //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
    },
    created() {

    },
    mounted() {

      // console.log("Bearer " + Storage.getItem("token"))
      // this.seteditor();
      // this.editor.txt.html(this.value)
      // console.log(this.value)
      console.log(this.uri)
      //  this.html = '999'
      //  var E = Wang
      // var editor = new Wang('#div1')


    },
     beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
    methods: {
       onCreated(editor) {
         console.log(editor)
            this.html = this.value

      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错


    },
    onChange(editor) {

      console.log( editor)
 console.log( this.uri)
      this.html = editor.getHtml() // onChange 时获取编辑器最新内容
      // this.$emit("value", data);
    //  editor.setHtml = this.value
        this.html =  this.html.replace(/<(\w[^>]*)  lang=([^|>]*)([^>]*)/gi, "<$1$3");//  Remove  Lang  attributes
  this.html  =  this.html.replace(/<\\?\?xml[^>]*>/gi, "");//  Remove  XML  elements  and  declarations
   this.html  =  this.html.replace(/<\/?\w+:[^>]*>/gi, "");//  Remove  Tags  with  XML  namespace  declarations:  <o:p></o:p>
   this.html  =  this.html.replace(/&nbsp;/, "");//  Replace  the  &nbsp;
   this.html  =  this.html.replace(/\n(\n)*( )*(\n)*\n/gi, '\n');
      this.$emit("change",   this.html);
      // console.log(editor,this.value); // onChange 时获取编辑器最新内容
    },
    customInsertFn(res, insertFn) {
   console.log(res)
      if (res.code == 200) {

        let url = res.data ;

        insertFn(url);
        // this.$Message.success("上传视频成功");
      } else {
        // this.$Message.error(res.message);
      }
    },

      seteditor() {

        const {
          createEditor,
          createToolbar
        } = window.wangEditor

        const editorConfig = {
          placeholder: 'Type here...',
          onChange(editor) {
            const html = editor.getHtml()
            console.log('editor content', html)
            // 也可以同步到 <textarea>
          }
        }

        const editor = createEditor({
          selector: '#editor-container',
          html: '<p><br></p>',
          config: editorConfig,
          mode: 'default', // or 'simple'
        })

        const toolbarConfig = {}

        const toolbar = createToolbar({
          editor,
          selector: '#toolbar-container',
          config: toolbarConfig,
          mode: 'default', // or 'simple'
        })







        //     this.editor  =  new Wang('#div1')

        //     // console.log(editor[config],'wwwwww')
        //     //  this.editor = new E(this.$refs.toolbar, this.$refs.editor)
        //     // console.log(this.editor)
        //         // this.editor.create()
        //             console.log(  this.editor)
        //     console.log(  this.editor.config)
        //     console.log(  this.editor.id)

        //        this.editor.config.pasteFilterStyle = false
        //       this.editor.config.uploadImgShowBase64 = false // base 64 存储图片
        //         this.editor.config.uploadImgServer = this.$api// 配置服务器端地址
        //      this.editor.config.uploadImgServer = this.uri // 配置服务器端地址
        //      this.editor.config.uploadImgHeaders = {
        //       authorization: "Bearer " + Storage.getItem("token")
        //       // headers: {
        //       // 'Content-Type':'application/form-data'
        //       // },
        //     }

        //     this.editor.config.uploadFileName = 'multipartFile' // 后端接受上传文件的参数名
        //      this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
        //      this.editor.config.uploadImgMaxLength = 6 // 限制一次最多上传 6 张图片
        //      this.editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间

        //     // 配置菜单
        //     // this.editor.config.menus = [
        //     //   'head', // 标题
        //     //   'bold', // 粗体
        //     //   'fontSize', // 字号
        //     //   'fontName', // 字体
        //     //   'italic', // 斜体
        //     //   'lineHeight',//行高
        //     //   'underline', // 下划线
        //     //   'strikeThrough', // 删除线
        //     //   'foreColor', // 文字颜色
        //     //   'backColor', // 背景颜色
        //     //   'link', // 插入链接
        //     //   'list', // 列表
        //     //   'justify', // 对齐方式
        //     //   'quote', // 引用
        //     //   'emoticon', // 表情
        //     //   'image', // 插入图片
        //     //   'table', // 表格
        //     //   'video', // 插入视频
        //     //   'code', // 插入代码
        //     //   'undo', // 撤销
        //     //   'redo', // 重复
        //     //   'fullscreen' // 全屏
        //     // ]

        //      this.editor.config.uploadImgHooks = {
        //       before: (xhr, editor, result) => {
        //         console.log(xhr, editor, result)
        //       },
        //       fail: (xhr, editor, result) => {
        //         console.log("Bearer " + sessionStorage.getItem("token"))
        //         console.log(xhr, editor, result)

        //         // 插入图片失败回调
        //       },
        //       success: (xhr, editor, result) => {
        //         // 图片上传成功回调
        //       },
        //       timeout: (xhr, editor) => {
        //         // 网络超时的回调
        //       },
        //       error: (xhr, editor) => {
        //         // 图片上传错误的回调
        //       },
        //       customInsert: (insertImg, result, editor) => {
        //         // 图片上传成功，插入图片的回调
        //         //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
        //         console.log(result.data)
        //         //insertImg()为插入图片的函数
        //         //循环插入图片
        //         // for (let i = 0; i < 1; i++) {
        //         // console.log(result)
        //         let url = result.data
        //         insertImg(url)
        //         // }
        //       }
        //     }
        //     /////////
        //      this.editor.config.customUploadImg = (files, insert) => {


        //                   var formData = new FormData();
        //         for(var i = 0;i < files.length;i ++) {
        //             formData.append("multipartFile", files[i], files[i].name);      //  多张图片文件都放进一个form-data，有些小伙伴说这里应该这样写：formData.append("file[" + i + "]", files[i], files[i].name)，后端才能拿到完整的图片数组，其实不然，用这个formData.getAll("file")方法恰好拿到数组，或者也可以forEach获取，有很多种办法。
        //         }

        //        console.log(formData)
        //       this.$api.addPic(formData).then(da => {
        //         console.log(da.data.data.split(','))
        //         let arr = da.data.data.split(',')

        //           for(var j=0;j<arr.length;j++){
        //             console.log(arr[j])
        //                         insert(arr[j]);    //  前面这个ip地址是服务器ip，后面的imgUrl是后台返回的图片地址，拼接上就是图片路径
        //                     }
        //       })
        //        console.log(this.editor.config.onchange)
        //     };



        //      this.editor.config.onchange = (html) => {
        //        console.log(html)
        //       this.info_ = html // 绑定当前逐渐地值
        //       this.$emit('change', this.info_) // 将内容同步到父组件中
        //     }
        //      this.editor.create()
      }
      //  this.editor.config.lineHeight = ['1', '1.15', '1.6', '2', '2.5', '3','4','5','6','7']
      //   this.editor.config.fontSize = {
      //     'x-small': { name: '10px', value: '1' },
      //     'small': { name: '13px', value: '2' },
      //     'normal': { name: '16px', value: '3' },
      //     'large': { name: '20px', value: '4' },
      //     'x-large': { name: '24px', value: '5' },
      //     'xx-large': { name: '32px', value: '6' },
      //     'xxx-large': { name: '58px', value: '7' },
      // }

      //
      //         // 创建富文本编辑器
      //
      //

    }
  }
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="css" scoped>
  #editor—wrapper {
    border: 1px solid #ccc;
    z-index: 100;
    /* 按需定义 */
  }

  #toolbar-container {
    border-bottom: 1px solid #ccc;
  }

  #editor-container {
    height: 500px;
  }
</style>
