<template>
  <div class="outting">
    <div class="select">
      <span class="title">楼层列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        @click="addUserHandler"
        class="addRoleButton"
      >新增楼层</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-bottom"
        @click="playShow"
        class="serachRoleButton"
      >高级查询</el-button>
      <div class="select-input-right-input">
        <el-input
          v-model="inputName"
          prefix-icon="el-icon-search"
          placeholder="请输入协议名称"
          size="small"
          @keyup.enter.native="search"
        ></el-input>
      </div>
    </div>
    <div class="xieyiTable">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column prop="title" label="标题" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.title || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="subtitle" label="副标题" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.subtitle || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.sort || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="mark" label="展示区域" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.mark || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column min-width="1" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="handleDetail(scope.$index, scope.row)"
            >编辑</el-button>
            <el-popconfirm title="确定删除这个楼层吗？" @onConfirm="deleteFloor()">
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                style="margin-left:10px;"
                slot="reference"
              >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <el-table-column min-width="1" label="所含版块" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-info"
              @click="handleAdd(scope.$index, scope.row)"
            >版块详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="添加楼层" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" label-position="left">
        <el-form-item label="楼层标题" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="楼层副标题" :label-width="formLabelWidth">
          <el-input v-model="form.subtitle" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="楼层顺序" :label-width="formLabelWidth">
          <el-input v-model="form.sort" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="展示区域" :label-width="formLabelWidth">
          <el-input v-model="form.mark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reset">重 置</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加楼层" :visible.sync="editDialogVisible" width="50%">
      <el-form :model="editForm" label-position="left">
        <el-form-item label="楼层标题" :label-width="formLabelWidth">
          <el-input v-model="editForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="楼层副标题" :label-width="formLabelWidth">
          <el-input v-model="editForm.subtitle" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="楼层顺序" :label-width="formLabelWidth">
          <el-input v-model="editForm.sort" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="展示区域" :label-width="formLabelWidth">
          <el-input v-model="editForm.mark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editReset">重 置</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "SysFloorPlate",
  data() {
    return {
      dialogVisible: false,
      editDialogVisible: false,
      form: {
        title: "",
        subtitle: "",
        sort: "",
        mark: ""
      },
      editForm: {
        title: "",
        subtitle: "",
        sort: "",
        mark: ""
      },
      inputName: "",
      formLabelWidth: "120px",
      total: 0,
      tableData: [],
      current: 1,
      size: 10,
      deleteId: "",
      editId: ""
    };
  },
  created() {
    this.sysFloorPlateList();
  },
  methods: {
    addUserHandler() {
      this.dialogVisible = true;
      this.reset();
    },
    reset() {
      (this.form.title = ""),
        (this.form.subtitle = ""),
        (this.form.mark = ""),
        (this.form.sort = "");
    },
    submit() {
      this.dialogVisible = false;
      if (this.form.sort) {
        this.$api
          .addFloorHandler({
            title: this.form.title,
            subtitle: this.form.subtitle,
            sort: Number(this.form.sort),
            mark: this.form.mark
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("添加楼层成功!");
              this.sysFloorPlateList();
            }
          });
      } else {
        this.$api
          .addFloorHandler({
            title: this.form.title,
            subtitle: this.form.subtitle,
            mark: this.form.mark
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("添加楼层成功!");
              this.sysFloorPlateList();
            }
          });
      }
    },
    sysFloorPlateList() {
      this.$api
        .floorPlateHandler({
          page: {
            current: this.current,
            size: this.size
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;

          console.log(res);
        });
    },
    playShow() {},
    handleDetail(index, row) {
      this.editDialogVisible = true;
      this.editForm.title = row.title;
      this.editForm.subtitle = row.subtitle;
      this.editForm.sort = row.sort;
      this.editForm.mark = row.mark;
      this.editId = row.id;
    },
    handleDelete(index, row) {
      this.deleteId = row.id;
    },
    deleteFloor() {
      this.$api.deleteFloorHandler(this.deleteId).then(res => {
        if (res.data.code == 200) {
          this.$message.success("删除成功!");
          this.sysFloorPlateList();
        }
      });
    },
    addBankuai() {},
    handleAdd(index, row) {
      this.$router.push({
        path: `/plateList`,
        query: { id: row.id, name: row.title }
      });
      this.row = row;
    },
    editSubmit() {
      this.$api
        .editFloorHandler({
          id: Number(this.editId),
          title: this.editForm.title,
          subtitle: this.editForm.subtitle,
          sort: this.editForm.sort,
          mark: this.editForm.mark,
        })
        .then(res => {
          console.log(res);
        });
    },
    editReset() {}
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .update-input-timer {
      float: right;
      line-height: 46px;
      margin-left: 25px;
      height: 46px;

      .demonstrationTwo {
        float: left;
        font-size: 0.8rem;
      }

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }
  }
  .xieyiTable {
    padding: 0rem 3rem;
    margin-top: 1rem;
  }
}
</style>