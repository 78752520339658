<template>
	<div class="systemSetting">
		<div class="left">
			<el-menu :collapse="collapse" default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
			 background-color="#262626" text-color="#fff" active-text-color="#ffd04b">
				<el-menu-item>
					<router-link to='/agreement'>协议列表</router-link>
				</el-menu-item>
				<el-submenu v-for="(item,index) in systemList" :key="item.id" :index='index+""'>
					<template slot="title">
						<i class="el-icon-location"></i>
						<router-link :to="item.children==null?{path:item.router}: ''">
							<span class="spName">{{ item.name }}</span>
						</router-link>
					</template>
					<el-menu-item-group>
						<el-menu-item v-for="(child,index2) in item.children" :key="child.id" :index='index +"-"+index2'>
							<router-link :to="{path:child.router}" class="spName">
								{{ child.name }}
							</router-link>
						</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
			</el-menu>

		</div>
		<!-- <div class="right">
      
        <router-view/>
      
     
    </div> -->
		<div class="main-right">
			<el-row>
				<el-col :span="24">
					<div class="grid-content bg-purple-dark">
						<router-view />
					</div>
				</el-col>
			</el-row>

		</div>
	</div>
</template>
<script>
	import bus from '../../components/bus'
	import Storage from "../../utils/Storage";
	export default {
		name: "SystemSetting",
		data() {
			return {
				systemList: [],
				oldSystemList: [],
				collapse: false,
			};
		},
		created() {
			const oldSystemList = JSON.parse(Storage.getItem("systemList"));
			// console.log(JSON.parse(Storage.getItem("systemList")));
			// console.log(oldSystemList)
			this.systemList = Storage.toTree(JSON.parse(Storage.getItem("systemList")));
			// console.log(this.systemList)
			this.systemList.forEach(element => {
				console.log(element);
			});
			bus.$on('collapse', msg => {
				this.collapse = msg;
				bus.$emit('collapse-content', msg);
			});
		},
		methods: {
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			navRouter(url) {
				this.$router.push(url);
			}
		}
	};
</script>
<style lang="less" scoped>
	.systemSetting {
		.main-right {
			height: 100%;
			background: #fafafa;
			overflow: auto;
			line-height: 1.5rem;
		}

		.el-row {
			line-height: 1.5rem;
			min-width: 1200px !important;

			.toolbar {
				margin: 0rem;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		width: 100%;
		height: 100%;
		overflow: hidden;

		.left {
			background: purple;
			height: 100%;
			// width: 15%;
			float: left;

			.el-icon-setting {
				color: #ffffff;
			}
		}

		.right {
			height: 100%;
			overflow: hidden;
			overflow: auto;
			line-height: 1.5rem;
			background-color: #FAFAFA;
		}
	}

	a {
		text-decoration: none;
		color: #FAFAFA;
	}

	.el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: none;
	}

	.el-meau {
		border: 0px;
	}

	.spName {
		color: #f4f4f5;
	}

	.el-menu-vertical-demo {
		&.el-menu {
			border: 0px;

			.el-menu-item,
			.el-submenu {
				min-width: 200px;
			}

			&.el-menu--collapse {

				.el-menu-item,
				.el-submenu {
					min-width: 0px;
				}
			}
		}

		height: 100%;
	}
</style>
