<template>
    <div class="outting">
        <div class="select">
            <span class="title">商品评价列表</span>

          <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                     class="addRoleButton">返回商品列表</el-button>
          <el-button type="text" size="small" icon="el-icon-plus" @click="addUserHandler" class="addRoleButton">新增评价
          </el-button>

        </div>
        <div class="table-vv">
            <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">
<!--              <el-table-column prop="id" label="评论ID" min-width="2" fixed align="center">-->

<!--              </el-table-column>-->
                <el-table-column prop="username" label="用户昵称" min-width="2" fixed align="center">

                </el-table-column>
              <el-table-column prop="goodsName" label="商品名称" min-width="2" fixed align="center">

              </el-table-column>

              <el-table-column prop="commentContent" label="评论内容" min-width="2" fixed align="center">

              </el-table-column>
              <el-table-column prop="starRate" label="满意度评分" min-width="2" fixed align="center">

              </el-table-column>
<!--              <el-table-column prop="status" label="评论审核状态" min-width="2" fixed align="center">-->
<!--                <template slot-scope="{row}">{{ row.status == 0? "待审核": row.status == 1?"审核通过": "审核不通过"}}</template>-->
<!--              </el-table-column>-->
              <el-table-column prop="isAnonymous" label="评论是否匿名" min-width="2" fixed align="center">
                <template slot-scope="{row}">{{ row.isAnonymous == 0? "否" : "是"}}</template>
              </el-table-column>
              <el-table-column prop="isTop" label="评论是否置顶" min-width="2" fixed align="center">
                <template slot-scope="{row}">{{ row.isTop == 0? "否" : "是"}}</template>
              </el-table-column>
                <el-table-column prop="additionalFileUrls" label="评论图片" min-width="2" fixed align="center">
                  <template slot-scope="scope" v-if="scope.row.additionalFileUrls != null" >

                    <el-image v-for="(item, index) in scope.row.additionalFileUrls.split(',')" :key="index" :src="item"
                              :preview-src-list="[item]" style="width: 40px;height: 40px;margin-right: 5px" >
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column min-width="1" label="操作" width="280" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" icon="el-icon-top"
                            @click="setTop(scope.row)">{{scope.row.isTop == 0 ? "置顶" : "取消置顶"}}</el-button>
                       <el-button type="text" size="small" icon="el-icon-top" v-if="scope.row.status == 0"
                                 @click="allowShow(scope.row)">审核通过</el-button>
                        <el-popconfirm title="确定置顶这条评价吗？" @onConfirm="deleteAddHandler(scope.row.id)">
                            <el-button type="text" size="small" icon="el-icon-delete"
                                @click="handleDelete(scope.$index, scope.row)" style="margin-left:10px;"
                                slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="total" class="pagin"
                           :current-page.sync="current"
                @current-change="changeCurrent"></el-pagination>
        </div>
        <el-dialog title="新增评价" :visible.sync="addDialogFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="addForm" size="small" :rules="addRules" ref="addRules" enctype="multipart/form-data">

              <el-form-item label="请输入评价内容" :label-width="formLabelWidth" prop="questionContent">
                <el-input v-model="commentContent" autocomplete="off" style="width:315px" type="textarea"
                          :rows="2">
                </el-input>

              </el-form-item>

              <el-form-item label="商品评价图片" ref="formImg2" prop="detailFiles" :label-width="formLabelWidth">
                <el-upload class="upload-demo" action :on-change="handleChangeDetail"
                           :on-remove="handleRemoveDetail" :on-exceed="handleExceed"
                           list-type="picture-card" :file-list="fileList" :limit="limitUpload"
                           :auto-upload="false" ref="my-upload">
                  <el-button type="primary" round size="mini" class="input-button">上传附件
                  </el-button>
                  <br />
                </el-upload>
              </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addEvaluate()" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import axios from "@/utils/http";
    import base from "@/api/base";

    export default {
        name: "answerList",
        data() {

            return {
                dialogImageUrl2: "",
                dialogVisible2: false,
              dialogImageUrl: '',
              dialogVisible: false,
              uploadImageList:[],
                value: "",
                items: [""],
              commentContent: '',
                questionContent:"",
                current: 1,
                fileList: [],
                fileNameList:[],
                limitUpload: 3,
                size: 10,
                total: 0,
                tableData: [],
                file: {},
                editFile: {},
                deleteId: "",
                editId: "",
                addDialogFormVisible: false,
                // 限制开始时间
                pickerOptionsStart: {},
                pickerOptionsEnd: {},
                addForm: {
                  areaName: "",
                  cityName: "",
                  contactName: "",
                  contactPhone: "",
                  detailAddress: "",
                  provinceName: "",
                  storeName: "",
                  streetName: "",
                  supplier: ""
                },
                editForm: {
                    contactName: "",
                    contactPhone: "",
                    storeName: "",
                    supplier: "",
                    id:""
                },
                addforceUpdate: "",
                addappType: "",
                editDialogFormVisible: false,
                addRules: {
                },
                editRules: {
                },
                formLabelWidth: "180px",
                editforceUpdate: "",
                editappType: ""
            };
        },
        created() {
            this.appList();
        },
        methods: {
          gobackPlaceDetail() {
            this.$router.push("/mallList");
          },
          handleRemoveDetail(file, fileList) {
            var index = this.fileNameList.findIndex(item => item == file.name)
            console.log(index)
            this.uploadImageList.splice(index,1)
          },

          handleChangeDetail(file, fileList) {
            const isLt5M = file.size / 1024 / 1024 < 0.48;
            if (!isLt5M) {
              this.$message.error('上传图片大小不能超过500kb!');
              fileList.splice(-1, 1); //移除选中图片
              return false;
            } else {

              this.fileList = fileList
              if (file.raw) {
                this.$nextTick(() => { //等待照片上传完成，
                  let formData = new FormData();
                  formData.append("multipartFile", file.raw);
                  this.$api
                      .addPic(formData)
                      .then(res => {
                        this.uploadImageList.push(res.data.data)
                        this.fileNameList.push(file.name)
                      })
                      .catch(error => {
                        console.log(error);
                      });
                })
              }
            }


          },

            //删除函数
            handleDelete(index, row) {
                this.deleteId = row.id;
            },
            //删除确定
            deleteAddHandler(id) {
                this.$api.evaluateDelete(id).then(res => {
                    console.log(res.data);
                    if (res.data.code == 200) {
                        this.$message.success("删除成功!");
                        this.appList();
                    }
                });
            },
          //置顶评价
          setTop(row) {
            this.$api.setTop(
             {
                id: row.id,
                result: row.isTop == 0 ? 1 : 0
              }
            ).then(res => {
              console.log(res.data);
              if (res.data.code == 200) {
                this.$message.success("设置成功!");
                this.appList();
              }
            });
          },
          allowShow(row){
            this.$api.allowShow(
                {
                  id:row.id,
                  result:  1
                }
            ).then(res => {
              console.log(res.data);
              if (res.data.code == 200) {
                this.$message.success("审核完成!");
                this.appList();
              }
            });
          },



            appList() {
                this.$api
                    .evaluateList({
                        data:this.$route.query.data.id

                    })
                    .then(res => {
                        // console.log(res.data);
                        this.tableData = res.data.data.records;

                    });
            },

            addUserHandler() {
                this.items = [""],
                    this.addDialogFormVisible = true;


            },

            changeCurrent(id) {

                this.appList();
            },




            addEvaluate(){
              var additionalFileUrls;
              if(this.uploadImageList.length == 1){
                additionalFileUrls = this.uploadImageList[0];
              }
              if(this.uploadImageList.length == 2){
                additionalFileUrls = this.uploadImageList[0] + "," + this.uploadImageList[1] ;
              }
              if(this.uploadImageList.length == 3){
                additionalFileUrls = this.uploadImageList[0] + "," + this.uploadImageList[1] + "," + this.uploadImageList[2] ;
              }
              this.$api
                  .addEvaluate({
                    commentContent:this.commentContent,
                    goodsId:this.$route.query.data.id,
                    starRate:5,
                    isTop:0,
                    additionalFileUrls:additionalFileUrls
                  })
                  .then(res => {
                      this.uploadImageList = []
                      this.commentContent = ""
                      this.addDialogFormVisible = false
                    this.$refs["my-upload"].clearFiles()
                      this.appList()
                  });
            },


            handleExceed(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },


        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .new_add {
            width: 100%;
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            margin-top: 3px;
            margin-left: 15px;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .select-status {
                width: 9rem;
                margin-left: 1.7rem;
                margin-top: 7px;
                // margin-top:7px;
            }

            .select-input-timer {
                float: right;
            }

            .select-status {
                float: right;
            }

            .select-input-right-input {
                float: right;
                // width: 8rem;
                line-height: 46px;
            }

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .addRoleButton {
                margin-left: 15px;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }

            .update-input-timer {
                float: right;
                line-height: 46px;
                margin-left: 25px;
                height: 46px;

                .demonstrationTwo {
                    float: left;
                    font-size: 0.8rem;
                }

                .update-input-timer-select {
                    width: 11rem;
                    margin-left: 5px;
                }
            }
        }

        .table-vv {
            padding: 1rem 3rem;

            .pagin {
                margin-left: 30%;
                margin-top: 10px;
                float: right;
            }
        }
    }
</style>
