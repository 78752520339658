<template>
    <div class="solution">

        <div class="block">
            <div class="lunbotu">
                <!-- <el-carousel-item > -->
                <img src="../../assets/jjfabanner.jpg" class="image" />
                <!-- </el-carousel-item> -->
            </div>
        </div>

        <div class="titleDisplay">
            <!-- 动态 -->
            <div v-html="outlink" class="texztaaa"></div>

            <!-- <div class="culture_brand">
                <p class="text_list">
                    随着社会经济的发展,火灾防控压力越来越大。从近几年的火灾趋势来看,小微场所的火灾增长趋势越来越凸显。据不完全统计全国三小场所有5000万个，涉及面广、破坏性强，一旦发生火灾极易蔓延扩大，造成伤亡损失。
                </p>
                <p></p>
                <div class="fire_data">
                    <img src="../../assets/homepPage/shuju.png" class="image1" />
                    <img src="../../assets/homepPage/shuju2.png" class="image2" />
                </div>
                <p style="margin-bottom:30px;text-align: center;"> *2020年全年火灾相关数据统计-来自应急消防救援局</p>

                <p class="text_list">
                    吻胜智慧消防平台以报警、应急等终端硬件产品+独立报警监控云平台双保障，具有实时监测火情、第一时间报警、吻胜云全链条监控、报警设备定位管理、设备自检、低电量报警等功能、有效解决学校、家庭住宅、商铺、工业园、超市等九小场所消防监督管理难点。
                </p>
                <div class="tagging_data">
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu01.gif" />
                        <br><span>多种方式</span><br>
                        <span>第一时间报警</span>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu0002.png" />
                        <br><span style="margin-top:10px;display:inline-block">设备自检</span><br>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu03.gif" />
                        <br><span>设备状态</span><br>
                        <span>实时查看</span>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu04.gif" />
                        <br><span>支持绑定</span><br>
                        <span>多个报警联系人</span>
                    </div>
                </div>
            </div>
            <div class="major_service">
                <p class="title_text">吻胜智慧消防NB-loT解决方案</p>
                <p class="text_list">
                    吻胜自主研发了接入NB-loT技术的烟感、气感、一氧化碳等设备，依托物联网、大数据、云计算和人工智能等新技术，统一汇聚，构成强大的智慧消防系统，使火灾警情早发现、早报警、早扑灭。</p>
                <p></p>
                <div class="tagging_data">
                    <div class="image_daping">
                        <div class="image3">
                            <img src="../../assets/homepPage/dzfs.png" />
                           
                        </div>
                        <div class="image4">
                            <img src="../../assets/homepPage/bjjk.png" />
                          
                        </div>
                    </div>
                    <div class="image_tit">
                        <p>
                            多种方式
                        </p>
                        <p>
                            支持绑定
                        </p>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="major_service2">
                <p class="title_text">NB-loT技术应用优势</p>
                <p class="text_list">
                    NB-loT（Narrow Band Internet of
                    Things），即基于蜂窝的窄带物联网，作为远距离无线通讯技术中的佼佼者，NB-loT具备高安全、广覆盖、低功耗、大连接和低成本这五大亮点。</p>
                <p></p>
                <div class="tagging_data">
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu05.gif" />
                        <br><span>高安全</span><br>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu06.gif" />
                        <br><span>深覆盖</span><br>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu07.gif" />
                        <br><span>低功率</span><br>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu08.gif" />
                        <br><span>大连接</span><br>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu09.gif" />
                        <br><span>通讯稳定</span><br>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu10.gif" />
                        <br><span>响应时间快</span><br>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu11.gif" />
                        <br><span>通讯距离远</span><br>
                    </div>
                    <div class="image3">
                        <img src="../../assets/homepPage/biaozhu12.jpg" />
                        <br><span>设备连接多</span><br>
                    </div>
                </div>
                <div>
                </div>
            </div>


            <div style="clear: both;"></div> -->
        </div>
    </div>
</template>
<script>
    import Storage from "../../utils/Storage";

    export default {
        name: "solution",
        components: {},
        data() {
            return {
                outlink: "",
                imageBox: [{
                        id: 0,
                        url: require("../../assets/banner1.jpg")
                    },
                    {
                        id: 1,
                        url: require("../../assets/banner2.jpg")
                    },
                    {
                        id: 2,
                        url: require("../../assets/banner3.jpg")
                    }
                    // {
                    //   id: 3,
                    //   url: require("../../assets/rotationChart/index_bg1.jpg")
                    // }
                ],
                select: "",
                showBac: "",
                ifLogin: "",
                isTop: true,
                loginChanges: "登录",
                input: "",
                menu_listones: 5,

            };
        },
        created() {

            // console.log(this.$route.query.id)
            // if (this.$route.query.id) {
            //     this.$api
            //         .solutionList()
            //         .then(res => {
            //             if (res.data.code == "200") {
            //                 for (let index = 0; index < res.data.data.length; index++) {
            //                     if (this.$route.query.id == res.data.data[index].id) {
            //                         //  console.log(res.data.data.records[index].newsContent )
            //                         this.outlink = res.data.data[index].newsContent;
            //                     }
            //                 }
            //             }
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });



            // } else {
            //     this.solutionList()
            // }
        },

        mounted() {
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0

       this.solutionList()

            // window.addEventListener('scroll', this.scrollToTop)
            // const that = this
            // let ispeed = Math.floor(-that.scrollTop / 5)
            // console.log(ispeed)
            // document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        },
    watch: {
      $route(to, from) {
          console.log(to,from)
          if(to.path == '/solution'){ 
              console.log('切换')
               this.$api
                    .solutionList()
                    .then(res => {
                        if (res.data.code == "200") {
                            for (let index = 0; index < res.data.data.length; index++) {
                                if (to.query.id == res.data.data[index].id) {
                                     console.log(res.data.data[index].newsContent )
                                    this.outlink = res.data.data[index].newsContent;
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

          }
      }},
        methods: {
            // scrollToTop() {
            //     const that = this
            //     let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            //     that.scrollTop = scrollTop
            //     that.btnFlag = false
            // },
            //新闻资讯列表
            solutionList() {

                this.$api
                    .solutionList()
                    .then(res => {
                        if (res.data.code == "200") {
                         for (let index = 0; index < res.data.data.length; index++) {
                                if (this.$route.query.id == res.data.data[index].id) {
                                     console.log(res.data.data[index].newsContent )
                                    this.outlink = res.data.data[index].newsContent;
                                }
                            }
                            //    console.log(res.data.data[0].newsContent)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            //登录事件
            loginHandler() {
                this.$router.push("/login");
            },

            goBackstage() {
                this.$router.replace("/mainRouter");
            },
            goUrl(url) {
                window.open(url, "_blank");
            }
        },
        // destroyed() {
        //     window.removeEventListener('scroll', this.scrollToTop)
        // },
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1200px) {
        .solution {
            // background-color: #fafafa;
            // overflow-x: auto;
            min-width: 1000px;
        }
    }

    @media screen and (min-width: 1201px) {
        .solution {
            // background-color: #fafafa;
            // overflow-x: auto;
            min-width: 1300px;
        }
    }

    @media screen and (min-width: 0px) and (max-width: 1370px) {
        .block {
            width: 100%;
            // margin-top: 5rem;

            .lunbotu {
                /deep/.el-carousel__container {
                    width: 100%;
                    height: 36rem;
                }

                height: 100%;
                width: 100%;

                .image {
                    width: 100%;
                    height: 100%;
                }
            }

            /deep/.el-carousel__button {
                border: 3px solid #f28c02;
                border-radius: 5px 5px;
                padding: 5px 10px;
            }
        }
    }

    @media screen and (min-width: 1371px) { 
        .block {
            width: 100%;
            // margin-top: 5rem;

            .lunbotu {
                /deep/.el-carousel__container {
                    width: 100%;
                    height: 40.625rem;
                }

                height: 100%;
                width: 100%;

                .image {
                    width: 100%;
                    height: 100%;
                }
            }

            /deep/.el-carousel__button {
                border: 3px solid #f28c02;
                border-radius: 5px 5px;
                padding: 5px 10px;
            }

            // .el-carousel__container {
            //   position: relative;
            //   height: 450 / 16rem;
            // }
        }

    }


    .culture_brand {
        width: 100%;
        background: #ffffff;
        padding-bottom: 8rem;

        font-size: 1.12rem;
        color: #333333;

        .text_list {
            width: 62.5%;
            margin: 0 auto;
            font-size: 1.5rem;
            color: #333333;
            // letter-spacing: 5px;
            line-height: 2.5rem;
            padding: 4.2rem 0 4.2rem 0;
        }

        .fire_data {
            // width: 50%;
            margin: 0 auto;
            display: flex;
            padding-top: 2rem;
            justify-content: center;

            .image1 {
                // width: 40%;
                width: 21rem;

                height: 17.1rem;
            }

            .image2 {
                // width: 58%;
                // height:18rem;
                width: 28.06rem;
                height: 17.1rem;
                margin-left: 1.375rem;
            }
        }

        .tagging_data {
            width: 60%;
            margin: 0 auto;
            display: flex;
            padding-top: 2rem;
            text-align: center;
            justify-content: space-around;

            .image3 {
                width: 20%;
                font-size: 1.25rem;
                color: #333333;

                img {
                    width: 40%;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .major_service {
        width: 100%;
        background: #efefef;
        padding-bottom: 8rem;

        .title_text {
            padding-top: 5rem;
            color: #333333;
            width: 62.5%;
            font-weight: 600;
            margin: 0 auto;
            font-size: 2.5rem;
        }

        .tagging_data {
            width: 60%;
            margin: 0 auto;
            display: flex;
            font-size: 1.125rem;
            color: #333333;
            padding-top: 2rem;
            // padding-bottom: 3rem;/


            flex-direction: column;

            .image_daping {
                display: flex;
                justify-content: space-around;
                //    justify-content: center;
                align-items: center;
            }

            .image_tit {
                width: 100%;
                // margin-left: 2rem;
                margin-top: 1rem;
                display: flex;
                justify-content: space-around;
                //    justify-content: center;
                align-items: center;

                p:nth-child(1) {
                    width: 58%;
                    text-align: center;
                }

                ;

                p:nth-child(2) {
                    width: 40%;
                    text-align: center;
                }
            }

            @media screen and (min-width: 1371px) {
                .image3 {
                    width: 53%;
                    //  font-size: 1.5rem;
                    text-align: center;
                    color: #333333;

                    img {
                        width: 100%;
                        height: 15rem;
                    }
                }

                .image4 {
                    width: 42%;
                    //  font-size: 1.5rem;
                    color: #333333;
                    text-align: center;

                    img {
                        width: 100%;
                        height: 15rem;
                    }
                }
            }

            @media screen and (min-width: 0px) and (max-width: 1370px) {
                .image3 {
                    width: 55%;
                    //  font-size: 1.5rem;
                    text-align: center;
                    color: #333333;
                    height: 14rem;

                    img {
                        width: 100%;
                        height: 13rem;
                    }
                }

                .image4 {
                    width: 43%;
                    height: 14rem;
                    //  font-size: 1.5rem;
                    color: #333333;
                    text-align: center;

                    height: 14rem;

                    img {
                        bottom: 1rem;
                        width: 100%;
                        height: 13rem;
                    }
                }
            }

        }

        .text_list {
            width: 62.5%;
            margin: 0 auto;
            font-size: 1.5rem;
            color: #333333;
            line-height: 2.5rem;
            padding: 2.1rem 0 2.1rem 0;
            // letter-spacing: 5px;
            // padding: 3rem 0 20px 0;
        }
    }

    .major_service2 {
        width: 100%;
        background: #ffffff;
        padding-bottom: 4rem;

        .title_text {
            padding-top: 5rem;
            color: #333333;
            width: 62.5%;
            font-weight: 600;
            margin: 0 auto;
            font-size: 2.5rem;
        }

        .tagging_data {
            width: 60%;
            text-align: center;
            margin: 0 auto;
            display: flex;
            font-size: 1.125rem;
            color: #333333;
            padding-top: 2rem;
            padding-bottom: 6rem;
            justify-content: space-around;

            .image3 {
                width: 9.5%;
                font-size: 1rem;

                color: #333333;

                img {
                    width: 60%;
                    margin-bottom: 1rem;
                    // min-width: ;
                }
            }
        }

        .text_list {
            width: 62.5%;
            margin: 0 auto;
            font-size: 1.5rem;
            // line-height: 3.75rem;
            line-height: 2.5rem;
            padding: 2.1rem 0 2.1rem 0;
            color: #333333;
            // letter-spacing: 5px;
            // padding: 3rem 0 20px 0;
        }
    }

    // 菜单导航样式
    .menuNavigation {
        // margin-top: 41px;
        width: 100%;
        margin-left: 10px;
        height: 80px;
        // padding-left: 20%;
        //  border: 1px rebeccapurple solid;
        background-color: #fff;

        .el-button {
            padding: 1px 1px 1px 10px;
        }

        .imgLogo {
            // border: #12b7f5 1px solid;
        }

        img {
            padding-left: 78%;
            width: 50px;
            height: 50px;
        }

        /deep/.el-input-group__append,
        .el-input-group__prepend {
            background-color: #fff !important;
        }

        .menu_list {
            height: 80px;
            color: #a1a1a1;
            // border: 1px rebeccapurple solid;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .active {
                color: red;
                transition: all 0.8s;
                background: #000;
                color: #fff;
            }
        }

        ul {
            width: 100%;
            padding-left: 3rem;
            text-align: center;
            border-bottom: #dddddd;

            div {
                padding: 0;
                margin: 0;
            }

            li {
                color: #a1a1a1;
                display: inline-block;
                height: 80px;
                line-height: 80px;
                margin: 0 auto;

                vertical-align: middle;
                text-align: center;

                span {
                    display: inline-block;
                    // padding: 8px 2px 0 2px;
                }

                list-style: none;
                cursor: pointer;

                //transition: font-size 0.5s, font-weight 0.5s;
                //navSelect
                .selected_marker {
                    width: 0;
                    background: #a1a1a1;
                    //text-align: center;
                    margin-top: 17px;
                    margin-left: 0;
                    margin-right: 0;
                    height: 3px;
                    border: none;
                    transition: width 0.2s;
                    overflow: hidden;
                }

                .selected_marker_left {
                    float: left;
                }

                .selected_marker_right {
                    float: right;
                }

                //.navSelect{
                //  font-weight: bold;
                //}
                //margin-bottom: 1px;
                &:hover {
                    //background: #128bf533;
                    //font-size: 1rem;
                    //font-weight: bolder;
                    //border-bottom: 2px solid #12b7f5;

                    .selected_marker {
                        //vertical-align: bottom;
                        //text-align: center;
                        width: 100%;
                    }
                }
            }
        }
    }

    .middle_show {
        width: 80%;
        // border: 1px solid red;
        margin: 0 auto;
        height: auto;
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;

        .middle_showchild {
            width: 20%;
            text-align: center;
            height: 100%;
            border: 1px solid #fff;
            background-color: #fff;

            img {
                width: 70%;
                // border: 1px solid red;
                margin: 0 auto;
                margin-top: 7%;
            }

            b {
                font-size: 1rem;
                color: #000;
            }

            p {
                padding: 15px 0 15px 0;
            }

            .el-button {
                margin-bottom: 20px;
            }
        }
    }

    .titleDisplay {
        // background-color: #efefef;
        // width: 100%;
        width: 100%;
        height: 100%;
        margin: auto;
        text-align: center;
        .texztaaa {
            //    /deep/div:nth-child(3){
            margin-right: auto;
            margin-left: auto;
            outline: 0px;
            // max-width: 860px;
            // padding: 2rem 0 6rem 0 !important;
            //    }
        }

        // padding-bottom: 3rem;
        .item-title {
            padding-top: 6rem;
            // padding-bottom: 3rem;
            text-align: center;
            color: #333333;
            width: 100%;
            margin: 0 auto;
            font-size: 2.1rem;
            font-weight: 500;

            p {
                text-shadow: 1px 1px 1px #666;
                //  text-decoration: underline;
                border-bottom: 8px solid #f28c02;
                display: inline-block;
                padding: 0 50px;
            }
        }

    }
</style>