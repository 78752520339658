var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outting"},[_vm._m(0),_c('div',{staticClass:"table"},[_c('div',{staticStyle:{"float":"right","margin-bottom":".5rem"}},[_c('el-button',{staticStyle:{"margin-right":"50px"},attrs:{"type":"primary","round":"","size":"small"},on:{"click":_vm.confirmChoose}},[_vm._v("批量通过")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ifTest),expression:"!ifTest"}],attrs:{"type":"primary","round":"","size":"small"},on:{"click":_vm.startTest}},[_vm._v("开始测试")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.ifTest),expression:"ifTest"}],attrs:{"round":"","size":"small","type":"danger"},on:{"click":_vm.endTest}},[_vm._v("结束测试")])],1),_c('el-table',{ref:"singleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":"","size":"small","header-cell-style":{'background-color': '#eeeeee'},"cell-style":_vm.cellStyle},on:{"select":_vm.getSelected}},[_c('el-table-column',{attrs:{"type":"selection","width":"50","align":"center"}}),_c('el-table-column',{attrs:{"type":"index","width":"50","label":"序号","align":"center","fixed":""}}),_c('el-table-column',{attrs:{"prop":"mac","label":"mac","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.mac || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"productName","label":"产品名称","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.productName || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"detectorLossFault","label":"丟失","min-width":"1","fixed":"","align":"center","formatter":_vm.stateFormatDetectorLossFault}}),_c('el-table-column',{attrs:{"prop":"selfChecking","label":"自检","min-width":"1","fixed":"","align":"center","formatter":_vm.stateFormatSelfChecking}}),_c('el-table-column',{attrs:{"prop":"fireAlarm","label":"火警/燃气泄漏","min-width":"1","fixed":"","align":"center","formatter":_vm.stateFormatFireAlarm}}),_c('el-table-column',{attrs:{"prop":"wirelessModuleElectricity","label":"电池电量比","min-width":"1","fixed":"","align":"center","formatter":_vm.stateFormatLightAlarm}}),_c('el-table-column',{attrs:{"prop":"rssi","label":"信号强度","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.rssi || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"statusTime","label":"时间","min-width":"1","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.statusTime || '暂无数据'))]}}])}),_c('el-table-column',{attrs:{"prop":"","label":"测试状态","min-width":"1","fixed":"","align":"center","formatter":_vm.stateFormatResult}}),_c('el-table-column',{attrs:{"prop":"id","label":"操作","min-width":"2","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","round":"","size":"mini"},on:{"click":function($event){return _vm.ifHege(scope.$index, scope.row)}}},[_vm._v("合格")]),_c('el-button',{attrs:{"round":"","size":"mini"},on:{"click":function($event){return _vm.ifNotHege(scope.$index, scope.row)}}},[_vm._v("不合格")])]}}])})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select"},[_c('span',{staticClass:"title"},[_vm._v("测试列表")])])}]

export { render, staticRenderFns }