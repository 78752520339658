<template>
    <div class="first">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item  @click.native="backTo">{{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"新闻资讯"}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color:#f28c02">
                        资讯详情
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="block">
            <div class="lunbotu" height="37.5rem">
                <img src="../../../assets/xwzxbanner.jpg" class="image" />
            </div>
        </div>
        <div class="major_service">
            <div class="item-title">
                <div class="text_subject">
                    <img src="../../../assets/login/information03.png" alt="" class="image">
                </div>

            </div>

            <div>

            </div>
        </div>


    </div>
</template>
<script>
    export default {
        name: "FireProducts",
        components: {},
        data() {
            return {

            };
        },
        mounted() {
            window.addEventListener('scroll', this.scrollToTop)
            const that = this
            let ispeed = Math.floor(-that.scrollTop / 5)
            document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        },
        created() {},
        methods: {
            scrollToTop() {
                const that = this
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                that.scrollTop = scrollTop
                that.btnFlag = false
            },
                     backTo() {
                if (this.$route.query.crumbsTitle == '首页') {
                    let vm = this
                    vm.$router.replace({path: `/`}); } else {
                    this.$router.replace({
                        path: "/newsInformation"
                    });
                }
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTop)
        },
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1200px) {
        .first {
            background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;
       
        }
    }

    @media screen and (min-width: 1201px) {
        .first {
            background-color: #efefef;
            overflow-x: auto;
            min-width: 1300px;
           
        }
    }

    .block {
        width: 100%;
        height: 37.5rem;

        // background-color: red;
        .lunbotu {
            height: 100%;
            width: 100%;

            .image {
                width: 100%;
                height: 100%;
            }
            img{
                  width: 100%;
            }
        }

    }

    .breadcrumb {
        padding: 6rem 0 2rem 0;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;
        /deep/.el-breadcrumb {
            float: right;
             padding: 2rem 0;
        }

        /deep/.el-breadcrumb__inner:hover {
            color: #f28c02;
        }
    }

    .major_service {
        width: 100%;
        padding-bottom: 20px;

        .item-title {
            padding-top: 2rem;
            text-align: center;
            // color: #333333;
            width: 100%;
            margin: 0 auto;

            .image {
                width: 30%;
                display: block;
                margin: 0 auto;
                img{
                    width: 100%;
                }
            }

            p {
                text-shadow: 1px 1px 1px #666;
                display: inline-block;
                color: #333333;
                padding: 0 50px;
                font-size: 2.25em;
            }

            p:nth-child(2) {
                font-size: 1.8755em;
                color: #666666;
                margin-top: 1rem;
            }

            .text_list {
                display: inline-block;
                transform: translateY(-2rem);
                font-size: 1.875rem;
                color: #333333;
            }

            .level_subject {
                text-align: left;
                width: 60%;
                letter-spacing: 4px;
                margin: 0 auto;
                font-size: 1.875rem;
                color: #333333;
                font-weight: 600;
            }

            .text_subject {
                display: block;
                font-size: 1.5rem;
                width: 60%;
                padding-top: 2rem;
                margin: 0 auto;
                text-align: left;
                color: #333333;

                // padding-bottom: 4rem;
                img {
                    width: 100%;

                }

            }

        }


    }
</style>