<template>
  <div class="outting">
    <div class="select">
      <span class="title">编辑账号</span>

      <el-button type="text" size="small" icon="el-icon-back" @click="goBackHandler">返回账号列表</el-button>
    </div>

    <div class="ceshi-vv">
      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label">用户基本信息</span>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm"
            size="small">
            <div style="width:100%;">
              <el-divider content-position="left">
                账户详情
                <el-tooltip class="item" effect="light" content="用户用于登录的基本账号信息" placement="right-end">
                  <i class="el-icon-info" style="margin-left: 5px"></i>
                </el-tooltip>
              </el-divider>
            </div>
            <div style="width:100%;display:flex;">
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="用户名 :" prop="userName">
                    <el-input size="small" placeholder="请输入用户名" v-model="ruleForm.userName" style="width:80%;">
                    </el-input>
                    <el-tooltip class="item" effect="light" content="用户名是必填项,只能中英文，数字，下划线，减号组成，且不能超过20位"
                      placement="top-start">
                      <i class="el-icon-info" style="margin-left: 5px"></i>
                    </el-tooltip>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="用户昵称 :" prop="inputNickName">
                    <el-input size="small" placeholder="请输入昵称" v-model="ruleForm.inputNickName" style="width:80%;">
                    </el-input>
                    <el-tooltip class="item" effect="light" content="昵称是0-20个字符" placement="top-start">
                      <i class="el-icon-info" style="margin-left: 5px"></i>
                    </el-tooltip>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="用户手机 :" prop="mobile">
                    <el-input size="small" placeholder="请输入手机号" v-model="ruleForm.mobile" style="width:80%;"></el-input>
                    <el-tooltip class="item" effect="light" content="此处的手机号将用于用户的登找回密码操作,请谨慎填写" placement="top-start">
                      <i class="el-icon-info" style="margin-left: 5px"></i>
                    </el-tooltip>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div style="width:100%;display:flex;margin-top:1rem;">
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="账号状态 :">
                    <el-select v-model="ruleForm.isEnable " placeholder="请选择是否启用" size="small" @change="changeIsEnable"
                      style="width:80%;">
                      <el-option label="启用" value="0"></el-option>
                      <el-option label="禁用" value="1"></el-option>
                    </el-select>
                    <el-tooltip class="item" effect="light" content="禁用后,该账号将不能登录系统" placement="top-start">
                      <i class="el-icon-info" style="margin-left: 5px"></i>
                    </el-tooltip>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="创建时间 :">
                    <el-input v-model="ruleForm.createTime" :disabled="true" style="width:80%;"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="修改时间 :">
                    <el-input v-model="ruleForm.updateTime" :disabled="true" style="width:80%;"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div style="width:100%;display:flex;margin-top:1rem;">
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="用户密码 :" prop="password">
                    <el-input v-model="ruleForm.password" :disabled="!isUpdatePwd" size="small" placeholder="请输入您要修改的密码"
                      show-password style="width:68%;"></el-input>
                    <el-button v-if="isShowUpdate" class="changePaeewordBut" type="text" size="mini"
                      @click="changePassWord">修改</el-button>
                    <el-button v-if="isShowCancle" class="changePaeewordBut" type="text" size="mini"
                      @click="changeCancle">取消</el-button>
                    <el-tooltip class="item" effect="light" content="您无权查看当前用户的密码,可通过左侧的修改验证进行修改" placement="right">
                      <i class="el-icon-info" style="margin-left: 5px"></i>
                    </el-tooltip>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark" v-if="ifManagement">
                  <el-form-item label="所属角色 :">
                    <el-select v-model="ruleForm.roleName" size="small" style="width:80%;" @change="changeRoleName">
                      <el-option v-for="item in options" :key="item.id" :label="item.roleName" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark" v-if="ifManagement">
                  <el-form-item label="账号深度 :">
                    <el-select v-model="ruleForm.accountLevel" placeholder="请选择账号深度" size="small" style="width:80%;"
                      @change="changeAccountLevel">
                      <el-option label="3" value="3"></el-option>
                      <el-option label="5" value="5"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div style="width:100%;">
              <el-divider content-position="left">项目详情</el-divider>
            </div>
            <div style="width:100%;display:flex;">
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="项目名称 :" prop="projectName">
                    <el-input size="small" placeholder="请输入项目名称" v-model="ruleForm.projectName" style="width:80%;">
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="项目联系人 :" prop="projectContact">
                    <el-input size="small" placeholder="请输入项目联系人" v-model="ruleForm.projectContact" style="width:80%;">
                    </el-input>
                  </el-form-item>
                </div>
              </div>
                   <div style="flex:1">
              <div class="grid-content bg-purple-dark"  >
                <el-form-item label="项目紧急联系人: " prop="isNotifyProjectMobile ">
                  <el-radio-group v-model="ruleForm.isNotifyProjectMobile">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                   <el-tooltip class="item" effect="light" content=" 选择'是',本项目下设备报警时,会通知到项目联系手机号" placement="right-end">
              <i class="el-icon-info" style="margin-left: 5px"></i>
            </el-tooltip>
                </el-form-item>
              </div>
            </div>
             
            </div>
            <div style="width:100%;display:flex;margin-top:1rem;">
               <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="联系人手机:" prop="projectMobile">
                    <el-input size="small" placeholder="请输入手机号码" v-model="ruleForm.projectMobile" style="width:80%;">
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="所属地区 :">
                    <el-cascader :props="props" size="small" v-model="ruleForm.sanji" @change="getChange"
                      :placeholder="defalutPlace" ref="cascader" style="width:80%;"></el-cascader>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="详细地址 :" prop="address">
                    <el-input size="small" placeholder="请输入详细地址" v-model="ruleForm.address" style="width:80%;">
                    </el-input>
                  </el-form-item>
                </div>
              </div>
             
            </div>
            <div style="width:100%;display:flex;margin-top:1rem;">
               <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="创建人 : ">
                    <el-input size="small" placeholder="请输入内容" v-model="ruleForm.sysUserName" style="width:80%;"
                      disabled></el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="全部设备:">
                    <el-input size="small" placeholder="0" v-model="ruleForm.deviceCount" style="width:80%;" disabled>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark">
                  <el-form-item label="当前账号设备:">
                    <el-input size="small" placeholder="0" v-model="ruleForm.deviceBelongCount" style="width:80%;"
                      disabled></el-input>
                  </el-form-item>
                </div>
              </div>
          
            </div>

            <div style="width:100%;display:flex;margin-top:1rem;">
                  <div style="flex:1;">
                <div class="grid-content bg-purple-dark"  v-if="ifManagement">
                  <el-form-item label="报警方式 :">
                    <div style="width:90%;">
                      <el-checkbox label="语音" name="type" v-model="thisCallMobile"></el-checkbox>
                      <el-checkbox label="短信" name="type" v-model="thisSendMsg"></el-checkbox>
                      <el-tooltip class="item" effect="light" content="如您取消报警方式的勾选,在发生火警时,将不能接收到语音或者短信的通知"
                        placement="right">
                        <i class="el-icon-info" style="margin-left: 5px"></i>
                      </el-tooltip>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div style="flex:1;">
                <div class="grid-content bg-purple-dark"  v-if="ifManagement">
                  <el-form-item label="报警温度:">
                    <el-radio-group v-model="ruleForm.isNotify" @change="isNotifyFn()">
                      <el-radio label="1">是</el-radio>
                      <el-radio label="0">否</el-radio>
                    </el-radio-group>
                    <span v-if="dialogVisible">
                      <el-input placeholder="" size="mini" style="width:50px;margin-left:10px"
                        v-model="ruleForm.notifyData" >
                      </el-input>℃
                    </span>
                  <el-tooltip class="item" effect="light" content="设置'温度',则设备报警时上报的温度超过设定值会在报警短信中提醒温度信息" placement="right-end">
              <i class="el-icon-info" style="margin-left: 5px"></i>
            </el-tooltip>
                  </el-form-item>
                </div>
              </div>
                  <div style="flex:1;">
              <div class="grid-content bg-purple-dark">
                  <el-form-item label="配置紧急联系人权限:" label-width="150px">
                    <el-radio-group v-model="ruleForm.isProjectLinkCall">
                      <el-radio label="1">是</el-radio>
                      <el-radio label="0">否</el-radio>
                    </el-radio-group>
                  <el-tooltip class="item" effect="light" content="选择'有',可在个人中心配置紧急联系人。最多可配置5位" placement="right-end">
              <i class="el-icon-info" style="margin-left: 5px"></i>
            </el-tooltip>
                  </el-form-item>
                </div>
            </div>
            </div>


            <div class="editBut">
              <el-button type="text" size="small" icon="el-icon-s-release" style="float:right" @click="cancelChange">
                取消修改</el-button>
              <el-button type="text" size="small" icon="el-icon-upload2" style="float:right;margin-right:10px"
                @click="editHandler('ruleForm')">保存修改</el-button>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="用户子账号列表">
          <span slot="label">子账号列表</span>
          <el-card shadow="always">
            <div>
              <el-button type="text" size="small" icon="el-icon-user-solid" @click="dispatchUser" style="float:right;">
                我要分配账号</el-button>
              <el-table :data="tableData" style="width: 100%;margin-bottom:20px;" row-key="id" stripe size="small"
                :tree-props="{children: 'sysUserDetailShowDtos', hasChildren: 'hasChildren'}"
                :header-cell-style="{'background-color': '#eeeeee'}" @select="getChildIds">
                <el-table-column type="selection" fixed align="center" :selectable="selectInit"></el-table-column>
                <el-table-column prop="id" label="ID" fixed align="center" width="120"></el-table-column>
                <el-table-column prop="username" label="用户名" align="center"></el-table-column>
                <el-table-column prop="sysUserName" label="创建人" align="center"></el-table-column>
                <el-table-column prop="nickName" label="昵称" align="center">
                  <template slot-scope="{row}">{{ row.nickName || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column prop="accountLevelView" label="账号等级" align="center"></el-table-column>
                <el-table-column prop="mobile" label="手机" align="center"></el-table-column>
                <el-table-column prop="province" label="省" align="center"></el-table-column>
                <el-table-column prop="city" label="市" align="center"></el-table-column>
                <el-table-column prop="area" label="区" align="center"></el-table-column>
                <el-table-column prop="address" label="详细地址" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
                <el-table-column prop="roleName" label="角色名称" align="center"></el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
                <el-table-column prop="projectContact" label="项目联系人" align="center"></el-table-column>
                <el-table-column prop="projectMobile" label="项目联系人手机" align="center"></el-table-column>
                <el-table-column prop="deviceCount" label="设备数量" align="center"></el-table-column>
                <el-table-column prop="isEnable" label="账号状态" align="center" :formatter="stateFormat"></el-table-column>
                <el-table-column prop="isCallMobile" label="语音通知" align="center" :formatter="stateFormatMobile">
                </el-table-column>
                <el-table-column prop="isSendMsg" label="短信通知" align="center" :formatter="stateFormatMsg">
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="提示" :visible.sync="dispatchDialogVisible" width="30%" :close-on-click-modal="false">
      <span>请先选择要分配的子账号</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dispatchDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dispatchDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="目标分配账号" :visible.sync="parentDialogFormVisible" :close-on-click-modal="false">
      <el-form :model="parentForm">
        <el-form-item label="账号名称" :label-width="formLabelWidth">
          <el-select v-model="dispatchValue" placeholder="请选择目标分配账号" @change="changeUser">
            <el-option v-for="item in dispatchListOptions" :key="item.id" :label="item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="parentDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dispatchUserHandler">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Storage from "../../../utils/Storage";

  export default {
    name: "SysUserDetail",
    data() {
      let that = this;
      var checkData = (rule, value, callback) => {
        if (value) {
          if (/[\u4E00-\u9FA5]/g.test(value)) {
            callback(new Error("密码不能输入汉字!"));
          } else {
            callback();
          }
        }
        callback();
      };
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
        if (!value) {
          return callback(new Error("电话号码不能为空"));
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error("请输入数字值"));
          } else {
            if (phoneReg.test(value)) {
              callback();
            } else {
              callback(new Error("电话号码格式不正确"));
            }
          }
        }, 100);
      };
      return {
        dispatchValue: "",
        dispatchListOptions: [],
        ruleForm: {
          userName: "",
          createTime: "",
          deviceBelongCount: "",
          updateTime: "",
          roleName: "",
          sanji: "",
          province: "",
          deviceCount: "",
          mobile: "",
          projectContact: "",
          isEnable: "",
          inputNickName: "",
          city: "",
          area: "",
          address: "",
          accountLevel: "",
          projectName: "",
          projectMobile: "",
          password: "",
          newPassword: "",
          isNotify: "",
          isProjectLinkCall: "",
          notifyData: '',
          isNotifyProjectMobile:"",
        },
        dialogVisible: false,
        rules: {
          userName: [{
              required: true,
              message: "请输入用户名称",
              trigger: "blur"
            },
            {
              pattern: "^[\u4e00-\u9fa5A-Za-z0-9-_]{1,20}$",
              message: "用户名是必填项,只能中英文，数字，下划线，减号组成，且不能超过20位",
              trigger: "blur"
            }
          ],
          inputNickName: [{
            required: false,
            min: 0,
            max: 20,
            message: "长度在 0 到 20 个字符",
            trigger: "blur"
          }],
          mobile: [{
            required: true,
            validator: checkPhone,
            trigger: "blur"
          }],
          password: [{
              required: false,
              min: 6,
              max: 18,
              message: "长度在 6 到 18 个字符",
              trigger: "blur"
            },
            {
              validator: checkData,
              trigger: "blur"
            },
            {
              pattern: "^[\u4e00-\u9fa5A-Za-z0-9-_+]{1,18}$",
              message: "密码只能中英文，数字，下划线，减号,加号组成，且不能小于6位,大于18位",
              trigger: "blur"
            }
          ],
          newPassword: [{
              required: false,
              min: 6,
              max: 18,
              message: "长度在 6 到 18 个字符",
              trigger: "blur"
            },
            {
              validator: checkData,
              trigger: "blur"
            }
          ],
          projectName: [{
            required: true,
            min: 1,
            max: 40,
            message: "长度在 1 到 40 个字符",
            trigger: "blur"
          }],
          projectContact: [{
            required: true,
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur"
          }],
          projectMobile: [{
            required: true,
            validator: checkPhone,
            trigger: "blur"
          }],
          address: [{
            required: true,
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur"
          }]
        },
        formLabelWidth: "120px",
        parentForm: {},
        parentDialogFormVisible: false,
        dispatchDialogVisible: false,
        childIds: [],
        //子账号列表数据
        tableData: [],
        //value: [],
        areaOptions: [],
        ifChangePass: false,
        typeGroup: [],
        value: "0",
        userId: "",
        ifPassword: true,
        sendIsEnable: "",
        sendRoleId: [],
        sendAccountLevel: "",
        sendProvince: "",
        sendCity: "",
        sendArea: "",
        sendNickName: "",
        sendMobile: "",
        sendProjectName: "",
        sendProjectContact: "",
        sendIsNotifyProjectMobile: "",
        sendProjectMobile: "",
        defalutPlace: "",
        thisCallMobile: "",
        thisSendMsg: "",
        options: [],
        ifManagement: "",
        userAccountLevel: "",
        showBox: true,
        sysUserName: "",
        id: "",
        ifSendNewPassword: false,
        code: 0,
        parentId: "",
        props: {
          lazy: true,
          value: "code",
          label: "name",
          lazyLoad(node, resolve) {
            var token = that.$store.state.userinfo.token;
            console.log(node);
            const {
              level
            } = node;
            if (level > 0) {
              that.code = node.data.code;
            }
            if (level == 1) {
              that.ruleForm.province = node.data.name;
            }
            if (level == 2) {
              that.ruleForm.city = node.data.name;
            }
            that.$api
              .areaSanjiHandler(that.code, token)
              .then(res => {
                const nodes = res.data.data;
                if (level >= 1) {
                  nodes.forEach(item => {
                    item.leaf = level == 2;
                  });
                }
                resolve(nodes);
              })
              .catch(err => {
                console.log(err);
              });
          }
        },
        isUpdatePwd: false,
        isShowCancle: false,
        isShowUpdate: true
      };
    },
    created() {
      var token = this.$store.state.userinfo.token;
      this.userId = Storage.getItem("userId");
      this.$api
        .userDetailId(this.userId, token)
        .then(res => {
          console.log(res.data.data);
          this.id = res.data.data.id;
          this.ruleForm.accountLevel = res.data.data.accountDegree;
          this.userAccountLevel = res.data.data.accountLevel;
          console.log("userAccountLevel" + this.userAccountLevel);
          if (res.data.data.username == null) {
            this.ruleForm.userName = "暂无数据";
          } else {
            this.ruleForm.userName = res.data.data.username; //用户名
          }
          if (res.data.data.createTime == null) {
            this.ruleForm.createTime = "暂无数据";
          } else {
            this.ruleForm.createTime = res.data.data.createTime; //创建时间
          }
          if (res.data.data.updateTime == null) {
            this.ruleForm.updateTime = "暂无数据";
          } else {
            this.ruleForm.updateTime = res.data.data.updateTime; //更新时间
          }
          if (res.data.data.nickName == null) {
            this.ruleForm.inputNickName = "暂无数据";
          } else {
            this.ruleForm.inputNickName = res.data.data.nickName; //昵称
          }
          if (res.data.data.mobile == null) {
            this.ruleForm.mobile = "暂无数据";
          } else {
            this.ruleForm.mobile = res.data.data.mobile; //手机
          }
          //是否启用（所有人可以更改）
          if (res.data.data.isEnable == 0) {
            this.sendIsEnable = 0;
            this.ruleForm.isEnable = "启用";
          } else {
            this.sendIsEnable = 1;
            this.ruleForm.isEnable = "禁用";
          }
          //角色名称
          if (res.data.data.roleName == null) {
            this.ruleForm.roleName = "暂无数据";
          } else {
            this.ruleForm.roleName = res.data.data.roleName;
          }
          //项目名称
          if (res.data.data.projectName == null) {
            this.ruleForm.projectName = "暂无数据";
          } else {
            this.ruleForm.projectName = res.data.data.projectName;
          }
          //项目联系人手机
          if (res.data.data.projectMobile == null) {
            this.ruleForm.projectMobile = "暂无数据";
          } else {
            this.ruleForm.projectMobile = res.data.data.projectMobile;
          }
          //项目联系人
          if (res.data.data.projectContact == null) {
            this.ruleForm.projectContact = "暂无数据";
          } else {
            this.ruleForm.projectContact = res.data.data.projectContact; //项目联系人
          }
            //紧急联系人
          if (res.data.data.isNotifyProjectMobile == null) {
            this.ruleForm.isNotifyProjectMobile = "暂无数据";
          } else {
            this.ruleForm.isNotifyProjectMobile = res.data.data.isNotifyProjectMobile.toString(); //紧急联系人
            console.log( this.ruleForm.isNotifyProjectMobile)
          }
          //设备数量
          if (res.data.data.deviceCount == null) {
            this.ruleForm.deviceCount = "暂无数据";
          } else {
            this.ruleForm.deviceCount = res.data.data.deviceCount;
          }
          //所属行政区
          if (res.data.data.address == null) {
            this.ruleForm.address = "暂无数据";
          } else {
            this.ruleForm.address = res.data.data.address;
          }
          //省市区三级联动选择
          if (res.data.data.province == null) {
            this.ruleForm.province = "暂无数据"; //省
          } else {
            this.ruleForm.province = res.data.data.province;
          }
          if (res.data.data.city == null) {
            this.ruleForm.city = "暂无数据"; //市
          } else {
            this.ruleForm.city = res.data.data.city;
          }
          if (res.data.data.area == null) {
            this.ruleForm.area = "暂无数据"; //区
          } else {
            this.ruleForm.area = res.data.data.area;
          }
          this.defalutPlace =
            this.ruleForm.province +
            "/" +
            this.ruleForm.city +
            "/" +
            this.ruleForm.area;
          //是否发送短信
          if (res.data.data.isSendMsg == 1) {
            this.thisSendMsg = true;
            this.isSendMsg = 1;
          } else {
            this.thisSendMsg = false;
            this.isSendMsg = 0;
          }
          if (res.data.data.isCallMobile == 1) {
            this.thisCallMobile = true;
            this.isCallMobile = 1;
            // console.log(this.thisCallMobile);
          } else {
            this.thisCallMobile = false;
            this.isCallMobile = 0;
            // console.log(this.thisCallMobile);
          }
          //项目创建人
          if (res.data.data.sysUserName == null) {
            this.ruleForm.sysUserName = "暂无数据";
          } else {
            this.ruleForm.sysUserName = res.data.data.sysUserName;
          }
            if (res.data.data.isNotify == 0) {
            this.ruleForm.isNotify = "0";
            this.dialogVisible = false
          } else {
            this.ruleForm.isNotify = res.data.data.isNotify.toString(); //是否报警温度
            this.dialogVisible = true
          }

         if (res.data.data.isProjectLinkCall == 0) {
            this.ruleForm.isProjectLinkCall = "0";
            // this.dialogVisible = false
          } else {
            this.ruleForm.isProjectLinkCall = res.data.data.isProjectLinkCall.toString(); //是否紧急联系人
            // this.dialogVisible = true
            console.log(this.ruleForm.isProjectLinkCall ,res.data.data.isProjectLinkCall.toString())
          }
             if (res.data.data.notifyData == null) {
            this.ruleForm.notifyData = "60";
          } else {
            this.ruleForm.notifyData = res.data.data.notifyData.toString(); //是否报警温度
          }
        })
        .catch(err => {
          console.log(err);
        });
      this.$api
        .roleListSelect(token)
        .then(res => {
          this.options = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
      this.$api
        .userDetail(token)
        .then(res => {
          // console.log(res.data.data)
          this.current = res.data.data.accountLevel;
          this.other = 3 - this.current;
          if (res.data.data.accountLevel == 0) {
            this.ifManagement = true;
          } else {
            this.ifManagement = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
      //子账号请求
      this.$api
        .userChild(this.userId)
        .then(res => {
          // console.log(res.data.data);
          this.tableData = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
      //子账号下拉列表
      this.$api
        .dispatchList()
        .then(res => {
          console.log(res.data.data);
          this.dispatchListOptions = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    methods: {
      selectInit(row, column) {
        if (row.accountLevel - this.userAccountLevel == 1) {
          return true;
        } else {
          return false;
        }
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      //修改密码
      /*changePassword() {
                this.ifPassword = false;
              },*/
      changeCancle() {
        (this.isShowUpdate = true), (this.isShowCancle = false);
        this.isUpdatePwd = false;
        this.ruleForm.password = "";
      },
      //报警方式选择
      changeWayHandler(eve) {
        // console.log(eve);
        // console.log(this.isCallMobile);

        if (eve.indexOf("语音") == -1) {
          this.isCallMobile = 0;
        } else {
          this.isCallMobile = 1;
        }
        if (eve.indexOf("短信") == -1) {
          this.isSendMsg = 0;
        } else {
          this.isSendMsg = 1;
        }
        console.log(this.isCallMobile, this.isSendMsg);
      },
      //编辑用户函数
      editHandler() {
        if (this.ruleForm.inputNickName == "暂无数据") {
          this.sendNickName = "";
        } else {
          this.sendNickName = this.ruleForm.inputNickName;
        }
        if (this.ruleForm.mobile == "暂无数据") {
          this.sendMobile = "";
        } else {
          this.sendMobile = this.ruleForm.mobile;
        }
        if (this.ruleForm.projectName == "暂无数据") {
          this.sendProjectName = "";
        } else {
          this.sendProjectName = this.ruleForm.projectName;
        }
        if (this.ruleForm.projectContact == "暂无数据") {
          this.sendProjectContact = "";
        } else {
          this.sendProjectContact = this.ruleForm.projectContact;
        } 
         if (this.ruleForm.isNotifyProjectMobile == "暂无数据") {
          this.sendIsNotifyProjectMobile = "";
        } else {
          this.sendIsNotifyProjectMobile = this.ruleForm.isNotifyProjectMobile;
        } 
        if (this.ruleForm.projectMobile == "暂无数据") {
          this.sendProjectMobile = "";
        } else {
          this.sendProjectMobile = this.ruleForm.projectMobile;
        }
        if (this.ruleForm.address == "暂无数据") {
          this.sendAddress = "";
        } else {
          this.sendAddress = this.ruleForm.address;
        }
        if (this.ruleForm.password == "请输入旧密码") {
          this.sendPassword = "";
        } else {
          this.sendPassword = this.ruleForm.password;
        }
        if (this.ruleForm.province == "暂无数据") {
          this.sendProvince = "";
        } else {
          this.sendProvince = this.ruleForm.province;
        }
        if (this.ruleForm.city == "暂无数据") {
          this.sendCity = "";
        } else {
          this.sendCity = this.ruleForm.city;
        }
        if (this.ruleForm.area == "暂无数据") {
          this.sendArea = "";
        } else {
          this.sendArea = this.ruleForm.area;
        }
        if (this.thisCallMobile == true) {
          this.isCallMobile = 1;
        } else {
          this.isCallMobile = 0;
        }
        if (this.thisSendMsg == true) {
          this.isSendMsg = 1;
        } else {
          this.isSendMsg = 0;
        }
        this.options.forEach(item => {
          if (this.ruleForm.roleName == item.roleName) {
            this.sendRoleId.push(item.id);
          }
        });

        this.$refs["ruleForm"].validate(valid => {
          if (valid) {
            var token = this.$store.state.userinfo.token;
            if (!this.newPassword) {
              this.$api
                .editUser({
                    nickName: this.sendNickName,
                    mobile: this.sendMobile,
                    id: this.id,
                    projectName: this.sendProjectName,
                    projectContact: this.sendProjectContact,
                    isNotifyProjectMobile: this.sendIsNotifyProjectMobile,
                    projectMobile: this.sendProjectMobile,
                    isCallMobile: Number(this.isCallMobile),
                    isSendMsg: Number(this.isSendMsg),
                    province: this.sendProvince,
                    roleIds: this.sendRoleId,
                    area: this.sendArea,
                    address: this.sendAddress,
                    city: this.sendCity,
                    password: this.sendPassword,
                    isEnable: this.sendIsEnable,
                    username: this.ruleForm.userName,
                    accountDegree: this.sendAccountLevel,
                      isNotify: Number(this.ruleForm.isNotify),
                      isProjectLinkCall: Number(this.ruleForm.isProjectLinkCall),
                    notifyData: Number(this.ruleForm.notifyData),
                  },
                  token
                )
                .then(res => {
                  if (res.data.code == 200) {
                    this.$message.success("编辑成功!");
                    this.$router.replace("/sysUser");
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            }
            if (this.newPassword) {
              this.$api
                .editUser({
                    nickName: this.sendNickName,
                    mobile: this.sendMobile,
                    id: this.id,
                    projectName: this.sendProjectName,
                    projectContact: this.sendProjectContact,
                    isNotifyProjectMobile: this.sendIsNotifyProjectMobile,
                    projectMobile: this.sendProjectMobile,
                    isCallMobile: Number(this.isCallMobile),
                    isSendMsg: Number(this.isSendMsg),
                    roleIds: this.sendRoleId,
                    area: this.sendArea,
                    address: this.sendAddress,
                    city: this.sendCity,
                    password: this.sendPassword,
                    province: this.sendProvince,
                    isEnable: this.sendIsEnable,
                    username: this.ruleForm.userName,
                    accountDegree: this.sendAccountLevel,
                      isNotify: this.ruleForm.isNotify,
                      isProjectLinkCall: this.ruleForm.isProjectLinkCall,
                    notifyData: this.ruleForm.notifyData,
                  },
                  token
                )
                .then(res => {
                  // console.log(res.data.code==200);
                  if (res.data.code == 200) {
                    this.$message.success("编辑成功!");
                    this.$router.replace("/sysUser");
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            return false;
          }
        });
      },
      //删除用户函数
      deleteHandler() {
        var token = this.$store.state.userinfo.token;
        this.$api
          .deleteUser(this.userId, token)
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.log(err);
          });
      },
      // 是否选择报警温度
      isNotifyFn() {
        if (this.ruleForm.isNotify == '1') {
          this.dialogVisible = true
        } else {
          this.dialogVisible = false
        }

      },
      //返回账号列表页面
      goBackHandler() {
        this.$router.replace("/sysUser");
      },
      //改变禁用启用状态
      changeIsEnable(status) {
        console.log(status);
        this.sendIsEnable = Number(status);
      },
      //改变角色
      changeRoleName(status) {
        console.log(status);
        this.sendRoleId = []
        this.sendRoleId.push(Number(status));
      },
      //改变账号等级
      changeAccountLevel(status) {
        console.log(status);
        this.sendAccountLevel = Number(status);
      },
      //密码适应函数
      changePassWord() {
        /*this.ifPassword = false;
                  this.ifChangePass = true;
                  this.ifSendNewPassword = true;*/
        this.isShowUpdate = false;
        this.isUpdatePwd = true;
        this.isShowCancle = true;
      },
      //联级选择器点击函数
      handleChange(value) {
        console.log(value);
      },
      //点击获取最后一级
      getChange(val) {
        var result = this.$refs["cascader"].getCheckedNodes(true);
        // console.log(result[0].data.name);
        this.ruleForm.area = result[0].data.name;
      },
      //是否开始账号展示函数
      stateFormat(row, column) {
        if (row.isEnable === 1) {
          return "禁用";
        } else {
          return "启用";
        }
      },
      //是否开启语音展示
      stateFormatMobile(row, column) {
        if (row.isCallMobile === 1) {
          return "是";
        } else {
          return "否";
        }
      },
      //是否发送短信展示
      stateFormatMsg(row, column) {
        if (row.isSendMsg === 1) {
          return "是";
        } else {
          return "否";
        }
      },
      //分配子账号函数
      dispatchUser() {
        if (this.childIds.length == 0) {
          this.dispatchDialogVisible = true;
        } else {
          this.parentDialogFormVisible = true;
          this.dispatchValue = "";
          this.parentId = "";
        }
      },
      //获得多选框的id数组
      getChildIds(selection, row) {
        console.log(selection);
        this.childIds = [];
        selection.forEach(item => {
          this.childIds.push(item.id);
        });
        console.log(this.childIds);
      },
      //分配子账号接口函数
      dispatchUserHandler() {
        if (this.parentId) {
          this.parentDialogFormVisible = false;
          this.$api
            .childDispatchHandler({
              childIds: this.childIds,
              parentId: this.parentId
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success("分配成功!");
                this.childIds = [];
                this.$api
                  .userChild(this.userId)
                  .then(res => {
                    console.log(res.data.data);
                    this.tableData = res.data.data;
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.$message.error("请先选择目标分配账号！");
        }
      },
      //清空校验函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      //取消修改函数
      cancelChange() {
        this.resetForm("ruleForm");
        var token = this.$store.state.userinfo.token;
        this.$api
          .userDetailId(this.userId, token)
          .then(res => {
            console.log(res.data.data);
            this.id = res.data.data.id;
            this.ruleForm.accountLevel = res.data.data.accountDegree;
            if (res.data.data.username == null) {
              this.ruleForm.userName = "暂无数据";
            } else {
              this.ruleForm.userName = res.data.data.username; //用户名
            }
            if (res.data.data.createTime == null) {
              this.ruleForm.createTime = "暂无数据";
            } else {
              this.ruleForm.createTime = res.data.data.createTime; //创建时间
            }
            if (res.data.data.updateTime == null) {
              this.ruleForm.updateTime = "暂无数据";
            } else {
              this.ruleForm.updateTime = res.data.data.updateTime; //更新时间
            }
            if (res.data.data.nickName == null) {
              this.ruleForm.inputNickName = "暂无数据";
            } else {
              this.ruleForm.inputNickName = res.data.data.nickName; //昵称
            }
            if (res.data.data.mobile == null) {
              this.ruleForm.mobile = "暂无数据";
            } else {
              this.ruleForm.mobile = res.data.data.mobile; //手机
            }

            //是否启用（所有人可以更改）
            if (res.data.data.isEnable == 0) {
              this.sendIsEnable = 0;
              this.ruleForm.isEnable = "启用";
            } else {
              this.sendIsEnable = 1;
              this.ruleForm.isEnable = "禁用";
            }
            //角色名称
            if (res.data.data.roleName == null) {
              this.ruleForm.roleName = "暂无数据";
            } else {
              this.ruleForm.roleName = res.data.data.roleName;
            }
            //项目名称
            if (res.data.data.projectName == null) {
              this.ruleForm.projectName = "暂无数据";
            } else {
              this.ruleForm.projectName = res.data.data.projectName;
            }
            //项目联系人手机
            if (res.data.data.projectMobile == null) {
              this.ruleForm.projectMobile = "暂无数据";
            } else {
              this.ruleForm.projectMobile = res.data.data.projectMobile;
            }
            //项目联系人
            if (res.data.data.projectContact == null) {
              this.ruleForm.projectContact = "暂无数据";
            } else {
              this.ruleForm.projectContact = res.data.data.projectContact; //项目联系人
            }
               //紧急联系人
            if (res.data.data.isNotifyProjectMobile == null) {
              this.ruleForm.isNotifyProjectMobile = "暂无数据";
            } else {
              this.ruleForm.isNotifyProjectMobile = res.data.data.isNotifyProjectMobile.toString(); //项目联系人
            }
            //设备数量
            if (res.data.data.deviceCount == null) {
              this.ruleForm.deviceCount = "暂无数据";
            } else {
              this.ruleForm.deviceCount = res.data.data.deviceCount;
            }
            //所属行政区
            if (res.data.data.address == null) {
              this.ruleForm.address = "暂无数据";
            } else {
              this.ruleForm.address = res.data.data.address;
            }
            //省市区三级联动选择
            if (res.data.data.province == null) {
              this.ruleForm.province = "暂无数据"; //省
            } else {
              this.ruleForm.province = res.data.data.province;
            }
            if (res.data.data.city == null) {
              this.ruleForm.city = "暂无数据"; //市
            } else {
              this.ruleForm.city = res.data.data.city;
            }
            if (res.data.data.area == null) {
              this.ruleForm.area = "暂无数据"; //区
            } else {
              this.ruleForm.area = res.data.data.area;
            }
            this.defalutPlace =
              this.ruleForm.province +
              "/" +
              this.ruleForm.city +
              "/" +
              this.ruleForm.area;
            //是否发送短信
            if (res.data.data.isSendMsg == 1) {
              this.thisSendMsg = true;
              this.isSendMsg = 1;
            } else {
              this.thisSendMsg = false;
              this.isSendMsg = 0;
            }
            if (res.data.data.isCallMobile == 1) {
              this.thisCallMobile = true;
              this.isCallMobile = 1;
              console.log(this.thisCallMobile);
            } else {
              this.thisCallMobile = false;
              this.isCallMobile = 0;
              console.log(this.thisCallMobile);
            }
            //项目创建人
            if (res.data.data.sysUserName == null) {
              this.ruleForm.sysUserName = "暂无数据";
            } else {
              this.ruleForm.sysUserName = res.data.data.sysUserName;
            }

               if (res.data.data.isNotify == 0) {
            this.ruleForm.isNotify = "0";
            this.dialogVisible = false
          } else {
            this.ruleForm.isNotify = res.data.data.isNotify.toString(); //是否报警温度
            this.dialogVisible = true
          }
                if (res.data.data.isProjectLinkCall == 0) {
            this.ruleForm.isProjectLinkCall = "0";
           
          } else {
            this.ruleForm.isProjectLinkCall = res.data.data.isProjectLinkCall.toString(); //是否报警温度
          
          }

             if (res.data.data.notifyData == null) {
            this.ruleForm.notifyData = "60";
          } else {
            this.ruleForm.notifyData = res.data.data.notifyData.toString(); //是否报警温度
          }
          })
          .catch(err => {
            console.log(err);
          });
      },
      //选择目标分配账号函数
      changeUser(val) {
        console.log(val);
        this.parentId = val;
      }
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }

    .el-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .el-col {
      border-radius: 4px;
    }

    .bg-purple-dark {
      // background: #99a9bf;
      font-size: 12 /16rem;
    }

    .grid-content {
      border-radius: 4px;
      min-height: 36px;
    }

    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #cccccc;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        color: @toolBarFontColor;
        font-size: 0.8rem;
        float: right;
        margin-top: 7px;
      }
    }

    .middle {
      overflow: hidden;
      background-color: #ffffff;
      margin: 10px 3rem 0 3rem;
    }
  }

  .passwordButton {
    // display: inline-block;
    margin-left: 30px;
  }

  .funButton {
    float: right;
  }

  .editButton {
    margin-right: 20px;
  }

  .ceshi-vv {
    padding: 0 3rem;
    margin-top: 10px;

    .item-vv {
      margin-top: 5px;
    }

    .item-vv-span {
      color: #898989;
      font-weight: normal;
    }
  }

  .changePaeewordBut {
    /*padding: 0px !important;
        float: left;*/
    margin-left: 5px;
  }

  .editBut {
    padding: 0 3rem;
    margin-top: 10px;
  }

  .el-form-item__label {
    font-size: 12px !important;
  }
</style>