<template>
    <div class="mainContainer">
        <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
            <span>确定删除吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelDelete">取 消</el-button>
                <el-button type="primary" @click="handleDelClose">确 定</el-button>
            </span>
        </el-dialog>
        <div class="select">
            <span class="title">统计行为列表</span>
            <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新
            </el-button>
            <el-button type="text" size="small" icon="el-icon-plus" class="select-button" @click="showAddProductDialog">
                新增</el-button>
        </div>
        <div class="table-vv">
            <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
            </div>
            <el-table :data="tableData" stripe size="small" class="product-table"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="isEnable" label="是否启用" align="center">
                    <template slot-scope="scope">
                        <el-select style="width:105px" v-model="scope.row.isEnable" v-show="scope.row.iseditor"
                            size="mini" placeholder="请选择">
                            <el-option label="否" value="0"></el-option>
                            <el-option label="是" value="1"></el-option>
                        </el-select>
                        <span v-show="!scope.row.iseditor">{{scope.row.isEnable == '0'?"否":"是"}}</span>
                    </template>
                </el-table-column>
                 <el-table-column prop="behaviorSource" label="行为来源" align="center">
                    <template slot-scope="scope">
                        <!-- <el-select style="width:105px" v-model="scope.row.behaviorSource" 
                            size="mini" placeholder="请选择">
                            <el-option label="平台" value="0"></el-option>
                            <el-option label="APP" value="1"></el-option>
                        </el-select> -->
                        <span >{{scope.row.behaviorSource == '0'?"平台":"APP"}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="统计行为描述信息" align="center"></el-table-column>
                <el-table-column prop="calculateUv" label="是否计算UV" align="center">
                    <template slot-scope="scope">

                        <span>{{scope.row.calculateUv == '0'?"否":"是"}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="requestMethod" label="需统计的请求方法" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" :formatter="dateFormat" align="center">
                </el-table-column>
                <el-table-column prop="updateTime" label="更新时间" :formatter="dateFormat" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center"  width="150">
                    <template slot-scope="scope">
                        <el-button @click="save(scope.row)" v-show="scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">修改
                        </el-button>
                        <el-button @click="edit(scope.row,scope)" v-show="!scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">编辑
                        </el-button>
                        <el-button @click="deleteProductD(scope.row.id)" type="text" size="small" icon="el-icon-delete">
                            删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
                layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
            </el-pagination>
        </div>
        <el-dialog title="新增帮助中心类型" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form">
                  <el-form-item label="行为来源" :label-width="formLabelWidth" prop="behaviorSource">
                    <el-select style="width:315px" v-model="form.behaviorSource" placeholder="请选择行为来源">
                        <el-option label="平台" value="0"></el-option>
                        <el-option label="APP" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否计算UV" :label-width="formLabelWidth" prop="calculateUv">
                    <el-select style="width:315px" v-model="form.calculateUv" placeholder="请选择是否计算UV">
                        <el-option label="否" value="0"></el-option>
                        <el-option label="是" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="统计行为描述信息" :label-width="formLabelWidth" prop="description">
                    <el-input style="width:315px" v-model="form.description" autocomplete="off" type="textarea"  placeholder="请填写描述信息"
                        :rows="2"></el-input>
                </el-form-item>
                <el-form-item label="是否启用" :label-width="formLabelWidth" prop="isEnable">
                    <el-select style="width:315px" v-model="form.isEnable" placeholder="请选择是否计算UV">
                        <el-option label="否" value="0"></el-option>
                        <el-option label="是" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="需统计的请求方法" :label-width="formLabelWidth" prop="requestMethod">
                    <el-input style="width:315px" v-model="form.requestMethod" autocomplete="off"  placeholder="请填写需统计的请求方法"></el-input >
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    export default {
        name: "statisticsBehavior",
        data() {
            return {
                productDel: false,
                productAdd: false,
                iseditor: false,
                form: {
                    calculateUv: "",
                    description: "",
                    isEnable: "",
                    requestMethod: "",
                    behaviorSource:"",
                },
                visible: false,
                total: 0,
                tableData: [],

                chooseOnlineStatus: "", //搜索选择
                sysUserlist: [], //账号所属类型

                checked: false,
                //新增账号弹出对话框标记
                dialogVisible: false,
                //修改账号弹出对话框标记
                modifydialogVisible: false,

                //产品品类列表
                categories: [],

                value: "",
                input: "",
                current: 1,
                pageSize: 10,
                delDialogVisible: false,
                productId: "",
                indexId: "", //反显id
                nameinput: "", //搜索输入
                indexIdlist: "", //反显列表
                formLabelWidth: "150px",
                rules: {
                    behaviorSource: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }, ],
                     calculateUv: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }, ],
                    isEnable: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }, ],
                    description: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],
                    requestMethod: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],

                }
            };
        },
        methods: {
            titleTest(rule, value, callback) {
                // const title= /^[\u0391-\uFFE5A-Za-z]+$/
                const title = /^[\a-\z\A-\Z0-9\u4e00-\u9fe5]+$/
                if (!title.test(value)) {
                    callback(new Error('标题只能输入中文、数字和英文'))
                } else {
                    callback()
                }
            },
            refresh() {
                this.questionStatus();
            },
            dateFormat(row, column, cellValue, index) {
                // console.log(row, column, cellValue, index)
                const daterc = row[column.property]
                if (daterc != null) {
                    var date = new Date(daterc);
                    var year = date.getFullYear();
                    /* 在日期格式中，月份是从0开始，11结束，因此要加0
                     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                     * */
                    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
                    // 拼接
                    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
                }
            },


            handlePageChange(val) {

                this.current = val;
                this.questionStatus();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.questionStatus();
            },


            deleteProductD(data) {
                this.delDialogVisible = true;
                this.productId = data;
            },
            cancelDelete() {
                this.delDialogVisible = false;
                this.productId = "";
            },
            handleDelClose() {
                this.delDialogVisible = false;
                this.deleteProduct(this.productId);
            },
            // 修改
            edit(row, index) {
                row.isEnable = row.isEnable == 0 ? '否' : '是'
                row.iseditor = true
                console.log(row)
            },
            save(item) {
                console.log(item)
                if(item.isEnable == '否'){
                    item.isEnable = 0
                }else if(item.isEnable == '是'){
                    item.isEnable = 1
                }
                this.$api
                    .statisticsBehaviorUpdate({
                        isEnable : item.isEnable ,
                        id: item.id
                    })
                    .then(res => {
                        if (res.data.code == 200) {

                            this.$message.success("修改成功");
                            this.questionStatus();
                            this.iseditor = false
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            deleteProduct(data) {

                this.$api
                    .statisticsBehavioDelete(data)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功!");
                            this.questionStatus();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            //新增产品
            addProduct() {
                this.dialogVisible = false;
            },
            questionStatus() {
                //查询列表
                this.$api
                    .statisticsBehaviorList({
                        current: this.current,
                        size: 10
                    })
                    .then(res => {
                        console.log(res.data.data.records)

                        for (let index = 0; index < res.data.data.records.length; index++) {
                            res.data.data.records[index].iseditor = false
                        }
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;


                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            showAddProductDialog() {
                this.dialogVisible = true;
                this.form.categoryName = "";
                     this.form.calculateUv= "";
                       this.form.description= "";
                       this.form.isEnable= "";
                       this.form.requestMethod= "";
                       this.form.behaviorSource= "";
                this.$nextTick(() => {
                    this.$refs["form"].clearValidate();
                });
            },
            // 新增账号接口
            submitForm(formName) {
                this.$refs["form"].validate(valid => {
                    if (valid) {

                        this.dialogVisible = false;
                        this.$api.statisticsBehavioAdd({
                            calculateUv: this.form.calculateUv,
                            description: this.form.description,
                            isEnable: this.form.isEnable,
                            requestMethod: this.form.requestMethod,
                            behaviorSource:this.form.behaviorSource
                        }).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("添加成功!");
                                this.questionStatus();
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },

            // 账号列表新增平台账号下拉框接口
            showaccountListSelector() {}
        },
        created() {
            //查询产品列表
            this.questionStatus();
            this.showaccountListSelector();
        }
    };
</script>
<style lang="less" scoped>
    @toolBarFontColor: rgba(17, 17, 17, 0.87);

    .el-row {
        margin: 1rem 3rem;
        line-height: 0;
        min-width: 900px;

        .platform-toolbar {
            position: relative;
            margin: 0;
            border: 1px solid #d9d9d9;

            .title-info {
                height: 46px;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .el-col {
                padding: 0 3rem;

                .toolbar-right {
                    float: right;
                    margin-top: 7px;

                    &.export {
                        margin-left: 1rem;

                        .toolbar-btn {
                            color: @toolBarFontColor;
                            font-size: 0.9rem;

                            &.left {
                                margin-left: 1.5rem;
                            }

                            &.right {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .title-info {
                float: left;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        .result-info-wrapper {
            height: 1rem;
            margin: 1rem 0 0.5rem 0;
            position: relative;

            .setting-panel {
                position: absolute;
                float: right;
                top: 2rem;
                width: 200px;
                height: 200px;
                background: red;
                z-index: 1000;
                border-radius: 3px;
            }

            .result-info {
                height: 1rem;
                line-height: 1rem;
                font-size: 0.875rem;
                float: left;
                color: #898989;

                span {
                    color: #292929;
                    padding: 0 0.1rem;
                }
            }

            .data-setting {
                font-size: 0.875rem;
                color: #2673bb;
                float: right;

                i {
                    padding-right: 0.2rem;
                }

                cursor: pointer;
            }
        }

        .el-table td,
        .el-table th {
            padding: 0 !important;
        }

        .el-pagination {
            margin: 1rem 0;
            float: right;
        }
    }

    .product-content {
        height: 6rem;

        .product-info-item {
            float: left;
            margin-right: 4rem;

            .label-info {
                margin-bottom: 0.5rem;

                label {
                    font-size: 0.75rem;

                    span {
                        color: red;
                        margin-right: 0.5rem;
                    }
                }
            }

            .el-input {
                width: 10rem;
            }

            .el-select {
                width: 13rem;
            }
        }
    }

    .container-el-row {
        margin-top: 0px;
    }

    .select {
        height: 46px;
        overflow: hidden;
        border-bottom: 1px solid #d9d9d9;
        padding: 0rem 3rem;

        .title {
            display: inline-block;
            line-height: 46px;
            font-weight: bold;
            font-size: 0.8rem;
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            margin-top: 7px;
            margin-left: 15px;
        }

        .select-input-right-input {
            float: right;
            margin-top: 7px;
        }
    }

    .table-vv {
        padding: 0rem 3rem;
        margin-top: 1rem;

        .result-info {
            height: 1rem;
            line-height: 1rem;
            font-size: 0.875rem;
            float: left;
            color: #898989;

            span {
                color: #292929;
                padding: 0 0.1rem;
            }
        }

        .el-pagination {
            margin: 1rem 0;
            float: right;
        }
    }
</style>