import { render, staticRenderFns } from "./batchCodes.vue?vue&type=template&id=e33be3e8&scoped=true&"
import script from "./batchCodes.vue?vue&type=script&lang=js&"
export * from "./batchCodes.vue?vue&type=script&lang=js&"
import style0 from "./batchCodes.vue?vue&type=style&index=0&id=e33be3e8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e33be3e8",
  null
  
)

export default component.exports