<template>
    <div class="outting">
        <div class="select">
            <span class="title">活动列表</span>
            <el-button type="text" size="small" icon="el-icon-plus" @click="addUserHandler" class="addRoleButton">创建活动
            </el-button>
   
        </div>
        <div class="table-vv">
            <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">
                    <el-table-column prop="activityName" label="活动名称 " min-width="2" fixed align="center"> </el-table-column>
                <el-table-column prop="activityType" label="活动类型" min-width="2" fixed align="center">
                      <template slot-scope="{row}">{{ row.activityType  == 0?'抽奖': row.activityType  == 1?'兑换':'领取' }}</template>
                </el-table-column>
                <el-table-column prop="activityDesc" label="活动描述" min-width="2" fixed align="center"></el-table-column>
                <el-table-column prop="activityCode" label="活动编码" min-width="2" fixed align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center"></el-table-column>
                <el-table-column min-width="1" label="操作" width="180" align="center">
                    <template slot-scope="scope">
                           <el-button type="text" size="small" icon="el-icon-circle-plus-outline"
                                @click="addprizes(scope.$index, scope.row)" style="margin-left:10px;"
                                slot="reference">创建奖品</el-button>
                        <el-popconfirm title="确定删除这个活动吗？" @onConfirm="deleteAddHandler()">
                            <el-button type="text" size="small" icon="el-icon-delete"
                                @click="handleDelete(scope.$index, scope.row)" style="margin-left:10px;"
                                slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="total" class="pagin"
                @current-change="changeCurrent"></el-pagination>
        </div>
        <el-dialog title="创建活动" :visible.sync="addDialogFormVisible" :close-on-click-modal="false">
            <el-form :model="addForm" size="small"  style="50%" :rules="addRules" ref="addRules"
                enctype="multipart/form-data">
                   <el-form-item label="活动名称" :label-width="formLabelWidth" prop="activityName">
                    <el-input v-model="addForm.activityName" autocomplete="off" style="width:315px" >
                    </el-input>
                </el-form-item>
                <el-form-item label="活动类型" :label-width="formLabelWidth" prop="activityType"> 
                    <el-select v-model="addForm.activityType " placeholder="请选活动类型">
                        <el-option label="抽奖" value="0"></el-option>
                        <el-option label="兑换" value="1"></el-option>
                        <el-option label="领取" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动描述" :label-width="formLabelWidth" prop="activityDesc">
                    <el-input v-model="addForm.activityDesc" autocomplete="off" style="width:315px" type="textarea" :rows="2">
                    </el-input>
                </el-form-item>
             
             

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addAppListForm('addForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>
      
    </div>
</template>
<script>
    export default {
        name: "AppList",
        data() {
          
            return {
               
                current: 1,
                size: 10,
                total: 0,
                tableData: [],
                deleteId: "",
                editId: "",
                addDialogFormVisible: false,
                // 限制开始时间
                pickerOptionsStart: {},
                pickerOptionsEnd: {},
                addForm: {
                    activityName: "",
                    activityType: "",
                    activityDesc: "",
                },
            
                addRules: {
                    activityName: [{
                        required: true,
                        message: "请输入活动名称",
                        trigger: "blur"
                    }],
                    activityType: [{
                            required: true,
                            message: "选择活动类型",
                            trigger: "change"
                        }
                    ],  
                    activityDesc: [{
                            required: true,
                            message: "请输入活动描述",
                            trigger: "blur"
                        },],
                },
                formLabelWidth: "180px",
            };
        },
        created() {
            this.appList();
        },
        methods: {
            addprizes(index,data){
       this.$router.push({
        path: `/activityPrizes`,
        query: {
            data: data
          }
      });
            },
            //删除函数
            handleDelete(index, row) {
                this.deleteId = row.id;
            },
            //删除确定
            deleteAddHandler() {
                this.$api.activityDelete(this.deleteId).then(res => {
                    console.log(res.data);
                    if (res.data.code == 200) {
                        this.$message.success("删除成功!");
                        this.appList();
                    }
                });
            },
         

            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            appList() {
                this.$api
                    .activityList({
                        current: this.current,
                        size: 10
                    })
                    .then(res => {
                        // console.log(res.data);
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                    });
            },
            addUserHandler() {
                this.file = {};
                this.addDialogFormVisible = true;
                if (this.$refs["addRules"] !== undefined) {
                    this.resetForm("addRules");
                }
            },
      deleteFn(item) {
        console.log(item)
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i] == item) {
            this.items.splice(i, 1)
          }
        }
      },
            changeCurrent(id) {
                this.current = id;
                this.appList();
            },

            changeAppType(id) {
                // console.log(id);
                this.addappType = Number(id);
            },
         

            addAppListForm(formName) {
                console.log(this.addForm,this.items)
                this.addForm.questionOptions = this.items
                this.$refs["addRules"].validate(valid => {
                    if (valid) {
                        this.addDialogFormVisible = false;
                        this.$api.activityAdd(this.addForm).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("创建活动成功！");
                                this.file = {};
                                this.appList();
                            }
                        });

                    } else {
                        return false;
                    }
                });
            },
         
   
   


        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;
      .new_add{
          width: 100%;
      }
      
  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 3px;
    margin-left: 15px;
  }
        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .select-status {
                width: 9rem;
                margin-left: 1.7rem;
                margin-top: 7px;
                // margin-top:7px;
            }

            .select-input-timer {
                float: right;
            }

            .select-status {
                float: right;
            }

            .select-input-right-input {
                float: right;
                // width: 8rem;
                line-height: 46px;
            }

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .addRoleButton {
                margin-left: 15px;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }

            .update-input-timer {
                float: right;
                line-height: 46px;
                margin-left: 25px;
                height: 46px;

                .demonstrationTwo {
                    float: left;
                    font-size: 0.8rem;
                }

                .update-input-timer-select {
                    width: 11rem;
                    margin-left: 5px;
                }
            }
        }

        .table-vv {
            padding: 1rem 3rem;

            .pagin {
                margin-left: 30%;
                margin-top: 10px;
                float: right;
            }
        }
    }
</style>