import { render, staticRenderFns } from "./userPrizeDetail.vue?vue&type=template&id=4e37f305&scoped=true&"
import script from "./userPrizeDetail.vue?vue&type=script&lang=js&"
export * from "./userPrizeDetail.vue?vue&type=script&lang=js&"
import style0 from "./userPrizeDetail.vue?vue&type=style&index=0&id=4e37f305&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e37f305",
  null
  
)

export default component.exports