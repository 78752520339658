<template>
    <div class="outting">
        <div class="select">
            <span class="title">全民学消防题库列表</span>
            <el-button type="text" size="small" icon="el-icon-plus" @click="addUserHandler" class="addRoleButton">新增题目
            </el-button>
          <el-input style="width:200px;margin-top:6px" v-model="questionContent" class="select-button" prefix-icon="el-icon-search"
                placeholder="请输入问题内容" size="small" @keyup.enter.native="appList"></el-input>
            <el-upload style="display:inline" class="select-button" action :on-change="handleChange"
                :on-remove="handleRemove" :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList"
                :limit="limitUpload" :auto-upload="false" ref="my-upload">
                <el-button type="info" round size="mini">批量导入题目</el-button>
            </el-upload>
        </div>
        <div class="table-vv">
            <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <el-table-column prop="questionType" label="问题类型" min-width="2" fixed align="center">
                    <template slot-scope="{row}">{{ row.questionType == 0?'单选题':"" }}</template>
                </el-table-column>
                <el-table-column prop="questionContent" label="问题题目内容 " min-width="2" fixed align="center">
                </el-table-column>
                <el-table-column prop="questionOptions" label="问题选项" min-width="2" fixed align="center">
                </el-table-column>
                <el-table-column prop="answer" label="正确答案" min-width="2" fixed align="center"></el-table-column>
                <el-table-column prop="questionAnalysis" label="答案解析" min-width="2" fixed align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" min-width="2" fixed align="center"></el-table-column>


                <el-table-column min-width="1" label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" icon="el-icon-edit"
                            @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
                        <el-popconfirm title="确定删除这个题目吗？" @onConfirm="deleteAddHandler()">
                            <el-button type="text" size="small" icon="el-icon-delete"
                                @click="handleDelete(scope.$index, scope.row)" style="margin-left:10px;"
                                slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="total" class="pagin"
                @current-change="changeCurrent"></el-pagination>
        </div>
        <el-dialog title="新增题目" :visible.sync="addDialogFormVisible" :close-on-click-modal="false" width="70%">
            <el-form :model="addForm" size="small" :rules="addRules" ref="addRules" enctype="multipart/form-data">

                <el-form-item label="题型" :label-width="formLabelWidth" prop="questionType">
                    <el-select v-model="addForm.questionType" placeholder="请选题目类型">
                        <el-option label="单选题" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问题题目内容" :label-width="formLabelWidth" prop="questionContent">
                    <el-input v-model="addForm.questionContent" autocomplete="off" style="width:315px" type="textarea"
                        :rows="2">
                    </el-input>
                </el-form-item>
                <el-form-item label="答案选项" :label-width="formLabelWidth" prop="questionOptions">
                    <div v-for="(item,i) of items" :key="i" class="new_add">
                        <el-input size="mini" clearable type="textarea" :rows="2"
                            style="width:315px;margin-bottom:1rem;" v-model="items[i]"></el-input>
                        <el-button size="mini" style="margin-left:1rem;float:right;" @click="deleteFn(items[i])">删除
                        </el-button>
                        <el-button size="mini" style="margin-left:1rem;float:right;" type="info" @click="onAdd()">新增选项答案
                        </el-button>


                    </div>
                </el-form-item>
                <el-form-item label="正确答案" :label-width="formLabelWidth" prop="answer">
                    <el-input v-model="addForm.answer" autocomplete="off" style="width:315px" type="textarea" :rows="2">
                    </el-input>
                </el-form-item>
              <el-form-item label="答案解析" :label-width="formLabelWidth" prop="answer">
                <el-input v-model="addForm.questionAnalysis" autocomplete="off" style="width:315px" type="textarea" :rows="2">
                </el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addAppListForm('addForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改正确答案" :visible.sync="editDialogFormVisible" :close-on-click-modal="false">
            <el-form :model="editForm" size="small" :inline="true" style="width:700px" :rules="editRules"
                :auto-upload="false" ref="editRules">
                <el-form-item label="正确答案" :label-width="formLabelWidth" prop="answer">
                    <el-input v-model="editForm.answer" autocomplete="off" style="width:215px" type="textarea"
                        :rows="2"></el-input>
                </el-form-item>
              <el-form-item label="答案解析" :label-width="formLabelWidth" prop="answer">
                <el-input v-model="editForm.questionAnalysis" autocomplete="off" style="width:215px" type="textarea"
                          :rows="2"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="editAppListForm('editForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: "answerList",
        data() {
            var valiIcon = (rule, value, callback) => {
                console.log(this.items.length)
                if (this.items.length < 2) {
                    callback(new Error('请填写选项'));
                } else {
                    callback();
                }
            };
            return {
                items: [
                    ""
                ],
                questionContent:"",
                current: 1,
                fileList: [],
                limitUpload: 1,
                size: 10,
                total: 0,
                tableData: [],
                file: {},
                editFile: {},
                deleteId: "",
                editId: "",
                addDialogFormVisible: false,
                // 限制开始时间
                pickerOptionsStart: {},
                pickerOptionsEnd: {},
                addForm: {
                    questionType: "",
                    questionOptions: "",
                    questionContent: "",
                    answer: "",
                    questionAnalysis: ""
                },
                editForm: {
                    answer: "",
                    questionAnalysis: ""
                },
                addforceUpdate: "",
                addappType: "",
                editDialogFormVisible: false,
                addRules: {
                    questionType: [{
                        required: true,
                        message: "请选择题目类型",
                        trigger: "change"
                    }],
                    questionContent: [{
                            required: true,
                            message: "请输入问题题目内容",
                            trigger: "blur"
                        },

                    ],
                    questionOptions: [{
                        required: true,
                        validator: valiIcon,
                        trigger: 'blur'
                    }],

                    answer: [{
                        required: true,
                        message: "请输入正确答案",
                        trigger: "blur"
                    }],

                },
                editRules: {
                    answer: [{
                        required: true,
                        message: "请输入正确答案,",
                        trigger: "blur"
                    }],

                },
                formLabelWidth: "180px",
                editforceUpdate: "",
                editappType: ""
            };
        },
        created() {
            this.appList();
        },
        methods: {
            //删除函数
            handleDelete(index, row) {
                this.deleteId = row.id;
            },
            //删除确定
            deleteAddHandler() {
                this.$api.integralquestionDelete(this.deleteId).then(res => {
                    console.log(res.data);
                    if (res.data.code == 200) {
                        this.$message.success("删除成功!");
                        this.appList();
                    }
                });
            },
            //更新函数
            handleUpdate(index, row) {
                this.editId = row.id;
                this.editFile = {};
                this.editDialogFormVisible = true;
                if (this.$refs["editRules"] !== undefined) {
                    this.resetForm("editRules");
                }
                console.log(row);

                this.editForm.answer = row.answer
              this.editForm.questionAnalysis = row.questionAnalysis

            },

            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            appList() {
                this.$api
                    .integralquestionList({
                        page: {
                            current: this.current,
                            size: 10,
                        },
                        queryDto:{
                            questionContent:this.questionContent
                        }
                    })
                    .then(res => {
                        // console.log(res.data);
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                    });
            },
            addUserHandler() {
                this.file = {};
                this.items = [
                        ""
                    ],
                    this.addDialogFormVisible = true;

                if (this.$refs["addRules"] !== undefined) {
                    this.resetForm("addRules");
                }
            },
            onAdd() {
                this.items.push('')
            },
            deleteFn(item) {
                console.log(item)
                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i] == item) {
                        this.items.splice(i, 1)
                    }
                }
            },
            changeCurrent(id) {
                this.current = id;
                this.appList();
            },

            changeAppType(id) {
                // console.log(id);
                this.addappType = Number(id);
            },
            editchangeAppType(id) {
                // console.log(id);
                this.editappType = Number(id);
            },
            addAppListForm(formName) {
                console.log(this.addForm, this.items)
                this.addForm.questionOptions = this.items
                this.$refs["addRules"].validate(valid => {
                    if (valid) {
                        this.addDialogFormVisible = false;
                        this.$api.integralquestionAdd(this.addForm).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("新增更新提示成功！");
                                this.file = {};
                                this.appList();
                            }
                        });

                    } else {
                        return false;
                    }
                });
            },
            editAppListForm(editForm) {
                this.$refs["editRules"].validate(valid => {
                    if (valid) {
                        this.editDialogFormVisible = false;

                        this.editForm.id = this.editId

                        this.$api.integralquestionUpdate(this.editForm).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("编辑成功！");
                                this.editFile = {};
                                this.appList();
                            }
                        });


                    } else {
                        return false;
                    }
                });
            },
            // handleChange(file, fileList) {
            //     console.log(file);
            //     this.file = []
            //     this.file = file.raw;
            // },
            editHandleChange(file, fileList) {
                console.log(file);
                this.editFile = file.raw;
            },
            handleChange(file, fileList) {
                this.fileTemp = file.raw;
                if (this.fileTemp) {
                    let formData = new FormData();
                    formData.append("file", this.fileTemp);
                    this.$api.integralquestionImport(formData).then(res => {
                        if (res.data.code == 200) {
                            this.$message({
                                type: "success",
                                message: "导入成功"
                            });
                            this.appList();
                        }
                    });
                } else {
                    this.$message({
                        type: "warning",
                        message: "请上传附件！"
                    });
                }
            },
            //超出最大上传文件数量时的处理方法
            handleExceed(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },
            //移除文件的操作方法
            handleRemove(file, fileList) {
                this.fileTemp = null;
                this.tableData = [];
            },

            importfxx(obj) {
                let _this = this;
                let inputDOM = this.$refs.inputer;
                // 通过DOM取文件数据
                this.file = event.currentTarget.files[0];
                var rABS = false; //是否将文件读取为二进制字符串
                var f = this.file;
                var reader = new FileReader();

                //if (!FileReader.prototype.readAsBinaryString) {
                FileReader.prototype.readAsBinaryString = function (f) {
                    var binary = "";
                    var rABS = false; //是否将文件读取为二进制字符串
                    var pt = this;
                    var wb; //读取完成的数据
                    var outdata;
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        var bytes = new Uint8Array(reader.result);
                        var length = bytes.byteLength;
                        for (var i = 0; i < length; i++) {
                            binary += String.fromCharCode(bytes[i]);
                        }

                        //此处引入，用于解析excelnpm
                        var XLSX = require("xlsx");

                        if (rABS) {
                            wb = XLSX.read(btoa(fixdata(binary)), {
                                //手动转化
                                type: "base64"
                            });
                        } else {
                            wb = XLSX.read(binary, {
                                type: "binary"
                            });
                        }
                        outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                        //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
                        //此处可对数据进行处理
                        let arr = [];

                        outdata.map(v => {
                            let questionContent = v["问题内容"];
                            let questionOptions = v["问题选项"];
                            let answer = v["正确答案"];
                            let questionType = v["题目类型"];
                            if (questionContent != "" || questionOptions != "" || questionType != "" ||
                                answer != "") {
                                arr.push({
                                    questionContent: questionContent,
                                    questionOptions: questionOptions,
                                    answer: answer,
                                    questionType: questionType,
                                });
                            }
                        });
                        _this.nameData = arr;

                        console.log(_this.nameData);

                        _this.$api.integralquestionImport(formData).then(res => {
                            if (res.data.code == 200) {
                                _this.$message({
                                    type: "success",
                                    message: "导入成功，共导入" + arr.length + "题"
                                });
                            }
                        });
                        return arr;
                    };
                    reader.readAsArrayBuffer(f);
                };
                if (rABS) {
                    reader.readAsArrayBuffer(f);
                } else {
                    reader.readAsBinaryString(f);
                }
            },


        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .new_add {
            width: 100%;
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            margin-top: 3px;
            margin-left: 15px;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .select-status {
                width: 9rem;
                margin-left: 1.7rem;
                margin-top: 7px;
                // margin-top:7px;
            }

            .select-input-timer {
                float: right;
            }

            .select-status {
                float: right;
            }

            .select-input-right-input {
                float: right;
                // width: 8rem;
                line-height: 46px;
            }

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .addRoleButton {
                margin-left: 15px;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }

            .update-input-timer {
                float: right;
                line-height: 46px;
                margin-left: 25px;
                height: 46px;

                .demonstrationTwo {
                    float: left;
                    font-size: 0.8rem;
                }

                .update-input-timer-select {
                    width: 11rem;
                    margin-left: 5px;
                }
            }
        }

        .table-vv {
            padding: 1rem 3rem;

            .pagin {
                margin-left: 30%;
                margin-top: 10px;
                float: right;
            }
        }
    }
</style>
