<template>
  <div class="outting">
    <div class="select">
      <span class="title">广告添加</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-back"
        @click="goBackHandler"
        class="addRoleButton"
      >返回广告列表</el-button>
    </div>
    <div class="luobitu--vv">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>轮播图图片</span>
        </div>
        <el-upload
          action
          :on-change="handleChange"
          list-type="picture-card"
          v-show="!value"
          :show-file-list="false"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
        <div v-show="value" class="el-upload-list el-upload-list--picture-card">
          <div class="el-upload-list__item is-success">
            <img class="avatar" v-show="value" :src="value" />
            <label class="el-upload-list__item-status-label">
              <i class="el-icon-upload-success el-icon-check"></i>
            </label>
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview">
                <i class="el-icon-zoom-in" @click.stop="handlePictureCardPreview"></i>
              </span>
              <span class="el-upload-list__item-delete">
                <i class="el-icon-delete" @click.stop="handleRemove"></i>
              </span>
            </span>
          </div>
        </div>
        <div style="margin: 20px;"></div>
        <el-form
          label-position="left"
          label-width="80px"
          :model="formLabelAlign"
          style="width:70%;"
        >
          <el-form-item label="图片名称">
            <el-input v-model="formLabelAlign.adName"></el-input>
          </el-form-item>
          <el-form-item label="图片描述">
            <el-input v-model="formLabelAlign.adDesc"></el-input>
          </el-form-item>
          <el-form-item label="图片序号">
            <el-input v-model="formLabelAlign.adSort"></el-input>
          </el-form-item>
          <el-form-item label="跳转链接">
            <el-input v-model="formLabelAlign.adLinkUrl "></el-input>
          </el-form-item>
          <!-- <el-form-item label="展示区域">
         
               <el-select v-model="formLabelAlign.mark" size="mini" clearable
                style="display:inline-block;width: 10.25rem;;margin-right:1rem">
                <el-option v-for="item in mark" :key="item.lable" :label="item.value" :value="item.lable">
                </el-option>
              </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="submitForm()">提交</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>
<script>
import qs from "qs";
export default {
  name: "addCarousel",
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      value: "",
      file: {},
      sendFile: "",
      mark:[{
        value:"系统首页",
         lable:"0",
      },
      {
        value:"商品顶部轮播",
         lable:"1",
      }],
      formLabelAlign: {
        adName: "",
        adDesc: "",
        adSort: "",
        adLinkUrl: "",
        // mark: "1"
      }
    };
  },
  methods: {
    goBackHandler() {
      this.$router.push("/advertiseCarousel");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.value = "";
      this.file = {};
    },
    handleChange(file, fileList) {
      this.value = file.url;
      this.file = file.raw;
    },

    submitForm() {
      let formData = new FormData();
      console.log(this.formLabelAlign.mark)
      formData.append("multipartFile", this.file);
      formData.append("adSort", Number(this.formLabelAlign.adSort));
      formData.append("adDesc", this.formLabelAlign.adDesc);
      formData.append("adLinkUrl", this.formLabelAlign.adLinkUrl);
      formData.append("adName", this.formLabelAlign.adName);
      // formData.append("mark", this.formLabelAlign.mark);
      this.$api.addPicMall(formData).then(res => {
        if (res.data.code == 200) {
          this.$message.success("添加成功！");
          this.$router.replace("/advertiseCarousel");
        }
      });
    },
    resetForm() {
      this.value=''
      this.file={}
      this.formLabelAlign.adSort=""
      this.formLabelAlign.adDesc=""
      this.formLabelAlign.adLinkUrl=""
      this.formLabelAlign.adName=""
      this.formLabelAlign.mark=""
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }
  .luobitu--vv {
    padding: 1rem 3rem;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>