<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">工单详情</span>
        <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail" class="addRoleButton">返工单列表
        </el-button>
      </div>
      <div class="detail">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>详情信息</span>
               
      <el-button
      v-if="editPhone"
        type="text"
        size="small"
        icon="el-icon-edit"
        class="select-button"
        @click="editFn('form')"
      >编辑</el-button>
    <el-button
    v-else
        type="text"
        size="small"
       icon="el-icon-check" 
        class="select-button"
        @click="editFn('form')"
      >提交</el-button>
          </div>
          <div style="display:flex;margin-bottom:40px">
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">工单编号</span>
                <div class="detail-vv">{{workOrderNo}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题标题</span>
                <div class="detail-vv">{{problemTitle}}</div>
              </div>
            </div>

            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题类型</span>
                <div class="detail-vv">{{problemType}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题状态</span>
                <div class="detail-vv">{{problemStatus}}</div>
              </div>
            </div>
          



          </div>
          <div style="display:flex;;margin-bottom:40px">

         

            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">创建时间</span>
                <div class="detail-vv">{{createTime}}</div>
              </div>
            </div>
           
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">手机号</span>
                <div class="detail-vv">
                  <el-form ref="form"  :rules="rules" :model="form" >
                   <el-form-item  prop="phone">
                    <el-input
                      size="mini"
                      placeholder="请输入手机号码"
                      v-model="form.phone"
                      :disabled="disabledPhone"
                      style="width:80%;"
                    ></el-input>
                  </el-form-item>
                  </el-form>
                  <!-- <el-input placeholder="手机号" v-model="phone" size="mini"  :disabled="disabledPhone" style="width: 11.25rem;;margin-right:2rem"
                    clearable></el-input> -->
                </div>

              </div>
            </div>
             <div style="flex:1;">
              <div class="grid-content bg-purple">
              
              </div>
            </div>
               <div style="flex:1;">
              <div class="grid-content bg-purple">
              
              </div>
            </div>

          </div>
          <div style="display:flex;margin-bottom:40px">
          <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题描述</span>
                <div class="detail-vv">{{problemDesc}}</div>
              </div>
            </div>
          
          </div>
          <div style="display:flex;">

            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span" style="display: inline-block; vertical-align: middle;">附件信息:</span>

                <span class="detail-span" v-if="additionalPicturesUrl == '' ">
                  暂无数据
                </span>
                <el-image v-else v-for="(item,index)  in   additionalPicturesUrl" :key="index"
                  style="width: 100px; height: 100px;margin-left:20px;display: inline-block; vertical-align: middle;"
                  :src="item" referrerpolicy="no-referrer"></el-image>
                <!-- <div class="detail-vv">{{additionalPicturesUrl}}</div> -->
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <!-- 流程节点展示 -->
      <div class="detail">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>处理流程</span>
          </div>
          <div class="stepShow">
            <el-steps :active="milepostActive" direction="vertical">
              <el-step v-for="(value, key) in milepost" :class="milepostActive== key+1 ? stepActive: '' "
                :title="value.nodeName" :key="key" :description="value.value">
                <template slot="description">
                  <p><span
                      style="color:#666;display:inline-block;margin: 0 0 5px 0px">问题状态：</span>{{value.problemStatus}}
                    <span style="color:#666;display:inline-block;margin: 0 0 5px 10px">时间:</span> {{value.createTime}}
                  </p>
                  <p style="margin-bottom:20px"><span style="color:#666;">处理意见：</span> {{value.handleOpinion}} </p>


                  <br />
                </template>
              </el-step>
            </el-steps>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "PlaceDetail",
    data() {
          var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (phoneReg.test(value)) {
            callback();
            console.log("zhengq ")
          } else {
            callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };
      return {
        formLabelWidth: "120px",
        // 默认步骤数
        milepostActive: 20,
         editPhone:true,
        milepost: [],
        form:{
       phone:"",
        },
         rules: {
            phone: [
          { required: false, validator: checkPhone, trigger: "blur" }
        ],
         },
        disabledPhone:true,
        // 动态添加类名
        stepActive: "stepActive",
        additionalPicturesUrl: "", //附加图片地址
        ccUsers: "", //抄送人员信息
        workOrderNo: "", //抄送人员信息
        createTime: "", //创建时间 
        creatorUser: "", // 问题创建者 
        handlerUser: "", //当前处理人员
        ccUserNames: "", //抄送人员
        id: "", //问题id 
        phone: "", //手机号
        mac: "", //关联mac号
        problemDesc: "", //问题描述 
        problemSource: "", // 问题来源 = ['后台', 'app'], 
        problemStatus: "", // 问题状态  
        productName: "", // 产品名称  
        problemTitle: "", //问题标题 
        problemType: "", // 问题类型 

      };
    },
    created() {
      sessionStorage.setItem("detailsIdProblem", this.$route.query.id);
    },
    mounted() {

      let detailsId = sessionStorage.getItem("detailsIdProblem");
      this.$api.workOrderDetail(detailsId).then(res => {


        this.ccUsers = res.data.data.ccUsers
        this.form.phone = res.data.data.phone
        this.workOrderNo = res.data.data.workOrderNo
        this.createTime = res.data.data.createTime
        this.creatorUser = res.data.data.creatorUser
        this.handlerUser = res.data.data.handlerUser
        this.id = res.data.data.id
        this.mac = res.data.data.mac
        this.problemDesc = res.data.data.problemDesc
        this.problemSource = res.data.data.problemSource
        this.productName = res.data.data.productName
        this.problemStatus = res.data.data.problemStatus
        this.problemTitle = res.data.data.problemTitle
        this.problemType = res.data.data.problemType
        if (res.data.data.additionalPicturesUrl == null) {
          this.additionalPicturesUrl = ""


        } else {
          this.additionalPicturesUrl = res.data.data.additionalPicturesUrl.split(",")
        }


      });
      this.$api.workOrderProcess(detailsId).then(res => {

        this.milepost = res.data.data
        let numList = []
        this.milepost.forEach((item, index) => {
          if (item.id === null) {
            // console.log(item)
            numList.push(index)
            // console.log(numList,)
            this.milepostActive = numList[0]
          } else {
            this.milepostActive = 20
          }
        })
      });
    },
    methods: {
      // 点击编辑
    editFn(form){
      this.editPhone =  !this.editPhone 
      this.disabledPhone =  !this.disabledPhone
      if(this.editPhone == true){
          console.log(this.form)
        
        this.$refs[form].validate((valid) => {
          if (valid) {
           this.$api
        .updateWorkOrderPhone({
          id:this.$route.query.id,
          phone :this.form.phone
        })
        .then(res => {
          console.log(res.data.data)
      
        })
        .catch(err => {
          console.log(err);
        });
    

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
    
      }
    },

      //回到模块页面
      gobackPlaceDetail() {
        this.$router.push("/myWorkorder");
      }
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .item {
      height: 80px;
      width: 80px;
      border: 1px solid blue;
      float: left;
    }
 .select-button {
    float: right;
    // color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15px;
  }
    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }
    }

    .detail {
      padding: 0rem 3rem;
      margin-top: 1rem;

      .detail-span {
        font-size: 12 /16rem;
        color: #898989;
      }

      .detail-vv {
        margin-top: 5 /16rem;
        font-size: 14 /16rem;
      }
    }
  }

  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    border-radius: 4px;
  }

  .bg-purple-dark {
    background: #99a9bf;
  }

  //   .bg-purple {
  //     background: #d3dce6;
  //   }
  .bg-purple-light {
    background: #e5e9f2;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .stepShow {
    width: 80%;
    margin: 3rem auto;

    .downloadList {
      //  transform:translate(5.5rem, -4rem)
    }

    /deep/.el-step.is-horizontal.stepActive {
      .el-step__head.is-finish {
        .el-step__line {

          // 当前步骤数横线样式设置
          .el-step__line-inner {
            width: 50% !important;
            border-width: 1px !important;
          }
        }

        // 当前步骤数圆圈样式设置
        .el-step__icon.is-text {
          background: #409eff;
          color: #fff;
        }
      }
    }
  }

  #wrapper {
    background: radial-gradient(ellipse at top left,
        rgba(255, 255, 255, 1) 40%,
        rgba(229, 229, 229, 0.9) 100%);
    height: 100vh;
    padding: 60px 80px;
    width: 100vw;
    display: flex;
  }

  .state-item {
    width: 80px;
    height: 40px;
    color: #606266;
    background: #f6f6f6;
    border: 2px solid rgba(0, 0, 0, 0.05);
    text-align: center;
    line-height: 40px;
    font-family: sans-serif;
    border-radius: 4px;
    //   margin-right: 60px;
  }

  .line-wrap {
    //   display: flex;
    width: 100px;
    margin-bottom: 40px;
  }
</style>