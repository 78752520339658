<template>
  <div class="mainContainer">
    <div class="select">
      <span class="title">设备数据查询</span>

      <el-button type="text" size="small" icon="el-icon-upload2" class="select-button" @click="problemExport">导出
      </el-button>
    </div>
    <div class="gaojiSearch">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="toolbar-right">
            <span style="font-size:14px" class="toolbarTit">时间间隔/(h)：</span>
            <!-- <el-input v-model="num" oninput="value=value.replace(/[^\d]/g,'')" type='number' size="mini"
              style="width: 11.25rem;;margin-right:1rem" clearable></el-input> -->
            <el-input v-model="num"   @input="changes"
                 oninput = "value=value.replace(/[^\d.]/g,'')"
            size="mini"
              style="width:4.25rem;;margin-right:1rem" clearable></el-input>
                 <span style="font-size:14px" class="toolbarTit">分钟/(min)：</span>
            <!-- <el-input v-model="num" oninput="value=value.replace(/[^\d]/g,'')" type='number' size="mini"
              style="width: 11.25rem;;margin-right:1rem" clearable></el-input> -->
            <el-input v-model="intervalTimeMin"   @input="minchanges"
                 oninput = "value=value.replace(/[^\d.]/g,'')"
             size="mini"
              style="width:4.25rem;;margin-right:1rem" clearable></el-input>            <span style="font-size:14px" class="toolbarTit">所属项目 ：</span>
            <el-input placeholder="请输入内容" v-model="projectName" size="mini" style="width: 11.25rem;;margin-right:1rem"
              clearable></el-input>
            <span style="font-size:14px" class="toolbarTit">产品名称 ：</span>
            <el-input placeholder="请输入内容" v-model="productName" size="mini" style="width: 11.25rem;;margin-right:1rem"
                      clearable></el-input>
            <span style="font-size:14px" class="toolbarTit">模组固件版本号 ：</span>
            <el-input  v-model="moduleVersion" size="mini" style="width: 4.25rem;;margin-right:1rem"
                      clearable></el-input>
            <span style="font-size:14px">日期：</span>
            <el-date-picker style="width: 22.25rem;" v-model="timeValue" range-separator="~" start-placeholder="开始日期"
              end-placeholder="结束日期" size="mini" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
              @change="getTimeBlock"></el-date-picker>
            <el-button style="margin-left:15px" size="small" type="primary" @click="imeiSearch">搜索</el-button>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <!-- @row-click="macQuery" -->
          <span >{{ total }}</span>个设备
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table" ref="refTable" @expand-change="expandSelect"
        :row-key="getRowKeys" :expand-row-keys="expands" :header-cell-style="{'background-color': '#eeeeee'}">
        <el-table-column type="expand" class="product-table2">
          <template slot-scope="scope">
            <span>共有{{ totalMac }}</span>条数据
            <el-button type="text" size="small" icon="el-icon-upload2" class="select-button2" @click="problemExport2">导出
            </el-button>
            <el-table :data="tableDataMac" stripe size="small" border class="product-table2"  
                :cell-style="cellStyle"
              :header-cell-style="{'background-color': '#f5f7fa'}">
           
              <el-table-column fixed prop="reporting_time"  label="上报时间" align="center" width="175">
                <template slot-scope="scope">
                  <span v-if="scope.row.mark ==  '1' " style="color:red">{{scope.row.reporting_time}}</span>
                  <span v-else style="color: #333">{{scope.row.reporting_time}}</span>
                </template>
              </el-table-column>
           
               <template v-for="(item,index) in options">
          <el-table-column width="155"  :key="index" :prop="item.dataPointKey"
                :label="item.dataPointKeyShowName" min-width="1" align="center"  >
          <template slot-scope="scope">
           <span :class="scope.row[item.dataPointKey] == '是' ? 'reddataPointKey' : ''">{{scope.row[item.dataPointKey]}}</span>
            </template>
              </el-table-column>
      </template>

             
            </el-table>
            <!-- <el-table style="width:100%" border :data="tableData11">
      <template v-for="(item, index) in tableHead">
        <el-table-column
          :prop="item.column_name"
          :label="item.column_comment"
          :key="index"
        >
         <template slot-scope="scope">
           <span :class="item.column_name == 'age' ? 'color_red' : ''">{{scope.row[item.column_name]}}</span>
            </template>
        </el-table-column>
      </template>
    </el-table> -->
            <el-pagination background :page-sizes="[10, 20]" :page-size="pageSizeMac" :total="totalMac"
              layout="sizes,prev, pager, next" @size-change="handleSizeChange2" @current-change="handlePageChange2">
            </el-pagination>
          </template>
        </el-table-column>
        <el-table-column label="序号" type="index" align="center" width="155"></el-table-column>
        <el-table-column prop="currentReportTime" label="最新上报时间" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.currentReportTime">{{scope.row.currentReportTime}}</span>
            <span v-else>暂无数据</span>
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" align="center">
        </el-table-column>
        <el-table-column prop="mac" label="MAC" align="center">
        </el-table-column>
            <el-table-column prop="moduleVersion" label="模组固件版本号" align="center">
        </el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center">
        </el-table-column>

        <el-table-column prop="dataCount" label="数据总条数" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.dataCount">{{scope.row.dataCount}}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
          <el-table-column prop="faultTotalCount" label="故障总条数" align="center">
              <template slot-scope="scope">
            <span v-if="scope.row.faultTotalCount">{{scope.row.faultTotalCount}}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
              <el-table-column prop="fireCount" label="火警条数" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.fireCount">{{scope.row.fireCount}}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
          <el-table-column prop="detectorLossFaultCount" label="底座分离故障总条数" align="center">
              <template slot-scope="scope">
            <span v-if="scope.row.detectorLossFaultCount">{{scope.row.detectorLossFaultCount}}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column prop="abnormalCount" label="异常项" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.abnormalCount">{{scope.row.abnormalCount}}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background :page-sizes="[10, 20,50,100]" :page-size="pageSize" :total="total"
        layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
      </el-pagination>
    </div>
  </div>
</template>


<script>
  import Bus from "../../components/bus";
  import qs from "qs";
  import PubSub from "pubsub-js";
  import Storage from "@/utils/Storage";
  export default {
    name: "equipmentQuery",
    data() {
      return {
           tableHead: [
        {
          column_name: "name",
          column_comment: "姓名"
        },
        {
          column_name: "age",
          column_comment: "年龄"
        },
        {
          column_name: "sex",
          column_comment: "性别"
        }
      ],
      tableData11: [
        {
          name: "张额",
          age: 19,
          sex: "女"
        },
        {
          name: "王安石",
          age: 12,
          sex: "男"
        },
        {
          name: "端费",
          age: 19,
          sex: "女"
        }
      ],
        month: "",
        mac: "",
        num: "30", //时间间隔
        timeValue: "",
        value: "",
        tableHeader: [],
        input: "",
        current: 1,
        currentMac: 1,
        week: "",
        total: 0,
        pageshow: true,
        totalMac: 0,
        startTime: "",
        endTime: "",
        tableData: [],
        tableDataMac: [],
        concentrationCO: true,
        concentrationCH4: true,
        // 获取row的key值
        getRowKeys(row) {
          // console.log(row.mac)
          return row.mac;
        },
        // 要展开的行，数值的元素是row的key值
        expands: [],
        pickerOptions: {
          disabledDate(time) {
            let cuttentDate = new Date(
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
            );
            let hmCurrentDate = cuttentDate.getTime();
            let three = 90 * 24 * 3600 * 1000;
            let threeMonths = hmCurrentDate - three;
            return time.getTime() > hmCurrentDate || time.getTime() < threeMonths;
          }
        },
        mon: "",
        year: "",
        yearweek: "", //选择周的月份
        pageSize: 20,
        pageSizeMac: 10,
        delDialogVisible: false,
        productId: "",
        indexId: "", //反显id
        nameinput: "", //搜索输入
        indexIdlist: "", //反显列表
        formLabelWidth: "120px",
        realNameList: [], //当前处理人员列表
        projectName: "", //项目名称
        mac: "", //设备号
        productName: "", //产品名称
        moduleVersion:"",
        placeName: "", //产品名称
        userMobile: "", //app注册用户手机号
        options: [],
        max:30,
        minmax:60,
        intervalTimeMin:"0",

      };
    },
    watch: {

    },

    methods: {
     cellStyle(row, column, rowIndex, columnIndex) {
        // console.log(row)
     },
      changeInput(num) {

        var pattern = /^[1-9][0-9]*$/ // 正整数的正则表达式

        // 不符合正整数时

        if (!pattern.test(this.num)) {

          // input 框绑定的内容非数字的全部置为空

          this.num = this.num.replace(/[^\d]/g, '')

        }

      },
 inputnum(){
        	this.num = this.num.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
       },
      changes(value){
        if(value > this.max) {
            this.num = this.max;
        }
      },
          minchanges(value){
        if(value > this.minmax) {
            this.intervalTimeMin = this.minmax;
        }
      },
      // 选择月份
      changeMonth(item) {
        console.log(item);
        this.echartsdate = item
        this.year = item.slice(0, 4);
        this.mon = item.slice(5, 7);

        this.fireTimeMonthStatistics();
      },
      getTime(val) {
        //   获取时间并格式化
        // 获取前val天的时间
        var dt = new Date();
        val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
        const y = dt.getFullYear();
        const m = (dt.getMonth() + 1 + "").padStart(2, "0");
        const d = (dt.getDate() + "").padStart(2, "0");
        const hh = (dt.getHours() + "").padStart(2, "0");
        const mm = (dt.getMinutes() + "").padStart(2, "0");
        const ss = (dt.getSeconds() + "").padStart(2, "0");

        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
        // return `${y}-${m}-${d} `;  // 获取的时间格式为 2021-04-09
        // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
        // return y + m + d  // 获取的时间格式为  20210409
      },

      refresh() {
        this.queryTestRecord();
      },
      // 搜索
      imeiSearch() {
        this.queryTestRecord()
      },


      handlePageChange(val) {
        this.current = val;
        this.queryTestRecord();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.queryTestRecord();
      },
      // 小表格分页
      handlePageChange2(val2) {
        console.log(val2)
        this.currentMac = val2;
        this.queryTestRecordByMac();
      },
      handleSizeChange2(val2) {
        this.pageSizeMac = val2;
        this.queryTestRecordByMac();
      },
      cancelDelete() {
        this.delDialogVisible = false;
        this.productId = "";
      },
      handleDelClose() {
        this.delDialogVisible = false;
        this.deleteProduct(this.productId);
      },


      queryTestRecord() {
        //查询列表
        this.$api
          .queryTestRecord({
            page: {
              current: this.current,
              size: this.pageSize,
            },
            query: {
              intervalTimeHour: this.num,
              intervalTimeMin: this.intervalTimeMin,
              projectName: this.projectName,
              productName: this.productName,
              moduleVersion: this.moduleVersion,
              startTime: this.startTime,
              endTime: this.endTime,
              mac: this.mac
            }
          })
          .then(res => {
            // console.log(res.data.data)
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          })
          .catch(error => {
            console.log(error);
          });
      },
         formatBoolean(row, column, cellValue) {
                var ret = ''  //你想在页面展示的值
                console.log(cellValue)
                if (cellValue) {
                    ret = "是"  //根据自己的需求设定
                } else {
                    ret = "否"
                }
                return ret;
            },
      queryTestRecordByMac() {
        // this.tableDataMac = []
        // this.totalMac = 0

        //小表格列表 
        this.$api
          .queryTestRecordByMac({
            page: {
              current: this.currentMac,
              size: this.pageSizeMac,
            },
            query: {
              intervalTimeHour: this.num,
              intervalTimeMin: this.intervalTimeMin,
              startTime: this.startTime,
              endTime: this.endTime,
              mac: this.mac
            }
          })
          .then(res => {
            // console.log(res.data.data.records)
            this.tableDataMac = res.data.data.records;
            // var tableData2 = res.data.data.records;
            var newTableData = this.tableDataMac.map(item => {
              for (var i in item) {
                if (item[i] === true || item[i] === "true") {
                  item[i] = "是";
                  // console.log(  item[i] )
                }
                if (item[i] === false || item[i] === "false") {
                  item[i] = "否";
                }
                if (item[i] == null) {
                  item[i] = "--";
                }
              }
              return item;
            });
            console.log(newTableData);
            this.tableDataMac = newTableData;
            this.totalMac = res.data.data.total;
            const json = res.data.data.records[0]
            // console.log(json)
            if (json.concentrationCH4 != null) {
              this.concentrationCH4 = true
            } else {
              this.concentrationCH4 = false
            }
            if (json.concentrationCO != null) {
              this.concentrationCO = true
            } else {
              this.concentrationCO = false
            }
            // Object.keys(json).forEach(key => {

            //   this.tableHeader.push(key)
            // });
            //   console.log(this.tableHeader);

          })
          .catch(error => {
            console.log(error);
          });
      },
      expandSelect(row, expandedRows) {
        this.$api.dataPotinSelect(row.mac).then(res => {
          this.options = res.data.data;
        });
        console.log(row, expandedRows)
        // this.queryTestRecordByMac()
        //  this.$refs.refTable.toggleRowExpansion(row)
        // console.log('row', row)

        var that = this
        if (expandedRows.length) {
          that.expands = []
          if (row) {
            that.expands.push(row.mac) // 每次push进去的是每行的ID
          }
        } else {
          that.expands = [] // 默认不展开
        }
        this.mac = row.mac
        this.currentMac = '1'
        this.queryTestRecordByMac()
      },
      clickTable(row, index, e) {
        //     console.log(row)
        // this.$refs.refTable.toggleRowExpansion(row)
        // if (this.expands.includes(row.mac)) {
        //   this.expands = this.expands.filter(val => val !== row.mac);
        // } else {
        //   //判断是否已经存在展开的行
        //   if (this.expands.length != 0) {
        //     //如果存在展开行,清空expands数组,使它关闭
        //     this.expands.splice(0, this.expands.length);
        //     //打开点击的行
        //     this.expands.push(row.mac);
        //   } else {
        //     //如果不存在展开行,直接push打开点击的行
        //     this.expands.push(row.mac);
        //   }
        // }
        // this.mac = row.mac
        // this.queryTestRecordByMac()
      },
      // 导出
      problemExport() {
        let properties = ["currentReportTime","moduleVersion", "projectName", "mac", "dataCount","faultTotalCount","fireCount","detectorLossFaultCount", "abnormalCount"];
        let titles = ["最新上报时间","模组固件版本号", "项目名称", "MAC", "数据总条数","故障总条数", "火警条数","底座分离故障总条数","异常项"];
        let query = {};
        query.intervalTimeHour = this.num;
        query.intervalTimeMin = this.intervalTimeMin;
        query.endTime = this.endTime;
        query.mac = this.mac;
        query.projectName = this.productName;
        query.startTime = this.startTime;
        this.$api
          .queryTestRecordExport({
            properties: properties,
            titles: titles,
            query: query
          })
          .then(res => {
            this.download(res.data);
          })
          .catch(error => {});
      },
      // 单个导出
      problemExport2() {
        let titles = ["上报时间"]
        let properties = ["reporting_time"]
        // let titles = ["上报时间", "NB卡号", "rsrp", "rsrq","snr", "自检", "温度", "火警/燃气泄漏","丢失故障", "一氧化碳气体浓度", "甲烷气体浓度"];
        //  if(this.concentrationCO == false && this.concentrationCH4 != false){
        //      titles = ["上报时间", "NB卡号", "rsrp", "rsrq","snr", "自检", "温度", "火警/燃气泄漏","丢失故障","甲烷气体浓度"];
        //  }else if(this.concentrationCH4 == false && this.concentrationCO != false){
        //         titles = ["上报时间", "NB卡号", "rsrp", "rsrq","snr", "自检", "温度", "火警/燃气泄漏","丢失故障","一氧化碳气体浓度"];
        //  }else if( this.concentrationCH4 == false &&  this.concentrationCO == false ){
        //      titles = ["上报时间", "NB卡号", "rsrp", "rsrq","snr", "自检", "温度", "火警/燃气泄漏","丢失故障"];
        //  }

        // let properties  = ["reportTime", "iccid", "rsrp", "rsrq","snr", "selfChecking", "temperature", "fireAlarm","detectorLossFault", "concentrationCO", "concentrationCH4"];
        //  if(this.concentrationCO == false && this.concentrationCH4 != false){
        //      properties = ["reportTime", "iccid", "rsrp", "rsrq","snr", "selfChecking", "temperature", "fireAlarm","detectorLossFault",  "concentrationCH4"];
        //  }else if(this.concentrationCH4 == false&& this.concentrationCO != false){
        //         properties = ["reportTime", "iccid", "rsrp", "rsrq","snr", "selfChecking", "temperature", "fireAlarm","detectorLossFault", "concentrationCO"];
        //  }else if( this.concentrationCH4 == false &&  this.concentrationCO == false ){

        //      properties =  ["reportTime", "iccid", "rsrp", "rsrq","snr", "selfChecking", "temperature", "fireAlarm","detectorLossFault"];
        //  }
        for (let i = 0; i < this.options.length; i++) {
          titles.push(this.options[i].dataPointKeyShowName)
          properties.push(this.options[i].dataPointKey)
        }
        // this,options.forEach((v,i)=>{                  
        //     Object.keys(v).forEach(v=>{
        //         console.log(v)//取到了key
        //         console.log(this,options[i][v])//取到了值
        //     })
        // })
        // console.log(titles)
        let query = {};
        query.intervalTimeHour = this.num;
        query.intervalTimeMin = this.intervalTimeMin;
        query.endTime = this.endTime;
        query.mac = this.mac;
        query.projectName = this.productName;
        query.startTime = this.startTime;
        this.$api
          .queryTestRecordByMacExport({
            properties: properties,
            titles: titles,
            query: query
          })
          .then(res => {
            this.download(res.data);
          })
          .catch(error => {});
      },
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
            // type: "application/vnd.ms-excel"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
      //重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      showAddProductDialog() {
        this.dialogVisible = true;
        this.form.problemStatus = "";
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
      },
      //获取时间函数2
      getTimeBlock() {
        console.log(this.timeValue);
        if (this.timeValue) {
          this.startTime = this.timeValue[0];
          this.endTime = this.timeValue[1];


        } else {
          this.startTime = "";
          this.endTime = "";
          // this.deviceDoneFireList();
        }
      },
    },
    created() {
      //查询产品列表
      this.endTime = this.getTime(0);
      this.startTime = this.getTime(7);
      this.queryTestRecord();

      this.timeValue = [this.startTime, this.endTime]


    }
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);
.color_red {
  color: red;
}
  .firelist {
    width: 80%;
    margin: 20px;
    font-size: 0.8rem;
  }
.reddataPointKey{
  color: red;
  // border: solid 1px red;
}
.blackdataPointKey{
  color: blue
}
  // .product-table2 {
  // background-color: #f5f7fa !important;
  /deep/.el-table,
  .el-table__expanded-cell {
    background-color: #f5f7fa;
    // background-color: transparent;//这是设置透明背景色
  }

  /deep/.el-table__expanded-cell {
    background-color: #f5f7fa;
  }

  .select-button2 {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 60%;
  }

  // }

  .charts {
    width: 45vw;
    height: 47vh;
    margin-top: 30px;
    min-width: 280px;
    min-height: 210px;
  }

  .el-row {
    margin: 1rem 3rem;
    line-height: 0;
    min-width: 900px;

    .platform-toolbar {
      position: relative;
      margin: 0;
      border: 1px solid #d9d9d9;

      .title-info {
        height: 46px;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      .el-col {
        padding: 0 3rem;

        .toolbar-right {
          float: right;
          margin-top: 7px;

          &.export {
            margin-left: 1rem;

            .toolbar-btn {
              color: @toolBarFontColor;
              font-size: 0.9rem;

              &.left {
                margin-left: 1.5rem;
              }

              &.right {
                margin-right: 0;
              }
            }
          }
        }
      }

      .title-info {
        float: left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .result-info-wrapper {
      height: 1rem;
      margin: 1rem 0 0.5rem 0;
      position: relative;

      .setting-panel {
        position: absolute;
        float: right;
        top: 2rem;
        width: 200px;
        height: 200px;
        background: red;
        z-index: 1000;
        border-radius: 3px;
      }

      .result-info {
        height: 1rem;
        line-height: 1rem;
        font-size: 0.875rem;
        float: left;
        color: #898989;

        span {
          color: #292929;
          padding: 0 0.1rem;
        }
      }

      .data-setting {
        font-size: 0.875rem;
        color: #2673bb;
        float: right;

        i {
          padding-right: 0.2rem;
        }

        cursor: pointer;
      }
    }

    .el-table td,
    .el-table th {
      padding: 0 !important;
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .product-content {
    height: 6rem;

    .product-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .container-el-row {
    margin-top: 0px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15px;
    }

    .active {
      color: #2673bb;
    }

    .select-input-right-input {
      float: right;
      margin-top: 7px;
    }
  }

  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    // background-color: #f4f6fa;

    .searchBut {
      float: right;
      margin-left: 20px;
    }
  }

  .toolbarTit {
    // min-width: 101px;
    display: inline-block;
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;
    padding-bottom: 6rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }
</style>