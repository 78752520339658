<template>
  <div class="outting">
    <div class="select">
      <span class="title">结果详情</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-back"
        class="select-button"
        @click="goBack"
      >返回结果列表</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-bottom"
        @click="playShow"
        class="select-button"
      >高级查询</el-button>
      <el-date-picker
        v-model="value"
        range-separator="~"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="small"
        value-format="yyyy-MM-dd HH:mm:ss"
        format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00','23:59:59']"
        type="datetimerange"
        @change="getTimeBlock"
        class="select-button"
      ></el-date-picker>
    </div>
    <div class="gaojiSearch" v-show="ifSearchMany">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="grid-content bg-purple-dark">
            <span>测试员查询</span>
            <div style="margin-top:5px;">
              <el-input
                v-model="username"
                prefix-icon="el-icon-search"
                placeholder="请输入测试员"
                size="small"
              ></el-input>
            </div>
          </div>
        </div>
        <div style="float:left;margin-left:1.5rem;">
          <div class="grid-content bg-purple-dark">
            <span>是否通过</span>
            <div style="margin-top:5px;">
              <el-select v-model="result" clearable size="small" @change="changeIfPass">
                <el-option
                  v-for="item in ifPassOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;margin-top:.5rem;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="checkHandler">搜索</el-button>
            </div>
            <div class="emptyBut">
              <el-button size="small" @click="emptyHandler">清空</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-vv">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column type="selection" min-width="1"></el-table-column>
        <el-table-column prop="deviceMac " label="MAC" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.deviceMac || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.productName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column
          prop="detectorLossFault"
          label="丢失"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatDetectorLossFault"
        ></el-table-column>
        <el-table-column
          prop="selfChecking"
          label="自检"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatSelfChecking"
        ></el-table-column>
        <el-table-column
          prop="fireAlarm"
          label="火警/燃气泄漏"
          min-width="1"
          fixed
          align="center"
          :formatter="stateFormatFireAlarm"
        >
          <!-- <template slot-scope="{row}">{{ row.fireAlarm || '暂无数据' }}</template> -->
        </el-table-column>

        <el-table-column prop="result" label="检测结果" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.result || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="testTime" label="检测时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.testTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="username" label="检测员" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.username || '暂无数据' }}</template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        class="pagin"
        @current-change="changeCurrent"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "TestResultDetail",
  data() {
    return {
      queryCon: {},
      value: "",
      startTime: "",
      endTime: "",
      ifSearchMany: false,
      result: "",
      username: "",
      current: 1,
      tableData: [],
      total: 0,
      ifPassOptions: [
        { value: 1, label: "通过" },
        { value: 0, label: "不通过" }
      ]
    };
  },
  created() {
    this.deviceMac = this.$route.params.deviceId;
    this.queryCon = this.$route.params.queryCon;
    console.log(this.queryCon);
    var end = new Date();
    var start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
    this.value = [start, end];
    this.endTime = end.Format("yyyy-MM-dd HH:mm:ss");
    this.startTime = this.value[0].Format("yyyy-MM-dd HH:mm:ss");
    this.testResultDetailHandler();
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "TestResult",
        params: {
          queryCon: this.queryCon
        }
      });
    },
    //故障渲染函数
    stateFormatDetectorLossFault(row) {
      if (row.detectorLossFault == "true") {
        return "是";
      } else if (row.detectorLossFault == "false") {
        return "否";
      } else {
        return "暂无数据";
      }
    },
    //自检渲染函数
    stateFormatSelfChecking(row) {
      if (row.selfChecking == "true") {
        return "是";
      } else if (row.selfChecking == "false") {
        return "否";
      } else {
        return "暂无数据";
      }
    },
    //火警渲染函数
    stateFormatFireAlarm(row) {
      if (row.selfChecking == "true") {
        return "是";
      } else if (row.selfChecking == "false") {
        return "否";
      } else {
        return "暂无数据";
      }
    },
    //测试结果渲染函数
    stateFormatResult(row) {
      if (row.result == 0) {
        return "不通过";
      } else if (row.result == 1) {
        return "通过";
      } else {
        return "测试中";
      }
    },
    getTimeBlock() {
      if (value) {
        this.startTime = this.value[0];
        this.endTime = this.value[1];
      } else {
        this.startTime = "";
        this.endTime = "";
      }
      console.log(this.startTime, this.endTime);
    },
    //高级查询函数
    playShow() {
      if (this.ifSearchMany == false) {
        this.ifSearchMany = true;
      } else {
        this.ifSearchMany = false;
      }
    },
    //是否通过选择函数
    changeIfPass(id) {
      this.result = id;
    },
    //搜索函数
    checkHandler() {
      this.testResultDetailHandler();
    },
    //清空函数
    emptyHandler() {
      this.value = "";
      this.startTime = "";
      this.endTime = "";
      this.username = "";
      this.result = "";
    },
    //详情数据接口
    testResultDetailHandler() {
      this.$api
        .testResultDetail({
          page: {
            current: this.current,
            size: 10
          },
          query: {
            deviceMac: this.deviceMac,
            username: this.username,
            result: this.result
          }
        })
        .then(res => {
          console.log(res.data.data.records);
          this.tableData = res.data.data.records;
        });
    },
    changeCurrent() {
      this.testResultDetailHandler();
    }
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15 /16rem;
    }
  }
  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;
    .searchBut {
      float: right;
      margin-left: 20px;
    }

    .emptyBut {
      float: right;
    }
  }
  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;
    .pagin {
      margin-left: 30%;
      margin-top: 10px;
      float: right;
    }
  }
}
</style>