<template>

  <div class="mainContainer">
    <div class="select">
      <span class="title">订单列表</span>
      <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新</el-button>
      <el-button type="text" size="small" icon="el-icon-download" class="select-button" @click="goodsExport">导出
      </el-button>
    </div>
    <div class="gaojiSearch">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="toolbar-right">
            <!-- <span style="font-size:14px" class="toolbarTit">订单类型 ：</span>
            <el-input placeholder="请输入兑换码"  v-model="orderType" size="mini" style="width: 12rem;;margin-right:1rem"
              clearable></el-input> -->
            <span style="font-size:12px;margin-bottom:20px" class="toolbarTit">订单号 ：</span>
            <el-input placeholder="请输入订单号" v-model="tradeNo" size="mini" style="width: 12rem;;margin-right:1rem"
              clearable></el-input>

            <span style="font-size:12px;margin-bottom:20px" class="toolbarTit">订单状态 ：</span>
            <el-select v-model="orderStatus" size="mini" clearable
              style="display:inline-block;width: 12rem;;margin-right:1rem">
              <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span style="font-size:12px;margin-bottom:20px" class="toolbarTit">创建人 ：</span>
            <el-input placeholder="请输入创建人" v-model="creator" size="mini" style="width: 12rem;;margin-right:1rem"
              clearable></el-input>
            <br />
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
                 <el-button size="mini" @click="emptyFn()">清空</el-button>
              <el-button size="mini" type="primary" @click="imeiSearch">搜索</el-button>
             
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个订单
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table" @select="getIds" @select-all="getAllIds"
        :header-cell-style="{'background-color': '#eeeeee'}">
        <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="orderType" label="订单类型" align="center"></el-table-column>
        <el-table-column prop="orderAmount" label="订单总额" align="center"></el-table-column>
        <!-- <el-table-column prop="tradeType" label="交易类型" align="center"></el-table-column> -->
        <el-table-column prop="tradeNo" label="订单号" align="center"></el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" align="center"></el-table-column>

        <el-table-column prop="creator" label="创建人" align="center"></el-table-column>
        <el-table-column prop="consignee" label="收货人" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="address" label="订单地址" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button @click="orderDetail(scope.row)" type="text" size="small" icon="el-icon-info">详情</el-button>
            <el-button @click="orderShip(scope.row)" type="text" v-if="scope.row.orderStatus == '待发货'" size="small"
              icon="el-icon-s-promotion">发货</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page.sync="currentPage" background :page-sizes="[10, 20]" :page-size="pageSize"
        :total="total" layout="sizes,prev, pager, next" @size-change="handleSizeChange"
        @current-change="handlePageChange">
      </el-pagination>
      <!-- 发货弹出 -->
      <el-dialog title="" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">

        <span style="margin-bottom:1rem;" class="logistics">
          订单编号：
        </span>
        {{orderNo}}

        <br>
        <span class="logistics">
          选择或输入快递公司：
        </span>
        <el-select v-model="value" style="margin-bottom:1rem;margin-right:1rem" filterable remote size="mini"
          @change="changeMethod" reserve-keyword placeholder="可下拉选择可输入框输入" clearable :remote-method="remoteMethod"
          :loading="loading">
          <el-option v-for="item in options" :key="item.deliveryCode" :label="item.deliveryName"
            :value="item.deliveryCode">
          </el-option>
        </el-select>
        <br>
        <span class="logistics">
          输入物流单号：
        </span>
        <el-input placeholder="请输入物流单号" v-model="shipmentNum" size="mini" style="width: 12rem;;margin-right:1rem"
          clearable></el-input>
        <br>

        <span class="dialog-footer">
          <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="addTracks">发 货</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>


<script>
  // import Bus from "../../../components/bus";
  export default {
    name: "orderList",
    data() {
      return {
        options: [],
        value: [],
        fit: "fill",
        orderNo: "",
        shipmentNum: "", //物流单号
        list: [],
        loading: false,
        productDel: false,
        productAdd: false,
        orderType: "",
        tradeType: "",
        tradeNo: "",
        orderStatus: "",
        creator: "",
        currentPage: 1,
        dialogVisible: false,
        //  orderStatus订单状态 0:待支付 1:已取消 2:已失效 3:待发货 4:待收货 5:已完成
        orderStatusList: [{
            value: "0",
            label: "待支付"
          },
          {
            value: "1",
            label: "已取消"
          },
          {
            value: "2",
            label: "已失效"
          },
          {
            value: "3",
            label: "待发货"
          },
          {
            value: "4",
            label: "待收货"
          },
          {
            value: "5",
            label: "已完成"
          }
        ],
        visible: false,
        total: 0,
        tableData: [],
        emileList: [{
            value: "1",
            label: "是"
          },
          {
            value: "0",
            label: "否"
          }
        ], //搜索下拉选择
        smsList: [{
            value: "1",
            label: "发送"
          },
          {
            value: "0",
            label: "不发送"
          }
        ], //是否发送短信
        chooseOnlineStatus: "", //搜索选择
        codeStatusList: [], //账号所属类型
        newSsUserlist: {
          value: "id",
          label: "typeName",
          children: "childs",
          expandTrigger: "hover"
          // emitPath: false
        },
        checked: false,
        //新增账号弹出对话框标记
        dialogVisible: false,
        //修改账号弹出对话框标记
        modifydialogVisible: false,
        //节点问题状态
        protocols: [],
        ids: [],
        //产品品类列表
        categories: [],
        input: "",
        current: 1,
        pageSize: 10,
        delDialogVisible: false,
        productId: "",
        indexId: "", //反显id
        nameinput: "", //搜索输入
        indexIdlist: "", //反显列表
        formLabelWidth: "120px",
        cachecurrent: "",
      };
    },
    methods: {
      refresh() {
        this.nodeList();
      },
              // 搜索清空
    emptyFn(){
      this.tradeNo = ""
      this.orderStatus = ""
      this.creator = ""
    
    },
      handlePageChange(val) {
        this.current = val;
        this.nodeList();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.nodeList();
      },
      imeiSearch() {
        this.current = 1
        // console.log(this.current)
        this.nodeList();
        
      },
      addTracks() {
        if (this.value == '' || this.shipmentNum == "") {
          this.$message({
            message: '请填物流公司和物流单号',
            type: 'warning'
          });
        } else {
          this.$api
            .addTracks({
              company: this.value,
              orderNo: this.orderNo,
              trackNo: this.shipmentNum
            })
            .then(res => {
              // console.log(res.data)
                this.$message.success("发货成功!");
              this.dialogVisible = false
              this.nodeList()

            })
            .catch(error => {
              console.log(error);
            });
        }

      },
      // 点击发货
      orderShip(item) {
        // console.log(item)
        this.orderNo = item.tradeNo;

        this.dialogVisible = true;
        this.value = "";
        this.shipmentNum = "";
        this.$api
          .GetDeliveryCodes({})
          .then(res => {
            console.log(res.data.data)
            this.options = res.data.data
          })
          .catch(error => {
            console.log(error);
          });
      },
      handleClose(done) {
        done();
        this.value = "";
      },
      changeMethod() {
        if (this.value == '') {
          console.log('11000')
          this.$api
            .GetDeliveryCodes({})
            .then(res => {
              console.log(res.data.data)
              this.options = res.data.data
            })
            .catch(error => {
              console.log(error);
            });
        }

      },
      remoteMethod(query) {
        console.log('000')
        if (query !== "") {
          console.log(query);
          this.loading = true;
          this.$api
            .GetDeliveryCodes({
              deliveryName: query
            })
            .then(res => {
              console.log(res.data.data)
              this.options = res.data.data
            })
            .catch(error => {
              console.log(error);
            });
          setTimeout(() => {
            this.loading = false;
            this.options = this.list.filter(item => {
              return item;
            });
          }, 200);
        } else {

        }
      },

      // 进入详情
      orderDetail(item) {
        // console.log(item.id);

        this.$router.push({
          path: `/orderDetail`,
          query: {
            data: item.id
          }
        });
      },
      // 导出
      goodsExport() {
        this.$api
          .GoodsOrderExport({
            query: {
              orderType: this.orderType,
              tradeNo: this.tradeNo,
              orderStatus: this.orderStatus,
              creator: this.creator,
              ids: this.ids
            },
            properties: [
              "orderType",
              "orderAmount",
              "tradeNo",
              "orderStatus",
              "creator",
              "consignee"
            ],
            titles: [
              "订单类型",
              "订单总额",
              "订单号",
              "订单状态",
              "创建人",
              "收货人"
            ]
          })
          .then(res => {
            // console.log(res);
            this.download(res.data);
          })
          .catch(error => {});
      },

      //导出函数
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },

      //批量选中
      getIds(selection, row) {
        var arr = [];
        // console.log(selection)
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].id);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;
        console.log(this.ids);
      },
      //全部选中
      getAllIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].id);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;
        console.log(this.ids);
      },

      nodeList() {
        //查询设备列表
        this.$api
          .GoodsOrderList({
            page: {
              current: this.current,
              size: this.pageSize
            },
            queryDto: {
              tradeNo: this.tradeNo,
              orderStatus: this.orderStatus,
              creator: this.creator
            }
          })
          .then(res => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.currentPage = parseInt(this.current)
            // console.log(this.currentPage)
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    created() {
      //查询产品列表

    },
    mounted() {
      console.log(sessionStorage.getItem("cachecurrent"))
    },
    beforeRouteEnter(to, from, next) {
      // console.log(from.path, '组件独享守卫beforeRouteEnter第二个参数');
      next(vm => {
        if (from.path == '/orderDetail') {
          vm.current = sessionStorage.getItem("cachecurrent")
          vm.tradeNo = sessionStorage.getItem("tradeNo")
          vm.orderStatus = sessionStorage.getItem("orderStatus")
          vm.creator = sessionStorage.getItem("creator")
          // console.log(vm.orderStatus)

          vm.nodeList();
        } else {
          //因为当钩子执行前，组件实例还没被创建
          // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
          // console.log(vm);//当前组件的实例
          vm.current = 1
          vm.tradeNo = ""
          vm.orderStatus = ""
          vm.creator = ""
          vm.nodeList();
        }

      });
    },

    beforeRouteLeave(to, from, next) {
      // 导航离开该组件的对应路由时调用
      if (to.path == '/orderDetail') {
        sessionStorage.setItem("cachecurrent", this.current);
        sessionStorage.setItem("tradeNo", this.tradeNo);
        sessionStorage.setItem("orderStatus", this.orderStatus);
        sessionStorage.setItem("creator", this.creator);

      } else {
        sessionStorage.removeItem("cachecurrent");
        sessionStorage.removeItem("tradeNo");
        sessionStorage.removeItem("tradeNo");
      }
      // console.log(from, '组件独享守卫beforeRouteLeave第二个参数');
      // console.log(next, '组件独享守卫beforeRouteLeave第三个参数');
      next();
    }
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);

  .message-text {
    font-family: MicrosoftYaHei;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3rem;
    letter-spacing: 0rem;
    color: #333333;
    width: 50%;
  }

  .fontblack {
    color: #999999;
  }

  .img2 {
    width: 0.81rem;
    height: 0.8rem;
    float: right;
  }

  .addressshow2 {
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    width: 75%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 1rem;
  }

  .addressshow1 {
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    width: 75%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 1rem;
  }

  .orderTitle {
    font-size: 1rem;
    color: #333333;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    height: 2.5rem;
  }

  .logistics {
    display: inline-block;
    min-width: 140px;
    text-align: right
  }

  .dialog-footer {
    display: inline-block;
    margin: 2rem 0 0 30%;

  }

  .orderDetail {
    font-size: 0.8rem;
    color: #666666;
    text-align: left;
  }

  .border-ceter {
    width: 92%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .pay-button {
    width: 88%;
    height: 2.6rem;
    position: relative;
    background-color: red;
    color: white;

    margin-left: 6%;
  }

  ul li {
    list-style: none;
    font-size: 1rem;
  }

  ul {
    padding-left: 1.5rem;
  }


  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }

  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;

    .searchBut {
      float: right;
      margin-left: 20px;
    }
  }

  .product-content {
    height: 6rem;

    .product-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .container-el-row {
    margin-top: 0px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15px;
    }

    .select-input-right-input {
      float: right;
      margin-top: 7px;
    }
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .item {
    margin: 4px;
    cursor: pointer;
  }

  .right .el-tooltip__popper {
    padding: 8px 10px;
    cursor: pointer;
  }
</style>