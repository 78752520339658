<template>
  <div class="mainContainer">
    <div class="select">
      <span class="title">最近30分钟上报故障数据</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-back"
        @click="goBackHandler"
        class="addRoleButton"
      >返回故障列表</el-button>
    </div>
    <div class="table-vv">
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="device-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column prop="mac" label="设备IMEI" align="center"></el-table-column>
        <el-table-column prop="messageId" label="消息ID" align="center"></el-table-column>
        <el-table-column prop="reportingTime" label="上报时间" align="center"></el-table-column>
        <el-table-column prop="dataPointShowName" label="故障类型" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "faultDetail",
  data() {
    return {
      tableData: [],
      mac: "",
      dataPointShowName: ""
    };
  },
  methods: {
    formatTiem(time) {
      return time.format;
    },
    getFaultDetail() {
      this.$api
        .getFaultDetail({
          params: {
            mac: this.mac
          }
        })
        .then(res => {
          this.tableData = res.data.data;
        })
        .catch(error => {});
    },
    goBackHandler() {
      this.$router.push("/faultList");
    }
  },
  created() {},
  mounted() {
    this.mac = this.$route.params.mac;
    this.getFaultDetail();
  }
};
</script>

<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;
      button {
        color: @toolBarFontColor;
        font-size: 0.8rem;
        float: right;
        margin-top: 7px;
      }
      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1.5rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;

  button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
  }

  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
}
</style>