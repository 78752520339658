<template>
    <div class="outting">
        <div class="select">
            <span class="title">版本更新提示列表</span>
            <el-button type="text" size="small" icon="el-icon-plus" @click="addUserHandler" class="addRoleButton">新增更新提示
            </el-button>
        </div>
        <div class="table-vv">
            <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <el-table-column prop="target" label="目标" min-width="2" fixed align="center">
                    <template slot-scope="{row}">{{ row.target == 1?'登录':"大屏" }}</template>
                </el-table-column>
                <el-table-column prop="upTime" label="版本更新时间" min-width="2" fixed align="center">
                    <template slot-scope="{row}">{{ row.upTime || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column prop="upInfo" label="版本更新提示信息" min-width="2" fixed align="center">
                    <template slot-scope="{row}">{{ row.upInfo || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column prop="upTipTime" label="版本更新提示通知时间" min-width="2" fixed align="center">
                    <template slot-scope="{row}">{{ row.upTipTime || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column prop="isRepeatPush" label="是否重复推送" min-width="1" fixed align="center">
                    <template slot-scope="{row}">{{ row.isRepeatPush == 1?'重复':"不重复"  }}</template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="1" fixed align="center">
                    <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column prop="updateTime" label="修改时间" min-width="1" fixed align="center">
                    <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column min-width="1" label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" icon="el-icon-edit"
                            @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
                        <el-popconfirm title="确定删除这个版本吗？" @onConfirm="deleteAddHandler()">
                            <el-button type="text" size="small" icon="el-icon-delete"
                                @click="handleDelete(scope.$index, scope.row)" style="margin-left:10px;"
                                slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="total" class="pagin"
                @current-change="changeCurrent"></el-pagination>
        </div>
        <el-dialog title="新增更新提示" :visible.sync="addDialogFormVisible" :close-on-click-modal="false">
            <el-form :model="addForm" size="small" :inline="true" style="width:700px" :rules="addRules" ref="addRules"
                enctype="multipart/form-data">

                <el-form-item label="目标" :label-width="formLabelWidth" prop="target">
                    <el-select v-model="addForm.target" placeholder="目标">
                        <el-option label="大屏" value="2"></el-option>
                        <el-option label="登录" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="版本更新时间" :label-width="formLabelWidth" prop="upTime">
                    <el-date-picker v-model="addForm.upTime" :picker-options="pickerOptionsEnd" value-format="timestamp"
                        format="yyyy-MM-dd HH:mm:ss" default-time="['23:59:59']" @change="changeStart" type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="版本更新提示信息" :label-width="formLabelWidth" prop="upInfo">
                    <el-input v-model="addForm.upInfo" autocomplete="off" style="width:215px" type="textarea" :rows="2">
                    </el-input>
                </el-form-item>
                <el-form-item label="版本更新提示通知时间" :label-width="formLabelWidth" prop="upTipTime">
                    <el-date-picker v-model="addForm.upTipTime" :picker-options="pickerOptionsStart"
                        value-format="timestamp" format="yyyy-MM-dd HH:mm:ss" type="datetime" @change="changeEnd"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否重复推送" :label-width="formLabelWidth" prop="isRepeatPush">
                    <el-select v-model="addForm.isRepeatPush " placeholder="是否重复推送">
                        <el-option label="不重复推送" value="0"></el-option>
                        <el-option label="重复推送" value="1"></el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addAppListForm('addForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改更新提示" :visible.sync="editDialogFormVisible" :close-on-click-modal="false">
            <el-form :model="editForm" size="small" :inline="true" style="width:700px" :rules="editRules"
                :auto-upload="false" ref="editRules" >
                <el-form-item label="版本更新提示信息" :label-width="formLabelWidth" prop="upInfo">
                    <el-input v-model="editForm.upInfo" autocomplete="off" style="width:215px" type="textarea"
                        :rows="2"></el-input>
                </el-form-item>
                <el-form-item label="是否重复推送" :label-width="formLabelWidth" prop="isRepeatPush">
                    <el-select v-model="editForm.isRepeatPush" placeholder="是否重复推送">
                        <el-option label="不重复推送" value="0"></el-option>
                        <el-option label="重复推送" value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="editAppListForm('editForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: "AppList",
        data() {
            return {
                current: 1,
                size: 10,
                total: 0,
                tableData: [],
                file: {},
                editFile: {},
                deleteId: "",
                editId: "",
                addDialogFormVisible: false,
                // 限制开始时间
                pickerOptionsStart: {},
                pickerOptionsEnd: {},
                addForm: {
                    target: "",
                    upTime: "",
                    upInfo: "",
                    upTipTime: "",
                    isRepeatPush: "",
                },
                editForm: {
                    isRepeatPush: "",
                    upInfo: "",
                },
                addforceUpdate: "",
                addappType: "",
                editDialogFormVisible: false,
                addRules: {
                    target: [{
                        required: true,
                        message: "请选择推送目标",
                        trigger: "change"
                    }],
                    upTime: [{
                            required: true,
                            message: "请选择版本更新时间",
                            trigger: "change"
                        },

                    ],
                    upInfo: [{
                        required: true,
                        message: "请输入版本更新提示信息",
                        trigger: "blur"
                    }],
                    upTipTime: [{
                        required: true,
                        message: "请选择版本更新提示通知时间",
                        trigger: "change"
                    }],
                    isRepeatPush: [{
                        required: true,
                        message: "请选择是否重复推送,",
                        trigger: "change"
                    }],

                },
                editRules: {
                    isRepeatPush: [{
                        required: true,
                        message: "请选择是否重复推送,",
                        trigger: "change"
                    }],
                    upInfo: [{
                        required: true,
                        message: "请输入版本更新提示信息",
                        trigger: "blur"
                    }],
                },
                formLabelWidth: "180px",
                editforceUpdate: "",
                editappType: ""
            };
        },
        created() {
            this.appList();
        },
        methods: {
            //删除函数
            handleDelete(index, row) {
                this.deleteId = row.id;
            },
            //删除确定
            deleteAddHandler() {
                this.$api.versionUpTipsDelete(this.deleteId).then(res => {
                    console.log(res.data);
                    if (res.data.code == 200) {
                        this.$message.success("删除成功!");
                        this.appList();
                    }
                });
            },
            //更新函数
            handleUpdate(index, row) {
                this.editId = row.id;
                this.editFile = {};
                this.editDialogFormVisible = true;
                if (this.$refs["editRules"] !== undefined) {
                    this.resetForm("editRules");
                }
                console.log(row);
                this.editForm.upInfo = row.upInfo   
                this.editForm.isRepeatPush = row.isRepeatPush   
                if(row.isRepeatPush == 0) {
                     this.editForm.isRepeatPush = '不重复推送'
                }else{
                    this.editForm.isRepeatPush = '重复推送'
                }
            
            },

            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            appList() {
                this.$api
                    .versionUpTipList({
                        current: this.current,
                        size: 10
                    })
                    .then(res => {
                        // console.log(res.data);
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                    });
            },
            addUserHandler() {
                this.file = {};

                this.addDialogFormVisible = true;

                if (this.$refs["addRules"] !== undefined) {
                    this.resetForm("addRules");
                }
            },
            stateFormatAppType(row) {
                if (row.type == 0) {
                    return "安卓";
                } else if (row.type == 1) {
                    return "IOS";
                } else if (row.type == 2) {
                    return "onenet";
                } else {
                    return "暂无数据";
                }
            },
            stateFormatForceUpdate(row) {
                if (row.forceUpdate == 0) {
                    return "是";
                } else if (row.forceUpdate == 1) {
                    return "否";
                } else {
                    return "暂无数据";
                }
            },
            changeCurrent(id) {
                this.current = id;
                this.appList();
            },

            changeAppType(id) {
                // console.log(id);
                this.addappType = Number(id);
            },
            editchangeAppType(id) {
                // console.log(id);
                this.editappType = Number(id);
            },
            addAppListForm(formName) {
                this.$refs["addRules"].validate(valid => {
                    if (valid) {
                        this.addDialogFormVisible = false;


                        // this.addForm.upTime =  this.timestampToTime(this.addForm.upTime)
                        // this.addForm.upTime =  new Date();

                        //  this.addForm.upTipTime=this.timestampToTime(this.addForm.upTipTime) 
                        //   this.addForm.upTipTime =  new Date();
                        this.$api.versionUpTipsAdd(this.addForm).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("新增更新提示成功！");
                                this.file = {};
                                this.appList();
                            }
                        });

                    } else {
                        return false;
                    }
                });
            },
            editAppListForm(editForm) {
                this.$refs["editRules"].validate(valid => {
                    if (valid) {
                        this.editDialogFormVisible = false;
                     if(this.editForm.isRepeatPush == '重复推送'){
                       this.editForm.isRepeatPush  = 1
                     }else if(this.editForm.isRepeatPush == '不重复推送'){
                           this.editForm.isRepeatPush  = 0
                     }
                   this.editForm.id = this.editId
                     
                            this.$api.versionUpTipUpdate(this.editForm).then(res => {
                                if (res.data.code == 200) {
                                    this.$message.success("编辑成功！");
                                    this.editFile = {};
                                    this.appList();
                                }
                            });
                     
                      
                    } else {
                        return false;
                    }
                });
            },
            handleChange(file, fileList) {
                console.log(file);
                this.file = file.raw;
            },
            editHandleChange(file, fileList) {
                console.log(file);
                this.editFile = file.raw;
            },
            // 结束时间限制开始时间
            changeStart() {
                if (!this.addForm.upTime) {
                    this.pickerOptionsStart = {
                        disabledDate: {}
                    }
                    return
                }
                this.pickerOptionsStart = Object.assign({}, this.pickerOptionsStart, {
                    // 可通过箭头函数的方式访问到this
                    disabledDate: (time) => {
                        var times = ''
                        times = time.getTime() > this.addForm.upTime
                        return times
                    }
                })
            },
            // 开始时间 控制结束时间
            changeEnd() {
                if (!this.addForm.upTipTime) {
                    this.pickerOptionsEnd = {
                        disabledDate: {}
                    }
                    return
                }
                this.pickerOptionsEnd = Object.assign({}, this.pickerOptionsEnd, {
                    disabledDate: (time) => {
                        return time.getTime() < this.addForm.upTipTime
                    }
                })
            },
            timestampToTime(value) {
                let date = new Date(value);
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? ('0' + MM) : MM;
                let d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                let h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                let m = date.getMinutes();
                m = m < 10 ? ('0' + m) : m;
                let s = date.getSeconds();
                s = s < 10 ? ('0' + s) : s;
                console.log(date)
                return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;

            }


        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .select-status {
                width: 9rem;
                margin-left: 1.7rem;
                margin-top: 7px;
                // margin-top:7px;
            }

            .select-input-timer {
                float: right;
            }

            .select-status {
                float: right;
            }

            .select-input-right-input {
                float: right;
                // width: 8rem;
                line-height: 46px;
            }

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .addRoleButton {
                margin-left: 15px;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }

            .update-input-timer {
                float: right;
                line-height: 46px;
                margin-left: 25px;
                height: 46px;

                .demonstrationTwo {
                    float: left;
                    font-size: 0.8rem;
                }

                .update-input-timer-select {
                    width: 11rem;
                    margin-left: 5px;
                }
            }
        }

        .table-vv {
            padding: 1rem 3rem;

            .pagin {
                margin-left: 30%;
                margin-top: 10px;
                float: right;
            }
        }
    }
</style>