<template>
  <div id="main-container">
    <el-container>
      <el-header>
        <div id="nav" style="display: flex; background-color: #132230;">
          <div style=" height: 61px;width: 215px;">
            <div style="height: 61px;width: 61px;float: left;" @click="collapseChage">
              <span
                style="height: 61px;width: 61px;line-height: 61px;display: block;text-align: center;"
              >
                <i v-if="!collapse" class="el-icon-s-fold" style="font-size: 22px;"></i>
                <i v-else class="el-icon-s-unfold" style="font-size: 22px;"></i>
              </span>
            </div>
            <div style="height: 61px;width: 139px;float: right;" @click="goHome" class="home-vv">
              <img src="../assets/bigScreen/logo_bird.png" class="logo_title" />
              <img src="../assets/circle.svg" style="height: 10px;width: 10px;margin: 15px;" />
              <img src="../assets/bigScreen/logo.png" style="width: 30px;margin-top: 15px;" />
            </div>
          </div>

          <div style="flex: 1;">
            <el-menu
              class="el-menu-demo"
              :default-active="activeIndex"
              mode="horizontal"
              background-color="#122230"
              text-color="#fff"
              active-text-color="#ffd04b"
            >
              <el-menu-item
                v-for="(item,index) in menuList"
                :key="item.platformId"
                @click="changeMenu(index)"
                :index="index.toString()"
              >
                <div>{{ item.platformName }}</div>
              </el-menu-item>
              <el-menu-item class="menu-item-right right-most-item" index="300000" title="帮助中心">
                <i class="el-icon-question" style="font-size: 22px;"></i>
              </el-menu-item>
              <el-menu-item
                class="menu-item-right"
                v-show="this.ifShow"
                @click="toSystemSetting()"
                title="系统设置"
                index="6"
              >
                <i class="el-icon-s-tools" style="font-size: 22px;"></i>
              </el-menu-item>

              <el-menu-item class="menu-item-right" title="个人中心" index="7">
                <el-dropdown size="mini">
                  <i class="el-icon-user-solid el-icon--right" style="font-size: 22px;"></i>
                  <el-dropdown-menu slot="dropdown">
                    <router-link to="/mySelf">
                      <el-dropdown-item divided class="bottom-select-item">个人中心</el-dropdown-item>
                    </router-link>
                    <router-link to="/login">
                      <el-dropdown-item divided @click.native="layouOut">退出登录</el-dropdown-item>
                    </router-link>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside width="auto" class="aside">
          <el-scrollbar style="height: 100%">
            <el-menu
              :default-active="activeIndex2"
              class="el-menu-vertical-demo"
              background-color="#122230"
              text-color="#fff"
              active-text-color="#ffd04b"
              :collapse="collapse"
              @select="selectMenu"
              :default-openeds="defaultOpeneds"
            >
              <el-submenu v-for="(item) in sideBar" :key="item.id" :index="item.router">
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group v-if="item.children != undefined">
                  <el-menu-item
                    v-for="(child) in item.children"
                    :key="child.id"
                    :index="child.router"
                  >
                    <i :class="child.icon"></i>
                    {{"\u3000" + child.name}}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-scrollbar>
        </el-aside>
        <el-main>
          <el-scrollbar style="height: 100%">
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                  <router-view />
                </div>
              </el-col>
            </el-row>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
    <!-- <div id="preloader" style="display: none;">
      <div></div>
    </div>-->
  </div>
</template>

<script>
import bus from "../components/bus";
import { mapActions } from "vuex";
import Storage from "../utils/Storage";
import { arrayToTree } from "../configs/index";
import base from "../api/base";
export default {
  conponents: {},
  data() {
    return {
      request: false,
      collapse: false,
      menuList: [],
      cloudList: [],
      ifShow: false,
      sideBar: [],
      sysMenuList: [],
      index: "",
      isRouter: true,
      activeIndex: "",
      activeIndex2: "", //高亮路由
      activeIndex22: "", //跳转路由
      defaultOpeneds: [],
      defaultOpenedsArray: []
    };
  },
  created() {
    var token = this.$store.state.userinfo.token;
    this.$api
      .getMenuList(token)
      .then(res => {
        console.log(res.data)
           this.menuList = res.data.data.menuList;
        this.sysMenuList = res.data.data.sysMenuList;
        this.ifShow = res.data.data.showSys;
        if (sessionStorage.getItem("activeIndex")) {
          this.activeIndex = sessionStorage.getItem("activeIndex");
          this.setActiveIndexActions({
            activeIndex: this.activeIndex
          });
        } else {
          console.log("没有activeIndex数据");
          if (res.data.data.menuList.length !== 0) {
            this.activeIndex = "0";
            this.setActiveIndexActions({
              activeIndex: "0"
            });
          } else {
            this.activeIndex = "6";
            this.setActiveIndexActions({
              activeIndex: "6"
            });
          }
        }
        if (JSON.parse(sessionStorage.getItem("sideBar"))) {
          this.sideBar = JSON.parse(sessionStorage.getItem("sideBar"));
          this.setSidebarActions({
            sidebar: this.sideBar
          });
        } else {
          if (res.data.data.menuList.length !== 0) {
            this.sideBar = Storage.toTree(res.data.data.menuList[0].menuList);
          } else {
            this.sideBar = Storage.toTree(this.sysMenuList);
          }
          this.setSidebarActions({
            sidebar: this.sideBar
          });
        }

        this.reset();
        //高亮路由
        if (sessionStorage.getItem("activeIndex2")) {
          console.log("有activeIndex2数据");
          // this.$router.push(sessionStorage.getItem("activeIndex2"));
          this.activeIndex2 = sessionStorage.getItem("activeIndex2");
          this.setActiveIndex2Actions({
            activeIndex2: this.activeIndex2
          });
        } else {
          console.log("没有activeIndex2数据");
          this.$router.push(this.activeIndex2);
          this.setActiveIndex2Actions({
            activeIndex2: this.activeIndex2
          });
        }
        //跳转路由
        // if (sessionStorage.getItem("activeIndex22")) {
        //   console.log("tiaohuan");
        //   this.$router.push(sessionStorage.getItem("activeIndex22"));
        // } else {
        //   this.$router.push(this.activeIndex2);
        // }
        })
      .catch(error => {
        console.log(error);
        // this.$router.replace("/login");
      });
    //存储本地接口List
    this.setLocalListActions({
      localList: base
    });
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(
        "sideBar",
        JSON.stringify(this.$store.state.sidebar.sidebar)
      );
      sessionStorage.setItem(
        "activeIndex2",
        this.$store.state.activeIndex2.activeIndex2
      );
      sessionStorage.setItem(
        "activeIndex",
        String(this.$store.state.activeIndex.activeIndex)
      );
      sessionStorage.setItem("activeIndex22", this.$route.path);
      sessionStorage.setItem(
        "cloudList",
        window.JSON.Stringify(this.$store.state.cloudList.cloudList)
      );
    });
    this.userInfomationHandler();
  },

  destroyed() {
    window.removeEventListener("beforeunload", e =>
      this.beforeunloadHandler(e)
    );
  },
  methods: {
    ...mapActions([
      "setSidebarActions",
      "setActiveIndex2Actions",
      "setActiveIndexActions",
      "seUserInfoActions",
      "setLocalListActions",
      "setCloudListActions"
    ]),
    toSystemSetting() {
      this.setActiveIndexActions({
        activeIndex: "6"
      });
      this.sideBar = Storage.toTree(this.sysMenuList);
      this.setSidebarActions({
        sidebar: this.sideBar
      });
      this.reset();
      this.$router.push(this.activeIndex2);
    },
    collapseChage() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
    changeMenu(index) {
      console.log(index);
      this.setActiveIndexActions({
        activeIndex: String(index)
      });
      this.activeIndex = String(index);
      this.sideBar = Storage.toTree(this.menuList[index].menuList);
      this.setSidebarActions({
        sidebar: this.sideBar
      });
      this.reset();
      this.$router.push(this.activeIndex2);
    },
    selectMenu(index, indexPath) {
      this.$router.push(index);
      this.activeIndex2 = index;
      this.setActiveIndex2Actions({
        activeIndex2: index
      });
    },
    //回到主页
    goHome(){
      console.log('点击')
       this.$router.push('/');
    },
    reset() {
      for (var i = 0, len = this.sideBar.length; i < len; i++) {
        this.defaultOpenedsArray[i] = this.sideBar[i].router;
      }
      this.defaultOpeneds = this.defaultOpenedsArray;
      console.log(this.sideBar);
      this.activeIndex2 = this.sideBar[0].children[0].router;
      //  this.activeIndex2 = this.sideBar[0].router;
      this.setActiveIndex2Actions({
        activeIndex2: this.activeIndex2
      });
    },
    //退出登录清除数据函数
    layouOut() {
      // console.log('退出清除数据')
      window.localStorage.clear("token");
      window.sessionStorage.clear();
      this.seUserInfoActions({
        token: null
      });
      this.setSidebarActions({
        sidebar: null
      });
      this.setActiveIndex2Actions({
        activeIndex2: null
      });
      this.setActiveIndexActions({
        activeIndex: null
      });
      this.setLocalListActions({
        localList: null
      });
      this.setCloudListActions({
        cloudList: null
      });
    },
    //获取当前登录，分派权限
    userInfomationHandler() {
      this.$api
        .userDetail()
        .then(res => {
          // console.log(res.data.data);
          sessionStorage.setItem("currentUserId", res.data.data.id);
          for (var i = 0; i < res.data.data.permissions.length; i++) {
            if (res.data.data.permissions[i].permissionType == 2) {
              this.cloudList.push(res.data.data.permissions[i].uri);
            }
          }
          sessionStorage.setItem("cloudList", JSON.stringify(this.cloudList));
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="less">
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}

.iconsize {
  font-size: 23px;
}

.spName {
  color: #fff;
}

.logo_title {
  width: 32px;
  margin-top: 14px;
  margin-left: 20px;
}

.logo {
  background-image: url(../assets/bigScreen/logo_bird.png);
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center;
}

.el-dropdown-menu__item {
  text-align: center;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

#nav {
  padding-left: 0px;

  // min-width: 1200px;
  .el-menu-demo {
    font-weight: bold;
    color: #12b7f5;
  }
}

.el-menu.el-menu--horizontal {
  // border-bottom: none;
  border-bottom: solid 1px yellow;
}

.el-menu {
  border: 0;
}

#main-container {
  height: 100%;
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: Helvetica, Arial, Tahoma, "Microsoft YaHei", "黑体", SimSun;
  overflow: auto;
}

//页面主体部分
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

//消除header内边距
.el-header {
  padding: 0px;
  background: red;
  overflow: hidden;

  span {
    i {
      color: #dddddd;
    }
  }
}

//main区域撑满高度
.el-main {
  background-color: #fafafa;
  color: #333;
  padding: 0px;
  line-height: 1.5rem;
  height: 100%;
  overflow: auto;

  .menu-btn {
    position: relative;
  }

  .el-row {
    line-height: 1.5rem;
    min-width: 1200px !important;

    .toolbar {
      margin: 0rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//container 撑满页面
.el-container {
  height: 100%;
}

//顶部导航栏右侧按钮样式
.menu-item-right {
  float: right !important;
  height: 60px;
  font-size: 60px;
  text-align: center;
}

.menu-item-right.right-most-item {
  margin-right: 2rem;
}

.menu_background {
  //background: 0 x392824 !important;
  font-size: 0.75rem;
  color: #dddddd !important;
}

.el-menu--horizontal {
  .el-menu-item {
    &:extend(.menu_background);

    &:hover {
      background: rgb(84, 92, 100) !important;
    }
  }
}
.el-scrollbar__wrap {
  overflow-x: hidden;
}
.horizontal-collapse-transition {
  transition: 0s width ease-in-out, 0s padding-left ease-in-out,
    0s padding-right ease-in-out;
}
.home-vv{
  &:hover{
    cursor:pointer
  }
}
</style>
