<template>
    <div class="jylh">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click.native="backTo">
                    {{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"产品中心"}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color:#f28c02">
                        产品详情
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="major_service">
            <div class="item-title">
                <p> 家用礼盒套装</p>
                <div>
                    <img src="../../../assets/productCut/JYLH.png" alt="" class="image">
                    <span class="text_list" style="margin-bottom:30px">
                        <!-- (JTY-GF-WSH6101) -->
                    </span>
                </div>
                <div class="level_subject">
                    概述
                </div>
                <div class="text_subject">
                    <img src="../../../assets/login/yingjilh.png" alt="" class="image">
                </div>
                <div class='botom_tit'>   *如需获取产品3c证书、检验报告、说明书等，
               可扫描底部公众号二维码进入“吻胜智能生活”公众号获取</div>
                <!-- <div class="text_subject">
                 家用消防礼盒-独立式1 <br>
                 独立式烟感×1、独立式甲烷探测器×1、独立式CO探测器×1、灭火毯×1、应急手电×1、灭火器×1
                </div>
                <div class="text_subject">
                 家用消防礼盒-独立式2 <br>
                 独立式烟感×2、独立式甲烷探测器×1、灭火毯×1、应急手电×1、灭火器×1
                </div>
                <div class="text_subject">
                 家用消防礼盒-独立式3 <br>
                 独立式烟感×2、独立式CO探测器×1、灭火毯×1、应急手电×1、灭火器×1
                </div>
                <div class="text_subject">
                 家用消防礼盒-NB式1 <br>
                 NB烟感×2、NB甲烷探测器×1、NB式CO探测器×1、灭火毯×1、应急手电×1、灭火器×1
                </div>
                 <div class="text_subject">
                 家用消防礼盒-NB式2 <br>
                 NB烟感×2、NB甲烷探测器×1、灭火毯×1、应急手电×1、灭火器×1
                </div>
                 <div class="text_subject">
                 家用消防礼盒-NB式3 <br>
                 NB烟感×2、NB式CO探测器×1、灭火毯×1、应急手电×1、灭火器×1
                </div> -->
            </div>

            <div>

            </div>
        </div>


    </div>
</template>
<script>
    import '../../../../static/css/global.css'
    export default {
        name: "jylh",
        components: {},
        data() {
            return {

            };
        },
        mounted() {
            window.addEventListener('scroll', this.scrollToTop)
            const that = this
            let ispeed = Math.floor(-that.scrollTop / 5)
            document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        },
        created() {},
        methods: {
            scrollToTop() {
                const that = this
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                that.scrollTop = scrollTop
                that.btnFlag = false
            },
            backTo() {
                if (this.$route.query.crumbsTitle == '首页') {
                    let vm = this
                    vm.$router.replace({
                        path: `/`
                    });
                } else {
                    this.$router.replace({
                        path: "/productCenter"
                    });
                }
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTop)
        },
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1370px) {
        .jylh {
            background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;

            .text_subject {
                font-size: 1.05rem;
                line-height: 1.8rem;
                padding-bottom: 3rem;
                padding-top: 1.5rem;
            }

            .level_subject {
                font-size: 1.15rem;
            }
        }
    }

    @media screen and (min-width: 1370px) {
        .jylh {
            background-color: #efefef;
            overflow-x: auto;
            min-width: 1300px;

            .text_subject {
                font-size: 1.25rem;
                line-height: 2.5rem;
                padding-bottom: 3rem;
                padding-top: 2rem;
            }

            .level_subject {
                font-size: 1.35rem;
            }
        }
    }

    .breadcrumb {
        padding: 2rem 0 2rem 0;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;
        z-index: 2000;

        /deep/.el-breadcrumb {
            float: right;
        }

        /deep/.el-breadcrumb__inner:hover {
            color: #f28c02;
        }
    }
 .botom_tit{
      text-align: left;
      width: 60%;
       margin: 0 auto;
         padding-bottom:  4rem;
         padding-left:  2rem;
  }
    .major_service {
        width: 100%;
        padding-bottom: 20px;
        overflow: hidden;

        .item-title {
            // padding-top: 2rem;
            text-align: center;
            // color: #333333;
            width: 100%;
            margin: 0 auto;

            .image {
                width: 20%;
                display: block;
                margin: 0 auto;
            }

            p {
                text-shadow: 1px 1px 1px #666;
                display: inline-block;
                color: #333333;
                padding: 0 50px;
                font-size: 1.5em;
            }

            .text_list {
                display: inline-block;
                transform: translateY(-2rem);
                font-size: 1rem;
                color: #333333;
            }

            .level_subject {
                text-align: left;
                width: 60%;
                letter-spacing: 4px;
                margin: 0 auto;
                //    font-size: 1.35rem; 
                color: #333333;
                font-weight: 600;
            }

            .text_subject {
                display: inline-block;
                //  font-size: 1.25rem;

                text-align: justify;
                width: 60%;

                margin: 0 auto;
                // text-align: left;
                color: #333333;

                // border: #f28c02 1px solid;

                img {
                    width: 100%;

                }

            }

        }


    }
</style>