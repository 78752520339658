<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">新增工单</span>
        <!-- <el-button
          type="text"
          size="small"
          icon="el-icon-back"
          @click="gobackPlaceDetail"
          class="addRoleButton"
        >返回问题列表</el-button> -->
      </div>
      <div class="detail">
        <el-card class="box-card" shadow="never">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-row v-if="accountLevel == 0">
              <el-col :span="6" :offset="1">
                <el-form-item label="吻胜云账号" prop="username">
                  <el-input v-model="ruleForm.username" placeholder="请填写吻胜云账号" @blur="getPhone()"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" :offset="1">
                <el-form-item label="手机号" prop="phone">
               <el-input v-model="ruleForm.phone" placeholder="请填写手机号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row>
              <el-col :span="6" :offset="1">
                <el-form-item label="IMEI" prop="IMEI">
               <el-input v-model.number="ruleForm.IMEI"  placeholder="请填写IMEI"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" :offset="1">
                <el-form-item label="问题标题" prop="problemTitle">
                  <el-input v-model.trim="ruleForm.problemTitle" placeholder="请输入问题标题"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" :offset="1">
                <el-form-item label="问题类型" prop="problemTypeId">
                  <el-select
                    v-model="ruleForm.problemTypeId"
                    placeholder="请选择问题类型"
                    style="width:100%"
                  >
                    <el-option
                      v-for="item in problemTypeId"
                      :key="item.id"
                      :label="item.typeName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" :offset="0">
              
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" :offset="1">
                <el-form-item label="问题描述" prop="problemDesc">
                  <el-input
                    v-model="ruleForm.problemDesc"
                    placeholder="请输入问题描述"
                    type="textarea"
                    size="mini"
                    rows="5"
                    style="margin-right:2rem;margin-bottom:10px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          
            <el-row>
              <el-col :span="14" :offset="1">
                <el-form-item label="图片" prop="file">
                  <el-upload
                    class="upload-demo"
                    action
                    :on-change="handleChange"
                    :on-remove="handleRemove"
                    :on-exceed="handleExceed"
                    list-type="picture-card"
                    :file-list="fileList"
                    :limit="limitUpload"
                    :auto-upload="false"
                    ref="my-upload"
                  >
                    <el-button type="primary" round size="mini" class="input-button">上传图片</el-button>
                    <br />
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" style="margin-top:50px;margin-left:15%">
                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlaceDetail",
  data() {
         var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (phoneReg.test(value)) {
            callback();
            console.log("zhengq ")
          } else {
            callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };
    return {
      formLabelWidth: "120px",
      // 默认步骤数
      limitUpload: 5,
      fileList: [],
      accountLevel:"",//判断是不是超级账号
      problemTypeId: [], //问题类型
      templateId: [], //处理模板
      product: [], //获取产品下拉列表接口
      stepActive: "stepActive",
      realNameList: [],
      ruleForm: {
        username: "",
        phone: "",
        IMEI: "",
        problemDesc: "",
        problemTitle: "",

        file: [],
        problemTypeId: "",

      },
      rules: {
        username: [
          { required: false, message: "请选择吻胜云账号", trigger: "blur" }
        ],
        phone: [
           { required: true, validator: checkPhone, trigger: "blur" }
        ],
    
        problemDesc: [{ required: true, message: "请输入问题描述", trigger: "blur" }],
        problemTitle: [
          { required: true, message: "请输入问题标题", trigger: "blur" }
        ],
        problemTypeId:[
          { required: true, message: "请选择类型", trigger: "change" }
        ],
         IMEI:[
          { required: true,  message: "请填写IMEI", trigger: "blur" },
         
        ],
        file: [{ required: false, message: "请上传图片", trigger: "blur" }]
      }
    };
  },
  created() {
     this.accountLevel =  sessionStorage.getItem('accountLevel');
  },
  mounted() {
    let data = this.$store.state.data;
    // 处理者下拉框接口
    // this.$api.moduleselectorList().then(res => {
    //   this.realNameList = res.data.data;
    // });
       // 获取产品下拉列表接口
    // this.$api.problemListForPull().then(res => {
    //   this.product = res.data.data;
    // });
    // 问题类型下拉框接口
    this.$api.selectorForWorkOrder().then(res => {
      this.problemTypeId = res.data.data;
    });
    // 问题模板下拉框接口
    // this.$api.processTemplateselector().then(res => {
    //   this.templateId = res.data.data;
    // });
  },
  methods: {
    // 获取手机号
    getPhone(){
       this.$api
        .getUserMobile(this.ruleForm.username)
        .then(res => {
          console.log(res.data.data)
          if(res.data.data == null){
               this.$message.error("账号不存在");
                this.ruleForm.phone  = ""
          }else{
            this.ruleForm.phone  = res.data.data 
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        let formData = new FormData();
        let fileList2 = [];
        this.fileList.forEach(file => {
          formData.append("files", file.raw); //此处一定是append file.raw 上传文件只需维护fileList file.raw.name要加上
          // formData.append("fileNames", file.name);
        });
        // formData.append("files", JSON.stringify(this.fileList));
        formData.append("accountName", this.ruleForm.username);
        formData.append("phone", this.ruleForm.phone );
        formData.append("mac", this.ruleForm.IMEI );
     
        formData.append("problemDesc", this.ruleForm.problemDesc);
        formData.append("problemTitle", this.ruleForm.problemTitle);
        formData.append("problemTypeId", this.ruleForm.problemTypeId);

        if (valid) {
          this.$api.submitWorkOrder(formData).then(res => {
            if (res.data.code == 200) {
              this.$message.success("提交成功!");
              this.$nextTick(() => {
                this.$refs["ruleForm"].clearValidate();
                this.ruleForm.ccUserIds = [];
                this.ruleForm.assignUserId = "";
                this.ruleForm.mac = "";
                this.ruleForm.problemDesc = "";
                this.ruleForm.problemTitle = "";
                this.ruleForm.problemTitle = "";
                this.ruleForm.problemTypeId = "";
                this.ruleForm.templateId = "";
                this.ruleForm.productId = "";
                this.fileList = [];
                this.$router.push("/myWorkorder");
              });
            } 
          });
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    handleChange(file, fileList) {
      console.log(file.raw, fileList);
      this.fileList = fileList;
      if (this.fileList == []) {
        this.$message({
          type: "warning",
          message: "请上传图片！"
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },
    //移除文件的操作方法
    handleRemove(file, fileList) {
      this.fileList = null;
      this.tableData = [];
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //回到模块页面
    gobackPlaceDetail() {
      this.$router.push("/questionList");
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .item {
    height: 80px;
    width: 80px;
    border: 1px solid blue;
    float: left;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }
  .detail {
    padding: 0rem 3rem;
    margin-top: 1rem;
    .detail-span {
      font-size: 12 /16rem;
      color: #898989;
    }
    .detail-vv {
      margin-top: 5 /16rem;
      font-size: 14 /16rem;
    }
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
//   .bg-purple {
//     background: #d3dce6;
//   }
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.stepShow {
  width: 80%;
  margin: 3rem auto;
  .downloadList {
    //  transform:translate(5.5rem, -4rem)
  }
  /deep/.el-step.is-horizontal.stepActive {
    .el-step__head.is-finish {
      .el-step__line {
        // 当前步骤数横线样式设置
        .el-step__line-inner {
          width: 50% !important;
          border-width: 1px !important;
        }
      }
      // 当前步骤数圆圈样式设置
      .el-step__icon.is-text {
        background: #409eff;
        color: #fff;
      }
    }
  }
}
.outting .item {
  margin: 10px;
  height: auto;
  width: auto;
  cursor: pointer;
  border: none;
}

.right .el-tooltip__popper {
  padding: 8px 10px;
  border: none;
  cursor: pointer;
}
</style>