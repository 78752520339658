import { render, staticRenderFns } from "./ediAdvertise.vue?vue&type=template&id=beaf3a2a&scoped=true&"
import script from "./ediAdvertise.vue?vue&type=script&lang=js&"
export * from "./ediAdvertise.vue?vue&type=script&lang=js&"
import style0 from "./ediAdvertise.vue?vue&type=style&index=0&id=beaf3a2a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beaf3a2a",
  null
  
)

export default component.exports