<template>
	<el-container>
		<el-main>
			<div class="main-left">
				<el-menu default-active="2" class="el-menu-vertical" @open="handleOpen" @close="handleClose" background-color="#363636"
				 text-color="#fff" active-text-color="#ffd04b" :collapse="collapse" :router="isRouter">
					<el-submenu v-for="(item) in sideBar" :key="item.id" :index="item.router">
						<template slot="title">
							<i :class="item.icon"></i>
							<span>{{ item.name }}</span>
						</template>
						<el-menu-item-group>
							<el-menu-item v-for="(child) in item.children" :key="child.id" :index="child.router">
								<i :class="child.icon"></i>
								{{ child.name }}
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
				</el-menu>
			</div>
			<div class="main-right">
				<el-row>
					<el-col :span="24">
						<div class="grid-content bg-purple-dark">
							<router-view />
						</div>
					</el-col>
				</el-row>

			</div>
		</el-main>
	</el-container>
</template>

<script>
	import Storage from "../../utils/Storage";
	import bus from "@/components/bus";

	export default {
		name: "WenSheng",
		data() {
			return {
				//  sideBar:[{id:1,name:"fenghong",router:'/rolemanagement',children:[{id:3,name:"fengxiaohong",router:'/rolemanagement'}]},{id:2,name:"hhh"}],
				sideBar: [],
				index: "",
				collapse: false,
				isRouter: true,
			};
		},
		components: {},

		mounted() {
			this.sideBar = Storage.toTree(this.$store.state.wenshengtwo);
			bus.$on('collapse', msg => {
				console.log("msg:" + msg)
				this.collapse = msg;
				bus.$emit('collapse-content', msg);
			});
		},
		methods: {
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			navRouter(url) {
				this.$router.push(url);
			},
		},
	};
</script>
<style lang="less" scoped>
	.el-main {
		height: 100%;

		.main-left {
			float: left;
			height: 100%;
			background: blue;
			overflow: hidden;

			.el-menu-vertical {
				&.el-menu {
					border: 0px;
					height: 100%;

					.el-menu-item,
					.el-submenu {
						min-width: 200px;
					}

					&.el-menu--collapse {

						.el-menu-item,
						.el-submenu {
							min-width: 0px;
						}
					}
				}
			}
		}

		.menu-btn {
			position: relative;
		}

		.main-right {
			height: 100%;
			background: #fafafa;
			overflow: auto;
			line-height: 1.5rem;
		}

		.el-row {
			line-height: 1.5rem;
			min-width: 1200px !important;

			.toolbar {
				margin: 0rem;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
</style>
