<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删此条应用案例吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDelete">取 消</el-button>
        <el-button type="primary" @click="handleDelClose">确 定</el-button>
      </span>
    </el-dialog>
    <div class="select">
      <span class="title">应用案例列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-refresh"
        class="select-button"
        @click="refresh"
      >刷新</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        class="select-button"
        @click="showAddProductDialog"
      >新增</el-button>
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个模板
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="newsTitle" label="案例标题" align="center"></el-table-column>
        <el-table-column prop="position" label="案例地址" align="center"></el-table-column>
        <el-table-column prop="applicationSystem" label="案例应用系统" align="center"></el-table-column>
        <el-table-column prop="sort" label="案例排序号" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="案例主图" align="center">
            <template slot-scope="scope">
            <!-- <el-popover placement="top-start" title trigger="hover"> -->
              <el-image
               slot="reference"
                :src="scope.row.newsImgUrl"
                :fit="fit"
                style="width:30px;height:30px"
                referrerpolicy="no-referrer"
              ></el-image>
            <!-- </el-popover> -->
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              @click="productDetail(scope.row)"
              type="text"
              size="small"
              icon="el-icon-info"
            >修改</el-button>
            <el-button
              @click="deleteProductD(scope.row.id)"
              type="text"
              size="small"
              icon="el-icon-delete"
            >删除</el-button>

          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20]"
        :page-size="pageSize"
        :total="total"
        layout="sizes,prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>

  </div>
</template>


<script>

export default {
  name: "newsList",
  data() {
    return {
      productDel: false,
      productAdd: false,
      form: {
        templateName: "",
        templateDesc: ""
      },
      visible: false,
      total: 0,
      tableData: [],
        fit: "fill",
      emileList: [
        {
          value: "1",
          label: "是"
        },
        {
          value: "0",
          label: "否"
        }
      ], //搜索下拉选择
      chooseOnlineStatus: "", //搜索选择
      sysUserlist: [], //账号所属类型
      newSsUserlist: {
        value: "id",
        label: "typeName",
        children: "childs",
        expandTrigger: "hover"
        // emitPath: false
      },
      checked: false,
      //新增账号弹出对话框标记
      dialogVisible: false,
      //修改账号弹出对话框标记
      modifydialogVisible: false,
      //节点问题状态
      protocols: [],

      //产品品类列表
      categories: [],

      value: "",
      input: "",
      current: 1,
      pageSize: 10,
      delDialogVisible: false,
      productId: "",
      indexId: "", //反显id
      nameinput: "", //搜索输入
      indexIdlist: "", //反显列表
      formLabelWidth: "120px",

    };
  },
  methods: {
    refresh() {
      this.newsList();
    },
    ifMohuHandler(e) {
      // console.log(e);
      this.ifMohu = false;
      this.searchData = [];
    },
    // changex清空
    upOptionType() {
      this.nameinput = "";
    },
    handlePageChange(val) {

      this.current = val;
      this.newsList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.newsList();
    },
    //修改模板
    productDetail(data) {

        this.$router.push({
        path: `/applicationCasesEdit`,
        query: {
            data: data
          }

      });
    },


    deleteProductD(data) {
      this.delDialogVisible = true;
      this.productId = data;
    },
    cancelDelete() {
      this.delDialogVisible = false;
      this.productId = "";
    },
    handleDelClose() {
      this.delDialogVisible = false;
      this.deleteProduct(this.productId);
    },
    deleteProduct(data) {

      this.$api
        .deleteAppCases(data)
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("删除应用案例成功!");
            this.newsList();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },


    newsList() {
      //查询设备列表
      this.$api
        .appCasesList({
            page: {
            current: this.current,
            size: this.pageSize
            }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 资讯新增
    showAddProductDialog() {
      this.$router.push({
        path: `/applicationCasesAdd`,

      });
    },



  },
  created() {
    //查询产品列表
    this.newsList();

  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.product-content {
  height: 6rem;

  .product-info-item {
    float: left;
    margin-right: 4rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 13rem;
    }
  }
}
.container-el-row {
  margin-top: 0px;
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15px;
  }
  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}
</style>
