<template>
    <div>
        <div class="statis_box">
            <el-card class="box-card" v-for="(item,index) in tableData" :key="index"  @click="getInfo(item)">
                <div @click="getInfo(item)" >
                   <div class="item"><span>统计日期:</span>{{item.statisticsDate }}</div>
                   <div class="item"><span>描述信息:</span>{{item.description  }}</div>
                   <div class="item"><span>UV:</span>{{item.uv }}</div>
                   <div class="item"><span>PV:</span>{{item.pv}}</div>
                  
                </div>
            </el-card>
        </div>
        <el-pagination background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
            layout="total,sizes,prev, pager, next"    @size-change="handleSizeChange" @current-change="handlePageChange">
        </el-pagination>
    </div>

</template>


<script>
    export default {
        name: "statisticsVisits",
        data() {
            return {
                current: 1,
                pageSize: 10,
                total: 0,
                tableData: [],
            };
        },
        methods: {
            getInfo(item){
                console.log(item.behaviorId)
                   
       this.$router.push({
        path: `/statisticsVisitsDdetail`,
        query: {
            data: item.behaviorId
          }
       
      });
            },
            getlist() {
                this.$api
                    .statisticsVisitsList({
                        current: this.current,
                        size: 10
                    })
                    .then(res => {
                        console.log(res.data.data.records)
                        this.tableData = res.data.data.records
                        this.total = res.data.data.total
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },

            handlePageChange(val) {

                this.current = val;
                this.getlist();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getlist();
            },
        },
        created() {
            this.getlist()
        }
    };
</script>
<style lang="less" scoped>
    .statis_box {
        display: flex;
        flex-wrap: wrap;
       justify-content: flex-start;
        margin: 1rem 5rem;

        .box-card {
            width: 20rem;
            margin-top: 20px;
            margin-right:5rem;
            cursor: pointer;
            .item{
                color: rgb(236, 118, 22);
                span{
                    display: inline-block;
                    width: 70px;
                    text-align: right;
                    color: black;
                    margin-right: 10px;
                }
            }
        }
    }
      .el-pagination {
            margin: 1rem 5rem;
            float: right;
        }
</style>