<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">模板详情</span>
        <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail" class="addRoleButton">返回模板列表
        </el-button>
      </div>
      <!-- 流程节点展示 -->
      <div class="stepShow">
        <!-- <div id="wrapper">
          <div
            class="line-wrap"
            style="margin-left: 70px;"
            v-for="(item,index) in options"
            :key="index"
          >
            <div :id="item.id" class="state-item">{{item.nodeName}}</div>
          </div>
        </div>-->
        <!-- 步骤条 -->
        <div v-show="milepostWhether">
          <h3 style="margin-bottom:20px">请新建一个模板</h3>
          <el-button type="info" @click="newlyAdded()" class="downloadList" size="mini">
            <i class="el-icon-circle-plus-outline"></i>
          </el-button>
        </div>
        <el-steps :active="milepostActive" align-center>
          <el-step v-for="(value, key) in milepost" :class="milepostActive== key+1 ? stepActive: '' "
            :title="value.nodeName" :key="key" :description="value.value">
            <template slot="description">
              <p><span style="color:#666">默认处理人：</span> {{value.handlerUserName}}</p>
              <p><span style="color:#666">是否展示：</span> {{value.viewedEnum}}</p>

              <span><span
                  style="color:#666;display:inline-block;margin-bottom:5px">问题状态：</span>{{value.problemStatus}}</span>
              <br />
              <p v-if="value.rollbackNodeName"><span style="color:#666">回滚节点：</span> {{value.rollbackNodeName}}</p>
              <el-button type="primary" plain @click="newlyAdded(value)" circle class="downloadList" size="mini">
                <i class="el-icon-circle-plus-outline"></i>
              </el-button>
              <!-- <el-button
                type="danger"
                size="mini"
                style="margin-top:5px"
                plain

                round
              >删除</el-button> -->
              <el-button type="primary" icon="el-icon-edit" size="mini" circle @click="configEdit(value)"></el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini" circle plain
                @click="deleteProductD(milepost[key],milepost[key+1],milepost[key-1])"></el-button>
              <br />

            </template>
          </el-step>
        </el-steps>
      </div>
      <el-dialog title="新增模板流程" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item label="默认处理者" :validate-on-rule-change="false" :label-width="formLabelWidth"
            prop="handlerUserId">
            <el-select v-model="form.handlerUserId" filterable placeholder="请选择默认处理者" size="small">
              <el-option v-for="(item,index) in realNameList" :key="index" :label="item.realName"
                :value="item.sysUserId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="节点名称" :validate-on-rule-change="false" :label-width="formLabelWidth" prop="nodeId">
            <el-select v-model="form.nodeId" filterable placeholder="请选择节点名称" size="small">
              <el-option v-for="item in nodeNameList" :key="item.id" :label="item.nodeName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否显示" :validate-on-rule-change="false" :label-width="formLabelWidth" prop="isExternalDisplay ">
            <el-select v-model="form.isExternalDisplay" filterable placeholder="是否显示" size="small">
              <el-option v-for="item in isExternalDisplayList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('form')">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
        <span>确定删除模板吗,删除后对应的数据点信息也会被删除？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDelete">取 消</el-button>
          <el-button type="primary" @click="handleDelClose">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改 -->
      <el-dialog title="修改模板流程" :visible.sync="dialogVisibleEdit" :lock-scroll="false" :close-on-click-modal="false">
        <el-form :model="formEdit" :rules="rulesEdit" ref="formEdit">
          <el-form-item label="默认处理者" :validate-on-rule-change="false" :label-width="formLabelWidth"
            prop="handlerUserId">
            <el-select v-model="formEdit.handlerUserId" filterable placeholder="请选择默认处理者" size="small">
              <el-option v-for="(item,index) in realNameList" :key="index" :label="item.realName"
                :value="item.sysUserId"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否显示" :validate-on-rule-change="false" :label-width="formLabelWidth"
            prop="isExternalDisplay">
            <el-select v-model="formEdit.isExternalDisplay" @change="$forceUpdate()" filterable placeholder="是否显示"
              size="small">
              <el-option v-for="item in isExternalDisplayList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleEdit = false">取 消</el-button>
          <el-button type="primary" @click="editForm('formEdit')">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
        <span>确定删除模板吗,删除后对应的数据点信息也会被删除？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDelete">取 消</el-button>
          <el-button type="primary" @click="handleDelClose">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  export default {
    name: "PlaceDetail",
    data() {
      return {
        placeId: "",
        placeName: "",
        placeLocation: "",
        userName: "",
        userMobile: "",
        options: [], //详情循环数组
        linkList: [],
        relations: [],
        milepost: [],
        milepostWhether: false,
        //新增流程节点弹出对话框标记
        dialogVisible: false,
        dialogVisibleEdit: false,
        delDialogVisible: false,
        rules: {
          nodeId: [{
            required: true,
            message: "请输入节点名称",
            trigger: "blur"
          }],
          isExternalDisplay: [{
            required: true,
            message: "请选择是否显示",
            trigger: "change" 
          }],
          handlerUserId: [{
            required: false,
            message: "请输入",
            trigger: "blur"
          }],
          // rollbackTemplateNodeId: [
          //   { required: false, message: "请选择", trigger: "blur" }
          // ]
        },
        rulesEdit: {
          isExternalDisplay: [{
            required: false,
            message: "请选择是否显示",
            trigger: "change"
          }],
          handlerUserId: [{
            required: false,
            message: "请输入",
            trigger: "blur"
          }],
          // rollbackTemplateNodeId: [
          //   { required: false, message: "请选择", trigger: "blur" }
          // ]
        },
        form: {
          nodeId: "",
          handlerUserId: "",
          isExternalDisplay: "",
          // rollbackTemplateNodeId: ""
        },
        formEdit: {
          handlerUserId: "",
          isExternalDisplay: "",
        },
        formLabelWidth: "120px",
        // 默认步骤数
        milepostActive: 20,
        //默认处理者数组
        realNameList: [],
        viewedEnum: "",
        //节点名称数组
        nodeNameList: [],
        isExternalDisplayList: [{
          id: "1",
          name: "显示"
        }, {
          id: "0",
          name: "不显示"
        }],
        processTemplate: [], //回滚节点
        // 动态添加类名
        stepActive: "stepActive"
      };
    },
    created() {

      //  let  = this.$route.query.data.id
      sessionStorage.setItem("detailsId", this.$route.query.data.id);
    },
    mounted() {
      // this.getData();
      let detailsId = sessionStorage.getItem("detailsId");
      this.$api.moduleDetails(detailsId).then(res => {
        this.milepost = res.data.data;
        if (res.data.data.length > 0) {
          this.milepostWhether = false;
        } else {
          this.milepostWhether = true;
        }
      });
    },
    methods: {
      //  新增流程节点、
      newlyAdded(item) {
        // 有数据进行添加
        if (item) {
          this.form.handlerUserId = "";
          this.form.nodeId = "";
          this.form.isExternalDisplay = "";
          // this.form.rollbackTemplateNodeId = "";
          this.dialogVisible = true;
          this.form.templateId = this.$route.query.data.id;
          this.form.previousTemplateNodeId = item.id;
          // 默认处理者下拉框接口
          this.$api.moduleselectorList().then(res => {

            this.realNameList = res.data.data;
          });
          // 节点名称下拉框接口
          this.$api.nodeselectorListUrl().then(res => {

            this.nodeNameList = res.data.data;
          });

        } else {
          // 没数据进行第一条数据添加
          this.form.handlerUserId = "";
          this.form.nodeId = "";
          this.form.isExternalDisplay = "";
          this.dialogVisible = true;
          this.form.templateId = this.$route.query.data.id;
          this.form.previousTemplateNodeId = "";
          // 默认处理者下拉框接口
          this.$api.moduleselectorList().then(res => {

            this.realNameList = res.data.data;
          });
          // 节点名称下拉框接口
          this.$api.nodeselectorListUrl().then(res => {

            this.nodeNameList = res.data.data;
          });
        }
      },
      // 新增模板接口
      submitForm(formName) {
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.dialogVisible = false;
            this.$api.nodepreconfigAdd(this.form).then(res => {
              if (res.data.code == 200) {
                this.$message.success("模板配置成功!");
                // this.$refs.form.resetFields();

                let detailsId = sessionStorage.getItem("detailsId");
                this.$api.moduleDetails(detailsId).then(res => {

                  this.milepost = res.data.data;
                  if (res.data.data.length > 0) {
                    this.milepostWhether = false;
                  } else {
                    this.milepostWhether = true;
                  }
                });
              }
            });
          } else {
            return false;
          }
        });
      },
      // 修改流程
      configEdit(item) {

        console.log(item)
        this.formEdit.handlerUserId = item.handlerUserName;
        this.formEdit.isExternalDisplay = item.viewedEnum;
        this.viewedEnum = item.viewedEnum
        this.formEdit.id = item.id;
        this.dialogVisibleEdit = true
        // 默认处理者下拉框接口
        this.$api.moduleselectorList().then(res => {
          this.realNameList = res.data.data;
        });


      },
      // 修改提交接口
      editForm(formEdit) {
        this.$refs["formEdit"].validate(valid => {
          if (valid) {
            this.dialogVisibleEdit = false;
            if (this.formEdit.isExternalDisplay == this.viewedEnum) {
              console.log(this.viewedEnum)
              if (this.formEdit.isExternalDisplay == "显示") {
                this.formEdit.isExternalDisplay = 1
              } else {
                this.formEdit.isExternalDisplay = 0
              }

            }

            for (let index = 0; index < this.realNameList.length; index++) {
              if (this.formEdit.handlerUserId == this.realNameList[index].realName) {
                this.formEdit.handlerUserId = this.realNameList[index].sysUserId
              }

            }
            console.log(this.formEdit)
            this.$api.configEdit(this.formEdit).then(res => {
              if (res.data.code == 200) {
                this.$message.success("修改配置成功!");
                let detailsId = sessionStorage.getItem("detailsId");
                this.$api.moduleDetails(detailsId).then(res => {
                  this.milepost = res.data.data;
                  if (res.data.data.length > 0) {
                    this.milepostWhether = false;
                  } else {
                    this.milepostWhether = true;
                  }
                });
              }
            });
          } else {
            return false;
          }
        });
      },
      // 取消删除
      cancelDelete() {
        this.delDialogVisible = false;
      },
      // 删除模板节点
      deleteProductD(data, lastdata, predata) {
         this.$confirm('此操作将删除模板节点, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            let lastdataNew
        let predataaNew
        if (lastdata) {
          lastdataNew = lastdata.id
        } else(
          lastdataNew = ""
        )
        if (predata) {
          predataaNew = predata.id
        } else(
          predataaNew = ""
        )
        this.$api
          .nodeConfigDelete({
            id: data.id,
            lastTemplateNodeId: lastdataNew,
            previousTemplateNodeId: predataaNew,
          })
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("删除模板配置成功!");
              let detailsId = sessionStorage.getItem("detailsId");
              this.$api.moduleDetails(detailsId).then(res => {

                this.milepost = res.data.data;
                if (res.data.data.length > 0) {
                  this.milepostWhether = false;
                } else { 
                  this.milepostWhether = true;
                }
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
        
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      
      },
      handleDelClose() {
        this.delDialogVisible = false;
        this.deleteProduct(this.productId);
      },
  
      // getData() {
      //   this.$api.moduleDetails(1).then(res => {

      //     this.options = res.data.data;
      //     console.log(this.options);
      //     let pre = "";
      //     for (let index = 0; index < res.data.data.length; index++) {
      //       if (index == 0) {
      //         pre = res.data.data[index].id + "";
      //       } else {
      //         this.relations.push([
      //           [pre],
      //           [res.data.data[index].id + ""]
      //         ]);
      //         pre = res.data.data[index].id + "";
      //       }
      //     }

      //     this.$nextTick(() => {
      //       this.showData();
      //     });
      //   });
      // },
      //回到模块页面
      gobackPlaceDetail() {
        this.$router.push("/moduleList");
      }
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .item {
      height: 80px;
      width: 80px;
      border: 1px solid blue;
      float: left;
    }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }
    }

    .detail {
      padding: 0rem 3rem;
      margin-top: 1rem;

      .detail-span {
        font-size: 12 /16rem;
        color: #898989;
      }

      .detail-vv {
        margin-top: 5 /16rem;
        font-size: 14 /16rem;
      }
    }
  }

  /deep/.el-step.is-center .el-step__description {
    padding-left: 5%;
    padding-right: 5%;
  }

  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    border-radius: 4px;
  }

  .bg-purple-dark {
    background: #99a9bf;
  }

  //   .bg-purple {
  //     background: #d3dce6;
  //   }
  .bg-purple-light {
    background: #e5e9f2;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .stepShow {
    width: 90%;
    margin: 3rem auto;

    .downloadList {
      //  transform:translate(5.5rem, -4rem)
    }

    /deep/.el-step.is-horizontal.stepActive {
      .el-step__head.is-finish {
        .el-step__line {

          // 当前步骤数横线样式设置
          .el-step__line-inner {
            width: 50% !important;
            border-width: 1px !important;
          }
        }

        // 当前步骤数圆圈样式设置
        .el-step__icon.is-text {
          background: #409eff;
          color: #fff;
        }
      }
    }
  }

  #wrapper {
    background: radial-gradient(ellipse at top left,
        rgba(255, 255, 255, 1) 40%,
        rgba(229, 229, 229, 0.9) 100%);
    height: 100vh;
    padding: 60px 80px;
    width: 100vw;
    display: flex;
  }

  .state-item {
    width: 80px;
    height: 40px;
    color: #606266;
    background: #f6f6f6;
    border: 2px solid rgba(0, 0, 0, 0.05);
    text-align: center;
    line-height: 40px;
    font-family: sans-serif;
    border-radius: 4px;
    //   margin-right: 60px;
  }

  .line-wrap {
    //   display: flex;
    width: 100px;
    margin-bottom: 40px;
  }

  .outting .item {
    margin: 10px;
    height: auto;
    width: auto;
    cursor: pointer;
    border: none;
    float: none;
  }


  .right .el-tooltip__popper {
    padding: 8px 10px;
    cursor: pointer;
  }
</style>