<template>

    <div class="mainContainer">
        <div class="select">
            <span class="title">充值记录列表</span>
            <el-button type="text" size="small" icon="el-icon-download" class="select-button" @click="goodsExport">导出
            </el-button>
            <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新
            </el-button>
            <div class="select-input-right-input" style="margin-left:12px;">
                <el-autocomplete @focus='focusFn' :fetch-suggestions="querySearch" placeholder="请输入" v-model="imei" clearable
                    size="small" @keyup.13.native="search">
                    <el-select style="width:8rem" slot="prepend" v-model="searchValue" size="small" placeholder="请选择"
                        @change='searchList()'>
                        <el-option v-for="item in searchOption" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-autocomplete>
            </div>
            <el-upload style="display:inline" class="select-button" action :on-change="handleChange"
                :on-remove="handleRemove" :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList"
                :limit="limitUpload" :auto-upload="false" ref="my-upload">
                <el-button type="info" round size="mini">充值完成通知上传</el-button>
            </el-upload>
        </div>
        <div class="table-vv">
            <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
                <div class="result-info">
                    共有
                    <span>{{ total }}</span>个设备
                </div>
              <p style=" float: right;">
                    
                     <a href="./uplist.xls" download="上传模板"  class="down_template">下载充值完成通知上传模板</a>
              </p>
            </div>
            <el-table :data="tableData" stripe size="small" class="product-table"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>

                <el-table-column prop="imei" label="设备识别码" align="center"></el-table-column>
                <el-table-column prop="iccid" label="iccid" align="center"></el-table-column>
              
                <el-table-column prop="imsi" label="imsi" align="center"></el-table-column>
                <el-table-column prop="rechargeDuration" label="充值时长(年)" align="center"></el-table-column>
                <el-table-column prop="rechargeStatus" label="充值状态" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.rechargeStatus == 0?'待支付':scope.row.rechargeStatus == 1?'已失效':scope.row.rechargeStatus == 2?'付款成功(充值中)':'充值成功'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="simAttr" label="卡归属地" align="center"></el-table-column>
                <el-table-column prop="tradeNo" label="交易流水号" align="center"></el-table-column>
                  <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                <!-- <el-table-column fixed="right" label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <el-button @click="orderDetail(scope.row)" type="text" size="small" icon="el-icon-info">查看
                        </el-button>
                        <el-button @click="orderDelete(scope.row.id)" type="text" size="small" icon="el-icon-s-promotion">
                            删除
                        </el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <el-pagination :current-page.sync="currentPage" background :page-sizes="[10, 20]" :page-size="pageSize"
                :total="total" layout="sizes,prev, pager, next" @size-change="handleSizeChange"
                @current-change="handlePageChange">
            </el-pagination>




            <!-- 删除 -->
            <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
                <span>确定删除该设备吗,删除后对应的数据点信息也会被删除？</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click=" delDialogVisible=  false">取 消</el-button>
                    <el-button type="primary" @click="handleDelClose">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>


<script>
// import Bus from "../../../components/bus";
export default {
  name: "deviceManagementList",
  data() {
    return {
      options: [],
      searchOption: [
        {
          label: "设备识别码",
          value: "imei"
        },
        {
          label: "充值状态",
          value: "rechargeStatus"
        },
        {
          label: "交易流水号",
          value: "tradeNo"
        }
      ],
      username: "",
      clientId: "",
      searchValue: "", //搜索选择
      password: "",
      imei: "",
      value: [],
      fit: "fill",
      radio2: "1",
      orderNo: "",
      shipmentNum: "", //物流单号
      list: [],
      fileList: [],
      limitUpload: 1,
      loading: false,
      productDel: false,
      productAdd: false,
      delDialogVisible: false,
      orderType: "",
      tradeType: "",
      tradeNo: "",
      orderStatus: "",
      creator: "",
      currentPage: 1,
      dialogVisible: false,
      batchDialogVisible: false,
      orderStatusList: [
        {
          value: "0",
          label: "待支付"
        },
        {
          value: "1",
          label: "已取消"
        },
        {
          value: "2",
          label: "已失效"
        },
        {
          value: "3",
          label: "待发货"
        },
        {
          value: "4",
          label: "待收货"
        },
        {
          value: "5",
          label: "已完成"
        }
      ],
      visible: false,
      total: 0,
      tableData: [],
      restaurants: [],
      nameData: [],
      emileList: [
        {
          value: "1",
          label: "是"
        },
        {
          value: "0",
          label: "否"
        }
      ], //搜索下拉选择
      smsList: [
        {
          value: "1",
          label: "发送"
        },
        {
          value: "0",
          label: "不发送"
        }
      ], //是否发送短信
      chooseOnlineStatus: "", //搜索选择
      codeStatusList: [], //账号所属类型
      newSsUserlist: {
        value: "id",
        label: "typeName",
        children: "childs",
        expandTrigger: "hover"
        // emitPath: false
      },
      checked: false,
      //新增账号弹出对话框标记
      dialogVisible: false,
      //修改账号弹出对话框标记
      modifydialogVisible: false,
      //节点问题状态
      protocols: [],
      ids: [],
      //产品品类列表
      categories: [],
      input: "",
      current: 1,
      pageSize: 10,
      delDialogVisible: false,
      productId: "",
      indexId: "", //反显id
      nameinput: "", //搜索输入
      indexIdlist: "", //反显列表
      formLabelWidth: "120px",
      cachecurrent: "",
      idDel: ""
    };
  },
  methods: {
    searchList() {
      
      this.imei = ''
      console.log(this.searchValue,this.imei);
      if (this.searchValue == "rechargeStatus") {
        this.restaurants = this.loadAll();
        this.imei =
          this.imei == "待支付"
            ? "0"
            : this.imei == "已失效"
              ? "1"
              : this.imei == "支付成功(充值中)"
                ? "2"
                : this.imei == "充值成功"
                  ? "3"
                  : "";
      } else {
        this.restaurants = [];
      }
    },
    focusFn() {
      if (this.searchValue == "rechargeStatus") {
        this.restaurants = this.loadAll();
      } else {
        this.restaurants = [];
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      //判断是否包含
      return restaurant => {
        return restaurant.value.indexOf(queryString) > -1;
      };
    },

    loadAll() {
      return [
        // ：0 待支付，1 已失效，2 支付成功（充值中），3 充值成功 ,
        {
          id: "0",
          value: "待支付"
        },
        {
          id: "1",
          value: "已失效"
        },
        {
          id: "2",
          value: "支付成功(充值中)"
        },
        {
          id: "3",
          value: "充值成功"
        }
      ];
    },
    refresh() {
      this.nodeList();
    },
    handleChange(file, fileList) {
      this.fileTemp = file.raw;
      if (this.fileTemp) {
        this.importfxx(this.fileTemp);
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！"
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },
    //移除文件的操作方法
    handleRemove(file, fileList) {
      this.fileTemp = null;
      this.tableData = [];
    },
    importfxx(obj) {
      let _this = this;
      let inputDOM = this.$refs.inputer;
      // 通过DOM取文件数据
      this.file = event.currentTarget.files[0];
      var rABS = false; //是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();

      //if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function(f) {
        var binary = "";
        var rABS = false; //是否将文件读取为二进制字符串
        var pt = this;
        var wb; //读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function(e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          //此处引入，用于解析excelnpm
          var XLSX = require("xlsx");

          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              //手动转化
              type: "base64"
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary"
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
          //此处可对数据进行处理
          let arr = [];
          let arr2 = [];
          outdata.map(v => {
            let imei = v["设备识别码"];
            let tradeNo = v["交易流水号"];
            if (imei != "" || tradeNo != "") {
              arr.push({
                imei: imei,
                tradeNo: tradeNo
              });
            }
          });
          _this.nameData = arr;

          console.log(_this.nameData);
          _this.$api.cardCompleteNotify(_this.nameData).then(res => {
            if (res.data.code == 200) {
              _this.$message({
                type: "success",
                message: "导入成功，共导入" + arr.length + "完成设备"
              });
            }
          });
          return arr;
        };
        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    // 搜索清空
    emptyFn() {
      this.tradeNo = "";
      this.orderStatus = "";
      this.creator = "";
    },
    handlePageChange(val) {
      this.current = val;
      this.imei =
        this.imei == "待支付"
          ? "0"
          : this.imei == "已失效"
            ? "1"
            : this.imei == "支付成功(充值中)"
              ? "2"
              : this.imei == "充值成功"
                ? "3"
                : "";
      this.nodeList();
      if (this.searchValue == "rechargeStatus") {
        this.imei =
          this.imei == "0"
            ? "待支付"
            : this.imei == "1"
              ? "已失效"
              : this.imei == "2"
                ? "支付成功(充值中)"
                : this.imei == "3"
                  ? "充值成功"
                  : "";
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.imei =
        this.imei == "待支付"
          ? "0"
          : this.imei == "已失效"
            ? "1"
            : this.imei == "支付成功(充值中)"
              ? "2"
              : this.imei == "充值成功"
                ? "3"
                : "";
      this.nodeList();
      if (this.searchValue == "rechargeStatus") {
        this.imei =
          this.imei == "0"
            ? "待支付"
            : this.imei == "1"
              ? "已失效"
              : this.imei == "2"
                ? "支付成功(充值中)"
                : this.imei == "3"
                  ? "充值成功"
                  : "";
      }
    },

    search() {
      this.current = 1;
      if (this.searchValue == "rechargeStatus") {
        this.imei =
          this.imei == "待支付"
            ? "0"
            : this.imei == "已失效"
              ? "1"
              : this.imei == "支付成功(充值中)"
                ? "2"
                : this.imei == "充值成功"
                  ? "3"
                  : "";
        console.log(this.imei);
      }
      this.nodeList();
      if (this.searchValue == "rechargeStatus") {
        this.imei =
          this.imei == "0"
            ? "待支付"
            : this.imei == "1"
              ? "已失效"
              : this.imei == "2"
                ? "支付成功(充值中)"
                : this.imei == "3"
                  ? "充值成功"
                  : "";
      }
    },

    orderDelete(data) {
      this.delDialogVisible = true;
      this.idDel = data;
    },
    handleDelClose() {
      this.$api
        .mqttDeviceDelete(this.idDel)
        .then(res => {
          if (res.data.code == 200) {
            this.delDialogVisible = false;
            this.$message.success("删除产品成功!");
            this.nodeList();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    handleClose(done) {
      done();
      this.value = "";
    },
    changeMethod() {
      if (this.value == "") {
        console.log("11000");
        this.$api
          .GetDeliveryCodes({})
          .then(res => {
            console.log(res.data.data);
            this.options = res.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    remoteMethod(query) {
      console.log("000");
      if (query !== "") {
        console.log(query);
        this.loading = true;
        this.$api
          .GetDeliveryCodes({
            deliveryName: query
          })
          .then(res => {
            console.log(res.data.data);
            this.options = res.data.data;
          })
          .catch(error => {
            console.log(error);
          });
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter(item => {
            return item;
          });
        }, 200);
      } else {
      }
    },

    changeRadio() {
      console.log(this.radio2);
    },

    // 查看
    orderDetail(item) {
      this.dialogVisible = true;
      console.log(item);
      this.username = item.username;
      this.clientId = item.clientId;
      this.password = item.password;
    },

    // 导出
    goodsExport() {
        if (this.searchValue == "rechargeStatus") {
        this.imei =
          this.imei == "待支付"
            ? "0"
            : this.imei == "已失效"
              ? "1"
              : this.imei == "支付成功(充值中)"
                ? "2"
                : this.imei == "充值成功"
                  ? "3"
                  : "";
        console.log(this.imei);
      }
      this.$api
        .cardRechargeRecordExport({
          query: {
           [this.searchValue]: this.imei
          },
          properties: [
            "imei",
            "iccid",
            "createTime",
            "imsi",
            "rechargeDuration",
            "rechargeStatus",
            "simAttr",
            "tradeNo"
          ],
          titles: [
            "设备识别码",
            "iccid",
            "创建时间",
            "imsi",
            "充值时长(年)",
            "充值状态",
            "卡归属地",
            "交易流水号"
          ]
        })
        .then(res => {
          console.log(res.data);

          this.download(res.data);
            if (this.searchValue == "rechargeStatus") {
        this.imei =
          this.imei == "0"
            ? "待支付"
            : this.imei == "1"
              ? "已失效"
              : this.imei == "2"
                ? "支付成功(充值中)"
                : this.imei == "3"
                  ? "充值成功"
                  : "";
      }
        })
        .catch(error => {});
    },
    //导出函数
    download(data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/octet-binary"
        })
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "excel.xls");
      // link.setAttribute("download", this.goodName + "excel.xls");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },

    nodeList() {
      //查询设备列表
      this.$api
        .cardRechargeRecordList({
          page: {
            current: this.current,
            size: this.pageSize
          },
          query: {
            [this.searchValue]: this.imei
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
          this.currentPage = parseInt(this.current);
          // console.log(this.currentPage)
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    //查询产品列表
  },
  mounted() {
    this.nodeList();
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.message-text {
  font-family: MicrosoftYaHei;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: 0rem;
  color: #333333;
  width: 50%;
}

.fontblack {
  color: #999999;
}

.img2 {
  width: 0.81rem;
  height: 0.8rem;
  float: right;
}

.addressshow2 {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 75%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 1rem;
}

.addressshow1 {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 75%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 1rem;
}

.orderTitle {
  font-size: 1rem;
  color: #333333;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  height: 2.5rem;
}

.logistics {
  display: inline-block;
  min-width: 140px;
  text-align: right;
}

.dialog-footer {
  display: inline-block;
  margin: 2rem 0 0 30%;
}

.orderDetail {
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
}

.border-ceter {
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
}

.pay-button {
  width: 88%;
  height: 2.6rem;
  position: relative;
  background-color: red;
  color: white;

  margin-left: 6%;
}

ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem;
  
}

.el-table td,
.el-table th {
  padding: 0 !important;
}

.el-pagination {
  margin: 1rem 0;
  float: right;
}

.product-content {
  height: 6rem;

  .product-info-item {
    float: left;
    margin-right: 4rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 13rem;
    }
  }
}

.container-el-row {
  margin-top: 0px;
}

.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;

  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15px;
  }

  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}

.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
 

  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.item {
  margin: 4px;
  cursor: pointer;
}

.right .el-tooltip__popper {
  padding: 8px 10px;
  cursor: pointer;
}

/deep/.el-dialog {
  .dialog_title {
    border: rgb(189, 178, 178) 1px solid;
    padding: 3px;
    display: inline-block;
    width: 20%;
    text-align: right;
  }

  .dialog_value {
    border: rgb(189, 178, 178) 1px solid;
    padding: 3px;
    display: inline-block;
    width: 60%;
    text-align: left;
  }
}
 .down_template{
         float: right;
         color: #898989;
         font-size: 0.8rem;
     }
</style>