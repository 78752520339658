<template>
  <div>
    <el-container>
      <el-main class="row-margin" style="overflow-x:hidden;">

        <div class="select">
          <span class="title" style="margin-right: 20px">批量导入IMEI SN码</span>
          <el-upload style="display:inline" class="select-button" action="#" :on-change="handleChange"
                     :on-remove="handleRemove" :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList"
                     :limit="limitUpload" :auto-upload="false" ref="my-upload">
            <el-button type="text" size="small" icon="el-icon-folder-opened" class="select-button" >
              批量导入</el-button>
          </el-upload>
        </div>

      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {

  data() {
    return {
      span: 4,
      limitUpload: 1,
      tableData: [],
      fileList: [],
      dialogVisible: false,
      productId: "",
      netOperatorId: "",
      successMac: "",
      failedMac: "",
      options: [],
      errorList: [],
      successList: [],

    };
  },
  methods: {
    handleChange(file, fileList) {
      this.fileTemp = file.raw;
      if (this.fileTemp) {
        let formData = new FormData();
        formData.append("file", this.fileTemp);
        this.$api.importSN(formData).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "导入成功"
            });

          }
        });
        this.$refs['my-upload'].clearFiles()
        this.fileList = []
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！"
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },
    //移除文件的操作方法
    handleRemove(file, fileList) {
      this.fileTemp = null;
      this.tableData = [];
    },
    queryResult() {
      if (this.tableData.length == 0) {
        this.$message.error("请导入SN码!");
      } else {
        this.$api.importSN(this.tableData).then(res => {
          console.log(res.data);
          if(res.data.code == "200"){
            this.$message({
              type: "success",
              message: "导入成功，共导入" + this.tableData.length + "个SN码"
            });
          }

        });
      }
    },


  }
};
</script>

<style lang="less" scoped>
// .title {
//   font-size: 0.8rem;
//   margin: 2rem;
//   font-weight: bold;
// }
textarea {
  outline: none;
  border: 0px;
  background-color: #fafafa;
  font-size: 0.8rem;
}
.row-height {
  height: 4rem;
}
.row-margin {
  margin-bottom: 0.5rem;
}
.left-title {
  font-size: 0.8rem;
  line-height: 3rem;
  text-align: right;
  margin-right: 1rem;
}
.input-small-height {
  padding-left: 5px;
  height: 1.7rem;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 0.5rem;
}
.input-height {
  height: 12rem;
  margin-top: 0.6rem;
  width: 99%;
  border: 1px solid #d3d3d3;
  border-radius: 1rem;
}
.desc {
  line-height: 3rem;
  font-size: 0.6rem;
  color: gray;
}
.query-button {
  float: right;
  width: 6rem;
  height: 1.7rem;
  font-size: 0.7rem;
  min-height: 20px;
  min-width: 60px;
}
.input-button {
  width: 6rem;
  height: 1.7rem;
  font-size: 0.7rem;
  min-height: 20px;
  min-width: 60px;
}
.device-content {
  height: 6rem;

  .device-info-item {
    float: left;
    margin-right: 3rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 12rem;
    }
  }
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }
}
.content {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .content-span {
    display: inline-block;
    margin-right: 10%;
  }
  .content-vv {
    width: 100%;
    display: flex;
    margin-top: 1rem;
  }
}

</style>
