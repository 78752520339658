<template>
    <div class="Household">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click.native="backTo">
                    {{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"新闻资讯"}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color:#f28c02">
                        资讯详情
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="block">
            <div class="lunbotu" height="37.5rem">
                <!-- <el-carousel-item > -->
                <img src="../../../assets/xwzxbanner.jpg" class="image" />
                <!-- </el-carousel-item> -->
            </div>
        </div>
        <div class="major_service">
            <div class="item-title">
                <div class="text_subject">
                    <!-- <iframe id="framework" title="framework" width="100%" height="100%"
                     frameborder="0" scrolling="no"
                        marginheight="0" marginwidth="0" 
                        src="https://www.baidu.com/"></iframe> -->
                    <div v-html="outlink" class="texztaaa"></div>
                </div>

            </div>

            <div>

            </div>
        </div>


    </div>
</template>
<script>
    export default {
        name: "Household",
        components: {},
        data() {
            return {
                img: "",
                outlink: "" // 外部链接,
            };
        },
        //       watch:{
        // 	    "$route":{
        // 	         handler(val,from){
        // 	             if(val.query.i){  //获取路由信息,刷新页面动态路由刷新参数丢失,储存在本地
        // 	                    window.localStorage.setItem('query',JSON.stringify({query:this.$route.query,path:this.$route.path}))
        // 	             }
        // 	      },
        // 	      deep:true,
        // 	      immediate:true
        // 	    }
        //   },
        mounted() {
            window.addEventListener("scroll", this.scrollToTop);
            const that = this;
            let ispeed = Math.floor(-that.scrollTop / 5);
            document.documentElement.scrollTop = document.body.scrollTop =
                that.scrollTop + ispeed;
            this.newsList()
            //   this.img = this.$route.query.data
        },
        created() {
            // const {
            //     formUrl
            // } = this.$route.query.data;
            const {
                data
            } = this.$route.query;
            console.log(data);
            // this.outlink = data;
            // this.$set(this,'outlink',data)
            // this.getUrl(this.outlink)
            //  this.$set(this,'outlink',formUrl)
            // console.log(this.outlink, formUrl, this.$route.query.data);
        },
        methods: {
            scrollToTop() {
                const that = this;
                let scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                that.scrollTop = scrollTop;
                that.btnFlag = false;
            },
            //新闻资讯列表
            newsList() {

                this.$api
                    .newsList({
                        page: {
                            current: this.$route.query.current,
                            size: this.$route.query.size
                        }
                    })
                    .then(res => {
                        if (res.data.code == "200") {
                            //   console.log(res.data.data.records)
                                 console.log(res.data.data)
                              console.log(this.$route.query.data)
                            for (let index = 0; index < res.data.data.records.length; index++) {

                                if (this.$route.query.data == res.data.data.records[index].id) {
                                
                                    this.outlink = res.data.data.records[index].newsContent;
                                        console.log( this.outlink)
                                }

                            }


                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },

            backTo() {
                if (this.$route.query.crumbsTitle == "首页") {
                    let vm = this;
                    vm.$router.push({
                        path: `/`
                    });
                    //  this.menu_listones = 1;
                } else {
                    this.$router.replace({
                        path: "/newsInformation"
                    });
                }
            }
        },
        destroyed() {
            window.removeEventListener("scroll", this.scrollToTop);
        }
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1200px) {
        .Household {
            background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;
        }
    }

    @media screen and (min-width: 1201px) {
        .Household {
            background-color: #efefef;
            overflow-x: auto;
            min-width: 1300px;
        }
    }

    .block {
        width: 100%;
        height: 37.5rem;

        // background-color: red;
        .lunbotu {
            height: 100%;
            width: 100%;

            .image {
                width: 100%;
                height: 100%;
            }
        }
    }

    .breadcrumb {
        padding: 0rem 0 2rem 0;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;

        /deep/.el-breadcrumb {
            float: right;
            padding: 2rem 0;
        }

        /deep/.el-breadcrumb__inner:hover {
            color: #f28c02;
        }
    }

    .major_service {
        width: 100%;
        padding-bottom: 20px;
        background-color: #fff;

        .item-title {
            padding-top: 2rem;
            text-align: center;
            // color: #333333;
            width: 100%;
            margin: 0 auto;

            .image {
                width: 30%;
                display: block;
                margin: 0 auto;
            }

            p {
                text-shadow: 1px 1px 1px #666;
                display: inline-block;
                color: #333333;
                padding: 0 50px;
                font-size: 2.25em;
            }

            p:nth-child(2) {
                font-size: 1.8755em;
                color: #666666;
                margin-top: 1rem;
            }

            .text_list {
                display: inline-block;
                transform: translateY(-2rem);
                font-size: 1.875rem;
                color: #333333;
            }

            .level_subject {
                text-align: left;
                width: 60%;
                letter-spacing: 4px;
                margin: 0 auto;
                font-size: 1.875rem;
                color: #333333;
                font-weight: 600;
            }

            .text_subject {
                display: block;
                font-size: 1.5rem;

                width: 60%;
                padding-top: 2rem;
                margin: 0 auto;
                text-align: left;
                color: #333333;

                .texztaaa {
                    /deep/div:nth-child(3) {
                        margin-right: auto;
                        margin-left: auto;
                        outline: 0px;
                      
                        max-width: 860px !important;
                    }
                      margin-bottom: 7rem;
                }

                // padding-bottom: 4rem;
                img {
                    width: 100%;
                }


            }
        }
    }
</style>