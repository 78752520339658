<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除该账号吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDelete">取 消</el-button>
        <el-button type="primary" @click="handleDelClose">确 定</el-button>
      </span>
    </el-dialog>
    <div class="select">
      <span class="title">账号列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-refresh"
        class="select-button"
        @click="refresh"
      >刷新</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        class="select-button"
        @click="showAddProductDialog"
      >新增</el-button>
      <div class="select-input-right-input">
        <!-- <el-input placeholder="请输入" v-model="nameinput" size="small" @keyup.13.native="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>-->
        <el-input
          placeholder="请输入搜索关键字"
          v-model="nameinput"
          class="input-with-select"
          size="small"
          @keyup.13.native="search"
          @blur="ifMohuHandler($event)"
        >
          <el-select
            style="width:8rem"
            slot="prepend"
            v-model="chooseOnlineStatus"
            size="small"
            @change="upOptionType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in optionType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="search" size="small"></el-button>
        </el-input>
      </div>
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个账号
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="typeName" label="账号所属类型" align="center"></el-table-column>
        <el-table-column prop="realName" label="真实姓名" align="center"></el-table-column>
        <el-table-column prop="email" label="邮箱" align="center"></el-table-column>
        <el-table-column prop="sysUserName" label="系统账号" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              @click="productDetail(scope.row)"
              type="text"
              size="small"
              icon="el-icon-info"
            >修改</el-button>
            <el-button
              @click="deleteProductD(scope.row.id)"
              type="text"
              size="small"
              icon="el-icon-delete"
    
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20]"
        :page-size="pageSize"
        :total="total"
        layout="sizes,prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <el-dialog
      title="新增账号"
      :visible.sync="dialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="真实姓名"    :label-width="formLabelWidth" prop="realName">
          <el-input v-model="form.realName" style="width:14rem"  size="small" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
          <el-input v-model="form.email" size="small"  style="width:14rem" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="平台账号"
          :validate-on-rule-change="false"
          :label-width="formLabelWidth"
          prop="sysUserId"
        >
          <el-select v-model="form.sysUserId"  style="width:14rem" filterable placeholder="请选择平台账号" size="small">
            <el-option
              v-for="item in protocols"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            ></el-option>
          </el-select>
          
            <el-tooltip class="item" effect="dark" content="平台账号为系统登录账号" placement="right-start">
     <i class="el-icon-info" style="display:inline-black;margin-left:10px" ></i>
    </el-tooltip>
        </el-form-item>
        <el-form-item label="账号所属类型"  :label-width="formLabelWidth" prop="typeId">
          <!-- <el-select v-model="form.typeId" placeholder="请选择账号所属类型" size="small">
            <el-option
              v-for="item in categories"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>-->
          <el-cascader
            v-model="form.typeId"
             style="width:14rem"
            :props="newSsUserlist"
            placeholder="请选择账号所属类型"
            size="small"
            :options="sysUserlist"
            :show-all-levels="false"
          ></el-cascader>
              <el-tooltip class="item" effect="dark" content="账号所属类型可在账号类型页面进行新增" placement="right-start">
     <i class="el-icon-info" style="display:inline-black;margin-left:10px" ></i>
    </el-tooltip>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="
      修改账号"
      :visible.sync="modifydialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :getCheckedNodes="true"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="真实姓名" :label-width="formLabelWidth" prop="realName">
          <el-input v-model="form.realName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="平台账号" :label-width="formLabelWidth">
          <el-select
            v-model="form.sysUserId"
            disabled
            filterable
            placeholder="请选择平台账号"
            size="small"
          >
            <el-option
              v-for="item in protocols"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号所属类型" :label-width="formLabelWidth" prop="typeId">
          <!-- <el-select v-model="form.typeId" placeholder="请选择账号所属类型" size="small">
            <el-option
              v-for="item in categories"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>-->
          <el-cascader
            v-model="form.typeId"
            :props="newSsUserlist"
            placeholder="请选择账号所属类型"
            size="small"
            :options="sysUserlist"
            :show-all-levels="false"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifydialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFormUpdate('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import Bus from "../../../components/bus";
export default {
  name: "accountList",
  data() {
    return {
      productDel: false,
      productAdd: false,
      form: {
        realName: "",
        sysUserId: "",
        typeId: "",
        email: ""
      },
      visible: false,
      total: 0,
      tableData: [],
      optionType: [
        {
          value: "typeName",
          label: "账号所属类型"
        },
        {
          value: "realName",
          label: "真实姓名"
        },
        {
          value: "sysUserName",
          label: "系统账号"
        },
        
      ], //搜索下拉选择
      chooseOnlineStatus: "真实姓名", //搜索选择
      sysUserlist: [], //账号所属类型
      newSsUserlist: {
        value: "id",
        label: "typeName",
        children: "childs",
        expandTrigger: "hover"
        // emitPath: false
      },
      checked: false,
      //新增账号弹出对话框标记
      dialogVisible: false,
      //修改账号弹出对话框标记
      modifydialogVisible: false,
      //协议类型列表
      protocols: [
        {
          id: 1,
          protocolName: "test"
        }
      ],

      //产品品类列表
      categories: [],

      value: "",
      input: "",
      current: 1,
      pageSize: 10,
      delDialogVisible: false,
      productId: "",
      indexId: "", //反显id
      nameinput: "", //搜索输入
      indexIdlist: "", //反显列表
      formLabelWidth: "120px",
      rules: {
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
          { min: 1, max: 10, message: "长度在 1 到 10 个字符", trigger: "blur" }
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
        sysUserId: [
          { required: true, message: "请选择平台账号", trigger: "change" }
        ],

        typeId: [
          { required: true, message: "请选择所属类型", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    refresh() {
      this.accountList();
    },
    ifMohuHandler(e) {
      this.ifMohu = false;
      this.searchData = [];
    },
    // changex清空
    upOptionType(){
       this.nameinput = ""
    },
    // 根据不同类型搜索
    search() {
      this.current = 1;
      if(this.chooseOnlineStatus == "真实姓名"){
this.chooseOnlineStatus =  "realName"
      }
      let query = { [this.chooseOnlineStatus]: this.nameinput };

      //查询设备列表
      this.$api
        .accountList({
          page: {
            current: this.current,
            size: this.pageSize
          },
          query: query
        })
        .then(res => {
          this.tableData = res.data.data.records;

          this.total = res.data.data.total;
          let cloudList = JSON.parse(sessionStorage.getItem("cloudList"));
          for (let i = 0; i < cloudList.length; i++) {
            if (
              this.$store.state.localList.localList.productDel == cloudList[i]
            ) {
              this.productDel = true;
            }
            if (
              this.$store.state.localList.localList.productAdd == cloudList[i]
            ) {
              this.productAdd = true;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    handlePageChange(val) {

      this.current = val;
      this.accountList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.accountList();
    },
    //修改账号
    productDetail(data) {
      this.form = {};
      this.indexId = [];
      sessionStorage.setItem("id", data);
       this.showaccountListSelector();
      for (let i = 0; i < this.sysUserlist.length; i++) {
        if (data.typeName == this.sysUserlist[i].typeName) {
          this.indexId = this.sysUserlist[i].id;
          this.indexIdlist = this.sysUserlist;
        } else if (this.sysUserlist[i].childs) {
          for (let j = 0; j < this.sysUserlist[i].childs.length; j++) {
            if (data.typeName == this.sysUserlist[i].childs[j].typeName) {
              this.indexId = [
                this.sysUserlist[i].id,
                this.sysUserlist[i].childs[j].id
              ];
              this.indexIdlist = this.sysUserlist[i].childs;
              // this.form.typeId = this.changeDetSelect(Number(this.indexId),this.sysUserlist[i].childs); //数据双向绑定
            } else {
              //  this.indexId = this.sysUserlist[i].id;
            }
          }
        }
        // console.log(this.sysUserlist[i].childs);
      }
      this.form = {
        realName: data.realName,
        sysUserId: data.sysUserName,
        id: data.id,
        email: data.email
      };
      this.form.typeId = this.indexId; //如果是第二层择需要父级id

      // this.form.typeId = this.changeDetSelect(Number(this.indexId),this.indexIdlist); //数据双向绑定

      this.modifydialogVisible = true;
  
    },
    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].typeName) {
              depth++;
              childrenEach(childrenData[j].typeName, depth);
            }
          }
        }
      
        return returnArr;
      }
      return childrenEach(treeData, depth);
    },

    deleteProductD(data) {
      this.delDialogVisible = true;
      this.productId = data;
    },
    cancelDelete() {
      this.delDialogVisible = false;
      this.productId = "";
    },
    handleDelClose() {
      this.delDialogVisible = false;
      this.deleteProduct(this.productId);
    },
    deleteProduct(data) {
      // console.log("删除产品" + data);
      this.$api
        .accountTypeDeleteU(data)
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("删除账号成功!");
            this.accountList();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //新增产品
    addProduct() {
      this.dialogVisible = false;

      this.$api
        .addProduct(this.product)
        .then(res => {
          this.form.realName = "";
          this.form.email = "";
          this.form.sysUserId = "";
          this.form.typeId = "";
          //查询产品列表
          this.accountList();
        })
        .catch(error => {
          console.log(error);
        });
    },
    accountList() {
      //查询设备列表
      this.$api
        .accountList({
          page: {
            current: this.current,
            size: this.pageSize
          }
          // query:{
          //   realName:"",
          //   sysUserName:"",
          //   typeName:""
          // }
        })
        .then(res => {
          this.tableData = res.data.data.records;
    
          this.total = res.data.data.total;
          let cloudList = JSON.parse(sessionStorage.getItem("cloudList"));
          for (let i = 0; i < cloudList.length; i++) {
            if (
              this.$store.state.localList.localList.productDel == cloudList[i]
            ) {
              this.productDel = true;
            }
            if (
              this.$store.state.localList.localList.productAdd == cloudList[i]
            ) {
              this.productAdd = true;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    showAddProductDialog() {
        this.showaccountListSelector();
      this.dialogVisible = true;
      this.form.realName = "";
      this.form.email = "";
      this.form.sysUserId = "";
      this.form.typeId = "";
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    // 新增账号接口
    submitForm(formName) {
      this.$refs["form"].validate(valid => {
        if (this.form.typeId.length > 1) {
          this.form.typeId = Number(this.form.typeId.pop());
        } else {
          this.form.typeId = Number(this.form.typeId);
        }
        if (valid) {
          this.dialogVisible = false;
          this.$api.accountListAdd(this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success("添加账号成功!");
              this.accountList();
              this.showaccountListSelector();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 修改账号接口
    submitFormUpdate(formName) {
  
      this.$refs["form"].validate(valid => {
        if (this.form.typeId.length > 1) {
          this.form.typeId = Number(this.form.typeId.pop());
        } else {
          this.form.typeId = Number(this.form.typeId);
        }
        if (valid) {
          this.modifydialogVisible = false;
          // this.form.id  =
          this.$api.accountListUpdate(this.form).then(res => {
            if (res.data.code == 200) {
              this.$message.success("修改账号成功!");
              this.accountList();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 账号列表新增平台账号下拉框接口
    showaccountListSelector() {
      this.$api
        .accountListSelector()
        .then(res => {
   
          this.protocols = res.data.data;
        })
        .catch(error => {
          console.log(error);
        });
      // 账号所属类型下拉框包括二级
      this.$api
        .accountType()
        .then(res => {
    
          this.sysUserlist = res.data.data;
          // for (let index = 0; index < res.data.data.length; index++) {

          //   this.sysUserlist.push({
          //        value: res.data.data[index].id,
          //        label: res.data.data[index].typeName,
          //        children:res.data.data[index].childs,
          //   })
          // }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    //查询产品列表
    this.accountList();
  
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.product-content {
  height: 6rem;

  .product-info-item {
    float: left;
    margin-right: 4rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 13rem;
    }
  }
}
.container-el-row {
  margin-top: 0px;
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15px;
  }
  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

    .item {
      margin: 4px;
      cursor: pointer;
    }

  
    .right .el-tooltip__popper {
      padding: 8px 10px;
         cursor: pointer;
    }
</style>
