import { render, staticRenderFns } from "./FaultList.vue?vue&type=template&id=c0537c02&scoped=true&"
import script from "./FaultList.vue?vue&type=script&lang=js&"
export * from "./FaultList.vue?vue&type=script&lang=js&"
import style0 from "./FaultList.vue?vue&type=style&index=0&id=c0537c02&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0537c02",
  null
  
)

export default component.exports