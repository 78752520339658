<template>
  <div class="outting">
    <div class="select">
      <span class="title">用户奖品管理详情</span>
      <el-button type="text" size="small" icon="el-icon-back" @click="gobackOrderListl" class="addRoleButton">返回用户奖品列表
      </el-button>
    </div>
    <el-card class="box-card">
      <div class="text_item" v-for="(item,index) in detailList" :key="index">

       
        <span class="item-span"> 
          联系人姓名  :<b class="contentlist"> {{item.contactName  }} </b>
        </span>
        <span class="item-span">
          联系人电话  :<b class="contentlist"> {{item.contactPhone    }} </b>
        </span>
          <span class="item-span">
          地址  :<b class="contentlist"> {{item.address  }} </b>
        </span>
         <span class="item-span">
          活动编码 : <b class="contentlist"> {{item.activityCode  }} </b>
        </span>
        <span class="item-span">
          活动名称  :<b class="contentlist"> {{item.activityName  }} </b>
        </span>
      
   
              <span class="item-span">
         奖品名称 :<b class="contentlist">{{item.prizeName   }} </b>
        </span>
            <span class="item-span" >
          优惠券折扣类型 :<b class="contentlist">{{item.couponDiscountType  == '0'?'无门槛':item.couponDiscountType  == '1'?"满减":'暂无' }} </b>
        </span>
         <span class="item-span">
          优惠券减少金额  :<b class="contentlist">{{item.couponMinusPrice}} </b>
        </span>
       <span  class="item-span">
          
          优惠券满足金额  :<b class="contentlist">{{item.couponFullPrice   ||"暂无"   }} </b>
        </span>
           <span class="item-span">
        奖品类型 :<b class="contentlist">{{item.prizeType  == '0'?'实物':item.prizeType == '1'?"补签卡":'优惠券' }} </b>
        </span>
      
       
      
           <span class="item-span">
           创建时间   :<b class="contentlist">{{item.createTime  }} </b>
        </span>
      
       
          <span class="item-span">
        奖品状态 :<b class="contentlist">{{item.prizeStatus  == '0'?'未使用':item.prizeStatus == '1'?"已使用":'已失效' }} </b>
        </span>
         <span class="item-span">
       奖品有效期  :<b class="contentlist">{{item.prizeValidity  ||"暂无" }} </b>
        </span>
        <span class="item-span">
       使用日期  :<b class="contentlist">{{item.useTime  ||"暂无" }} </b>
        </span>
       
           <span  class="item-span">
          失效日期    :<b class="contentlist">{{item.invalidTime  }} </b>
        </span >
         <span style="vertical-align: middle;" class="item-span">
       奖品图片地址 :<b class="contentlist"> 
           <el-image
      style="width: 100px; height: 100px;vertical-align: middle;"
      :src="item.prizePicture "
      fit="fill"></el-image></b>
        </span>
      </div>
    </el-card>
 
   
  </div>
</template>
<script>
  export default {
    name: "userPrizeDetail",
    data() {
      return {
        tableData: [],
        total: 0,
        options: [],
        value: [],
        tradeNo: "",
        shipmentNum: "", //物流单号
        loading: false,
        disabledNo: true,
        detailList: [],
        orderToGoodsShowVos: [],
        fit: "fill",

        logisticsList: [],
        dialogVisible: false,
        logDialogVisible: false,
      };
    },
    methods: {

      changeCurrent() {},
      gobackOrderListl() {
        this.$router.push({
          path: `/prizeManagement`,
        });
      },
  
    },
    created() {

    },
    mounted() {
      // console.log(this.$route.query.data);
      this.$api
        .userPrizeDetail(this.$route.query.data)
        .then(res => {
          console.log(res.data.data)
          this.detailList = [res.data.data]
          console.log(this.detailList)
          this.tradeNo = res.data.data.tradeNo
          this.orderToGoodsShowVos = res.data.data.orderToGoodsShowVos
        })
        .catch(error => {
          console.log(error);
        });

    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .contentlist {
      color: rgb(68, 176, 248);
      padding: 0 0.4rem;
    }
     .logistics {
    display: inline-block;
    min-width: 140px;
    text-align: right
  }
    .dialog-footer {
    display: inline-block;
    margin: 2rem 0 0 30%;

  }

    .track-rcol {}

    .track-list {
      position: relative;
    }

    .track-list li {
      position: relative;
      padding: 0px 0 1.5rem 25px;
      line-height: 1rem;
      border-left: 1px solid #d9d9d9;
      color: #999;
    }

    .track-list li.first {
      color: red;
      padding-top: 0;
      width: 100%;
      text-align: left;
      border-left: 1px solid #d9d9d9;
    }

    .track-list li .node-icon {
      position: absolute;
      left: -5.5px;
      border-radius: 0.25rem;
      width: 0.5rem;
      height: 0.5rem;
      top: 4px;
      background-color: #999999;
    }

    .track-list li.first .node-icon {
      background-position: 0-72px;
      background-color: red;
      width: 1rem;
      z-index: 2;
      height: 1rem;
      position: absolute;
      left: -9px;
      top: 0;
      border-radius: 0.5rem;
    }

    .track-list li .time {
      margin-right: 20px;
      position: relative;
      top: 4px;
      display: inline-block;
      vertical-align: middle;
      background-color: white;
      color: #999;
      width: 100%;
      text-align: left;
    }

    .track-list li .txt {
      position: relative;
      display: inline-block;
      vertical-align: top;
      color: #999;
    }

    .track-list li.first .time {
      text-align: left;
      width: 94%;
      color: red;
    }

    .track-list li.first .txt {
      color: red;
      text-align: left;
      width: 94%;
    }

    .track-list li.finall {
      position: relative;
      padding: 0px 0 1.5rem 25px;
      line-height: 18px;
      border-color: white;
      border-left: 1px solid #ffffff;
      color: #999;
    }

    .track-list li.finall .div-spilander {
      width: 1px;
      position: absolute;
      position: absolute;
      left: -1.5px;
      height: 0.5rem;
      background-color: #d9d9d9;
    }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .select-status {
        width: 9rem;
        margin-left: 1.7rem;
        margin-top: 7px;
      }

      .select-input-timer {
        float: right;
      }

      .select-status {
        float: right;
      }

      .select-input-right-input {
        float: right;
        // width: 8rem;
        line-height: 46px;
      }

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }

    }

    .text {
      font-size: 0.85rem;
    }

    .el-icon-edit,
    .el-icon-check {
      margin: 0 20px;
      cursor: pointer;
    }

    .item {
      padding: 18px 0;
    }

    .box-card {
      margin: 1rem 3rem;

      span {
        padding: 1rem 1rem;
        display: inline-block;
        // border: 1px black solid;
        min-width: 14rem;
      }
    }

    .guzhangTable {
      padding: 0rem 3rem;
      margin-top: 1rem;

    }
  }
  .text_item{
       font-size: 0.85rem;
       display: flex;
         flex-wrap: wrap;
         align-items: center;
        //  justify-content: space-between;
         .item-span{
             width: 19%;
         }
  }
</style>