import Vue from 'vue'
import VueRouter from 'vue-router'
import WenSheng from '../views/wensheng/WenSheng.vue'
import About from '../views/platform/PlatForm.vue'
import MiddleWare from '../views/middleware/MiddleWare.vue'
import Login from '../views/login/Login.vue'
import Backstage from '../views/backstage.vue'
import MainRouter from '../views/mainRouter.vue'
import BigScreen from '../views/bigDataResearchScreen/bigDataResearchScreen.vue'
import First from '../views/first/First.vue'
import Detail from '../views/first/Detail.vue'
import RoleManagement from '../views/wensheng/RoleManagement/RoleManagement.vue'
import RoleAdd from '../views/wensheng/RoleManagement/RoleAdd.vue'
import SystemSetting from '../views/systemSetting/systemSetting.vue'
import SysUser from '../views/systemSetting/sysUser/sysUser.vue'
import SysPermission from '../views/systemSetting/sysPermission/sysPermission.vue'
import SysRole from '../views/systemSetting/sysRole/sysRole.vue'
import AddRole from '../views/systemSetting/sysRole/addRole.vue'
import EditRole from '../views/systemSetting/sysRole/editRole.vue'
import DeviceList from '../views/backend/device/DeviceList.vue'
import ProductList from '../views/backend/product/ProductList.vue'
import ProductCategoryList from '../views/backend/product/ProductCategoryList.vue'
import SysUserDetail from '../views/systemSetting/sysUser/sysUserDetail.vue'
import DeviceDetail from '../views/backend/device/DeviceDetail.vue'
import DeviceInfo from '../views/backend/device/DeviceInfo.vue'
import DeviceCommand from '../views/backend/device/DeviceCommand.vue'
import DeviceRealtimeData from '../views/backend/device/DeviceRealtimeData.vue'
import DeviceHistoryData from '../views/backend/device/DeviceHistoryData.vue'
import AgreeMent from '../views/backend/agreeMent/agreeMent.vue'
import SysUserAdd from '../views/systemSetting/sysUser/sysUserAdd.vue'
import MySelf from '../views/login/mySelf.vue'
import DeviceRegisterList from '../views/middleware/device/deviceRegisterList.vue'
import FireAlarmList from '../views/backend/alarmManage/FireAlarmList.vue'
import FaultList from '../views/backend/alarmManage/FaultList.vue'
import LotRegistDevice from '../views/middleware/device/lotRegistDevice'
import LotDeleteDevice from '../views/middleware/device/lotDeleteDevice'
import productDetail from '../views/backend/product/ProductDetail'
import ManyDispatch from '../views/backend/device/manyDispatch.vue'
import ManyUnbundling from '../views/backend/device/manyUnbundling.vue'
import CleanDeviceInfo from '../views/backend/device/CleanDeviceInfo.vue'
import PlaceList from '../views/backend/appBackstage/placeList.vue'
import RoomList from '../views/backend/appBackstage/roomList.vue'
import PlaceDetail from '../views/backend/appBackstage/placeDetail.vue'
import UserList from '../views/backend/appBackstage/userList.vue'
import FireDetail from '../views/backend/alarmManage/fireDetail'
import FaultDetail from '../views/backend/alarmManage/faultDetail'
import FaultType from '../views/backend/management/faultType.vue'
import ManagementRecords from '../views/backend/device/managementRecords.vue'
import DeviceRepair from '../views/backend/management/deviceRepair.vue'
import AppLog from "../views/log/appLog/appLog.vue"
import WebLog from "../views/log/webLog/webLog.vue"
import HomeIn from "../views/backend/homeManagement/homeIn.vue"
import AddAdvertisement from "../views/backend/homeManagement/addAdvertisement.vue"
import AddEdit from "../views/backend/homeManagement/addEdit.vue"
import SysFloorPlate from "../views/backend/homeManagement/sysFloorPlate.vue"
import PlateList from "../views/backend/homeManagement/plateList.vue"
import AddPlate from "../views/backend/homeManagement/addPlate.vue"
import EditPlate from "../views/backend/homeManagement/editPlate.vue"
import SystemCode from "../views/testSystem/systemCode.vue"
import TestDevice from '../views/testSystem/testDevice.vue'
import TestResult from '../views/testSystem/testResult.vue'
import TestDeviceJy from '../views/testSystem/testDeviceJy.vue'
import TestResultDetail from '../views/testSystem/testResultDetail.vue'
import QueryDeviceResult from "../views/testSystem/queryDeviceResult.vue"
import AppList from '../views/backend/appEdition/appList.vue'
import VideoTutorial from "../views/first/VideoTutorial";
import VideoTutorialDetail from "../views/first/VedioTutorialDetail"
import AccountList from "../views/problemManagement/accountManagement/accountList"
import AccountType from "../views/problemManagement/accountManagement/accountType"
import NodeList from "../views/problemManagement/nodeMoudel/nodeList"
import QuestionStatus from "../views/problemManagement/questionManagement/questionStatus"
import ModuleList from "../views/problemManagement/nodeMoudel/moduleList"
import ModuleDetails from "../views/problemManagement/nodeMoudel/moduleDetails"
import QuestionList from "../views/problemManagement/questionManagement/questionList"
import QuestionDetails from "../views/problemManagement/questionManagement/questionDetails"
import QuestionHandle from "../views/problemManagement/questionManagement/questionHandle"
import QuestionAdd from "../views/problemManagement/questionManagement/questionAdd"
import QuestionType from "../views/problemManagement/questionManagement/questionType"
import FireStatistics from "../views/backend/alarmManage/fireStatistics"
import FireStatisticsDetails from "../views/backend/alarmManage/fireStatisticsDetails"
import ProductCenter from "../views/first/productCenter"
import AboutUs from "../views/first/aboutUs"
import Solution from "../views/first/solution"
import NewsInformation from "../views/first/newsInformation"
import MerchantsToJoin from "../views/first/merchantsToJoin"
import FireAlarm from "../views/first/productDetails/fireAlarm"
import FireAlarmNn from "../views/first/productDetails/fireAlarmNn"
import Methane from "../views/first/productDetails/methane"
import MethaneNn from "../views/first/productDetails/methaneNn"
import CarbonMonoxide from "../views/first/productDetails/carbonMonoxide"
import CarbonMonoxideNn from "../views/first/productDetails/carbonMonoxideNn"
import Smoke from "../views/first/productDetails/smoke"
import SmokeLl from "../views/first/productDetails/smokeLl"
import SmokeWw from "../views/first/productDetails/smokeWw"
import Jylh from "../views/first/productDetails/jylh"
import Flashlight from "../views/first/productDetails/flashlight"
import FireBlanket from "../views/first/productDetails/fireBlanket"
import DynamicPage from "../views/first/productDetails/dynamicPage" //动态产品页面
import FireExtinguisher from "../views/first/productDetails/fireExtinguisher"
import Household from "../views/first/newsDetails/Household"
import MobileCloud from "../views/first/newsDetails/MobileCloud"
import FireProducts from "../views/first/newsDetails/FireProducts"
import Prosperity from "../views/first/newsDetails/Prosperity"
import News from "../views/backend/homeManagement/news.vue"
import NewAdd from "../views/backend/homeManagement/newAdd.vue"
import NewModify from "../views/backend/homeManagement/newModify.vue"
import LotRegistQuery from '../views/middleware/device/lotRegistQuery'
import EquipmentQuery from "../views/testSystem/equipmentQuery.vue"
import Upgrade from "../views/middleware/upgradeManagement/upgrade"
import UpgradeList from "../views/middleware/upgradeManagement/upgradeList"
import UpgradeGroup from "../views/middleware/upgradeManagement/upgradeGroup"
import MyWorkorder from "../views/workOrder/myWorkorder"
import SubmitWorkorder from "../views/workOrder/submitWorkorder"
import WorkOrderDetails from "../views/workOrder/workOrderDetails"
import WoekOrderHandle from "../views/workOrder/woekOrderHandle"
import ThirdPartyApplications from "../views/systemSetting/thirdPartyApplications/thirdPartyApplications"
import CardManagement from "../views/systemSetting/cardManagement/cardManagement"
import PlanConfiguration from "../views/backend/homeManagement/planConfiguration"
import PlanModify from "../views/backend/homeManagement/planModify"
import PlanAdd from "../views/backend/homeManagement/planAdd"
import MallList from "../views/appletsMall/mallList"
import OrderList from "../views/appletsMall/orderList"
import AddGood from "../views/appletsMall/addGood"
import GoodsUpdate from "../views/appletsMall/goodsUpdate"
import ExchangeList from "../views/appletsMall/exchangeList"
import RedemptionCode from "../views/appletsMall/redemptionCode"
import OrderDetail from "../views/appletsMall/orderDetail"
import ExchangeDetail from "../views/appletsMall/exchangeDetail"
import BatchCodes from "../views/appletsMall/batchCodes"
import AppStatistics from "../views/appletsMall/statistics"
import ProductCarousel from "../views/appletsMall/productCarousel"
import AddCarousel from "../views/appletsMall/addCarousel"
import EditCarousel from "../views/appletsMall/editCarousel"
import StatisticsOverview from "../views/backend/alarmManage/statisticsOverview"
import Heatmap from "../views/backend/alarmManage/heatmap"
import WechatLoginShow from "../views/backend/agreeMent/wechatLoginShow"
import NewsletterStatistics from "../views/backend/alarmManage/newsletterStatistics"
import SignalStatistics from "../views/backend/alarmManage/signalStatistics"
import WifiDeviceRegisterList from '../views/middleware/wifiDevice/wifiDeviceRegisterList.vue'
import WifiLotDeleteDevice from '../views/middleware/wifiDevice/wifiLotDeleteDevice.vue'
import WifiLotUpdateDevice from '../views/middleware/wifiDevice/wifiLotUpdateDevice'
import WifiLotRegistDevice from '../views/middleware/wifiDevice/wifiLotRegistDevice.vue'
import WifiLotRegistQuery from '../views/middleware/wifiDevice/wifiLotRegistQuery.vue'
import AdvertiseCarousel from '../views/appletsMall/advertiseCarousel'
import EdiAdvertise from '../views/appletsMall/ediAdvertise'
import AddAdvertise from '../views/appletsMall/addAdvertise'
import AppNews from "../views/backend/homeManagement/appNews.vue"
import AddAppNews from "../views/backend/homeManagement/addAppNews.vue"
import AppNewsModify from "../views/backend/homeManagement/appNewsModify.vue"
import AppPlan from "../views/backend/homeManagement/appPlan.vue"
import AppAddPlan from "../views/backend/homeManagement/appAddPlan.vue"
import AppPlanModily from "../views/backend/homeManagement/appPlanModily.vue"
import DeviceManagementList from "../views/middleware/DevicManagement/deviceManagementList.vue"
import AddDeviceMqtt from "../views/middleware/DevicManagement/addDeviceMqtt.vue"
import WifiSystemCode from "../views/testSystem/wifiSystemCode.vue"
import ForgetPage from '../views/login/forgetPage.vue'
import CardExpiration from "../views/systemSetting/cardManagement/cardExpiration"
import CardRechargeRecord from "../views/systemSetting/cardManagement/cardRechargeRecord"
import CardRechargeRules from "../views/systemSetting/cardManagement/cardRechargeRules"
import FileManagement from "../views/backend/fileManagement/fileManagement.vue"
import FileManagementWeb from "../views/backend/fileManagement/fileManagementWeb.vue"
import VersionUpTipList from "../views/backend/versionUpTipList/versionUpTipList.vue"
import ThirdPlatformAppLimitDataList from "../views/systemSetting/thirdPartyApplications/thirdPlatformAppLimitDataList"
import AnswerList from "../views/appletsMall/integral_system/answerList"
import ShopList from "../views/appletsMall/shopList"
import EvaluationList from "../views/appletsMall/evaluation/evaluation"
import ActivityList from "../views/appletsMall/activity_prizes/activityList"
import PrizeList from "../views/appletsMall/activity_prizes/prizeList"
import ActivityPrizes from "../views/appletsMall/activity_prizes/activityPrizes"
import PrizeManagement from "../views/appletsMall/activity_prizes/prizeManagement"
import UserPrizeDetail from "../views/appletsMall/activity_prizes/userPrizeDetail"
import HelpCenterType from "../views/systemSetting/helpCenter/helpCenterType"
import HelpCenter from "../views/systemSetting/helpCenter/helpCenter"
import AddHelpCenter from "../views/systemSetting/helpCenter/addHelpCenter"
import UpdataHelpCenter from "../views/systemSetting/helpCenter/updataHelpCenter"
import StatisticsBehavior from "../views/systemSetting/userBehavior/statisticsBehavior"
import StatisticsVisits from "../views/systemSetting/userBehavior/statisticsVisits"
import StatisticsVisitsDdetail from "../views/systemSetting/userBehavior/statisticsVisitsDdetail"
import WifiTestResult from "../views/testSystem/wifiTestResult"
import WifiTestResultInfo from "../views/testSystem/wifiTestResultInfo"
import StatisticsSmsList from "../views/systemSetting/userBehavior/statisticsSmsList"
import PointsAdd from "../views/appletsMall/points_add/pointsAdd"
import Share from "../views/appletsMall/activity_prizes/shareConfigList"
import ProgramVersion from "../views/backend/programVersion/programVersion"
import ProductPointList from "../views/systemSetting/userBehavior/productPointList"
import ProductConfiguration from "../views/backend/homeManagement/productConfiguration"
import AddProductConfiguration from "../views/backend/homeManagement/addProductConfiguration"
import EditProductConfiguration from "../views/backend/homeManagement/editProductConfiguration"
import AppProductConfiguration from "../views/backend/homeManagement/appProductConfiguration"
import AppAddProductConfiguration from "../views/backend/homeManagement/appAddProductConfiguration"
import AppEditProductConfiguration from "../views/backend/homeManagement/appEditProductConfiguration"
import GoodsTypeManager from "../views/appletsMall/goodsTypeManager"
import CategoryGoodsList from "../views/appletsMall/categoryGoodsList"
import ChooseGoodsList from "../views/appletsMall/chooseGoodsList"
import ChooseGoods from "../views/appletsMall/chooseGoods"
import SingleBoardDetection from "../views/testSystem/singleBoardDetection"
import PlayCamVideo from "../views/backend/device/PlayCamVideo"
import ApplicationCases from "../views/first/applicationcases"
import ApplicationCasesManage from "../views/backend/homeManagement/applicationCasesManage"
import ApplicationCasesEdit from "../views/backend/homeManagement/applicationCasesEdit"
import ApplicationCasesAdd from "../views/backend/homeManagement/applicationCasesAdd"
import GetWhiteList from "../views/middleware/whiteQueryList"
import DownloadCenter from "../views/first/downloadCenter"
import FilePreview from "../views/first/filePreview"
import TeleCardNumImportList from "../views/middleware/device/TeleCardNumImportList"
import TeleCardNumQueryList from "../views/testSystem/TeleCardNumQueryList"
import store from '../store'



const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass: 'active',
	//详情页回来缓存数据
	scrollBehavior(to,from,savedPosition){
     if(savedPosition){
		 return savedPosition
	 }else{
		 return{
			 x:0,
			 y:0
		 }
	 }
	},

	routes: [
		// {
		// 	path: '/',
		// 	component: PlayCamVideo
		// },
		{
			path: '/',
			name: 'First',
			component: First,
			children: [
				{
					path: '/productCenter',
					name: 'ProductCenter',
					component: ProductCenter,
				},
				{
					path: '/applicationcases',
						name: 'ApplicationCases',
					component: ApplicationCases,
				},
				{
					path: '/aboutUs',
					name: 'AboutUs',
					component: AboutUs,
				},
				{
					path: '/downloadCenter',
					name: 'DownloadCenter',
					component: DownloadCenter,
				},
				{
					path: '/filePreview',
					name: 'FilePreview',
					component: FilePreview,
				},
				{
					path: '/solution',
					name: 'Solution',
					component: Solution,
				},
				{
					path: '/newsInformation',
					name: 'NewsInformation',
					component: NewsInformation,
				},
				{
					path: '/merchantsToJoin',
					name: 'MerchantsToJoin',
					component: MerchantsToJoin,
				},
				{
					path: '/fireAlarm',
					name: 'FireAlarm',
					component: FireAlarm,
				},
				{
					path: '/fireAlarmNn',//6101N
					name: 'FireAlarmNn',
					component: FireAlarmNn,
				},
				{
					path: '/methane',//6200
					name: 'Methane',
					component: Methane,
				},
				{
					path: '/methaneNn',//6200
					name: 'MethaneNn',
					component: MethaneNn,
				},
				{
					path: '/carbonMonoxide',//6201
					name: 'CarbonMonoxide',
					component: CarbonMonoxide,
				},
				{
					path: '/carbonMonoxideNn',//6201N
					name: 'CarbonMonoxideNn',
					component: CarbonMonoxideNn,
				},
				{
					path: '/smoke',//6103
					name: 'Smoke',
					component: Smoke,
				},
				{
					path: '/smokeLl',//6103l
					name: 'SmokeLl',
					component: SmokeLl,
				},
				{
					path: '/smokeWw',//6103w
					name: 'SmokeWw',
					component: SmokeWw,
				},
				{
					path: '/jylh',//家用礼盒
					name: 'Jylh',
					component: Jylh,
				},
				{
					path: '/flashlight',// 手电筒
					name: 'Flashlight',
					component: Flashlight,
				},
				{
					path: '/fireBlanket',// 手电筒
					name: 'FireBlanket',
					component: FireBlanket,
				},

				{
					path: '/dynamicPage',// 动态页面
					name: 'DynamicPage',
					component: DynamicPage,
				},
				{
					path: '/fireExtinguisher',// 手电筒
					name: 'FireExtinguisher',
					component: FireExtinguisher,
				},
				{
					path: '/household',// 新闻资讯——家用灭火器
					name: 'Household',
					component: Household,
				},
				{
					path: '/mobileCloud',// 新闻资讯——参加移动云活动
					name: 'MobileCloud',
					component: MobileCloud,
				},
				{
					path: '/fireProducts',// 新闻资讯——搞事情消防产品
					name: 'FireProducts',
					component: FireProducts,
				},
					{
					path: '/prosperity',// 新闻资讯——喜报
					name: 'Prosperity',
					component: Prosperity,
				},

			]
		},
		{
			path: '/bigDataSupervisoryCenter',
			component: BigScreen
		},

		{
			path: '/detail',
			name: 'Detail',
			component: Detail
		},{
			path: "/videoTutorial",
			name: "VideoTutorial",
			component: VideoTutorial
		},

		{
			path: "/videoTutorialDetail",
			name: "VideoTutorialDetail",
			component: VideoTutorialDetail
		},
		// {
		// 	// path: '/mainRouter',
		// 	// name: 'mainRouter',
		// 	// component: MainRouter,
		// 	path: '/backstage',
		// 	name: 'backstage',
		// 	component: Backstage,
		// 	meta: {
		// 		isLogined: true
		// 	},
		// },
		{
			// path: '/backstage',
			// name: 'backstage',
			// component: Backstage,
			path: '/mainRouter',
			name: 'mainRouter',
			component: MainRouter,
			meta: {
				isLogined: true
			},
			children: [{
					path: '/myself',
					name: 'MySelf',
					component: MySelf,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/myWorkorder',
					name: 'MyWorkorder',
					component: MyWorkorder,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/submitWorkorder',
					name: 'SubmitWorkorder',
					component: SubmitWorkorder,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/workOrderDetails',
					name: 'WorkOrderDetails',
					component: WorkOrderDetails,
					meta: {
						isLogined: true
					},
				},
                {
					path: '/woekOrderHandle',
					name: 'WoekOrderHandle',
					component: WoekOrderHandle,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/sysuserdetail',
					name: 'SysUserDetail',
					component: SysUserDetail,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/sysuser',
					name: 'SysUser',
					component: SysUser,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/sysuseradd',
					name: 'SysUserAdd',
					component: SysUserAdd,
					meta: {
						isLogined: true
					},
				},

				{
					path: '/sysPermission',
					name: 'SysPermission',
					component: SysPermission,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/sysRole',
					name: 'SysRole',
					component: SysRole,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/addRole',
					name: 'AddRole',
					component: AddRole,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/editrole',
					name: 'EditRole',
					component: EditRole,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/thirdPartyApplications',//第三方路由
					name: 'ThirdPartyApplications',
					component: ThirdPartyApplications,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/thirdPlatformAppLimitDataList',//第三方路由限制
					name: 'ThirdPlatformAppLimitDataList',
					component: ThirdPlatformAppLimitDataList,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/cardManagement',//卡管理
					name: 'CardManagement',
					component: CardManagement,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/cardExpiration',//卡到期管理
					name: 'CardExpiration',
					component: CardExpiration,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/cardRechargeRecord',//卡充值记录
					name: 'CardRechargeRecord',
					component: CardRechargeRecord,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/cardRechargeRules',//卡充值规则
					name: 'CardRechargeRules',
					component: CardRechargeRules,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/helpCenterType',//帮助中心类型
					name: 'HelpCenterType',
					component: HelpCenterType,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/helpCenter',//帮助中心类型
					name: 'HelpCenter',
					component: HelpCenter,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/addHelpCenter',//帮助中心类型
					name: 'AddHelpCenter',
					component: AddHelpCenter,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/updataHelpCenter',//帮助中心类型
					name: 'UpdataHelpCenter',
					component: UpdataHelpCenter,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/agreement',
					name: 'AgreeMent',
					component: AgreeMent,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/wechatLoginShow', //微信登录展示状态
					name: 'WechatLoginShow',
					component: WechatLoginShow,
					meta: {
						isLogined: true
					},
				},
				{
					path: '/rolemanagement',
					name: 'RoleManagement',
					component: RoleManagement,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/roleadd',
					name: 'RoleAdd',
					component: RoleAdd,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/productList',
					name: 'product',
					component: ProductList,
					meta: {
						isLogined: true
					}

				},{
					path: '/pointsAdd',
					name: 'pointsAdd',
					component: PointsAdd,
					meta: {
						isLogined: true
					}

				},

				{
					path: '/productDetail',
					name: 'productDetail',
					component: productDetail,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/manyDispatch',
					name: 'ManyDispatch',
					component: ManyDispatch,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/manyUnbundling',
					name: 'ManyUnbundling',
					component: ManyUnbundling,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/cleanDeviceInfo',
					name: 'CleanDeviceInfo',
					component: CleanDeviceInfo,
					meta: {
						isLogined: true
					}

				},
				{
					// path: '/productCategoryList',
					path: '/productType',
					name: 'productCategoryList',
					component: ProductCategoryList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/deviceList',
					name: 'DeviceList',
					component: DeviceList,
					meta: {
						isLogined: true,

					}

				},
				{
					path: '/fireAlarmList',
					component: FireAlarmList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/fireStatistics',//火警统计
					component: FireStatistics,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/fireStatisticsDetails',//火警周期-火警统计的详情
					component: FireStatisticsDetails,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/statisticsOverview',//火警统计的概览
					component: StatisticsOverview,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/newsletterStatistics',//消息统计
					component: NewsletterStatistics,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/signalStatistics',//信号统计
					component: SignalStatistics,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/heatmap',//信号热力图
					component: Heatmap,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/fireDetail',
					component: FireDetail,
					name:'fireDetail',
					meta: {
						isLogined: true
					}

				},
				{
					path: '/faultDetail',
					component: FaultDetail,
					name:'faultDetail',
					meta: {
						isLogined: true
					}

				},
				{
					path: '/faultList',
					component: FaultList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/deviceRegisterList',
					component: DeviceRegisterList,
					meta: {
						isLogined: true
					}

				},{
					path: '/getWhiteList',
					component: GetWhiteList,
					meta: {
						isLogined: true
					}

				},

				{
					path: '/teleCardNumImportList',
					component: TeleCardNumImportList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/importRegisterDevice',
					component: LotRegistDevice,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/wifiDeviceRegisterList',
					component: WifiDeviceRegisterList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/wifiLotDeleteDevice',
					component: WifiLotDeleteDevice,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/wifiLotUpdateDevice',
					component: WifiLotUpdateDevice,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/wifiLotRegistDevice',
					component: WifiLotRegistDevice,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/wifiLotRegistQuery',
					component: WifiLotRegistQuery,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/lotRegistQuery',//设备注册列表
					component: LotRegistQuery,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/upgrade',//设备升级
					component: Upgrade,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/upgradeList',//设备升级
					component: UpgradeList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/upgradeGroup',//设备组升级
					component: UpgradeGroup,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/lotDeleteDevice',
					component: LotDeleteDevice,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/placeList',
					name:'PlaceList',
					component: PlaceList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/placeDetail',
					name:'PlaceDetail',
					component: PlaceDetail,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/roomList',
					name:'RoomList',
					component: RoomList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/userList',
					name:'UserList',
					component: UserList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/faultType',
					name:'FaultType',
					component: FaultType,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/deviceRepair',
					name:'DeviceRepair',
					component: DeviceRepair,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/accountList',
					name:'AccountList',//账号管理-账号列表
					component: AccountList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/accountType',
					name:'AccountType',//账号管理-账号类型列表
					component: AccountType,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/nodeList',
					name:'NodeList',//节点模块-节点列表
					component: NodeList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/questionStatus',
					name:'QuestionStatus',//问题管理-问题状态
					component: QuestionStatus,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/moduleList',
					name:'ModuleList',//节点模块-模块列表
					component: ModuleList,
					meta: {
						isLogined: true,
						keepAlive: false,
					}
				},

				{
					path: '/moduleDetails',
					name:'ModuleDetails',//节点模块-模块详情
					component: ModuleDetails,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/questionList',
					name:'QuestionList',//问题管理-问题列表
					component: QuestionList,
					meta: {
						isLogined: true,
						keepAlive: false,

					}
				},
				{
					path: '/questionDetails',
					name:'QuestionDetails',//问题管理-问题详情
					component: QuestionDetails,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/questionHandle',
					name:'QuestionHandle',//问题管理-问题处理
					component: QuestionHandle,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/questionAdd',
					name:'QuestionAdd',//问题管理-问题新增
					component: QuestionAdd,
					meta: {
						isLogined: true
					}
				},
					{
					path: '/questionType',
					name:'QuestionType',//问题管理-问题类型
					component: QuestionType,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/mallList',
					name:'MallList',//小程序商城-商品列表
					component: MallList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/goodsTypeManager',
					name:'GoodsTypeManager',//小程序商城-商品列表
					component: GoodsTypeManager,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/categoryGoodsList',
					name:'CategoryGoodsList',//小程序商城-商品列表
					component: CategoryGoodsList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/chooseGoodsList',
					name:'ChooseGoodsList',//小程序商城-分配商品列表
					component: ChooseGoodsList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/chooseGoods',
					name:'chooseGoods',//小程序商城-分配商品列表
					component: ChooseGoods,
					meta: {
						isLogined: true,
						keepAlive: false

					}
				},
				{
					path: '/orderList',
					name:'OrderList',//小程序商城-物流
					component: OrderList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/addGood',
					name:'AddGood',//小程序商城-新增商品
					component: AddGood,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/goodsUpdate',
					name:'GoodsUpdate',//小程序商城-修改商品
					component: GoodsUpdate,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/exchangeList',
					name:'ExchangeList',//小程序商城-修改商品
					component: ExchangeList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/orderDetail',
					name:'OrderDetail',//小程序商城-订单详情
					component: OrderDetail,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/redemptionCode',
					name:'RedemptionCode',//小程序商城-生成兑换码
					component: RedemptionCode,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/exchangeDetail',
					name:'ExchangeDetail',//小程序商城-兑换码详情
					component: ExchangeDetail,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/batchCodes',
					name:'BatchCodes',//小程序商城-卖出批量兑换码
					component: BatchCodes,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/appStatistics',
					name:'AppStatistics',//小程序商城-统计
					component: AppStatistics,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/productCarousel',
					name:'ProductCarousel',//小程序商城-商品轮播
					component: ProductCarousel,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/addCarousel',
					name:'AddCarousel',//小程序商城-商品轮播
					component: AddCarousel,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/editCarousel',
					name:'EditCarousel',//小程序商城-商品轮播
					component: EditCarousel,
					meta: {
						isLogined: true
					}
				},

				{
					path: '/advertiseCarousel',
					name:'AdvertiseCarousel',//小程序商城-广告列表
					component: AdvertiseCarousel,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/ediAdvertise',
					name:'EdiAdvertise',//小程序商城-广告修改
					component: EdiAdvertise,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/addAdvertise',
					name:'AddAdvertise',//小程序商城-广告新增
					component: AddAdvertise,
					meta: {
						isLogined: true
					}
				},
					{
					path: '/answerList',
					name:'AnswerList',//小程序全民学消防题库
					component: AnswerList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/shopList',
					name:'ShopList',//小程序全民学消防题库
					component: ShopList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/evaluation',
					name:'EvaluationList',//小程序全民学消防题库
					component: EvaluationList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/activityList',
					name:'ActivityList',//活动列表
					component: ActivityList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/shareConfigList',
					name:'ShareConfigList',//活动列表
					component: Share,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/prizeList',
					name:'prizeList',//奖品列表
					component: PrizeList,
					meta: {
						keepAlive: true,
						isLogined: true,

					}
				},
				{
					path: '/activityPrizes',
					name:'ActivityPrizes',//奖品列表
					component: ActivityPrizes,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/prizeManagement',
					name:'PrizeManagement',//奖品列表
					component: PrizeManagement,
					meta: {
						isLogined: true
					}
				},

				{
					path: '/userPrizeDetail',
					name:'UserPrizeDetail',//用户奖品列表
					component: UserPrizeDetail,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/statisticsBehavior',
					name:'StatisticsBehavior',//统计行为列表
					component: StatisticsBehavior,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/productPointList',
					name:'ProductPointList',//产品型号指标新增
					component: ProductPointList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/statisticsVisits',
					name:'StatisticsVisits',//  访问量统计列表
					component: StatisticsVisits,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/statisticsVisitsDdetail',
					name:'StatisticsVisitsDdetail',//  访问量统计列表
					component: StatisticsVisitsDdetail,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/wifiTestResult',
					name:'WifiTestResult',//  产测wifi设备结果
					component: WifiTestResult,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/singleBoardDetection',
					name:'SingleBoardDetection',//  产测wifi设备结果
					component: SingleBoardDetection,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/wifiTestResultInfo',
					name:'WifiTestResultInfo',//  产测wifi设备结果详情
					component: WifiTestResultInfo,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/playCamVideo',
					name:'PlayCamVideo',//  产测wifi设备结果详情
					component: PlayCamVideo,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/statisticsSmsList',
					name:'StatisticsSmsList',//  短信分类统计
					component: StatisticsSmsList,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/deviceDetail',
					name: 'DeviceDetail',
					component: DeviceDetail,
					meta: {
						isLogined: true
					},
				// },
					children: [
						{
							path: '/deviceInfo',
							name: 'DeviceInfo',
							component: DeviceInfo,
							meta: {
								isLogined: true
							}
						},
						{
							path: '/deviceHistoryData',
							name: 'DeviceHistoryData',
							component: DeviceHistoryData,
							meta: {
								isLogined: true
							}
						},
						{
							path: '/deviceCommand',
							name: 'DeviceCommand',
							component: DeviceCommand,
							meta: {
								isLogined: true
							}
						},
						{
							path: '/deviceRealtimeData',
							name: 'DeviceRealtimeData',
							component: DeviceRealtimeData,
							meta: {
								isLogined: true
							}
						},
						{
							path: '/managementRecords',
							name:'ManagementRecords',
							component: ManagementRecords,
							meta: {
								isLogined: true
							}

						},
					]
				},
				{
					path: '/platform',
					name: 'PlatForm',
					// route level code-splitting
					// this generates a separate chunk (about.[hash].js) for this route
					// which is lazy-loaded when the route is visited.
					// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
					component: About
				},
				{
					path: '/zhongjianjian',
					name: 'Middle',
					component: MiddleWare
				},
				{
					path: '/applog',
					name:'AppLog',
					component: AppLog,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/weblog',
					name:'WebLog',
					component: WebLog,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/homeIn',
					name:"HomeIn",
					component: HomeIn,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/news',
					name:"News",
					component: News,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/planConfiguration',
					name:"PlanConfiguration",
					component: PlanConfiguration,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/appNews',
					name:"AppNews",
					component: AppNews,
					meta: {
						isLogined: true
					}

				},
				{
					path: "/applicationCasesManage",
					name: "ApplicationCasesManage",
					component: ApplicationCasesManage
				},
				,{
					path: "/applicationCasesEdit",
					name: "ApplicationCasesEdit",
					component: ApplicationCasesEdit
				},
				,{
					path: "/applicationCasesAdd",
					name: "ApplicationCasesAdd",
					component: ApplicationCasesAdd
				},
				{
					path: '/addAppNews',
					name:"AddAppNews",
					component: AddAppNews,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/appNewsModify',
					name:"AppNewsModify",
					component: AppNewsModify,
					meta: {
						isLogined: true
					}

				},

				{
					path: '/appPlan',
					name:"AppPlan",
					component: AppPlan,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/appAddPlan',
					name:"AppAddPlan",
					component: AppAddPlan,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/appPlanModily',
					name:"AppPlanModily",
					component: AppPlanModily,
					meta: {
						isLogined: true
					}

				},

				{
					path: '/planAdd',
					name:"PlanAdd",
					component: PlanAdd,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/planModify',
					name:"PlanModify",
					component: PlanModify,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/newAdd',
					name:"NewAdd",
					component: NewAdd,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/newModify',
					name:"NewModify",
					component: NewModify,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/addAdvertisement',
					name:"AddAdvertisement",
					component: AddAdvertisement,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/addEdit',
					name:"AddEdit",
					component: AddEdit,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/productConfiguration',
					name:"ProductConfiguration",
					component: ProductConfiguration,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/addProductConfiguration',
					name:"AddProductConfiguration",
					component: AddProductConfiguration,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/editProductConfiguration',
					name:"EditProductConfiguration",
					component: EditProductConfiguration,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/appProductConfiguration',
					name:"AppProductConfiguration",
					component: AppProductConfiguration,
					meta: {
						isLogined: true
					}
				},

				{
					path: '/appAddProductConfiguration',
					name:"AppAddProductConfiguration",
					component: AppAddProductConfiguration,
					meta: {
						isLogined: true
					}
				},

				{
					path: '/appEditProductConfiguration',
					name:"AppEditProductConfiguration",
					component: AppEditProductConfiguration,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/fileManagement',
					name:"FileManagement",
					component: FileManagement,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/fileManagementWeb',
					name:"FileManagementWeb",
					component: FileManagementWeb,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/versionUpTipList',
					name:"VersionUpTipList",
					component: VersionUpTipList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/programVersion',
					name:"ProgramVersion",
					component: ProgramVersion,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/sysFloorPlate',
					name:"SysFloorPlate",
					component: SysFloorPlate,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/plateList',
					name:"PlateList",
					component: PlateList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/addPlate',
					name:"AddPlate",
					component: AddPlate,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/editPlate',
					name:"EditPlate",
					component: EditPlate,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/systemCode',
					name:"SystemCode",
					component: SystemCode,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/wifiSystemCode',
					name:"WifiSystemCode",
					component: WifiSystemCode,
					meta: {
						isLogined: true
					}

				},

				{
					path: '/testDevice',
					name:"TestDevice",
					component: TestDevice,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/teleCardNumQueryList',
					name:"TeleCardNumQueryList",
					component: TeleCardNumQueryList,
					meta: {
						isLogined: true
					}

				},

				{
					path: '/testResult',
					name:"TestResult",
					component: TestResult,
					meta: {
						isLogined: true
					}

				},

				{
					path: '/testDeviceJy',
					name:"TestDeviceJy",
					component: TestDeviceJy,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/testResultDetail',
					name:"TestResultDetail",
					component: TestResultDetail,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/queryDeviceResult',
					component: QueryDeviceResult,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/equipmentQuery',
					component: EquipmentQuery,
					meta: {
						isLogined: true
					}
				},
				{
					path: '/appList',
					name:"AppList",
					component: AppList,
					meta: {
						isLogined: true
					}

				},
               	{
					path: '/deviceManagementList',
					name:"DeviceManagementList",
					component: DeviceManagementList,
					meta: {
						isLogined: true
					}

				},
				{
					path: '/addDeviceMqtt',
					name:"AddDeviceMqtt",
					component: AddDeviceMqtt,
					meta: {
						isLogined: true
					}

				},

			]
		},
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/forgetPage',
			name: 'ForgetPage',
			component: ForgetPage
		},
	],

})
// let request=false
router.beforeEach((to, from, next) => {
	// console.log("to--->",to);
	// console.log("from--->",from);
	document.body.scrollTop = 0
	// firefox
	document.documentElement.scrollTop = 0
	if (to.meta.isLogined) {
		// 读取token
		// console.log(store.state.userinfo.token)
		const token = store.state.userinfo.token
		if (token) {
			next();
		} else {
			next({
				path: "/login"
			})
		}
	}

	next();
})

// router.beforeEach((to, from, next) => {

// 	next()
//   })


export default router
