<template>
  <div>
    <el-dialog title="修改问题等级" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="问题等级" :label-width="formLabelWidth">
          <el-select v-model="changeLevel" placeholder="请选择问题等级">
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="conformChangeProblemLevel()">确 定</el-button>
      </div>
    </el-dialog>
      <el-dialog title="修改模板名称" :visible.sync="dialogTemplate">
      <el-form>
        <el-form-item label="模板名称" :label-width="formLabelWidth">
          <el-select v-model="templateId"  @change="NodeIdFn()"  placeholder="请选择模板名称">
              <el-option v-for="item in templateList" :key="item.id" :label="item.templateName" :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
           <el-form-item label="节点名称" :label-width="formLabelWidth">
          <el-select v-model="templateNodeId" placeholder="请选择节点名称">
              <el-option v-for="item in templateNodeIdList" :key="item.templateNodeId" :label="item.nodeName" :value="item.templateNodeId">
              </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTemplate = false">取 消</el-button>
        <el-button type="primary" @click="conformTemplate()">确 定</el-button>
      </div>
    </el-dialog>
    <div class="outting">
      <div class="select">
        <span class="title">问题详情</span>
        <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail" class="addRoleButton">返回问题列表
        </el-button>
      </div>
      <div class="detail">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>详情信息</span>
          </div>
          <div style="display:flex;margin-bottom:40px">
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题标题</span>
                <div class="detail-vv">{{problemTitle}}</div>
              </div>
            </div>

            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题类型</span>
                <div class="detail-vv">{{problemType}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题状态</span>
                <div class="detail-vv">{{problemStatus}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题创建者</span>
                <div class="detail-vv">{{creatorUser}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">关联mac号</span>
                <div class="detail-vv">{{mac}}</div>
              </div>
            </div>

          </div>
          <div style="display:flex;;margin-bottom:40px">

            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">产品名称</span>
                <div class="detail-vv">{{productName}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题来源</span>
                <div class="detail-vv">{{problemSource}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题等级</span>
                <div class="detail-vv">{{severity}}
                  <!--                  <span style="margin-left: 20px" >-->
                  <!--                    <i class="el-icon-edit" style="color: #3f9eff"></i>-->
                  <!--                  </span>-->
                  <el-button v-show="showEditLevel" @click="editProblemLevel()" type="text" size="small"
                    style="margin-left: 30px" icon="el-icon-edit">修改</el-button>
                </div>
              </div>
            </div>
             <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">模板名称</span>
                <div class="detail-vv">{{templateName}}
                  <el-button v-show="showEditLevel" @click="editTemplateName()" type="text" size="small"
                    style="margin-left:10px" icon="el-icon-edit">修改</el-button>
                </div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">创建时间</span>
                <div class="detail-vv">{{createTime}}</div>
              </div>
            </div>
          
            <!--            <div style="flex:1;">-->
            <!--              <div class="grid-content bg-purple">-->
            <!--                &lt;!&ndash; <span class="detail-span">抄送人员信息</span>-->
            <!--                <div class="detail-vv"  v-for="(index,key) in ccUsers" :key="key">{{index.realName}}</div> &ndash;&gt;-->
            <!--              </div>-->
            <!--            </div>-->

          </div>
          <div style="display:flex;margin-bottom:40px">
              <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">当前处理人员</span>
                <div class="detail-vv">{{handlerUser}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">手机号码</span>
                <div class="detail-vv">{{phone}}</div>
              </div>
            </div>

            <div style="flex:3;">
              <div class="grid-content bg-purple">
                <span class="detail-span">问题描述</span>
                <div class="detail-vv">{{problemDesc}}</div>
              </div>
            </div>

          </div>
          <div style="display:flex;">

            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span" style="display: inline-block; vertical-align: middle;">附件信息:</span>

                <span class="detail-span" v-if="istitle == '' ">
                  暂无数据
                </span>
                <el-image class="img_list"  @click="dialogTableVisible = true" v-else-if="istitle == '1'" v-for="(item,index)  in   additionalPicturesUrl"
                  :key="index"
          
                  :src="item" referrerpolicy="no-referrer"></el-image>
                <span v-else v-for="(i,key)  in   additionalPicturesUrl" :key="key">
                  <el-link :href="i" style="margin-left:20px;" target="primary">下载附件{{key+1}}</el-link>
                </span>

                <!-- <div class="detail-vv">{{additionalPicturesUrl}}</div> -->
              </div>
            </div>
          </div>
        </el-card>
      </div>
<el-dialog title="" :visible.sync="dialogTableVisible" width="80%">
  <el-image class="img_list2"   v-for="(item,index)  in   additionalPicturesUrl"
                  :key="index"
          
                  :src="item" referrerpolicy="no-referrer"></el-image>
</el-dialog>
      <div class="detail">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>处理流程</span>
          </div>
          <div class="stepShow">
            <el-steps :active="milepostActive" direction="vertical">
              <el-step v-for="(value, key) in milepost" :class="milepostActive== key+1 ? stepActive: '' "
                :title="value.nodeName" :key="key" :description="value.value">
                <template slot="description">
                  <p><span style="color:#666">处理人：</span> {{value.handlerUser}} <span><span
                        style="color:#666;display:inline-block;margin: 0 0 5px 10px">问题状态：</span>{{value.problemStatus}}</span>
                    <span style="color:#666;display:inline-block;margin: 0 0 5px 10px">时间:</span> {{value.createTime}}
                    <span style="color:#666;margin-left:15px">抄送人员信息：</span> <span
                      v-for=" (item,index) in value.ccUserNames" :key="index"> {{item}} </span></p>
                  <p style="margin-bottom:20px"><span style="color:#666;">处理意见：</span> {{value.handleOpinion}} </p>


                  <br />
                </template>
              </el-step>
            </el-steps>
          </div>
        </el-card>
      </div>
    </div>

  </div>
</template>
<script>
  export default {
    name: "PlaceDetail",
    data() {
      return {
        formLabelWidth: "120px",
        // 默认步骤数
        milepostActive: 20,
           dialogTableVisible: false,
        milepost: [],
        templateId:"",
        templateNodeId:"",
        templateList: [],
        templateNodeIdList: [],
        showEditLevel: false,
        dialogFormVisible: false,
        dialogTemplate: false,
        changeLevel: "",
        // 动态添加类名
        stepActive: "stepActive",
        additionalPicturesUrl: "", //附加图片地址
        istitle: "",
        ccUsers: "", //抄送人员信息
        createTime: "", //创建时间
        creatorUser: "", // 问题创建者
        handlerUser: "", //当前处理人员
        ccUserNames: "", //抄送人员
        id: "", //问题id
        mac: "", //关联mac号
        problemDesc: "", //问题描述
        problemSource: "", // 问题来源 = ['后台', 'app'],
        problemStatus: "", // 问题状态
        productName: "", // 产品名称
        problemTitle: "", //问题标题
        problemType: "", // 问题类型
        phone: "",
        severity: "", //问题等级
        templateName: "", //问题等级
      };
    },
    created() {
      sessionStorage.setItem("detailsIdProblem", this.$route.query.id);

    },
    mounted() {
      let level = sessionStorage.getItem('accountLevel');
      if (level == 0) {
        this.showEditLevel = true
      }
      this.id = sessionStorage.getItem("detailsIdProblem");

      this.$api.problemDetail(this.id).then(res => {


        this.ccUsers = res.data.data.ccUsers
        this.createTime = res.data.data.createTime
        this.creatorUser = res.data.data.creatorUser
        this.handlerUser = res.data.data.handlerUser
        this.id = res.data.data.id
        this.mac = res.data.data.mac
        this.problemDesc = res.data.data.problemDesc
        this.problemSource = res.data.data.problemSource
        this.productName = res.data.data.productName
        this.problemStatus = res.data.data.problemStatus
        this.problemTitle = res.data.data.problemTitle
        this.problemType = res.data.data.problemType
        this.phone = res.data.data.phone
        this.severity = res.data.data.severity
        this.templateName = res.data.data.templateName
        if (res.data.data.additionalPicturesUrl == null) {
          this.additionalPicturesUrl = ""
          this.istitle = ""


        } else {

          console.log(res.data.data.additionalPicturesUrl.search('jpg') != -1 || res.data.data.additionalPicturesUrl
            .search('png') != -1 ||
            res.data.data.additionalPicturesUrl.search('jpeg') != -1 || res.data.data.additionalPicturesUrl
            .search('gif') || res.data.data.additionalPicturesUrl.search('bmp') != -1)
          if (res.data.data.additionalPicturesUrl.search('jpg') != -1 || res.data.data.additionalPicturesUrl.search(
              'png') != -1 ||
            res.data.data.additionalPicturesUrl.search('jpeg') != -1 || res.data.data.additionalPicturesUrl.search(
              'gif') != -1 || res.data.data.additionalPicturesUrl.search('bmp') != -1) {
            this.istitle = "1"
            this.additionalPicturesUrl = res.data.data.additionalPicturesUrl.split(",")
            console.log("图片", this.additionalPicturesUrl)

          } else {

            this.additionalPicturesUrl = res.data.data.additionalPicturesUrl.split(",")
            console.log("文章", this.additionalPicturesUrl)
            this.istitle = "2"
          }


        }


      });
      this.$api.problemHandleProcess(this.id).then(res => {

        this.milepost = res.data.data
        let numList = []
        this.milepost.forEach((item, index) => {
          if (item.id === null) {
            // console.log(item)
            numList.push(index)
            // console.log(numList,)
            this.milepostActive = numList[0]
          } else {
            this.milepostActive = 20
          }
        })
      });
    },
    methods: {
      editProblemLevel() {
        this.changeLevel = this.severity
        this.dialogFormVisible = true
      },
      conformChangeProblemLevel() {
        this.$api.changeProblemLevel({
          "id": this.id,
          "severity": this.changeLevel
        }).then(res => {
          if (res.data.message.indexOf('成功') != -1) {
            this.severity = this.changeLevel
            this.dialogFormVisible = false
          }
        });
      },
      // 修改模板id
      editTemplateName(){
        this.dialogTemplate = true 
        this.templateId = ''
        this.templateNodeId = ''
         this.$api.templateAndNode().then(res => {
        console.log(res.data)
        this.templateList = res.data.data
        });
      },
    // 修改模板id联动给数据节点id
    NodeIdFn(){
       this.templateNodeIdList =[]
       this.templateNodeId =""
      for (let index = 0; index < this.templateList.length; index++) {
          if(this.templateId == this.templateList[index].id ){
             this.templateNodeIdList = this.templateList[index].templateNodes
       }
      }
    },
    // 提交确认修改
    conformTemplate(){
        this.$api.changeProblemLevel({
             "id": this.id,
          "templateId": this.templateId,
          "templateNodeId": this.templateNodeId
        }).then(res => {
        
        if(res.data.code == '200'){
             this.$message({
          message: '修改成功',
          type: 'success'
        });
           this.dialogTemplate = false
             for (let index = 0; index < this.templateList.length; index++) {
          if(this.templateId == this.templateList[index].id ){
             this.templateName = this.templateList[index].templateName
       }
      }
        
        }
           
          
        });
    },
      //回到模块页面
      gobackPlaceDetail() {
        this.$router.back();
        let keepAliveList = {
               id: this.$route.query.id,
            isActive: this.$route.query.isActive,
            current: this.$route.query.current,
            ifSearchMany: this.$route.query.ifSearchMany,
            mac: this.$route.query.mac,
            handlerUserId: this.$route.query.handlerUserId,
            isHandlerUser: this.$route.query.isHandlerUser,
            problemStatus: this.$route.query.problemStatus,
            problemSource: this.$route.query.problemSource,
            creatorUserId: this.$route.query.creatorUserId,
            problemTitle: this.$route.query.problemTitle,
            productId: this.$route.query.productId,
            workOrderNo: this.$route.query.workOrderNo,
            severityCode: this.$route.query.severityCode,
            templateName: this.$route.query.templateName,
        }
          sessionStorage.setItem("keepAliveList", JSON.stringify(keepAliveList));
          // console.log(keepAliveList)
        // this.$router.push({
        //   path: '/questionList',
        //   query: {
        //       id: this.$route.query.id,
        //     isActive: this.$route.query.isActive,
        //     current: this.$route.query.current,
        //     ifSearchMany: this.$route.query.ifSearchMany,
        //     mac: this.$route.query.mac,
        //     handlerUserId: this.$route.query.handlerUserId,
        //     isHandlerUser: this.$route.query.isHandlerUser,
        //     problemStatus: this.$route.query.problemStatus,
        //     problemSource: this.$route.query.problemSource,
        //     creatorUserId: this.$route.query.creatorUserId,
        //     problemTitle: this.$route.query.problemTitle,
        //     productId: this.$route.query.productId,
        //     workOrderNo: this.$route.query.workOrderNo,
        //     severityCode: this.$route.query.severityCode,

        //   }
        // });
        // this.$router.back();
      }
    },
    // 详情页路由判断
   beforeRouteLeave(to, from, next) {
         if(to.path == '/questionList'){
         sessionStorage.setItem("isKeepAlive", "1");
         }
        next();
        console.log(to.path)
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .item {
      height: 80px;
      width: 80px;
      border: 1px solid blue;
      float: left;
    }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }
    }

    .detail {
      padding: 0rem 3rem;
      margin-top: 1rem;

      .detail-span {
        font-size: 12 /16rem;
        color: #898989;
      }

      .detail-vv {
        margin-top: 5 /16rem;
        font-size: 14 /16rem;
      }
    }

    .img_list {
      width:10%;
      height: auto;
      margin-left: 20px;
      display: inline-block;
      vertical-align: middle;
    }
    
    .img_list2 {
      width:100%;
      height: auto;
      // margin-left: 20px;
      display: inline-block;
      vertical-align: middle;
    }

    .img_list:hover {
      //  transform: scale(3,3);
      // width: 400px;
      // height: auto;
      cursor: pointer;
      //  background-size: 110% 110%;
    }
  }

  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    border-radius: 4px;
  }

  .bg-purple-dark {
    background: #99a9bf;
  }

  //   .bg-purple {
  //     background: #d3dce6;
  //   }
  .bg-purple-light {
    background: #e5e9f2;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .stepShow {
    width: 80%;
    margin: 3rem auto;

    .downloadList {
      //  transform:translate(5.5rem, -4rem)
    }

    /deep/.el-step.is-horizontal.stepActive {
      .el-step__head.is-finish {
        .el-step__line {

          // 当前步骤数横线样式设置
          .el-step__line-inner {
            width: 50% !important;
            border-width: 1px !important;
          }
        }

        // 当前步骤数圆圈样式设置
        .el-step__icon.is-text {
          background: #409eff;
          color: #fff;
        }
      }
    }
  }

  #wrapper {
    background: radial-gradient(ellipse at top left,
        rgba(255, 255, 255, 1) 40%,
        rgba(229, 229, 229, 0.9) 100%);
    height: 100vh;
    padding: 60px 80px;
    width: 100vw;
    display: flex;
  }

  .state-item {
    width: 80px;
    height: 40px;
    color: #606266;
    background: #f6f6f6;
    border: 2px solid rgba(0, 0, 0, 0.05);
    text-align: center;
    line-height: 40px;
    font-family: sans-serif;
    border-radius: 4px;
    //   margin-right: 60px;
  }

  .line-wrap {
    //   display: flex;
    width: 100px;
    margin-bottom: 40px;
  }
</style>