<template>
	<div class="middle">

	</div>
</template>
<script>
	import SideNav from '../../components/sideNav'
	export default {
		name: 'Middle',
		//  components: {
		//   SideNav
		// }
	}
</script>
<style lang="less" scoped>
	.middle {
		width: 100%
	}

	.sideNav {
		width: 20%
	}
</style>
