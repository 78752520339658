<template>
    <div>
        <div class="outting">
            <div class="select">
                <span class="title">生成兑换码</span>
                <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回兑换列表</el-button>
            </div>
            <div class="detail">
                <el-card class="box-card" shadow="never">
                    
                    <a href="./demo.xlsx" download="后端码"  class="down_template">下载后端码导入模板</a>

                    <el-form :model="ruleForm" :rules="rules" :inline="true" ref="ruleForm" label-width="150px"
                        class="demo-ruleForm">
                        <el-form-item label="兑换码数量 " prop="codeNum">
                            <el-input v-model.number="ruleForm.codeNum" size="mini"
                                style="width:10rem;display:inline-block" placeholder="请输入兑换码数量"></el-input>
                        </el-form-item>

                        <el-form-item label="商品名称" prop="goodsId">
                            <el-select v-model="ruleForm.goodsId" placeholder="请选择商品名称" size="mini"
                                style="display:inline-block;width:10rem">
                                <el-option v-for="item in goodsList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="后端码" prop="needTail">
                            <el-select v-model="ruleForm.needTail" size="mini" placeholder="请选择后端码"
                                style="display:inline-block;width:10rem" @change="needTailFn">
                                <el-option label="需要" value="0"></el-option>
                                <el-option label="不需要" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <br>
                        <div v-if="ruleForm.needTail === '0'"
                            style="flex:6.5;font-size:.8rem;display:inline-block;margin-left:50px;margin-bottom:10px;">
                            <el-radio-group v-model="radio" @change="radioFn()">
                                <el-radio label="1">
                                    <el-upload class="upload-demo" :disabled="fileLDis" action :on-change="handleChange"
                                        :on-remove="handleRemove" :on-exceed="handleExceed" accept=".xls, .xlsx"
                                        :file-list="fileList" :limit="limitUpload" :auto-upload="false">
                                        <el-button type="primary" :disabled="fileLDis" round size="mini"
                                            class="input-button">导入后端码
                                        </el-button>
                                        <span style="color: red;display:inline">*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式
                                    </el-upload>
                                </el-radio>
                                <el-radio label="0">
                                    <el-input v-model="BackstageNo" maxlength="3" :disabled="fixCodeDis"
                                        onkeyup="value=value.replace(/[\W]/g,'')" size="mini"
                                        onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
                                        style="width:10rem;display:inline-block" placeholder="请输入后端码数"></el-input>
                                </el-radio>
                            </el-radio-group>

                        </div>
                        <br v-if="ruleForm.needTail === '0'">
                        <!-- <div 
                            style="flex:4.5;font-size:.8rem;line-height:2rem;display:inline-block;margin-left:50px">
                            <el-upload class="upload-demo" action :on-change="handleChange" :on-remove="handleRemove"
                                :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList"
                                :limit="limitUpload" :auto-upload="false">
                                <el-button type="primary" round size="mini" class="input-button">导入后端号
                                </el-button>
                                <span style="color: red">*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式
                            </el-upload>
                        </div> -->
                        <el-form-item label="有效期开始" prop="validityTime">
                            <el-date-picker style="width: 22.25rem;" clearable v-model="ruleForm.validityTime"
                                :picker-options="pickerBeginDateBefore" range-separator="~" start-placeholder="开始日期"
                                end-placeholder="结束日期" size="mini" value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
                                type="datetimerange" @change="getTimeBlock2"></el-date-picker>
                        </el-form-item>
                        <el-col :span="10" :offset="8">
                            <el-form-item>
                                <el-button type="primary" size="mini" @click="submitForm('ruleForm')">立即创建</el-button>
                                <el-button size="mini" @click="resetForm('ruleForm')">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-form>
                    <template>
                        <div v-if="tableDataList">
                            <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
                                <div class="result-info">
                                    共有
                                    <span>{{ total }}</span>个兑换码
                                    <el-button type="text" size="small" icon="el-icon-download" class="select-button"
                                        @click="goodsExport">导出
                                    </el-button>
                                </div>



                            </div>
                            <el-table :data="tableDataList" stripe size="small" class="product-table"
                                :header-cell-style="{'background-color': '#eeeeee'}">
                                <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
                                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                                <!-- <el-table-column prop="id" label="节点编号" align="center"></el-table-column> -->
                                <el-table-column prop="goodsName" label="商品名称" align="center"></el-table-column>
                                <el-table-column prop="exchangeCode" label="兑换码" align="center"></el-table-column>
                                <el-table-column prop="codeStatus" label="兑换情况" align="center">
                                    <template slot-scope="scope">
                                        <span>
                                            {{scope.row.codeStatus == 0 ?'待兑换':scope.row.codeStatus == 1?'已兑换':'已过期'}}
                                        </span>

                                    </template>
                                </el-table-column>
                                <el-table-column prop="validityTimeStart" label="有效期开始" align="center">
                                </el-table-column>

                                <el-table-column prop="validityTimeEnd" label="有效期截止" align="center"></el-table-column>

                                <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                            </el-table>
                            <el-pagination background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
                                layout="sizes,prev, pager, next" @size-change="handleSizeChange"
                                @current-change="handlePageChange">
                            </el-pagination>
                        </div>
                    </template>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
    import EditorBar from "../../components/wangEnduit";

    import E from "wangeditor";
    export default {
        name: "redemptionCode",
        components: {
            EditorBar
        },
        data() {
            var checkAge = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('不能为空'))
                }
                setTimeout(() => {
                    // console.log('number', Number.isInteger(value))
                    if (!/(^[1-9]\d*$)/.test(value)) {
                        callback(new Error('请输入数字值'))
                    } else {
                        if (value > 10000) {
                            callback(new Error('不能超过10000'))
                        } else {
                            callback()
                        }
                    }
                }, 1000)
            }
            return {
                formLabelWidth: "120px",
                uri: "",
                timer: "",
                BackstageNo: "", //输入的后端码数
                goodName: "",
                radio: '1',
                fixCodeDis: true,
                fileLDis: false,
                goodsList: [],
                tableData: [],
                tableDataList: [],
                detail: "",
                content: "",
                current: 1,
                pageSize: 10,
                total: 0,
                pickerBeginDateBefore: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7 //如果当天可选，就不用减8.64e7
                    }
                },
                isClear: false, //清除富文本编辑器内容
                // 默认步骤数
                limitUpload: 5,
                limitUpload2: 1,
                fileList: [],
                fileMulLis: [],
                stepActive: "stepActive",

                dialogImageUrl: "",
                dialogVisible: false,
                value: "",
                file: {},
                dialogImageUrl2: "",
                dialogVisible2: false,
                value2: "",
                file2: {},
                batchId: "",
                fileList: [],
                limitUpload: 1,
                colFile: "",
                ruleForm: {
                    goodsId: "",
                    tailCodes: [],
                    codeNum: "",
                    needTail: "",
                    validityTime: "",
                },
                rules: {
                    tailCodes: [{
                        required: true,
                        message: "后端码",
                        trigger: "blur"
                    }],
                    validityTime: [{
                        required: true,
                        message: '请选择有效时间',
                        trigger: 'change'
                    }],

                    goodsId: [{
                        required: true,
                        message: '请选择商品名称',
                        trigger: 'change'
                    }],
                    needTail: [{
                        required: true,
                        message: '请选择后端码',
                        trigger: 'change'
                    }],
                    codeNum: [{
                        required: true,
                        validator: checkAge,
                        trigger: 'change'
                    }, ],
                }
            };
        },
        created() {

        },
        mounted() {
            this.goodsListPull()
        },
        methods: {
            change(val) {
                // console.log(val);
                this.ruleForm.newsContent = val;
            },
            goodsListPull() {
                this.$api.GoodsListPull().then(res => {
                    // console.log(res.data.data)
                    this.goodsList = res.data.data

                });
            },
            // 后端码的单选
            radioFn() {
                if (this.radio == 1) {
                    this.BackstageNo = ""
                    this.fileLDis = false
                    this.fixCodeDis = true
                } else {
                    this.fileTemp = null
                    this.fileLDis = true
                    this.fixCodeDis = false
                    this.fileList = []
                }
            },
            //获取创建日期
            getTimeBlock2() {
                if (this.ruleForm.validityTime) {
                    this.validityTimeStart = this.ruleForm.validityTime[0];
                    this.validityTimeEnd = this.ruleForm.validityTime[1];
                } else {
                    this.validityTimeStart = "";
                    this.validityTimeEnd = "";
                }
            },
            needTailFn() {
                this.tableData = []
            },
            handlePageChange(val) {
                this.current = val;
                this.nodeList();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.nodeList();
            },
            nodeList() {
                this.$api
                    .CodeList({
                        page: {
                            current: this.current,
                            size: this.pageSize
                        },
                        codeShowQueryVo: {
                            batchId: this.batchId
                        }
                    })
                    .then(res => {
                        // console.log(res.data.data.records)
                        this.total = res.data.data.total;
                        this.tableDataList = res.data.data.records;

                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            submitForm(ruleForm) {
                //  this.ruleForm.colFile = this.file
                //     this.ruleForm.detailFiles = this.fileList
                console.log(this.ruleForm.needTail, this.tableData, '--', this.BackstageNo)

                if (this.ruleForm.needTail == '0') {
                    if (this.tableData || this.BackstageNo) {
                        console.log('zhengc ')
                        this.$refs[ruleForm].validate(valid => {
                            this.ruleForm.tailCodes = this.tableData
                            this.ruleForm.validityTimeStart = this.validityTimeStart
                            this.ruleForm.validityTimeEnd = this.validityTimeEnd
                            this.ruleForm.codeType = this.radio
                            this.ruleForm.fixCode = this.BackstageNo
                            if (valid) {
                                this.$api.GenerateCode(this.ruleForm).then(res => {
                                    if (res.data.code == 200) {
                                        this.batchId = res.data.data
                                        this.$message.success("新增成功!");
                                        this.$nextTick(() => {
                                            this.$api
                                                .CodeList({
                                                    page: {
                                                        current: 1,
                                                        size: 10
                                                    },
                                                    codeShowQueryVo: {
                                                        batchId: res.data.data
                                                    }
                                                })
                                                .then(res => {
                                                    this.total = res.data.data.total;
                                                    this.tableDataList = res.data.data
                                                        .records;
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });

                                        });
                                    }
                                });
                            } else {
                                console.log("error submit!!");

                                return false;
                            }
                        });
                    } else {
                        this.$message.info("请填写后端码!");
                    }

                }
                // if (this.ruleForm.needTail == '0' && this.tableData.length == 0 ) {
                //     console.log('请填写后端码')
                //     this.$message.info("请填写后端码!");
                // } 
                else {
                    this.$refs[ruleForm].validate(valid => {
                        this.ruleForm.tailCodes = this.tableData
                        this.ruleForm.validityTimeStart = this.validityTimeStart
                        this.ruleForm.validityTimeEnd = this.validityTimeEnd
                        this.ruleForm.codeType = this.radio
                        this.ruleForm.fixCode = this.BackstageNo
                        if (valid) {
                            this.$api.GenerateCode(this.ruleForm).then(res => {
                                if (res.data.code == 200) {
                                    this.batchId = res.data.data
                                    this.$message.success("新增成功!");
                                    this.$nextTick(() => {
                                        this.$api
                                            .CodeList({
                                                page: {
                                                    current: 1,
                                                    size: 10
                                                },
                                                codeShowQueryVo: {
                                                    batchId: res.data.data
                                                }
                                            })
                                            .then(res => {
                                                this.total = res.data.data.total;
                                                this.tableDataList = res.data.data.records;
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });

                                    });
                                }
                            });
                        } else {
                            console.log("error submit!!");

                            return false;
                        }
                    });
                }

            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.tableData = []
                this.fileList = []
                this.validityTimeStart = ""
                this.validityTimeEnd = ""
            },
            //回到模块页面
            gobackPlaceDetail() {
                this.$router.push("/exchangeList");
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                // this.fileList = fileList
                this.value = "";
                this.file = {};
            },

            handlePictureCardPreview2(file) {
                this.dialogImageUrl2 = file.url;
                this.dialogVisible2 = true;
            },
            handleChange(file, fileList) {
                this.fileTemp = file.raw;
                if (this.fileTemp) {
                    this.importfxx(this.fileTemp);

                } else {
                    this.$message({
                        type: "warning",
                        message: "请上传附件！"
                    });
                }
            },
            //超出最大上传文件数量时的处理方法
            handleExceed(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },
            //移除文件的操作方法
            handleRemove(file, fileList) {
                this.fileTemp = null;
                this.tableData = [];
            },

            importfxx(obj) {
                let _this = this;
                let inputDOM = this.$refs.inputer;
                // 通过DOM取文件数据
                this.file = event.currentTarget.files[0];
                var rABS = false; //是否将文件读取为二进制字符串
                var f = this.file;
                var reader = new FileReader();

                //if (!FileReader.prototype.readAsBinaryString) {
                FileReader.prototype.readAsBinaryString = function (f) {
                    var binary = "";
                    var rABS = false; //是否将文件读取为二进制字符串
                    var pt = this;
                    var wb; //读取完成的数据
                    var outdata;
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        var bytes = new Uint8Array(reader.result);
                        var length = bytes.byteLength;
                        for (var i = 0; i < length; i++) {
                            binary += String.fromCharCode(bytes[i]);
                        }

                        //此处引入，用于解析excelnpm
                        var XLSX = require("xlsx");

                        if (rABS) {
                            wb = XLSX.read(btoa(fixdata(binary)), {
                                //手动转化
                                type: "base64"
                            });
                        } else {
                            wb = XLSX.read(binary, {
                                type: "binary"
                            });
                        }
                        outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                        
                        //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
                        //此处可对数据进行处理
                        let arr = [];
                        let arr2 = [];
                        outdata.map(v => {
                            let mac = v["后端码"];
                            if (mac != "") {
                                arr.push(mac);

                            }
                        });
                        _this.tableData = arr;
                        let anblen = ""
                        for (let index = 0; index < _this.tableData.length; index++) {
                            const element = _this.tableData[index].toString();
                            // console.log(element) //23
                            var l = element.length;
                            var blen = 0;
                            for (i = 0; i < l; i++) {
                                if ((element.charCodeAt(i) & 0xff00) != 0) {
                                    blen++;
                                }
                                blen++;
                            }
                            // console.log(blen)
                            if (blen < 4) {
                                anblen = '1'
                            } else {
                                _this.$message.error('后端码长度超过三位');
                                _this.tableData = []
                                _this.fileList = []
                            }
                        }
                        if (anblen == '1') {
                            _this.$message({
                                type: "success",
                                message: "导入成功，共导入" + arr.length + "个有效设后端码"
                            });
                        }

                        console.log(_this.tableData)

                        return arr;
                    };
                    reader.readAsArrayBuffer(f);
                };
                if (rABS) {
                    reader.readAsArrayBuffer(f);
                } else {
                    reader.readAsBinaryString(f);
                }
            },

            //      strlen(str){
            //     var len = 0;
            //     for (var i=0; i<str.length; i++) {
            //      var c = str.charCodeAt(i);
            //     //单字节加1
            //      if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {
            //        len++;
            //      }
            //      else {
            //       len+=2;
            //      }
            //     }
            //     console.log(len)
            //     return len;
            // },
            // 导出
            goodsExport() {
                //    let queryDo = {
                //   exchangeCode: this.exchangeCode,
                //   goodsId: this.goodsId,
                //   codeStatus: this.codeStatus,
                //   createTimeStart: this.createTimeStart,
                //   createTimeEnd: this.createTimeEnd,
                //   validityTimeStart: this.validityTimeStart,
                //   validityTimeEnd: this.validityTimeEnd,
                // }
                //   console.log(queryDo );  

                this.$api
                    .CodeExport({
                        query: {
                            batchId: this.batchId
                        },
                        properties: [
                            "exchangeCode",
                            "validityTimeStart",
                            "validityTimeEnd",
                            // "codeStatus",
                            "goodsName",
                            "createTime",
                        ],
                        titles: [
                            "兑换码",
                            "有效期开始",
                            "有效期截止",
                            // "兑换情况",
                            "商品名称",
                            "创建时间",
                        ]
                    })
                    .then(res => {
                        console.log(res.data);
                        for (let index = 0; index < this.goodsList.length; index++) {
                            if (this.ruleForm.goodsId == this.goodsList[index].id) {
                                this.goodName = this.goodsList[index].name
                            }
                            // console.log(this.goodName)
                        }
                        this.download(res.data);
                    })
                    .catch(error => {});
            },
            //导出函数
            download(data) {


                if (!data) {
                    return;
                }
                let url = window.URL.createObjectURL(
                    new Blob([data], {
                        type: "application/octet-binary"
                    })
                );
                let link = document.createElement("a");
                link.style.display = "none";
                link.href = url;
                link.setAttribute("download", this.goodName + "excel.xls");

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            },

        },
        beforeDestroy() {
            //   clearTimeout(this.timer);
        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    /deep/.el-upload-list {
        display: inline-block;
    }
     .down_template{
         float: right;
         color: #898989;
         font-size: 0.8rem;
     }
    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .item {
            height: 80px;
            width: 80px;
            border: 1px solid blue;
            float: left;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            //   margin-top: 7px;
            margin-left: 15px;
        }

        .detail {
            padding: 0rem 3rem;
            margin-top: 1rem;

            .detail-span {
                font-size: 12 /16rem;
                color: #898989;
            }

            .detail-vv {
                margin-top: 5 /16rem;
                font-size: 14 /16rem;
            }
        }

        .upload-demo {
            margin-left: 0.5em;
            display: inline-block;
        }

    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-pagination {
        margin: 1rem 0;
        float: right;
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    //   .bg-purple {
    //     background: #d3dce6;
    //   }
    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .stepShow {
        width: 80%;
        margin: 3rem auto;

        .downloadList {
            //  transform:translate(5.5rem, -4rem)
        }

        /deep/.el-step.is-horizontal.stepActive {
            .el-step__head.is-finish {
                .el-step__line {

                    // 当前步骤数横线样式设置
                    .el-step__line-inner {
                        width: 50% !important;
                        border-width: 1px !important;
                    }
                }

                // 当前步骤数圆圈样式设置
                .el-step__icon.is-text {
                    background: #409eff;
                    color: #fff;
                }
            }
        }
    }

    .outting .item {
        margin: 10px;
        height: auto;
        width: auto;
        cursor: pointer;
        border: none;
    }

    .right .el-tooltip__popper {
        padding: 8px 10px;
        border: none;
        cursor: pointer;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>