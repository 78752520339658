<template>
    <div class="smokeLl">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click.native="backTo">
                    {{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"产品中心"}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color:#f28c02">
                        产品详情
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="major_service">
            <div class="item-title">
                <p> 控烟探测器</p>
                <div>
                    <img src="../../../assets/login/6103L.png" alt="" class="image">
                    <span class="text_list" style="margin-bottom:30px">
                        (WSH6103L)
                    </span>
                </div>
                <div class="level_subject">
                    产品特点
                </div>
                <div class="text_subject">
                    <ul>
                        <li> 1.采用光电式感烟探测技术，安全性能卓越</li>
                        <li>2.高灵敏，高可靠性控烟探测报警器</li>
                        <li>3.响应迅速，高分贝警讯输出报警</li>
                        <li>4.耗电极微，正常情况下单节电池可持续检测长达3年</li>
                        <li>5.电池防漏装设计，安装快速方便</li>
                        <li>6.使用手机APP可实时接收状态信息</li>

                    </ul>
                </div>
                <div class="level_subject">
                    主要用途及适用范围
                </div>
                <div class="text_subject">
                    本产品主要用来探测吸烟产生的烟雾，适宜安装在学校、医院、图书馆等禁烟场所，最佳安装位置为顶棚中心。本产品不宜安装在厨房、浴室等烟尘、水气密集的场所，且应避免毗邻壁炉、通风口等处。另外，应尽量偏离霓虹灯、电子节能灯等灯具设备（安全距离宜大于50厘米）。
                </div>
                <div class="level_subject">
                    工作原理
                </div>
                <div class="text_subject">
                    本产品由迷宫，控制芯片及其外围电路组成。能够实时监测烟雾浓度，对环境进行周期性检测。一旦检测到烟雾，并达到预设浓度值则立即报警。
                </div>
                <div class="level_subject">
                    性能参数
                </div>
                <div class="text_subject">
                    <ul>
                        <li>1.电源：DC3V（锂电池CR17450</li>
                        <li>2.指示灯：红色（报警</li>
                        <li>3.最大风速：5m/s</li>
                        <li>4.报警音量：＞80dB(A)/3m </li>
                        <li>5.环境温度：-10℃ ~ 50℃</li>
                        <li>6.相对湿度：≤95%（40±2℃）</li>
                        <li>7.外形尺寸：直径100mm,高59mm</li>
                        <li>8.材料颜色：ABS 白色</li>
                        <li>9.重量：125g(不含电池)</li>

                    </ul>
                </div>
          <div class='botom_tit'>   *如需获取产品3c证书、检验报告、说明书等，
               可扫描底部公众号二维码进入“吻胜智能生活”公众号获取</div>
            </div>

            <div>

            </div>
        </div>


    </div>
</template>
<script>
import '../../../../static/css/global.css'
    export default {
        name: "smokeLl",
        components: {},
        data() {
            return {

            };
        },
        mounted() {
            window.addEventListener('scroll', this.scrollToTop)
            const that = this
            let ispeed = Math.floor(-that.scrollTop / 5)
            document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        },
        created() {},
        methods: {
            scrollToTop() {
                const that = this
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                that.scrollTop = scrollTop
                that.btnFlag = false
            },
            backTo() {
                if (this.$route.query.crumbsTitle == '首页') {
                    let vm = this
                    vm.$router.replace({
                        path: `/`
                    });
                } else {
                    this.$router.replace({
                        path: "/productCenter"
                    });
                }
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTop)
        },
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1370px) {
        .smokeLl {
               background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;
            .text_subject{
                 font-size: 1.05rem;
                 line-height: 1.8rem;
                  padding-bottom: 1rem;
                    padding-top: 1.5rem;
            }
             .level_subject{
                font-size: 1.15rem; 
            }
        }
    }

    @media screen and (min-width: 1370px) {
        .smokeLl {
             background-color: #efefef;
            overflow-x: auto;
            min-width: 1300px;
             .text_subject{
                 font-size: 1.25rem;
                 line-height: 2.5rem;
                  padding-bottom: 1.5rem;
                    padding-top: 2rem;
            }
            .level_subject{
                font-size: 1.35rem; 
            }
        }
    }

    .breadcrumb {
        padding: 2rem 0 2rem 0;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;

        /deep/.el-breadcrumb {
            float: right;
        }

        /deep/.el-breadcrumb__inner:hover {
            color: #f28c02;
        }
    }
 .botom_tit{
      text-align: left;
      width: 60%;
       margin: 0 auto;
         padding-bottom:  4rem;
         padding-left:  2rem;
  }
    .major_service {
        width: 100%;
        padding-bottom: 20px;

        .item-title {
            padding-top: 2rem;
            text-align: center;
            // color: #333333;
            width: 100%;
            margin: 0 auto;

            .image {
                width: 10%;
                display: block;
                margin: 4rem auto;
            }

            p {
                text-shadow: 1px 1px 1px #666;
                display: inline-block;
                color: #333333;
                padding: 0 50px;
                font-size: 1.5em;
            }

            .text_list {
                display: inline-block;
                transform: translateY(-2rem);
              font-size: 1rem; 
                color: #333333;
            }

           .level_subject {
                text-align: left;
                width: 60%;
                letter-spacing: 4px;
                margin: 0 auto;
            //    font-size: 1.35rem; 
                color: #333333;
                font-weight: 600;
            }

            .text_subject {
                display: inline-block;
            //  font-size: 1.25rem;
                
                text-align: justify;
                width: 60%;
              
                margin: 0 auto;
                // text-align: left;
                color: #333333;
               
                // border: #f28c02 1px solid;
         
                img {
                    width: 100%;

                }

            }

        }


    }
</style>