<template>
	<div>12345</div>
</template>
<script>
	export default {
		name: 'RoleManagement',

	}
</script>
<style lang="less" scoped>

</style>
