<template>
    <div class="carbonMonoxide">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click.native="backTo">{{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"产品中心"}}
                    
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color:#f28c02">
                        产品详情
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="major_service">
            <div class="item-title">
                <p>家用可燃气体探测器（一氧化碳CO）</p>
                <div>
                    <img src="../../../assets/login/6201.png" alt="" class="image">
                    <span class="text_list" style="margin-bottom:30px">
                        (JT-WSH6201)
                    </span>
                   

                </div>
                <div class="level_subject">
                    概述
                </div>
                <div class="text_subject">
                    本产品为家用可燃气体探测器（以下简称探测器），用于探测可燃气体（一氧化碳CO）的泄露，当浓度达到报警动作值时，发出报警和控制信号。提示相关人员及时采取措施排除隐患，实现早期预防，避免火灾、人生伤亡事故的发生。
                   适用于使用管道煤气、人工煤气或一氧化碳气体产生和聚集的场所或其他散发可燃气体和可燃蒸汽的场所。

                </div>
                <div class="level_subject">
                    产品特点
                </div>
                <div class="text_subject">
                    探测器为独立式安装设备，内置优质电化学气敏元件、高性能微处理器 ，采用SMT表面贴装工艺，可靠性高，一致性好。同时探测器还具有灵敏度高、抗酒精、温湿度变化影响能力强等优点。
                </div>
                <div class="level_subject">
                    适用范围
                </div>
                <div class="text_subject">
                    <ul>
                        <li> · <span>探测器安装于现场，用于监测现场气体（一氧化碳）泄漏，在检测到一氧化碳气体并达到预设值后，指示灯红灯常亮并有蜂鸣声。</span> </li>
                        <li> · <span>设计遵照《家用可燃气体探测器》GB 15322.2-2019国家标准。</span> </li>
                        <li> · <span>适用于厂房、仓库、住宅等场所对可燃气体泄漏的安全监测。</span> </li>

                    </ul>
                </div>
                <div class="level_subject">
                    工作原理
                </div>
                <div class="text_subject">
                    探测器由传感器、控制芯片及其外围电路组成。能够实时监测一氧化碳气体，对环境进行实时性检测，一旦检测到一氧化碳气体，并且达到预定浓度值后，指示灯红常亮并伴有蜂鸣声。
                </div>
                <div class="level_subject">
                    性能参数
                </div>
                <div class="text_subject">
                    <img src="../../../assets/login/details6201.png" alt="" class="image">
                </div>
                <div class='botom_tit'>   *如需获取产品3c证书、检验报告、说明书等，可扫描底部公众号二维码进入“吻胜智能生活”公众号获取</div>
            </div>

            <div>

            </div>
        </div>


    </div>
</template>
<script>
 import '../../../../static/css/global.css'
    export default {
        name: "carbonMonoxide",
        components: {},
        data() {
            return {

            };
        },
        mounted() {
            // window.addEventListener('scroll', this.scrollToTop)
            // const that = this
            // let ispeed = Math.floor(-that.scrollTop / 5)
            // document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
              document.querySelector(".carbonMonoxide").scrollTop = 0
         
          // firefox浏览器中是这句
          document.querySelector(".carbonMonoxide").scrollTop = 0

        },
        created() {},
        methods: {
            scrollToTop() {
                const that = this
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                that.scrollTop = scrollTop
                that.btnFlag = false
            },
            backTo() {
                if (this.$route.query.crumbsTitle == '首页') {
                    let vm = this
                    vm.$router.replace({path: `/`}); } else {
                    this.$router.replace({
                        path: "/productCenter"
                    });
                }
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTop)
        },
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1370px) {
        .carbonMonoxide {
            background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;
            .text_subject{
                 font-size: 1.05rem;
                 line-height: 1.8rem;
                //   padding-bottom: 3rem;
                    padding-top: 1.5rem;
            }
             .level_subject{
                font-size: 1.15rem; 
            }
        }
    }

    @media screen and (min-width: 1370px) {
        .carbonMonoxide {
            background-color: #efefef;
            overflow-x: auto;
            min-width: 1300px;
             .text_subject{
                 font-size: 1.25rem;
                 line-height: 2.5rem;
                  padding-bottom: 0.5rem;
                    padding-top: 2rem;
            }
            .level_subject{
                font-size: 1.35rem; 
            }
        }
    }

    .breadcrumb {
        padding: 2rem 0 2rem 0;
        width: 60%;
        margin: 0 auto;
          cursor: pointer;
        /deep/.el-breadcrumb {
            float: right;

        }

        /deep/.el-breadcrumb__inner:hover {
            color: #f28c02;
        }
    }
 .botom_tit{
      text-align: left;
      width: 60%;
       margin: 0 auto;
         padding-bottom:  4rem;
         padding-left:  2rem;
  }
    .major_service {
        width: 100%;
        padding-bottom: 20px;

        .item-title {
            padding-top: 2rem;
            text-align: center;
            // color: #333333;
            width: 100%;
            margin: 0 auto;

            .image {
                width: 20%;
                display: block;
                margin: 0 auto;
            }

            p {
                text-shadow: 1px 1px 1px #666;
                display: inline-block;
                color: #333333;
                padding: 0 50px;
                 font-size: 1.5em;
            }

            .text_list {
                display: inline-block;
                transform: translateY(-2rem);
                  font-size: 1rem; 
                color: #333333;
            }

            .level_subject {
                text-align: left;
                width: 60%;
                letter-spacing: 4px;
                margin: 0 auto;
            //    font-size: 1.35rem; 
                color: #333333;
                font-weight: 600;
            }

            .text_subject {
                display: inline-block;
            //  font-size: 1.25rem;
                
                text-align: justify;
                width: 60%;
              
                margin: 0 auto;
                // text-align: left;
                color: #333333;
               
                // border: #f28c02 1px solid;
         
                img {
                    width: 100%;

                }

            }

        }


    }
</style>