<template>
    <div>
        <div class="outting">
            <div class="select">
                <span class="title">商品修改</span>
                <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回商品列表</el-button>
            </div>
            <div class="detail">
                <el-card class="box-card" shadow="never">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">

                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="商品名称" prop="name">
                                    <el-input v-model.trim="ruleForm.name" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品名称"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="商品编码" prop="name">
                                    <el-input v-model.trim="ruleForm.goodsNo" style="width:90%;margin-bottom:10px"
                                              placeholder="请输入商品编码"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                                <el-form-item label="商品原价" prop="oriPrice">
                                    <el-input v-model.trim="ruleForm.oriPrice" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品原价"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                   <el-row>
                            <el-col :span="10">
                                <el-form-item label="商品实际价格" prop="actualPrice">
                                    <el-input v-model.trim="ruleForm.actualPrice" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品实际价格"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                             <el-form-item label="商品库存" prop="stock">
                                    <el-input v-model.number="ruleForm.stock" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品库存"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>


                      <el-row>
                        <el-col :span="10">
                          <el-form-item label="销量范围开始值" prop="salesVolumeStart">
                            <el-input v-model.trim="ruleForm.salesVolumeStart" style="width:90%;margin-bottom:10px"
                                      placeholder="请输入销量范围开始值"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="7" :offset="0">
                          <el-form-item label="销量范围结束值" prop="salesVolumeEnd">
                            <el-input v-model.number="ruleForm.salesVolumeEnd" style="width:90%;margin-bottom:10px"
                                      placeholder="请输入销量范围结束值"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="3" :offset="0">
                          <el-form-item label="是否展示随机销量" prop="salesVolumeEnd">
                            <el-switch
                                v-model="ruleForm.salesVolumeIsRandom"
                                active-color="#13ce66"
                                inactive-color="#9a9a9a">
                            </el-switch>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="10">
                          <el-form-item label="销量显示值" prop="salesVolumeShow">
                            <el-input v-model.trim="ruleForm.salesVolumeShow" style="width:90%;margin-bottom:10px"
                                      placeholder="请输入销量显示值"></el-input>
                          </el-form-item>
                        </el-col>

                        <el-col :span="10">
                          <el-form-item label="安装视频链接" prop="salesVolumeShow">
                            <el-input v-model.trim="ruleForm.installVideoUrl" style="width:90%;margin-bottom:10px"
                                      placeholder="请输入安装视频链接"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                          <el-row>
                            <el-col :span="10">
                                <el-form-item label="已兑换数量" prop="newsTitle">
                                    <el-input v-model="ruleForm.exchangeAmount"  disabled style="width:90%;margin-bottom:10px"
                                        placeholder="">
                                        </el-input>

                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                                <el-form-item label="发货时间" prop="deliveryTime">
                                    <el-input v-model.trim="ruleForm.deliveryTime" style="width:90%;margin-bottom:10px"
                                        placeholder="请填写发货时间"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                            <el-row>
                            <el-col :span="10">
                                <el-form-item label="上架时间" prop="shelfTime">
                                     <el-date-picker style="width:90%;margin-bottom:10px" clearable v-model="ruleForm.shelfTime"
                                          range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                                          value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
                                          type="datetimerange" >

                                     </el-date-picker>

                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="10" :offset="0">
                                <el-form-item label="下架时间" prop="offShelfTime">
                                     <el-date-picker v-model="ruleForm.offShelfTime" value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col> -->
                        </el-row>
                            <el-row>
                            <el-col :span="10">
                                <el-form-item label="商品列表图片"  ref="formImg"  prop="colFile">
                                    <el-upload action="#"  :before-upload="beforeAvatarUpload" :on-change="handleChange" list-type="picture-card" v-show="!value"
                                        :show-file-list="false">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt />
                                    </el-dialog>
                                    <div v-show="value" class="el-upload-list el-upload-list--picture-card">
                                        <div class="el-upload-list__item is-success">
                                            <img  width="100%" v-show="value" :src="value"  />
                                            <label class="el-upload-list__item-status-label">
                                                <i class="el-icon-upload-success el-icon-check"></i>
                                            </label>
                                            <span class="el-upload-list__item-actions">
                                                <span class="el-upload-list__item-preview">
                                                    <i class="el-icon-zoom-in"
                                                        @click="handlePictureCardPreview()"></i>
                                                </span>
                                                <span class="el-upload-list__item-delete">
                                                    <i class="el-icon-delete" @click.stop="handleRemove"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>
                              <el-col :span="10">
                                <el-form-item label="上传视频" ref="formVideo">
                                  <el-upload
                                      v-show="!videoPath"
                                      action
                                      class="upload-demo"
                                      ref="video"
                                      :before-upload="beforeUploadVideo"
                                      :limit="1"
                                      :auto-upload="false"
                                      :on-change="UploadVideo"
                                      :on-exceed="handleExceed"
                                      drag>
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">
                                      将文件拖到此处，或<em>点击上传</em>
                                    </div>
                                  </el-upload>
                                  <el-progress
                                      v-show="progressFlag"
                                      :percentage="loadProgress"
                                  ></el-progress>
                                  <video v-if="videoPath !='' && videoPath != null && videoPath != undefined"
                                         :src="videoPath"
                                         class="avatar"
                                         controls="controls">您的浏览器不支持视频播放</video>
                                  <el-button @click="clearVideo()" v-if="videoPath !='' && videoPath != null && videoPath != undefined"  type="primary" round size="mini" class="input-button">替换视频</el-button>
                                </el-form-item>

                              </el-col>

                              <el-col :span="10" :offset="0">
                                <el-form-item label="商品详情图片" ref="formImg3">
                                  <div v-for="(item,index) in fileList" style="display: inline-block;">
                                    <el-upload :on-change="(file, fileList) => {handleChange2(file, fileList, index)}" list-type="picture-card"
                                               :show-file-list="false" v-show="!item.url" action="#" style="margin-right: 8px"
                                               :auto-upload="false">
                                      <i class="el-icon-plus" ></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible2">
                                      <img width="100%" :src="dialogImageUrl2" alt />
                                    </el-dialog>
                                    <div  class="el-upload-list el-upload-list--picture-card" v-show="item.url">
                                      <div class="el-upload-list__item is-success">
                                        <img  width="100%"  :src="item.url" v-show="item.url" />
                                        <label class="el-upload-list__item-status-label">
                                          <i class="el-icon-upload-success el-icon-check"></i>
                                        </label>
                                        <span class="el-upload-list__item-actions">
                                                <span class="el-upload-list__item-preview">
                                                    <i class="el-icon-zoom-in"
                                                       @click="handlePictureCardPreview2(item)"></i>
                                                </span>
                                                <span class="el-upload-list__item-delete">
                                                    <i class="el-icon-delete" @click="handleRemove2(index)"></i>
                                                </span>
                                            </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div style="display:inline-block;">
                                    <el-upload
                                        :on-change="(file, fileList) => {handleChange2(file, fileList, -1)}"
                                        list-type="picture-card"
                                        :show-file-list="false"  action=""
                                        :auto-upload="false" style="margin-right: 8px">
                                      <el-button type="primary" round size="mini" class="input-button">上传附件</el-button>
                                                                              <br />
                                  </el-upload></div>
                                </el-form-item>
                              </el-col>

                        </el-row>
                        <el-form-item label="商品详情" prop="details">
                            <editor-bar  :uri='uri' v-model="ruleForm.details" :isClear="isClear" @change="change"></editor-bar>
                        </el-form-item>
                        <el-row>
                            <el-col :span="10" style="margin-top:50px;margin-left:30%">
                                <el-form-item class="footer">>
                                    <el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>

                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
import EditorBar from "../../components/appWangEnduit";

import E from "wangeditor";
import axios from "@/utils/http";
import base from "@/api/base";
export default {
  name: "goodsUpdate",
  components: {
    EditorBar
  },
  data() {
    var valiIcon = (rule, value, callback) => {
      if (!this.value) {
        callback(new Error("请上传图片"));
      } else {
        callback();
      }
    };

    var valiIcon2 = (rule, value, callback) => {
      console.log(value);
      if (!this.fileList[0]) {
        callback(new Error("请上传图片"));
      } else {
        callback();
      }
    };
    return {
      videoPath:"",
      progressFlag: false,
      loadProgress: 0,
      formLabelWidth: "120px",
      uri: "",
      detail: "",
      content: "",
      detailFiles: [],
      isClear: false, //清除富文本编辑器内容
      // 默认步骤数
      limitUpload: 5,
      limitUpload2: 1,
      fileList: [],
      fileMulLis: [],
      stepActive: "stepActive",
      dialogImageUrl: "",
      dialogVisible: false,
      value: "",
      addphoto: "",
      file: {},
      dialogImageUrl2: "",
      dialogVisible2: false,
      value2: "",
      file2: {},
      oriUrl: "",
      filesall:[],
      colFile: "",
      ruleForm: {
        name: "",
        oriPrice: "",
        actualPrice: "",
        stock: "",
        exchangeAmount:"",
        deliveryTime: "",
        shelfTime: "",
        offShelfTime: "",
        salesVolumeStart:"",
        salesVolumeEnd:"",
        salesVolumeShow:"",
        installVideoUrl:"",
        salesVolumeIsRandom:false,
        colFile: "",
        details: "",
        goodsNo: "",
        detailFilesjpg: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请填写商品名称",
            trigger: "blur"
          }
        ],
        deliveryTime: [
          {
            required: true,
            message: "请填写发货时间",
            trigger: "blur"
          }
        ],
        shelfTime: [
          { required: true, message: "请选择上架时间", trigger: "change" }
        ],
        offShelfTime: [
          { required: true, message: "请选择下架时间", trigger: "change" }
        ],
        oriPrice: [
          { required: true, message: "请输入商品原价", tigger: "blur" },
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确格式,可保留两位小数"
          }
        ],
        actualPrice: [
          { required: true, message: "请输入商品实际价格", tigger: "blur" },
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确格式,可保留两位小数"
          }
        ],
        stock: [
          { required: true, message: "请输入商品库存" },
          {
            type: "number",
            required: true,
            message: "请填写数字",
            trigger: "blur"
          }
        ],
        colFile: [{ required: true, validator: valiIcon, trigger: "change" }], // 图片验证,
        detailFiles: [
          { required: true, validator: valiIcon2, trigger: "change" }
        ] ,

      }
    };
  },
  created() {
    this.uri = "api/iotServer/goods/addPic";
  },
  mounted() {
    console.log(this.$route.query.data);

    this.$api
      .goodsDetail(this.$route.query.data.id)
      .then(res => {
        console.log(res.data.data);
        this.ruleForm.name = res.data.data.name;
        this.ruleForm.oriPrice = res.data.data.oriPrice;
        this.ruleForm.actualPrice = res.data.data.actualPrice;
        this.ruleForm.goodsNo = res.data.data.goodsNo;
        this.dialogImageUrl = res.data.data.columnPicture;
        this.value = res.data.data.columnPicture;
        console.log(res.data.data.detailPicture.split(","));
        this.detailFilesjpg = res.data.data.detailPicture.split(",");
        this.ruleForm.stock = res.data.data.stock;
        this.ruleForm.exchangeAmount = res.data.data.exchangeAmount;
        this.ruleForm.details = res.data.data.details;
        this.ruleForm.salesVolumeIsRandom = res.data.data.salesVolumeIsRandom == 1;
        this.ruleForm.salesVolumeShow = res.data.data.salesVolumeShow;
        this.ruleForm.salesVolumeEnd = res.data.data.salesVolumeEnd;
        this.ruleForm.salesVolumeStart = res.data.data.salesVolumeStart;
        this.videoPath = res.data.data.videoPicture;
        this.ruleForm.installVideoUrl = res.data.data.installVideoUrl;
        this.detailFilesjpg.forEach(item => {
          let obj = {
            url: item
          };
          this.fileList.push(obj);
        });
        this.ruleForm.shelfTime = [res.data.data.shelfTime,res.data.data.offShelfTime]
        // this.ruleForm.offShelfTime = ;
        this.ruleForm.deliveryTime = res.data.data.deliveryTime;
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    clearVideo(){
      this.videoPath = '';
      this.$refs["video"].clearValidate();
    },
    UploadVideo(file) {
      this.progressFlag = true;
      this.loadProgress = 0;

      let formData = new FormData();
      formData.append("multipartFiles",file.raw,file.name)
      axios.post(base.baseUrl + base.GoodsVideoUpload, formData,{
        headers: {
          "content-Type": 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          if(progressEvent.lengthComputable){
            let val = parseInt((progressEvent.loaded / progressEvent.total) * 100 ).toFixed(0)
            this.loadProgress = Math.floor(val)-1;
          }

        }
      }).then((res) => {
        this.videoPath = res.data.data
        this.progressFlag = false;
        this.loadProgress = 100;
        // this.$message({
        //   type: "success",
        //   message: res.data.data
        // });
      });

    },

    UploadFile(file,index) {


      let formData = new FormData();
      formData.append("multipartFiles",file.raw,file.name)
      axios.post(base.baseUrl + base.GoodsVideoUpload, formData,{
        headers: {
          "content-Type": 'multipart/form-data',
        },
      }).then((res) => {
        if(index == -1){
          this.fileList.push({url:res.data.data})
        }else {
          this.fileList[index].url = res.data.data
        }

      });

    },
    beforeUploadVideo(file) {
      const isLt30M = file.size / 1024 / 1024 < 50;
      if (
          [
            "video/mp4",
            "video/ogg",
            "video/flv",
            "video/avi",
            "video/wmv",
            "video/rmvb",
          ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt30M) {
        this.$message.error("上传视频大小不能超过50MB哦!");
        return false;
      }
    },

    beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 0.50;
        if (!isLt2M) {
                     console.log('2200')
          this.$message.error('上传图片大小不能超过500KB!');
           this.value = ''
        }
        return  isLt2M;
      },

    beforeAvatarUpload2(file) {

      const isLt2M = file.size / 1024 / 1024 < 0.50;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过500KB!');
        // this.value = ''
      }
      return  isLt2M;
    },
    change(val) {
      // console.log(val);
      this.ruleForm.newsContent = val;
    },
    submitForm(ruleForm) {

      this.$refs[ruleForm].validate(valid => {
        this.ruleForm.colFile = this.file;
        this.ruleForm.detailFiles = this.filesall;
        let formData = new FormData();
//  单张图片是否删除
        if (this.value == this.dialogImageUrl) {
              console.log("没有替换");
        }else{
              formData.append("colFile",this.ruleForm.colFile);
        }

        // if (this.addphoto === 1) {
        //
        //   console.log("有新增");
        //     formData.append("goodsDetailPicDto.oriUrl", this.oriUrl);
        //   this.ruleForm.detailFiles.forEach(file => {
        //     formData.append("goodsDetailPicDto.files", file.raw); //此处一定是append file.raw 上传文件只需维护fileList file.raw.name要加上
        //   });
        // } else if (this.addphoto === 2) {
        //   formData.append("goodsDetailPicDto.oriUrl", this.oriUrl);
        //   console.log("有删除");
        // } else {
        //   console.log("无新增，无删除");
        //   //  formData.append("goodsDetailPicDto.files", null)
        //
        //
        // }
        let detailImages = [];
        this.fileList.forEach(item => {
          if(item.url != ""){
            detailImages.push(item.url)
          }
        });
          formData.append("detailPicUrls", detailImages);
          formData.append("id", this.$route.query.data.id);
          formData.append("name", this.ruleForm.name);
          formData.append("goodsNo", this.ruleForm.goodsNo);
          formData.append("oriPrice", this.ruleForm.oriPrice);
          formData.append("actualPrice", this.ruleForm.actualPrice);
          formData.append("details", this.ruleForm.details);
          formData.append("stock", this.ruleForm.stock);
          formData.append("shelfTime", this.ruleForm.shelfTime[0]);
          formData.append("offShelfTime", this.ruleForm.shelfTime[1]);
          formData.append("deliveryTime", this.ruleForm.deliveryTime);
        formData.append("salesVolumeShow", this.ruleForm.salesVolumeShow == null?"":this.ruleForm.salesVolumeShow);
        formData.append("salesVolumeEnd", this.ruleForm.salesVolumeEnd == null?"":this.ruleForm.salesVolumeEnd);
        formData.append("salesVolumeStart", this.ruleForm.salesVolumeStart == null?"":this.ruleForm.salesVolumeStart);
        formData.append("salesVolumeIsRandom", this.ruleForm.salesVolumeIsRandom == true ?1:0);
        formData.append("installVideoUrl", this.ruleForm.installVideoUrl);
        formData.append("videoPicture", this.videoPath);
        // console.log(this.ruleForm);
        if (valid) {
          this.$api.goodsUpdate(formData).then(res => {
            if (res.data.code == 200) {
              this.$message.success("更新成功!");
              this.$nextTick(() => {
                this.$router.push("/mallList");
                console.log("00000");
                this.$refs["ruleForm"].clearValidate();
                this.value = "";
                this.value2 = "";
                this.file = {};
                this.file2 = {};
                this.fileList = [];
                this.ruleForm.newsSubtitle = "";
                this.ruleForm.newsTitle = "";
                this.ruleForm.releaseTime = "";
                this.ruleForm.contentUrl = "";
                this.ruleForm.newsContent = "";
              });
            }
          });
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    handleChange(file, fileList) {
      // console.log(file.raw, fileList);
      this.value = file.url;
      this.file = file.raw;
      if (file.raw) {
        this.$nextTick(() => {
          //等待照片上传完成，
          this.$refs["formImg"].clearValidate();
        });
      }
    },

    handleChange2(file, fileList,index) {
      if(this.beforeAvatarUpload2(file)){
        this.UploadFile(file,index)
      }




    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },


    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.clearVideo()
    },
    //回到模块页面
    gobackPlaceDetail() {
      this.$router.push("/mallList");
    },
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      // this.fileList = fileList;
      this.value = "";
      this.file = {};
    },

    handleRemove2(index) {
        this.fileList[index].url = ""
    },


    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.footer{
  position: fixed;
  bottom: 0;
left:40%;
//   width:30%;
  line-height: var(--footer-height);
//   background: #42b983;
  color: #fff;
}
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;

  .item {
    height: 80px;
    width: 80px;
    border: 1px solid blue;
    float: left;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }

  .detail {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .detail-span {
      font-size: 12 /16rem;
      color: #898989;
    }

    .detail-vv {
      margin-top: 5 /16rem;
      font-size: 14 /16rem;
    }
  }
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

//   .bg-purple {
//     background: #d3dce6;
//   }
.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.stepShow {
  width: 80%;
  margin: 3rem auto;

  .downloadList {
    //  transform:translate(5.5rem, -4rem)
  }

  /deep/.el-step.is-horizontal.stepActive {
    .el-step__head.is-finish {
      .el-step__line {
        // 当前步骤数横线样式设置
        .el-step__line-inner {
          width: 50% !important;
          border-width: 1px !important;
        }
      }

      // 当前步骤数圆圈样式设置
      .el-step__icon.is-text {
        background: #409eff;
        color: #fff;
      }
    }
  }
}

.outting .item {
  margin: 10px;
  height: auto;
  width: auto;
  cursor: pointer;
  border: none;
}

.right .el-tooltip__popper {
  padding: 8px 10px;
  border: none;
  cursor: pointer;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
