<template>
  <div class="outting">
    <div class="select">
      <span class="title">平台日志</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-bottom"
        @click="playShow"
        class="serachRoleButton"
      >高级查询</el-button>
      <div class="select-input-right-input">
        <el-input
          v-model="operatorName"
          prefix-icon="el-icon-search"
          placeholder="请输入账号名"
          size="small"
          @keyup.enter.native="search"
        ></el-input>
      </div>
    </div>
    <div class="gaojiSearch" v-show="ifSearchMany">
      <div style="width:100%;overflow: hidden;">
        <div style="width:12rem;float:left;">
          <div class="grid-content bg-purple-dark">
            <span>接口返回码</span>
            <div style="margin-top:5px;">
              <el-input
                v-model="methodResultCode"
                prefix-icon="el-icon-search"
                placeholder="请输入接口返回码"
                size="small"
              ></el-input>
            </div>
          </div>
        </div>
        <div style="width:12rem;float:left;margin-left:1.5rem;">
          <div class="grid-content bg-purple-dark">
            <span>操作类型</span>
            <div style="margin-top:5px;">
              <el-select
                v-model="operatorType"
                placeholder="请选择操作类型"
                class="select-status"
                @change="changeHandler"
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div style="width:12rem;float:left;margin-left:1.5rem;">
          <div class="grid-content bg-purple-dark">
            <span>接口返回描述</span>
            <div style="margin-top:5px;">
              <el-input
                v-model="methodResultMsg"
                prefix-icon="el-icon-search"
                placeholder="请输入接口返回描述"
                size="small"
              ></el-input>
            </div>
          </div>
        </div>
        <div style="width:12rem;float:left;margin-left:1.5rem;">
          <div class="grid-content bg-purple-dark">
            <span>操作方法</span>
            <div style="margin-top:5px;">
              <el-input
                v-model="requestMethod"
                prefix-icon="el-icon-search"
                placeholder="请输入操作方法"
                size="small"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;margin-top:1rem;">
        <div class="grid-content bg-purple-dark">
          <span>操作时间</span>
          <div style="margin-top:5px;">
            <el-date-picker
              size="small"
              class="update-input-timer-select"
              v-model="value"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="~"
              start-placeholder="开始"
              end-placeholder="结束"
              align="right"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00','23:59:59']"
              @change="getTimeBlock"
              style="width:25.5rem;"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;margin-top:1rem;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="checkHandler">搜索</el-button>
            </div>
            <div class="emptyBut">
              <el-button size="small" @click="emptyHandler">清空</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhanghuTable">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column prop="requestDesc" label="操作描述" min-width="1" align="center"></el-table-column>
        <el-table-column prop="requestMethod" label="操作方法" min-width="2" align="center"></el-table-column>
        <el-table-column prop="operatorType" label="操作类型" min-width="1" align="center"></el-table-column>
        <el-table-column prop="clientIp" label="客户端IP" min-width="1" align="center"></el-table-column>
        <el-table-column prop="methodResultCode" label="接口返回码" min-width="1" align="center"></el-table-column>
        <el-table-column prop="methodResultMsg" label="接口返回描述" min-width="1" align="center"></el-table-column>
        <el-table-column prop="operatorName" label="操作账号名" min-width="1" align="center"></el-table-column>
        <el-table-column prop="operatorId" label="操作账号id" min-width="1" align="center"></el-table-column>
        <el-table-column prop="createTime" label="操作时间" min-width="1" align="center"></el-table-column>
        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-info"
              @click="handleDetail(scope.$index, scope.row)"
            >查看参数</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        class="pagin"
        @current-change="changeCurrent"
      ></el-pagination>
      <el-dialog title="参数详情" :visible.sync="dialogVisible" :lock-scroll="false">
        <div style="display:flex; justify-content:flex-start;flex-wrap: wrap;">
          <div
            style="margin-bottom:5%;overflow:hidden;width:100%;"
            v-for="(item,index) in canshu"
            :key="index"
          >
          <div style="margin-top:1.5rem;float:left;margin-right:2%;width:23%"  v-for="(val, key, i) in item"  :key="i">
            <div>
               <span class="new-span">参数名</span>
            <div class="new-vv" style="font-size:12px">{{key}}</div>
               </div>
           <div style="margin-top:10px">
               <span class="new-span">参数值</span>
            <div class="new-vv" style="font-size:12px">{{val}}</div>
               </div>
           </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppLog",
  data() {
    return {
      operatorName: "", //账号名
      tableData: [], //表格数据
      ifSearchMany: false, //展示高级搜索
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      startTime: "",
      endTime: "",
      methodResultMsg: "", //操作结果描述
      requestMethod: "", //请求操作方法
      methodResultCode: "", //接口返回码
      options: [
        { value: 0, label: "增加" },
        { value: 1, label: "删除" },
        { value: 2, label: "修改" },
        { value: 3, label: "查询" }
      ],
      operatorType: "", //操作类型
      current: 1,
      total: 0,
      dialogVisible: false,
      canshu: []
    };
  },
  methods: {
    handleDetail(index, row) {
      this.canshu = JSON.parse(row.requestParam);
      this.dialogVisible=true
    },
    //账号名查询函数
    search() {
      this.getWeblogList();
    },
    //高级搜索函数
    playShow() {
      if (this.ifSearchMany == false) {
        this.ifSearchMany = true;
      } else {
        this.ifSearchMany = false;
      }
    },
    getTimeBlock() {
      if (this.value) {
        this.startTime = this.value[0];
        this.endTime = this.value[1];
      } else {
        this.startTime = "";
        this.endTime = "";
      }
    },
    //列表请求函数
    getWeblogList() {
      this.$api
        .getLogListUrl({
          page: {
            current: this.current,
            size: 10
          },
          query: {
            operatorName: this.operatorName,
            methodResultMsg: this.methodResultMsg,
            requestMethod: this.requestMethod,
            methodResultCode: this.methodResultCode,
            operatorType: this.operatorType,
            startTime: this.startTime,
            endTime: this.endTime
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    //操作类型函数
    changeHandler(index) {},
    checkHandler() {
      this.getWeblogList();
    },
    emptyHandler() {
      (this.startTime = ""),
        (this.endTime = ""),
        (this.operatorName = ""),
        (this.methodResultMsg = ""),
        (this.requestMethod = ""),
        (this.methodResultCode = ""),
        (this.operatorType = "");
      this.value = "";
    },
    changeCurrent(id) {
      this.current = id;
      this.getWeblogList();
    }
  },
  created() {
    this.getWeblogList();
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .update-input-timer {
      float: right;
      line-height: 46px;
      margin-left: 25px;
      height: 46px;

      .demonstrationTwo {
        float: left;
        font-size: 0.8rem;
      }

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }
  }
  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;
    .searchBut {
      float: right;
      margin-left: 20px;
    }
    .emptyBut {
      float: right;
    }
  }
  .zhanghuTable {
    padding: 0rem 3rem;
    margin-top: 1rem;
  }
  .pagin {
    margin-left: 30%;
    margin-top: 10px;
    float: right;
  }
  .new-span {
    font-size: 12 /16rem;
    color: #898989;
  }

  .new-vv {
    font-size: 13 / 16rem;
    margin-top: 5 /16rem;
  }
}
</style>