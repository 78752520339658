<template>
  <div class="footer">
    <div class="wrap">
      <!--      <el-row :gutter="0">-->
      <div class="top_title">
        <h3 @click="switching">关于我们</h3>
        <h3>技术支持</h3>
        <h3 @click="switchingCp">了解产品</h3>
            <h3>友情链接</h3>
        <h3>联系我们</h3>

      </div>
      <div class="bottom_list">
        <div style="width:70%">
          <div class="bottom_list_title">
            <!-- <p> -->
              <a @click="switching">公司介绍</a>
            <!-- </p> -->
            <p @click="switchingJj">
              解决方案
            </p>
            <p @click="productCategory('烟雾报警器')">
              烟雾报警器
            </p>
              <a href="http://www.jbufa.com/" >青鸟消防</a>
            <p style="min-width:8rem">400-0692-119 </p>
            <!-- <p > -->

            <!-- </p> -->
          </div>

          <div class="bottom_list_title">
            <p @click="switchingNew">新闻资讯</p>
            <p @click="loginIn" style="letter-spacing:0.5em;">吻胜云</p>
            <p @click="productCategory('可燃气体报警器')">可燃气体报警器</p>
            <p style="margin-right:1.5rem"></p>
            <p>jiayong@jbufa.com</p>
          </div>
          <div class="bottom_list_title">
            <p></p>
            <p></p>
            <p @click="productCategory('控烟报警器')" >控烟报警器</p>
            <p></p>
            <p style="min-width:12.5rem">北京市海淀区成府路207号</p>
          </div>
          <div class="bottom_list_title">
            <p></p>
            <p></p>
            <p @click="productCategory('应急产品')">应急产品</p>
            <!-- <p >灭<span style="color:#21242b">2</span>火<span style="color:#21242b">2</span>毯</p> -->

            <p></p>
          </div>
          <div class="bottom_list_title">
            <p></p>
            <p></p>
            <p @click="productCategory('礼品专区')">礼品专区</p>
            <p></p>
          </div>

        </div>
        <div class="bottom_footer">
          <div class="footerFive">
            <!-- <img src="../assets/jdsc.png" alt="京东商城" />
            <h4>京东商城</h4> -->
          </div>
          <div class="footerFive">
            <!-- <img src="../assets/tianmao.png" alt="天猫商城" />
            <h4>天猫商城</h4> -->
          </div>
          <div class="footerFive">
            <img src="../assets/app.png" alt="APP" />
            <h4>APP</h4>
          </div>

          <div class="footerFive">
            <img src="../assets/wensheng.jpg" alt="公众号" />
            <h4>公众号</h4>
          </div>
          <div class="footerFive">
            <img src="../assets/xiaochengxu.jpg" alt="小程序" />
            <h4>小程序</h4>
          </div>
        </div>
      </div>

      <!-- <el-row :gutter="0">
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <div style="width: 100%;">
              <div class="footerFour left-item">
                <h3 style="margin-left:0;margin-top:10px;">关于我们</h3>

                <div style="margin-top:0;">
                  <ul style="margin-left:1px;">
                    <li><a href="http://www.jbufa.com/index.php?c=category&catid=31">公司介绍</a></li>
                    <li><a href="http://www.jbufa.com/index.php?c=category&catid=33">资质荣誉</a></li>
                    <li><a href="http://www.jbufa.com/index.php?c=category&catid=32">员工活动</a></li>
                    <li><a href="http://www.jbufa.com/index.php?c=category&catid=34">战略合作</a></li>
                  </ul>
                </div>
              </div>
              <div class="footerFour">
                <h3 style="margin-left:0; margin-top:10px;">技术支持</h3>
                <div style="margin-top:0;">
                  <ul>
                    <li><a href="#">技术服务</a></li>
                    <li><a href="#">解决方案</a></li>
                    <li><a href="#">下载中心</a></li>
                    <li><a href="#">线下门店</a></li>
                  </ul>
                </div>
              </div>
              <div class="footerFour">
                <h3 style="margin-left:0;margin-top:10px;">了解产品</h3>
                <div style="margin-top:0;">
                  <ul>
                    <li><a href="#">灭火器</a></li>
                    <li><a href="#">灭火毯</a></li>
                    <li><a href="#">烟&#12288;感</a></li>
                    <li><a href="#">控&#12288;烟</a></li>
                  </ul>
                </div>
              </div>
                         <div class="footerFour">
                <h3 style="margin-left:0;margin-top:10px;">了解产品</h3>
                <div style="margin-top:0;">
                  <ul>
                    <li><a href="#">灭火器</a></li>
                    <li><a href="#">灭火毯</a></li>
                    <li><a href="#">烟&#12288;感</a></li>
                    <li><a href="#">控&#12288;烟</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <div style="padding-left: 96%;width: 100%;">
              <div class="footerFive">
                <img src='../assets/xiaochengxu.jpg' alt="小程序二维码">
                <h4>小程序二维码</h4>
              </div>

              <div class="footerFive">
                <img src='../assets/app.png' alt="小程序二维码">
                <h4>APP二维码</h4>
              </div>
              <div class="footerFive">
                <img src='../assets/tianmao.jpg' alt="小程序二维码">
                <h4>天猫店二维码</h4>
              </div>
            </div>
          </el-col>

      </el-row>-->
    </div>
    <div class="bottom-vv">
      <div class="inner-vv">
        <div style="width:100%;height:10%;"></div>
        <div style="height:30%;">
          <span>版权所有© 2021 北京青鸟美好生活科技有限公司</span>
        </div>

        <div style="height:40%; margin-top:0.5rem">
          <span>
            <img class="publicSafety" src="../assets/public_safety.png" />
            <a href="http://beian.miit.gov.cn/" target="_blank">京ICP备2021016382号-1</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "FooterNav",
     data() {
      return {

        option: [],

      };
    },
    mounted() {
      window.addEventListener('scroll', this.scrollToTop)
      const that = this
      let ispeed = Math.floor(-that.scrollTop / 5)
      document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
      this.productListForCategory()
    },
    methods: {
        productListForCategory() {
        this.$api
          .productListForCategory()
          .then(res => {
            console.log(res.data.data)
            this.option = res.data.data
          })
          .catch(error => {
            console.log(error);
          });
      },
      // 公司介绍
      switching() {
        this.menu_listones = 5;
        this.$router.push({
          path: "/aboutUs"
        });
      },
      // 新闻资讯
      switchingNew() {
        this.menu_listones = 4;
        this.$router.push({
          path: "/newsInformation"
        });
      },
      // 跳转产品分类
      productCategory(data){

 console.log(data,this.option)
   for (let index = 0; index < this.option.length; index++) {
    if(data == this.option[index].productCategoryName ){
      data = this.option[index].id

    }

   }

        this.menu_listones = 2;
        this.$router.push({
          path: "/productCenter",
           query: {
            data:data,
           }
        });
      },
      //了解产品
      switchingCp() {
        this.menu_listones = 2;
        this.$router.push({
          path: "/productCenter"
        });
      },
      loginIn() {
        this.$router.push({
          path: "/login"
        });
      },
      //解决方案
      switchingJj() {
        this.menu_listones = 3;
        this.$router.push({
          path: "/solution"
        });
      },

      scrollToTop() {
        const that = this
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        that.scrollTop = scrollTop
        that.btnFlag = false
      },
    },
    destroyed() {
      window.removeEventListener('scroll', this.scrollToTop)
    },

  };
</script>
<style lang="less" scoped>
  .footer {
    width: 100%;
    //min-width: 1024px;
    height: 22rem;
    background-color: #21242b;
    padding-top: 2rem;
    overflow: hidden;

    .wrap {
      width: 88%;
      height: 80%;
      margin: 0 auto;
      overflow: hidden;

      .bottom_list {
        width: 100%;
        display: flex;
        justify-content: start;

        .bottom_list_title {
          width: 100%;
          display: flex;
          justify-content: flex-start;

          p {
            font-size: 1.06rem;
            margin-right: 3rem;
            padding: 0.5rem 0 0.5rem 0;
            // text-align-last: justify;
            min-width: 6rem;
            cursor: pointer;
            color: #b7b5b2;

            span {
              color: #21242b;
            }


          }

 a {
             font-size: 1.06rem;
            margin-right: 3rem;
            padding: 0.5rem 0 0.5rem 0;
            // text-align-last: justify;
            min-width: 6rem;
            cursor: pointer;
            color: #b7b5b2;
            }
        }

        .bottom_footer {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          // margin-left: 10%;
        }
      }

      .top_title {
        width: 70%;
        display: flex;
        cursor: pointer;
        //  border: 1px solid red;
        justify-content: flex-start;
        padding: 0.5rem 0 0.5rem 0;

        h3 {
          // width: 20%;
          margin-right: 3rem;
          font-size: 1.06rem;
          min-width: 6rem;
          color: #fff;
        }
      }

      div {
        // float: left;
      }

      .footerFive {
        width: 25% !important;
        height: 100%;
        font-size: 1rem;
        text-align: center;
        padding-left: 1rem;
        // float: left;

        img {
          width: 90%;
          margin-top: 2rem;
          // margin-left: 20px;
        }

        h4 {
          width: 100%;
          color: #8d8d8d;
          margin-top: 1rem;
          line-height: 1rem;
          height: 1rem;
          display: inline-block;
          vertical-align: middle;
          // display: table-cell;
        }
      }

      //.footerFive .left-item{
      //  margin-left: 16rem !important;
      //}
      .contact-item {
        margin-top: 1.5rem;
        width: 100%;
        float: right;

        span {
          display: inline-block;
          float: right;
        }
      }

      .contact-item-content {
        margin-top: 0.5rem;
        width: 100%;
        float: right;

        h2 {
          display: inline-block;
          float: right;
        }

        h3 {
          display: inline-block;
          float: right;
          font-size: 1.2rem;
        }
      }
    }

    .footer-contact-us {
      .top-item {
        margin-top: 0.5rem !important;
      }

      width: 100%;
      float: right;
      text-align: center;

      div {
        float: right;
        text-align: center;
        padding-right: 9%;

        h2 {
          text-align: center;
          font-size: 2rem;
        }
      }

      //background: #ff994f;
    }

    .left-item {
      //background: #12b7f5 !important;
      padding-left: 15% !important;
    }


    .footerFour {
      padding-left: 5%;
      width: 20%;
      height: 100%;

      h3 {
        color: #ffffff;
        margin-left: 40px;
      }

      ul {
        list-style-type: none;
        padding-inline-start: 0;
        width: 300px;

        li {
          font-size: 0.95rem;
          margin-top: 5%;
          color: #8d8d8d;

          a {
            color: #898989;

            &:hover {
              color: #ffffff;
            }
          }
        }
      }
    }

    .bottom-vv {
      width: 100%;
      margin-top: 1rem;
      height: 50 /16rem;
      //background-color: black;
      border-top: 0.5px solid #645d58;

      .inner-vv {
        margin: 0 auto;
        height: 100%;

        //  background-color: rebeccapurple;
        div {
          width: 100%;
          color: #8d8d8d;
          font-size: 0.8rem;
          text-align: center;

          a {
            color: #8d8d8d;
          }
        }
      }
    }

    .publicSafety {
      width: 1rem;
      height: 1rem;
    }
  }
</style>
