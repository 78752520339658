<template>
  <div style="width: 50vw;margin: 0 auto;min-height: 700px;padding-bottom: 30px">
    <div style="margin-top: 30px;padding-bottom: 30px">
      <div  style="float: left;color: black;font-size: 16px">{{fileName}}</div>
      <div  style="float: right;color: #3d89ef;font-size: 14px" @click="download()">下载</div>
      <div style="height: 30px;border-bottom: 1px #8d8d8d dashed;"></div>
    </div>
    <pdf :src="src"></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import axios  from "axios";
export default {
  name: "filePreview",
  components:{pdf},
  data() {
    return {
      src: '',
      fileName:'',
      fileTitle:'',
    };
  },
  methods: {
    async download(){
      // window.open(this.src);
      const response = await axios({
        url: this.src,
        method: 'GET',
        responseType:'blob'
      })
      let url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf"
          })
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", this.fileTitle);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  },
  mounted() {

    this.src = this.$route.query.src;
    this.fileName = this.$route.query.fileName;
    this.fileTitle = this.$route.query.fileTitle;
    console.log(this.$route.query)
  }
}
</script>

<style scoped>

</style>
