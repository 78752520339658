<template>
	<div class="mainContainer">

		<el-card class="box-card" shadow="never">
			<div slot="header" class="clearfix">
				<span>实时状态</span>
			</div>
			<el-row :gutter="0" justify="start">
				<el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
					<div class="info-item realtime-data">
						<div><span>静态点故障</span></div>
						<div>否</div>
					</div>
				</el-col>
				<el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
					<div class="info-item realtime-data">
						<div><span>模拟量</span></div>
						<div>27</div>
					</div>
				</el-col>
				<el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
					<div class="info-item realtime-data">
						<div><span>RSSI</span></div>
						<div>16</div>
					</div>
				</el-col>
				<el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
					<div class="info-item realtime-data">
						<div><span>温度</span></div>
						<div>41</div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="0" justify="start">
				<el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
					<div class="info-item realtime-data">
						<div><span>污染故障</span></div>
						<div>否</div>
					</div>
				</el-col>
				<el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
					<div class="info-item realtime-data">
						<div><span>火警</span></div>
						<div>否</div>
					</div>
				</el-col>
				<el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
					<div class="info-item realtime-data">
						<div><span>模组欠压故障</span></div>
						<div>否</div>
					</div>
				</el-col>
				<el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
					<div class="info-item realtime-data">
						<div><span>欠压故障</span></div>
						<div>否</div>
					</div>
				</el-col>
			</el-row>

		</el-card>
		<!--    <div ref="deviceData" class="deviceInfoData">-->

		<!--    </div>-->
	</div>
</template>


<script>
	export default {
		name: "DeviceRealtimeData",
		data() {
			return {};
		},
		methods: {
			initCharts() {
				console.log("初始化Echarts")
				var echarts = require('echarts');
				var myChart = echarts.init(this.$refs.deviceData);
				myChart.setOption({
					title: {
						text: '设备信息页'
					},
					tooltip: {},
					xAxis: {
						data: ['火警', '底座分离故障', '欠压故障', '自检', '迷宫污染故障', '静态点故障']
					},
					yAxis: {},
					series: [{
						name: '设备历史数据',
						type: 'bar',
						data: [5, 20, 36, 10, 10, 20]
					}]
				});
			}
		},
		mounted() {
			this.initCharts();
		}
	};
</script>
<style lang="less" scoped>
	.mainContainer {
		padding: 2rem 3rem;

		.deviceInfoData {
			height: 500px;
		}

		.text {
			font-size: 14px;
		}

		.item {
			margin-bottom: 18px;
		}

		.clearfix:before,
		.clearfix:after {
			display: table;
			content: "";
		}

		.clearfix:after {
			clear: both
		}

		.box-card {
			//width: 480px;
			background: #fcfcfc;
			margin-bottom: 3rem;
			border: none;

			//font-family: Helvetica,Arial,Tahoma,"Microsoft YaHei","黑体",SimSun;
			span {
				font-weight: bold;
				font-size: 1.35rem;
			}

			.info-item {
				width: 100%;

				span {
					color: #898989;
					font-size: 1rem;
					font-weight: normal;
				}

				padding: 2rem 3rem 2rem 0;
				display: inline-block;
				line-height: 3rem;

				&.realtime-data {
					span {
						color: #12b7f5;
					}
				}
			}
		}
	}
</style>
