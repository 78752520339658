/**
 * 封装axios
 */

import axios from "axios"
import {
    Loading
} from "element-ui";
import qs from "querystring"
import Storage from "../utils/Storage";
import Router from '../router/index'
import {
    Message
} from 'element-ui';

let loading

function startLoading() { //使用Element loading-start 方法
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}

function endLoading() { //使用Element loading-close 方法
    loading.close()
}

let needLoadingRequestCount = 0

export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}

// 错误信息处理函数
const errorHandle = (status, other) => {
    switch (status) {
        case 400:

            // 请求次数的限制
            if (other.MESSAGE == "请登录") {
                Router.replace("/Login");
            }
            break;
        case 401:
            if (Storage.getItem("token")) {
                let Base64 = require('js-base64').Base64
                let token = Storage.getItem("token")
                let array = token.split('.')
                let userinfo = Base64.decode(array[1])
                let time = JSON.parse(userinfo).exp * 1000
                console.log(Date.now() + '----' + time)
                if (Date.now() > time) {
                    Router.replace("/Login");
                    return
                }
            }

            Message({
                message: '该用户无此权限',
                type: 'error'
            });
            break;
        case 503:
            Message({
                message: '后台服务异常',
                type: 'error'
            });
            console.log("错误")
            break;
        case 500:
            Message({
                message: '连接服务器失败',
                type: 'error'
            });
            console.log("错误")
            break;
        case 403:
            console.log("请求被限制");
            break;
        case 404:
            console.log("客户端错误");
            break;
        default:
            console.log(other);
            break;
    }
}

const instance = axios.create({
    timeout: 600000
})



/**
 * token:登陆 令牌
 */

instance.defaults.baseURL = "";
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';


instance.interceptors.request.use(
    config => {
        //
        if (config.url == "/api/oauth/token"||config.url == "/api/iotServer/sysNews/productList/home"||  config.url == "/api/iotServer/sysNews/productListForApp/home"||config.url == "/api/iotServer/sysNews/productList"|| config.url == "/api/iotServer/sysNews/productListForCategory"|| config.url == "/api/iotServer/sysAd/list"   || config.url == "/api/iotServer/attractInvestment/add"   || config.url == '/api/iotServer/sysUser/getCode' ||config.url == "/api/iotServer/homePage/userAccess"|| config.url == '/api/iotServer/sysUser/forgetPassword' || config.url == "/api/oauth/wx/token" ||config.url == "/api/iotServer/sysNews/newsList"|| config.url == "/api/iotServer/sysNews/solutionList"||config.url == "/api/iotServer/sysUser/getLoginCode" ||config.url == "/api/iotServer/sysNews/companyCases/showList" || config.url == "/api/iotServer/fileCategory/list"|| config.url == "/api/iotServer/file/listForWeb") {

        } else {
            config.headers.authorization = "Bearer " + Storage.getItem("token")
        }
        if (config.url !== '/api/iotServer/device/quickQuery' && config.url !== '/api/iotServer/qc/checkMac'&& config.url !== '/api/iotServer/device/message/fireAndDeviceCount'&& config.url !== '/api/iotServer/device/message/faultList') {
            startLoading()
        }
        if(config.url == "/api/iotServer/chinanetCard/export"){
            config.timeout= 6000000;
          }

        return config;
    },
    error => {
        return Promise.reject(err);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    // 成功
    response => {

            endLoading()


        if (response.status === 200) {
            if (response.data.code != "200" && response.data.code != null) {
                Message({
                    message: response.data.message,
                    type: 'error'
                });
            }
            return Promise.resolve(response)
        } else {

            Message({
                message: response.data.error_description,
                type: 'error'
            });
            return Promise.reject(response)
        }

    },
    // 失败
    error => {
        const {
            response
        } = error;

            endLoading()

        if (response) {
            errorHandle(response.status, response.data)
            if (response.data.error_description == "" || response.data.error_description == null) {
                response.data.error_description = response.data.MESSAGE
            }

            if (response.data.error_description == "" || response.data.error_description == null) {

            } else {
                Message({
                    message: response.data.error_description,
                    type: 'error'
                });
            }


            return Promise.reject(response)
        } else {
            Message({
                message: "网络请求失败,请检查网络连接后重试",
                type: 'error'
            });
        }

    }
);

// 导出
export default instance
