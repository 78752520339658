<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除该产品吗,删除后对应的数据点信息也会被删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDelete">取 消</el-button>
        <el-button type="primary" @click="handleDelClose">确 定</el-button>
      </span>
    </el-dialog>
    <div class="select">
      <span class="title">产品列表</span>
      <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新</el-button>
      <el-button type="text" size="small" icon="el-icon-plus" class="select-button" @click="showAddProductDialog">新增
      </el-button>
    </div>
    <!-- <el-row class="container-el-row">
      <div class="result-info-wrapper">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个产品。
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="name" label="产品名称" align="center"></el-table-column>
        <el-table-column prop="productKey" label="产品key" align="center"></el-table-column>
        <el-table-column prop="createSysUsername" label="创建账号" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              @click="productDetail(scope.row.id)"
              type="text"
              size="small"
              icon="el-icon-info"
            >详情</el-button>
            <el-button
              @click="deleteProductD(scope.row.id)"
              type="text"
              size="small"
              icon="el-icon-delete"
              v-if="productDel"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20]"
        :page-size="pageSize"
        :total="total"
        layout="sizes,prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </el-row>-->
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个产品
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="name" label="产品名称" align="center"></el-table-column>
        <el-table-column prop="productKey" label="产品key" align="center"></el-table-column>
        <el-table-column prop="categoryName" label="产品品类名称" align="center"></el-table-column>
        <el-table-column prop="createSysUsername" label="创建账号" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="productDetail(scope.row.id)" type="text" size="small" icon="el-icon-info">详情</el-button>
            <el-button @click="deleteProductD(scope.row.id)" type="text" size="small" icon="el-icon-delete"
              v-if="productDel">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background ref="pagination" :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
        layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
      </el-pagination>
    </div>
    <el-dialog title="新增产品" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="产品名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上传图片" ref="formImg" :label-width="formLabelWidth" prop="productImgUrl">
          <el-upload action="#" :auto-upload='false' :on-change="handleChange" list-type="picture-card"
            v-show="!isValue" :show-file-list="false">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisibleImg">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
          <div v-show="isValue" class="el-upload-list el-upload-list--picture-card">
            <div class="el-upload-list__item is-success">
              <img class="avatar" v-show="isValue" :src="isValue" />
              <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-check"></i>
              </label>
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview">
                  <i class="el-icon-zoom-in" @click.stop="handlePictureCardPreview"></i>
                </span>
                <span class="el-upload-list__item-delete">
                  <i class="el-icon-delete" @click.stop="handleRemove"></i>
                </span>
              </span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="产品品类" :label-width="formLabelWidth" prop="categoryId">
          <el-select v-model="form.categoryId" @change="isCategoryType(form)" placeholder="请选择" size="small">
            <el-option v-for="item in categories" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="协议类型" v-if="isOwn" :label-width="formLabelWidth" prop="joinType">
          <el-select v-model="form.joinType" @change="isprotocolId(form)" placeholder="请选择协议类型" size="small">
            <el-option v-for="item in protocols" :key="item.id" :label="item.protocolName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="协议名称" v-if="isOwn" :label-width="formLabelWidth" prop="protocolId">
          <el-select v-model="form.protocolId" placeholder="协议名称" size="small">
            <el-option v-for="item in protocolsList" :key="item.id" :label="item.protocolName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运营商类型" v-if="isprotocols" :label-width="formLabelWidth" prop="netOperator">
          <el-select v-model="form.netOperator" placeholder="请选择" size="small">
            <el-option v-for="item in netOperatorList" :key="item.value" :label="item.laber" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接入服务器" v-if="serverType" :label-width="formLabelWidth" prop="protocolServerType">
          <el-select v-model="form.protocolServerType" placeholder="请选择" size="small">
            <el-option v-for="item in protocolServerTypeList" :key="item.value" :label="item.laber" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
  import Bus from "../../../components/bus";
  export default {
    name: "ProductList",
    
  
    data() {
       var valiIcon = (rule, value, callback) => {
      if (!this.isValue) {
      callback(new Error('请上传11图片'));
    } else {
      callback();
   }
    };
  //      var valiIcon = (rule, value, callback) => { // 图片验证
  //   if (!this.hasFmt) {
  //     callback(new Error('请上传图片'));
  //   } else {
  //     callback();
  //  },
      return {
        productDel: false,
        productAdd: false,
        form: {
          name: "",
          protocolId: "",
          joinType: "",
          categoryId: "",
          netOperator: "",
          protocolServerType: "",

        },
        file: {},
        isValue: "",
        visible: false,
        dialogImageUrl: "",
        dialogVisibleImg: false,
        total: 0,
        tableData: [],
        checked: false,
        isOwn: false,
        isprotocols: false, //协议类型是否是NB
        serverType: false, //协议类型是否是wifi
        categoryTypeList: [{
          id: "0",
          name: "自有"
        }, {
          id: "1",
          name: "第三方"
        }],
        //新增产品弹出对话框标记
        dialogVisible: false,
        //协议类型列表
        protocols: [{
          id: 0,
          protocolName: "NB"
        }, {
          id: 1,
          protocolName: "WIFI"
        }],
        protocolsList: [],
        //产品品类列表
        categories: [],
        protocolServerTypeList: [{
            laber: "阿里云",
            value: "0",

          },
          {
            laber: "吻胜云",
            value: "1",

          },
        ],
        netOperatorList: [{
            laber: "移动",
            value: "0",

          },
          {
            laber: "电信",
            value: "1",

          },
          {
            laber: "联通",
            value: "2",

          }
        ],
        value: "",
        input: "",
        current: 1,
        pageSize: 10,
        productImgUrl: "", //图片url
        delDialogVisible: false,
        productId: "",
        formLabelWidth: "120px",
        rules: {
          name: [{
              required: true,
              message: "请输入产品名称()",
              trigger: "blur"
            },
            {
              min: 1,
              max: 30,
              message: "长度在 1 到 30 个字符",
              trigger: "blur"
            }
          ],
          joinType: [{
            required: true,
            message: "请选择协议类型",
            trigger: "change"
          }],
          protocolId: [{
            required: true,
            message: "请选择协议名称",
            trigger: "change"
          }],
          categoryId: [{
            required: true,
            message: "请选择产品品类",
            trigger: "change"
          }],
          netOperator: [{
            required: true,
            message: "请选择运营商类型",
            trigger: "change"
          }],
          protocolServerType: [{
            required: true,
            message: "请选择接入服务器",
            trigger: "change"
          }],

          productImgUrl: [ {required:true, validator: valiIcon, trigger: 'change' }]  // 图片验证,
        }
      };
    },
    methods: {
      refresh() {
        this.productList();
      },
      handlePageChange(val) {
        console.log(val);
        this.current = val;
        this.productList();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.productList();
      },

      handlePictureCardPreview(file) {
    
        this.dialogImageUrl =this.isValue;
        this.dialogVisibleImg = true;
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
        this.isValue = "";
        this.file = {};
      },
      handleChange(file, fileList) {

        this.isValue = file.url;
        this.file = file.raw;
        let formData = new FormData();
        formData.append("multipartFile", this
          .file);
        this.$api
          .addPic(formData)
          .then(res => {
            // console.log(res.data.data)
            this.productImgUrl = res.data.data

            // this.rules.productImgUrl = [{
            //   required: false,
            //   message: ""
            // }]
            this.$refs['formImg'].clearValidate()

          })
          .catch(error => {
            console.log(error);
          });
      },

      protocolServerTypel() {
        console.log("777t", this.form.protocolServerType)

      },
      // 详情
      productDetail(data) {
        sessionStorage.setItem("id", data);

        sessionStorage.setItem('currentPage', this.current)
        console.log(sessionStorage.getItem("currentPage"))
        this.$router.push("/productDetail");

      },
      deleteProductD(data) {
        this.delDialogVisible = true;
        this.productId = data;
      },
      cancelDelete() {
        this.delDialogVisible = false;
        this.productId = "";
      },
      handleDelClose() {
        this.delDialogVisible = false;
        this.deleteProduct(this.productId);
      },
      // 产品品类切换
      isCategoryType(id) {
        console.log(id)
        let categoryType = ''
        for (let index = 0; index < this.categories.length; index++) {
          if (id.categoryId == this.categories[index].id) {
            categoryType = this.categories[index].categoryType
          }
        }
        // console.log(categoryType)
        if (categoryType == '0') {
          this.isOwn = true
        } else {
          this.isOwn = false
          this.serverType = false
        }
      },
      //协议类型切换
      isprotocolId(id) {

        if (id.joinType == '0') {
          this.isprotocols = true
          this.serverType = false
        } else {
          this.isprotocols = false
          this.serverType = true
        }
      },
      deleteProduct(data) {
        console.log("删除产品" + data);
        this.$api
          .delProduct(data)
          .then(res => {
            if (res.data.code == 200) {
              this.$message.success("删除产品成功!");
              this.productList();
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      //新增产品
      addProduct() {
        this.dialogVisible = false;

        this.$api
          .addProduct(this.product)
          .then(res => {
            this.form.name = "";
            this.form.protocolId = "";
            this.form.protocolName = "";
            this.form.categoryId = "";
            this.form.netOperator = "";
            //查询产品列表
            this.productList();
          })
          .catch(error => {
            console.log(error);
          });
      },
      productList() {
        //查询设备列表
        this.$api
          .productList({
            // page: {
            current: this.current,
            size: this.pageSize
            // }
          })
          .then(res => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            let cloudList = JSON.parse(sessionStorage.getItem("cloudList"));
            for (let i = 0; i < cloudList.length; i++) {
              if (
                this.$store.state.localList.localList.productDel == cloudList[i]
              ) {
                this.productDel = true;
              }
              if (
                this.$store.state.localList.localList.productAdd == cloudList[i]
              ) {
                this.productAdd = true;
              }
            }
            this.$nextTick(() => {
              this.$refs.pagination.internalCurrentPage = this.current
            })
          })
          .catch(error => {
            console.log(error);
          });
      },
      //重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      showAddProductDialog() {
        // this.$refs['formImg'].clearValidate()

        this.dialogVisible = true;
        this.isValue = ""
        this.dialogImageUrl = ""
        this.isOwn = false
        this.serverType = false
        this.isprotocols = false
        if (this.$refs["form"] !== undefined) {
          console.log('查看')
          this.resetForm("form");
          // this.rules.productImgUrl = [{
          //   required: true,
          //   message: "请上传图片",
          // }]
          console.log(this.rules.productImgUrl[0].required)
        }
        //请求后台接口，获取产品品类列表
        this.$api
          .categoryListForPull({
            // page: {
            //   current: this.current,
            //   size: this.pageSize
            // }
          })
          .then(res => {
            console.log(res.data.data);
            this.categories = res.data.data;

          })
          .catch(error => {
            console.log(error);
          });
        //请求后台接口，获取协议列表
        this.$api
          .protocolList
        //   {
        //   page: {
        //     current: this.current,
        //     size: this.pageSize
        //   }
        // }
        ()
        .then(res => {
            console.log(res.data.data);
            this.protocolsList = res.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      },
      submitForm(formName) {
        // if(this.productImgUrl){
        //        this.$refs['formImg'].clearValidate()
        //    }
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.dialogVisible = false;
            this.form.productImgUrl = this.productImgUrl;
            console.log(this.form)
            this.$api.addProduct(this.form).then(res => {
              if (res.data.code == 200) {
                this.$message.success("添加产品成功!");
                this.rules.productImgUrl = [{
                  required: true,
                }]
                this.productList();
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    created() {
      //查询产品列表
      //
    },
    mounted() {

      this.current = parseInt(sessionStorage.getItem('currentPage')) || 1
      console.log(sessionStorage.getItem('currentPage'))
    },
    beforeRouteEnter(to, from, next) {
      // console.log(from.path)
      if (from.path != '/productDetail') {
 next(vm => {
          vm.productList();
        });
        sessionStorage.removeItem("currentPage")
      } else {
        next(vm => {
          vm.productList();
        });

      }
      next();
    },
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);

  .el-row {
    margin: 1rem 3rem;
    line-height: 0;
    min-width: 900px;

    .platform-toolbar {
      position: relative;
      margin: 0;
      border: 1px solid #d9d9d9;

      .title-info {
        height: 46px;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      .el-col {
        padding: 0 3rem;

        .toolbar-right {
          float: right;
          margin-top: 7px;

          &.export {
            margin-left: 1rem;

            .toolbar-btn {
              color: @toolBarFontColor;
              font-size: 0.9rem;

              &.left {
                margin-left: 1.5rem;
              }

              &.right {
                margin-right: 0;
              }
            }
          }
        }
      }

      .title-info {
        float: left;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .result-info-wrapper {
      height: 1rem;
      margin: 1rem 0 0.5rem 0;
      position: relative;

      .setting-panel {
        position: absolute;
        float: right;
        top: 2rem;
        width: 200px;
        height: 200px;
        background: red;
        z-index: 1000;
        border-radius: 3px;
      }

      .result-info {
        height: 1rem;
        line-height: 1rem;
        font-size: 0.875rem;
        float: left;
        color: #898989;

        span {
          color: #292929;
          padding: 0 0.1rem;
        }
      }

      .data-setting {
        font-size: 0.875rem;
        color: #2673bb;
        float: right;

        i {
          padding-right: 0.2rem;
        }

        cursor: pointer;
      }
    }

    .el-table td,
    .el-table th {
      padding: 0 !important;
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .product-content {
    height: 6rem;

    .product-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .container-el-row {
    margin-top: 0px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15px;
    }
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }
</style>