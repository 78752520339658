<template>
    <div>       
            <div class="left_tit" > <h3 style=";margin-left:2rem">数据点分析详情</h3>
            <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回wifi结果列表</el-button>
                     </div>
        <div class="statis_box">
            
                <div style="width:100%;overflow:hidden;">
        <div style="width:18.66%;float:left; text-align: center;" v-for="(item,index) in newList" :key="index">
          <div class="grid-content bg-purple-dark" style="margin-top:20px">
            <div>
              <span class="new-span">key</span>
              <div class="new-vv">{{item.key}}</div>
            </div>
            <div style="">
              <span class="new-span">value</span>
              <div class="new-vv">{{item.value }}</div>
            </div>
             <div>
              <span class="new-span">result</span>
              <div class="new-vv">{{item.result }}</div>
            </div>
          </div>
        </div>
      </div>
           
        </div>
       
    </div>

</template>


<script>
    export default {
        name: "wifiTestResultInfo",
        data() {
            return {
              
                newList: [],
            };
        },
        methods: {
           
                 //回到模块页面
    gobackPlaceDetail() {
      this.$router.push("/wifiTestResult");
    },

         
        },
        created() {
             console.log(this.$route.params.data);
             this.newList =  JSON.parse(this.$route.params.data)
        }
    };
</script>
<style lang="less" scoped>
    .statis_box {
        display: flex;
       
        flex-wrap: wrap;
       justify-content: flex-start;
        margin: 1rem 5rem;

        .box-card {
            width: 20rem;
            margin-top: 20px;
            margin-right:5rem;
            cursor: pointer;
            .item{
                color: rgb(236, 118, 22);
                span{
                    display: inline-block;
                    width: 70px;
                    text-align: right;
                    color: black;
                    margin-right: 10px;
                }
            }
        }
    }
      .el-pagination {
            margin: 1rem 5rem;
            float: right;
        }
        .addRoleButton{
  float: right;
  color: rgb(92, 90, 90);
  margin-top:-1rem; 
  margin-right: 2rem;
//   font-size: 0.8rem;
}
    .left_tit{
           text-align: left;
           margin: 20px 20px;
           }
          .new-span{
            color: black;
            font-size: 1rem;
            font-weight: 600;
          }
          .new-vv{
             color: rgb(80, 77, 77);
            font-size:0.8rem;
          }
</style>