<template>
  <div class="mainContainer">
    <el-tabs v-model="activeName" @tab-click="handleClick" style="padding:0 3rem;">
      <el-tab-pane label="数据点数据" name="first" v-if="ifSunshDeviceData">
        <div class="query-toolbar">
          <div style="width:100%;overflow: hidden;">
            <div style="float:left;margin-left:1rem;">
              <div class="info-item">
                <div class="top-label">
                  <span>选择数据点</span>
                </div>
                <div class="middle-component">
                  <el-select v-model="value" placeholder="请选择" size="small" @change="changeAlarm" v-intro-step="1"
                    v-intro="'点击这里选择当前设备要查询的数据点，默认为火警。'">
                    <el-option v-for="item in options" :key="item.dataPointKey" :label="item.dataPointKeyShowName"
                      :value="item.dataPointKey"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div style="float:left;margin-left:2rem;">
              <div class="info-item">
                <div class="top-label">
                  <span>选择时间</span>
                </div>
                <div class="middle-component">
                  <el-date-picker v-model="value1" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                    size="small" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
                    @change="getTimeBlock" v-intro-step="2" v-intro="'点击这里选择当前设备要查询的数据点时间范围，默认为一周。'"></el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div style="width:100%;overflow: hidden;">
            <el-button size="small" @click="cheakHandler" style="float:right;margin-right:3rem;" v-intro-step="3"
              v-intro="'选择好筛选条件点击此处进行查询。不选择按照默认值展示。'">查询</el-button>
            <el-button size="small" @click="reset" style="float:right;margin-right:1rem;">重置</el-button>
          </div>
        </div>
        <div class="bar-vv">
          <div id="myChart" v-if="ifChart"></div>
          <div class="noData" v-show="ifNo">暂无图表数据</div>
        </div>
        <div style="width:100%;">
          <div>共有{{total}}条数据</div>
          <el-table :data="tableData" stripe size="small" :header-cell-style="{'background-color': '#eeeeee'}">
            <el-table-column prop="reportingTime" label="上报时间" min-width="1" align="center"></el-table-column>
            <el-table-column prop="dataPointValue" label="数据" min-width="1" align="center"
              :formatter="stateFormatDataPoint"></el-table-column>
            <el-table-column prop="dataType" label="数据点类型" min-width="1" align="center"
              :formatter="stateFormatDataPointType"></el-table-column>
          </el-table>
          <el-pagination background :page-sizes="[10, 20, 30, 40]" :page-size="10" :total="total"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="sizes,prev, pager, next">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="全部数据" name="second" v-if="ifSunshDeviceData">
        <div class="query-toolbar">
          <div style="width:100%;overflow: hidden;">
            <div style="float:left;margin-left:1rem;">
              <div class="info-item">
                <div class="top-label">
                  <span>选择时间</span>
                </div>
                <div class="middle-component">
                  <el-date-picker v-model="value2" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                    size="small" :picker-options="pickerOptions2" value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
                    @change="getTimeBlock2"></el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div style="width:100%;overflow: hidden;">
            <div style="float:right;">
              <el-button size="small" @click="cheakHandler2" style="float:right;margin-right:3rem;">查询</el-button>
              <el-button size="small" @click="reset2" style="float:right;margin-right:1rem;">重置</el-button>
            </div>
          </div>
        </div>
        <div style="height:1rem;line-height:1rem;margin-top:1rem;font-size:.875rem;color:#898989;margin-bottom:.5rem;">
          共有
          <span style="color:black;">{{total2}}</span> 条数据
        </div>
        <!-- 全部数据表格 -->
        <el-table :data="tableData2"  ref="table" :height="tableHeight" stripe size="small" class="data-table" style="margin-top:0;"
          :header-cell-style="{'background-color': '#eeeeee'}">
          <el-table-column prop="reporting_time" label="上报时间" min-width="1" align="center"></el-table-column>
          <el-table-column v-for="(item,index) in options" :key="index" :prop="item.dataPointKey"
            :label="item.dataPointKeyShowName" min-width="1" align="center">
          </el-table-column>
        </el-table>
        <el-pagination background :page-sizes="[10, 20, 30, 40]" :page-size="10" :total="total2"
          @size-change="handleSizeChange2" @current-change="handleCurrentChange2" layout="sizes,prev, pager, next">
        </el-pagination>
      </el-tab-pane>
         <el-tab-pane label="失败记录" name="third" v-if="ifSunshDeviceData">
        <div class="query-toolbar">
          <div style="width:100%;overflow: hidden;">
            <div style="float:left;margin-left:1rem;">
              <div class="info-item">
                <div class="top-label">
                  <span>选择时间</span>
                </div>
                <div class="middle-component">
                  <el-date-picker v-model="value3" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                    size="small" :picker-options="pickerOptions2" value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
                    @change="getTimeBlock3"></el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div style="width:100%;overflow: hidden;">
            <div style="float:right;">
              <el-button size="small" @click="cheakHandler3" style="float:right;margin-right:3rem;">查询</el-button>
              <el-button size="small" @click="reset3" style="float:right;margin-right:1rem;">重置</el-button>
            </div>
          </div>
        </div>
        <div style="height:1rem;line-height:1rem;margin-top:1rem;font-size:.875rem;color:#898989;margin-bottom:.5rem;">
          共有
          <span style="color:black;">{{total3}}</span> 条数据
        </div>
        <el-table :data="tableData3"  stripe size="small" class="data-table" style="margin-top:0"
          :header-cell-style="{'background-color': '#eeeeee'}">
          <el-table-column prop="reporting_time" label="上报时间" min-width="1" align="center"></el-table-column>
          <el-table-column v-for="(item,index) in options" :key="index" :prop="item.dataPointKey"
            :label="item.dataPointKeyShowName" min-width="1" align="center">
          </el-table-column>
        </el-table>
        <el-pagination background :page-sizes="[10, 20, 30, 40]" :page-size="10" :total="total3"
          @size-change="handleSizeChange3" @current-change="handleCurrentChange3" layout="sizes,prev, pager, next">
        </el-pagination>
      </el-tab-pane>
      <!-- 电气火灾全部数据 -->
      <el-tab-pane label="全部数据" name="second" v-else>
        <div class="query-toolbar">
          <div style="width:100%;overflow: hidden;">
            <div style="float:left;margin-left:1rem;">
              <div class="info-item">
                <div class="top-label">
                  <span>选择时间</span>
                </div>
                <div class="middle-component">
                  <el-date-picker v-model="value2" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                    size="small" :picker-options="pickerOptions2" value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
                    @change="getTimeBlock2"></el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div style="width:100%;overflow: hidden;">
            <div style="float:right;">
              <el-button size="small" @click="cheakHandler2" style="float:right;margin-right:3rem;">查询</el-button>
              <el-button size="small" @click="reset2" style="float:right;margin-right:1rem;">重置</el-button>
            </div>
          </div>
        </div>
        <div style="height:1rem;line-height:1rem;margin-top:1rem;font-size:.875rem;color:#898989;margin-bottom:.5rem;">
          共有
          <span style="color:black;">{{total2}}</span> 条数据
        </div>
        <el-table :data="tableData2"  max-height="800px"  stripe size="small" class="data-table" style="margin-top:0"
          :header-cell-style="{'background-color': '#eeeeee'}">

          <el-table-column v-for="(item,index) in options" :key="index" :prop="item" :label="item" min-width="1"
            align="center">
          </el-table-column>
        </el-table>
        <el-pagination background :page-sizes="[10, 20, 30, 40]" :page-size="10" :total="total2"
          @size-change="handleSizeChange2" @current-change="handleCurrentChange2" layout="sizes,prev, pager, next">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
  // 引入基本模板
  let echarts = require("echarts/lib/echarts");
  // 引入柱状图组件
  require("echarts/lib/chart/bar");
  // 引入提示框和title组件
  require("echarts/lib/component/tooltip");
  require("echarts/lib/component/title");
    import {debounce} from "../../../utils/debounce";
  export default {
    name: "DeviceHistoryData",
    data() {
      return {
        ifSunshDeviceData: true,
       clientHeight: document.documentElement.clientHeight,
          tableHeight:500,// 定义table高度
        ifShowP2: false,
        size2: 10,
        size3: 10,
        current2: 1,
        current3: 1,
        msg: "Abcdf",
        total2: 0,
        total3: 0,
        activeName: "first",
        ifNo: false,
        ifChart: false,
        myChart: null,
        myLossChart: null,
        myStaticChart: null,
        myEleChart: null,
        alarmType: "",
        deviceMac: "",
        startTime: "",
        endTime: "",
        startTime2: "",
        endTime2: "",
          startTime3: "",
        endTime3: "",
        total: 0,
        size: 10,
        current: 1,
        dataPointKeyShowName: "",
        tableData: [],
        tableData2: [],
        tableData3: [],
        options: [],
        value1: "",
        value2: "",
        value3: "",
        newValueList: ["是", "否", "是"],
        value: "",
        pickerOptions: {
          disabledDate(time) {
            let cuttentDate = new Date(
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
            );
            let hmCurrentDate = cuttentDate.getTime();
            let three = 90 * 24 * 3600 * 1000;
            let threeMonths = hmCurrentDate - three;
            return time.getTime() > hmCurrentDate || time.getTime() < threeMonths;
          }
        },
        pickerOptions2: {
          disabledDate(time) {
            let cuttentDate = new Date(
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
            );
            let hmCurrentDate = cuttentDate.getTime();
            let three = 90 * 24 * 3600 * 1000;
            let threeMonths = hmCurrentDate - three;
            return time.getTime() > hmCurrentDate || time.getTime() < threeMonths;
          }
        }
      };
    },
    methods: {
      guide() {
        this.$intro()
          .setOptions({
            prevLabel: "上一步",
            nextLabel: "下一步",
            skipLabel: "跳过",
            doneLabel: "结束",
            exitOnOverlayClick: false, //是否点击空白处退出
            showStepNumbers: true, //是否显示数字
            scrollToElement: true, //是否滑动到高亮的区域
            overlayOpacity: 0.7,
            tooltipClass: "text-vv",
            tooltipPosition: "left"
          })
          .oncomplete(function () {
            //点击跳过按钮后执行的事件
          })
          .onexit(function () {
            //点击结束按钮后， 执行的事件
          })
          .start();
      },
      tabClick(item){
      // console.log(item)

      },
      //修改选择的报警选项
      changeAlarm(val) {
        // console.log(val);
        this.alarmType = val;
        let obj = {};
        obj = this.options.find(item => {
          return item.dataPointKey === val;
        });

        // console.log(obj.dataPointKeyShowName);
        this.dataPointKeyShowName = obj.dataPointKeyShowName;
      },
      //查询函数
      cheakHandler() {
        this.$api
          .dataPointEcharts({
            mac: this.deviceMac,
            dataPointkey: this.alarmType,
            startTime: this.startTime,
            endTime: this.endTime
          })
          .then(res => {
            // console.log(res.data.data);
            if (res.data.data.dateList) {
              this.ifChart = true;
              this.ifNo = false;
              this.$nextTick(() => {
                this.myChart = this.$echarts.init(
                  document.getElementById("myChart")
                );
                if (
                  res.data.data.valueList[0] == "false" ||
                  res.data.data.valueList[0] == "true"
                ) {
                  var newValueList = [];
                  for (var i = 0; i < res.data.data.valueList.length; i++) {
                    // console.log(res.data.data.valueList[i]);
                    if (res.data.data.valueList[i] == "false") {
                      newValueList.push("否");
                    } else {
                      newValueList.push("是");
                    }
                  }
                  var chartsOption = {
                    title: {
                      left: "center",
                      text: this.dataPointKeyShowName
                    },
                    tooltip: {
                      trigger: "axis",
                      position: function (pt) {
                        return [pt[0], "10%"];
                      },

                      formatter: function (params) {
                        return params[0].axisValue + "<br/>" + params[0].data;
                      }
                    },
                    toolbox: {
                      feature: {
                        dataZoom: {
                          show: false
                        },
                        restore: {},
                        saveAsImage: {}
                      }
                    }, //h还原 保存图片
                    dataZoom: [{
                      type: "inside",
                      start: 0,
                      end: 100
                    }],
                    xAxis: {
                      type: "category",
                      data: res.data.data.dateList,
                      axisLine: {
                        lineStyle: {
                          color: "bule"
                        }
                      }
                    },
                    yAxis: {
                      type: "category",
                      data: ["否", "是"]
                    },
                    series: [{
                      data: newValueList,
                      type: "line"
                    }]
                  };
                  this.myChart.setOption(chartsOption, true);
                } else {
                  var numArr = []
                  for (let index = 0; index < res.data.data.valueList.length; index++) {

                    numArr.push(parseInt(res.data.data.valueList[index]))

                    // console.log(numArr) //["123.456", "456.789"]
                    // console.log("00000")
                  } {
                    var chartsOption = {
                      tooltip: {
                        trigger: "axis",
                        position: function (pt) {
                          return [pt[0], "10%"];
                        }
                      },
                      title: {
                        left: "center",
                        text: this.dataPointKeyShowName
                      },
                      toolbox: {
                        feature: {
                          dataZoom: {
                            show: false
                          },
                          restore: {},
                          saveAsImage: {}
                        }
                      }, //h还原 保存图片
                      dataZoom: [{
                        type: "inside",
                        start: 0,
                        end: 100
                      }],
                      xAxis: {
                        type: "category",
                        data: res.data.data.dateList,
                        axisLine: {
                          lineStyle: {
                            color: "bule"
                          }
                        }
                      },
                      yAxis: {
                        type: "value"
                      },
                      series: [{
                        data: numArr,
                        type: "line"
                      }]
                    };
                    this.myChart.setOption(chartsOption, true);
                  }
                }
              });
            } else {
              this.ifChart = false;
              this.ifNo = true;
            }
          });
        this.$api
          .dataPointTable({
            page: {
              current: 1,
              size: 10
            },
            historyQueryDto: {
              mac: this.deviceMac,
              dataPointkey: this.alarmType,
              startTime: this.startTime,
              endTime: this.endTime
            }
          })
          .then(res => {
            // console.log(res);
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
      },
      //获取时间函数1
      getTimeBlock() {
        // console.log(this.value1);
        if (this.value1) {
          this.startTime = this.value1[0];
          this.endTime = this.value1[1];
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      },
      //获取时间函数2
      getTimeBlock2() {
        // console.log(this.value1);
        if (this.value2) {
          this.startTime2 = this.value2[0];
          this.endTime2 = this.value2[1];
        } else {
          this.startTime2 = "";
          this.endTime2 = "";
        }
      },
         //获取时间函数3
      getTimeBlock3() {
        // console.log(this.value1);
        if (this.value3) {
          this.startTime3 = this.value3[0];
          this.endTime3 = this.value3[1];
        } else {
          this.startTime3 = "";
          this.endTime3 = "";
        }
      },
      //重置函数1
      reset() {
        // (this.value = "火警"),
         (this.value1 = "");
        (this.startTime = ""), (this.endTime = "");
        this.alarmType = this.options[0].dataPointKey;
        this.value = this.options[0].dataPointKeyShowName;
        console.log(this.value)
        // this.dataPointKeyShowName = "火警";
      },
      //重置函数2
      reset2() {
        this.value2 = "";
        (this.startTime2 = ""), (this.endTime2 = "");
      },
           //重置函数3
      reset3() {
        this.value3 = "";
        (this.startTime3 = ""), (this.endTime3 = "");
      },
      //数据点类型展示函数
      stateFormatDataPointType(row) {
        if (row.dataType == 0) {
          return "火警";
        } else if (row.dataType == 1) {
          return "故障";
        } else if (row.dataType == 2) {
          return "普通";
        } else {
          return "暂无数据";
        }
      },
      stateFormatDataPoint(row) {
        // console.log(row);
        if (row.dataPointValue == "true") {
          return "是";
        } else if (row.dataPointValue == "false") {
          return "否";
        } else {
          return row.dataPointValue;
        }
      },
      //每页显示条数函数
      handleSizeChange(val) {
        // console.log(val);
        this.size = val;
        this.$api
          .dataPointTable({
            page: {
              current: this.current,
              size: this.size
            },
            historyQueryDto: {
              mac: this.deviceMac,
              dataPointkey: this.alarmType,
              startTime: this.startTime,
              endTime: this.endTime
            }
          })
          .then(res => {
            // console.log(res.data.data);
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
      },
      //每页显示条数2
      handleSizeChange2(val) {
        this.size2 = val;
        var tabLabel = sessionStorage.getItem("tabLabel");
        if (tabLabel == '电气火灾') {
          var deviceName = sessionStorage.getItem("deviceName");
          this.$api.sunshDeviceHistory({
              page: {
                current: this.current2,
                size: this.size2
              },
              query: {
                "qryValue": deviceName,
                startTime: this.startTime2,
                endTime: this.endTime2
              }
            })
            .then(res => {
              var tableData2 = res.data.data.records;
              var newTableData = tableData2.map(item => {
                for (var i in item) {
                     if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });
              // console.log(newTableData);
              this.tableData2 = newTableData;
              this.total2 = res.data.data.total;
            });
        } else {
          this.$api
            .allDeviceListStatus({
              page: {
                current: this.current2,
                size: this.size2
              },
              historyQueryDto: {
                mac: this.deviceMac,
                startTime: this.startTime2,
                endTime: this.endTime2
              }
            })
            .then(res => {
              var tableData2 = res.data.data.records;
              var newTableData = tableData2.map(item => {
                for (var i in item) {
            if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });
              // console.log(newTableData);
              this.tableData2 = newTableData;
              this.total2 = res.data.data.total;
            });
        }
      },
        //每页显示条数3
      handleSizeChange3(val) {
        this.size3 = val;
        var tabLabel = sessionStorage.getItem("tabLabel");
           this.$api
            .deviceFailureRecord({
              page: {
                current: this.current3,
                size: this.size3
              },
              historyQueryDto: {
                mac: this.deviceMac,
                startTime: this.startTime3,
                endTime: this.endTime3
              }
            })
            .then(res => {
              var tableData3 = res.data.data.records;
              var newTableData = tableData3.map(item => {
                for (var i in item) {
                if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });
              // console.log(newTableData);
              this.tableData3 = newTableData;
              this.total3= res.data.data.total;
            });

      },
      //分页函数
      handleCurrentChange(val) {
        // console.log(val);
        this.current = val;
        this.$api
          .dataPointTable({
            page: {
              current: this.current,
              size: this.size
            },
            historyQueryDto: {
              mac: this.deviceMac,
              dataPointkey: this.alarmType,
              startTime: this.startTime,
              endTime: this.endTime
            }
          })
          .then(res => {
            // console.log(res.data.data);
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
      },
      //分页函数2
      handleCurrentChange2(val) {
        this.current2 = val;
        var tabLabel = sessionStorage.getItem("tabLabel");
        if (tabLabel == '电气火灾') {
          var deviceName = sessionStorage.getItem("deviceName");
          this.$api.sunshDeviceHistory({
              page: {
                current: this.current2,
                size: this.size2
              },
              query: {
                "qryValue": deviceName,
                startTime: this.startTime2,
                endTime: this.endTime2
              }
            })
            .then(res => {
              var tableData2 = res.data.data.records;
              var newTableData = tableData2.map(item => {
                for (var i in item) {
                      if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });

              this.tableData2 = newTableData;
              this.total2 = res.data.data.total;
            });
        } else {
          this.$api
            .allDeviceListStatus({
              page: {
                current: this.current2,
                size: this.size2
              },
              historyQueryDto: {
                mac: this.deviceMac,
                startTime: this.startTime2,
                endTime: this.endTime2
              }
            })
            .then(res => {
              var tableData2 = res.data.data.records;
              var newTableData = tableData2.map(item => {
                for (var i in item) {
                  if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });
              console.log(newTableData);
              this.tableData2 = newTableData;
              this.total2 = res.data.data.total;
            });
        }

      },
           //分页函数3
      handleCurrentChange3(val) {
        this.current3 = val;
        var tabLabel = sessionStorage.getItem("tabLabel");

           this.$api
            .deviceFailureRecord({
              page: {
                current: this.current3,
                size: this.size3
              },
              historyQueryDto: {
                mac: this.deviceMac,
                startTime: this.startTime3,
                endTime: this.endTime3
              }
            })
            .then(res => {
              var tableData3 = res.data.data.records;
              var newTableData = tableData3.map(item => {
                for (var i in item) {
               if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });
              // console.log(newTableData);
              this.tableData3 = newTableData;
              this.total3= res.data.data.total;
            });



      },
      //tabs点击切换
      handleClick(tab, event) {
        // console.log(tab.label, event);
        if(tab.label == '失败记录'){
             var tabLabel = sessionStorage.getItem("tabLabel");
        this.deviceMac = sessionStorage.getItem("deviceMac");
          this.$api
            .deviceFailureRecord({
              page: {
                current: this.current3,
                size: this.size3
              },
              historyQueryDto: {
                mac: this.deviceMac,
                startTime: this.startTime3,
                endTime: this.endTime3
              }
            })
            .then(res => {
              var tableData3 = res.data.data.records;
              var newTableData = tableData3.map(item => {
                for (var i in item) {
                    if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });
              // console.log(newTableData);
              this.tableData3 = newTableData;
              this.total3= res.data.data.total;
            });
        }

      },
      //查询函数2
      cheakHandler2() {
        var tabLabel = sessionStorage.getItem("tabLabel");
        if (tabLabel == '电气火灾') {
          var deviceName = sessionStorage.getItem("deviceName");
          this.$api.sunshDeviceHistory({
              page: {
                current: this.current2,
                size: this.size2
              },
              query: {
                "qryValue": deviceName,
                startTime: this.startTime2,
                endTime: this.endTime2
              }
            })
            .then(res => {
              var tableData2 = res.data.data.records;
              var newTableData = tableData2.map(item => {
                for (var i in item) {
                      if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });
              // console.log(newTableData);
              this.tableData2 = newTableData;
              this.total2 = res.data.data.total;

            });
        } else {
          this.$api
            .allDeviceListStatus({
              page: {
                current: this.current2,
                size: this.size2
              },
              historyQueryDto: {
                mac: this.deviceMac,
                startTime: this.startTime2,
                endTime: this.endTime2
              }
            })
            .then(res => {
              var tableData2 = res.data.data.records;
              var newTableData = tableData2.map(item => {
                for (var i in item) {
                       if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });
              console.log(newTableData);
              this.tableData2 = newTableData;
              this.total2 = res.data.data.total;



            });
        }

      },
         //失败记录
      cheakHandler3() {
        var tabLabel = sessionStorage.getItem("tabLabel");
        this.deviceMac = sessionStorage.getItem("deviceMac");
          this.$api
            .deviceFailureRecord({
              page: {
                current: this.current3,
                size: this.size3
              },
              historyQueryDto: {
                mac: this.deviceMac,
                startTime: this.startTime3,
                endTime: this.endTime3
              }
            })
            .then(res => {
              var tableData3 = res.data.data.records;
              var newTableData = tableData3.map(item => {
                for (var i in item) {
                   if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
                }
                return item;
              });
              // console.log(newTableData);
              this.tableData3 = newTableData;
              this.total3= res.data.data.total;
            });


      },
       // 防抖
        getTableHeight: debounce(function() {
          // 给table高度赋值
          this.tableHeight = this.clientHeight - 126
        }, 300),
    },
    created() {

      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      var end2 = new Date();
      var start2 = new Date();
      start2.setTime(start2.getTime() - 3600 * 1000 * 24 * 7);
       var end3 = new Date();
      var start3 = new Date();
      start3.setTime(start2.getTime() - 3600 * 1000 * 24 * 7);
      this.value1 = [start, end];
      this.endTime = end.Format("yyyy-MM-dd HH:mm:ss");
      this.startTime = this.value1[0].Format("yyyy-MM-dd HH:mm:ss");
      this.value2 = [start2, end2];
      const endFamtTime = end2.Format("yyyy-MM-dd HH:mm:ss");
      const startFamtTime = this.value2[0].Format("yyyy-MM-dd HH:mm:ss");
      this.startTime2 = startFamtTime;
      this.endTime2 = endFamtTime;
       this.value3 = [start3, end3];
      const endFamtTime3 = end3.Format("yyyy-MM-dd HH:mm:ss");
      const startFamtTime3 = this.value3[0].Format("yyyy-MM-dd HH:mm:ss");
      this.startTime3 = startFamtTime3;
      this.endTime3 = endFamtTime3;
      // console.log(startFamtTime);
      // console.log(endFamtTime);

      var tabLabel = sessionStorage.getItem("tabLabel");
      if (tabLabel == '电气火灾') {
        this.ifSunshDeviceData = false
        this.activeName = 'second'
        var deviceName = sessionStorage.getItem("deviceName");
        this.$api.sunshDeviceHistory({

          page: {
            current: this.current2,
            size: this.size2
          },
          query: {
            "qryValue": deviceName,
            startTime: this.startTime2,
            endTime: this.endTime2
          }
        }).then(res => {

          this.options = Object.keys(res.data.data.records[0])

          console.log()
          this.tableData2 = res.data.data.records
          this.total2 = res.data.data.total;

        });


      } else {
        this.ifSunshDeviceData = true
        this.deviceMac = sessionStorage.getItem("deviceMac");
        this.$api.dataPotinSelect(this.deviceMac).then(res => {
          this.options = res.data.data;
          this.value = res.data.data[0].dataPointKeyShowName
          this.alarmType = res.data.data[0].dataPointKey
          this.dataPointKeyShowName = res.data.data[0].dataPointKeyShowName
        });
        this.$api
          .dataPointTable({
            page: {
              current: 1,
              size: 10
            },
            historyQueryDto: {
              mac: this.deviceMac,
              dataPointkey: this.alarmType,
              startTime: this.startTime,
              endTime: this.endTime
            }
          })
          .then(res => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
        this.$api
          .allDeviceListStatus({
            page: {
              current: 1,
              size: 10
            },
            historyQueryDto: {
              mac: this.deviceMac,
              startTime: startFamtTime,
              endTime: endFamtTime
            }
          })
          .then(res => {
            var tableData2 = res.data.data.records;
            // console.log(tableData2);
            var newTableData = tableData2.map(item => {
              for (var i in item) {
                   if (item[i] === true ) {
                    item[i] = "是";
                  }
                  if (item[i] === false ) {
                    item[i] = "否";
                  }
              }
              return item;
            });
            // console.log(newTableData);
            this.tableData2 = newTableData;
            this.total2 = res.data.data.total;
                setTimeout(() => {
             console.log(this.$refs.table.$el.offsetTop,window.innerHeight) // 0
            // this.tableHeight = window.innerHeight - this.$refs.table.$el.offsetTop;

            // 监听窗口大小变化
            // let self = this;
            // window.onresize = function() {
            //     self.tableHeight = window.innerHeight - self.$refs.table.$el.offsetTop
            // }
        },900)
　　　　　//此处需要通过延迟方法来设置值，不然会出现值已更新，但页面没更新的问题
        //this.$refs.table.$el.offsetTop：表格距离浏览器的高度
          });
      }
        //  初始值，自己定义
        this.tableHeight = this.clientHeight - 126
    },
    watch: {
        //  监听高度变化
        clientHeight(newValue, oldValue) {
          if(newValue) {
            this.getTableHeight()
          }
        }
      },
    mounted() {
      this.$api
        .dataPointEcharts({
          mac: this.deviceMac,
          dataPointkey: this.alarmType,
          startTime: this.startTime,
          endTime: this.endTime
        })
        .then(res => {
          // console.log(res.data.data);
          if (res.data.data.dateList) {
            this.ifChart = true;
            this.ifNo = false;
            this.$nextTick(() => {
              this.myChart = this.$echarts.init(
                document.getElementById("myChart")
              );
              var newValueList = [];
              for (var i = 0; i < res.data.data.valueList.length; i++) {
                if (res.data.data.valueList[i] == "false") {
                  newValueList.push("否");
                } else {
                  newValueList.push("是");
                }
              }
              var chartsOption = {
                title: {
                  left: "center",
                  text: "火警"
                },
                toolbox: {
                  feature: {
                    dataZoom: {
                      show: false
                    },
                    restore: {},
                    saveAsImage: {}
                  }
                }, //h还原 保存图片
                xAxis: {
                  type: "category",
                  // boundaryGap: false,
                  data: res.data.data.dateList,
                  axisLine: {
                    lineStyle: {
                      color: "bule"
                    }
                  }
                },
                yAxis: {
                  type: "category",
                  // boundaryGap: [0, "100%"],
                  data: ["否", "是"]
                },
                tooltip: {
                  trigger: "axis",
                  position: function (pt) {
                    return [pt[0], "10%"];
                  },

                  formatter: function (params) {
                    return params[0].axisValue + "<br/>" + params[0].data;
                  }
                },
                dataZoom: [{
                  type: "inside",
                  start: 0,
                  end: 100
                }],
                series: [{
                  name: "火警数据",
                  type: "line",
                  // smooth: true,
                  // symbol: "none",
                  // sampling: "average",
                  data: newValueList
                }]
              };
              this.myChart.setOption(chartsOption, true);
            });
          } else {
            this.ifChart = false;
            this.ifNo = true;
          }
        });
      if (sessionStorage.getItem("ifNew") == "true") {
        if (sessionStorage.getItem("ifDisplay2") !== "true") {
          this.guide();
        }

        sessionStorage.setItem("ifDisplay2", true);
      }
  window.onresize = () => {
          this.clientHeight = document.documentElement.clientHeight;
        }
        //this.$refs.table.$el.offsetTop：表格距离浏览器的高度
　　　　 //50表示你想要调整的表格距离底部的高度（你可以自己随意调整），因为我们一般都有放分页组件的，所以需要给它留一个高度　

    }
  };
</script>
<style lang="less" scoped>
  .mainContainer {
    padding: 2rem 0;
    font-size: 1rem;
  //   .data-table2 /deep/ .el-table__body-wrapper::-webkit-scrollbar{
  //    /*width: 0;宽度为0隐藏*/
  //   width: 2px;
  // }
//     .data-table2/deep/.el-table--scrollable-y ::-webkit-scrollbar {
//   display: none;
// }


    .title {
      padding: 1rem 3rem;
      font-size: 1.5rem;
      font-weight: normal;
    }

    .el-table {
      // padding: 0 3rem;
      background-color: #fafafa;
    }

    .el-pagination {
      margin: 1rem 3rem;
      float: right;
    }

    .query-toolbar {
      background: #f4f6fa;
      height: 12rem;
      min-height: 12rem;
      width: 100%;
      min-width: 60rem;
      overflow: hidden;

      .info-item-btn {
        line-height: 4rem;
        margin: 4rem 3rem;
        // float: right;
        overflow: auto;
        min-height: 4rem;
      }
    }

    .info-item {
      margin-top: 2rem;

      .top-label {
        color: #999;
        font-size: 0.8rem;
        line-height: 1rem;
      }

      .bottom-label {
        color: #999;
        font-size: 0.8rem;
        line-height: 1rem;
      }

      .middle-component {
        height: 4rem;
        line-height: 4rem;
      }
    }

    .bar-vv {
      padding: 1rem 3rem;

      #myChart {
        width: 100%;
        height: 300px;

        div {
          width: 1189px;
        }
      }

      .noData {
        width: 20%;
        height: 50px;
        margin: 0 auto;
        color: #645d58;
      }
    }
    /deep/.data-table .cell{
    padding: 0!important;
    height: 70px;
    // line-height: 70px!important;
    text-align: center;
  }
  }
</style>
