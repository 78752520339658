<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">应用管理</span>
      </div>
      <div class="detail">
        <el-card class="box-card" shadow="never" v-if="addformAppData">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-row>
              <el-col :span="6" :offset="1">
                <el-form-item label="应用名称" prop="appName">
                  <el-input size="mini" v-model="ruleForm.appName" placeholder="请输入应用名称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" :offset="1">
                <el-form-item label="回调地址" prop="callBackUrl">
                  <el-input size="mini" v-model="ruleForm.callBackUrl" placeholder="请输入回调地址"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" style="margin-top:50px;margin-left:15%">
                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
        <!-- 有数据 -->
        <el-card class="box-card" shadow="never" v-else>
          <div style="width:90%;overflow:hidden;">
            <!-- <div style="width:16.66%;float:left;" v-for="(item,index) in newList" :key="index"> -->
            <div style="float:right;">
              <el-button v-if="editTitle" type="text" size="small" icon="el-icon-edit" class="select-button"
                @click="editData">编辑</el-button>
              <el-button v-else type="text" size="small" icon="el-icon-check" class="select-button" @click="editData">提交
              </el-button>
              <!-- <el-button type="primary" icon="el-icon-edit" size="mini" @click="editData"></el-button> -->
              <el-button icon="el-icon-delete" type="text" size="mini" @click="delData">删除</el-button>
            </div>

            <div style="width:100%;float:left;">
              <div class="grid-content bg-purple-dark">
                <div>
                  <span class="new-span">应用名称:</span><span class="new-vv">
                    <el-input placeholder="请输入内容" style="width:15rem;margin-left:0.5rem;" size="mini" v-model="appName"
                      :disabled="disabled">
                    </el-input>
                  </span>
                </div>
                  <div style="margin-top:20px">
                  <span class="new-span">应用秘钥:</span><span class="new-vv" style="color:#66b1ff"
                    @click="addApp"> 重置</span>
                  <el-tooltip class="item" effect="light" content="点击重置可更换应用秘钥，请及时保存" placement="top-start">
                    <i class="el-icon-info" style="margin-left: 5px"></i>
                  </el-tooltip>
                </div>
                <div style="margin-top:20px">
                  <span class="new-span">AES秘钥:</span><span class="new-vv" style="color:#66b1ff" @click="addAes">
                    重置</span>
                  <el-tooltip class="item" effect="light" content="点击重置可更换AES秘钥，请及时保存" placement="top-start">
                    <i class="el-icon-info" style="margin-left: 5px"></i>
                  </el-tooltip>
                </div>
                 <div style="margin-top:20px">
                  <span class="new-span">APPid:</span><span class="new-vv">
                    <el-input placeholder="请输入内容" size="mini" style="width:15rem;margin-left:0.5rem;"
                      v-model="APPid" :disabled="disabled">
                    </el-input>
                  </span>
                </div>
                <div style="margin-top:20px">
                  <span class="new-span">回调地址:</span><span class="new-vv">
                    <el-input placeholder="请输入内容" size="mini" style="width:15rem;margin-left:0.5rem;"
                      v-model="callBackUrl" :disabled="disabled">
                    </el-input>
                  </span>
                </div>
                <div style="margin-top:20px">
                  <span class="new-span">是否启用回调地址:</span><span class="new-vv">
                    <el-switch
                        :disabled="disabled"
                        v-model="value"
                        active-color="#13ce66"
                        inactive-color="#9a9a9a">
                    </el-switch>
                  </span>
                </div>
                <div style="margin-top:20px">
                  <span class="new-span">创建时间:</span><span class="new-vv" style="color:#66b1ff"> {{createTime}}</span>
                </div>
                <div style="margin-top:20px">
                  <span class="new-span">更新时间:</span><span class="new-vv" style="color:#66b1ff"> {{updateTime}}</span>
                </div>

              </div>

            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "thirdPartyApplications",
    data() {
      return {
        newList: [],
        addformAppData: true,
        editTitle: true,
        createTime: "",
        callBackUrl: "",
        APPid:"",
        updateTime: "",
        appName: "",
        value:"",
        disabled: true,
        ruleForm: {
          appName: "",
          callBackUrl: "",

        },
        rules: {
          appName: [{
            required: true,
            message: "请输入应用名称",
            trigger: "blur"
          }],
          callBackUrl: [{
            required: true,
            message: "请输入回调地址",
            trigger: "blur"
          }],
        }
      };
    },
    created() {

    },
    mounted() {
      this.$api.getThirdPlatformAppData().then(res => {
        console.log(res.data.data)
        if (res.data.data) {
          console.log('有数据')
          this.addformAppData = false
          this.createTime = res.data.data.createTime
          this.appName = res.data.data.appName
          this.callBackUrl = res.data.data.callBackUrl
          this.updateTime = res.data.data.updateTime
          this.APPid = res.data.data.appId
          this.value = res.data.data.urlIsAvailable == 1 ? true : false
        } else {
          console.log('新增')
          this.addformAppData = true
        }
      });

    },
    methods: {
      // 修改
      editData() {
        this.disabled = !this.disabled
        this.editTitle = !this.editTitle
        if (this.editTitle == true) {
          console.log('提交')
          this.$api.editThirdPlatformAppData({
            appName: this.appName,
            callBackUrl: this.callBackUrl,
            urlIsAvailable:this.value ? "1" : "0"
          }).then(res => {
            if (res.data.code == '200') {
              console.log(res.data.data)
              this.$message({
                message: '修改成功',
                type: 'success'
              });
                 this.$api.getThirdPlatformAppData().then(res => {
        console.log(res.data.data)
        if (res.data.data) {
          console.log('有数据')
          this.addformAppData = false
          this.createTime = res.data.data.createTime
          this.appName = res.data.data.appName
          this.callBackUrl = res.data.data.callBackUrl
          this.updateTime = res.data.data.updateTime
        } else {
          console.log('新增')
          this.addformAppData = true
        }
      });

            } else {

            }

          });
        }
      },
      // 删除
      delData() {
        this.$api.delThirdPlatformAppData().then(res => {
          console.log(res.data.data)
          this.$message({
            message: '删除成功',
            type: 'info'
          });
          this.addformAppData = true
        });
      },
      addAes() {
        this.$api.regenerateAes128Key().then(res => {
          console.log(res.data.data)
          this.$alert(res.data.data, 'AES秘钥', {
            confirmButtonText: '确定',
            callback: action => {
              // this.$message({
              //   type: 'info',
              //   message: `action: ${ action }`
              // });
            }
          });
        });
      },

      addApp() {
        this.$api.regenerateThirdPlatformAppSecret().then(res => {
          console.log(res.data.data)
          this.$alert(res.data.data, '应用秘钥', {
            confirmButtonText: '确定',
            callback: action => {}
          });
        });
      },
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.$api.addThirdPlatformAppData({
              appName: this.ruleForm.appName,
              callBackUrl: this.ruleForm.callBackUrl,
            }).then(res => {
              if (res.data.code == 200) {
                this.$message.success("提交成功!");
                this.addformAppData = false
                console.log(res.data.data)
                this.$api.getThirdPlatformAppData().then(res => {
                  console.log(res.data.data)
                  if (res.data.data) {
                    console.log('有数据')
                    this.createTime = res.data.data.createTime
                    this.appName = res.data.data.appName
                    this.callBackUrl = res.data.data.callBackUrl
                    this.updateTime = res.data.data.updateTime
                  } else {

                  }
                });
                //  this.createTime = res.data.data.createTime
                //   this.appName = res.data.data.appName
                //   this.callBackUrl = res.data.data.callBackUrl
                //   this.updateTime  = res.data.data.updateTime
                this.ruleForm.appName = ""
                this.ruleForm.callBackUrl = ""
                // this.$alert(res.data.data.appSecret , '推送对应的 app_secret',res.data.data.encryptionKey , 'AES秘钥',{
                this.$alert("应用秘钥：" + res.data.data.appSecret + '<br>' + "AES秘钥：" + res.data.data.encryptionKey + '<br>' + "APPid：" + res.data.data.appId, '秘钥信息,请及时保存', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                    callback: action => {}
                  });
              }
            });
          } else {
            console.log("error submit!!");

            return false;
          }
        });
      },
      resetForm(ruleForm) {
        this.$refs[ruleForm].resetFields();
      },
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;


    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }
    }

    .grid-content {
      border-radius: 4px;
      min-height: 36px;
      padding: 0rem 3rem;
    }

    .new-span {
      font-size: 12 /16rem;
      color: #898989;
    }

    .newSpan {
      font-size: 12 /16rem;
      color: red;
    }

    .new-vv {
      font-size: 13 / 16rem;
      margin-top: 5 /16rem;
      cursor: pointer;
      /*鼠标悬停变小手*/
    }

    .newVv {
      font-size: 14 /16rem;
      margin-top: 5 /16rem;
      color: whitesmoke;
    }


  }
</style>
