<template>
    <div class="outting">
        <div class="left-card">

            <div class="left_tit">
                <h3 style=";margin-left:2rem">短信分类统计</h3>


                <span style="font-size:12px;;margin-left:2rem">时间 ：</span>
                <el-date-picker style="width: 22.25rem;;margin-right:2rem;margin-top:10px" clearable v-model="codeTime"
                    range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" size="mini"
                    value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="daterange" @change="getTimeBlock" >
                </el-date-picker>
                <el-date-picker v-model="codeTimemouth" size="mini"  value-format="yyyy-MM" format="yyyy-MM"  type="monthrange"   @change="getTimemouth"
                    style="width: 22.25rem;;margin-right:2rem;margin-top:10px" range-separator="至"
                    start-placeholder="开始月份" end-placeholder="结束月份">
                </el-date-picker>
                <el-button size="mini" type="primary" @click="codeStatistics">搜索</el-button>
            </div>
            <div class="charts" id="rightTop"></div>
        </div>



    </div>
</template>
<script>
    export default {
        name: "statistics",
        data() {
            return {
                codeTime: '',
                codeTimemouth: '',
                dateType: '0',
            };
        },
        methods: {
            getCurrentMonthFirst () {

var date = new Date()

date.setDate(1)

var month = parseInt(date.getMonth() + 1)

var day = date.getDate()

if (month < 10) month = '0' + month

if (day < 10) day = '0' + day

this.codeTimemouth[0] = this.codeTimemouth[0] + '-' + '01' + '-' + "00:00:00"

},

getCurrentMonthLast () {

var date = new Date()

var year = date.getFullYear()

var month = date.getMonth() + 1

month = month < 10 ? '0' + month : month

//  var day = new Date(year, month, 0).getDate();
// var day = new Date(this.codeTimemouth[1].substring(0,4), this.codeTimemouth[1].substring(5,7), 0)
 var day = this.getLastDay(this.codeTimemouth[1].substring(0,4),this.codeTimemouth[1].substring(5,7))
console.log(this.getLastDay(this.codeTimemouth[1].substring(0,4),this.codeTimemouth[1].substring(5,7)))
// console.log(this.codeTimemouth[1].substring(0,4), this.codeTimemouth[1],this.codeTimemouth[1].substring(5,7),day)
this.codeTimemouth[1] = this.codeTimemouth[1] + '-' + day + '-' + '23:59:59'

},

getLastDay(year, month){
  
    var d = new Date(0);
    if (month == 12)
    {
        d.setUTCFullYear(year + 1);
        d.setUTCMonth(0);
    }
    else
    {
        d.setUTCFullYear(year);
        d.setUTCMonth(month);
    }
    d.setTime(d.getTime() - 1);
    return d.getUTCDate();
},
            handleOpen(key, keyPath) {
                // console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                // console.log(key, keyPath);
            },
            getTimeBlock() {
            this.codeTimemouth = ''
            },
               getTimemouth() {
                //   console.log(val)
            this.codeTime = ''
        
            },
            getTime(val) {
                //   获取时间并格式化
                // 获取前val天的时间
                var dt = new Date();
                val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + "").padStart(2, "0");
                const d = (dt.getDate() + "").padStart(2, "0");
                const hh = (dt.getHours() + "").padStart(2, "0");
                const mm = (dt.getMinutes() + "").padStart(2, "0");
                const ss = (dt.getSeconds() + "").padStart(2, "0");

                // return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
                return `${y}-${m}-${d}`; // 获取的时间格式为 2021-04-09
                // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
                // return y + m + d  // 获取的时间格式为  20210409
            },
            codeStatistics() {
                let startTime =''
                let endTime =''
                if( this.codeTime != ''){
                      startTime= this.codeTime[0]
                        endTime= this.codeTime[1]
                        this.dateType = '0'
                }else{
                    this.getCurrentMonthFirst()
                    this.getCurrentMonthLast()
                    console.log(this.codeTimemouth)
                   
                     startTime= this.codeTimemouth[0]
                        endTime= this.codeTimemouth[1]
                        // [this.getTime(7), this.getTime(0)]

                          this.dateType = '1'
                }
                this.$api
                    .statisticsSmsList({
                        startTime: startTime,
                        endTime: endTime,
                        dateType: this.dateType
                    })
                    .then(res => {
                        console.log(res.data.data)
                        this.echarts2 = this.$echarts.init(document.getElementById("rightTop"));
                        let option2 = {
                            tooltip: {
                                trigger: 'axis'
                            },
                            grid: {

                                //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算

                                // top: "2%",

                                left: "10%",

                                right: "10%",

                                bottom: "10%"

                            },
                            legend: {
                                data: ['报警数量', '模组欠压故障数量',
                                    '报警流控数量', '探测器丢失故障数量', '报警备用数量','总计'
                                ]
                            },
                            // backgroundColor: 'rgba(255, 173, 177, 0.2)', //背景色
                            xAxis: {
                                type: 'category',
                                axisLine: {
                                    onZero: false,
                                    lineStyle: {
                                        color: '#8b8aa2',
                                    }
                                },

                                // color:,
                                data: res.data.data.dateList
                            },
                            yAxis: {
                                type: 'value',
                                color: '#fff',
                                axisLabel: {
                                    // formatter: '{value} 个'
                                },
                                axisLine: {
                                    onZero: false,
                                    lineStyle: {
                                        color: '#8b8aa2',
                                    }
                                },
                            },
                            series: [{
                                    name: "报警数量",
                                    data: res.data.data.alarmNumberList,
                                    type: 'bar',
                                    stack: 'Total',
                                    smooth: true,
                                    lineStyle: {
                                        width: 1
                                    },
                                    showSymbol: false,
                                    itemStyle: {
                                        color: '#2952ce'
                                    },
                                    areaStyle: {
                                        normal: {
                                            color: '#2952ce' //改变区域颜色
                                        },
                                    },
                                    barWidth: 30,
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    smooth: true
                                },
                                {
                                    name: "模组欠压故障数量",
                                    data: res.data.data.underVoltageFaultModuleNumberList,
                                    type: 'bar',
                                    stack: 'Total',
                                    smooth: true,
                                    lineStyle: {
                                        width: 1
                                    },
                                    showSymbol: false,
                                    itemStyle: {
                                        color: '#6cad2b'
                                    },
                                    barWidth: 30,
                                    areaStyle: {
                                        normal: {
                                            color: '#6cad2b' //改变区域颜色
                                        },
                                    },
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    smooth: true
                                }, {
                                    name: "报警流控数量",
                                    data: res.data.data.limitNumberList,
                                    type: 'bar',
                                    stack: 'Total',
                                    smooth: true,
                                    lineStyle: {
                                        width: 1
                                    },
                                    barWidth: 30,
                                    showSymbol: false,
                                    itemStyle: {
                                        color: '#F04A4E'
                                    },
                                    areaStyle: {
                                        normal: {
                                            color: '#F04A4E' //改变区域颜色
                                        },
                                    },
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    smooth: true
                                }, {
                                    name: "探测器丢失故障数量",
                                    data: res.data.data.detectorLossFaultNumberList,
                                    type: 'bar',
                                    stack: 'Total',
                                    smooth: true,
                                    lineStyle: {
                                        width: 1
                                    },
                                    barWidth: 30,
                                    showSymbol: false,
                                    itemStyle: {
                                        color: '#48e2be'
                                    },
                                    areaStyle: {
                                        normal: {
                                            color: '#48e2be' //改变区域颜色
                                        },
                                    },
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    smooth: true
                                }, {
                                    name: "报警备用数量",
                                    data: res.data.data.backNumberList,
                                    type: 'bar',
                                    stack: 'Total',
                                    smooth: true,
                                    barWidth: 30,
                                    lineStyle: {
                                        width: 1
                                    },
                                    showSymbol: false,
                                    itemStyle: {
                                        color: '#f2c130'
                                    },
                                    areaStyle: {
                                        normal: {
                                            color: '#f2c130' //改变区域颜色
                                        },
                                    },
                                    emphasis: {
                                        focus: 'series'
                                    },
                                    smooth: true
                                },
                                {
                                    name: '总计',
                                    type: 'bar',
                                    stack: '',
                                    data: res.data.data.smsTotalList,
                                    barWidth: 30,
                                    label: {
                                        normal: {
                                            show: true,
                                            position: 'top',
                                            color: '#000'
                                        },
                                    },
                                    z: -1,
                                    //不同系列的柱间距离，为百分比,如果想要两个系列的柱子重叠，可以设置 barGap 为 '-100%'。
                                    barGap: '-100%',
                                }
                            ]

                        };
                        this.echarts2.setOption(option2);

                    })
                    .catch(error => {
                        console.log(error);
                    });
            },

        },
        created() {

            this.codeTime = [this.getTime(7), this.getTime(0)]

        },
        mounted() {
            this.codeStatistics()
        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        // display: flex;
        text-align: center;
        // justify-content: space-around;
        background-color: #fafafa;

        .left-card {
            width: 97%;

            // margin-top: 1rem;
            // margin: 1rem auto;
            .left_tit {
                text-align: left;
                margin: 20px 20px;
            }

            .charts {
                width: 100%;
                height: 58vh;
                margin-top: 30px;
                margin-bottom: 3rem;
                min-width: 280px;
                min-height: 210px;
            }
        }

        .addRoleButton {
            float: right;
            color: rgb(92, 90, 90);
            margin-top: -1rem;
            //   font-size: 0.8rem;
        }

    }
</style>