<template>
	<div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
      <!-- <router-view/> -->
	</div>
</template>

<script>
	export default {
		name: 'app'
	}
</script>

<style>
	/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
	body {
		margin: 0;
    overflow: auto;
	}

	/* 解决表头和内容无法对齐的问题 */
	body .el-table th.gutter {
		display: table-cell !important;
	}

  @font-face {
    font-family: 'iconfont';
    src: url('./assets/fonts/iconfont/iconfont.ttf?t=1626659053853') format('truetype');
  }

  body::-webkit-scrollbar{
    width:3px;
  }

  body::-webkit-scrollbar-track{
    background: #FAFAFA;
  }

  body::-webkit-scrollbar-thumb{
    background:rgba(193, 189, 189,0.7);
  }

</style>
