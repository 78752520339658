<template>
    <div>
        <div class="outting">
            <div class="select">
                <span class="title">新增帮助中心内容</span>
                <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回帮助中心列表</el-button>
            </div>
            <div class="detail">
                <el-card class="box-card" shadow="never">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                        <el-row>
                            <el-col :span="7" :offset="0">
                                <el-form-item label="选择帮助中心类型" prop="releaseTime">
                                    <el-select v-model="ruleForm.categoryId" placeholder="请选择"  style="width:100%;margin-bottom:10px">
                                        <el-option v-for="item in options" :key="item.id" :label="item.categoryName"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                           
                           <el-col :span="7">
                                <el-form-item label="选择预置热点问题" prop="title">
                                   <el-select v-model="ruleForm.hotHelpDoc" placeholder="请选择"  style="width:100%;margin-bottom:10px">
                                        <el-option  label="不是热点问题" value="0"> </el-option>
                                        <el-option  label="热点问题" value="1"> </el-option>
                                    </el-select>
                                       </el-form-item>
                            </el-col>
                             </el-row>
                  <el-row>      
                          <el-col :span="10">
                                <el-form-item label="主标题" prop="title">
                                    <el-input v-model.trim="ruleForm.title" style="width:100%;margin-bottom:10px"
                                        placeholder="请输入主标题"  type="textarea"></el-input>
                                </el-form-item>
                            </el-col>
                              </el-row>
                        <el-form-item label="新增内容编辑" prop="content">
                            <editor-bar :uri='uri' v-model="ruleForm.content" :isClear="isClear" @change="change">
                            </editor-bar>
                        </el-form-item>
                        <el-row>
                            <el-col :span="10" style="margin-top:50px;margin-left:30%">
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <!-- <editor-bar v-model="content" :isClear="isClear"></editor-bar> -->
                    <!-- <div id="div1" height="500px">

                    </div> -->
                    <!-- <div>
                        <editor-bar v-model="detail" :isClear="isClear" @change="change"></editor-bar>
                    </div> -->


                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
import EditorBar from "../../../components/wangEnduit";

import E from "wangeditor";
export default {
  name: "appAddPlan",
  components: {
    EditorBar
  },
  data() {
    return {
    
      formLabelWidth: "120px",
      options: [],
      detail: "",
      content: "",
      isClear: false, //清除富文本编辑器内容
      // 默认步骤数
      limitUpload: 1,
      limitUpload2: 1,
      fileList: [],
      fileMulLis: [],
      stepActive: "stepActive",
      uri: "",
      dialogImageUrl: "",
      dialogVisible: false,
      value: "",
      file: {},
      dialogImageUrl2: "",
      dialogVisible2: false,
      value2: "",
      file2: {},

      ruleForm: {
        categoryId: "",
        title: "",
        hotHelpDoc: "",
        content: ""
      },
      rules: {
        categoryId: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ],
        title: [
          {
            required: true,
            message: "请输入",
            trigger: "blur"
          },
          {
                            min: 0,
                            max: 15,
                            message: '长度在15个字符以内',
                            trigger: 'blur'
                        }
        ],
        content: [
          {
            required: true,
            message: "请填写",
            trigger: "blur"
          }
        ],
        hotHelpDoc: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ]
      }
    };
  },
  created() {
    this.uri = "api/iotServer/sysNews/addPic";
  },
  mounted() {
      this.getType()
  },
  methods: {
    //查询设备列表
    getType() {
      this.$api
        .categoryList()
        .then(res => {
          this.options = res.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    change(val) {
      console.log(val);
      this.ruleForm.newsContent = val;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$api.helpCenterAdd(this.ruleForm).then(res => {
            if (res.data.code == 200) {
              this.$message.success("新增成功!");
              this.$nextTick(() => {
                this.$router.push("/helpCenter");
                console.log("00000");
                this.$refs["ruleForm"].clearValidate();
                // this.value = "";
                // this.value2 = "";
                // this.file = {};
                // this.file2 = {};
                // this.fileList = [];
                // this.ruleForm.newsSubtitle = "";
                // this.ruleForm.newsTitle = "";
                // this.ruleForm.releaseTime = "";
                // this.ruleForm.contentUrl = "";
                // this.ruleForm.newsContent = "";
              });
            } else if (res.data.code == 10110008) {
            
            }
          });
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //回到模块页面
    gobackPlaceDetail() {
      this.$router.push("/helpCenter");
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;

.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;

  .item {
    height: 80px;
    width: 80px;
    border: 1px solid blue;
    float: left;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }

  .detail {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .detail-span {
      font-size: 12 /16rem;
      color: #898989;
    }

    .detail-vv {
      margin-top: 5 /16rem;
      font-size: 14 /16rem;
    }
  }
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

//   .bg-purple {
//     background: #d3dce6;
//   }
.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.stepShow {
  width: 80%;
  margin: 3rem auto;

  .downloadList {
    //  transform:translate(5.5rem, -4rem)
  }

  /deep/.el-step.is-horizontal.stepActive {
    .el-step__head.is-finish {
      .el-step__line {
        // 当前步骤数横线样式设置
        .el-step__line-inner {
          width: 50% !important;
          border-width: 1px !important;
        }
      }

      // 当前步骤数圆圈样式设置
      .el-step__icon.is-text {
        background: #409eff;
        color: #fff;
      }
    }
  }
}

.outting .item {
  margin: 10px;
  height: auto;
  width: auto;
  cursor: pointer;
  border: none;
}

.right .el-tooltip__popper {
  padding: 8px 10px;
  border: none;
  cursor: pointer;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>