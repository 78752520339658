
<template>
  <div>
    <el-container>
      <el-main class="row-margin" style="overflow-x:hidden;">
        <el-dialog title="请选择要修改成的产品型号" :visible.sync="dialogVisible" width="48rem">
          <div class="device-content">
            <div class="device-info-item">
              <div>
                <div class="label-info">
                  <label>
                    <span>*</span>产品类型
                  </label>
                </div>
                <el-select v-model="productId" placeholder="请选择" size="small">
                  <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" size="small">取 消</el-button>
            <el-button type="primary" @click="commitUpdate()" size="small">确 定</el-button>
          </span>
        </el-dialog>

        <div class="select">
          <span class="title">批量修改设备</span>
             <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回注册列表</el-button>
        </div>
        <div class="content">
          <div style="width:100%;display:flex;height:2rem;">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;">
              <span class="content-span">导入设备名称:</span>
            </div>
            <div style="flex:4.5;font-size:.8rem;line-height:2rem;font-size: 0.6rem;color: gray;">
              <el-upload
                class="upload-demo"
                action
                :on-change="handleChange"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                accept=".xls, .xlsx"
                :file-list="fileList"
                :limit="limitUpload"
                :auto-upload="false"
              >
                <el-button type="primary" round size="mini" class="input-button">导入</el-button>
                <span style="color: red">*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式
              </el-upload>
            </div>
            <div style="flex:1;line-height:2rem;">
              <el-button
                type="primary"
                round
                size="mini"
                class="query-button"
                @click="showAddDeviceDialog"
              >开始修改</el-button>
            </div>
          </div>

          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;height:2rem;">
              <span class="content-span">导入结果:</span>
            </div>
            <div style="flex:5.5;">
              <textarea class="input-height" disabled="disabled" v-model="tableData"></textarea>
            </div>
          </div>



          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;">
              <span class="content-span">修改成功：</span>
            </div>
            <div style="flex:5.5;">
             <textarea class="input-height" disabled="disabled" v-model="successMac"></textarea>
            </div>
          </div>

          <div class="content-vv">
            <div style="flex:1;font-size:.8rem;text-align:right;line-height:2rem;">
              <span class="content-span">修改失败：</span>
            </div>
            <div style="flex:5.5;">
              <textarea class="input-height" disabled="disabled" v-model="failedMac"></textarea>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Storage from "../../../utils/Storage";
export default {
  name: "lotDeleteDevice",
  data() {
    return {
      span: 4,
      limitUpload: 1,
      tableData: [],
      fileList: [],
      options:[],
      dialogVisible: false,
      successMac: "",
      failedMac: "",
      productId:""

    };
  },
  methods: {

    commitUpdate() {
      // let macs = this.tableData.toString().replace(/,/g, "-");
      // macs.replace("[", "");
      // macs.replace("]", "");
      this.$api
        .wifiUpdateDevices({
          imeiList: this.tableData,
          productId: this.productId
        })
        .then(res => {
          if (res.data.code == "200") {
            this.$message({
              message: "修改完成",
              type: "success"
            });
            this.successMac = res.data.data.imeiSucc;
            this.failedMac = res.data.data.imeiFail;
             this.dialogVisible = false;

          }
        })
        .catch(error => {});
    },
    showAddDeviceDialog() {
      this.dialogVisible = true;
      //请求后台接口，获取产品列表
      this.$api
          .productListForPullUrl({
            page: {
              current: 1,
              size: 1000
            }
          })
          .then(res => {
            this.options = res.data.data;
          })
          .catch(error => {});
    },
        //回到模块页面
            gobackPlaceDetail() {
                this.$router.push("/wifiDeviceRegisterList");
            },

    handleChange(file, fileList) {
      this.fileTemp = file.raw;
      if (this.fileTemp) {
        this.importfxx(this.fileTemp);
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！"
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },

    //移除文件的操作方法
    handleRemove(file, fileList) {
      this.fileTemp = null;
      this.tableData = [];
    },
    importfxx(obj) {
      let _this = this;
      let inputDOM = this.$refs.inputer;
      // 通过DOM取文件数据
      this.file = event.currentTarget.files[0];
      var rABS = false; //是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();

      //if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function(f) {
        var binary = "";
        var rABS = false; //是否将文件读取为二进制字符串
        var pt = this;
        var wb; //读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function(e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          //此处引入，用于解析excelnpm
          var XLSX = require("xlsx");

          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              //手动转化
              type: "base64"
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary"
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
          //此处可对数据进行处理
          let arr = [];
          let arr2 = [];
          outdata.map(v => {
            let mac = v["MAC"];
            if (mac != "" && !arr.find(imei => imei === mac)) {
              arr.push(mac);
            }
          });
          _this.tableData = arr;
          _this.$message({
            type: "success",
            message: "导入成功，共导入" + arr.length + "个有效设备名称"
          });
          return arr;
        };
        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    }
  },
  mounted() {

  }
};
</script>

<style lang="less" scoped>
textarea {
  outline: none;
  border: 0px;
  background-color: #fafafa;
  font-size: 0.8rem;
}
.row-height {
  height: 4rem;
}
.row-margin {
  margin-bottom: 0.5rem;
}
.left-title {
  font-size: 0.8rem;
  line-height: 3rem;
  text-align: right;
  margin-right: 1rem;
}
.input-small-height {
  padding-left: 5px;
  height: 1.7rem;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 0.5rem;
}
.input-height {
  height: 12rem;
  margin-top: 0.6rem;
  width: 99%;
  border: 1px solid #d3d3d3;
  border-radius: 1rem;
}
.desc {
  line-height: 3rem;
  font-size: 0.6rem;
  color: gray;
}
.query-button {
  float: right;
  width: 6rem;
  height: 1.7rem;
  font-size: 0.7rem;
  min-height: 20px;
  min-width: 60px;
}
.input-button {
  width: 6rem;
  height: 1.7rem;
  font-size: 0.7rem;
  min-height: 20px;
  min-width: 60px;
}
.device-content {
  height: 6rem;

  .device-info-item {
    float: left;
    margin-right: 3rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 12rem;
    }
  }
}
  .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            button {
                float: right;
              color: #333;
                margin-top: 7px;
            }
        }
.content {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .content-span {
    display: inline-block;
    margin-right: 10%;
  }
  .content-vv {
    width: 100%;
    display: flex;
    margin-top: 1rem;
  }
}
</style>
