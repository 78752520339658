<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">卡到期续费管理</span>
      </div>
      <div class="detail">
        <el-card class="box-card" shadow="never">
          <h5>卡到期通知</h5>
          <span class="new-span">时间选择: <el-tooltip class="item" effect="light" content="点击添加，可再次选择一个续费提示时间"
              placement="right-end">
              <i class="el-icon-info" style="margin-left: 5px;margin-right:15px"></i>
            </el-tooltip></span>
          <!-- <el-input size="mini" clearable style="width:10rem;margin-bottom:1rem"
            v-model="accessNumber" placeholder="请输入接入号码："></el-input> -->
          <div v-for="(item,i) of items" :key="i" class="new_add">
            <el-input type="text" oninput="value=value.replace(/[^\d]/g,'')" size="mini" clearable
              style="width:5rem;margin-bottom:1rem;margin-right:1rem" v-model="items[i]"></el-input>
            <span>天</span>
            <el-button size="mini" style="margin-left:3rem" type="info" @click="onAdd()">新增续费时间</el-button>
            <el-button size="mini" style="margin-left:3rem" @click="deleteFn(items[i])">删除</el-button>

          </div>
          <el-button size="mini" class="new_submit" style="margin-left:10rem" type="primary"
            @click="inquireAccessNumber()">提交</el-button>
          <br>

        </el-card>

      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "cardExpiration",
    data() {
      return {
        accessNumber: "",
        items: [
""
        ],

      };
    },
    created() {

    },
    mounted() {
      this.$api
        .cardRechargeNotifyList()
        .then(res => {
          console.log(res.data.data)
           this.items = res.data.data
           if( this.items  == null){
                 this.items = ['']
           }
          // if (res.data.data) {
          //    this.tableData = res.data.data
          // }
        });

    },
    methods: {
      // 卡资料信息
      inquireAccessNumber() {
        let itemslist = []
        for (let index = 0; index < this.items.length; index++) {
          // console.log(this.items[index])
          if(this.items[index] == 0|| this.items[index] == []){
            
               this.$message.error("续费时间不能为0或空!");
             return
          }
          itemslist.push(
            Number(this.items[index])
          )
        }
        console.log(itemslist)
        this.$api
          .cardRechargeNotify(
            itemslist
          )
          .then(res => {
            console.log(res.data.data)
            // if (res.data.data) {
            //    this.tableData = res.data.data

            // }
               this.$message({
                            type: "success",
                            message: "成功添加续费提醒"
                        });
          });
      },
      onAdd() {
        this.items.push('')
      },
      deleteFn(item) {
        console.log(item)
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i] == item) {
            this.items.splice(i, 1)
          }
        }
      },

      resetForm(ruleForm) {
        this.$refs[ruleForm].resetFields();
      },
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;
    font-size: 0.8rem;
    color: #898989;

    .box-card {
      width: 96%;
      margin: 1rem auto;
    }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }
    }

    .grid-content {
      border-radius: 4px;
      min-height: 36px;
      padding: 0rem 3rem;
    }

    .new-span {
      font-size: 12 /16rem;
      //   color: #898989;
    }

    .newSpan {
      font-size: 12 /16rem;
      color: red;
    }

    .new-vv {
      font-size: 13 / 16rem;
      margin-top: 5 /16rem;
      //   cursor: pointer;
      margin-right: 4rem;

      /*鼠标悬停变小手*/
    }

    .newVv {
      font-size: 14 /16rem;
      margin-top: 5 /16rem;
      color: whitesmoke;
    }

    .new_add {
      margin-left: 5rem;
      //  text-align: center;
    }

    .new_submit {
      float: right;
    }
  }
</style>