import { render, staticRenderFns } from "./versionUpTipList.vue?vue&type=template&id=f1adbc40&scoped=true&"
import script from "./versionUpTipList.vue?vue&type=script&lang=js&"
export * from "./versionUpTipList.vue?vue&type=script&lang=js&"
import style0 from "./versionUpTipList.vue?vue&type=style&index=0&id=f1adbc40&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1adbc40",
  null
  
)

export default component.exports