<template>
    <div class="fireAlarmNn">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click.native="backTo">
                    {{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"产品中心"}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color:#f28c02">
                        产品详情
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="major_service">
            <div class="item-title">
                <p> 独立式感烟火灾探测报警器</p>
                <div>
                    <img src="../../../assets/login/JTY-GF-WSH6101N.png" alt="" class="image">
                    <span class="text_list" style="margin-bottom:30px"> 
                        (JTY-GF-WSH6101N)
                    </span>
                </div>
                <div class="level_subject">
                    概述
                </div>
                <div class="text_subject">
                    JTY-GF-WSH6101N独立式光电感烟火灾探测报警器（以下简称烟感）是北京青鸟美好生活科技有限公司开发的新一代独立式光电感烟火灾探测报警器，主要用来探测燃烧初期可产生烟尘微粒的火灾。适宜安装在普通住宅、别墅、古建筑及小型商场、家居、歌舞厅等场所，最佳安装位置为顶棚中心。本产品不宜安装在厨房、浴室等烟尘、水气密集的场所，且应避免毗邻壁炉、通风口等处。另外，应尽量偏离霓虹灯、电子节能灯等灯具设备（安全距离宜大于50厘米）。
                </div>
                <div class="level_subject">
                    产品特点
                </div>
                <div class="text_subject">
                    <ul>
                        <li> · <span>电池供电，独立式安装，无需布线，安装方便快捷。</span> </li>
                        <li> · <span>精确的烟雾监测及通过对迷宫信号的智能分析，保证其卓越的安全性能。</span> </li>
                        <li> · <span>高性能迷宫，有效减少设备误报。</span> </li>
                        <li> · <span>具备电池欠压。</span> </li>
                        <li> · <span>具备全电子式自动检测功能</span> </li>
                        <li> · <span>响应迅速，高分贝警讯输出报警，报警声：大于80dB（正前方3m）</span> </li>

                    </ul>
                </div>
                <div class="level_subject">
                    适用范围
                </div>
                <div class="text_subject">
                    <ul>
                        <li> · <span>烟感安装于现场，用于对现场火灾发生前期烟雾检测，在检测到烟雾并达到预设值后会发出火灾报警声。</span> </li>
                        <li> · <span>应用设计遵照国家标准GB 50116-2013 《火灾自动报警系统设计规范》。</span> </li>
                        <li> · <span>适宜安装在普通住宅、别墅、古建筑及小型商场、家居、歌舞厅等场所，最佳安装位置为顶棚中心。</span> </li>

                    </ul>
                </div>
                <div class="level_subject">
                    工作原理
                </div>
                <div class="text_subject">
                    本产品由迷宫，控制芯片及其外围电路组成。能够实时监测烟雾，对环境进行周期性检测。一旦检测到烟雾，并且达到预定浓度值后，立即报警。
                </div>
                <div class="level_subject">
                    性能参数
                </div>
                <div class="text_subject">
                    <img src="../../../assets/login/details6101n.png" alt="" class="image">
                </div>
        <div class='botom_tit'>   *如需获取产品3c证书、检验报告、说明书等，
               可扫描底部公众号二维码进入“吻胜智能生活”公众号获取</div>
            </div>

            <div>

            </div>
        </div>


    </div>
</template>
<script>
  import '../../../../static/css/global.css'
    export default {
        name: "fireAlarmNn",
        components: {},
        data() {
            return {

            };
        },
        mounted() {
            window.addEventListener('scroll', this.scrollToTop)
            const that = this
            let ispeed = Math.floor(-that.scrollTop / 5)
            document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        },
        created() {},
        methods: {
            scrollToTop() {
                const that = this
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                that.scrollTop = scrollTop
                that.btnFlag = false
            },
            backTo() {
                if (this.$route.query.crumbsTitle == '首页') {
                    let vm = this
                    vm.$router.replace({
                        path: `/`
                    });
                } else {
                    this.$router.replace({
                        path: "/productCenter"
                    });
                }
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTop)
        },
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1370px) {
        .fireAlarmNn {
           background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;
            .text_subject{
                 font-size: 1.05rem;
                 line-height: 1.8rem;
                //   padding-bottom: 3rem;
                    padding-top: 1.5rem;
            }
             .level_subject{
                font-size: 1.15rem; 
            }
        }
    }

    @media screen and (min-width: 1370px) {
        .fireAlarmNn {
             background-color: #efefef;
            overflow-x: auto;
            min-width: 1300px;
             .text_subject{
                 font-size: 1.25rem;
                 line-height: 2.5rem;
                  padding-bottom: 0.5rem;
                    padding-top: 2rem;
            }
            .level_subject{
                font-size: 1.35rem; 
            }
        }
    }

    .breadcrumb {
        padding: 2rem 0 2rem 0;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;

        /deep/.el-breadcrumb {
            float: right;
        }

        /deep/.el-breadcrumb__inner:hover { 
            color: #f28c02;
        }
    }
 .botom_tit{
      text-align: left;
      width: 60%;
       margin: 0 auto;
         padding-bottom:  4rem;
         padding-left:  2rem;
  }
    .major_service {
        width: 100%;
        padding-bottom: 20px;

        .item-title {
            padding-top: 2rem;
            text-align: center;
            // color: #333333;
            width: 100%;
            margin: 0 auto;

            .image {
                width: 20%;
                display: block;
                margin: 0 auto;
            }

            p {
                text-shadow: 1px 1px 1px #666;
                display: inline-block;
                color: #333333;
                padding: 0 50px;
                font-size: 1.5em;
            }

            .text_list {
                display: inline-block;
                transform: translateY(-2rem);
               font-size: 1rem; 
                color: #333333;
            }

           .level_subject {
                text-align: left;
                width: 60%;
                letter-spacing: 4px;
                margin: 0 auto;
            //    font-size: 1.35rem; 
                color: #333333;
                font-weight: 600;
            }

            .text_subject {
                display: inline-block;
            //  font-size: 1.25rem;
                
                text-align: justify;
                width: 60%;
              
                margin: 0 auto;
                // text-align: left;
                color: #333333;
               
                // border: #f28c02 1px solid;
         
                img {
                    width: 100%;

                }

            }

        }



    }
</style>