<template>
  <div class="productCenter">
    <div style="margin: 0 auto;width: 50vw;min-height: 750px">
      <div style="text-align: center;color: black;padding-top: 25px;margin-bottom: 30px">
        <span style="font-weight: bold;font-size: 25px">资料下载</span>
        <p style="font-size: 13px">Data download</p>

      </div>
      <div style="display: flex">
        <div class="tab_flex" :class="{active_style: index == current}" v-for="(item,index) in typeOptions" @click="changeItem(index)">{{item.fileCategoryName}}</div>
      </div>
      <img style="width: 100%;" :src="lineSrc" />

          <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
            <el-tab-pane v-for="item in typeSecondOptions"  :label="item.fileCategoryName" :name="item.id">

            </el-tab-pane>
          </el-tabs>
         <el-row :gutter="24" style="margin-top: 10px;" >  <!--分栏间隔-->
           <el-col :span="8"  v-if="filesList" v-for="(item, index) in filesList" >  <!--共24份，xs超小型设备，sm小屏设备，md中屏，lg大屏-->
             <div style="text-align: center;margin-bottom: 30px">
               <div style="height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 18px;
            background: #efefef;">
                 <div style="color: black;" v-html="item.remark"></div>
               </div>
               <div style="width: 50%;height: 40px;line-height: 40px;text-align: center;
               margin-top: -20px;margin-left: 25%;background: #f28c02;color: white;cursor: pointer" @click="goPreview(item)">立即下载</div>
             </div>
           </el-col>
         </el-row>


    </div>
  </div>
</template>

<script>
import line1 from '../../assets/download/line1.svg'
import line2 from '../../assets/download/line2.svg'
import line3 from '../../assets/download/line3.svg'
export default {
  name: "downloadCenter",
  data() {
    return {
      activeName: '',

      filesList:[],
      typeOptions:[],
      typeSecondOptions:[],
      lineSrc:line1,
      current: 0,
    };
  },
  created() {
    this.getFileCategory()
  },
  methods: {
    goPreview(item){

      this.$router.push({
        path:"/filePreview",

        query:{
          src:item.fileUrl,fileName: item.remark,fileTitle: item.fileName
        }
      })

    },
    changeItem(index){
      if(index != this.current){
        this.current = index
        this.typeSecondOptions = this.typeOptions[index].sonFileCategorys
        this.activeName = this.typeSecondOptions[0].id
        if(index == 0){
          this.lineSrc = line1
        }else if(index == 1){
          this.lineSrc = line2
        }else if(index == 2){
          this.lineSrc = line3
        }
        this.getFilesList()
      }

    },
    handleClick(tab, event) {
      this.getFilesList()
    },
    getFileCategory(){
      this.$api
          .getFileCategoryList()
          .then(res => {

            this.typeOptions = res.data.data;
            this.typeSecondOptions = this.typeOptions[0].sonFileCategorys
            this.activeName = this.typeSecondOptions[0].id
            this.getFilesList()
          })
          .catch(error => {

          });
    },
    getFilesList() {
      //查询设备列表
      this.$api
          .fileManagementListWeb({
            fileQueryDto: {
              fileCategoryId:this.activeName
            }

          })
          .then(res => {
            this.filesList = res.data.data.records;


          })
          .catch(error => {
            console.log(error);
          });
    }
  }
}
</script>

<style lang="less" scoped >
@media screen and (min-width: 0px) and (max-width: 1200px) {
  .productCenter {
    background-color: #ffffff;
  // overflow-x: auto;
    min-width: 1000px;
  }
}

@media screen and (min-width: 1201px) {
  .productCenter {
    background-color: #ffffff;
  // overflow-x: auto;
    min-width: 1300px;
  }
}

.tab_flex{
  flex: 1;
  text-align: center;
  font-size: 18px;
  color: black;

  cursor: pointer
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #9a9a9a;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 66px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.active_style{
  color: #f28c02;
  font-weight: bold;
}
/deep/ .el-tabs__item {
  //切换活动项的文字颜色
  color: #7f7f7f !important;
  font-size: 18px;
  margin: 0;
  padding: 0;
}
/deep/ .el-tabs__item.is-active {
  //切换活动项的文字颜色
  color: #000 !important;
  font-weight: bold !important;
}

/deep/ .el-tabs__item.is-active:hover {
  //切换活动项的文字颜色
  color: #000 !important;

}
/deep/ .el-tabs__item:hover {
  //切换活动项的文字颜色
  color: #7f7f7f !important;
}
/deep/ .el-tabs__active-bar {
  background-color: transparent !important; //切换活动项的长条颜色
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px !important; //切换活动项的长条颜色
}


</style>
