<template>
    <div>
        <div class="outting">
            <div class="select">
                <span class="title">商品新增</span>
                <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回商品列表</el-button>
            </div>
            <div class="detail">
                <el-card class="box-card" shadow="never">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">

                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="商品名称" prop="name">
                                    <el-input v-model.trim="ruleForm.name" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品名称"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="商品编码" prop="name">
                                    <el-input v-model.trim="ruleForm.goodsNo" style="width:90%;margin-bottom:10px"
                                              placeholder="请输入商品编码"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                                <el-form-item label="商品原价(元)" prop="oriPrice">
                                    <el-input v-model.trim="ruleForm.oriPrice" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品原价"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="商品实际价格(元)" prop="actualPrice">
                                    <el-input v-model.trim="ruleForm.actualPrice" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品实际价格"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                                <el-form-item label="商品库存(个)" prop="stock">
                                    <el-input v-model.number="ruleForm.stock" style="width:90%;margin-bottom:10px"
                                        placeholder="请输入商品库存"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                      <el-row>
                        <el-col :span="10">
                          <el-form-item label="销量范围开始值" prop="salesVolumeStart">
                            <el-input v-model.trim="ruleForm.salesVolumeStart" style="width:90%;margin-bottom:10px"
                                      placeholder="请输入销量范围开始值"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="7" :offset="0">
                          <el-form-item label="销量范围结束值" prop="salesVolumeEnd">
                            <el-input v-model.number="ruleForm.salesVolumeEnd" style="width:90%;margin-bottom:10px"
                                      placeholder="请输入销量范围结束值"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="3" :offset="0">
                          <el-form-item label="是否展示随机销量" prop="salesVolumeEnd">
                            <el-switch
                                v-model="ruleForm.salesVolumeIsRandom"
                                active-color="#13ce66"
                                inactive-color="#9a9a9a">
                            </el-switch>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="10">
                          <el-form-item label="销量显示值" prop="installVideoUrl">
                            <el-input v-model.trim="ruleForm.salesVolumeShow" style="width:90%;margin-bottom:10px"
                                      placeholder="请输入销量显示值"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="10">
                          <el-form-item label="安装视频链接" prop="salesVolumeShow">
                            <el-input v-model.trim="ruleForm.installVideoUrl" style="width:90%;margin-bottom:10px"
                                      placeholder="请输入安装视频链接"></el-input>
                          </el-form-item>
                        </el-col>

                      </el-row>
                        <el-row>
                            <el-col :span="10">
                                <!-- <el-form-item label="商品列表图片" prop="newsTitle">
                                    <el-input v-model.trim="ruleForm.newsTitle" style="width:90%;margin-bottom:10px"
                                        placeholder="请上传商品列表图片"></el-input>
                                </el-form-item> -->
                            </el-col>
                            <el-col :span="10" >
                                <el-form-item label="发货时间" prop="deliveryTime">
                                    <el-input v-model.trim="ruleForm.deliveryTime" style="width:90%;margin-bottom:10px"
                                        placeholder="请填写发货时间"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="0">
                                <el-form-item label="上架时间" prop="shelfTime">
                                     <el-date-picker style="width:90%;margin-bottom:10px"  v-model="ruleForm.shelfTime"
                range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
                type="datetimerange" ></el-date-picker>
                                    <!-- <el-date-picker v-model="ruleForm.shelfTime" value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期">
                                    </el-date-picker> -->

                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>

                            <!-- <el-col :span="10" :offset="0">
                                <el-form-item label="下架时间" prop="offShelfTime">
                                    <el-date-picker v-model="ruleForm.offShelfTime" @change="compare()"
                                        value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" type="datetime"
                                        placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col> -->
                        </el-row>
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="商品列表图片" ref="formImg" prop="colFile">
                                    <el-upload action="#" :before-upload="beforeAvatarUpload" :on-change="handleChange"
                                        list-type="picture-card" v-show="!value" :show-file-list="false">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt />
                                    </el-dialog>
                                    <div v-show="value" class="el-upload-list el-upload-list--picture-card">
                                        <div class="el-upload-list__item is-success">
                                            <img class="avatar" v-show="value" :src="value"   />
                                            <label class="el-upload-list__item-status-label">
                                                <i class="el-icon-upload-success el-icon-check"></i>
                                            </label>
                                            <span class="el-upload-list__item-actions">
                                                <span class="el-upload-list__item-preview">
                                                    <i class="el-icon-zoom-in"
                                                        @click.stop="handlePictureCardPreview"></i>
                                                </span>
                                                <span class="el-upload-list__item-delete">
                                                    <i class="el-icon-delete" @click.stop="handleRemove"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>
                          <el-col :span="10">
                            <el-form-item label="上传主图视频" ref="formVideo">
                              <el-upload
                                  v-show="!videoPath"
                                  action
                                  class="upload-demo"
                                  ref="video"
                                  :before-upload="beforeUploadVideo"
                                  :limit="1"
                                  :auto-upload="false"
                                  :on-change="UploadVideo"
                                  :on-exceed="handleExceed"
                                  drag
                              >

                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                  将文件拖到此处，或<em>点击上传</em>
                                </div>


                              </el-upload>
                              <el-progress
                                  v-show="progressFlag"
                                  :percentage="loadProgress"
                              ></el-progress>
                              <video v-if="videoPath !='' && videoPath != null && videoPath != undefined"

                                     :src="videoPath"
                                     class="avatar"
                                     controls="controls">您的浏览器不支持视频播放</video>
                              <el-button @click="clearVideo()" v-if="videoPath !='' && videoPath != null && videoPath != undefined"  type="primary" round size="mini" class="input-button">替换视频</el-button>
                            </el-form-item>

                          </el-col>
                            <!-- <el-button @click="toBottom()">滚动到底部</el-button> -->
                            <el-col :span="10" :offset="0">
                                <el-form-item label="商品详情图片" ref="formImg2" prop="detailFiles">
                                    <el-upload class="upload-demo" action :on-change="handleChangeDetail"
                                        :on-remove="handleRemoveDetail" :on-exceed="handleExceed"
                                        list-type="picture-card" :file-list="fileList" :limit="limitUpload"
                                        :auto-upload="false" ref="my-upload">
                                        <el-button type="primary" round size="mini" class="input-button">上传附件
                                        </el-button>
                                        <br />
                                    </el-upload>
                                </el-form-item>
                            </el-col>

                        </el-row>
                        <el-form-item label="商品详情" prop="details" style="margin-bottom:50px">
                            <editor-bar :uri='uri' v-model="ruleForm.details" :isClear="isClear" @change="change">
                            </editor-bar>
                        </el-form-item>

                        <el-row>
                            <el-col style="margin-top:50px;">
                                <el-form-item class="footer">
                                    <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                                    <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>

                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
    import EditorBar from "../../components/appWangEnduit";

    import E from "wangeditor";
    import axios from "@/utils/http";
    import base from "@/api/base";
    export default {
        name: "addGood",
        components: {
            EditorBar
        },
        data() {
            var valiIcon = (rule, value, callback) => {
                if (!this.value) {
                    callback(new Error('请上传图片'));
                } else {
                    callback();
                }
            };
            var valiIcon2 = (rule, value, callback) => {
                //   console.log(value)
                if (!this.fileList[0]) {
                    callback(new Error('请上传图片'));
                } else {
                    callback();
                }
            };
            return {
                installVideoPath:"",
                videoPath:"",
                progressFlag: false,
                loadProgress: 0,
                installProgressFlag: false,
                installLoadProgress: 0,
                formLabelWidth: "120px",
                uri: "",
                detail: "",
                content: "",
                isClear: false, //清除富文本编辑器内容
                // 默认步骤数
                limitUpload: 5,
                limitUpload2: 1,
                fileList: [],
                fileMulLis: [],
                stepActive: "stepActive",

                dialogImageUrl: "",
                dialogVisible: false,
                value: "",
                file: {},
                dialogImageUrl2: "",
                dialogVisible2: false,
                value2: "",
                file2: {},
                colFile: "",
                ruleForm: {
                    name: "",
                    goodsNo:"",
                    oriPrice: "",
                    actualPrice: "",
                    stock: "",
                    deliveryTime: "",
                    shelfTime: "",
                    // offShelfTime: "",
                    colFile: "",
                    details: "",
                    detailFiles: '',
                  salesVolumeStart:"",
                  salesVolumeEnd:"",
                  salesVolumeShow:"",
                  installVideoUrl:"",
                  salesVolumeIsRandom:false,
                },
                rules: {
                    name: [{
                        required: true,
                        message: "请填写商品名称",
                        trigger: "blur"
                    }],
                    goodsNo: [{
                        required: true,
                        message: "请填写商品编码",
                        trigger: "blur"
                    }],
                    details: [{
                        required: true,
                        message: "请填写商品详情",
                        trigger: "blur"
                    }],
                    deliveryTime: [{
                        required: true,
                        message: "请填写发货时间",
                        trigger: "blur"
                    }],
                    shelfTime: [{
                        required: true,
                        message: '请选择上架时间',
                        trigger: 'change'
                    }],
                    // offShelfTime: [{
                    //     required: true,
                    //     message: '请选择下架时间',
                    //     trigger: 'change'
                    // }],
                    oriPrice: [{
                            required: true,
                            message: '请输入商品原价',
                            tigger: 'blur'
                        },
                        {
                            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
                            message: '请输入正确格式,可保留两位小数'
                        }
                    ],
                    actualPrice: [{
                            required: true,
                            message: '请输入商品实际价格',
                            tigger: 'blur'
                        },
                        {
                            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
                            message: '请输入正确格式,可保留两位小数'
                        }
                    ],
                    stock: [{
                            required: true,
                            message: "请输入商品库存"
                        },
                        {
                            type: 'number',
                            required: true,
                            message: "请填写数字",
                            trigger: "blur"
                        }
                    ],
                    colFile: [{
                        required: true,
                        validator: valiIcon,
                        trigger: 'change'
                    }], // 图片验证,
                    detailFiles: [{
                        required: true,
                        validator: valiIcon2,
                        trigger: 'change'
                    }] // 图片验证,
                }
            };
        },
        created() {
            this.uri = 'api/iotServer/goods/addPic'
        },
        mounted() {

        },
        methods: {
          clearVideo(){
            this.videoPath = '';
            this.$refs.video.clearFiles();
          },
          clearInstallVideo(){
            this.installVideoPath = '';
            this.$refs.installVideo.clearFiles();
          },
          UploadInstallVideo(file) {
            // this.videoPath = URL.createObjectURL(params.file);
            this.installProgressFlag = true;
            this.installLoadProgress = 0;

            let formData = new FormData();
            formData.append("multipartFiles",file.raw,file.name)
            axios.post(base.baseUrl + base.GoodsVideoUpload, formData,{
              headers: {
                "content-Type": 'multipart/form-data',
              },
              onUploadProgress: progressEvent => {
                if(progressEvent.lengthComputable){
                  let val = parseInt((progressEvent.loaded / progressEvent.total) * 100 ).toFixed(0)
                  this.installLoadProgress = Math.floor(val)-1;
                }

              }
            }).then((res) => {
              this.installVideoPath = res.data.data
              this.installProgressFlag = false;
              this.installLoadProgress = 100;
              // this.$message({
              //   type: "success",
              //   message: res.data.data
              // });
            });
            // this.$api.goodsVideoUpdate(formData).then((res) => {
            //   this.videoPath = res.data.data
            //
            // });
          },
          UploadVideo(file) {
            // this.videoPath = URL.createObjectURL(params.file);
            this.progressFlag = true;
            this.loadProgress = 0;

            let formData = new FormData();
            formData.append("multipartFiles",file.raw,file.name)
            axios.post(base.baseUrl + base.GoodsVideoUpload, formData,{
              headers: {
                "content-Type": 'multipart/form-data',
              },
              onUploadProgress: progressEvent => {
                if(progressEvent.lengthComputable){
                  let val = parseInt((progressEvent.loaded / progressEvent.total) * 100 ).toFixed(0)
                  this.loadProgress = Math.floor(val)-1;
                }

              }
            }).then((res) => {
              this.videoPath = res.data.data
              this.progressFlag = false;
              this.loadProgress = 100;
              // this.$message({
              //   type: "success",
              //   message: res.data.data
              // });
            });
            // this.$api.goodsVideoUpdate(formData).then((res) => {
            //   this.videoPath = res.data.data
            //
            // });
          },
          beforeUploadVideo(file) {
            const isLt30M = file.size / 1024 / 1024 < 50;
            if (
                [
                  "video/mp4",
                  "video/ogg",
                  "video/flv",
                  "video/avi",
                  "video/wmv",
                  "video/rmvb",
                ].indexOf(file.type) == -1
            ) {
              this.$message.error("请上传正确的视频格式");
              return false;
            }
            if (!isLt30M) {
              this.$message.error("上传视频大小不能超过50MB哦!");
              return false;
            }
          },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 0.50;
                if (!isLt2M) {
                    console.log('2200')
                    this.$message.error('上传图片大小不能超过 500KB!');
                    this.value = ''
                }
                return isLt2M;
            },

            // //日期比较的方法
            // compare(date1, date2) {
            //     let dates1 = new Date(date1);
            //     let dates2 = new Date(date2);
            //     if (dates1 > dates2) {
            //         return true
            //     } else {
            //         return false
            //     }
            // },

            change(val) {
                // console.log(val);
                this.ruleForm.newsContent = val;
            },
            submitForm(ruleForm) {
                //  this.ruleForm.colFile = this.file
                //     this.ruleForm.detailFiles = this.fileList
                this.$refs[ruleForm].validate(valid => {
                    this.ruleForm.colFile = this.file
                    this.ruleForm.detailFiles = this.fileList
                    let formData = new FormData();
                    // console.log(this.value);
                    this.ruleForm.detailFiles.forEach(file => {
                        formData.append("detailFiles", file
                        .raw); //此处一定是append file.raw 上传文件只需维护fileList file.raw.name要加上
                        // formData.append("fileNames", file.name);
                    });
                    formData.append("name", this.ruleForm.name);
                    formData.append("goodsNo", this.ruleForm.goodsNo);
                    formData.append("oriPrice", this.ruleForm.oriPrice);
                    formData.append("actualPrice", this.ruleForm.actualPrice);
                    formData.append("colFile", this.ruleForm.colFile);
                    formData.append("details", this.ruleForm.details);
                    formData.append("stock", this.ruleForm.stock);this.ruleForm.shelfTime == null ? "" :this.ruleForm.shelfTime[0],
                    formData.append("shelfTime",this.ruleForm.shelfTime == null ? "" :this.ruleForm.shelfTime[0],);
                    formData.append("offShelfTime", this.ruleForm.shelfTime == null ? "" :this.ruleForm.shelfTime[1],);
                    formData.append("deliveryTime", this.ruleForm.deliveryTime);
                    formData.append("videoPicture", this.videoPath);
                  formData.append("installVideoUrl", this.ruleForm.installVideoUrl);
                  formData.append("salesVolumeShow", this.ruleForm.salesVolumeShow);
                  formData.append("salesVolumeEnd", this.ruleForm.salesVolumeEnd);
                  formData.append("salesVolumeStart", this.ruleForm.salesVolumeStart);
                  formData.append("salesVolumeIsRandom", this.ruleForm.salesVolumeIsRandom == true ?1:0);
                    //   console.log(this.ruleForm)
                    if (valid) {
                        this.$api.addGoods(formData).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("新增成功!");
                                this.$nextTick(() => {
                                    this.$router.push("/mallList");
                                    console.log("00000");
                                    this.$refs["ruleForm"].clearValidate();
                                    this.value = "";
                                    this.value2 = "";
                                    this.file = {};
                                    this.file2 = {};
                                    this.fileList = [];
                                    this.ruleForm.newsSubtitle = "";
                                    this.ruleForm.newsTitle = "";
                                    this.ruleForm.releaseTime = "";
                                    this.ruleForm.contentUrl = "";
                                    this.ruleForm.newsContent = "";
                                });
                            }
                        });
                    } else {
                        console.log("error submit!!");

                        return false;
                    }

                });
            },
            handleChange(file, fileList) {
                console.log(file.raw, file);
                this.value = file.url;
                this.file = file.raw;
                if (file.raw) {
                    this.$nextTick(() => { //等待照片上传完成，
                        this.$refs['formImg'].clearValidate()
                    })

                }
            },
            //超出最大上传文件数量时的处理方法
            handleExceed(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },
            //移除文件的操作方法
            handleRemoveDetail(file, fileList) {
                this.fileList = null;
                this.tableData = [];
            },



            resetForm(formName) {
                   this.ruleForm.shelfTime={
                  required: true,
                        message: '请选择上架时间',

                }
                this.clearVideo()
                this.$refs[formName].resetFields();

            },
            //回到模块页面
            gobackPlaceDetail() {
                this.$router.push("/mallList");
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleRemove(file, fileList) {
                // console.log(file, fileList);
                // this.fileList = fileList
                this.value = "";
                this.file = {};
            },
            handleChangeDetail(file, fileList) {

                const isLt5M = file.size / 1024 / 1024 < 0.50;
                if (!isLt5M) {
                    this.$message.error('上传图片大小不能超过500kb!');
                    fileList.splice(-1, 1); //移除选中图片
                    return false;
                } else {

                    // console.log(fileList)
                    this.fileList = fileList
                    if (file.raw) {
                        this.$nextTick(() => { //等待照片上传完成，
                            this.$refs['formImg2'].clearValidate()
                        })
                    }
                }


            },
            handlePictureCardPreview2(file) {
                this.dialogImageUrl2 = file.url;
                this.dialogVisible2 = true;
            },

        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .footer {
        position: fixed;
        bottom: 0;
        left: 40%;
        //   width:30%;
        line-height: var(--footer-height);
        //   background: #42b983;
        color: #fff;
    }

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .item {
            height: 80px;
            width: 80px;
            border: 1px solid blue;
            float: left;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }
        }

        .detail {
            padding: 0rem 3rem;
            margin-top: 1rem;

            .detail-span {
                font-size: 12 /16rem;
                color: #898989;
            }

            .detail-vv {
                margin-top: 5 /16rem;
                font-size: 14 /16rem;
            }
        }
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    //   .bg-purple {
    //     background: #d3dce6;
    //   }
    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .stepShow {
        width: 80%;
        margin: 3rem auto;

        .downloadList {
            //  transform:translate(5.5rem, -4rem)
        }

        /deep/.el-step.is-horizontal.stepActive {
            .el-step__head.is-finish {
                .el-step__line {

                    // 当前步骤数横线样式设置
                    .el-step__line-inner {
                        width: 50% !important;
                        border-width: 1px !important;
                    }
                }

                // 当前步骤数圆圈样式设置
                .el-step__icon.is-text {
                    background: #409eff;
                    color: #fff;
                }
            }
        }
    }

    .outting .item {
        margin: 10px;
        height: auto;
        width: auto;
        cursor: pointer;
        border: none;
    }

    .right .el-tooltip__popper {
        padding: 8px 10px;
        border: none;
        cursor: pointer;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
