<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除该用户奖品吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDelete">取 消</el-button>
        <el-button type="primary" @click="handleDelClose">确 定</el-button>
      </span>
    </el-dialog>
    <div class="select">
      <span class="title">奖品管理列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-refresh"
        class="select-button"
        @click="refresh"
      >刷新</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-download"
        class="select-button"
        @click="goodsExport"
      >导出</el-button>
    
    </div>
    <div class="select">
                  <el-button
     type="primary"
        size="small"
        class="select-button"
        @click="questionStatus"
      >搜索</el-button>
   <el-input style="width:200px" v-model="prizeName"   class="select-button" 
                placeholder="请输入奖品名称" size="small" 
                ></el-input>
                 <el-input style="width:200px" v-model="activityName"   class="select-button" 
                placeholder="请输入活动名称" size="small" 
                ></el-input>
  <el-select v-model="prizeStatus" placeholder="请选择奖品状态" clearable size="small"  class="select-button"  style="width:200px">
                <el-option v-for="item in prizeStatusList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="prizeType" placeholder="请选择奖品类型" clearable size="small"  class="select-button"  style="width:200px">
                <el-option v-for="item in prizeTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
   
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个问题
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
          @select="getIds"  @select-all="getAllIds"
        size="small"
        class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >      <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="activityCode" label="活动编码" align="center"></el-table-column>
        <el-table-column prop="activityName" label="活动名称" align="center"></el-table-column>
        <el-table-column prop="contactName" label="联系人姓名" align="center"></el-table-column>
        <el-table-column prop="contactPhone" label="联系人电话" align="center"></el-table-column>
        <el-table-column prop="address" label="收货地址" align="center"></el-table-column>
        <el-table-column prop="prizeName" label="奖品名称" align="center"></el-table-column>
        <el-table-column prop="prizeStatus" label="奖品状态" align="center">
                <template slot-scope="{row}">{{ row.prizeStatus =='0'?'未使用':row.prizeStatus == '1'?'已使用':'已失效'}}</template>
        </el-table-column>
         <el-table-column prop="prizeType" label="奖品类型" align="center">
                <template slot-scope="{row}">{{ row.prizeType =='0'?'实物':row.prizeType == '1'?'补签卡':'优惠卷'}}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="prizeValidity" label="奖品有效期" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              @click="deleteProductD(scope.row.id)"
              type="text"
              size="small"
              icon="el-icon-circle-plus-outline"
       
            >查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-sizes="[10, 20]"
        :page-size="pageSize"
        :total="total"
        layout="sizes,prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
   
  </div>
</template>


<script>
import Bus from "../../../components/bus";
export default {
  name: "problemStatus",
  data() {
    return {
      productDel: false,
      productAdd: false,
     
      form: {
        problemStatus: "",
      },
      visible: false,
      total: 0,
       ids: [],
      tableData: [],
      prizeType:"",
       prizeName:"",
       activityName:"",
      prizeTypeList:[ {
          value: "0",
          label: "实物"
        },{
          value: "1",
          label: "补签卡"
        },{
          value: "2",
          label: "优惠卷"
        },],
        prizeStatusList:[ {
          value: "0",
          label: "未使用"
        },{
          value: "1",
          label: "已使用"
        },{
          value: "2",
          label: "已失效"
        }],
         prizeStatus:"",
      optionType: [
        {
          value: "problemStatus",
          label: "真实姓名"
        },
        {
          value: "sysUserName",
          label: "平台账号"
        },
        {
          value: "typeName",
          label: "平台账号"
        }
      ], //搜索下拉选择
      chooseOnlineStatus: "", //搜索选择
      sysUserlist: [], //账号所属类型
      newSsUserlist: {
        value: "id",
        label: "typeName",
        children: "childs",
        expandTrigger: "hover"
        // emitPath: false
      },
      checked: false,
      //新增账号弹出对话框标记
      dialogVisible: false,
      //修改账号弹出对话框标记
      modifydialogVisible: false,
      //协议类型列表
      protocols: [
        {
          id: 1,
          protocolName: "test"
        }
      ],

      //产品品类列表
      categories: [],

      value: "",
      input: "",
      current: 1,
      pageSize: 10,
      delDialogVisible: false,
      productId: "",
      indexId: "", //反显id
      nameinput: "", //搜索输入
      indexIdlist: "", //反显列表
      formLabelWidth: "120px",
     
     
    };
  },
  methods: {
    refresh() {
      this.questionStatus();
    },
    ifMohuHandler(e) {
      // console.log(e);
      this.ifMohu = false;
      this.searchData = [];
    },
    // changex清空
    upOptionType() {
      this.nameinput = "";
    },
    handlePageChange(val) {

      this.current = val;
      this.questionStatus();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.questionStatus();
    },
 
    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].typeName) {
              depth++;
              childrenEach(childrenData[j].typeName, depth);
            }
          }
        }
   
        return returnArr;
      }
      return childrenEach(treeData, depth);
    },

    deleteProductD(data) {


           this.$router.push({
        path: `/userPrizeDetail`,
        query: {
            data: data
          }
      });
    },
    cancelDelete() {
      this.delDialogVisible = false;
      this.productId = "";
    },
    handleDelClose() {
      this.delDialogVisible = false;
      this.deleteProduct(this.productId);
    },
    deleteProduct(data) {
    
      this.$api
        .userPrizeDetail(data)
        .then(res => {
          if (res.data.code == 200) {
            this.$message.success("删除成功!");
            this.questionStatus();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
  
    questionStatus() {
      //查询设备列表
      this.$api
        .userPrizeList({
           page: {
            current: this.current,
            size: this.pageSize
           },
           queryVo:{
             activityName:this.activityName,
             prizeType:this.prizeType,
             prizeName:this.prizeName,
             prizeStatus:this.prizeStatus,
           }
        })
        .then(res => {
            console.log(res.data)
          this.tableData = res.data.data.records;
    
          this.total = res.data.data.total;

      
        })
        .catch(error => {
          console.log(error);
        });
    },
          //批量选中
      getIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;

      },
      //全部选中
      getAllIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].id);
        }
        this.ids = idArr;

      },
      // 导出
      goodsExport() {
        let queryDo = {
          ids: this.ids,
          activityName:"",
          prizeName:"",
          prizeStatus:"",
          prizeType:"",
        }
      
        this.$api
          .userPrizeExport({
            query: queryDo,
            properties: [
              "activityCode",
              "activityName",
              "contactName",
              "contactPhone",
              "address",
              "prizeName",
              "address",
              "prizeStatus",
              "prizeType",
              "createTime",
              "prizeValidity",
            ],
            titles: [
              "活动编码",
              "活动名称",
              "联系人姓名",
              "联系人电话",
              "收货地址",
              "奖品名称",
              "奖品状态",
              "奖品类型",
              "创建时间",
              "奖品有效期",
            ]
          })
          .then(res => {
            // console.log(res);
            this.download(res.data);
          })
          .catch(error => {});
      },
      //导出函数
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    showAddProductDialog() {
      this.dialogVisible = true;
 this.form.problemStatus	 = "";
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    // 新增账号接口
    submitForm(formName) {
      this.$refs["form"].validate(valid => {
        if (valid) {
         
          this.dialogVisible = false;
          this.$api.questionStatusAdd(this.form.problemStatus).then(res => {
            if (res.data.code == 200) {
              this.$message.success("添加状态成功!");
              this.questionStatus();
            }
          });
        } else {
          return false;
        }
      });
    },
   
    // 账号列表新增平台账号下拉框接口
    showaccountListSelector() {}
  },
  created() {
    //查询产品列表
    this.questionStatus();
    this.showaccountListSelector();
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.product-content {
  height: 6rem;

  .product-info-item {
    float: left;
    margin-right: 4rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 13rem;
    }
  }
}
.container-el-row {
  margin-top: 0px;
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15px;
  }
  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}
</style>
