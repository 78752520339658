import { render, staticRenderFns } from "./mySelf.vue?vue&type=template&id=7a07d0ff&scoped=true&"
import script from "./mySelf.vue?vue&type=script&lang=js&"
export * from "./mySelf.vue?vue&type=script&lang=js&"
import style0 from "./mySelf.vue?vue&type=style&index=0&id=7a07d0ff&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a07d0ff",
  null
  
)

export default component.exports