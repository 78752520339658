<template>
  <div class="outting">
    <div class="select">
      <span class="title">账号列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus" 
        @click="addUserHandler"
        class="addRoleButton"
        v-if="addUserUrl"
      >添加账号</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-bottom"
        @click="playShow"
        class="serachRoleButton"
      >高级查询</el-button>
      <div class="select-input-right-input">
        <el-input
          v-model="inputUser"
          prefix-icon="el-icon-search"
          placeholder="请输入用户名"
          size="small"
          @keyup.enter.native="search"
        ></el-input>
      </div>
    </div>
    <!-- <div class="gaojiSearch" v-show="ifSearchMany">
      <el-row :gutter="20">
        <el-col :span="7.5">
          <div class="grid-content bg-purple-dark">
            <span>更新时间</span>
            <div style="margin-top:5px;">
              <el-date-picker
                size="small"
                class="update-input-timer-select"
                v-model="value2"
                type="datetimerange"
                :picker-options="pickerOptionsTwo"
                range-separator="~"
                start-placeholder="开始"
                end-placeholder="结束"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00','23:59:59']"
                @change="getTimeBlock"
              ></el-date-picker>
            </div>
          </div>
        </el-col>
        <el-col :span="4.2">
          <div class="grid-content bg-purple-dark">
            <span>手机号查询</span>
            <div style="margin-top:5px;">
              <el-input
                v-model="inputMobile"
                prefix-icon="el-icon-search"
                placeholder="请输入手机号"
                size="small"
              ></el-input>
            </div>
          </div>
        </el-col>
        <el-col :span="4.2">
          <div class="grid-content bg-purple-dark">
            <span>账号状态查询</span>
            <div style="margin-top:5px;">
              <el-select
                v-model="value"
                placeholder="请选择账号状态"
                class="select-status"
                @change="changeHandler"
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>

        <el-col :span="4">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button siza="mini" type="primary" @click="checkHandler">搜索</el-button>
            </div>
            <div class="emptyBut">
              <el-button siza="mini" @click="emptyHandler">清空</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>-->
    <div class="gaojiSearch" v-show="ifSearchMany">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="grid-content bg-purple-dark">
            <span>更新时间</span>
            <div style="margin-top:5px;">
              <el-date-picker
                size="small"
                class="update-input-timer-select"
                v-model="value2"
                type="datetimerange"
                :picker-options="pickerOptionsTwo"
                range-separator="~"
                start-placeholder="开始"
                end-placeholder="结束"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00','23:59:59']"
                @change="getTimeBlock"
                style="width:25.5rem;"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <div style="width:12rem;float:left;margin-left:1.5rem;">
          <div class="grid-content bg-purple-dark">
            <span>手机号查询</span>
            <div style="margin-top:5px;">
              <el-input
                v-model="inputMobile"
                prefix-icon="el-icon-search"
                placeholder="请输入手机号"
                size="small"
              ></el-input>
            </div>
          </div>
        </div>
        <div style="width:12rem;float:left;margin-left:1.5rem;">
          <div class="grid-content bg-purple-dark">
            <span>账号状态查询</span>
            <div style="margin-top:5px;">
              <el-select
                v-model="value"
                placeholder="请选择账号状态"
                class="select-status"
                @change="changeHandler"
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;margin-top:1rem;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="checkHandler">搜索</el-button>
            </div>
            <div class="emptyBut">
              <el-button size="small" @click="emptyHandler">清空</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- <el-row>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark"></div>
        </el-col>

        <el-col :span="4">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button siza="mini" type="primary" @click="checkHandler">搜索</el-button>
            </div>
            <div class="emptyBut">
              <el-button siza="mini" @click="emptyHandler">清空</el-button>
            </div>
          </div>
        </el-col>
      </el-row>-->
    </div>
    <div class="zhanghuTable">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column type="selection" min-width="1"></el-table-column>
        <el-table-column prop="id" label="用户ID" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.id || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="username" label="用户名" min-width="1" align="center">
          <template slot-scope="{row}">{{ row.username || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" min-width="1" align="center">
          <template slot-scope="{row}">{{ row.mobile || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="nickName" label="昵称" min-width="1" align="center">
          <template slot-scope="{row}">{{ row.nickName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="isEnable" label="账户状态" min-width="1" align="center">
          <template slot-scope="{row}">{{ row.isEnable==1 ? "禁用":"启用" }}</template>
        </el-table-column>
        <el-table-column prop="region" label="地区" min-width="1" align="center">
          <template slot-scope="{row}">{{ row.region || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="roleName" label="角色名称" min-width="1" align="center">
          <template slot-scope="{row}">{{ row.roleName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="projectName" label="项目名称" min-width="1" align="center">
          <template slot-scope="{row}">{{ row.projectName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="2" align="center">
          <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-info"
              @click="handleDetail(scope.$index, scope.row)"
            >查看详情</el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
              v-if="deleteUserUrl"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        class="pagin"
        @current-change="changeCurrent"
      ></el-pagination>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
      <span>确定删除“{{this.deletePowerName}}”这个用户吗</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureDeleteUserHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Storage from "../../../utils/Storage";
import { mapActions } from "vuex";
export default {
  name: "SysUser",
  data() {
    return {
      deleteUserUrl: false,
      addUserUrl: false,
      deletePowerName: "",
      dialogVisible: false,
      deletePowerId: "",
      total: 0,
      ifSearchMany: false,
      userStatus: "",
      value: "",
      value2: "",
      startUpdateTime: "",
      endUpdateTime: "",
      input: "",
      inputUser: "",
      inputMobile: "",
      options: [
        {
          value: "1",
          label: "禁用"
        },
        {
          value: "0",
          label: "启用"
        }
      ],
      tableData: [],
      pickerOptionsTwo: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  created() {
    var token = this.$store.state.userinfo.token;
    this.$api
      .userTable(
        {
          page: {
            current: 1,
            size: 10
          }
        },
        token
      )
      .then(res => {
        // console.log(res.data.data);
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      })
      .catch(err => {
        console.log(err);
      });
    //判断删除权限

    let cloudList = JSON.parse(sessionStorage.getItem("cloudList"));
    for (let i = 0; i < cloudList.length; i++) {
      //删除账户权限判断
      if (this.$store.state.localList.localList.deleteUserUrl == cloudList[i]) {
        this.deleteUserUrl = true;
      }
      if (this.$store.state.localList.localList.addUserUrl == cloudList[i]) {
        this.addUserUrl = true;
      }
    }
  },
  methods: {
    ...mapActions(["setCloudListActions"]),
    //获取时间函数
    getTimeBlock() {
      if (this.value2) {
        this.startUpdateTime = this.value2[0];
        this.endUpdateTime = this.value2[1];
      } else {
        this.startUpdateTime = "";
        this.endUpdateTime = "";
      }
    },
    //详情函数
    handleDetail(index, row) {
      Storage.setItem("userId", row.id);
      this.$router.push("/sysuserdetail");
    },
    //查询函数
    checkHandler() {
      var token = this.$store.state.userinfo.token;
      this.$api
        .userTable(
          {
            page: {
              current: 1,
              size: 10
            },
            query: {
              startTime: this.startUpdateTime,
              endTime: this.endUpdateTime,
              isEnable: this.userStatus,
              mobile: this.inputMobile,
              username: this.inputUser
            }
          },
          token
        )
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.toal;
        })
        .catch(err => {
          console.log(err);
        });
    },
    changeCurrent(id) {
      var token = this.$store.state.userinfo.token;
      this.$api
        .userTable(
          {
            page: {
              current: id,
              "size ": 10
            },
            query: {
              startTime: this.startUpdateTime,
              endTime: this.endUpdateTime,
              isEnable: String(this.userStatus),
              mobile: this.inputMobile,
              username: this.inputUser
            }
          },
          token
        )
        .then(res => {
          this.tableData = res.data.data.records;
          this.toal = res.data.data.total;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //选择器点击函数
    changeHandler(index) {
      console.log(index);
      this.userStatus = Number(index);
    },
    //增加用户跳转函数
    addUserHandler() {
      this.$router.push("/sysuseradd");
    },
    //高级搜索点击函数
    playShow() {
      if (this.ifSearchMany == false) {
        this.ifSearchMany = true;
      } else {
        this.ifSearchMany = false;
      }
    },
    //清空查询函数
    emptyHandler() {
      (this.startUpdateTime = ""),
        (this.endUpdateTime = ""),
        (this.userStatus = ""),
        (this.inputMobile = ""),
        (this.inputUser = ""),
        (this.value2 = ""),
        (this.value = "");
    },
    //input回车搜索函数
    search() {
      var token = this.$store.state.userinfo.token;
      this.$api
        .userTable(
          {
            page: {
              current: 1,
              "size ": 10
            },
            query: {
              username: this.inputUser
            }
          },
          token
        )
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = Number(res.data.data.total);
        })
        .catch(err => {
          console.log(err);
        });
    },
    //删除账号
    handleDelete(index, row) {
      // console.log(row)
      this.deletePowerId = row.id;
      this.deletePowerName = row.username;
      this.dialogVisible = true;
    },
    sureDeleteUserHandler() {
      this.dialogVisible = false;
      var token = this.$store.state.userinfo.token;
      this.$api
        .deleteUser(this.deletePowerId, token)
        .then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message.success("删除成功!");
            this.tableData.forEach(item => {
              if (item.id == this.deletePowerId) {
                this.tableData.splice(this.tableData.indexOf(item), 1);
              }
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;

.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;
  }

  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    border-radius: 4px;
  }

  // .bg-purple-dark {
  //   background: #99a9bf;
  //   float: left;
  // }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .searchBut {
    float: right;
    margin-left: 20px;
  }

  .emptyBut {
    float: right;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .update-input-timer {
      float: right;
      line-height: 46px;
      margin-left: 25px;
      height: 46px;

      .demonstrationTwo {
        float: left;
        font-size: 0.8rem;
      }

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }
  }

  .pagin {
    margin-left: 30%;
    margin-top: 10px;
  }

  .zhanghuTable {
    padding: 0rem 3rem;
    margin-top: 1rem;
    // .el-table td,
    // .el-table th {
    //   padding: 0rem !important;
    // }
  }
}

.outting
  .select
  .update-input-timer
  .update-input-timer-select[data-v-21cd696e] {
  height: 30px;
  margin-top: 7px;
}

.el-select .el-input--suffix {
  &.el-input__inner {
    height: 30px !important;
  }
}
</style>
