<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">场地列表</span>
        <el-button
          type="text"
          size="small"
          icon="el-icon-back"
          @click="gobackPlaceDetail"
          class="addRoleButton"
        >返回场地列表</el-button>
      </div>
      <div class="detail">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>场地详情</span>
          </div>
          <div style="display:flex;">
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">名称</span>
                <div class="detail-vv">{{placeName}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">地址</span>
                <div class="detail-vv">{{placeLocation}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">用户</span>
                <div class="detail-vv">{{userName}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">用户电话</span>
                <div class="detail-vv">{{userMobile}}</div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card" shadow="never" style="margin-top:1rem;">
          <div slot="header" class="clearfix">
            <span>联系人详情</span>
          </div>
          <div style="display:flex;" v-for="(item,index) in linkList" :key="index">
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">场地ID</span>
                <div class="detail-vv">{{item.placeId}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">紧急联系人</span>
                <div class="detail-vv">{{item.linkManName}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">联系人手机</span>
                <div class="detail-vv">{{item.linkManMobile}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">场地名称</span>
                <div class="detail-vv">{{item.placeName}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">创建时间</span>
                <div class="detail-vv">{{item.createTime}}</div>
              </div>
            </div>
            <div style="flex:1;">
              <div class="grid-content bg-purple">
                <span class="detail-span">更新时间</span>
                <div class="detail-vv">{{item.updateTime}}</div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlaceDetail",
  data() {
    return {
      placeId: "",
      placeName: "",
      placeLocation: "",
      userName: "",
      userMobile: "",
      linkList: []
    };
  },
  created() {
    console.log(Number(this.$route.query.placeId));
    this.placeId = Number(this.$route.query.placeId);
    this.$api.placeDetail(this.placeId).then(res => {
      console.log(res.data.data);
      this.placeName = res.data.data.placeName;
      this.placeLocation = res.data.data.placeLocation;
      this.userName = res.data.data.userName;
      this.userMobile = res.data.data.userMobile;
      this.linkList = res.data.data.linkman;
    });
  },
  methods: {
    //回到列表页面
    gobackPlaceDetail() {
      this.$router.push("/placeList");
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;
    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }
  }
  .detail {
    padding: 0rem 3rem;
    margin-top: 1rem;
    .detail-span {
      font-size: 12 /16rem;
      color: #898989;
    }
    .detail-vv {
      margin-top: 5 /16rem;
      font-size: 14 /16rem;
    }
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
//   .bg-purple {
//     background: #d3dce6;
//   }
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>