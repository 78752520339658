<template>
    <div class="methaneNn">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click.native="backTo">
                    {{this.$route.query.crumbsTitle=="首页"? this.$route.query.crumbsTitle :"产品中心"}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span style="color:#f28c02">
                        产品详情
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="major_service">
            <div class="item-title">
                <p> 家用可燃气体探测器（甲烷CH4）</p>
                <div>
                    <img src="../../../assets/login/JT-WSH6200Njw.png" alt="" class="image">
                    <span class="text_list" style="margin-bottom:30px">
                        (JT-WSH6200N)
                    </span>
                </div>
                <div class="level_subject">
                    概述
                </div>
                <div class="text_subject">
                    本产品为家用可燃气体探测器（以下简称探测器），用于探测可燃气体的泄露，当浓度达到报警动作值时，发出报警和控制信号。提示相关人员及时采取措施排除隐患，实现早期预防，避免火灾、爆炸事故的发生。
                    适用于使用天然气、液化气的场所或其他散发可燃气体和可燃蒸汽的场所，例如餐馆后厨、家庭厨房、厂房、仓库、机房等场所。
                    <br>
                    探测器为独立式安装设备，内置优质催化燃烧式气敏元件、高性能微处理器 ，采用SMT表面贴装工艺，可靠性高，一致性好。同时探测器还具有高灵敏度、 对酒精和温湿度的感知程度小等优点。<br>

                    设计遵照GB 15322.2-2019《家用可燃气体探测器》国家标准。


                </div>
                <div class="level_subject">
                    技术特征
                </div>
                <div class="text_subject">

                    <ul>
                        <li>主要参数</li>
                        <li> · <span>工作电压：AC220V/50Hz （±15%）</span> </li>
                        <li> · <span>工作温度：-10～+55℃</span> </li>
                        <li> · <span>贮存温度：-25℃～+60℃</span> </li>
                        <li> · <span>相对湿度：≤95%，无凝露</span> </li>
                        <li> · <span>报警动作值：10%LEL 甲烷（CH4）</span> </li>
                        <li> · <span>稳定性：≤±3%LEL</span> </li>
                        <li> · <span>响应时间：≤30s</span> </li>
                        <li> · <span>报警声信号：≥75dB(1m)</span> </li>
                        <li> · <span>指示灯：绿色指示灯1个（常亮预热、闪亮巡检），红色指示灯1个（常亮报警），黄色指示灯1个（常亮故障，闪亮传感器失效）</span> </li>
                        <li> · <span>传感器：催化燃烧式传感器；</span> </li>
                        <li> · <span>传感器使用寿命：≥5年</span> </li>
                        <li> · <span>控制输出接口：继电器接口(触点容量DC30V/2A或AC125/0.5A)；脉冲输出接口（12V/1A/0.5s）</span> </li>
                        <li> · <span>数据读取接口：参照国标GB 15322.2-2019 附录A；</span> </li>
                        <li> · <span>数据读取接口：参照国标GB 15322.2-2019 附录A；</span> </li>
                        <li> ·
                            <span>联网接口输出信号的类型、参数：采用TTL串行通讯信号电平，波特率为4800bps。上位机发出查询命令后，探测器反馈13字节数据，分别为3字节命令标志，1字节预留，2字节浓度，6字节数据和状态，1字节版本。</span>
                        </li>
                        <li> · <span>各类报警历史记录的最大存储条数：</span> </li>
                        <li> <span>a.探测器报警记录：200条；</span> </li>
                        <li> <span>b.探测器报警恢复记录：200条</span> </li>
                        <li> <span>c.探测器故障记录：100条；</span> </li>
                        <li> <span>d.探测器故障恢复记录：100条</span> </li>
                        <li> <span>e.探测器掉电记录：50条</span> </li>
                        <li> <span>f.探测器上电记录：50条</span> </li>
                        <li> <span>g.气体传感器失效记录：1条</span> </li>
                        <li> · <span>外形尺寸：95mm×95mm×44mm（长×宽×高）</span> </li>
                        <li> · <span>重量：约 100克</span> </li>

                    </ul>
                </div>
   <div class='botom_tit'>   *如需获取产品3c证书、检验报告、说明书等，
               可扫描底部公众号二维码进入“吻胜智能生活”公众号获取</div>

            </div>

            <div>

            </div>
        </div>


    </div>
</template>
<script>
import '../../../../static/css/global.css'
    export default {
        name: "methaneNn",
        components: {},
        data() {
            return {

            };
        },
        mounted() {
            window.addEventListener('scroll', this.scrollToTop)
            const that = this
            let ispeed = Math.floor(-that.scrollTop / 5)
            document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        },
        created() {},
        methods: {
            scrollToTop() {
                const that = this
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                that.scrollTop = scrollTop
                that.btnFlag = false
            },
            backTo() {
                if (this.$route.query.crumbsTitle == '首页') {
                    let vm = this
                    vm.$router.replace({
                        path: `/`
                    });
                } else {
                    this.$router.replace({
                        path: "/productCenter"
                    });
                }
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTop)
        },
    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1370px) {
        .methaneNn {
           background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;
            .text_subject{
                 font-size: 1.05rem;
                 line-height: 1.8rem;
                //   padding-bottom: 3rem;
                    padding-top: 1.5rem;
            }
             .level_subject{
                font-size: 1.15rem; 
            }
        }
    }

    @media screen and (min-width: 1370px) {
        .methaneNn {
          background-color: #efefef;
            overflow-x: auto;
            min-width: 1300px;
             .text_subject{
                 font-size: 1.25rem;
                 line-height: 2.5rem;
                  padding-bottom: 2rem;
                    padding-top: 2rem;
            }
            .level_subject{
                font-size: 1.35rem; 
            }
        }
    }

    .breadcrumb {
        padding: 2rem 0 2rem 0;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;

        /deep/.el-breadcrumb {
            float: right;
        }

        /deep/.el-breadcrumb__inner:hover {
            color: #f28c02;
        }
    }
  .botom_tit{
      text-align: left;
      width: 60%;
       margin: 0 auto;
         padding-bottom:  4rem;
         padding-left:  2rem;
  }
    .major_service {
        width: 100%;
        padding-bottom: 20px;

        .item-title {
            padding-top: 2rem;
            text-align: center;
            // color: #333333;
            width: 100%;
            margin: 0 auto;

            .image {
                width: 20%;
                display: block;
                margin: 0 auto;
            }

            p {
                text-shadow: 1px 1px 1px #666;
                display: inline-block;
                color: #333333;
                padding: 0 50px;
                font-size: 1.5em;
            }

            .text_list {
                display: inline-block;
                transform: translateY(-2rem);
               font-size: 1rem; 
                color: #333333;
            }

            .level_subject {
                text-align: left;
                width: 60%;
                letter-spacing: 4px;
                margin: 0 auto;
            //    font-size: 1.35rem; 
                color: #333333;
                font-weight: 600;
            }

            .text_subject {
                display: inline-block;
            //  font-size: 1.25rem;
                
                text-align: justify;
                width: 60%;
              
                margin: 0 auto;
                // text-align: left;
                color: #333333;
               
                // border: #f28c02 1px solid;
         
                img {
                    width: 100%;

                }

            }

        }


    }
</style>