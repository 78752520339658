<template>
  <div class="outting">
    <div class="select">
      <span class="title">广告管理</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        @click="addUserHandler"
        class="addRoleButton"
      >添加广告</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-bottom"
        @click="playShow"
        class="serachRoleButton"
      >高级查询</el-button>
      <div class="select-input-right-input">
        <el-input
          v-model="adName"
          prefix-icon="el-icon-search"
          placeholder="请输入广告名称"
          size="small"
          @keyup.enter.native="search"
        ></el-input>
      </div>
    </div>
    <div class="gaojiSearch" v-show="ifSearchMany">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="grid-content bg-purple-dark">
            <span>创建时间</span>
            <div style="margin-top:5px;">
              <el-date-picker
                size="small"
                class="update-input-timer-select"
                v-model="value2"
                type="datetimerange"
                :picker-options="pickerOptionsTwo"
                range-separator="~"
                start-placeholder="开始"
                end-placeholder="结束"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00','23:59:59']"
                @change="getTimeBlock"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <div style="width:12rem;float:left;margin-left:1.5rem;">
          <div class="grid-content bg-purple-dark">
            <span>图片顺序</span>
            <div style="margin-top:5px;">
              <el-input
                v-model="adSort"
                prefix-icon="el-icon-search"
                placeholder="请输入图片顺序"
                size="small"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;margin-top:.5rem;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="checkHandler">搜索</el-button>
            </div>
            <div class="emptyBut">
              <el-button size="small" @click="emptyHandler">清空</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="luobitu--vv">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column prop="adName" label="名称" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.adName || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="adDesc" label="描述" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.adDesc || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="adSort" label="图片顺序" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.adSort || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="mark" label="展示区域" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.mark == 0?'小程序顶部轮播':row.mark == 1?'商品顶部图片':row.mark == 2?'商城广告':row.mark == 3?'移动官网轮播':row.mark == 4?'web官网轮播':'APP开屏广告' }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.createTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="1" fixed align="center">
          <template slot-scope="{row}">{{ row.updateTime || '暂无数据' }}</template>
        </el-table-column>
        <el-table-column label="图片" min-width="1" fixed align="center">
          <template slot-scope="scope">
            <el-popover placement="top-start" title trigger="hover">
              <el-image
                :src="scope.row.adImgUrl"
                :fit="fit"
                style="width:160px;height:80px"
                referrerpolicy="no-referrer"
              ></el-image>
              <el-image
                slot="reference"
                :src="scope.row.adImgUrl"
                style="width: 30px;height: 30px"
                :fit="fit"
                referrerpolicy="no-referrer"
              ></el-image>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="handleChange(scope.$index, scope.row)"
            >编辑</el-button>
            <el-popconfirm title="确定删除这条广告吗？" @onConfirm="deleteAddHandler()">
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                style="margin-left:10px;"
                slot="reference"
              >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        style="float:right;margin-top:10px;"
        @current-change="changeCurrent"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import bus from "@/components/bus";
export default {
  name: "HomeIn",
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      tableData: [],
      fit: "fill",
      deleteId: "",
      current: 1,
      total: 0,
      row: {},
      ifSearchMany: false,
      adName: "",
      value2: "",
      pickerOptionsTwo: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      startTime: "",
      endTime: "",
      adSort: ""
    };
  },
  created() {
    this.advertisementList();
  },
  destroyed() {
    bus.$emit("row", this.row);
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    addUserHandler() {
      this.$router.push("/addAdvertisement");
    },
    //编辑广告函数
    handleChange(index, row) {
      console.log(index, row);
      this.$router.push({
        path: `/addEdit`,
        query: { id: row.id }
      });
      this.row = row;
    },
    //删除广告函数
    handleDelete(index, row) {
      this.deleteId = row.id;
      console.log(this.deleteId);
    },
    //删除广告确定函数
    deleteAddHandler() {
      this.$api.advertisementDeleteHandler(this.deleteId).then(res => {
        if (res.data.code == 200) {
          this.$message.success("删除广告成功!");
          this.advertisementList();
        }
      });
    },
    //请求列表数据函数
    advertisementList() {
      if(this.adSort){
         this.$api
        .advertisementList({
          page: {
            current: this.current,
            size: 10,
          },
          queryDto: {
            adName: this.adName,
            adSort: Number(this.adSort),
            startTime: this.startTime,
            endTime: this.endTime
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
      }else{
          this.$api
        .advertisementList({
          page: {
            current: this.current,
            size: 10,
          },
          queryDto: {
            adName: this.adName,
            startTime: this.startTime,
            endTime: this.endTime
          }
        })
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
      }

    },
    //分页函数
    changeCurrent(val) {
      this.current = val;
      this.advertisementList();
    },
    //回车查询函数
    search() {
      this.advertisementList();
    },
    //高级搜索函数
    playShow() {
      if (this.ifSearchMany == false) {
        this.ifSearchMany = true;
      } else {
        this.ifSearchMany = false;
      }
    },
    //获取时间函数
    getTimeBlock() {
      if (this.value2) {
        this.startTime = this.value2[0];
        this.endTime = this.value2[1];
      } else {
        this.startTime = "";
        this.endTime = "";
      }
    },
    checkHandler() {
      this.advertisementList();
    },
    emptyHandler() {
      (this.startTime = ""),
        (this.endTime = ""),
        (this.adName = ""),
        (this.adSort = ""),
        (this.value2 = "");
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;
.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    .serachRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .update-input-timer {
      float: right;
      line-height: 46px;
      margin-left: 25px;
      height: 46px;

      .demonstrationTwo {
        float: left;
        font-size: 0.8rem;
      }

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }
  }
  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;
    .el-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .el-col {
      border-radius: 4px;
    }
    .grid-content {
      border-radius: 4px;
      min-height: 36px;
    }

    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }
    .searchBut {
      float: right;
      margin-left: 20px;
    }

    .emptyBut {
      float: right;
    }
  }
  .luobitu--vv {
    padding: 1rem 3rem;
  }
  text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
}
</style>
