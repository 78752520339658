<template>
	<div class="platForm">
		<h1>This is an PlatForm page</h1>
	</div>
</template>
<script>
	export default {
		name: 'PlatForm',
	}
</script>
