<template>
  <div class="main-content">
    <div class="select">
      <span class="title"><img src="../../../assets/icons/camera.svg" style="width: 20px;height: 20px;margin-right: 5px" >{{deviceName}}</span>
      <el-button type="text" size="small" icon="el-icon-back" @click="goBackHandler">返回设备列表</el-button>
    </div>
    <div style="padding: 50px;display: flex;
  justify-content: center;
  align-items: center;">
      <div id="container" style="width: 70vw;height: 39.5vw;"></div>
    </div>



  </div>
</template>

<script>

// import EasyPlayer from '@easydarwin/easyplayer'
export default {
  components: {

  },
  data() {
    return {
      src:"",
      deviceName:''
    }
  },
  mounted() {
    this.src = this.$route.query.src
    this.deviceName = this.$route.query.deviceName
    this.play()
  },
  methods: {
    goBackHandler(){
      this.$router.push('/deviceInfo')
    },
    play(){
      var player = new window.VCloudPlayer();

      let that = this
      player.createPlayer({
        uid: "",//用户唯一id
        bid: "", //业务id，用于统计，根据业务标识建表。业务自己指定，尽量由个性化标志，防止与其他业务冲突
        pid: "web", //平台端id， web/H5
        cid: '',//项目id，用于统计，根据项目标识建立宽表查询
        playerType: 0,//0-video, 1-canvas
        isLive: true,
        autoplay: true,
        controls:true,
        container: 'container',
        src: that.src,
        mediaData: {
          type: 'flv', //mp4(CDNType: 1云帆，2PPIO，3星域，0普通), m3u8(seekTo), flv, relay(relayKey: null)
          CDNType: 0,
          codec: 'h265'//用于统计，区分媒体类型，无法确认类型时，填0或不填
        }
      }).then(() => {
        player.play(); // 若设置静音，可实现自动播放
      });
    }
  },
  beforeDestroy() {
    this.player.destroy();
    this.player = null;

  }
}
</script>

<style lang="less" scoped>

@toolBarFontColor: rgba(17, 17, 17, 0.87);
.main-content {
  padding: 1rem 0 1rem 0;

  .tab-nav {
    //background: red;
    border-bottom: 1px solid #d9d9d9;

    .tab-item {
      cursor: pointer;
      display: inline-block;
      padding: 0 1.5rem;
      line-height: 2rem;
      font-weight: bold;
      font-size: small;

      &.left {
        margin-left: 3rem;
        border-bottom: 3px solid #4faaf0;
      }

      &.right {
        margin-right: 3rem;
      }

      &:hover {
        color: #4faaf0;
      }
    }
  }
}

.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #cccccc;
  padding: 0rem 3rem;

  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }
  button {
    color: @toolBarFontColor;
    font-size: 0.8rem;
    float: right;
    margin-top: 7px;
  }
}



</style>
