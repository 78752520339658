<template>
    <div class="newsInformation">

        <div class="block">
            <div class="lunbotu">
                <!-- <el-carousel-item > -->
                <img src="../../assets/xwzxbanner.jpg" class="image" />
                <!-- </el-carousel-item> -->
            </div>
        </div>

        <div class="titleDisplay">
            <div class="major_service">
                <div class="item-title">
                    <p>热点内容</p>
                    <span></span>
                </div>
                <div class="hotContent">
                    <div class="box_hot" v-for="(item,index) in this.tableList" :key="index">
                        <div class="left_hot">

                            <img :src="item.newsImgUrl" class="image" />
                        </div>
                        <div class="right_hot" @click="houseHold(item)">

                            <p> {{ item.newsTitle }}</p>
                            <p>{{ item.newsSubtitle }}</p>
                            <p> <span>
                                   日期： {{item.releaseTime.slice(0,10)}}
                                </span>

                                <span @click="houseHold(item)">
                                    查看详情>>
                                </span>
                            </p>

                        </div>
                    </div>
                    <el-pagination :current-page.sync="currentPage" class="paging"  background :page-size="pageSize" :total="total"
                        layout="prev, pager, next" @size-change="handleSizeChange"
                        @current-change="handlePageChange"></el-pagination>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
    </div>
</template>
<script>
    import Storage from "../../utils/Storage";

    export default {
        name: "newsInformation",
        components: {},
        data() {
            return {
                select: "",
                showBac: "",
                ifLogin: "",
                isTop: true,
                 currentPage: 1,
                loginChanges: "登录",
                input: "",
                menu_listones: 5,
                tableList: [],
                pageSize: 5,
                total: 0,
            };
        },
        mounted() {
            // this.newsList()
        },
        created() {

        },
          beforeRouteEnter(to, from, next) {
      console.log(from.path, '组件独享守卫beforeRouteEnter第二个参数');
      next(vm => {
        if (from.path == '/household') {
          vm.current = sessionStorage.getItem("newseCurrent")
          vm.newsList();
        } else {
          //因为当钩子执行前，组件实例还没被创建
          // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
          // console.log(vm);//当前组件的实例
          vm.current = 1
          vm.newsList();
        }

      });
    },

    beforeRouteLeave(to, from, next) {
      // 导航离开该组件的对应路由时调用
      // 可以访问组件实例 `this`
      // console.log(this, 'beforeRouteLeave'); //当前组件实例
      console.log(to.path, '组件独享守卫beforeRouteLeave第一个参数');
      if (to.path == '/household') {
        sessionStorage.setItem("newseCurrent", this.current);
      } else {
        sessionStorage.removeItem("newseCurrent");
      }
      next();
    },
        methods: {

            //   详情跳转到灭火器文
            houseHold(data) {
                //    window.location.href = 'https://mp.weixin.qq.com/s/vDL6aqZ7dFCZ-DRX6jyx8Q'

    //   window.open('https://mp.weixin.qq.com/s/vDL6aqZ7dFCZ-DRX6jyx8Q', '_blank')
              if(data.clickUrl == null || data.clickUrl == ""){
                this.$router.push({
                  path: `/household`,
                  query: {
                    data: data.id,
                    current: this.current,
                    size: this.pageSize
                  }

                });
              }else {
                window.open(
                    data.clickUrl,
                    "_blank"
                );

              }

            },

            //新闻资讯列表
            newsList() {

                this.$api
                    .newsList({
                        page: {
                            current: this.current,
                            size: this.pageSize
                        }
                    })
                    .then(res => {
                        this.tableList = res.data.data.records;
                        this.total = res.data.data.total;
                        // console.log(this.tableList)
                           this.currentPage = parseInt(this.current)
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.newsList();
            },
            handlePageChange(val) {

                this.current = val;
                this.newsList();
            },
        },

    };
</script>
<style lang="less" scoped>
    @media screen and (min-width: 0px) and (max-width: 1200px) {
        .newsInformation {
            background-color: #fafafa;
            overflow-x: auto;
            min-width: 1000px;
        }
    }

    @media screen and (min-width: 1201px) {
        .newsInformation {
            background-color: #fafafa;
            overflow-x: auto;
            min-width: 1300px;
        }
    }

    @media screen and (min-width: 0px) and (max-width: 1370px) {
        .block {
            width: 100%;
            // margin-top: 5rem;

            .lunbotu {
                /deep/.el-carousel__container {
                    width: 100%;
                    height: 36rem;
                }

                height: 100%;
                width: 100%;
                  background-color: #efefef;
                .image {
                    width: 100%;
                    height: 100%;
                }
            }

            /deep/.el-carousel__button {
                border: 3px solid #f28c02;
                border-radius: 5px 5px;
                padding: 5px 10px;
            }
        }
    }

    @media screen and (min-width: 1371px) {
        .block {
            width: 100%;
            // margin-top: 5rem;

            .lunbotu {
                /deep/.el-carousel__container {
                    width: 100%;
                    height: 40.625rem;
                }
              background-color: #efefef;
                height: 100%;
                width: 100%;

                .image {
                    width: 100%;
                    height: 100%;
                }
            }

            /deep/.el-carousel__button {
                border: 3px solid #f28c02;
                border-radius: 5px 5px;
                padding: 5px 10px;
            }

            // .el-carousel__container {
            //   position: relative;
            //   height: 450 / 16rem;
            // }
        }

    }


    .culture_brand {
        width: 100%;
        background: #ffffff;
        padding-bottom: 20px;

        .text_list {
            width: 60%;
            margin: 0 auto;
            font-size: 1.875rem;
            color: #333333;
            letter-spacing: 5px;
            padding: 3rem 0 3rem 0;
        }
    }

    .major_service {
        width: 100%;
        // background: #ffffff;
        padding-bottom: 20px;
        // margin-top: 20px;

        .middle_major_show {
            width: 80%;
            height: 14rem;
            // border: 1px solid red;
            margin: 0 auto;
            margin-top: 2rem;
            display: flex;
            justify-content: space-between;

            .middle_major_showchild {
                width: 48%;
                border: 1px solid red;
                background-color: #fff;
                height: 100%;
            }
        }

        .hotContent {
            width: 60%;
            margin: 0 auto;
            padding-top: 5rem;
            // border: 1px solid red;

            // height: 200px;
            .box_hot:hover {

                .right_hot {
                    p:nth-child(1) {
                        color: #f28c02;

                    }
                }
            }

            .box_hot {
                width: 100%;
                height: 10rem;
                display: flex;
                justify-content: space-around;
                background: #ffffff;
                border-radius: 10px 10px;
                box-shadow: 2px 2px rgb(216, 212, 212);
                padding: 20px 0;
                margin-bottom: 2rem;

                .left_hot {
                    width: 35%;

                    // border: 1px solid rebeccapurple;
                    .image {
                        width: 100%;
                        height: 100%;
                    }
                }

                .right_hot {
                    width: 55%;
                    // border: 1px solid rebeccapurple;
                    position: relative;

                    p:nth-child(1) {
                        font-size: 1.8rem;
                        margin-bottom: 10px;
                        color: #333333
                    }

                    p:nth-child(2) {
                        font-size: 1.125rem;
                        color: #666666
                    }

                    p:nth-child(3) {
                        font-size: 1.125rem;
                        position: absolute;
                        bottom: 0;
                        color: #333333;
                        display: flex;
                        width: 100%;
                        justify-content: space-between;

                        span:nth-child(2) {
                            color: #389efc;
                            cursor: pointer;
                        }

                    }

                    p:nth-child(1):hover {
                        color: #f28c02;
                        cursor: pointer;
                    }
                }
            }
            .paging{
                //  border: 1px solid pink;
                //  float: left;
                text-align: right;
                margin-right: -10px;
                margin-bottom: 6rem;
            }
        }

        .text_list {
            width: 70%;
            margin: 0 auto;
            font-size: 1.875rem;
            color: #333333;
            letter-spacing: 5px;
            padding: 3rem 0 20px 0;
        }
    }

    .middle_show {
        width: 80%;
        // border: 1px solid red;
        margin: 0 auto;
        height: auto;
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;

        .middle_showchild {
            width: 20%;
            text-align: center;
            height: 100%;
            border: 1px solid #fff;
            background-color: #fff;

            img {
                width: 70%;
                // border: 1px solid red;
                margin: 0 auto;
                margin-top: 7%;
            }

            b {
                font-size: 1rem;
                color: #000;
            }

            p {
                padding: 15px 0 15px 0;
            }

            .el-button {
                margin-bottom: 20px;
            }
        }
    }

    .titleDisplay {
        background-color: #efefef;
        width: 100%;

        // padding-bottom: 3rem;
        .item-title {
            padding-top: 6rem;
            // padding-bottom: 3rem;
            text-align: center;
            color: #333333;
            width: 100%;
            margin: 0 auto;
            font-size: 2.1rem;
            letter-spacing: 5px;
            font-weight: 550;

            p {
                text-shadow: 1px 1px 1px #666;
                //  text-decoration: underline;
                // border-bottom: 8px solid #f28c02;
                display: inline-block;
                padding: 0 50px;
            }

            span {
                width: 14%;
                margin: 0 auto;
                height: 8px;
                margin-top: -10px;
                display: block;
                // background: #f28c02;
                background: rgba(242, 140, 2, 0.7);
            }
        }

    }
</style>
