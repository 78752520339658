<template>
  <div>
    <div class="outting">
      <div class="select">
        <span class="title">NB卡管理</span>
      </div>
      <div class="detail">
          <el-tabs v-model="activeName" style="width:96%;margin:auto"  @tab-click="handleClick">
    <el-tab-pane label="电信"  name="first"> 
      <div style=" float: right;margin-bottom:5px" >   
         <el-upload   class="select-button" style="display:inline" action :on-change="handleChange"
                :on-remove="handleRemove" :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList"
                :limit="limitUpload" :auto-upload="false" ref="my-upload">
                <el-button size="mini">上传文件</el-button>
            </el-upload>
            <el-button size="mini"   @click="chinanetCardExport">导出文件</el-button></div>
  
             
      <el-card class="box-card" shadow="never">
          <h5>卡资料信息</h5>
          <span class="new-span">接入号码：</span>
          <el-input size="mini" clearable style="width:15rem;margin-right:1rem;margin-bottom:1rem"
            v-model="accessNumber" placeholder="请输入接入号码："></el-input>
          <el-button size="mini" type="primary" @click="inquireAccessNumber()">查询</el-button>
          <br>
          <span class="new-span">卡归属地:</span> <span class="new-vv" style="color:#66b1ff">{{simAttr ||'暂无'}}</span>
          <span class="new-span">到期时间 :</span> <span class="new-vv" style="color:#66b1ff">{{expDate ||'暂无'}}</span>
        </el-card>
        <el-card class="box-card" shadow="never">
          <h5>根据imei获取卡号信息</h5>
          <span class="new-span">IMEI：</span>
          <el-input size="mini" style="width:15rem;margin-right:1rem;margin-bottom:1rem" clearable v-model="imei"
            placeholder="请输入IMEI"></el-input>
          <el-button size="mini" type="primary" @click="inquireImei()">查询</el-button>
          <br>
          <span class="new-span">接入号码:</span> <span class="new-vv"
            style="color:#66b1ff">{{accessNumberimei ||'暂无'}}</span>
          <span class="new-span">iccid:</span> <span class="new-vv" style="color:#66b1ff">{{iccidimei ||'暂无'}}</span>
          <span class="new-span">imsi:</span> <span class="new-vv" style="color:#66b1ff">{{imsiimei||'暂无'}}</span>
        </el-card>
        <el-card class="box-card" shadow="never">
          <h5>设备imei查询</h5>
          <span class="new-span">请求编码：</span>
          <el-select v-model="code" size="mini" style="margin-right:1rem" placeholder="请选择">
            <el-option v-for="item in codeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="new-span">请求值：</span>
          <el-input size="mini" style="width:15rem;margin-right:1rem;margin-bottom:1rem" v-model="valueimei"
            placeholder="请输入请求值"></el-input>
          <el-button size="mini" type="primary" @click="inquire()">查询</el-button>
          <br>
          <span class="new-span">imei:</span> <span class="new-vv" style="color:#66b1ff">{{valueImei ||'暂无'}}</span>

        </el-card>
        <el-card class="box-card" shadow="never">
          <h5>三码互查</h5>
          <span class="new-span">请求编码：</span>
          <el-select v-model="codeThree" size="mini" style="margin-right:1rem" placeholder="请选择">
            <el-option v-for="item in codeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="new-span">请求值：</span>
          <el-input size="mini" style="width:15rem;margin-right:1rem;margin-bottom:1rem" v-model="valueimeiThree"
            placeholder="请输入请求值"></el-input>
          <el-button size="mini" type="primary" @click="inquireThree()">查询</el-button>
          <br>
          <span class="new-span">接入号码:</span> <span class="new-vv"
            style="color:#66b1ff">{{accessNumberThree ||'暂无'}}</span>
          <span class="new-span">iccid:</span> <span class="new-vv" style="color:#66b1ff">{{iccidThree ||'暂无'}}</span>
          <span class="new-span">imsi:</span> <span class="new-vv" style="color:#66b1ff">{{imsiThree ||'暂无'}}</span>
        </el-card>
          
        <el-card class="box-card" shadow="never">
          <h5>卡信息更新</h5>
          <span class="new-span">IMEI：</span>
          <el-input size="mini" clearable style="width:15rem;margin-right:1rem;margin-bottom:1rem"
            v-model="chinanetCardu" placeholder="请输入IMEI："></el-input>
          <el-button size="mini" type="primary" @click="chinanetCardupdate()">更新</el-button>
          
           <el-upload style="display:inline" class="upload-demo" action :on-change="handleChange"
                        :on-remove="handleRemove" :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList"
                        :limit="limitUpload" :auto-upload="false" ref="my-upload">
                        <el-button type="info"  round size="mini" class="input-button">卡批量更新</el-button>
                        <!-- <span style="color: red;" >*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式 -->
                        <br />
                    </el-upload> 
                      <el-button size="mini"  class="upload-demo"   type="primary" @click="chinanetCardupdateB()">批量更新</el-button>
                      <a href="./imeis.xlsx" download="卡批量更新模板"  class="down_template">下载卡批量更新模板</a>
          <br>

          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="accessNumber" label="接入号码" ></el-table-column>
            <el-table-column prop="createTime" label="创建时间" ></el-table-column>
            <el-table-column prop="expDate" label="到期时间"></el-table-column>
            <el-table-column prop="iccid" label="iccid"></el-table-column>
            <el-table-column prop="imei" label="设备识别码"></el-table-column>
            <el-table-column prop="imsi" label="imsi"></el-table-column>
            <el-table-column prop="isAuth" label="权限 "></el-table-column>
            <el-table-column prop="netOperator" label="运营商类型 "></el-table-column>
            <el-table-column prop="notificationDate " label="通知日期 "></el-table-column>
            <el-table-column prop="simAttr" label="卡归属地 "></el-table-column>
            <el-table-column prop="updateTime" label="更新时间 "></el-table-column>
          </el-table>
        </el-card></el-tab-pane>
    <el-tab-pane label="移动" name="second"> 
      <el-card class="box-card" shadow="never">
          <h5>卡资料信息</h5>
          <span class="new-span">imei:</span>
          <el-input size="mini" clearable style="width:15rem;margin-right:1rem;margin-bottom:1rem"
            v-model="chinamobileImei" placeholder="imei"></el-input>
          <el-button size="mini" type="primary" @click="chinamobile()">查询</el-button>
          <br>
          <span class="new-span">到期时间 :</span> <span class="new-vv" style="color:#66b1ff">{{chinamobileexpDate ||'暂无'}}</span>
        </el-card>
   
        
      
          
        <el-card class="box-card" shadow="never">
          <h5>卡信息更新</h5>
          <span class="new-span">IMEI：</span>
          <el-input size="mini" clearable style="width:15rem;margin-right:1rem;margin-bottom:1rem"
            v-model="chinanetCardu" placeholder="请输入IMEI："></el-input>
          <el-button size="mini" type="primary" @click="chinanetCardupdate()">更新</el-button>
          
           <el-upload style="display:inline" class="upload-demo" action :on-change="handleChange"
                        :on-remove="handleRemove" :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList"
                        :limit="limitUpload" :auto-upload="false" ref="my-upload">
                        <el-button type="info"  round size="mini" class="input-button">卡批量更新</el-button>
                        <!-- <span style="color: red;" >*</span>请导入xls/xlsx格式文件，文件内容设置为文本格式 -->
                        <br />
                    </el-upload> 
                      <el-button size="mini"  class="upload-demo"   type="primary" @click="chinanetCardupdateB()">批量更新</el-button>
                      <a href="./imeis.xlsx" download="卡批量更新模板"  class="down_template">下载卡批量更新模板</a>
          <br>

          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="accessNumber" label="接入号码" ></el-table-column>
            <el-table-column prop="createTime" label="创建时间" ></el-table-column>
            <el-table-column prop="expDate" label="到期时间"></el-table-column>
            <el-table-column prop="iccid" label="iccid"></el-table-column>
            <el-table-column prop="imei" label="设备识别码"></el-table-column>
            <el-table-column prop="imsi" label="imsi"></el-table-column>
            <el-table-column prop="isAuth" label="权限 "></el-table-column>
            <el-table-column prop="netOperator" label="运营商类型 "></el-table-column>
            <el-table-column prop="notificationDate " label="通知日期 "></el-table-column>
            <el-table-column prop="simAttr" label="卡归属地 "></el-table-column>
            <el-table-column prop="updateTime" label="更新时间 "></el-table-column>
          </el-table>
        </el-card></el-tab-pane>
  </el-tabs>
     
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "cardManagement",
    data() {
      return {
        accessNumber: "",
        chinamobileImei:"",
         activeName: 'first',
         chinamobileexpDate:"",
        tableData: [],
         fileList: [],
         nameData: [],
        simAttr: "",
        expDate: "",
        chinanetCardu: "",
        codeThree: "",
        limitUpload:1,
        valueimeiThree: "",
        imei: "",
        accessNumberimei: "",
        iccidimei: "",
        imsiimei: "",
        valueimei: "",
        code: "",
        valueImei: "",
        accessNumberThree: "",
        iccidThree: "",
        imsiThree: "",
        codeList: [{
          label: "接入号码",
          value: "0"
        }, {
          label: "iccid",
          value: "1"
        }, {
          label: "imsi",
          value: "2"
        }]
      };
    },
    created() {

    },
    mounted() {


    },
    methods: {
        //导出函数
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
      // 电信卡导出
      chinanetCardExport(){

          var formData = new FormData();
          console.log(this.nameData)
       formData.append('file', this.fileTemp)
     
            // for(var i = 0;i <  this.nameData.length;i ++) {
            //     formData.append(file this.nameData[i], this.nameData[i].name);      //  多张图片文件都放进一个form-data，有些小伙伴说这里应该这样写：formData.append("file[" + i + "]", files[i], files[i].name)，后端才能拿到完整的图片数组，其实不然，用这个formData.getAll("file")方法恰好拿到数组，或者也可以forEach获取，有很多种办法。
            // }
    this.$api
          .chinanetCardExport(
        formData
          )
          .then(res => {
            console.log(res.data.data)
              this.download(res.data);
            if (res.data.data) {


            }

          });
      },
      // 移动卡
      chinamobile(){
      this.$api
          .chinamobile(this.chinamobileImei)
          .then(res => {
          
            if (res.data.data) {
           
              this.chinamobileexpDate = res.data.data
            }

          });
      },
        handleClick(tab, event) {
        console.log(tab, event);
      },
      // 卡资料信息
      inquireAccessNumber() {
        this.simAttr = ''
        this.expDate = ''
        this.$api
          .cardDataInfo(this.accessNumber)
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {
              this.simAttr = res.data.data.simAttr
              this.expDate = res.data.data.expDate
            }

          });
      },
      // 根据imei获取卡号信息
      inquireImei() {
        this.accessNumberimei = ''
        this.iccidimei = ''
        this.imsiimei = ''
        this.$api
          .cardInfo(this.imei)
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {
              this.accessNumberimei = res.data.data.accessNumber
              this.iccidimei = res.data.data.iccid
              this.imsiimei = res.data.data.imsi
            }

          });
      },
      inquire() {
        this.valueImei = ""
        this.$api
          .getImei({
            code: this.code,
            value: this.valueimei,
          })
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {
              this.valueImei = res.data.data
            }

          });
      },
      // 三码互查
      inquireThree() {
        this.accessNumberThree = ""
        this.iccidThree = ""
        this.imsiThree = ""
        this.$api
          .telephonePlus({
            code: this.codeThree,
            value: this.valueimeiThree,
          })
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {
              this.accessNumberThree = res.data.data.accessNumber
              this.iccidThree = res.data.data.iccid
              this.imsiThree = res.data.data.imsi
            }

          });
      },
      // 卡信息更新
      chinanetCardupdate() {

        this.$api
          .chinanetCardupdate(
          this.chinanetCardu.split(',')
          )
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {
               this.tableData = res.data.data

            }

          });
      },
        // 卡信息更新
      chinanetCardupdateB() {

        this.$api
          .chinanetCardupdate(
         this.nameData
          )
          .then(res => {
            console.log(res.data.data)
            if (res.data.data) {


            }

          });
      },
       handleChange(file, fileList) {
                this.fileTemp = file.raw;
                if (this.fileTemp) {
                    this.importfxx(this.fileTemp);
                } else {
                    this.$message({
                        type: "warning",
                        message: "请上传附件！"
                    });
                }
            },
            //超出最大上传文件数量时的处理方法
            handleExceed(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },
            //移除文件的操作方法
            handleRemove(file, fileList) {
                this.fileTemp = null;

            },
            importfxx(obj) {
                let _this = this;
                let inputDOM = this.$refs.inputer;
                // 通过DOM取文件数据
                this.file = event.currentTarget.files[0];
                var rABS = false; //是否将文件读取为二进制字符串
                var f = this.file;
                var reader = new FileReader();

                //if (!FileReader.prototype.readAsBinaryString) {
                FileReader.prototype.readAsBinaryString = function (f) {
                    var binary = "";
                    var rABS = false; //是否将文件读取为二进制字符串
                    var pt = this;
                    var wb; //读取完成的数据
                    var outdata;
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        var bytes = new Uint8Array(reader.result);
                        var length = bytes.byteLength;
                        for (var i = 0; i < length; i++) {
                            binary += String.fromCharCode(bytes[i]);
                        }

                        //此处引入，用于解析excelnpm
                        var XLSX = require("xlsx");

                        if (rABS) {
                            wb = XLSX.read(btoa(fixdata(binary)), {
                                //手动转化
                                type: "base64"
                            });
                        } else {
                            wb = XLSX.read(binary, {
                                type: "binary"
                            });
                        }
                        outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                        //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
                        //此处可对数据进行处理
                        let arr = [];
                        let arr2 = [];
                        // outdata.map(v => {
                        //     let mac = v["IMEI"];
                        //     if (mac != "") {
                        //         arr.push(mac);
                        //     }
                        // });
                        _this.nameData = outdata;
                        _this.$message({
                            type: "success",
                            message: "导入成功，共导入" + outdata.length + "个"
                        });
                        return arr;
                    };
                    reader.readAsArrayBuffer(f);
                };
                if (rABS) {
                    reader.readAsArrayBuffer(f);
                } else {
                    reader.readAsBinaryString(f);
                }
            },
      resetForm(ruleForm) {
        this.$refs[ruleForm].resetFields();
      },
    }
  };
</script>
<style lang="less" scoped>
  @border-bottom-color: #e7eaed;
  @toolBarFontColor: #111d;

  .outting {
    height: 100%;
    overflow: hidden;
    background-color: #fafafa;

    .box-card {
      width: 96%;
      margin: 1rem auto;
    }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .title {
        display: inline-block;
        line-height: 46px;
        font-weight: bold;
        font-size: 0.8rem;
      }

      button {
        float: right;
        color: @toolBarFontColor;
        margin-top: 7px;
      }
    }

    .grid-content {
      border-radius: 4px;
      min-height: 36px;
      padding: 0rem 3rem;
    }

    .new-span {
      font-size: 12 /16rem;
      color: #898989;
    }

    .newSpan {
      font-size: 12 /16rem;
      color: red;
    }

    .new-vv {
      font-size: 13 / 16rem;
      margin-top: 5 /16rem;
      //   cursor: pointer;
      margin-right: 4rem;

      /*鼠标悬停变小手*/
    }

    .newVv {
      font-size: 14 /16rem;
      margin-top: 5 /16rem;
      color: whitesmoke;
    }
    .input-button{
      margin-left: 3rem;
    }
    a{
      font-size: 0.8rem;
      color: #898989;
      float: right;
    }
    
        .upload-demo {
            margin-left: 0.5em;
            display: inline-block;
           
        }
          /deep/.el-upload-list {
        display: inline-block;
         transform: translate(10%,30%);
    }
  }
</style>