<template>
  <div class="outting">
    <div class="select">
      <span class="title">编辑角色</span>
       <el-button type="text" size="small" icon="el-icon-back" @click="goBackHandler">返回角色列表</el-button>
    </div>
    <!-- 左边权限勾选开始 -->
    <div class="container">
      <div class="left">
        <div class="left-title">
          <span class="left-title-text">角色信息</span>
        </div>
        <div class="left-container">
          <el-form
            :model="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            label-position="left"
          >
            <!-- <h3>角色信息</h3> -->
            <el-form-item label="角色名称">
              <el-input v-model="ruleForm.roleName" :placeholder="ruleForm.roleName"></el-input>
            </el-form-item>
            <el-form-item label="角色介绍">
              <el-input v-model="ruleForm.remark" :placeholder="ruleForm.remark"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="right">
        <div class="right-title">
          <span class="right-title-text">权限信息</span>
        </div>
        <div class="right-container">
          <el-tree
            ref="tree"
            :data="dataTree"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            @check="getCheckNode"
            :default-checked-keys="this.defaultDisplayIdTwo"
            :default-expanded-keys="defaultDisplayId"
          ></el-tree>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Storage from "../../../utils/Storage";

export default {
  name: "EditRole",
  data() {
    return {
      leafList: [],
      defaultDisplayIdTwo: [],
      oldCheckNodeList: [],

      defaultDisplayId: [],
      ifDianJi: false,
      defaultId: [],
      allChildrenId: [],
      ifAdd: true,
      activeName: "first",
      oldEditableTabs: [],
      defaultNodesThree: [],
      editableTabs: [],
      defaultProps: {
        children: "children",
        label: "name",
        label: "permissionName",
        id: "id"
      },
      defaultNodes: [],
      checkNodeList: [],
      ruleForm: {
        roleName: "",
        remark: ""
      },
      dataTree: []
    };
  },
  created() {
    var allId = [];
    var token = this.$store.state.userinfo.token;
    this.$api.permissionList(token).then(res => {
      // console.log( res.data.data)
      this.oldEditableTabs = res.data.data;
      var oldEditableTabsOne = res.data.data;
      var keyMap = {
        permissionList: "children",
        platformId: "id",
        platformName: "permissionName"
      };
      this.defaultDisplayId = [];
      oldEditableTabsOne.forEach(element => {
        console.log(element);
        console.log(element.platformId);
        this.defaultDisplayId.push(element.platformId);
      });

      for (var i = 0; i < oldEditableTabsOne.length; i++) {
        var obj = oldEditableTabsOne[i];
        for (var key in obj) {
          var newKey = keyMap[key];
          if (newKey) {
            obj[newKey] = obj[key];
            delete obj[key];
          }
        }
      }

      this.arr = [];
      for (var i = 0; i < oldEditableTabsOne.length; i++) {
        oldEditableTabsOne[i].children = Storage.toTreePower(
          oldEditableTabsOne[i].children
        );
        this.arr.push(oldEditableTabsOne[i].id);
      }
      this.dataTree = oldEditableTabsOne;

      console.log(oldEditableTabsOne);
      var leafList = [];
      var leafListOne = Storage.leafHandler(oldEditableTabsOne, leafList);
      console.log(leafListOne);
      this.leafList = leafListOne; //获取所有叶子节点
      this.$api
      .roleDetail(Storage.getItem("editId"), token)
      .then(res => {
        console.log(res.data.data)
        var defaultId = [];
        var permissionListOne = res.data.data.permissions;
        permissionListOne.forEach(item => {
          // console.log(item.id)
          defaultId.push(item.id);
        });
        var allDefaultId = [];
        for (var i = 0; i < this.leafList.length; i++) {
          for (var j = 0; j < defaultId.length; j++) {
            if (this.leafList[i] == defaultId[j]) {
              allDefaultId.push(defaultId[j]);
            }
          }
        }
        console.log(allDefaultId);
        this.defaultId = defaultId;
        this.defaultDisplayIdTwo = allDefaultId;
        this.ruleForm.roleName = res.data.data.roleName;
        this.ruleForm.remark = res.data.data.remark;
        this.roleId = res.data.data.id;
      })
      .catch(err => {
        console.log(err);
      });
    })
  },
  methods: {
    goBackHandler() {
      this.$router.replace("/sysrole");
    },

    getCheckNode(obj1, obj2) {
      this.ifDianJi = true;
      // console.log(obj2.checkedKeys);
      // console.log(obj2.halfCheckedKeys);
      var subNodeList = [];
      var oldCheckNodeList = obj2.checkedKeys.concat(obj2.halfCheckedKeys);

      console.log(oldCheckNodeList)
      this.oldCheckNodeList = oldCheckNodeList;
      for (var i = 0; i <= this.oldCheckNodeList.length; i++) {
        for (var j = 0; j <= this.defaultDisplayId.length; j++) {
          if (this.oldCheckNodeList[i] == this.defaultDisplayId[j]) {
            this.oldCheckNodeList.splice(i, 1);
          }
        }
      }
      console.log(this.oldCheckNodeList)
    },
    submitForm() {
      var token = this.$store.state.userinfo.token;
      this.sendList = [];
      if (this.ifDianJi) {
        this.sendList = this.oldCheckNodeList;
      } else {
        this.sendList = this.defaultId;
      }
      this.$api
        .editRole(
          {
            id: this.roleId,
            permissions: this.sendList,
            remark: this.ruleForm.remark,
            roleName: this.ruleForm.roleName
          },
          token
        )
        .then(res => {
          // console.log(res);
          if (res.data.code == 200) {
            this.$message.success("编辑成功！");
            this.$router.replace("/sysrole");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: #111d;

.outting {
  width: 100%;
  background-color: #fafafa;
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #cccccc;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    button {
      color: @toolBarFontColor;
      font-size: 0.8rem;
      float: right;
      margin-top: 7px;
    }
  }

  .container {
    margin-top: 20px;
    padding: 0rem 3rem;

    .left {
      width: 49.5%;
      background-color: #ffffff;
      float: left;

      .left-title {
        width: 100%;
        height: 36px;
        border-bottom: 1px solid #cccccc;

        span {
          display: inline-block;
          margin-top: 7px;
          font-weight: bold;
          font-size: 0.8rem;
          margin-left: 1rem;
        }
      }

      .left-container {
        margin-top: 1rem;
        padding: 0 1rem;
      }
    }

    .right {
      width: 49.5%;
      margin-left: 1%;
      background-color: #ffffff;
      float: left;

      .right-title {
        width: 100%;
        height: 36px;
        border-bottom: 1px solid #cccccc;

        span {
          display: inline-block;
          margin-top: 7px;
          font-weight: bold;
          font-size: 0.8rem;
          margin-left: 1rem;
        }
      }
    }

    .right-container {
      margin-top: 1rem;
      padding: 0 0.5rem;
    }
  }
}
</style>
