<template>

  <div class="outting">
    <div class="select">
      <span class="title">
        故障报警列表
      </span>
    </div>
    <div class="main-content">
      <div class="tab-nav">
        <div class="tab-item left" id="realTimeFireAlarm" ref="realTimeFireAlarm" @click="changeStyleAndView($event)">
          故障实时列表
        </div>
        <div class="tab-item" id="todoFireAlarm" ref="todoFireAlarm" @click="changeStyleAndView($event)">故障待处理列表
        </div>
        <div class="tab-item right" id="dealWithFireAlarm" ref="dealWithFireAlarm" @click="changeStyleAndView($event)">
          故障已处理列表
        </div>
      </div>
      <div v-show="realTimeFireAlarm">
        <div class="select">
          <!-- <span class="title">
            故障实时列表
            <el-tooltip class="item" effect="light" content="当前正在报故障的设备如果没有上报故障消除，又没有持续上报故障，则会在半个小时后从该页面消除"
                        placement="right-end">
              <i class="el-icon-info" style="margin-left: 5px"></i>
            </el-tooltip>
          </span> -->

          <div class="select-input-right-input">
            <el-input v-model="imei" placeholder="请输入IMEI号" size="small" @keyup.13.native="keywordsSearch">
              <el-button slot="append" icon="el-icon-search" @click="keywordsSearch"></el-button>
            </el-input>
          </div>
        </div>

        <div class="alermTable">

          <el-table :data="tableData" stripe size="small" class="device-table"
            :header-cell-style="{'background-color': '#eeeeee'}">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="mac" label="设备IMEI" min-width="1" align="center" v-if="ifMac">
              <template slot-scope="{row}">{{ row.mac || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="mac" label="设备名称" min-width="1" align="center">
              <template slot-scope="{row}">{{ row.deviceName || '暂无数据' }}</template>
            </el-table-column>

            <el-table-column prop="name" label="产品名称" min-width="1" align="center" v-if="ifName">
              <template slot-scope="{row}">{{ row.productName || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="product" label="所属项目" min-width="1" align="center" v-if="ifProduct">
              <template slot-scope="{row}">{{ row.projectName || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="place" label="当前归属账号" min-width="1" align="center" v-if="ifPlace">
              <template slot-scope="{row}">{{ row.username || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="provider" label="场地名称" min-width="1" align="center" v-if="ifProvider">
              <template slot-scope="{row}">{{ row.placeName || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="time" label="场地地址" min-width="1" align="center" v-if="ifTime">
              <template slot-scope="{row}">{{ row.location || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column min-width="1" label="操作" align="center" width="100px">
              <template slot-scope="scope">
                <el-button type="text" size="small" icon="el-icon-info" @click="handleDetail(scope.$index, scope.row)">
                  查看详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :current-page="current"
            :total="total" layout="sizes,prev, pager, next" @size-change="handleSizeChange"
            @current-change="handlePageChange"></el-pagination>
        </div>
      </div>
      <div v-show="todoFireAlarm">
        <!-- <div class="select"> -->
        <!-- <span class="title">
            故障待处理列表
          </span> -->
        <!-- </div> -->
        <div class="alermTable">
          <el-table :data="tableDataDevice" style="width: 100%" stripe size="small"
            :header-cell-style="{'background-color': '#eeeeee'}">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="checkResult" label="处理结果" min-width="1" align="center">
              <template slot-scope="{row}">{{
                  row.checkResult == 0 ? "真实故障" : row.checkResult == 1 ? "测试" : row.checkResult == 2? "误报" : "暂无数据"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="checkType " label="处理状态" min-width="1" align="center">
              <template slot-scope="{row}">{{
                  row.checkType == 0 ? "待处理" : row.checkType == 1 ? " 已处理 " : "暂无数据"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="dataPointShowName" label="数据点值" min-width="1" align="center">
              <template slot-scope="{row}">{{ row.dataPointShowName || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="location " label="场地地址 " min-width="1" align="center" v-if="ifProduct">
              <template slot-scope="{row}">{{ row.location || '暂无数据' }}</template>
            </el-table-column>
            +
            <el-table-column prop="mac " label="设备名称" min-width="1" align="center" v-if="ifPlace">
              <template slot-scope="{row}">{{ row.mac || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="placeName" label="场地名称" min-width="1" align="center" v-if="ifProvider">
              <template slot-scope="{row}">{{ row.placeName || '暂无数据' }}</template>
            </el-table-column>

            <el-table-column prop="productName" label="产品名称" min-width="1" align="center" v-if="ifProvider">
              <template slot-scope="{row}">{{ row.productName || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="reportTime" label="首次上报时间" min-width="1" align="center" v-if="ifProvider">
              <template slot-scope="{row}">{{ row.reportTime || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="updatetime" label="最新上报时间" min-width="1" align="center" v-if="ifProvider">
              <template slot-scope="{row}">{{ row.updatetime || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column min-width="1" label="操作" align="center" width="100px">
              <template slot-scope="scope">
                <el-button type="text" size="small" icon="el-icon-info" @click="handleList(scope.$index, scope.row)">处理
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!--          <el-pagination background :page-sizes="[10, 20, 50, 100]" :page-size="flultPageSize"-->
          <!--                         :current-page="flultCurrent" :total="totalDevice" layout="sizes,prev, pager, next"-->
          <!--                         @size-change="deviceHandleSizeChange" @current-change="deviceHandlePageChange"></el-pagination>-->
          <el-dialog title="处理：" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form">
              <el-form-item label="处理结果" :label-width="formLabelWidth" prop="checkResult">
                <el-select v-model="form.checkResult" clearable style="width:60%;margin-bottom:10px">
                  <el-option v-for="item in checkResultList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="备注信息" :label-width="formLabelWidth" prop="remark">
                <el-input v-model="form.remark" type="textarea" :rows="3" style="width:60%;margin-bottom:10px"
                  autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="submitFormFandle('form')">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
      <div v-show="dealWithFireAlarm">
        <!-- <div class="select"> -->
        <!-- <span class="title">
            故障已处理列表
          </span> -->
        <!-- </div> -->
        <div class="alermTable">
          <div class="middle-component">
            <span style="display:inline-block;margin-bottom:10px;font-size:0.9rem;margin-right:10px">选择时间：</span>
            <el-date-picker v-model="timeValue" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
              size="small" :picker-options="pickerOptions2" value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
              @change="getTimeBlock"></el-date-picker>
          </div>
          <el-table :data="doneTableDataDevice" style="width: 100%" stripe size="small"
            :header-cell-style="{'background-color': '#eeeeee'}">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="checkResult" label="处理结果" min-width="1" align="center">
              <template slot-scope="{row}">{{
                   row.checkResult == 0 ? "真实故障" : row.checkResult == 1 ? "测试" : row.checkResult == 2 ? "误报" : "暂无数据"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="checkType " label="处理状态" min-width="1" align="center">
              <template slot-scope="{row}">{{
                  row.checkType == 0 ? "待处理" : row.checkType == 1 ? " 已处理 " : "暂无数据"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="dataPointShowName" label="数据点值" min-width="1" align="center">
              <template slot-scope="{row}">{{ row.dataPointShowName || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="location " label="场地地址 " min-width="1" align="center" v-if="ifProduct">
              <template slot-scope="{row}">{{ row.location || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="mac " label="设备名称" min-width="1" align="center" v-if="ifPlace">
              <template slot-scope="{row}">{{ row.mac || '暂无数据' }}</template>
            </el-table-column>  
            <el-table-column prop="placeName" label="场地名称" min-width="1" align="center" v-if="ifProvider">
              <template slot-scope="{row}">{{ row.placeName || '暂无数据' }}</template>
            </el-table-column>

            <el-table-column prop="productName" label="产品名称" min-width="1" align="center" v-if="ifProvider">
              <template slot-scope="{row}">{{ row.productName || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="reportTime" label="首次上报时间" min-width="1" align="center" v-if="ifProvider">
              <template slot-scope="{row}">{{ row.reportTime || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column prop="updatetime" label="最新上报时间" min-width="1" align="center" v-if="ifProvider">
              <template slot-scope="{row}">{{ row.updatetime || '暂无数据' }}</template>
            </el-table-column>
            <el-table-column min-width="1" label="操作" align="center" width="100px">
              <template slot-scope="scope">
                <el-button type="text" size="small" icon="el-icon-info"
                  @click="doneHandleList(scope.$index, scope.row)">修改处理
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background :page-sizes="[10, 20, 50, 100]" :page-size="donePageSize"
            :current-page="doneCurrent" :total="doneTotalDevice" layout="sizes,prev, pager, next"
            @size-change="doneHandleSizeChange" @current-change="doneHandlePageChange"></el-pagination>

          <el-dialog title="处理：" :visible.sync="doneDialogVisible" :lock-scroll="false" :close-on-click-modal="false">
            <el-form :model="doneForm" :rules="rules" ref="doneForm">
              <el-form-item label="处理结果" :label-width="formLabelWidth" prop="checkResult">
                <el-select v-model="doneForm.checkResult" clearable style="width:60%;margin-bottom:10px">
                  <el-option v-for="item in checkResultList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="备注信息" :label-width="formLabelWidth" prop="remark">
                <el-input v-model="doneForm.remark" type="textarea" :rows="3" style="width:60%;margin-bottom:10px"
                  autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="doneDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="doneSubmitFormFandle('form')">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import bus from "@/components/bus";

  export default {
    data() {
      return {
        itemIds: [
          "realTimeFireAlarm",
          "todoFireAlarm",
          "dealWithFireAlarm"
        ],
        realTimeFireAlarm: true,
        todoFireAlarm: false,
        dealWithFireAlarm: false,
        current: 1,
        pageSize: 10,
        total: 0,
        imei: "",
        ifSearchMany: false,
        value: "",
        inputKeyword: "",
        value1: "",
        visible: false,
        ifMac: true,
        donePageSize: 10,
        flultPageSize: 10,
        deviceFirePageSize: 10,
        doneFirePageSize: 10,
        deviceFireCurrent: 1,
        doneFireCurrent: 1,
        flultCurrent: 1,
        totalDevice: 0,
        doneTotalDevice: 0,
        tableDataDevice: [], //待处理表格函数
        doneTableDataDevice: [], //已完成表格函数
        dialogVisible: false, //处理弹框
        doneDialogVisible: false, //处理弹框
        formLabelWidth: "120px",
        rules: {
          checkResult: [{
            required: true,
            message: "请输入备注信息",
            trigger: "blur"
          }],
          remark: [{
            required: false,
            message: "请输入类型名称",
            trigger: "blur"
          }]
        },
        form: {
          checkResult: "",
          remark: "",
          dataPoint: "",
          mac: "",
          faultId: "",
        },
        doneForm: {
          checkResult: "",
          remark: "",
          dataPoint: "",
          mac: "",
          faultId: "",
        },
        ifName: true,
        doneCurrent: 1,
        startTime: "",
        endTime: "",
        timeValue: "", //故障完成选择时间
        ifProduct: true,
        ifPlace: true,
        ifProvider: true,
        ifTime: true,
        checkMac: true,
        checkName: true,
        checkProduct: true,
        checkPlace: true,
        checkProvider: true,
        checkTime: true,
        checkResultList: [{
            label: "真实故障",
            value: "0"
          },
          {
            label: "测试",
            value: "1"
          },
          {
            label: "误报",
            value: "2"
          }
        ],
        tableData: [], //表格函数
        pickerOptions2: {
          disabledDate(time) {
            let cuttentDate = new Date(
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
            );
            let hmCurrentDate = cuttentDate.getTime();
            let three = 90 * 24 * 3600 * 1000;
            let threeMonths = hmCurrentDate - three;
            return time.getTime() > hmCurrentDate || time.getTime() < threeMonths;
          }
        },
        pickerOptionsTwo: {
          shortcuts: [{
              text: "最近一周",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "最近一个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "最近三个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit("pick", [start, end]);
              }
            }
          ]
        }
      };
    },
    methods: {
      handleSizeChange(val) {
        this.pageSize = val;
        this.getFaultList();
      },
      handlePageChange(val) {
        this.current = val;
        this.getFaultList();
      },
      changeStyleAndView(event) {
        var el = event.currentTarget;
        console.log("当前对象的内容：" + el.id);
        el.style.borderBottom = "3px solid #12b7f5";
        switch (el.id) {
          case "realTimeFireAlarm":
            this.realTimeFireAlarm = true;
            this.getFaultList()
            break;
          case "todoFireAlarm":
            this.todoFireAlarm = true;
            this.deviceFireList(); //待处理
            break;
          case "dealWithFireAlarm":
            this.dealWithFireAlarm = true
            this.doneFauttList(); //已完成
            break;
          default:
            break;
        }
        this.itemIds.forEach(item => {
          if (item !== el.id) {
            switch (item) {
              case "realTimeFireAlarm":
                this.$refs.realTimeFireAlarm.style.borderBottom = "none";
                this.realTimeFireAlarm = false;
                break;
              case "todoFireAlarm":
                this.$refs.todoFireAlarm.style.borderBottom = "none";
                this.todoFireAlarm = false;
                break;
              case "dealWithFireAlarm":
                this.$refs.dealWithFireAlarm.style.borderBottom = "none";
                this.dealWithFireAlarm = false;
                break;
              default:
                break;
            }
          }
        });
      },
      //高级搜索点击函数
      playShow() {
        if (this.ifSearchMany == false) {
          this.ifSearchMany = true;
        } else {
          this.ifSearchMany = false;
        }
      },
      //搜索函数
      checkHandler() {},
      //清空函数
      emptyHandler() {},
      //搜索回车函数
      search() {},
      //高级搜索函数
      keywordsSearch() {
        this.current = 1;
        this.getFaultList();
      },
      shezhibiaotou() {},
      //导出函数
      ourPutHandler() {},
      //查看详情函数
      handleDetail(index, row) {
        this.$router.push({
          name: "faultDetail",
          params: {
            mac: row.mac
          }
        });
      },

      //获取时间函数2 m
      getTimeBlock() {

        if (this.timeValue) {
          this.startTime = this.timeValue[0];
          this.endTime = this.timeValue[1];
          this.doneFauttList()

        } else {
          this.startTime = "";
          this.endTime = "";
          this.doneFauttList()
        }
      },
      //删除函数
      handleDelete() {},
      //
      changeTableHead() {
        this.ifMac = this.checkMac;
        this.ifName = this.checkName;
        this.ifProduct = this.checkProduct;
        this.ifPlace = this.checkPlace;
        this.ifProvider = this.checkProvider;
        this.ifTime = this.checkTime;
      },
      handleClick(row) {
        console.log(row);
      },
      checkMacHandler() {},
      checkNameHandler() {},
      checkProductHandler() {},
      checkPlaceHandler() {},
      checkProviderHandler() {},
      checkTimeHandler() {},
      getFaultList() {
        this.$api
          .getFaultList({
            page: {
              current: this.current,
              size: this.pageSize
            },
            keyword: this.imei
          })
          .then(res => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          })
          .catch(error => {});
      },
      // 待处理分页方法
      deviceHandleSizeChange(val) {
        this.flultPageSize = val;
        this.deviceFireList();
      },
      deviceHandlePageChange(val) {
        this.flultCurrent = val;
        this.deviceFireList();
      },
      // 已完成分页方法
      doneHandleSizeChange(val) {
        this.donePageSize = val;
        this.doneFauttList();
      },
      doneHandlePageChange(val) {
        this.doneCurrent = val;
        this.doneFauttList();
      },
      // 待处理
      deviceFireList() {
        this.$api
          .devicefaultList({
            page: {
              current: this.flultCurrent,
              size: this.flultPageSize
            },
          })
          .then(res => {

            this.tableDataDevice = res.data.data;
            this.totalDevice = res.data.data.length;
            // this.doneFauttList();
          })
          .catch(error => {});
      },
      //已完成
      doneFauttList() {
        this.$api
          .doneFauttList({
            page: {
              current: this.doneCurrent,
              size: this.donePageSize
            },
            dealDto: {
              startTime: this.startTime,
              endTime: this.endTime
            }
          })
          .then(res => {
            this.doneTableDataDevice = res.data.data.records;
            this.doneTotalDevice = res.data.data.total;
          })
          .catch(error => {});
      },
      // 处理
      handleList(index, row) {
        this.dialogVisible = true;
        console.log(index, row);
        this.form.checkResult = "";
        this.form.remark = "";
        this.form.dataPoint = row.dataPoint;
        this.form.faultId = row.id;
        this.form.mac = row.mac;
      },
      // 修改处理
      doneHandleList(index, row) {
        this.doneDialogVisible = true;
        console.log(index, row);
        this.doneForm.checkResult = row.checkResult == 0 ? "真实故障" : row.checkResult == 1 ? "测试" : row.checkResult == 2 ?
          "误报" : "暂无数据";
        this.doneForm.remark = this.doneTableDataDevice[index].remark;
        this.doneForm.dataPoint = row.dataPoint;
        this.doneForm.faultId = row.id;
        this.doneForm.mac = row.mac;
      },
      // 处理确定接口
      submitFormFandle() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.dialogVisible = false;

            this.$api.faultDeall(this.form).then(res => {
              if (res.data.code == 200) {
                this.$message.success("处理成功!");
                this.deviceFireList();
                bus.$emit("refreshNum", true);
              }
            });
          } else {
            return false;
          }
        });
      },
      // 修改处理确定接口
      doneSubmitFormFandle() {
        this.$refs["doneForm"].validate(valid => {
          if (valid) {
            this.doneDialogVisible = false;
            this.doneForm.checkResult = this.doneForm.checkResult == "真实故障" ? 0 : this.doneForm.checkResult ==
              "测试" ? 1 : this.doneForm.checkResult == "误报" ? 2 : this.doneForm.checkResult;
            this.$api.faultDeall(this.doneForm).then(res => {
              if (res.data.code == 200) {
                this.$message.success("处理成功!");
                this.doneFauttList();
              }
            });
          } else {
            return false;
          }
        });
      },
      getTime(val) {
        //   获取时间并格式化
        // 获取前val天的时间
        var dt = new Date();
        val === 0 ? "" : dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000 * val);
        const y = dt.getFullYear();
        const m = (dt.getMonth() + 1 + "").padStart(2, "0");
        const d = (dt.getDate() + "").padStart(2, "0");
        const hh = (dt.getHours() + "").padStart(2, "0");
        const mm = (dt.getMinutes() + "").padStart(2, "0");
        const ss = (dt.getSeconds() + "").padStart(2, "0");

        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`; // 获取的时间格式为 2021-04-09 09:34:00
        // return `${y}-${m}-${d} `;  // 获取的时间格式为 2021-04-09
        // return y+m+d+hh+mm+ss  // 获取的时间格式为 20210409093733
        // return y + m + d  // 获取的时间格式为  20210409
      }
    },

    created() {
      this.endTime = this.getTime(0);
      this.startTime = this.getTime(3);
    },

    mounted() {
      this.getFaultList();
      // this.deviceFireList(); //待处理
      // this.doneFauttList(); //已完成
      if (this.$route.query.type == 2) {
        document.getElementById("todoFireAlarm").click();
      }
      bus.$on("refresh", msg => {
        document.getElementById("todoFireAlarm").click();
      });

    },


  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: #111d;

  .outting {
    width: 100%;

    .main-content {
      padding: 1rem 0 1rem 0;

      .tab-nav {
        //background: red;
        border-bottom: 1px solid #d9d9d9;

        .tab-item {
          cursor: pointer;
          display: inline-block;
          padding: 0 1.5rem;
          line-height: 2rem;
          font-weight: bold;
          font-size: small;

          &.left {
            margin-left: 3rem;
            border-bottom: 3px solid #4faaf0;
          }

          &.right {
            margin-right: 3rem;
          }

          &:hover {
            color: #4faaf0;
          }
        }
      }
    }

    .select {
      height: 46px;
      overflow: hidden;
      border-bottom: 1px solid #d9d9d9;
      padding: 0rem 3rem;

      .select-input-right-input {
        float: right;
        // width: 8rem;
        line-height: 46px;
      }
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .serachButton {
      color: @toolBarFontColor;
      font-size: 0.8rem;
      float: right;
      margin-top: 7px;
      margin-left: 15px;
    }

    .gaojiSearch {
      margin-top: 10px;
      padding: 1rem 3rem;
      margin-bottom: 10px;
      background-color: #f4f6fa;

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }

    .el-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .el-col {
      border-radius: 4px;
    }

    //   .bg-purple-dark {
    //     background: #99a9bf;
    //   }
    .grid-content {
      border-radius: 4px;
      min-height: 36px;
    }

    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }

    .alermTable {
      padding: 0rem 3rem;
      margin-top: 1rem;

      .el-pagination {
        margin: 1rem 0;
        float: right;
      }
    }
  }

  .headerShow-check {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 200px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

  }

  .dialog-footer {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 15px;
  }
</style>