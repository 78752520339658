<template>
  <div class="outting">
    <div class="select">
      <span class="title">角色列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-plus"
        @click="addRoleHandler"
        class="addRoleButton"
      >添加角色</el-button>
      <el-button
        type="text"
        size="small"
        icon="el-icon-bottom"
        @click="playShow"
        class="serachRoleButton"
      >高级查询</el-button>
      <div class="input-role">
        <el-input
          v-model="input"
          placeholder="请输入角色名称"
          prefix-icon="el-icon-search"
          class="select-input-right-input"
          size="small"
          @keyup.enter.native="search"
        ></el-input>
      </div>
    </div>
    <div class="gaojiSearch" v-show="ifSearchMany">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="grid-content bg-purple-dark">
            <span>创建时间</span>
            <div style="margin-top:5px;">
              <el-date-picker
                size="small"
                class="create-input-timer-select"
                v-model="value1"
                type="datetimerange"
                :picker-options="pickerOptionsOne"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00','23:59:59']"
                @change="getDataBlock"
                style="width:25.5rem;"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <div style="float:left;margin-left:1rem;">
          <div class="grid-content bg-purple-dark">
            <span>更新时间</span>
            <div style="margin-top:5px;">
              <el-date-picker
                size="small"
                class="update-input-timer-select"
                v-model="value2"
                type="datetimerange"
                :picker-options="pickerOptionsTwo"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00','23:59:59']"
                @change="getTimeBlock"
                style="width:25.5rem;"
              ></el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;margin-top:.5rem;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
              <el-button size="small" type="primary" @click="checkHandler">搜索</el-button>
            </div>
            <div class="emptyBut">
              <el-button size="small" @click="emptyHandler">清空</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 高级搜索框结束 -->
    <div class="roleTable">
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        size="small"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column type="selection" min-width="1"></el-table-column>
        <el-table-column prop="roleName" label="角色名" min-width="1" align="center"></el-table-column>
        <el-table-column prop="remark" label="简介" min-width="1" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="2" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="2" align="center"></el-table-column>
        <el-table-column prop="sysUserCount" label="系统用户数量" min-width="1" align="center"></el-table-column>
        <el-table-column min-width="1" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        class="pagin"
        @current-change="changeCurrent"
      ></el-pagination>

      <el-dialog
        title="提示"
        :visible.sync="deleteRoleFormVisible"
        width="30%"
        :close-on-click-modal="false"
      >
        <span>确定要删除"{{ this.deleteName }}"这个角色吗</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteRoleFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="deleteRoleHandler">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Storage from "../../../utils/Storage";
export default {
  name: "SysRole",
  components: {
    // AddRole
  },
  data() {
    return {
      deleteName: "",
      ifSearchMany: false,

      total: 0,
      formLabelWidth: "120px",
      //创建时间筛选
      pickerOptionsOne: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      value1: "",
      //更新时间筛选
      pickerOptionsTwo: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      value2: "",
      input: "",
      //表格数据
      tableData: [],
      //编辑角色数据

      row: {},
      editRoleForm: {
        name: "",
        remark: ""
      },
      rules: {
        // name: [{ required: true, message: "请输入模块名称", trigger: "blur" }],
      },
      //删除角色数据
      deleteRoleFormVisible: false,
      //日期选择数据
      startCreateTime: "",
      endCreateTime: "",
      //时间选择数据
      startUpdateTime: "",
      endUpdateTime: ""
    };
  },
  created() {
    var token = this.$store.state.userinfo.token;
    this.$api
      .getRoleList(
        {
          page: {
            current: 1,
            "size ": 10
          }
        },
        token
      )
      .then(res => {
        this.tableData = res.data.data.records;
        this.total = Number(res.data.data.total);
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    //编辑角色获取数据并且赋值本地
    handleEdit(index, row) {
      console.log(index, row);
      this.row = row;
      this.$router.replace("/editrole");
      console.log(this.row.roleName);
      console.log(row.id);
      Storage.setItem("editId", row.id);
    },
    //删除角色获取数据并且赋值本地
    handleDelete(index, row) {
      console.log(index, row);
      this.row = row;
      this.deleteRoleFormVisible = true;
      this.deleteName = this.row.roleName;
    },
    //删除角色请求函数
    deleteRoleHandler() {
      var token = this.$store.state.userinfo.token;
      this.$api.deleteRole([this.row.id], token).then(res => {
        this.deleteRoleFormVisible = false;
        if (res.data.code == 200) {
          this.$message.success("删除成功");
          for (var i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].id == this.row.id) {
              this.tableData.splice(i, 1);
            }
          }
        }
      });
    },
    //分页请求数据
    changeCurrent(id) {
      var token = this.$store.state.userinfo.token;
      console.log(id);
      this.$api
        .getRoleList(
          {
            page: {
              current: id,
              "size ": 10
            },
            query: {
              endCreateTime: this.endCreateTime,
              endUpdateTime: this.endUpdateTime,
              roleName: this.input,
              startCreateTime: this.startCreateTime,
              startUpdateTime: this.startUpdateTime
            }
          },
          token
        )
        .then(res => {
          console.log(res.data.data.records);
          this.tableData = res.data.data.records;
          this.total = Number(res.data.data.total);
        })
        .catch(err => {
          console.log(err);
        });
    },
    //日期点击确定函数
    getDataBlock() {
      console.log(this.value1);
      if (this.value1) {
        this.startCreateTime = this.value1[0];
        this.endCreateTime = this.value1[1];
      } else {
        this.startCreateTime = "";
        this.endCreateTime = "";
      }
    },
    //时间选择确定函数
    getTimeBlock() {
      // console.log(this.value2);
      if (this.value2) {
        this.startUpdateTime = this.value2[0];
        this.endUpdateTime = this.value2[1];
      } else {
        this.startUpdateTime = "";
        this.endUpdateTime = "";
      }
    },
    //查询函数
    checkHandler() {
      var token = this.$store.state.userinfo.token;
      this.$api
        .getRoleList(
          {
            page: {
              current: 1,
              "size ": 10
            },
            query: {
              endCreateTime: this.endCreateTime,
              endUpdateTime: this.endUpdateTime,
              roleName: this.input,
              startCreateTime: this.startCreateTime,
              startUpdateTime: this.startUpdateTime
            }
          },
          token
        )
        .then(res => {
          // console.log(res.data.data.records);
          this.tableData = res.data.data.records;
          this.total = Number(res.data.data.total);
        })
        .catch(err => {
          console.log(err);
        });
    },
    //添加角色启动对话框函数
    addRoleHandler() {
      this.$router.replace("/addRole");
    },
    //控制高级搜索显示函数
    playShow() {
      if (this.ifSearchMany == false) {
        this.ifSearchMany = true;
      } else {
        this.ifSearchMany = false;
      }
    },
    //清空查询条件请求函数
    emptyHandler() {
      (this.endCreateTime = ""),
        (this.endUpdateTime = ""),
        (this.input = ""),
        (this.startCreateTime = ""),
        (this.startUpdateTime = "");
      (this.value1 = ""), (this.value2 = "");
    },
    //input框回车事件
    search() {
      var token = this.$store.state.userinfo.token;
      this.$api
        .getRoleList(
          {
            page: {
              current: 1,
              "size ": 10
            },
            query: {
              roleName: this.input
            }
          },
          token
        )
        .then(res => {
          this.tableData = res.data.data.records;
          this.total = Number(res.data.data.total);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;

.select-input-timer {
  width: 100%;
  margin-left: 25px;
  margin-top: 20px;
}

.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;

  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;
  }

  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    border-radius: 4px;
  }

  // .bg-purple-dark {
  //   background: #99a9bf;
  // }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }

  .input-role {
    float: right;
    margin-top: 7px;
  }

  .roleTable {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .el-table td,
    .el-table th {
      padding: 0rem !important;
    }

    .el-table th.gutter {
      display: table-cell !important;
    }
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.outting
  .select
  .update-input-timer
  .update-input-timer-select[data-v-194502c2] {
  height: 30px;
  margin-top: 7px;
}

.outting
  .select
  .create-input-timer
  .create-input-timer-select[data-v-194502c2] {
  height: 30px;
  margin-top: 7px;
}

.el-date-editor .el-range-separator {
  padding: 0 5px;
  line-height: 25px;
  width: 5%;
  color: #303133;
}

.addRoleButton {
  margin-left: 15px;
}

.serachRoleButton {
  margin-left: 15px;
}

.el-date-editor .el-range__icon {
  font-size: 14px;
  margin-left: -5px;
  color: #c0c4cc;
  float: left;
  line-height: 32px;
  margin-bottom: 8px;
}

.searchBut {
  float: right;
  margin-left: 20px;
}

.emptyBut {
  float: right;
}

</style>
