<template>
  <div class="mainContainer">
        <div class="select">
      <span class="title">设备详情</span>
      <el-button type="text" size="small" icon="el-icon-back" @click="goBackHandler">返回设备列表</el-button>
    </div>
      <!-- <el-row class="main-content">
        <el-col :span="24">
          <div class="tab-nav">
            <div
              class="tab-item left"
              id="deviceInfo"
              ref="deviceInfo"
              @click="changeStyleAndView($event)"
            >设备信息</div>
            <div
              class="tab-item"
              id="deviceHistoryData"
              ref="deviceHistoryData"
              @click="changeStyleAndView($event)"
            >历史数据</div>
            <div
              class="tab-item right"
              id="managementRecords"
              ref="managementRecords"
              @click="changeStyleAndView($event)"
            >维修记录</div>
          </div>
        </el-col>
        <el-col :span="24">
          <router-view />
        </el-col>
      </el-row> -->
      <div class="main-content">
          <div class="tab-nav">
            <div
              class="tab-item left"
              id="deviceInfo"
              ref="deviceInfo"
              @click="changeStyleAndView($event)"
            >设备信息</div>
            <div
              class="tab-item"
              id="deviceHistoryData"
              ref="deviceHistoryData"
              @click="changeStyleAndView($event)"
            >历史数据</div>
            <!-- <div
              class="tab-item right"
              id="managementRecords"
              ref="managementRecords"
              @click="changeStyleAndView($event)"
            >维修记录</div> -->
          </div>
          <div style="width:100%;">
             <router-view />
          </div>
      </div>
 
  </div>
</template>


<script>
export default {
  name: "DeviceDetail",
  data() {
    return {
      itemIds: [
        "deviceInfo",
        "deviceHistoryData",
        // "deviceCommand",
        // "deviceAlarmList",
        "managementRecords"
      ]
    };
  },
  methods: {
    initCharts() {},
    goBackHandler(){
     this.$router.push('/deviceList')
    },
    changeStyleAndView(event) {
      var el = event.currentTarget;
      console.log("当前对象的内容：" + el.id);
      el.style.borderBottom = "3px solid #12b7f5";
      switch (el.id) {
        case "deviceInfo":
          this.$router.push("deviceInfo");
          break;
        case "deviceHistoryData":
          this.$router.push("deviceHistoryData");
          break;
        // case "deviceCommand":
        //   this.$router.push("deviceCommand");
        //   break;
        // case "deviceAlarmList":
        //   this.$router.replace("deviceAlarmList");
        //   break;
        // case "managementRecords":
        //   this.$router.push("managementRecords");
          break;
        default:
          break;
      }
      this.itemIds.forEach(item => {
        if (item !== el.id) {
          switch (item) {
            case "deviceInfo":
              this.$refs.deviceInfo.style.borderBottom = "none";
              break;
            case "deviceHistoryData":
              this.$refs.deviceHistoryData.style.borderBottom = "none";
              break;
            // case "deviceCommand":
            //   this.$refs.deviceCommand.style.borderBottom = "none";
            //   break;
            // case "deviceAlarmList":
            //   this.$refs.deviceAlarmList.style.borderBottom = "none";
            //   break;
            // case "managementRecords":
            //   this.$refs.managementRecords.style.borderBottom = "none";
              break;
            default:
              break;
          }
        }
      });
    }
  },
  mounted() {
    this.initCharts();
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.mainContainer {
  // .header {
  //   border-bottom: 1px solid #bebebe;
  //   line-height: 1rem;
  //   padding: 0.2rem 0;
  //   height: 1rem;
  // }

  // .platform-toolbar {
  //   position: relative;
  //   margin: 0;
  //   border: 1px solid #d9d9d9;

  //   .title-info {
  //     height: 46px;
  //     line-height: 46px;
  //     font-weight: bold;
  //     font-size: 0.8rem;
  //   }

  //   .el-col {
  //     padding: 0 3rem;

  //     .toolbar-right {
  //       float: right;
  //       margin-top: 7px;

  //       &.export {
  //         margin-left: 1rem;

  //         .toolbar-btn {
  //           color: @toolBarFontColor;
  //           font-size: 0.9rem;

  //           &.left {
  //             margin-left: 1.5rem;
  //           }

  //           &.right {
  //             margin-right: 0;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .title-info {
  //     float: left;
  //   }
  // }

  .main-content {
    padding: 1rem 0 1rem 0;

    .tab-nav {
      //background: red;
      border-bottom: 1px solid #d9d9d9;

      .tab-item {
        cursor: pointer;
        display: inline-block;
        padding: 0 1.5rem;
        line-height: 2rem;
        font-weight: bold;
        font-size: small;

        &.left {
          margin-left: 3rem;
          border-bottom: 3px solid #4faaf0;
        }

        &.right {
          margin-right: 3rem;
        }

        &:hover {
          color: #4faaf0;
        }
      }
    }
  }
}
 .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #cccccc;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }
      button {
      color: @toolBarFontColor;
      font-size: 0.8rem;
      float: right;
      margin-top: 7px;
    }
 }

</style>
