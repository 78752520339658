<template>
  <div class="mainContainer">
    <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
      <span>确定删除该卡号信息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDelClose">确 定</el-button>
      </span>
    </el-dialog>

    <div class="select">
      <span class="title">物联网卡号列表</span>
      <el-button
        type="text"
        size="small"
        icon="el-icon-refresh-right"
        class="select-button"
        @click="refresh"
      >同步</el-button>
      <el-upload  class="select-button" action="#" :on-change="handleChange"
                 :on-remove="handleRemove" :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList"
                 :limit="limitUpload" :auto-upload="false" ref="my-upload">
        <el-button type="text" size="small" icon="el-icon-folder-opened" class="select-button" >
          批量导入</el-button>
      </el-upload>
      <div class="select-input-right-input">
        <el-input placeholder="请输入ICCID" v-model="imei" size="small" @keyup.13.native="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </div>
    </div>

    <div class="table-vv">
<!--      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">-->
<!--        <div class="result-info">-->
<!--          共有-->
<!--          <span>{{ total }}</span>台设备-->
<!--        </div>-->
<!--      </div>-->
      <el-table
        :data="tableData"
        stripe
        size="small"
        class="device-table"
        :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column v-if="checkedImei" prop="iccid" label="ICCID" align="center"></el-table-column>

        <el-table-column v-if="checkedProduct" prop="accessNumber" label="卡号" align="center"></el-table-column>

        <el-table-column v-if="checkedTime" prop="createTime" label="创建时间" align="center"></el-table-column>

<!--        <el-table-column v-if="checkedDelDet" fixed="right" label="操作" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button-->
<!--              @click="deleteDevice(scope.row.mac,scope.row.netOperator)"-->
<!--              type="text"-->
<!--              v-show="allowDelete"-->
<!--              size="small"-->
<!--            >删除</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>

    </div>

  </div>
</template>


<script>


export default {
  data() {
    return {

      allowDelete: true,
      imei: "",
      visible: false,
      checkedRegistStatus: true,
      macStr: "",
      checkedProduct: true,
      checkedOpera: true,
      checkedTime: true,
      checkedOnline: true,
      checkedDelDet: true,
      checkedImei: true,
      delDialogVisible: false,
      tableData: [],
      //新增设备弹出对话框标记
      dialogVisible: false,
      options: [],
      limitUpload: 1,
      value: "",
      input: "",
      fileList: [],
      total: 0
    };
  },
  methods: {
    handleChange(file, fileList) {
      this.fileTemp = file.raw;
      if (this.fileTemp) {
        let formData = new FormData();
        formData.append("file", this.fileTemp);
        this.$api.teleCardNumImport(formData).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "导入成功"
            });

          }
        });
        this.$refs['my-upload'].clearFiles()
        this.fileList = []
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！"
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },
    //移除文件的操作方法
    handleRemove(file, fileList) {
      this.fileTemp = null;

    },
    deleteDevice(macStr, netOpera) {
      this.delDialogVisible = true;
      this.macStr = macStr;
      this.netOperatorId = netOpera;
    },
    handleDelClose() {
      this.delDialogVisible = false;
      this.$api
        .deleteIotDevice({
          macStr: this.macStr,
          netOperatorId: this.netOperatorId
        })
        .then(res => {
          if (res.data.code == "200") {
            this.$message({
              message: "删除成功",
              type: "success"
            });
            this.macStr = "";
            this.netOperatorId = "";
            this.getRegisterList();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    search() {

      this.getRegisterList();
    },


    refresh() {
      this.$api
          .voiceCardInfoUpdate()
          .then(res => {

          })
          .catch(error => {});
    },
  getRegisterList() {
      //查询设备列表
      this.$api
        .getVoiceCardInfo(this.imei)
        .then(res => {
            this.tableData = [res.data.data]
        })
        .catch(error => {});
    }
  },
  created() {
  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.device-content {
  height: 6rem;

  .device-info-item {
    float: left;
    margin-right: 3rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 12rem;
    }
  }
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15 /16rem;
  }
  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
  .data-setting {
    font-size: 0.875rem;
    color: #2673bb;
    float: right;
    line-height: 1rem;

    i {
      padding-right: 0.2rem;
    }

    cursor: pointer;
  }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}
</style>
