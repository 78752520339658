<template>
    <div class="mainContainer">
        <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
            <span>确定删除吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelDelete">取 消</el-button>
                <el-button type="primary" @click="handleDelClose">确 定</el-button>
            </span>
        </el-dialog>
        <div class="select">
            <span class="title">产品指标管理</span>
            <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新
            </el-button>
            <el-button type="text" size="small" icon="el-icon-plus" class="select-button" @click="showAddProductDialog">
                新增</el-button>
        </div>
        <div class="table-vv">
            <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
            </div>
            <el-table :data="tableData" stripe size="small" class="product-table"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="productName" label="产品名称" align="center"></el-table-column>
               
                <el-table-column prop="dataPointKey" label="数据点key值" align="center"></el-table-column>
                <el-table-column prop="dateRange" label="数据点的统计单位" align="center">
                        <template slot-scope="scope">
                      
                        <span >{{scope.row.dateRange  == '0'?"天":"实时"}}</span>
                    </template>
                </el-table-column>

          
                <!-- <el-table-column prop="upgradeMethod" label="升级方式" align="center">
                    <template slot-scope="scope">
                        <el-select style="width:105px" v-model="scope.row.upgradeMethod" v-show="scope.row.iseditor"
                            size="mini" placeholder="请选择">
                            <el-option label="主动" value="0"></el-option>
                            <el-option label="手动" value="1"></el-option>
                        </el-select>
                        <span v-show="!scope.row.iseditor">{{scope.row.upgradeMethod  == '0'?"主动":"手动"}}</span>
                    </template>
                </el-table-column> -->
        <el-table-column prop="avgLevel" label="产品的数据点平均值" align="center"> 
             <template slot-scope="scope">
                       <el-input  v-model="scope.row.avgLevel" v-show="scope.row.iseditor" width='200' size="mini"></el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.avgLevel}}</span>
                    </template>
        </el-table-column>
                <el-table-column prop="maxLevel" label="正常标准的最高值" align="center" >
                 <template slot-scope="scope">
                       <el-input  v-model="scope.row.maxLevel" v-show="scope.row.iseditor" width='200' size="mini"></el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.maxLevel}}</span>
                    </template></el-table-column>
                <el-table-column prop="minLevel" label="正常标准的最低值" align="center">
                        <template slot-scope="scope">
                       <el-input  v-model="scope.row.minLevel" v-show="scope.row.iseditor" width='200' size="mini"></el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.minLevel}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="createTime" label="创建时间" :formatter="dateFormat" align="center">
                </el-table-column>
                    <el-table-column prop="updateTime" label="更新时间" :formatter="dateFormat" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <el-button @click="save(scope.row)" v-show="scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">修改
                        </el-button>
                        <el-button @click="edit(scope.row,scope)" v-show="!scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">编辑
                        </el-button>
                        <el-button @click="deleteProductD(scope.row.id)" type="text" size="small" icon="el-icon-delete">
                            删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background :page-sizes="[10, 20]" :page-size="pageSize" :total="total"
                layout="sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handlePageChange">
            </el-pagination>
        </div>
        <el-dialog title="产品指标管理新增" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="产品名称" :label-width="formLabelWidth" prop="productId">
                    <el-select style="width:315px" v-model="form.productId" placeholder="请选择产品名称">
                        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据点key值" :label-width="formLabelWidth" prop="dataPointKey">
                    <el-input style="width:315px" v-model="form.dataPointKey" autocomplete="off"
                        placeholder="请填写数据点key值">
                    </el-input>
                </el-form-item>
                <el-form-item label="数据点的统计单位" :label-width="formLabelWidth" prop="dateRange">
                    <el-select style="width:315px" v-model="form.dateRange" placeholder="请选择数据点的统计单位">
                        <el-option label="天" value="0"></el-option>
                        <el-option label="实时" value="1"></el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="产品的数据点平均值" :label-width="formLabelWidth" prop="avgLevel">
                    <el-input style="width:315px" v-model="form.avgLevel" autocomplete="off" placeholder="请填写产品的数据点平均值">
                    </el-input>
                </el-form-item>
                <el-form-item label="正常标准的最高值" :label-width="formLabelWidth" prop="maxLevel">
                    <el-input style="width:315px" v-model="form.maxLevel" autocomplete="off" placeholder="请填写正常标准的最高值">
                    </el-input>
                </el-form-item>
                <el-form-item label="正常标准的最低值" :label-width="formLabelWidth" prop="minLevel">
                    <el-input style="width:315px" v-model="form.minLevel" autocomplete="off" placeholder="请填写正常标准的最低值">
                    </el-input>
                </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('form')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    export default {
        name: "programVersion",
        data() {
            return {
                productDel: false,
                options: [],
                productAdd: false,
                iseditor: false,
                limitUpload: 1,
                form: {
                    productId: "",
                    avgLevel: "",
                    dataPointKey: "",
                    dateRange: "",
                    maxLevel: "",
                    minLevel: "",
                },
                rules: {
                    productId: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }, ],
                    avgLevel: [{
                        required: true,
                        message: "请选择",
                        trigger: "change"
                    }, ],
                    dataPointKey: [{
                        required: true,
                        message: "请选择",
                        trigger: "blur"
                    }, ],
                    dateRange: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],
                    maxLevel: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],
                    minLevel: [{
                        required: true,
                        message: "请输入",
                        trigger: "blur"
                    }, ],

                },
                visible: false,
                total: 0,
                tableData: [],
                fileList: [],
                chooseOnlineStatus: "", //搜索选择
                sysUserlist: [], //账号所属类型

                checked: false,
                //新增账号弹出对话框标记
                dialogVisible: false,
                //修改账号弹出对话框标记
                modifydialogVisible: false,

                //产品品类列表
                categories: [],

                value: "",
                input: "",
                current: 1,
                pageSize: 10,
                delDialogVisible: false,
                productId: "",
                indexId: "", //反显id
                nameinput: "", //搜索输入
                indexIdlist: "", //反显列表
                formLabelWidth: "150px",

            };
        },
        methods: {
            handleChange(file, fileList) {
                this.fileTemp = file.raw;
                this.form.fileList = fileList;
                if (this.fileTemp) {
                    console.log(this.fileTemp)
                } else {
                    this.$message({
                        type: "warning",
                        message: "请上传附件！"
                    });
                }
            },
            //超出最大上传文件数量时的处理方法
            handleExceed(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },
            //移除文件的操作方法
            handleRemove(file, fileList) {
                this.fileTemp = null;

            },

            refresh() {
                this.questionStatus();
            },
            dateFormat(row, column, cellValue, index) {
                // console.log(row, column, cellValue, index)
                const daterc = row[column.property]
                if (daterc != null) {
                    var date = new Date(daterc);
                    var year = date.getFullYear();
                    /* 在日期格式中，月份是从0开始，11结束，因此要加0
                     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                     * */
                    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
                    // 拼接
                    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
                }
            },


            handlePageChange(val) {

                this.current = val;
                this.questionStatus();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.questionStatus();
            },


            deleteProductD(data) {
                this.delDialogVisible = true;
                this.productId = data;
            },
            cancelDelete() {
                this.delDialogVisible = false;
                this.productId = "";
            },
            handleDelClose() {
                this.delDialogVisible = false;
                this.deleteProduct(this.productId);
            },
            // 修改
            edit(row, index) {
                row.iseditor = true
            },
            save(item) {
              
                this.$api
                    .productPointUpdate({
                        avgLevel: item.avgLevel,
                        minLevel: item.minLevel,
                        maxLevel: item.maxLevel,
                        id: item.id
                    })
                    .then(res => {
                        if (res.data.code == 200) {

                            this.$message.success("修改成功");
                            this.questionStatus();
                            this.iseditor = false
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            deleteProduct(data) {

                this.$api
                    .productPointDelete({
                        id:data
                    })
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功!");
                            this.questionStatus();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },

            questionStatus() {
                //查询列表
                this.$api
                    .productPointList({
                        current: this.current,
                        size: 10
                    })
                    .then(res => {
                        console.log(res.data.data.records)
                        for (let index = 0; index < res.data.data.records.length; index++) {
                            res.data.data.records[index].iseditor = false
                        }
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;


                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            showAddProductDialog() {
                this.dialogVisible = true;
                //请求后台接口，获取产品列表
                this.$api
                    .productListForPullUrl({
                        page: {
                            current: 1,
                            size: 1000
                        }
                    })
                    .then(res => {
                        this.options = res.data.data;
                    })
                    .catch(error => {});
                this.form.productId = "";
                this.form.avgLevel = "";
                this.form.dataPointKey = "";
                this.form.dateRange = "";
                this.form.maxLevel = "";
                this.form.minLevel = "";
                this.$nextTick(() => {
                    this.$refs["form"].clearValidate();
                });
            },
            // 新增程序接口
            submitForm(formName) {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        this.dialogVisible = false;
                        this.$api.productPointAdd({
                      
                                    productId: this.form.productId,
                                    avgLevel: this.form.avgLevel,
                                    dataPointKey: this.form.dataPointKey,
                                    dateRange: this.form.dateRange,
                                    maxLevel: this.form.maxLevel,
                                    minLevel: this.form.minLevel,
                                
                            }

                        ).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("添加成功!");
                                this.questionStatus();
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },

            // 账号列表新增平台账号下拉框接口
            showaccountListSelector() {}
        },
        created() {
            //查询产品列表
            this.questionStatus();
            this.showaccountListSelector();
        }
    };
</script>
<style lang="less" scoped>
    @toolBarFontColor: rgba(17, 17, 17, 0.87);

    .el-row {
        margin: 1rem 3rem;
        line-height: 0;
        min-width: 900px;

        .platform-toolbar {
            position: relative;
            margin: 0;
            border: 1px solid #d9d9d9;

            .title-info {
                height: 46px;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .el-col {
                padding: 0 3rem;

                .toolbar-right {
                    float: right;
                    margin-top: 7px;

                    &.export {
                        margin-left: 1rem;

                        .toolbar-btn {
                            color: @toolBarFontColor;
                            font-size: 0.9rem;

                            &.left {
                                margin-left: 1.5rem;
                            }

                            &.right {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .title-info {
                float: left;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        .result-info-wrapper {
            height: 1rem;
            margin: 1rem 0 0.5rem 0;
            position: relative;

            .setting-panel {
                position: absolute;
                float: right;
                top: 2rem;
                width: 200px;
                height: 200px;
                background: red;
                z-index: 1000;
                border-radius: 3px;
            }

            .result-info {
                height: 1rem;
                line-height: 1rem;
                font-size: 0.875rem;
                float: left;
                color: #898989;

                span {
                    color: #292929;
                    padding: 0 0.1rem;
                }
            }

            .data-setting {
                font-size: 0.875rem;
                color: #2673bb;
                float: right;

                i {
                    padding-right: 0.2rem;
                }

                cursor: pointer;
            }
        }

        .el-table td,
        .el-table th {
            padding: 0 !important;
        }

        .el-pagination {
            margin: 1rem 0;
            float: right;
        }
    }

    .product-content {
        height: 6rem;

        .product-info-item {
            float: left;
            margin-right: 4rem;

            .label-info {
                margin-bottom: 0.5rem;

                label {
                    font-size: 0.75rem;

                    span {
                        color: red;
                        margin-right: 0.5rem;
                    }
                }
            }

            .el-input {
                width: 10rem;
            }

            .el-select {
                width: 13rem;
            }
        }
    }

    .container-el-row {
        margin-top: 0px;
    }

    .select {
        height: 46px;
        overflow: hidden;
        border-bottom: 1px solid #d9d9d9;
        padding: 0rem 3rem;

        .title {
            display: inline-block;
            line-height: 46px;
            font-weight: bold;
            font-size: 0.8rem;
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            margin-top: 7px;
            margin-left: 15px;
        }

        .select-input-right-input {
            float: right;
            margin-top: 7px;
        }
    }

    .table-vv {
        padding: 0rem 3rem;
        margin-top: 1rem;

        .result-info {
            height: 1rem;
            line-height: 1rem;
            font-size: 0.875rem;
            float: left;
            color: #898989;

            span {
                color: #292929;
                padding: 0 0.1rem;
            }
        }

        .el-pagination {
            margin: 1rem 0;
            float: right;
        }
    }
</style>