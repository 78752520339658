<template>
  <div class="outting">
    <div class="select">
      <span class="title">积分补赠</span>
      <el-button type="text" size="small" icon="el-icon-plus" @click="addPointsHandler" class="addRoleButton">单个补赠
      </el-button>
<!--      <el-input style="width:200px;margin-top:6px" v-model="mobile" class="select-button" prefix-icon="el-icon-search"-->
<!--                placeholder="请输入用户手机号/账号" size="small" @keyup.enter.native="appList"></el-input>-->
      <el-upload style="display:inline" class="select-button" action :on-change="handleChange"
                 :on-remove="handleRemove" :on-exceed="handleExceed" accept=".xls, .xlsx" :file-list="fileList"
                 :limit="limitUpload" :auto-upload="false" ref="upload">
        <el-button type="info" round size="mini" style="margin-right: 100px">批量补赠</el-button>
      </el-upload>
    </div>
    <div class="table-vv">
      <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">



        <el-table-column prop="mobile" label="补赠用户" min-width="2" fixed align="center"></el-table-column>
        <el-table-column prop="points" label="补赠积分" min-width="2" fixed align="center"></el-table-column>
        <el-table-column prop="createTime" label="补赠时间" min-width="2" fixed align="center"></el-table-column>




      </el-table>
      <el-pagination background layout="prev, pager, next" :total="total" class="pagin"
                     @current-change="changeCurrent"></el-pagination>
    </div>
    <el-dialog title="单个补赠
" :visible.sync="addDialogFormVisible" :close-on-click-modal="false" width="70%">
      <el-form :model="addForm" size="small" :rules="addRules" ref="addRules" enctype="multipart/form-data">


        <el-form-item label="用户手机/账号" :label-width="formLabelWidth" prop="questionContent">
          <el-input v-model="addForm.mobile" autocomplete="off" style="width:315px" type="number"
                    :rows="2">
          </el-input>
        </el-form-item>

        <el-form-item label="补赠积分数量" :label-width="formLabelWidth" prop="answer">
          <el-input v-model="addForm.points" autocomplete="off" style="width:315px" type="number" :rows="2">
          </el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="addAppListForm('addForm')" size="small">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
export default {
  name: "answerList",
  data() {

    return {


      current: 1,
      fileList: [],
      limitUpload: 1,
      size: 10,
      total: 0,
      tableData: [],
      file: {},
      editFile: {},
      addDialogFormVisible: false,
      mobile: "",
      addForm: {
        points: "",
        mobile: "",

      },


      addRules: {

        mobile: [{
          required: true,
          message: "请输入用户手机号/账号",
          trigger: "blur"
        },

        ],

        points: [{
          required: true,
          message: "请输入补赠积分数量",
          trigger: "blur"
        }],

      },

      formLabelWidth: "180px",

    };
  },
  created() {
    this.appList();
  },
  methods: {



    //重置函数
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    appList() {
      this.$api
          .addPointsList({
            page: {
              current: this.current,
              size: 10,
            },

          })
          .then(res => {
            // console.log(res.data);
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          });
    },
    addPointsHandler() {
      this.file = {};

          this.addDialogFormVisible = true;

      if (this.$refs["addRules"] !== undefined) {
        this.resetForm("addRules");
      }
    },


    changeCurrent(id) {
      this.current = id;
      this.appList();
    },


    addAppListForm() {
      this.$refs["addRules"].validate(valid => {
        if (valid) {
          this.addDialogFormVisible = false;
          this.$api.singleAddPoints(this.addForm).then(res => {

            if (res.data.code == 200) {
              this.$message.success("积分补赠成功！");
              this.file = {};
              this.appList();
            }
          });

        } else {
          return false;
        }
      });
    },

    editHandleChange(file) {
      console.log(file);
      this.editFile = file.raw;
    },
     handleChange(file) {
      this.fileTemp = file.raw;
      if (this.fileTemp) {
        let formData = new FormData();
        formData.append("multipartFile", this.fileTemp);
        this.$api.addPoints(formData).then(res => {
          this.$refs.upload.clearFiles();
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "导入成功"
            });
            this.appList();
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件！"
        });
      }
    },
    //超出最大上传文件数量时的处理方法
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
      });
      return;
    },
    //移除文件的操作方法
    handleRemove(file) {
      this.fileTemp = null;

    },



  }
};
</script>
<style lang="less" scoped>
@border-bottom-color: #e7eaed;
@toolBarFontColor: #111d;

.outting {
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;

  .new_add {
    width: 100%;
  }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 3px;
    margin-left: 15px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .select-status {
      width: 9rem;
      margin-left: 1.7rem;
      margin-top: 7px;
      // margin-top:7px;
    }

    .select-input-timer {
      float: right;
    }

    .select-status {
      float: right;
    }

    .select-input-right-input {
      float: right;
      // width: 8rem;
      line-height: 46px;
    }

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .addRoleButton {
      margin-left: 15px;
    }

    button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
    }

    .update-input-timer {
      float: right;
      line-height: 46px;
      margin-left: 25px;
      height: 46px;

      .demonstrationTwo {
        float: left;
        font-size: 0.8rem;
      }

      .update-input-timer-select {
        width: 11rem;
        margin-left: 5px;
      }
    }
  }

  .table-vv {
    padding: 1rem 3rem;

    .pagin {
      margin-left: 30%;
      margin-top: 10px;
      float: right;
    }
  }
}
</style>
