<template>
    <div class="outting">
        <div class="select">
            <span class="title">分配配置列表</span>
<!--            <el-button type="text" size="small" icon="el-icon-plus" @click="addUserHandler" class="addRoleButton">创建分享类型-->
<!--            </el-button>-->
            <el-button type="text" size="small" icon="el-icon-plus" @click="addUserHandler" class="addRoleButton">创建分享
            </el-button>

        </div>
        <div class="table-vv">
            <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">
                    <el-table-column prop="shareTitle" label="分享标题 " min-width="2" fixed align="center"> </el-table-column>
<!--                <el-table-column prop="sharePicUrl" label="分享主图" min-width="2" fixed align="center"></el-table-column>-->
              <el-table-column prop="sharePicUrl" label="分享主图" min-width="2" fixed align="center">
                <template slot-scope="scope">
                  <el-popover placement="top-start" title trigger="hover">
                    <el-image :src="scope.row.sharePicUrl" :fit="fit" style="width:160px;height:160px"
                              referrerpolicy="no-referrer"></el-image>
                    <el-image slot="reference" :src="scope.row.sharePicUrl" style="width: 50px;height: 50px"
                              :fit="fit" referrerpolicy="no-referrer"></el-image>
                  </el-popover>
                </template>
              </el-table-column>
<!--                <el-table-column prop="clickUrl" label="跳转路径" min-width="2" fixed align="center"></el-table-column>-->
                <el-table-column prop="shareChannel" label="分享渠道" min-width="2" fixed align="center">

                  <template
                      slot-scope="{row}">{{ row.shareChannel  == 0?'微信好友': '微信朋友圈' }}</template>
                </el-table-column>
              <el-table-column prop="category" label="分享所属页面" min-width="2" fixed align="center">
<!--                <template slot-scope="{row}">{{ row.category  == 1?'签到': row.category  == 2?'答题' : row.category  == 3? '兑换' :row.category  == 4?"抽奖" : "个人中心"}}</template>-->
              </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center"></el-table-column>
                <el-table-column min-width="1" label="操作" width="180" align="center">
                    <template slot-scope="scope">
<!--                           <el-button type="text" size="small" icon="el-icon-circle-plus-outline"-->
<!--                                @click="addprizes(scope.$index, scope.row)" style="margin-left:10px;"-->
<!--                                slot="reference">编辑</el-button>-->
                        <el-popconfirm title="确定删除这个分享配置吗？" @onConfirm="deleteAddHandler()">
                            <el-button type="text" size="small" icon="el-icon-delete"
                                @click="handleDelete(scope.$index, scope.row)" style="margin-left:10px;"
                                slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>

        </div>
        <el-dialog title="创建" :visible.sync="addDialogFormVisible" :close-on-click-modal="false">
            <el-form :model="addForm" size="small"  style="50%" :rules="addRules" ref="addRules"
                enctype="multipart/form-data">
                   <el-form-item label="分享标题" :label-width="formLabelWidth" prop="activityName">
                    <el-input v-model="addForm.shareTitle" autocomplete="off" style="width:315px" >
                    </el-input>
                </el-form-item>
<!--              <el-form-item label="跳转路径" :label-width="formLabelWidth" prop="activityName">-->
<!--                <el-input v-model="addForm.shareTitle" autocomplete="off" style="width:315px" >-->
<!--                </el-input>-->
<!--              </el-form-item>-->
                <el-form-item label="分享渠道" :label-width="formLabelWidth" prop="activityType">
                    <el-select v-model="addForm.shareChannel " placeholder="请选活动类型">
                        <el-option label="分享好友" value="0"></el-option>
                        <el-option label="分享朋友圈" value="1"></el-option>
                    </el-select>
                </el-form-item>
              <el-form-item label="分享所属页面" :label-width="formLabelWidth" prop="activityType">
                <el-select v-model="addForm.categoryId " placeholder="请选活动类型">
                  <el-option label="签到" value="1"></el-option>
                  <el-option label="全民学消防" value="2"></el-option>
                  <el-option label="积分兑换" value="3"></el-option>
                  <el-option label="积分抽奖" value="4"></el-option>
                  <el-option label="个人中心" value="5"></el-option>
                </el-select>
              </el-form-item>

<!--                <el-form-item label="分享主图" :label-width="formLabelWidth" prop="activityDesc">-->
<!--                    <el-input v-model="addForm.sharePicUrl" autocomplete="off" style="width:315px" type="textarea" :rows="2">-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
              <el-form-item label="分享主图" :label-width="formLabelWidth" ref="formImg" prop="sharePicUrl">
                <el-upload action="#" :on-change="handleChange" list-type="picture-card" v-show="!value"
                           :show-file-list="false">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
                <div v-show="value" class="el-upload-list el-upload-list--picture-card">
                  <div class="el-upload-list__item is-success">
                    <img class="avatar" v-show="value" :src="value" />
                    <label class="el-upload-list__item-status-label">
                      <i class="el-icon-upload-success el-icon-check"></i>
                    </label>
                    <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview">
                                    <i class="el-icon-zoom-in" @click.stop="handlePictureCardPreview"></i>
                                </span>
                                <span class="el-upload-list__item-delete">
                                    <i class="el-icon-delete" @click.stop="handleRemove"></i>
                                </span>
                            </span>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addAppListForm('addForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
    export default {
        name: "AppList",
        data() {

            return {


                tableData: [],
                deleteId: "",
                editId: "",
                addDialogFormVisible: false,
                dialogVisible: false,
                dialogImageUrl: "",
                value: "",
                file: {},
                addForm: {
                  categoryId: "",
                  shareTitle: "",
                  clickUrl: "",
                  shareChannel: "",
                  sharePicUrl: {},

                },
                //
                // addRules: {
                //     activityName: [{
                //         required: true,
                //         message: "请输入活动名称",
                //         trigger: "blur"
                //     }],
                //     activityType: [{
                //             required: true,
                //             message: "选择活动类型",
                //             trigger: "change"
                //         }
                //     ],
                //
                // },
                formLabelWidth: "180px",
            };
        },
        created() {
            this.appList();
        },
        methods: {


          handleChange(file, fileList) {

            this.value = file.url;
            this.file = file.raw;
            if (file.raw) {
              this.$nextTick(() => { //等待照片上传完成，
                this.$refs['formImg'].clearValidate()
              })

            }
          },
          handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
          },
          handleRemove() {

            this.value = "";
            this.file = {};
          },
            addprizes(index,data){
      //  this.$router.push({
      //   path: `/activityPrizes`,
      //   query: {
      //       data: data
      //     }
      // });
            },
            //删除函数
            handleDelete(index, row) {
                this.deleteId = row.id;
            },
            //删除确定
            deleteAddHandler() {
                this.$api.shareDelete(this.deleteId).then(res => {
                    console.log(res.data);
                    if (res.data.code == 200) {
                        this.$message.success("删除成功!");
                        this.appList();
                    }
                });
            },



            appList() {
                this.$api
                    .shareList({

                    })
                    .then(res => {
                        // console.log(res.data);
                        this.tableData = res.data.data.records;

                    });
            },
            addUserHandler() {
                this.file = {};
                this.addDialogFormVisible = true;
            },
      deleteFn(item) {

        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i] == item) {
            this.items.splice(i, 1)
          }
        }
      },



            addAppListForm() {


                        this.addDialogFormVisible = false;
                      this.addForm.sharePicUrl = this.file
                      let formData = new FormData();


                      formData.append("categoryId", this.addForm.categoryId);
                      formData.append("shareTitle", this.addForm.shareTitle);
                      formData.append("clickUrl", this.addForm.clickUrl);
                      formData.append("shareChannel", this.addForm.shareChannel);
                      formData.append("file", this.addForm.sharePicUrl);

                        this.$api.shareAdd(formData).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("创建分享成功！");
                                this.file = {};
                                this.value = ''
                                this.appList();
                            }
                        });


            },





        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;
      .new_add{
          width: 100%;
      }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 3px;
    margin-left: 15px;
  }
        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .select-status {
                width: 9rem;
                margin-left: 1.7rem;
                margin-top: 7px;
                // margin-top:7px;
            }

            .select-input-timer {
                float: right;
            }

            .select-status {
                float: right;
            }

            .select-input-right-input {
                float: right;
                // width: 8rem;
                line-height: 46px;
            }

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .addRoleButton {
                margin-left: 15px;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }

            .update-input-timer {
                float: right;
                line-height: 46px;
                margin-left: 25px;
                height: 46px;

                .demonstrationTwo {
                    float: left;
                    font-size: 0.8rem;
                }

                .update-input-timer-select {
                    width: 11rem;
                    margin-left: 5px;
                }
            }
        }

        .table-vv {
            padding: 1rem 3rem;

            .pagin {
                margin-left: 30%;
                margin-top: 10px;
                float: right;
            }
        }
    }
</style>
