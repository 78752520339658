import { render, staticRenderFns } from "./categoryGoodsList.vue?vue&type=template&id=a44619aa&scoped=true&"
import script from "./categoryGoodsList.vue?vue&type=script&lang=js&"
export * from "./categoryGoodsList.vue?vue&type=script&lang=js&"
import style0 from "./categoryGoodsList.vue?vue&type=style&index=0&id=a44619aa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a44619aa",
  null
  
)

export default component.exports