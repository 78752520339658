<template>

    <div class="mainContainer">
        <div class="select">
            <span class="title">卡充值管理</span>
            <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新
            </el-button>
        </div>
        <div class="gaojiSearch">
            <div style="width:100%;overflow: hidden;">
                <div style="float:left">
                    <div class="toolbar-right">
                        <h5>卡充值规则：</h5>
                        <span style="font-size:12px;margin-bottom:20px" class="toolbarTit">所属运营商 ：</span>
                        <el-select v-model="netOperatorName" size="mini" clearable
                            style="display:inline-block;width: 12rem;;margin-right:1rem">
                            <el-option v-for="item in netOperator " :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span style="font-size:12px;margin-bottom:20px" class="toolbarTit">价格 ：</span>
                        <el-input style="width:8rem;margin-bottom:1rem;margin-right:1rem" size="mini"
                            v-model="modelValue" type="text" min="0" :change="checkValue()">
                        </el-input>
                        <span style="font-size:12px;margin-bottom:20px" class="toolbarTit">时间(年) ：</span>
                        <el-input type="text" oninput="value=value.replace(/[^\d]/g,'')" size="mini" clearable
                            style="width:5rem;margin-bottom:1rem;margin-right:1rem" v-model="rechargeDuration">
                        </el-input>
                        <br />
                    </div>
                </div>
            </div>
            <div style="width:100%;overflow: hidden;">
                <div style="float:right;">
                    <div class="grid-content bg-purple-dark">
                        <div class="searchBut">
                            <el-button size="mini" type="primary" @click="addFn()">确认</el-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-vv">
            <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
                <h5>卡充值规则列表：</h5>
            </div>
            <el-table :data="tableData" stripe size="small" class="product-table"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <el-table-column type="selection" align="center" width="55"></el-table-column>
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="netOperator" label="运营商" align="center">
                    <template slot-scope="scope">
                        <span>
                            {{scope.row.netOperator == 0 ?'移动':scope.row.netOperator == 1?'电信':scope.row.netOperator == 2?'联通':""}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="价格" align="center">
                    <template slot-scope="scope">
                        <el-input style="width:5rem" type="text" v-model="scope.row.price" v-show="scope.row.iseditor"
                            size="mini"> </el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.price}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="rechargeDuration" label="年" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <el-button @click="save(scope.row)" v-show="scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">修改
                        </el-button>
                        <el-button @click="edit(scope.row,scope)" v-show="!scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">编辑
                        </el-button>
                        <el-button @click="orderDelete(scope.row.id)" type="text" size="small"
                            icon="el-icon-s-promotion">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>





            <!-- 删除 -->
            <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
                <span>确定删除该规则吗,删除后对应的数据点信息也会被删除？</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click=" delDialogVisible=  false">取 消</el-button>
                    <el-button type="primary" @click="handleDelClose">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>


<script>
    // import Bus from "../../../components/bus";
    export default {
        name: "deviceManagementList",
        data() {
            return {
                total: 0,
                tableData: [],
                iseditor: false,
                delDialogVisible: false,
                current: 1,
                pageSize: 10,
                rechargeDuration: "",
                modelValue: "",
                idDel:"",
                netOperatorName: "",
                netOperator: [{
                        value: "0",
                        label: "移动"
                    },
                    {
                        value: "1",
                        label: "电信"
                    }, {
                        value: "2",
                        label: "联调"
                    },
                ]
            };
        },
        methods: {
            edit(row, index) {
               row.iseditor = true
            },
            save(item) {
                this.$api
                    .cardRechargeRulesUpdate({
                        price: item.price,
                        id:item.id
                    })
                    .then(res => {
                        if (res.data.code == 200) {

                            this.$message.success("修改规则成功");
                            this.nodeList();
                               item.iseditor = false
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            addFn() {
                if(this.netOperatorName == '' ||this.rechargeDuration == ''||this.modelValue == '' ){
                      this.$message.error("请填写所有信息!");
                         return
                }
                this.$api
                    .cardRechargeRulesAdd({
                        netOperator: this.netOperatorName,
                        price: this.modelValue,
                        rechargeDuration: this.rechargeDuration,
                    })
                    .then(res => {
                        if (res.data.code == 200) {

                            this.$message.success("新增规则成功");
                            this.nodeList();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            checkValue() {
                var value = '' + this.modelValue.toString();
                value = value
                    .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
                    .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
                    .replace(/^\./g, '') //保证第一个为数字而不是.
                    .replace('.', '$#$')
                    .replace(/\./g, '')
                    .replace('$#$', '.')
                    .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
                if (value.indexOf('.') < 0 && value != '') {
                    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                    value = parseFloat(value);
                }
                this.modelValue = value;
            },
            refresh() {
                this.nodeList();
            },


            handlePageChange(val) {
                this.current = val;
                this.nodeList();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.nodeList();
            },

            orderDelete(data) {
                this.delDialogVisible = true;
                this.idDel = data;
            },
            handleDelClose() {
                this.$api
                    .cardRechargeRulesDelete(this.idDel)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.delDialogVisible = false
                            this.$message.success("删除规则成功!");
                            this.nodeList();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },

            handleClose(done) {
                done();
                this.value = "";
            },


            // 导出
            goodsExport() {
                this.$api
                    .mqttDeviceExport({
                        query: {
                            deviceName: "",
                            productName: "",
                            ids: this.ids,
                        },
                        properties: [
                            "activationTime",
                            "deviceName",
                            "id",
                            "productName",
                            "createTime",
                        ],
                        titles: [
                            "激活时间",
                            "设备名称",
                            "主键id",
                            "产品名称",
                            "创建时间",

                        ]
                    })
                    .then(res => {
                        console.log(res.data);

                        this.download(res.data);
                    })
                    .catch(error => {});
            },

            nodeList() {
                //查询设备列表
                this.$api
                    .cardRechargeRulesList({

                    })
                    .then(res => {
                     
                         for (let index = 0; index < res.data.data.records.length; index++) {
                         res.data.data.records[index].iseditor = false
                            
                         }
                            this.tableData = res.data.data.records;
                        this.total = res.data.data.total;

                        // console.log(this.currentPage)
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        created() {
            //查询产品列表

        },
        mounted() {
            this.nodeList();

        },

    };
</script>
<style lang="less" scoped>
    @toolBarFontColor: rgba(17, 17, 17, 0.87);

    .message-text {
        font-family: MicrosoftYaHei;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 3rem;
        letter-spacing: 0rem;
        color: #333333;
        width: 50%;
    }

    .fontblack {
        color: #999999;
    }

    .img2 {
        width: 0.81rem;
        height: 0.8rem;
        float: right;
    }

    .addressshow2 {
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        width: 75%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 1rem;
    }

    .addressshow1 {
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        width: 75%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 1rem;
    }

    .orderTitle {
        font-size: 1rem;
        color: #333333;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
        height: 2.5rem;
    }

    .logistics {
        display: inline-block;
        min-width: 140px;
        text-align: right
    }

    .dialog-footer {
        display: inline-block;
        margin: 2rem 0 0 30%;

    }

    .orderDetail {
        font-size: 0.8rem;
        color: #666666;
        text-align: left;
    }

    .border-ceter {
        width: 92%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .pay-button {
        width: 88%;
        height: 2.6rem;
        position: relative;
        background-color: red;
        color: white;

        margin-left: 6%;
    }

    ul li {
        list-style: none;
        font-size: 1rem;
    }

    ul {
        padding-left: 1.5rem;
    }


    .el-table td,
    .el-table th {
        padding: 0 !important;
    }

    .el-pagination {
        margin: 1rem 0;
        float: right;
    }



    .product-content {
        height: 6rem;

        .product-info-item {
            float: left;
            margin-right: 4rem;

            .label-info {
                margin-bottom: 0.5rem;

                label {
                    font-size: 0.75rem;

                    span {
                        color: red;
                        margin-right: 0.5rem;
                    }
                }
            }

            .el-input {
                width: 10rem;
            }

            .el-select {
                width: 13rem;
            }
        }
    }

    .container-el-row {
        margin-top: 0px;
    }

    .select {
        height: 46px;
        overflow: hidden;
        border-bottom: 1px solid #d9d9d9;
        padding: 0rem 3rem;

        .title {
            display: inline-block;
            line-height: 46px;
            font-weight: bold;
            font-size: 0.8rem;
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            margin-top: 7px;
            margin-left: 15px;
        }

        .select-input-right-input {
            float: right;
            margin-top: 7px;
        }
    }

    .table-vv {
        padding: 0rem 3rem;
        margin-top: 1rem;

        .result-info {
            height: 1rem;
            line-height: 1rem;
            font-size: 0.875rem;
            float: left;
            color: #898989;

            span {
                color: #292929;
                padding: 0 0.1rem;
            }
        }

        .el-pagination {
            margin: 1rem 0;
            float: right;
        }
    }

    .gaojiSearch {
        margin-top: 10px;
        padding: 1rem 3rem;
        margin-bottom: 10px;
        background-color: #f4f6fa;

        .searchBut {
            float: right;
            margin-left: 20px;
        }
    }
</style>