<template>

    <div class="mainContainer">
        <div class="select">
            <span class="title">官网下载中心文件管理列表</span>
            <!-- <el-button type="text" size="small" icon="el-icon-download" class="select-button" @click="goodsExport">导出
            </el-button> -->
            <el-button type="text" size="small" icon="el-icon-refresh" class="select-button" @click="refresh">刷新
            </el-button>
        </div>
        <div class="table-vv">
            <el-card class="box-card">
            <div>
                <span style="font-size:15px;"> 文件新增：</span>
                <el-upload style="display:inline" class="select-button" action :on-change="handleChange"
                    :on-remove="handleRemove" :on-exceed="handleExceed" accept=".doc, .docx, .xls, .xlsx ,.pdf,.png,.jpg" :file-list="fileList"
                    :limit="limitUpload" :auto-upload="false" ref="my-upload">
                    <el-button type="primary" round size="mini">文件上传</el-button>
                </el-upload>
                <div style="margin:2rem 0">
                    <span style="vertical-align: middle;font-size:15px;" >文件名称：</span>
                    <el-input v-model="remark" type="textarea" :rows="2" style="width:28rem;vertical-align: middle;" size="mini"></el-input>
                <el-button type="primary" round size="mini" style="float:right;" @click="add_file">提交</el-button>
                </div>
              <div style="margin:2rem 0">
                <span style="vertical-align: middle;font-size:15px;" >文件排序：</span>
                <el-input v-model="sort" type="text" placeholder="请输入排序号，数字越小越靠前" :rows="1" style="width:28rem;vertical-align: middle;" size="mini"></el-input>

              </div>

              <div style="margin:2rem 0">
                <span style="vertical-align: middle;font-size:15px;" >文件一级分类：</span>
                <el-select v-model="value" placeholder="请选择" size="small"         @change="change" >
                  <el-option

                      v-for="item in typeOptions"
                      :key="item.id"
                      :label="item.fileCategoryName"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div style="margin:2rem 0">
                <span style="vertical-align: middle;font-size:15px;" >文件二级分类：</span>
                <el-select v-model="value2" placeholder="请选择" size="small">
                  <el-option
                      v-for="item in typeSecondOptions"
                      :key="item.id"
                      :label="item.fileCategoryName"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </div>

            </div>
</el-card>
            <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
                <div class="result-info">
                    共有
                    <span>{{ total }}</span>个文件
                </div>
            </div>
            <el-table :data="tableData" stripe size="small" class="product-table"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>

                <el-table-column prop="fileName" label="文件后缀名称" align="center"></el-table-column>
                <el-table-column prop="fileSize" label="文件大小(kb)" align="center"></el-table-column>
                <el-table-column prop="fileUrl" label="文件路径" align="center"></el-table-column>
                <el-table-column prop="remark" label="文件名称" align="center"></el-table-column>
              <el-table-column prop="fileCategoryName" label="文件类别" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <el-button @click="orderDetail(scope.row)" type="text" size="small" icon="el-icon-info">编辑
                        </el-button>
                        <el-button @click="orderDelete(scope.row.id)" type="text" size="small" icon="el-icon-s-promotion">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="currentPage" background :page-sizes="[10, 20]" :page-size="pageSize"
                :total="total" layout="sizes,prev, pager, next" @size-change="handleSizeChange"
                @current-change="handlePageChange">
            </el-pagination>


 <!-- 查看 -->
            <el-dialog title="详细信息" :visible.sync="dialogVisible" width="50%">
               <div style="margin:2rem 0">
                    <span style="vertical-align: middle;" >文件名称：</span>
                    <el-input v-model="newremark" type="textarea" :rows="2" style="width:28rem;vertical-align: middle;" size="mini"></el-input>

                </div>
              <div style="margin:2rem 0">
                <span style="vertical-align: middle;" >文件排序：</span>
                <el-input v-model="newSort" type="text" :rows="1" style="width:28rem;vertical-align: middle;" size="mini"></el-input>

              </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="updata()">确 定</el-button>
                </span>

            </el-dialog>

            <!-- 删除 -->
            <el-dialog title="提示" :visible.sync="delDialogVisible" width="30%">
                <span>确定删除该文件吗,删除后对应的文件信息也会被删除？</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click=" delDialogVisible=  false">取 消</el-button>
                    <el-button type="primary" @click="handleDelClose">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>


<script>
    export default {
        name: "fileManagement",
        data() {
            return {
                options: [],
                remark:'',
                typeOptions:[],
                typeSecondOptions:[],
                type:'',
                sort:'',
                newremark:'',
                newSort:'',
                username: "",
                clientId: "",
                searchValue: "", //搜索选择
                password: "",
                imei: "",
                value: "",
                value2:"",
                fit: "fill",
                list: [],
                fileList: [],
                limitUpload: 1,
                loading: false,
                productDel: false,
                productAdd: false,
                delDialogVisible: false,
                orderType: "",
                tradeType: "",
                tradeNo: "",
                orderStatus: "",
                creator: "",
                currentPage: 1,
                updataId: '',
                dialogVisible: false,
                batchDialogVisible: false,
                visible: false,
                total: 0,
                tableData: [],
                fileTemp: [],
                restaurants: [],
                nameData: [],
                chooseOnlineStatus: "", //搜索选择
                codeStatusList: [], //账号所属类型
                checked: false,

                protocols: [],
                ids: [],
                //产品品类列表
                categories: [],
                input: "",
                current: 1,
                pageSize: 10,

                productId: "",
                indexId: "", //反显id
                nameinput: "", //搜索输入
                indexIdlist: "", //反显列表
                formLabelWidth: "120px",
                cachecurrent: "",
                idDel: ""
            };
        },
        methods: {
            change(){
              this.typeOptions.forEach((item)=>{
                if(item.id == this.value){
                  this.value2 = ""
                  this.typeSecondOptions = item.sonFileCategorys;

                }
              })
            },

            refresh() {
                this.nodeList();
            },
            updata(){
                 let formData = new FormData();

        formData.append("id", this.updataId);
        formData.append("remark",this.newremark);
              formData.append("sort",this.newSort);
                 this.$api.fileManagementUpdateWeb(formData).then(res => {
                            if (res.data.code == 200) {
                                 this.nodeList();
                                   this.dialogVisible = false
                                   this.newremark =''
                                    this.newSort = ''
                                this.$message({
                                    type: "success",
                                    message: "修改成功"
                                });
                            }
                        });
            },
            // 新增文件
            add_file(){
                if(this.fileTemp.length == 0){
                         this.$message.error("请导入文件!");
                         return
                }
                  if(this.remark == ''){
                         this.$message.error("请填写文件名称信息!");
                         return
                }
              if(this.value2 == ''){
                this.$message.error("请选择文件分类");
                return
              }
                let formData = new FormData();
              formData.append("multipartFile", this.fileTemp);
              formData.append("remark",this.remark);
              formData.append("sort",this.sort);
              formData.append("fileCategoryId",this.value2);
                      this.$api.fileManagementWebAdd(formData).then(res => {
                            if (res.data.code == 200) {
                                 this.nodeList();
                                this.$message({
                                    type: "success",
                                    message: "导入成功"
                                });
                                  this.fileList =[];
                                  console.log(  this.fileList)
                                  this.remark = '';
                                  this.sort = '';
                            }
                        });
            },
            handleChange(file, fileList) {
                this.fileTemp = file.raw;
                if (this.fileTemp) {
                    console.log(this.fileTemp)
                    // this.importfxx(this.fileTemp);
                } else {
                    this.$message({
                        type: "warning",
                        message: "请上传附件！"
                    });
                }
            },
            //超出最大上传文件数量时的处理方法
            handleExceed(file, fileList) {
                this.$message({
                    type: "warning",
                    message: "超出最大上传文件数量的限制！请删除已上传文件后重新上传"
                });
                return;
            },
            //移除文件的操作方法
            handleRemove(file, fileList) {
                this.fileTemp = null;
                // this.tableData = [];
            },
            importfxx(obj) {
                let _this = this;
                let inputDOM = this.$refs.inputer;
                // 通过DOM取文件数据
                this.file = event.currentTarget.files[0];
                var rABS = false; //是否将文件读取为二进制字符串
                var f = this.file;
                var reader = new FileReader();

                //if (!FileReader.prototype.readAsBinaryString) {
                FileReader.prototype.readAsBinaryString = function (f) {
                    var binary = "";
                    var rABS = false; //是否将文件读取为二进制字符串
                    var pt = this;
                    var wb; //读取完成的数据
                    var outdata;
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        var bytes = new Uint8Array(reader.result);
                        var length = bytes.byteLength;
                        for (var i = 0; i < length; i++) {
                            binary += String.fromCharCode(bytes[i]);
                        }

                        //此处引入，用于解析excelnpm
                        var XLSX = require("xlsx");

                        if (rABS) {
                            wb = XLSX.read(btoa(fixdata(binary)), {
                                //手动转化
                                type: "base64"
                            });
                        } else {
                            wb = XLSX.read(binary, {
                                type: "binary"
                            });
                        }
                        outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                        //outdata就是读取的数据（不包含标题行即表头，表头会作为对象的下标）
                        //此处可对数据进行处理
                        let arr = [];
                        let arr2 = [];
                        outdata.map(v => {
                            let imei = v["设备识别码"];
                            let tradeNo = v["交易流水号"];
                            if (imei != "" || tradeNo != "") {
                                arr.push({
                                    imei: imei,
                                    tradeNo: tradeNo
                                });
                            }
                        });
                        _this.nameData = arr;

                        console.log(_this.nameData);
                        _this.$api.cardCompleteNotify(_this.nameData).then(res => {
                            if (res.data.code == 200) {
                                _this.$message({
                                    type: "success",
                                    message: "导入成功，共导入" + arr.length + "完成设备"
                                });
                            }
                        });
                        return arr;
                    };
                    reader.readAsArrayBuffer(f);
                };
                if (rABS) {
                    reader.readAsArrayBuffer(f);
                } else {
                    reader.readAsBinaryString(f);
                }
            },
            // 搜索清空
            emptyFn() {
                this.tradeNo = "";
                this.orderStatus = "";
                this.creator = "";
            },
            handlePageChange(val) {
                this.current = val;

                this.nodeList();

            },
            handleSizeChange(val) {
                this.pageSize = val;

                this.nodeList();

            },
            orderDelete(data) {
                this.delDialogVisible = true;
                this.idDel = data;
            },
            handleDelClose() {
                this.$api
                    .fileManagementDelete(this.idDel)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.delDialogVisible = false;
                            this.$message.success("删除产品成功!");
                            this.nodeList();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },






            // 查看
            orderDetail(item) {
                this.dialogVisible = true;
                console.log(item);
                this.newremark =item.remark
                this.newSort = item.sort
                this.updataId = item.id

            },

            //导出函数
            download(data) {
                if (!data) {
                    return;
                }
                let url = window.URL.createObjectURL(
                    new Blob([data], {
                        type: "application/octet-binary"
                    })
                );
                let link = document.createElement("a");
                link.style.display = "none";
                link.href = url;
                link.setAttribute("download", "excel.xls");
                // link.setAttribute("download", this.goodName + "excel.xls");

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            },
            getFileCategory(){
              this.$api
                  .getFileCategoryList()
                  .then(res => {

                      this.typeOptions = res.data.data;
                  })
                  .catch(error => {

                  });
            },
            nodeList() {
                //查询设备列表
                this.$api
                    .fileManagementListWeb({
                        page: {
                            current: this.current,
                            size: this.pageSize
                        },
                        fileQueryDto: {
                          fileCategoryId:""
                        }

                    })
                    .then(res => {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                        this.currentPage = parseInt(this.current);
                        // console.log(this.currentPage)
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        created() {
            //查询产品列表
        },
        mounted() {
            this.nodeList();
            this.getFileCategory()
        }
    };
</script>
<style lang="less" scoped>
    @toolBarFontColor: rgba(17, 17, 17, 0.87);

    .message-text {
        font-family: MicrosoftYaHei;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 3rem;
        letter-spacing: 0rem;
        color: #333333;
        width: 50%;
    }

    .fontblack {
        color: #999999;
    }

    .img2 {
        width: 0.81rem;
        height: 0.8rem;
        float: right;
    }

    .addressshow2 {
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        width: 75%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 1rem;
    }

    .addressshow1 {
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        width: 75%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 1rem;
    }

    .orderTitle {
        font-size: 1rem;
        color: #333333;
        height: auto;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
        height: 2.5rem;
    }

    .logistics {
        display: inline-block;
        min-width: 140px;
        text-align: right;
    }

    .dialog-footer {
        display: inline-block;
        margin: 2rem 0 0 30%;
    }

    .orderDetail {
        font-size: 0.8rem;
        color: #666666;
        text-align: left;
    }

    .border-ceter {
        width: 92%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .pay-button {
        width: 88%;
        height: 2.6rem;
        position: relative;
        background-color: red;
        color: white;

        margin-left: 6%;
    }

    ul li {
        list-style: none;
        font-size: 1rem;
    }

    ul {
        padding-left: 1.5rem;
    }

    .el-table td,
    .el-table th {
        padding: 0 !important;
    }

    .el-pagination {
        margin: 1rem 0;
        float: right;
    }

    .product-content {
        height: 6rem;

        .product-info-item {
            float: left;
            margin-right: 4rem;

            .label-info {
                margin-bottom: 0.5rem;

                label {
                    font-size: 0.75rem;

                    span {
                        color: red;
                        margin-right: 0.5rem;
                    }
                }
            }

            .el-input {
                width: 10rem;
            }

            .el-select {
                width: 13rem;
            }
        }
    }

    .container-el-row {
        margin-top: 0px;

    }

    .select {
        height: 46px;
        overflow: hidden;
        border-bottom: 1px solid #d9d9d9;
        padding: 0rem 3rem;

        .title {
            display: inline-block;
            line-height: 46px;
            font-weight: bold;
            font-size: 0.8rem;
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            margin-top: 7px;
            margin-left: 15px;
        }

        .select-input-right-input {
            float: right;
            margin-top: 7px;
        }
    }

    .table-vv {
        padding: 0rem 3rem;
        margin-top: 1rem;

        .result-info {
            height: 1rem;
            line-height: 1rem;
            font-size: 0.875rem;
            float: left;
            color: #898989;

            span {
                color: #292929;
                padding: 0 0.1rem;
            }
        }

        .el-pagination {
            margin: 1rem 0;
            float: right;
        }
    }

    .item {
        margin: 4px;
        cursor: pointer;
    }

    .right .el-tooltip__popper {
        padding: 8px 10px;
        cursor: pointer;
    }

    /deep/.el-dialog {
        .dialog_title {
            border: rgb(189, 178, 178) 1px solid;
            padding: 3px;
            display: inline-block;
            width: 20%;
            text-align: right;
        }

        .dialog_value {
            border: rgb(189, 178, 178) 1px solid;
            padding: 3px;
            display: inline-block;
            width: 60%;
            text-align: left;
        }
    }
    .box-card{
        // padding: 1rem 0;
        margin: 1rem 0;
    }
</style>
