<template>
  <div class="mainContainer">

    <div class="select">
      <span class="title">兑换码列表</span>

      <el-button type="text" size="small" icon="el-icon-download" class="select-button" @click="goodsExport">导出
      </el-button>
      <el-button type="text" size="small" icon="el-icon-folder-opened" class="select-button" @click="redemptionCode">
        生成兑换码</el-button>

      <el-button type="text" size="small" icon="el-icon-upload" class="select-button" @click="batchCodes">
        批量卖出兑换码</el-button>
    </div>
    <div class="gaojiSearch">
      <div style="width:100%;overflow: hidden;">
        <div style="float:left">
          <div class="toolbar-right">
            <span style="font-size:12px" class="toolbarTit">兑换码 ：</span>
            <el-input placeholder="请输入兑换码" v-model="exchangeCode" size="mini" style="width: 10.25rem;;margin-right:1rem"
              clearable></el-input>
            <span style="font-size:12px" class="toolbarTit">商品名称 ：</span>
            <el-select v-model="goodsId" size="mini" clearable style="display:inline-block;;margin-right:1rem">
              <el-option v-for="item in goodsList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <span style="font-size:12px;margin-bottom:10px" class="toolbarTit">兑换码状态 ：</span>
            <el-select v-model="codeStatus" size="mini" clearable
              style="display:inline-block;width: 10.25rem;;margin-right:1rem">
              <el-option v-for="item in codeStatusList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <span style="font-size:12px;margin-bottom:10px" class="toolbarTit">库存状态 ：</span>
            <el-select v-model="stockStatus" size="mini" clearable
              style="display:inline-block;width: 10.25rem;;margin-right:2rem">
              <el-option v-for="item in stockStatusList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <!-- <i class="el-icon-bottom" ></i> -->

            <!-- <el-button size="mini" type="primary" circle @click="dropDownFn()">{{dropDowntext}}</el-button> -->
            <br />
            <div v-show="dropDown">
              <!-- :picker-options="pickerOptions"当天之后的时间不能选择 -->
              <span style="font-size:12px">有效期时间 ：</span>
              <el-date-picker style="width: 20.25rem;;margin-right:2rem" clearable v-model="validityTime"
                range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" size="mini"
                value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
                type="datetimerange" @change="getTimeBlock"></el-date-picker>
              <span style="font-size:12px">创建日期 ：</span>
              <el-date-picker style="width: 20.25rem;" clearable v-model="createTime" range-separator="~"
                start-placeholder="开始日期" end-placeholder="结束日期" size="mini" value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" type="datetimerange"
                @change="getTimeBlock2"></el-date-picker>
              <br>
              <span style="font-size:12px">卖出时间 ：</span>
              <el-date-picker style="width: 20.25rem;;margin-right:2rem;margin-top:10px" clearable v-model="saleTime"
                range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" size="mini"
                value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
                type="datetimerange" @change="getTimeBlock"></el-date-picker>
              <span style="font-size:12px" class="toolbarTit">买方信息 ：</span>
              <el-input placeholder="请输入买方信息" v-model="buyerInfo" size="mini" style="width: 10.25rem;;margin-right:1rem"
                clearable></el-input>
              <span style="font-size:12px;margin-bottom:10px" class="toolbarTit">是否有效 ：</span>
              <el-select v-model="isEnable" size="mini" clearable
                style="display:inline-block;width: 10.25rem;;margin-right:1rem">
                <el-option v-for="item in isEnableList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;overflow: hidden;">
        <div style="float:right;">
          <div class="grid-content bg-purple-dark">
            <div class="searchBut">
               <el-button type="primary" @click="dropDownFn()" icon="el-icon-top"  v-if="dropDown" circle size="mini"></el-button>
               <el-button type="primary" @click="dropDownFn()" icon="el-icon-bottom"  v-else   circle size="mini"></el-button>
              <!-- <span @click="dropDownFn()" class="dropDowntext">{{dropDowntext}}</span> -->
                <el-button size="mini" @click="emptyFn()">清空</el-button>
              <el-button size="mini" type="primary" @click="imeiSearch">搜索</el-button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-vv">

      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个兑换码
        </div>
      </div>
      <el-table :data="tableData" stripe size="small" class="product-table"
        :header-cell-style="{'background-color': '#eeeeee'}" @select="getIds" @select-all="getAllIds">
        <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <!-- <el-table-column prop="id" label="节点编号" align="center"></el-table-column> -->
        <el-table-column prop="exchangeCode" label="兑换码" align="center"></el-table-column>
        <el-table-column prop="goodsName" label="商品名称" align="center"></el-table-column>

        <el-table-column prop="isEnable" label=" 是否有效" align="center">
          <template slot-scope="scope">
            <span>
              {{scope.row.isEnable == 0 ?'无效':'有效'}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="codeStatus" label="兑换情况" align="center">
          <template slot-scope="scope">
            <span>
              {{scope.row.codeStatus == 0 ?'待使用':scope.row.codeStatus == 1?'使用中':'已使用'}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="stockStatus" label="库存状态" align="center">
          <template slot-scope="scope">
            <span>
              {{scope.row.stockStatus == 0 ?'在库状态':scope.row.stockStatus == 1?'卖出状态':''}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="validityTimeStart" label="有效期开始" align="center"></el-table-column>
        <el-table-column prop="validityTimeEnd" label="有效期截止" align="center"></el-table-column>

        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>


        <el-table-column prop="buyerInfo" label="买方信息" align="center"></el-table-column>
        <el-table-column prop="saleTime" label="卖出时间" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="changeDetail(scope.row)" type="text" size="small" v-if="scope.row.codeStatus == '2' "
              icon="el-icon-info">详情</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination background :current-page.sync="currentPage" :page-sizes="[10, 20]" :page-size="pageSize"
        :total="total" layout="sizes,prev, pager, next" @size-change="handleSizeChange"
        @current-change="handlePageChange">
      </el-pagination>
    </div>


  </div>
</template>


<script>
  // import Bus from "../../../components/bus";
  export default {
    name: "exchangeList",
    data() {
      return {
        productDel: false,
        productAdd: false,
        batchId: '',
        currentPage: 1,
        input3: "",
        onShelf: "",
        dropDowntext: "展开∨",
        dropDown: false,
        goodsList: [],
        isEnableList: [{
            value: '0',
            label: "无效",
          },
          {
            value: '1',
            label: "有效"
          }
        ],
        stockStatusList: [{
            id: '0',
            name: "在库状态",
          },
          {
            id: 1,
            name: "卖出状态 "
          }
        ],
        codeStatusList: [{
            id: '0',
            name: "待使用"
          },
          {
            id: '1',
            name: "使用中"
          },
          {
            id: '2',
            name: "已使用"
          },


        ],
        goodsId: "",
        pickerOptions: {
          disabledDate(time) {
            let cuttentDate = new Date(
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
            );
            let hmCurrentDate = cuttentDate.getTime();
            let three = 90 * 24 * 3600 * 1000;
            let threeMonths = hmCurrentDate - three;
            return time.getTime() > hmCurrentDate || time.getTime() < threeMonths;
          }
        },
        exchangeCode: "",
        validityTimeStart: "", //有效截止日期
        validityTimeEnd: "",
        validityTime: "", //有效截止日期
        codeStatus: "",
        createTimeStart: "",
        createTime: "",
        createTimeEnd: "",
        downShelf: "",
        onstartTime: "",
        downstartTime: "",
        onendTime: "",
        downendTime: "",
        form: {
          nodeName: "",
          isSendEmail: "",
          nodeDesc: "",
          problemStatusId: "",
          isSendSmsCreator: ""
        },
        formModify: {
          isSendEmail: "",
          isSendSmsCreator: ""
        },
        visible: false,
        total: 0,
        tableData: [],
        emileList: [{
            value: "1",
            label: "是"
          },
          {
            value: "0",
            label: "否"
          }
        ], //搜索下拉选择
        smsList: [{
            value: "1",
            label: "发送"
          },
          {
            value: "0",
            label: "不发送"
          }
        ], //是否发送短信
        chooseOnlineStatus: "", //搜索选择
        sysUserlist: [], //账号所属类型
        newSsUserlist: {
          value: "id",
          label: "typeName",
          children: "childs",
          expandTrigger: "hover"
          // emitPath: false
        },
        checked: false,
        //新增账号弹出对话框标记
        dialogVisible: false,
        //修改账号弹出对话框标记
        modifydialogVisible: false,
        //节点问题状态
        protocols: [],

        //产品品类列表
        categories: [],
        ids: [],
        saleTime: [], //，卖出时间
        buyerInfo: "", //，买家信息
        stockStatus: "", //库存状态
        isEnable: "", //是否有效
        value: "",
        input: "",
        current: 1,
        pageSize: 10,
        delDialogVisible: false,
        productId: "",
        indexId: "", //反显id
        nameinput: "", //搜索输入
        indexIdlist: "", //反显列表
        formLabelWidth: "120px",
        detailgood:"",


      };
    },
    methods: {
      refresh() {
        this.nodeList();
      },
        // 搜索清空
    emptyFn(){
      this.exchangeCode = ""
      this.goodsId = ""
      this.codeStatus = ""
      this.stockStatus = ""
      this.validityTime = ""
      this.createTime = ""
      this.saleTime = ""
      this.buyerInfo = ""
      this.isEnable = ""
    },
      // 点击跳转详情
      changeDetail(item) {
        console.log(item.exchangeCode) //打印结果 vHWpb52419633Q 
        this.$router.push({
          path: `/exchangeDetail`,
          query: {
            key: item.exchangeCode
          }
        });
      },

      imeiSearch() {
        this.current = 1
        this.nodeList();
      },
      //获取有效截止日期
      getTimeBlock() {
        if (this.validityTime) {
          this.validityTimeStart = this.validityTime[0];
          this.validityTimeEnd = this.validityTime[1];
        } else {
          this.validityTimeStart = "";
          this.validityTimeEnd = "";
        }
      },
      //获取创建日期
      getTimeBlock2() {
        if (this.createTime) {
          this.createTimeStart = this.createTime[0];
          this.createTimeEnd = this.createTime[1];
        } else {
          this.createTimeStart = "";
          this.createTimeEnd = "";
        }
      },
      // 商品名称下拉框
      goodsListPull() {
        this.$api.GoodsListPull().then(res => {
          console.log(res.data.data)
          for (let index = 0; index < res.data.data.length; index++) {
   this.goodsList.push({
     id:res.data.data[index].id.toString(),
     name:res.data.data[index].name
   })
            
          }
          // this.goodsList = res.data.data

        });
      },
      ifMohuHandler(e) {
        // console.log(e);
        this.ifMohu = false;
        this.searchData = [];
      },

      // changex清空
      upOptionType() {
        this.nameinput = "";
      },
      handlePageChange(val) {
        this.current = val;
        this.nodeList();
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.nodeList();
      },
      isSendSmsCreator(item) {
        console.log(item);
      },



      //批量选中
      getIds(selection, row) {
        var arr = [];
        // console.log(selection)
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].goodsId);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].goodsId);
        }
        this.ids = idArr;
        console.log(this.ids)
      },
      //全部选中
      getAllIds(selection, row) {
        var arr = [];
        for (var i = 0; i < selection.length; i++) {
          arr.push(selection[i].mac);
        }
        this.dispatchList = arr;
        var idArr = [];
        for (var i = 0; i < selection.length; i++) {
          idArr.push(selection[i].goodsId);
        }
        this.ids = idArr;

      },


      nodeList() {
        //查询设备列表
        console.log(this.stockStatus)
        let queryDo = {
          exchangeCode: this.exchangeCode,
          goodsId: this.goodsId,
          codeStatus: this.codeStatus,
          createTimeStart:this.createTime == null ? "" :this.createTime[0],
          createTimeEnd:this.createTime == null ? "" :this.createTime[1],
          validityTimeStart: this.validityTime == null ? "" :this.validityTime[0],
          validityTimeEnd:  this.validityTime == null ? "" :this.validityTime[1],
          saleTimeStart:  this.saleTime == null ? "" :this.saleTime[0],
          saleTimeEnd: this.saleTime == null ? "" :this.saleTime[1],
          buyerInfo: this.buyerInfo,
          isEnable: this.isEnable,
          stockStatus: this.stockStatus,
        }

        Object.keys(queryDo).map(key => (!queryDo[key] ? delete queryDo[key] : ''));
        // console.log(queryDo );  
        this.$api
          .CodeList({
            page: {
              current: this.current,
              size: this.pageSize
            },
            codeShowQueryVo: queryDo
          })
          .then(res => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
            this.currentPage = parseInt(this.current)
            // if (this.dropDown == true) {
            //   this.dropDowntext = "收起∧"
            // } else {
            //   this.dropDowntext = "展开∨"
            // }
          })
          .catch(error => {
            console.log(error);
          });
      },
      //重置函数
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 生成兑换码
      redemptionCode() {
        this.$router.push("/redemptionCode");
      },
      // 批量卖出兑换码
      batchCodes() {
        this.$router.push("/batchCodes");
      },
      dropDownFn() {
        this.dropDown = !this.dropDown
        if (this.dropDown == true) {
          this.dropDowntext = "收起∧"
        } else {
          this.dropDowntext = "展开∨"
        }
      },



      // 导出
      goodsExport() {
        let queryDo = {
          exchangeCode: this.exchangeCode,
          goodsId: this.goodsId,
          codeStatus: this.codeStatus,
          stockStatus: this.stockStatus,
        createTimeStart:this.createTime == null ? "" :this.createTime[0],
          createTimeEnd:this.createTime == null ? "" :this.createTime[1],
          validityTimeStart: this.validityTime == null ? "" :this.validityTime[0],
          validityTimeEnd:  this.validityTime == null ? "" :this.validityTime[1],
          saleTimeStart:  this.saleTime == null ? "" :this.saleTime[0],
          saleTimeEnd: this.saleTime == null ? "" :this.saleTime[1],
          buyerInfo: this.buyerInfo,
          isEnable: this.isEnable,
        }
        // console.log(queryDo );  
        Object.keys(queryDo).map(key => (!queryDo[key] ? delete queryDo[key] : ''));
        // console.log(queryDo );  
        this.$api
          .CodeExport({
            query: queryDo,
            properties: [
              "exchangeCode",
              "validityTimeStart",
              "validityTimeEnd",
              // "codeStatus",
              "goodsName",
              "createTime",

            ],
            titles: [
              "兑换码",
              "有效期开始",
              "有效期截止",
              // "兑换情况",
              "商品名称",
              "创建时间",
            ]
          })
          .then(res => {
            // console.log(res);
            this.download(res.data);
          })
          .catch(error => {});
      },
      //导出函数
      download(data) {
        if (!data) {
          return;
        }
        let url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/octet-binary"
          })
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xls");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
    },
    created() {
      //查询产品列表
      // this.nodeList();
    },
    mounted() {
      this.goodsListPull()
    },
    beforeRouteEnter(to, from, next) {
      // console.log(from.path, '组件独享守卫beforeRouteEnter第二个参数');
      next(vm => {
        if (from.path == '/exchangeDetail') {
          vm.current = sessionStorage.getItem("excurrent")
          vm.exchangeCode = sessionStorage.getItem("exchangeCode")
          vm.codeStatus = sessionStorage.getItem("codeStatus")
          vm.isEnable = sessionStorage.getItem("isEnable")
          vm.goodsId = sessionStorage.getItem("exGoodsId")
          vm.stockStatus = sessionStorage.getItem("exStockStatus")
          vm.buyerInfo = sessionStorage.getItem("buyerInfo")//买方信息
          vm.createTime = sessionStorage.getItem("exCreateTime").split(',')
          vm.validityTime = sessionStorage.getItem("exValidityTime").split(',')
          vm.saleTime = sessionStorage.getItem("exSaleTime").split(',')
          vm.dropDown = JSON.parse(sessionStorage.getItem("exDropDown"));
          vm.nodeList();
        } else {
          //因为当钩子执行前，组件实例还没被创建
          // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
          // console.log(vm);//当前组件的实例
          vm.current = 1
          vm.exchangeCode = ""
          vm.codeStatus = ""
          vm.isEnable = ""
          vm.exStockStatus = ""
          vm.goodsId = ""
          vm.buyerInfo = ""
          vm.createTime = ""
          vm.validityTime = ""
          vm.saleTime = ""
          vm.dropDown = false
          vm.nodeList();
        }

      });
    },

    beforeRouteLeave(to, from, next) {
      // 导航离开该组件的对应路由时调用
      // 可以访问组件实例 `this`
      // console.log(this, 'beforeRouteLeave'); //当前组件实例
      // console.log(to.path, '组件独享守卫beforeRouteLeave第一个参数');
      if (to.path == '/exchangeDetail') {
        sessionStorage.setItem("excurrent", this.current);
        sessionStorage.setItem("exchangeCode", this.exchangeCode);
        sessionStorage.setItem("codeStatus", this.codeStatus);
        sessionStorage.setItem("isEnable", this.isEnable);
        sessionStorage.setItem("exDropDown", JSON.stringify(this.dropDown));
        sessionStorage.setItem("exGoodsId", this.goodsId);
        sessionStorage.setItem("buyerInfo", this.buyerInfo);
        sessionStorage.setItem("exStockStatus", this.stockStatus);
        sessionStorage.setItem("exCreateTime", this.createTime);
        sessionStorage.setItem("exValidityTime", this.validityTime);//有效期时间
        sessionStorage.setItem("exSaleTime", this.saleTime);//卖出


      } else {
        sessionStorage.removeItem("excurrent");
        sessionStorage.removeItem("exchangeCode");
        sessionStorage.removeItem("codeStatus");
        sessionStorage.removeItem("isEnable");
        sessionStorage.removeItem("exDropDown");
        sessionStorage.removeItem("exGoodsId");
        sessionStorage.removeItem("exStockStatus");
        sessionStorage.removeItem("exCreateTime");
        sessionStorage.removeItem("exValidityTime");
        sessionStorage.removeItem("exSaleTime");
        sessionStorage.removeItem("buyerInfo");

      }
      // console.log(from, '组件独享守卫beforeRouteLeave第二个参数');
      // console.log(next, '组件独享守卫beforeRouteLeave第三个参数');
      next();
    }
  };
</script>
<style lang="less" scoped>
  @toolBarFontColor: rgba(17, 17, 17, 0.87);

  .dropDowntext {
    font-size: 0.5rem;
    color: #686464;
    cursor: pointer;
    margin-right: 12px;
  }

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }



    .toolbar-right {
      float: right;
      margin-top: 7px;

      &.export {
        margin-left: 1rem;

        .toolbar-btn {
          color: @toolBarFontColor;
          font-size: 0.9rem;

          &.left {
            margin-left: 1.5rem;
          }

          &.right {
            margin-right: 0;
          }
        }
      }

    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }


  .product-content {
    height: 6rem;

    .product-info-item {
      float: left;
      margin-right: 4rem;

      .label-info {
        margin-bottom: 0.5rem;

        label {
          font-size: 0.75rem;

          span {
            color: red;
            margin-right: 0.5rem;
          }
        }
      }

      .el-input {
        width: 10rem;
      }

      .el-select {
        width: 13rem;
      }
    }
  }

  .gaojiSearch {
    margin-top: 10px;
    padding: 1rem 3rem;
    margin-bottom: 10px;
    background-color: #f4f6fa;

    .searchBut {
      float: right;
      margin-left: 20px;
    }
  }

  .toolbarTit {
    min-width: 60px;
    display: inline-block;
  }

  .container-el-row {
    margin-top: 0px;
  }

  .select {
    height: 46px;
    overflow: hidden;
    border-bottom: 1px solid #d9d9d9;
    padding: 0rem 3rem;

    .title {
      display: inline-block;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .select-button {
      float: right;
      color: @toolBarFontColor;
      margin-top: 7px;
      margin-left: 15px;
    }

    .select-input-right-input {
      float: right;
      margin-top: 7px;
    }
  }

  .table-vv {
    padding: 0rem 3rem;
    margin-top: 1rem;

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .searchClass {
      //  border: 1px solid red;
      width: 100%;

      //  position: relative;
      span {
        font-size: 0.8rem;
        color: rgba(17, 17, 17, 0.87);
      }

      .el-button {
        position: relative;
        margin-bottom: 20px;
        left: 95%;
      }

      .select-searchClass {
        // margin-left:10%;
      }

    }

    .el-pagination {
      margin: 1rem 0;
      float: right;
    }
  }

  .item {
    margin: 4px;
    cursor: pointer;
  }

  .right .el-tooltip__popper {
    padding: 8px 10px;
    cursor: pointer;
  }
</style>