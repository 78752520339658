import { render, staticRenderFns } from "./editRole.vue?vue&type=template&id=716dcdf3&scoped=true&"
import script from "./editRole.vue?vue&type=script&lang=js&"
export * from "./editRole.vue?vue&type=script&lang=js&"
import style0 from "./editRole.vue?vue&type=style&index=0&id=716dcdf3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "716dcdf3",
  null
  
)

export default component.exports