<template>
    <div class="outting">
        <div class="select">
            <span class="title">商品分类列表</span>
            <el-button type="text" size="small" icon="el-icon-plus" @click="addUserHandler" class="addRoleButton">创建商品类别
            </el-button>

        </div>
        <div class="table-vv">
            <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">
                    <el-table-column prop="categoryName" label="商品类别名称 " min-width="2" fixed align="center"> </el-table-column>
                <el-table-column prop="displayArea" label="商品分类展示区域" min-width="2" fixed align="center">
                      <template slot-scope="{row}">{{ row.displayArea  == 1?'商城首页':'为你推荐' }}</template>
                </el-table-column>
                <el-table-column prop="sort" label="商品排序" min-width="2" fixed align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center"></el-table-column>
              <el-table-column label="图片" min-width="1" fixed align="center">
                <template slot-scope="scope">
                  <el-popover placement="top-start" title trigger="hover">
                    <el-image
                        :src="scope.row.categoryPicture"
                        :fit="fit"
                        style="width:160px;height:80px"
                        referrerpolicy="no-referrer"
                    ></el-image>
                    <el-image
                        slot="reference"
                        :src="scope.row.categoryPicture"
                        style="width: 30px;height: 30px"
                        :fit="fit"
                        referrerpolicy="no-referrer"
                    ></el-image>
                  </el-popover>
                </template>
              </el-table-column>
                <el-table-column min-width="1" label="操作" width="180" align="center">
                    <template slot-scope="scope">
                      <el-button @click="goodsList(scope.row)" type="text" size="small" icon="el-icon-info">查看</el-button>
                      <el-button @click="chooseGoodsList(scope.row)" type="text" size="small" icon="el-icon-info">分配</el-button>
                      <el-button @click="editHandler(scope.row)" type="text" size="small" icon="el-icon-info">编辑</el-button>
                        <el-popconfirm title="确定删除这个商品类别吗？" @onConfirm="deleteAddHandler()">
                            <el-button type="text" size="small" icon="el-icon-delete"
                                @click="handleDelete(scope.$index, scope.row)" style="margin-left:10px;"
                                slot="reference">删除</el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>

        </div>
      <el-dialog title="修改商品类别" :visible.sync="editDialogFormVisible" :close-on-click-modal="false">
        <el-form :model="editForm" size="small"  style="50%"  ref="addRules"
                 enctype="multipart/form-data">
          <el-form-item label="商品类别名称" :label-width="formLabelWidth" prop="activityName">
            <el-input v-model="editForm.categoryName" autocomplete="off" style="width:315px" >
            </el-input>
          </el-form-item>

          <el-form-item label="商品类别排序" :label-width="formLabelWidth" prop="sort">
            <el-input v-model="editForm.sort" autocomplete="off" style="width:315px" >
            </el-input>
          </el-form-item>
<!--          <el-form-item label="商品展示区域" :label-width="formLabelWidth" prop="sort">-->
<!--            <el-input v-model="editForm.displayArea" autocomplete="off" style="width:315px"-->
<!--                      placeholder="首页填1，为你推荐填2">-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="商品类别图标" ref="formImg" prop="file" :label-width="formLabelWidth">-->
<!--            <el-upload action="#" :before-upload="beforeAvatarUpload" :on-change="handleChange"-->
<!--                       ref="imgUpload"-->
<!--                       list-type="picture-card" v-show="!value" :show-file-list="false">-->
<!--              <i class="el-icon-plus"></i>-->
<!--            </el-upload>-->
<!--            <el-dialog :visible.sync="dialogVisible">-->
<!--              <img width="100%" height="100%" :src="dialogImageUrl" alt />-->
<!--            </el-dialog>-->
<!--            <div v-show="value" class="el-upload-list el-upload-list&#45;&#45;picture-card">-->
<!--              <div class="el-upload-list__item is-success">-->
<!--                <img class="avatar" v-show="value" :src="value" />-->
<!--                <label class="el-upload-list__item-status-label">-->
<!--                  <i class="el-icon-upload-success el-icon-check"></i>-->
<!--                </label>-->
<!--                <span class="el-upload-list__item-actions">-->
<!--                                                <span class="el-upload-list__item-preview">-->
<!--                                                    <i class="el-icon-zoom-in"-->
<!--                                                       @click.stop="handlePictureCardPreview"></i>-->
<!--                                                </span>-->
<!--                                                <span class="el-upload-list__item-delete">-->
<!--                                                    <i class="el-icon-delete" @click.stop="handleRemove"></i>-->
<!--                                                </span>-->
<!--                                            </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-form-item>-->




        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editDialogFormVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="editCategoryInfo()" size="small">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="创建商品类别" :visible.sync="addDialogFormVisible" :close-on-click-modal="false">
            <el-form :model="addForm" size="small"  style="50%" :rules="addRules" ref="addRules"
                enctype="multipart/form-data">
                   <el-form-item label="商品类别名称" :label-width="formLabelWidth" prop="activityName">
                    <el-input v-model="addForm.activityName" autocomplete="off" style="width:315px" >
                    </el-input>
                </el-form-item>

              <el-form-item label="商品类别排序" :label-width="formLabelWidth" prop="sort">
                <el-input v-model="addForm.sort" autocomplete="off" style="width:315px" >
                </el-input>
              </el-form-item>
              <el-form-item label="商品展示区域" :label-width="formLabelWidth" prop="sort">
                <el-input v-model="addForm.displayArea" autocomplete="off" style="width:315px"
                          placeholder="首页填1，为你推荐填2">
                </el-input>
              </el-form-item>
                  <el-form-item label="商品类别图标" ref="formImg" prop="file" :label-width="formLabelWidth">
                    <el-upload action="#" :before-upload="beforeAvatarUpload" :on-change="handleChange"
                               ref="imgUpload"
                               list-type="picture-card" v-show="!value" :show-file-list="false">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" height="100%" :src="dialogImageUrl" alt />
                    </el-dialog>
                    <div v-show="value" class="el-upload-list el-upload-list--picture-card">
                      <div class="el-upload-list__item is-success">
                        <img class="avatar" v-show="value" :src="value" />
                        <label class="el-upload-list__item-status-label">
                          <i class="el-icon-upload-success el-icon-check"></i>
                        </label>
                        <span class="el-upload-list__item-actions">
                                                <span class="el-upload-list__item-preview">
                                                    <i class="el-icon-zoom-in"
                                                       @click.stop="handlePictureCardPreview"></i>
                                                </span>
                                                <span class="el-upload-list__item-delete">
                                                    <i class="el-icon-delete" @click.stop="handleRemove"></i>
                                                </span>
                                            </span>
                      </div>
                    </div>
                  </el-form-item>




            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addAppListForm('addForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
    export default {
        name: "AppList",
        data() {
          var valiIcon = (rule, value, callback) => {
            if (!this.value) {
              callback(new Error('请上传图片'));
            } else {
              callback();
            }
          };
            return {

                current: 1,
                size: 10,
                total: 0,
                tableData: [],
                deleteId: "",
                editId: "",
                value: "",
                addDialogFormVisible: false,
              editDialogFormVisible:false,
                // 限制开始时间
                pickerOptionsStart: {},
                pickerOptionsEnd: {},
                addForm: {
                    activityName: "",
                    file: "",
                    displayArea:"",
                    sort:""


                },
              editForm: {
                categoryName: "",
                id:"",
                sort:""
              },
              dialogImageUrl: "",
              dialogVisible: false,
                addRules: {
                    activityName: [{
                        required: true,
                        message: "请输入商品类别名称",
                        trigger: "blur"
                    }],
                  displayArea: [{

                    required: true,
                    message: "首页填1，为你推荐填2",
                    trigger: "blur"
                  }],
                  sort: [{
                    required: true,
                    message: "请输入类别排序，数字越小越靠前",
                    trigger: "blur"
                  }],
                    file: [{
                            validator: valiIcon,
                            message: "请上传商品类别图片",
                            trigger: "change"
                        },],

                },
                formLabelWidth: "180px",
            };
        },
        created() {
            this.appList();
        },
        methods: {
          goodsList(data) {

            this.$router.push({
              path: `/categoryGoodsList`,
              query: {
                data: data
              }

            });
          },
          chooseGoodsList(data) {

            this.$router.push({
              path: `/chooseGoodsList`,
              query: {
                data: data
              }

            });
          },
          handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
          },
          handleRemove(file, fileList) {

            this.value = "";
            this.file = {};
          },
          beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 0.2;
            if (!isLt2M) {
              console.log('2200')
              this.$message.error('上传图片大小不能超过 200KB!');
              this.value = ''
            }
            return isLt2M;
          },
            //删除函数
            handleDelete(index, row) {
                this.deleteId = row.id;
            },
            //删除确定
            deleteAddHandler() {
                this.$api.goodsCategoryDelete(this.deleteId).then(res => {
                    console.log(res.data);
                    if (res.data.code == 200) {
                        this.$message.success("删除成功!");
                        this.appList();
                    }
                });
            },
          handleChange(file, fileList) {
            console.log(file.raw, file);
            this.value = file.url;
            this.file = file.raw;
            if (file.raw) {
              this.$nextTick(() => { //等待照片上传完成，
                this.$refs['formImg'].clearValidate()
              })

            }
          },

            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            appList() {
                this.$api
                    .goodsTypeList({
                        current: this.current,
                        size: 10
                    })
                    .then(res => {
                        // console.log(res.data);
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                    });
            },
            addUserHandler() {
                this.file = {};
                this.addDialogFormVisible = true;
                if (this.$refs["addRules"] !== undefined) {
                    this.resetForm("addRules");
                }
            },


          editHandler(item) {

            this.editDialogFormVisible = true;
            this.editForm.sort = item.sort;
            this.editForm.categoryName = item.categoryName;
            this.editForm.id = item.id;
          },



            addAppListForm(formName) {
                this.$refs["addRules"].validate(valid => {
                    if (valid) {
                        this.addDialogFormVisible = false;
                        let formData = new FormData();
                      formData.append("categoryName", this.addForm.activityName);
                      formData.append("displayArea", this.addForm.displayArea);
                      formData.append("sort", this.addForm.sort);
                      formData.append("file", this.file);
                        this.$api.goodsTypeAdd(formData).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("创建成功！");
                                this.file = {};
                              this.$refs.imgUpload.clearFiles();
                                this.value = ""
                                this.appList();
                            }
                        });

                    } else {
                        return false;
                    }
                });
            },

          editCategoryInfo() {

                this.editDialogFormVisible = false;
                // let formData = new FormData();
                // formData.append("categoryName", this.addForm.activityName);
                // formData.append("displayArea", this.addForm.displayArea);
                // formData.append("sort", this.addForm.sort);
                // formData.append("file", this.file);

                this.$api.goodsTypeEdit(this.editForm).then(res => {
                  if (res.data.code == 200) {
                    this.$message.success("编辑成功！");
                    this.appList();
                  }
                });



          },



        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;
      .new_add{
          width: 100%;
      }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 3px;
    margin-left: 15px;
  }
        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .select-status {
                width: 9rem;
                margin-left: 1.7rem;
                margin-top: 7px;
                // margin-top:7px;
            }

            .select-input-timer {
                float: right;
            }

            .select-status {
                float: right;
            }

            .select-input-right-input {
                float: right;
                // width: 8rem;
                line-height: 46px;
            }

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .addRoleButton {
                margin-left: 15px;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }

            .update-input-timer {
                float: right;
                line-height: 46px;
                margin-left: 25px;
                height: 46px;

                .demonstrationTwo {
                    float: left;
                    font-size: 0.8rem;
                }

                .update-input-timer-select {
                    width: 11rem;
                    margin-left: 5px;
                }
            }
        }

        .table-vv {
            padding: 1rem 3rem;

            .pagin {
                margin-left: 30%;
                margin-top: 10px;
                float: right;
            }
        }
    }
</style>
